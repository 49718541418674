import React, { Component } from "react";
import Popup from '../../../common/Auth/Popup';
import './POList.css';
import Dropzone, { useDropzone } from "react-dropzone";
import { API_ENDPOINT_SUPPLIER } from "../../../../services/API";
import User from "../../../../services/User";
import CommonConfirmationModal from "../../../common/Modals/CommonConfirmationModal";
import API from "../../../../services/API";
import EnumPOStatus from "./EnumPOStatus";
import FileViewerModal from "../../../common/FileViewerModal/FileViewerModal";
import { convertUTF8String } from "../../../../services/ConversionService";
import CommonPopUpModal from "../../../CommonPopUpModal/CommonPopUpModal";
import FileMetaDataPopover from "../../../common/PopOver/FileMetaDataPopover";
const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
})

export class POModal extends React.Component {
    user = new User();
    api = new API();
    taxYears = [
        {
            value: "2017",
            text: "2017"
        },
        {
            value: "2018",
            text: "2018"
        },
        {
            value: "2019",
            text: "2019"
        },
        {
            value: "2020",
            text: "2020"
        },
        {
            value: "2021",
            text: "2021"
        },
    ];

    constructor(props_) {
        super(props_);
        this.state = {
            isCreateFundingRequestModalOpen: false,
            purchaseorder: props_.data ? props_.data.documentId : "",
            purchaseamount: props_.data ? props_.data.total : "",
            purchaseDocuments: props_.data ? props_.data.documentFiles : [],
            taxId: null,
            poLists:[],
            otherDocuments:props_.data && props_.data.purchaseOrder && props_.data.purchaseOrder.additionalDocs 
            && props_.data.purchaseOrder.additionalDocs.length>0 ? props_.data.purchaseOrder.additionalDocs :[] ,
            isFinancialDocument:false,
            isFileViewerModal:false,
            fileViewerContent:null
        };
        this.getProfile = this.getProfile.bind(this);
    }

    componentDidMount() {
        if(this.props && (!this.props.isReadOnly)){
            this.loadPOs();
        }
    }
    loadPOs() {
        this.api.docGetPO().then(invoices => {
            this.setState({ poLists: invoices });
        });
    }

    componentWillMount() {
        this.getProfile();
        var currentYear = new Date().getFullYear();
        this.setState({
            taxYear: currentYear - 1
        });

    }
    async getProfile() {
        if(this.user.currentUserRole()==="supplier"){
            const profileResponse = await this.api.getProfile();
            this.setState({
                taxId: profileResponse.user.taxId
            })
        }
    }

    render() {
        return (
            <>
                {(this.state.validationError && this.state.validationError.length) &&
                    <CommonConfirmationModal
                        title={this.props.t("transactions_page.po_content.invalid_inputs")}
                        onClose={() => { this.setState({ validationError: null }) }}
                    >
                        <ul>
                            {this.state.validationError.map(t => <li>{t}</li>)}
                        </ul>
                    </CommonConfirmationModal>
                }
                {this.state.isFileViewerModal && this.state.fileViewerContent &&
                    <FileViewerModal
                        onClose={() => {
                            this.setState({ isFileViewerModal: false, fileViewerContent: null });
                        }}
                        data={this.state.fileViewerContent}
                        isFinancialDocument={false}
                    ></FileViewerModal>
                }
                <CommonPopUpModal
                    title={this.props.t("transactions_page.po_content.title")}
                    onClose={e => this.props.onClose()}
                    promptOnClose={false}
                >
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="purchaseorder">{this.props.t("grid_view_header_labels.po_number")}</label>
                                {
                                    this.props.isReadOnly ?
                                        <div className="form-value">{this.state.purchaseorder}</div>
                                        : <input name="purchaseorder" className="form-control"

                                            onChange={(ev_) => {
                                                this.setState({
                                                    purchaseorder: ev_.target.value
                                                });
                                            }}
                                        ></input>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="taxamount">{this.props.t("grid_view_header_labels.po_amount")}</label>
                                {
                                    this.props.isReadOnly ?
                                        <div className="form-value">{dollarFormatter.format(this.state.purchaseamount)}</div>
                                        : <input name="purchaseamount" className="form-control" type="text" style={{ textAlign: "right" }}
                                            placeholder="$0.00"
                                            value={this.state.purchaseamount}
                                            onChange={(ev_) => {
                                                this.setState({
                                                    purchaseamount: this._formatDollars(ev_.target.value)
                                                });
                                            }}
                                        ></input>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <label>{this.props.t("transactions_page.po_content.purchase_order_file")}</label>
                            <Dropzone
                                className="customDropzone"
                                disabled={this.props.isReadOnly}
                                disabledClassName={this.props.isReadOnly? "disabledDropzone" :""}
                                onDrop={doc =>
                                    this.onDropFinancialDoc(
                                        "Purchase_Documents",
                                        doc
                                    )
                                }
                            >
                                {this.state.purchaseDocuments.length
                                    ? <div>
                                        {
                                            this.getDropBoxContent(this.state.purchaseDocuments)
                                        }
                                    </div>
                                    : (this.props.isReadOnly ?
                                        <div>{this.props.t("documents_not_found_msg")}</div>
                                        : <div>{this.props.t("transactions_page.po_content.drop_file_for_po")}</div>)
                                }
                            </Dropzone>
                        </div>
                        <div className="col-md-6">
                        <label>{this.props.t("supporting_documents_label")}</label>
                            <Dropzone
                                className="customDropzone"
                                disabled={this.props.isReadOnly}
                                disabledClassName={this.props.isReadOnly? "disabledDropzone" :""}
                                onDrop={doc =>
                                    this.onDropFinancialDoc(
                                        "Purchase_Documents",
                                        doc
                                    )
                                }
                            >
                                {this.state.otherDocuments.length
                                    ? <div>
                                        {
                                            this.getDropBoxContent(this.state.otherDocuments)
                                        }
                                    </div>
                                    : (this.props.isReadOnly ?
                                        <div>{this.props.t("documents_not_found_msg")}</div>
                                        : <div>{this.props.t("transactions_page.po_content.drop_file_for_po")}</div>)
                                }
                            </Dropzone>
                        </div>
                        {/* <div className="col-md-6 dropbox-container">
                            <label>Tax Documents</label>
                            <Dropzone
                                disabled={this.props.isReadOnly}
                                onDrop={doc =>
                                    this.onDropFinancialDoc(
                                        "Tax_Document",
                                        doc
                                    )
                                }
                            >
                                {this.state.taxDocuments.length
                                    ? <div>
                                        {
                                            this.getDropBoxContent(this.state.taxDocuments)
                                        }
                                    </div>
                                    : (this.props.isReadOnly ?
                                        <div>No documents were uploaded</div>
                                        : <div>Drop the last 3 years of tax documents to upload</div>)
                                }
                            </Dropzone>
                        </div> */}
                    </div>
                    <div className="row">
                        {this.props.isReadOnly ?
                            <div>  </div> : <div className="col-md-12 dropbox-container">
                                Please upload aging report and financial statements in your <a className="checkbox-termsColorChange" href='/document' target="_blank" onClick={() => {
                                    this.user.setCurrentUserType("FinancialStatements");
                                }}>&nbsp;Document Page</a></div>}
                    </div>
                    {
                        !this.props.isReadOnly && (
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <button
                                        className="btn btn-primary btn-block mt-2"
                                        type="submit"
                                        disabled={(!this.state.purchaseorder || !this.state.purchaseamount || !this.state.purchaseDocuments.length)}
                                        onClick={() => {
                                            let purchaseOrder = this.state.purchaseorder;
                                            let purchaseAmount = this.state.purchaseamount;
                                            let taxId = this.state.taxId;
                                            let errorMessage = [];
                                            const duplicatePo = this.state.poLists.filter(po => po.documentId == purchaseOrder);
                                            if (duplicatePo.length > 0) {
                                                errorMessage.push(this.props.t("transactions_page.po_content.po_is_already_imported"));
                                            }
                                            if (!purchaseOrder) {
                                                errorMessage.push(this.props.t("transactions_page.po_content.po_number_invalid"));
                                            }
                                            if (!purchaseAmount) {
                                                errorMessage.push(this.props.t("transactions_page.po_content.valid_financing_volume"));
                                            }
                                            if (!taxId) {
                                                errorMessage.push(this.props.t("transactions_page.po_content.add_tax_id_profile"));
                                            }

                                            if (errorMessage.length > 0) {
                                                this.setState({
                                                    validationError: errorMessage
                                                });
                                            }
                                            else {
                                                let formData = new FormData();
                                                formData.append("taxId", this.state.taxId);
                                                formData.append("purchaseorder", this.state.purchaseorder);
                                                formData.append("purchaseamount", this.state.purchaseamount.replace(/[^0-9.]/g, ""));
                                                for (let file of this.state.purchaseDocuments) {
                                                    formData.append("PUR_DOC", file, file.name);
                                                }
                                                this.props.onSubmit(formData);
                                            }
                                        }}
                                    >{this.props.t("button_names.submit")}</button>
                                </div>
                            </div>
                        )
                    }

                </CommonPopUpModal>
            </>
        );
    }

    _formatDollars(text_) {
        if (text_ != "") {
            text_ = text_.replace(/[^0-9.]/g, "");
            let value = parseFloat(text_);
            if (!isNaN(value)) {
                return dollarFormatter.format(value);
            }
        }

        return "";
    }

    getDropBoxContent(files) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                return <div className="d-flex" key={d.filename}><a href={""} className="mr-2" onClick={(e)=>{
                    e.preventDefault();
                    this.setState({
                        isFileViewerModal:true,
                        isFinancialDocument:false,
                        fileViewerContent:d
                    })
                }}>{convertUTF8String(d.filename)}</a>
                <FileMetaDataPopover data={d} /></div>
            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    onDropFinancialDoc(type, documents) {
        let existingDocuments = [];
        switch (type) {
            case "Purchase_Documents":
                existingDocuments = this.state.purchaseDocuments;
                if (this.user.currentUserType() == "POFinancing") {
                    this.setState({ purchaseDocuments: documents });
                } else {
                    existingDocuments = existingDocuments.concat(documents);
                    this.setState({ purchaseDocuments: existingDocuments });
                }


                break;
            case "Tax_Document":
                existingDocuments = this.state.taxDocuments;
                existingDocuments = existingDocuments.concat(documents);
                this.setState({ taxDocuments: existingDocuments });
                break;
        }
    }
}