import React, { Component } from 'react'
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import User from '../../../services/User';
import API from '../../../services/API';
import Papa from "papaparse";
import { ReactDOM } from 'react';

const CsvViewer = ({htmlString}) => {
    return (<>
        <iframe id="fileViewer" name="fileViewer" className="embed-responsive-item" style={{background:"white"}} srcDoc={htmlString}></iframe>
    </>);
}

export default CsvViewer;