// Author Rajesh Rajendran
import React, { useRef, useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from 'styled-components';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '@mui/material/Tooltip';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

// Utility function to generate steps
export const generateSteps = (statusCounts = {}, stepTitles = [], statusToInclude = [], statuRequiredAction = [], statusRequiredDropdown = [], dropdownItems = {}) => {
    return stepTitles.map((title, index) => {
        const status = statusToInclude[index];
        const count = statusCounts[status] || 0;
        return {
            status: status,
            counts: count,
            title,
            statuRequiredAction,
            statusRequiredDropdown,
            dropdownItems
        };
    });
};

// Utility function to calculate status counts
export const calculateStatusCounts = (data, statusUpto, countsFor) => {
    const counts = {};
    let totalCount = 0;

    // Initialize counts for all statuses up to statusUpto
    for (let i = 0; i <= statusUpto; i++) {
        counts[i] = 0;
    }

    // Initialize counts for the subdivided statuses
    counts[21] = 0;
    counts[22] = 0;
    counts[33] = 0;
    counts[61] = 0;
    counts[62] = 0;
    counts[63] = 0;
    counts[77] = 0;
    counts[78] = 0;
    counts[64] = 0;
    counts[101] = 0;

    data.forEach(item => {
        if (item.status === 6 && (countsFor == "FR" || countsFor == 'F_OPR')) {
            counts[6]++; // Always increment count for status 6

            // Check conditions for further subdivisions
            if (item.contracts && item.contracts.length && !item.contracts[0].fundingAggrement) {
                counts[61]++;
            } else if (item.contracts && item.contracts.length && !item.contracts[0].signedAggrements) {
                counts[62]++;
            } else counts[64]++
        } else if (item.status === 2 && countsFor == "F_CON") {
            counts[2]++; // Always increment count for status 2
            if (!item.fundingAggrement && !item.signedAggrements) {
                counts[21]++
            } else if (item.fundingAggrement && !item.signedAggrements) {
                counts[22]++
            }
        } else if (item.status === 3 && countsFor == 'F_OPR') {
            if (!item.isDeclined) {
                counts[3]++
            }
        } else if (item.status === 7 && countsFor == 'F_OPR') {
            counts[7]++ // Always increment count for status 7
            if (!item.isPreFundingRequest) {
                counts[77]++
            } else { counts[78]++ }
        } else if (item.status === 7 && countsFor == 'FR') {
            if (!item.isPreFundingRequest) {
                counts[77]++
            } else { counts[78]++ }
        }
        else {
            if (counts[item.status] === undefined) {
                counts[item.status] = 0;
            }
            counts[item.status]++;
        }
        totalCount++;
    });

    if (countsFor == "FR" || countsFor == 'F_OPR') {
        counts[63] = (counts[64] || 0) + (counts[11] || 0);
        counts[7] = (counts[77] || 0) + (counts[78] || 0) + (counts[5] || 0);;
    } if (countsFor == "SUPP_INV" || countsFor == "SUPP_SC" || countsFor == "BUY_INV") {
        counts[20]= (counts[0] || 0) + (counts[2] || 0);
        counts[23] = (counts[2] || 0);
        counts[101] = (counts[100] || 0);
        counts[100] = (counts[100] || 0) + (counts[3] || 0);       
    } if (countsFor == 'F_OPR') {
        counts[79] = (counts[77] || 0) + (counts[5] || 0) + (counts[78] || 0) + (counts[8] || 0);
        
    }

    // Store the total count of all items in the special -1 key
    counts[-1] = totalCount;

    return counts;
};


// Utility function to calculate counts for specific status texts and total
export const calculateStatusCountsByText = (data) => {
    const counts = {
        "0": 0,  // Submitted counts
        "1": 0,  // Awaiting Submission counts
        "2": 0,
        "99": 0,
        "100": 0,
        "-1": 0  // Total counts
    };

    data.forEach(item => {
        if (item.statusText === "Submitted") {
            counts["0"]++;
        } else if (item.statusText === "Awaiting Submission") {
            counts["1"]++;
        } else if (item.statusText === "In Funding") {
            counts["99"]++;
        } else if (item.statusText === "Archived") {
            counts["100"]++;
        }
        counts["-1"]++;
    });

    return counts;
};

/**
 * StatusStepper Component

 * This component renders a stepper with clickable steps and scrollable content.
 * It also displays left and right arrows for navigation when the content overflows.
 * The active step is highlighted, and icons are conditionally rendered based on step status and counts.
 * 
 */
export const StatusStepper = ({ activeStep, steps, statusCounts, handleStepClick }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [stepsPerPage, setStepsPerPage] = useState(9);
    const [hoveredInfoIcon, setHoveredInfoIcon] = useState(null);
    const stepperRef = useRef(null);
    const { t, i18n } = useTranslation();

    const updateStepsPerPage = () => {
        if (stepperRef.current) {
            const containerWidth = stepperRef.current.offsetWidth;
            const defaultStepsPerPage = 9;
            const calculatedStepsPerPage = Math.floor(containerWidth / 150);
            setStepsPerPage(calculatedStepsPerPage > 0 ? calculatedStepsPerPage : defaultStepsPerPage);
        }
    };

    useEffect(() => {
        updateStepsPerPage();
        const handleResize = () => {
            updateStepsPerPage();
            // Ensure that on resize, adjust currentPage if needed
            setCurrentPage(prevPage => Math.min(prevPage, totalPages - 1));
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const totalPages = Math.ceil(steps.length / stepsPerPage);

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 0));
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages - 1));
    };

    const isActiveStep = (activeStep, index, steps) => {
        return activeStep === "" && index !== steps.length - 1;
    };

    const getWarningIconColor = (status, activeStep, index, statusRequiredDropdown, steps) => {
        if (isActiveStep(activeStep, index, steps)) {
            return 'rgb(255, 180, 91)'; // Orange color for active warning icon
        }
        return activeStep !== null && activeStep !== index ? 'gray' : 'rgb(255, 180, 91)'; 
    };

    const getInfoIconColor = (status, activeStep, index, statusRequiredDropdown, steps) => {
        if (isActiveStep(activeStep, index, steps)) {
            return '#255F79'; // Blue color for active info icon
        }
        return activeStep !== null && activeStep !== index ? 'gray' : '#255F79'; 
    };

    const getOpacity = (activeStep, index, steps) => {
        if (isActiveStep(activeStep, index, steps)) {
            return '';
        }
        return activeStep !== null && activeStep !== index ? '0.5' : '';
    };
    
    const getStatusIcon = (status, Counts, activeStep, index, statuRequiredAction, statusRequiredDropdown) => {
        const warningIconStyle = {
            height: "auto",
            width: '18px',
            marginLeft: "5px",
            color: getWarningIconColor(status, activeStep, index, statusRequiredDropdown, steps),
            opacity: getOpacity(activeStep, index, steps)
            
        };

        const infoIconStyle = {
            height: "auto",
            width: '18px',
            marginLeft: "5px",
            color: getInfoIconColor(status, activeStep, index, statusRequiredDropdown, steps),
            opacity: getOpacity(activeStep, index, steps)
            
        };

        const icons = [];

        if (Counts > 0 && statuRequiredAction.includes(status)) {
            icons.push(<Tooltip key="warning" title={t("action_required")}><WarningAmberOutlinedIcon style={warningIconStyle} /></Tooltip>);
        }
        if (statusRequiredDropdown.includes(status)) {
            icons.push(
                <Tooltip key="info" >
                    <InfoOutlinedIcon 
                        style={infoIconStyle} 
                        onMouseEnter={() => setHoveredInfoIcon(index)} 
                        onMouseLeave={() => setHoveredInfoIcon(null)} 
                    />
                </Tooltip>
            );
        }
    
        return icons.length > 0 ? icons : null;
    };

    const dropDownArray = (status, dropdownItemsMap) => {

        const dropdownItems = dropdownItemsMap[status] || [];
        const itemsWithCounts = dropdownItems.map(item => ({
            label: item.label,
            count: statusCounts[item.countKey] || 0
        }));

        return itemsWithCounts;
    };

    const renderDropdownMenu = (step) => (
        <div className="dropdown-menu1">
            <ul>
                {dropDownArray(step.status, step.dropdownItems).map((item, i) => (
                    <li key={i}>
                        <span className="list-label">{item.label}</span>
                        <span className="badge">{item.count}</span>
                    </li>
                ))}
            </ul>
        </div>
    );

    return (
        <div className="stepper-container" ref={stepperRef}>
            {currentPage !== 0 && (
                <div className="arrow-container">
                    <button onClick={handlePrevPage} disabled={currentPage === 0}><ArrowBackIosIcon /></button>
                </div>
            )}
            <div className="centerAlign">
            <StepperContent className="breadcrumb">
                {steps.slice(currentPage * stepsPerPage, (currentPage + 1) * stepsPerPage).map((step, index) => (
                    <div
                        key={index}
                        onClick={() => handleStepClick(index, step.status)}
                        className={`
                            ${isActiveStep(activeStep, index, steps) ? 'initial_label_col' :
                                (activeStep !== null && activeStep !== index) ? 'inactive_label_col' :
                                    'active_label_col'}
                        `}
                    >
                        <span className="breadcrumb__inner">
                            <span className="breadcrumb__title">
                                <span className={`
                                    ${isActiveStep(activeStep, index, steps) ? 'initial_title_col' :
                                    (activeStep !== null && activeStep !== index) ? 'inactive_title_col' :
                                        'active_title_col'}
                                `}> {step.title}
                                </span>
                            </span>
                        </span>
                        <span className="badge-wrapper">
                            <span className={`custom-badge ${isActiveStep(activeStep, index, steps) ? "initial-badge": 
                                (activeStep !== null && activeStep !== index) ? "inactive-badge": "active-badge" }`} >
                                {step.counts}
                            </span>
                            <span>
                                {getStatusIcon(step.status, step.counts, activeStep, index, step.statuRequiredAction, step.statusRequiredDropdown)}
                                {hoveredInfoIcon === index && step.statusRequiredDropdown.includes(step.status) && renderDropdownMenu(step)}
                            </span>
                        </span>
                    </div>
                ))}
            </StepperContent>
            </div>
            {currentPage !== totalPages - 1 && (
                <div className="arrow-container ml-2">
                    <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}><ArrowForwardIosIcon /></button>
                </div>
            )}
        </div>
    );
};

const StepperContent = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

