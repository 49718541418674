import React, { Component } from 'react';
import DashboardWidgetContainer from './DashboardWidgetContainer';
import API from '../../../services/API';
import { dollarFormatter } from '../../../services/Utilities';
import { compareRole,enumRole } from '../../../configs/GlobalConfig';
import User from '../../../services/User';

 export default class TopBuyersWidget extends Component {

    api = new API();
    user =new User();

    constructor(props_) {
        super(props_);

        this.state = {
            buyers: {},
            allBuyers:{},
            isViewMore: true,
        };
    }

    componentDidMount() {
        this.getInvoices();
    }

    async getInvoices() {
        let _userId= null;
        if(this.props && this.props.data && compareRole( this.user.currentUserRole(),enumRole.Funder)){
          _userId = this.props.data._id;
        }
        const invoicesResponse = await this.api.getalldocForDashboard(_userId);
        let customersDocs = invoicesResponse.invoices
            .filter(docs => docs.documentType != "TCF" && (docs.status!=99 && docs.status!=100))
            // .filter(docs=>!(new Date(docs.documentDueDate) < new Date()))
            .reduce((cus, docs) => {
                let customerName = docs.buyerName ? docs.buyerName : docs.invoice.buyerName;

                if (!cus[customerName]) {
                    cus[customerName] = 0;
                }
                cus[customerName] = (cus[customerName] ? cus[customerName] : 0) + docs.total;
                return cus;
            }, {});
        const customers = Object.entries(customersDocs)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 5)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        // let customers = Object.fromEntries(
        //     Object.entries(customersDocs).sort(([,a],[,b]) => b-a)
        // );
        const fullCustomers = Object.entries(customersDocs)
            .sort(([, a], [, b]) => b - a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        this.setState({ buyers: customers, allBuyers: fullCustomers });

    }

    render() {
        const{ buyers,allBuyers,isViewMore} = this.state;
        const customers=isViewMore?allBuyers:buyers;
        const customersCount= Object.keys(customers).length;
        return (
            <DashboardWidgetContainer title={this.props.t("dashboard_page.widget_titles.top_buyers")} count={customersCount} size="col-md-4">
                {Object.keys(customers).length < 1 && (
                    <div className="awaiting-text-color text-center" style={{ fontSize: 14, fontWeight: 600 }}>
                        {this.props.t("common_names.no_record")}
                    </div>
                )}
                {Object.keys(customers).length > 0 && (
                    <React.Fragment>
                        <table className="w-100">
                            {/* <tr>
                                <th>
                                    Customer Name
                                </th>
                                <th className="text-right">
                                    Invoice/PO Amount
                                </th>
                            </tr> */}
                            {Object.keys(customers).length > 0 &&
                                Object.keys(customers)
                                    .map(b =>
                                        <tr>
                                            <td className="py-2 normal-text-color">
                                                {b}
                                            </td>
                                            <td className="py-2 amount-color text-right" style={{fontWeight:600}}>
                                                {dollarFormatter.format(customers[b])}
                                            </td>
                                        </tr>
                                    )}

                        </table>
                        {/* <div className="row">
                            <div className="col-md-12 text-right">
                                <a className="anchorstyle" href="" onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ isViewMore: !isViewMore })
                                }
                                }>{isViewMore ? "View Less" : "View More"}</a>
                            </div>
                        </div> */}
                    </React.Fragment>
                )}
            </DashboardWidgetContainer>
        );
    }
}

