import React, { Component } from 'react';
import DashboardWidgetContainer from './DashboardWidgetContainer';
import API from '../../../services/API';
import { dollarFormatter } from '../../../services/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import CommonConfirmationModal  from '../Modals/CommonConfirmationModal';
import { withRouter,NavLink } from "react-router-dom";
import { compareRole, enumRole } from '../../../configs/GlobalConfig';
import User from '../../../services/User';

export default class TopBuyerswithHighestDBTWidget extends Component {

    api = new API();
    user =new User();
    constructor(props_) {
        super(props_);

        this.state = {
            buyers: [],
            isImportData:false,
            nonErpBuyers:[],
            erpBuyers:[],
            user: this.props && this.props.data?this.props.data:{},
            allavgCollectionPeriod:[],
            topAVgAcollectionPeriod:[],
            isViewMore: true
        };
    }

    componentDidMount() {
        if(this.props && this.props.data){
            this.getallBuyers();
        }
        else{
            this.getProfile()
            .then(() => {
                this.getallBuyers();
            });
        }
    }


    async getProfile() {
        const profileResponse = await this.getV2Profile();
        this.setState({ user: profileResponse.user });
    }

    async getV2Profile() {
        return await this.api.getV2Profile();
        // return await this.api.getProfile();
    }

    async getallBuyers() {
        let _userId= null;
        if(this.props && this.props.data && compareRole( this.user.currentUserRole(),enumRole.Funder)){
          _userId = this.props.data._id;
        }
        //const nonErpBuyers=(await this.api.getBuyers()).users.map(val=>({...val,isERP:false}));
        const erpBuyersResponse = (await this.api.getERPBuyers(_userId)).map(val=>({...val,isERP:true}));
        this.setState({ erpBuyers:erpBuyersResponse });
        this.getCustomerDBT();
    }
       
    async getCustomerDBT(){
        let _userId= null;
        if(this.props && this.props.data && compareRole( this.user.currentUserRole(),enumRole.Funder)){
          _userId = this.props.data._id;
        }
        let averageCollectionPeriod=(await this.api.getallErpAverageCollectionPeriod(_userId)).filter(avg=>avg.customerId);
        averageCollectionPeriod=await Promise.all( averageCollectionPeriod.map(async (val)=>{
            val.avgCollectionPeriod=Math.round(val.avgCollectionPeriod);
            // if(this.state.user.accountingInformation && this.state.user.accountingInformation=="quickbooks"){
            //     val.avgCollectionPeriod=Math.round(val.avgCollectionPeriod)
            // }
            // else {
            //     const txVolume=await  this.api.getTransactionVolume(val.supplierId,val.customerId);
            //     val.avgCollectionPeriod=Math.round(val.avgCollectionPeriod)-(txVolume?this.getTermName(txVolume.paymentTerm?txVolume.paymentTerm:"NET30"):30);
            // }
            return val;
        }));
        const allavgCollectionPeriod=averageCollectionPeriod.sort(function(a, b){return b.avgCollectionPeriod- a.avgCollectionPeriod});
        const topAVgAcollectionPeriod=averageCollectionPeriod.sort(function(a, b){return b.avgCollectionPeriod- a.avgCollectionPeriod})
        .slice(0,5);

        this.setState({
            allavgCollectionPeriod:allavgCollectionPeriod,
            topAVgAcollectionPeriod: topAVgAcollectionPeriod
        })
    }


    getTermName(net) {
        switch (net) {
            case "NET30":
            case "Net 30":
                return 30;
            case "NET45":
            case "Net 45":
                return 45;
            case "NET60":
            case "Net 60":
                return 60;
            case "NET90":
            case "Net 90":
                return 90;
            case "NET120":
            case "Net 120":
                return 120;
            case "NET180":
            case "Net 180":
                return 180;
        }
        return 30;
    }

    render() {
        const{ allavgCollectionPeriod,topAVgAcollectionPeriod,isViewMore} = this.state;
        const customers=isViewMore?allavgCollectionPeriod:topAVgAcollectionPeriod;
        const {t}=this.props;
        return (
            <React.Fragment>
                {this.state.isImportData && <CommonConfirmationModal
                title={t("dashboard_page.widget_titles.late_customers")}
                    
                    submitText="Ok"
                    onSubmit={() => {
                        this.getCustomerDBT(this.state.erpBuyers)
                    }}
                    onClose={() => { this.setState({ isImportData: false }) }}
                >
                    {t("dbt_sync_msg")}
                </CommonConfirmationModal>}
                <DashboardWidgetContainer  title={t("dashboard_page.widget_titles.late_customers")} size="col-md-4"
                    // titleContent={
                    //     <div className="p-3 d-inline-block"
                    //         style={{ float: "right", cursor: "pointer" }}
                    //         onClick={() => {
                    //             this.setState({ isImportData: true })
                    //         }}
                    //     >
                    //         <FontAwesomeIcon icon={faSyncAlt} />
                    //     </div>
                    // }
                    >
                    {(customers.length < 1 && this.state.user.accountingInformation=="erpinfo" && compareRole(this.user.currentUserRole(),enumRole.Supplier)) && (
                        <div className="awaiting-text-color text-center" style={{ fontSize: 14, fontWeight: 600 }}>
                            {t("please_connect_erp_msg")} <NavLink  style={{ color: "#930000" }} className="anchorstyle" to="/profile/erpInformation"> Profile page</NavLink>
                        </div>
                    )}
                    {(customers.length < 1 && this.state.user.accountingInformation=="quickbooks" &&  compareRole(this.user.currentUserRole(),enumRole.Supplier)) && (
                        <div className="awaiting-text-color text-center" style={{ fontSize: 14, fontWeight: 600 }}>
                           {t("please_connect_qbo_msg")} <NavLink style={{ color: "#930000" }} className="anchorstyle" to="/profile/erpInformation"> Profile page</NavLink>
                        </div>
                    )}
                    {(customers.length < 1 && compareRole(this.user.currentUserRole(),enumRole.Funder)) &&(
                        <>
                         <div className="awaiting-text-color text-center" style={{ fontSize: 14, fontWeight: 600 }}>
                           {t("common_names.no_record")}
                        </div>
                        </>
                    )}
                    {customers.length > 0 && (
                        <React.Fragment>
                            <table className="w-100">
                                {/* <tr>
                                    <th>
                                        Customer Name
                                    </th>
                                    <th className="text-right">
                                        Days Beyond Term
                                    </th>
                                </tr> */}
                                {customers.length > 0 &&
                                   customers
                                        .map(b =>
                                            <tr>
                                                <td className="py-2 normal-text-color">
                                                    {b.customerName}
                                                </td>
                                                <td className="py-2 amount-color text-center"  style={{fontWeight:600}}>
                                                    {b.avgCollectionPeriod} days
                                                </td>
                                            </tr>
                                        )}

                            </table>
                            {/* <div className="row">
                                <div className="col-md-12 text-right">
                                    <a className="anchorstyle" href="" onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({ isViewMore: !isViewMore })
                                    }
                                    }>{isViewMore ? "View Less" : "View More"}</a>
                                </div>
                            </div> */}
                        </React.Fragment>
                    )}
                </DashboardWidgetContainer>
            </React.Fragment>
        );
    }
}

