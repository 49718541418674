export default class NotificationService {
    static _id = [];
    static _isMessageModal=false;

    static emit = (id_) => {
        NotificationService._id.splice(NotificationService._id.indexOf(id_),1); 
    }

    static subscribe = (id_) => {
        NotificationService._id.push(id_);
    }
}
