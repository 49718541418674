import React, { Component } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faExclamationTriangle, faSortUp, faSortDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import AppState from '../../../../contexts/AppState/AppState';
import AppProvider from '../../../../contexts/AppState/AppStateProvider';
import API,{API_ENDPOINT_SUPPLIER} from '../../../../services/API';
import { InvoiceModal } from "./InvoiceModal";
import User from "../../../../services/User";
import FullSpinner from "../../../common/Spinner/FullSpinner";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import BuyerSubmitInvoiceModal from './BuyerSubmitInvoiceModal';
import { getAllFinancialDocIsPresent } from "../../../../services/Utilities";
import DataGrid from '../../../common/DataGrid/DataGrid';
import DataGridFilter from '../../../common/DataGrid/DataGridFilter';
import { dollarFormatter, dateFormatter,localDateFormat ,addDaysInSufixForPaymentTerm} from '../../../../services/Utilities';
import ViewInvoiceModal from "./ViewInvoiceModal";
import CommonCreateFundingRequestModal from "../../../common/Modals/CommonCreateFundingRequestModal";
import { NavLink} from "react-router-dom";
import FileViewerModal from '../../../common/FileViewerModal/FileViewerModal';
import NotificationService from '../../../../services/NotificationService';
import { generateSteps, calculateStatusCounts, StatusStepper } from '../../../../services/StatusStepperService';

export default class BuyerInvoiceList extends Component {
    user = new User();
    api = new API();

    _columnsSpecification = [
        {
            key: "supplierName",
            name: this.props.t("grid_view_header_labels.supplier_name")
        },
        {
            key: "documentId",
            name: this.props.t("grid_view_header_labels.invoice_number"),
            eventType: "view"
        },
        {
            key: "total",
            name: this.props.t("grid_view_header_labels.invoice_amount"),
            textAlignment: "right",
            contentProvider: (datum_) => {
                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>;
            }
        },
        {
            key: "documentDate",
            name: this.props.t("grid_view_header_labels.invoice_date"),
            contentProvider: (datum_) => {
                return localDateFormat(datum_.documentDate);
            }
        },
        {
            key: "documentDueDate",
            name: this.props.t("grid_view_header_labels.due_date"),
            contentProvider: (datum_) => {
                return `${localDateFormat(datum_.documentDueDate)} (${addDaysInSufixForPaymentTerm(datum_.invoice.net)})`;
            }
        },
        {
            key: "status",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{this.props.t("status_label.awaiting_submission")}</div>;
                    case 1:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{this.props.t("status_label.awaiting_approve_reject")}</div>;
                    case 2:
                        return <div className="progress-text-color">{this.props.t("status_label.buyer_approved")}</div>;
                    case 3:
                        return <div className="archived-text-color">{this.props.t("status_label.buyer_rejected")}</div>;
                    case 99:
                        return <div className="progress-text-color">{this.props.t("status_label.in_funding")}</div>;
                    case 100:
                        return <div className="archived-text-color">{this.props.t("status_label.archived")}</div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
        {
            key: "action",
            name: "Type/Request",
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div><button className="submit-invoice-button"
                        >{this.props.t("status_label.submit_invoice")}</button></div>;

                    case 1:
                        return this.props.t("status_label.buyer_approval");
                    case 2:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>{this.props.t("status_label.open")}</div>;
                    case 3:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>{this.props.t("status_label.archived")}</div>;
                    case 99:
                    case 101:
                        return <div style={{ color: "#5F9FBC" }}>{datum_.fundingReqId}</div>;
                    case 100:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>{this.props.t("status_label.archived")}</div>;
                    default:
                        return this.props.t("status_label.unknown");
                }
            },
            eventType: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return "SubmitInvoice";
                    case 1:
                        return "";
                    case 2:
                        return "open";
                    case 3:
                    case 99:
                    case 100:
                        return "";
                    default:
                        return "";
                }
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loadingText: "",
            allInvoices: [],
            invoices: [],
            CFR: [],
            selectedInvoice: "",
            isAllCheckboxEnabled: true,
            customers: [],
            isViewPopupOpen: false,
            filtersApplied: {},
            isResetFilterEnabled:false,
            isDeleteInvoice:false,
            isApprovePopupOpen:false,
            isrejectPopupOpen:false,
            isFileViewerModal:false,
            isFinancialDocument:false,
            fileViewerContent: null,
            steps: "",
            activeStep: '',
            statusCounts: [],
        };
        this.getInvoices = this.getInvoices.bind(this);
    }
    componentDidMount() {
        this.getInvoices();
    }

    async getInvoices() {
      //  this.setState({ isLoading: true });
        const invoicesResponse = await this.api.getInvoices();
        this.setInvoices(invoicesResponse.invoices);
       // this.setState({ isLoading: false });

        // Calculate the counts for each status up to 'statusUpto'
        const countsFor = 'BUY_INV'
        const statusUpto = 12; //Define the maximum status value to be calculated
        const counts = calculateStatusCounts(invoicesResponse.invoices, statusUpto, countsFor);
        this.setState({ statusCounts: counts });

    }
    async setInvoices(invoices) {
        let isAllCheckbox = true;
        let suppliers = [];
        const invoice = invoices
            .map(inv => {
                if (inv.status != 100) {
                    let isRowSelect = true;
                    let isExistSupplier = suppliers.filter(b => b == inv.supplier.name);
                    if (isExistSupplier.length == 0) {
                        suppliers.push(inv.supplier.name);
                    }
                    if (inv.status == 2) {
                        isRowSelect = false;
                        isAllCheckbox = false;
                    }
                    inv.isRowSelect = isRowSelect;
                }

                if (inv.supplier && inv.supplier.name) {
                    inv.supplierName = inv.supplier.name;
                }
                else {
                    inv.supplierName = "";
                }

                switch (inv.status) {
                    case 0:
                        inv.statusText =  this.props.t("status_label.awaiting_submission");
                        break;
                    case 1:
                        inv.statusText = this.props.t("status_label.awaiting_approve_reject");
                        break;
                    case 2:
                        inv.statusText = this.props.t("status_label.buyer_approved");
                        break;
                    case 3:
                        inv.statusText = this.props.t("status_label.buyer_rejected");
                        break;
                    case 99:
                        inv.statusText = this.props.t("status_label.in_funding");
                        break;
                    case 100:
                        inv.statusText = this.props.t("status_label.archived");
                        break;
                    case 101:
                        inv.statusText = this.props.t("status_label.supplier_accepted_funding_offer");
                        break;
                    default:
                        inv.statusText = this.props.t("status_label.unknown");
                }

                return inv;
            });
        suppliers = suppliers.sort();
        this.setState({ allInvoices: invoice, isAllCheckboxEnabled: isAllCheckbox, customers: suppliers }, () => {
            this._resetFilter();
            this.onFilterChange();
        });
    }

    archived(e) {
        const { filteredInvoices, invoices } = this.state;
        const invoiceDocs = filteredInvoices.length > 0 ? filteredInvoices : invoices;
        if (e.target.checked) {
            const archivedInvoices = invoiceDocs.filter(inv => (inv.status == 100 || inv.status == 3));
            this.setState({ filteredInvoices: archivedInvoices, isAllCheckboxEnabled: true, isArchived: true });
        }
        else {
            // const archivedInvoices=invoices.filter(inv=>inv.status!=100);
            this.getInvoices();
        }
    }

    onFilterChange(status) {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }
        
        if (status === undefined && this.state.filtersApplied.status?.length) {
            status = this.state.filtersApplied.status[0].value;
        }

        let filteredInvoices = this.state.allInvoices.filter(invoice_ => {
            
            if (!this.state.filtersApplied.archived) {
                if (invoice_.status == 100 || invoice_.status == 3) {
                    invoice_.isRowSelect = true;
                    return false;
                }
            }

            if (this.state.filtersApplied.customers && this.state.filtersApplied.customers.length) {
                if (invoice_.supplierName != this.state.filtersApplied.customers[0].text) {
                    return false;
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length && status !==100) {
                if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 23)) {
                    if (![2].includes(invoice_.status)) {
                        return false;
                    }
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 100)) {
                    if (![3, 100].includes(invoice_.status)) {
                        return false;
                    }
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length && status !== 23 &&status !==100) {
                if (invoice_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                }
            }

            if (this.state.filtersApplied.invoiceDate &&
                this.state.filtersApplied.invoiceDate.length &&
                this.state.filtersApplied.invoiceDate[0].startDate) {
                let startDate = this.state.filtersApplied.invoiceDate[0].startDate;
                let endDate = this.state.filtersApplied.invoiceDate[0].endDate;
                endDate.setHours(23);
                endDate.setMinutes(59);
                let date = new Date(invoice_.documentDate);
                if (date < startDate || date > endDate) {
                    return false;
                }
            }

            if (filteredText) {
                if (!(invoice_.supplierName.toLowerCase().indexOf(filteredText) >= 0 ||
                    invoice_.documentId.toLowerCase().indexOf(filteredText) >= 0 ||
                    invoice_.statusText.toLowerCase().indexOf(filteredText) >= 0)) {
                    return false;
                }
            }

            return true;
        });

        this.setState({
            invoices: filteredInvoices
        }, () => {
            this._hasResetFilterEnabled();
            this.openNotificationActionModal();
        });
    }

    openNotificationActionModal=()=>{
        const _notificationIds=NotificationService._id;
        if(_notificationIds && _notificationIds.length==1){
            if(this.state.invoices && this.state.invoices.length>0){
                const _notificationFR=this.state.invoices.filter(f_=>f_.documentId==_notificationIds[0]);
                NotificationService.emit(_notificationIds[0]);
                if (_notificationFR && _notificationFR.length > 0) {
                    const selectedTCF = _notificationFR[0];
                    this.setState({
                        isViewPopupOpen: true,
                        selectedInvoice: selectedTCF,
                    });
                }
            }
        }
    }

    handleStepClick = (step, status) => {
        this.setState(prevState => {
            const isSameStep = prevState.activeStep === step;
            return {
                activeStep: isSameStep ? "" : step,
                filtersApplied: isSameStep
                    ? {
                        ...prevState.filtersApplied,
                        textFilter: "",
                        archived: false,
                        customers: [],
                        status: []
                    }
                    : {
                        ...prevState.filtersApplied,
                        status: [{ value: status }],
                        archived: status==3 || status==100? true : false,
                    }
            };
        }, () => {
            this.onFilterChange(status);
        });
    };

    generateStepData = () => {
        const { statusCounts } = this.state;

        const stepTitles = [
            this.props.t("stepper_labels.buy_inv.awaiting_approval"),
            this.props.t("stepper_labels.buy_inv.approved_rejected"),
            this.props.t("stepper_labels.buy_inv.in_funding"),
            this.props.t("stepper_labels.buy_inv.archived"),
        ];

        const dropdownItems = {
            100: [
                { label: this.props.t("stepper_labels.buy_sc.drop_down.archived"), countKey: '101' },
                { label: this.props.t("stepper_labels.buy_sc.drop_down.buyer_rejected"), countKey: '3' },
            ],
        };

        const statusToInclude = [1, 23, 99, 100]; // Status codes to include in the steps
        const statuRequiredAction = [0, 1]; // Status to show Action Icon
        const statusRequiredDropdown = [100];
        return generateSteps(statusCounts, stepTitles, statusToInclude, statuRequiredAction, statusRequiredDropdown, dropdownItems );
    }

    render() {
        const { customers, isViewPopupOpen,
            selectedInvoice, isDeleteInvoice, isLoading, isApprovePopupOpen, isrejectPopupOpen, activeStep } = this.state;
        const invoices = this.state.invoices;

        const steps = this.generateStepData(); 

        return (
            <AppProvider>
                <AppState.Consumer>
                    {appState => (
                        <React.Fragment>
                            <FullSpinner
                                isSpinning={isLoading}
                            />
                            {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                                onClose={() => {
                                    this.setState({ isFileViewerModal: false, fileViewerContent: null });
                                }}
                                data={this.state.fileViewerContent}
                                isFinancialDocument={false}
                            ></FileViewerModal>}
                            {( isViewPopupOpen  ||isDeleteInvoice || isApprovePopupOpen || isrejectPopupOpen) && (
                                <div className="terms-checkbox-container">
                                    {isViewPopupOpen && selectedInvoice && <ViewInvoiceModal
                                        isReadOnly={false}
                                        data={selectedInvoice}
                                        onClose={() => {
                                            this.setState({ isViewPopupOpen: false, selectedInvoice: null });
                                        }}
                                        t={this.props.t}
                                    />}
                                    {
                                        isApprovePopupOpen && selectedInvoice && (
                                            <CommonConfirmationModal
                                                title={this.props.t("approve_invoice")+": #" + selectedInvoice.documentId}
                                                submitText={this.props.t("button_names.approve")}
                                                onClose={() => {
                                                    this.setState({ isApprovePopupOpen: false, selectedInvoice: null });
                                                    this.getInvoices();
                                                }}
                                                onSubmit={() => {
                                                    const invoiceToApprove = {
                                                        id: selectedInvoice._id,
                                                        reject: false,
                                                    };
                                                    return this.api.docApproveInvoice(invoiceToApprove).then(r => {
                                                        this.getInvoices();
                                                        this.setState({ isApprovePopupOpen: false, selectedInvoice: null });
                                                    });
                                                }}
                                            >
                                                <div className='d-flex flex-column align-items-center'>

                                                    {
                                                        selectedInvoice.documentFiles &&
                                                        selectedInvoice.documentFiles.map((document_, index) => {
                                                            return <div className=""
                                                                key={index}>
                                                                <a key={index} onClick={(e)=>{
                                                                    e.preventDefault();
                                                                    this.setState({
                                                                        isFileViewerModal:true,
                                                                        isFinancialDocument:false,
                                                                        fileViewerContent:document_
                                                                    })
                                                                }} className="anchorstyle">{this.props.t("view_invoice_file")}</a>
                                                            </div>
                                                        })
                                                    }

                                                    <div>  {this.props.t("transactions_page.supply_chain.approve_supply_chain_msg")}</div>
                                                </div>
                                               
                                            </CommonConfirmationModal>
                                        )
                                    }
                                    {
                                        isrejectPopupOpen && selectedInvoice && (
                                            <CommonConfirmationModal
                                                title={this.props.t("reject_invoice")+": #" + selectedInvoice.documentId}
                                                submitText={this.props.t("button_names.reject")}
                                                onClose={() => {
                                                    this.setState({ isrejectPopupOpen: false, selectedInvoice: null });
                                                    this.getInvoices();
                                                }}
                                                onSubmit={() => {
                                                    const invoiceToReject = {
                                                        id: selectedInvoice._id,
                                                        reject: true,
                                                    };
                                                    return this.api.docRejectInvoice(invoiceToReject).then(r => {
                                                        this.getInvoices();
                                                        this.setState({ isrejectPopupOpen: false, selectedInvoice: null });
                                                    });
                                                }}
                                            >
                                                <div className='d-flex flex-column'>
                                                    
                                                        {
                                                            selectedInvoice.documentFiles &&
                                                            selectedInvoice.documentFiles.map((document_, index) => {
                                                                return <div className=""
                                                                    key={index}>
                                                                    <a key={index} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState({
                                                                            isFileViewerModal: true,
                                                                            isFinancialDocument: false,
                                                                            fileViewerContent: document_
                                                                        })
                                                                    }} className="anchorstyle">{this.props.t("view_invoice_file")}</a>
                                                                </div>
                                                            })
                                                        }
                                                    
                                                    <div> {this.props.t("transactions_page.supply_chain.reject_supply_chain_msg")}</div>
                                                </div>

                                              
                                            </CommonConfirmationModal>
                                        )
                                    }
                                    {
                                        this.state.isDeleteInvoice && (
                                            <CommonConfirmationModal
                                                title={this.props.t("delete_invoice")+": #" + this.state.selectedInvoice.documentId}
                                                submitText={this.props.t("button_names.delete")}
                                                onClose={() => {
                                                    this.getInvoices();
                                                    this.setState({ isDeleteInvoice: false, selectedInvoice: null });
                                                }}
                                                onSubmit={async () => {
                                                    // this.setState({ isLoading: true });
                                                    await this.api.deleteDocument(this.state.selectedInvoice);
                                                    // this.setState({ isLoading: false });
                                                }}
                                            >
                                                {this.props.t("delete_invoice_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }
                                </div>
                            )}
                            <StatusStepper
                                activeStep={activeStep}
                                steps={steps}
                                handleStepClick={this.handleStepClick}
                                statusCounts={this.state.statusCounts}
                            />
                            <DataGrid
                                title="Buyer Invoices"
                                emptydataLabel={this.props.t("no_invoice_found")}
                                data={invoices}
                                columns={this._columnsSpecification}
                                isNoPagination={true}
                                isRowsNonSelectable={true}
                                isGridSearch={true}
                                isNoMoreLink={false}
                                appliedFilter={this.state.filtersApplied}
                                resetFilters={() => this._resetFilter()}
                                searchText={(text_) => {
                                    this.state.filtersApplied.textFilter = text_;
                                    this.setState({
                                        filtersApplied: this.state.filtersApplied
                                    }, () => this.onFilterChange());
                                }}
                                popoverItems={[
                                    { key: "approveInvoice", label: this.props.t("approve_invoice") },
                                    { key: "rejectInvoice", label:  this.props.t("reject_invoice")  },
                                    { key: "View", label:  this.props.t("view_invoice")}
                                ]}
                                onPopoverClick={(key_, row_) => {
                                    if (key_ == "approveInvoice") {
                                        this.setState({
                                            isApprovePopupOpen: true,
                                            selectedInvoice: row_,
                                        });
                                    }
                                    else if (key_ == "rejectInvoice") {
                                        this.setState({
                                            isrejectPopupOpen: true,
                                            selectedInvoice: row_,
                                        });
                                    }
                                    else if (key_ == "View") {
                                        this.setState({
                                            isViewPopupOpen: true,
                                            selectedInvoice: row_
                                        });
                                    }
                                }}
                                filterComponent={
                                    <React.Fragment>
                                        <DataGridFilter
                                            label={this.props.t("grid_view_filter_names.supplier")}
                                            type="options"
                                            options={
                                                customers.map(cus => {
                                                    return ({ value: cus, text: cus });
                                                })
                                            }
                                            value={this.state.filtersApplied.customers}
                                            change={(filter_) => {
                                                this.state.filtersApplied.customers = filter_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => this.onFilterChange());
                                            }}
                                        />
                                        <DataGridFilter
                                            label={this.props.t("grid_view_filter_names.date_range")}
                                            type="date_range"
                                            value={this.state.filtersApplied.invoiceDate}
                                            change={(filter_) => {
                                                this.state.filtersApplied.invoiceDate = filter_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => this.onFilterChange());
                                            }}
                                        />
                                    </React.Fragment>
                                }
                                isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                                handleTableCellClick={(dataRow, type, index) => {
                                    if (type == "view" || type == "open") {
                                        this.setState({
                                            isViewPopupOpen: true,
                                            selectedInvoice: dataRow,
                                        });
                                    }
                                    else if (type == "SubmitInvoice") {

                                    }
                                }}
                                isResetFilterEnabled={this.state.isResetFilterEnabled}
                            />
                        </React.Fragment>
                    )}
                </AppState.Consumer>
            </AppProvider>
        );
    }

    _resetFilter = () => {
        this.state.filtersApplied.textFilter = "";
        this.state.filtersApplied.archived = false;
        this.state.filtersApplied.customers = [];
        this.state.filtersApplied.status = [];
        this.state.filtersApplied.invoiceDate = [{
            startDate: null,
            endDate: null,
            key: "selection"
        }];
        this.setState({
            filtersApplied: this.state.filtersApplied,
            activeStep:""
        }, () => this.onFilterChange());
    };

    _hasResetFilterEnabled = () => {
        if (this.state.filtersApplied.textFilter != "" || this.state.filtersApplied.archived || this.state.filtersApplied.customers.length > 0
            || this.state.filtersApplied.status.length > 0 || (this.state.filtersApplied.invoiceDate[0].startDate && this.state.filtersApplied.invoiceDate[0].endDate)) {
            this.setState({ isResetFilterEnabled: true });
        }
        else {
            this.setState({ isResetFilterEnabled: false });
        }
    };
}


