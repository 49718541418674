import React, { Component } from 'react'
import FullSpinner from '../../common/Spinner/FullSpinner';
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import { dollarFormatter } from '../../../services/Utilities';
import User from '../../../services/User';
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { trans } from '../../../services/localizationService';

const numberMask = createNumberMask({
    prefix: "$",
    suffix: "",
    allowDecimal: true
});

export default class UpdateCreditLimitModal extends Component {
    api = new API();
    user = new User();

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            creditLimit:{},
            isSubmitBtnEnable:false
        };
    }

    componentDidMount() {
        let _creditLimitValue= this.props.data.creditLimitForTradingPartner?this.props.data.creditLimitForTradingPartner:{};
        Object.keys(_creditLimitValue).forEach(tp_ => {
            _creditLimitValue[tp_].existingCreditLimitValue= _creditLimitValue[tp_].creditLimitValue;
        });
        this.setState({creditLimit:_creditLimitValue});
    }

    handleCreditLimit=(e,tp_)=>{
        const {creditLimit}=this.state;
        let _isSubmitBtnEnable=false;
        let _creditLimitValue =creditLimit;
        _creditLimitValue[tp_].creditLimitValue=Number(e.target.value.replace(/[^0-9.-]+/g,""));
        if( _creditLimitValue[tp_].creditLimitValue > _creditLimitValue[tp_].existingCreditLimitValue){
            _isSubmitBtnEnable=true;
        }
        this.setState({
            creditLimit:_creditLimitValue,isSubmitBtnEnable:_isSubmitBtnEnable
        })
    }

    validateCreditLimitValue=(tpId_)=>{
        const {creditLimit}=this.state;
        let _creditLimitValue= Number(creditLimit[tpId_].creditLimitValue.toString().replace(/[^0-9.-]+/g,""));
        if( _creditLimitValue >= creditLimit[tpId_].existingCreditLimitValue){
            return false;
        }
        else{
            return true;
        }
    }

    render() {
        let { 
            creditLimitForTradingPartner,
            _id,
            fundingContractId
        } = this.props.data;
        let {
            creditLimit
        } =this.state;
        return (
            <>

                <div className="modal fade show modal-large" id="updateCreditLimitModal" tabIndex="-1" role="dialog" aria-labelledby="updateCreditLimitModalLabel" aria-hidden="true" style={{ "display": "block", "zIndex": "99999" }}>
                    <div className="modal-dialog modal-large modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close agreement-close" data-dismiss="modal" aria-label="Close" style={{ cursor: "pointer" }} onClick={() => {
                                    this.props.onClose();
                                }}>
                                    <span aria-hidden="true"> &times;</span>
                                </button>
                                <h5 className='modal-title'>
                                    {trans("fundingContracts_page.updateCreditLimit_content.title")}: #{fundingContractId?fundingContractId : ""}
                                </h5>
                            </div>
                            <div className="modal-body flex-column">
                                {creditLimit && (
                                    <>
                                        <div className="row pb-4">
                                            {Object.keys(creditLimit).map((tp_, i) => {
                                                return (
                                                    <>
                                                        <div className='col-4'>
                                                            <label>{creditLimit[tp_].tradingPartnerName}</label>
                                                        </div>
                                                        <div className='col-8'>
                                                            <MaskedInput
                                                                name={tp_}
                                                                onChange={e => this.handleCreditLimit(e, tp_)}
                                                                mask={numberMask}
                                                                className="form-control"
                                                                placeholder="$ ..."
                                                                value={creditLimit[tp_].creditLimitValue}
                                                            />
                                                            {this.validateCreditLimitValue(tp_) && (
                                                                <div className="formErrors">
                                                                    {trans("fundingContracts_page.updateCreditLimit_content.credit_limit_validation")}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer justify-content-center">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.props.onClose} style={{ width: 150 }}>
                                    {trans("button_names.cancel")}
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{ width: 150 }}
                                    disabled={!this.state.isSubmitBtnEnable}
                                    onClick={async(e) => {
                                        let _creditLimitValue = this.state.creditLimit;
                                        Object.keys(_creditLimitValue).forEach(c_=>{
                                            if(c_.existingCreditLimitValue){
                                                delete c_.existingCreditLimitValue;
                                            }
                                        })
                                       await this.api.updateCreditLimitValue({
                                            _id: _id,
                                            creditLimit: _creditLimitValue
                                        });
                                        this.props.onClose();
                                    }}>
                                    {trans("button_names.update")}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>
        )
    }
}