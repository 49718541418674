import React, { Component } from 'react'

import InvoicesList from '../Documents/Invoices/InvoicesList';

//import {TaxCreditList} from '../taxcredit/TaxCreditList';
import PoList from '../Documents/Po/PoList';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import User from '../../../services/User';
import TaxcreditList from '../Documents/TaxCredit/TaxcreditList';
import Profile from "../../common/Profile/Profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faExclamationTriangle, faSortUp, faSortDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { AddModal } from '../../common/Modals/CommonConfirmationModal';
import {getAllFinancialDocIsPresent,isUSCountryUser} from '../../../services/Utilities';
import { NavLink ,withRouter } from 'react-router-dom';
import AssetBasedLendingList from './AssetBasedLending/AssetBasedLendingList';
import SupplierInvoiceList from './Invoices/SupplierInvoiceList';
import BuyerInvoiceList from './Invoices/BuyerInvoiceList';
import SupplierChainList from './SupplyChain/SupplierChainList';
import { compareRole } from '../../../configs/GlobalConfig';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

class Documents extends Component {
    user = new User();

    api = new API();

    constructor(props) {
        super(props);
        this.state = {
            activeDocumentTab: this.user.currentUserType(),
            isUpload: false,
            CFSselectedCount: 0,
            isCreateFundingRequest: false,
            missingFinDoc: "",
            taxId: null,
            user:null
        }
        this.upload=this.upload.bind(this);
        this.hasCreateFundingRequest=this.hasCreateFundingRequest.bind(this);
        this.createFundingRequest=this.createFundingRequest.bind(this);
    }
    componentDidMount() {
        document.title = `Transactions - LedgerFunding ${this.user.currentUserRole()}`;
        const { appState } = this.props;
        if (appState.state.isNavFromNotifications) {
            this.currentDocumentTab("InvoiceFinancing")
            appState.setState("isNavFromNotifications", false);
        }
        if (this.props.match.params.tab) {
            this.currentDocumentTab(this.props.match.params.tab);
        }
       // this.checkIfSupplierUploadedDocumentation();
    }

    componentWillMount() {
        this.getProfile();
    }

    async checkIfSupplierUploadedDocumentation() {
        const res = await getAllFinancialDocIsPresent(this.props.appState.state.currentUser.email)
        this.setState({
            missingFinDoc: res
        })
    }

    async getProfile() {
        const profileResponse = await this.api.getProfile();
        this.setState({
            taxId: profileResponse.user.taxId && profileResponse.user.taxId!="DUMMYTAXID"?profileResponse.user.taxId:"",
            user:profileResponse.user
        })
    }

    currentDocumentTab(_tabName) {
        switch (_tabName) {
            case "TaxCreditFinancing":
                this.user.setCurrentUserType(_tabName);
                this.setState({ activeDocumentTab: _tabName });
                break
            case "InvoiceFinancing":
                this.user.setCurrentUserType(_tabName);
                this.setState({ activeDocumentTab: _tabName });
                break
            case "POFinancing":
                this.user.setCurrentUserType(_tabName);
                this.setState({ activeDocumentTab: _tabName });
                break
            case "ABL":
                this.user.setCurrentUserType(_tabName);
                this.setState({activeDocumentTab:_tabName});
                break;
            case "SupplyChain":
                this.user.setCurrentUserType(_tabName);
                this.setState({ activeDocumentTab: _tabName });
                break;
        }
    }

    upload() {
        const { isUpload } = this.state;
        this.setState({ isUpload: !isUpload });
    }

    hasCreateFundingRequest(count) {
        this.setState({ CFSselectedCount: count });
    }

    createFundingRequest(){
        const {isCreateFundingRequest}=this.state;
        this.setState({isCreateFundingRequest:!isCreateFundingRequest});
    }

    enableCreateFundingRequestBtn=(currentTab_,role_)=>{
        if(currentTab_=="SupplyChain" && compareRole(role_,"buyer")){
            return true;
        }
        else if(currentTab_!="SupplyChain" && compareRole(role_,"supplier")){
            return true;
        }
        else{
            return false;
        }
    }

    render() {
        const {t}=this.props;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <div className="header-titlebar">
                            {/* {this.user.currentUserRole() == "supplier" && ( */}
                                <h3>{t("page_titles.transactions")}</h3>
                            {/* )} */}
                            {(this.user.currentUser().email) &&
                                <ul className="nav nav-tabs mt-2" id="productTab" role="tablist">
                                    <li className="nav-item">
                                        <a
                                            className={"nav-link" + (this.state.activeDocumentTab == "InvoiceFinancing" ? " active" : "")}
                                            id="invoicetab"
                                            data-toggle="tab"
                                            href="#invoicetab"
                                            role="tab"
                                            aria-controls="invoicetab"
                                            aria-selected="false"
                                            onClick={() => {
                                                this.currentDocumentTab("InvoiceFinancing")
                                            }}
                                        >
                                             {this.user.currentUserRole() === "supplier" ? t("transactions_page.navigation_tab_tiles.invoice_financing") : t("tab_names.buyer_transactions_invoice_tab")}
                                            
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            className={"nav-link" + (this.state.activeDocumentTab == "POFinancing" ? " active" : "")}
                                            id="potab"
                                            data-toggle="tab"
                                            href="#potab"
                                            role="tab"
                                            aria-controls="potab"
                                            aria-selected="false"
                                            onClick={() => {
                                                this.currentDocumentTab("POFinancing")
                                            }}
                                        >
                                            {this.user.currentUserRole() === "supplier" ? t("transactions_page.navigation_tab_tiles.po_financing") : t("tab_names.buyer_transactions_po")}

                                        </a>
                                    </li>
                                    {(compareRole(this.user.currentUserRole(),"buyer")  || compareRole(this.user.currentUserRole(),"supplier")) && (
                                        <li className="nav-item">
                                            <a
                                                className={"nav-link" + (this.state.activeDocumentTab == "SupplyChain" ? " active" : "")}
                                                id="potab"
                                                data-toggle="tab"
                                                href="#potab"
                                                role="tab"
                                                aria-controls="potab"
                                                aria-selected="false"
                                                onClick={() => {
                                                    this.currentDocumentTab("SupplyChain")
                                                }}
                                            >
                                                {this.user.currentUserRole() === "supplier" ? t("transactions_page.navigation_tab_tiles.supply_chain") : t("tab_names.buyer_transactions_supply_chain_tab")}

                                            </a>
                                        </li>
                                    )}

                                    {/* <li className="nav-item">
                                        <a
                                            className={"nav-link" + (this.state.activeDocumentTab == "ABL" ? " active" : "")}
                                            id="abl-tab"
                                            data-toggle="tab"
                                            href="#abl"
                                            role="tab"
                                            aria-controls="abl"
                                            aria-selected="true"
                                            onClick={() => {
                                                this.currentDocumentTab("ABL")
                                            }}
                                        >
                                            Asset-Based Lending
                                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        {/* <a
                                                className={"nav-link" + (this.state.activeDocumentTab == "TaxCreditFinancing" ? " active" : "")}
                                                id="tcf-tab"
                                                data-toggle="tab"
                                                href="#tcf"
                                                role="tab"
                                                aria-controls="tcf"
                                                aria-selected="true"
                                                onClick={() => {
                                                    this.currentDocumentTab("TaxCreditFinancing")
                                                }}
                                            >
                                                Tax Credit Pre-Funding
                                            </a> */}
                                    </li>
                                    {/* <li className="nav-item">
                                        <a
                                            className={"nav-link" + (this.state.activeDocumentTab=="FinancialStatements" ?" active" :"")}
                                            id="financialDoctab"
                                            data-toggle="tab"
                                            href="#financialDoctab"
                                            role="tab"
                                            aria-controls="financialDoctab"
                                            aria-selected="false"
                                            onClick={() => {
                                                this.currentDocumentTab("FinancialStatements")
                                            }}
                                        >
                                            Financial Statements
                                        </a>
                                    </li> */}
                                    {(this.user.currentUserRole() === "supplier" || this.state.activeDocumentTab == "POFinancing" || (this.user.currentUserRole() === "buyer" && this.state.activeDocumentTab=="SupplyChain")) && (
                                        <li className="nav-item ml-auto" >
                                            <button
                                                className="btn btn-primary"
                                                style={{ marginTop: -18, width: 150 }}
                                                onClick={() => {
                                                    this.upload();
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className="mr-2"
                                                    icon={faFileUpload}
                                                />{t("button_names.upload")}</button>
                                        </li>
                                    )}

                                </ul>
                            }
                        </div>
                        <div className="content-container documents-list">
                            {(this.user.currentUserRole() === "supplier") && (this.state.activeDocumentTab == "TaxCreditFinancing") && (
                                <TaxcreditList
                                appState={appState}
                                isUpload={this.state.isUpload}
                                upload={this.upload}
                                ></TaxcreditList>
                            )
                            }
                            {(this.state.activeDocumentTab == "InvoiceFinancing") && (
                                <>
                                    {this.user.currentUserRole() === "supplier" ? (
                                        <SupplierInvoiceList
                                            appState={appState}
                                            isUpload={this.state.isUpload}
                                            upload={this.upload}
                                            createFundingRequest={this.createFundingRequest}
                                            hasCreateFundingRequest={(count) => this.hasCreateFundingRequest(count)}
                                            isCreateFundingRequest={this.state.isCreateFundingRequest}
                                            t={t}></SupplierInvoiceList>
                                    ) :
                                        <BuyerInvoiceList
                                            appState={appState}
                                            t={t}
                                        ></BuyerInvoiceList>
                                    }
                                </>

                            )
                            }
                            {(this.user.currentUserRole() === "supplier" ||this.user.currentUserRole()==="buyer") && (this.state.activeDocumentTab == "POFinancing") && (
                                <PoList appState={appState}
                                isUpload={this.state.isUpload} 
                                upload={this.upload}
                                createFundingRequest={this.createFundingRequest}
                                hasCreateFundingRequest={(count)=>this.hasCreateFundingRequest(count)}
                                isCreateFundingRequest={this.state.isCreateFundingRequest}
                                t={t}></PoList>
                                
                            )
                            }
                            {((compareRole(this.user.currentUserRole(),"buyer")  || compareRole(this.user.currentUserRole(),"supplier")) && this.state.activeDocumentTab == "SupplyChain") && (
                                <SupplierChainList
                                    appState={appState}
                                    isUpload={this.state.isUpload}
                                    upload={this.upload}
                                    createFundingRequest={this.createFundingRequest}
                                    hasCreateFundingRequest={(count) => this.hasCreateFundingRequest(count)}
                                    isCreateFundingRequest={this.state.isCreateFundingRequest}
                                    t={t}
                                />
                            )}
                            {/* {(this.user.currentUserRole() === "supplier") && (this.state.activeDocumentTab == "ABL") && (
                                <AssetBasedLendingList appState={appState}
                                    isUpload={this.state.isUpload}
                                    upload={this.upload}
                                    createFundingRequest={this.createFundingRequest}
                                    hasCreateFundingRequest={(count) => this.hasCreateFundingRequest(count)}
                                    isCreateFundingRequest={this.state.isCreateFundingRequest}></AssetBasedLendingList>
                            )
                            } */}
                            {
                                (this.enableCreateFundingRequestBtn(this.state.activeDocumentTab,this.user.currentUserRole())) &&
                                <div style={{ textAlign: "right" }}>
                                    {this.state.CFSselectedCount >= 1 && (
                                        <div className="row-select-count">{this.state.CFSselectedCount} {t("rows_selected")} </div>
                                    )}
                                    <button
                                        className="btn btn-primary mt-3"
                                        style={{ width: 205, marginLeft: 25 }}
                                        disabled={this.state.CFSselectedCount == 0}
                                        onClick={() => {
                                            const userDetails=this.state.user;
                                            const isQuesDetails=userDetails.questionnaires && userDetails.questionnaires.length>0?true:false;
                                            if((isUSCountryUser(userDetails.country) && !this.state.taxId)){
                                                AddModal(t("incomplete_financial_information"),  <div>
                                                {t("please_enter_label")}  <b>{t("profile_page.financial_information_content.tax_id_label")}</b> {t("in_label")}   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">{t("dashboard_page.task_list_content.finanical_information")}</NavLink></div> , null, false);
                                            }
                                            else{
                                                if(isUSCountryUser(userDetails.country)){
                                                    const _taxID=userDetails.taxId?userDetails.taxId.replace(/[^0-9.]/g, ""):"";
                                                    if(_taxID.length==9){
                                                        this.setState({ isCreateFundingRequest: true });
                                                    }
                                                    else{
                                                        AddModal(t("incomplete_financial_information"),  <div>
                                                        {t("please_update_label")}  <b>{t("profile_page.financial_information_content.tax_id_label")}</b> {t("in_label")}   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">{t("dashboard_page.task_list_content.finanical_information")}</NavLink></div> , null, false);
                                                    }
                                                    
                                                }
                                                else {
                                                    this.setState({ isCreateFundingRequest: true });
                                                }
                                            }
                                            
                                            //TO-DO: Comented Questionnaires validation:

                                            // if((isUSCountryUser(userDetails.country) && !this.state.taxId) && !isQuesDetails){
                                            //     AddModal('Incomplete financial information',  <div>
                                            //     Please enter <b>Tax ID and Questionnaire</b> in   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div> , null, false);
                                            // }
                                            // else if((isUSCountryUser(userDetails.country) && !this.state.taxId)){
                                            //     AddModal('Incomplete financial information',  <div>
                                            //     Please enter <b>Tax ID</b> in   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div> , null, false);
                                            // }
                                            // else if(!isQuesDetails){
                                            //     AddModal('Incomplete financial information',  <div>
                                            //     Please update <b>Questionnaire</b> in   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div> , null, false);
                                            // }
                                            // else{
                                            //     if(userDetails.country=="United States" || userDetails.country== "United States of America"){
                                            //         const _taxID=userDetails.taxId?userDetails.taxId.replace(/[^0-9.]/g, ""):"";
                                            //         if(_taxID.length==9){
                                            //             this.setState({ isCreateFundingRequest: true });
                                            //         }
                                            //         else{
                                            //             AddModal('Incomplete financial information',  <div>
                                            //             Please update <b>Tax ID</b> in   <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div> , null, false);
                                            //         }
                                                    
                                            //     }
                                            //     else {
                                            //         this.setState({ isCreateFundingRequest: true });
                                            //     }
                                            // }
                                            
                                            // else if (this.state.missingFinDoc == "All document are present") {
                                            //     this.setState({ isCreateFundingRequest: true });
                                            // }
                                            // else if((this.state.missingFinDoc == "Financial Documents")) {
                                            //     AddModal('INCOMPLETE FINANCIAL INFORMATION',<div>Please upload financial statements in <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div>, null, false);
                                            // }
                                            // else if((this.state.missingFinDoc == "Aging Report")) {
                                            //     AddModal('Incomplete financial information', <div>Please upload an aging report in <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink> or fill in valid <NavLink  style={{ color: "#930000" }} className="anchorstyle" to="/profile/erpInformation">ERP Information.</NavLink></div>, null, false);
                                            // }
                                            // else if(this.state.missingFinDoc=="Bank Statement"){
                                            //     AddModal('INCOMPLETE FINANCIAL INFORMATION',<div>Please upload bank statement in <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div>, null, false);
                                            // }
                                            // else if(this.state.missingFinDoc=="Aging Report and Bank Statement"){
                                            //     AddModal('INCOMPLETE FINANCIAL INFORMATION',<div>Please upload aging report and bank statement in <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div>, null, false);
                                            // }
                                            // else if(this.state.missingFinDoc=="Financial Documents and Bank Statement"){
                                            //     AddModal('INCOMPLETE FINANCIAL INFORMATION',<div>Please upload financial statements and bank statement in <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div>, null, false);
                                            // }
                                            // else if(this.state.missingFinDoc=="Financial Documents and Aging Report"){
                                            //     AddModal('INCOMPLETE FINANCIAL INFORMATION',<div>Please upload financial statements and upload aging report in <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink></div>, null, false);
                                            // }
                                            // else{
                                            //     AddModal('INCOMPLETE FINANCIAL INFORMATION',<div>Please upload the financial documents,bank statement and aging report in <NavLink style={{ color: "#930000" }} target="blank" className="anchorstyle" to="/profile/financialInformation">Financial Information</NavLink>.</div>, null, false);
                                            // }
                                        }}
                                    >{t("button_names.create_funding_request")}</button>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}

export default compose(withRouter,withTranslation() )(Documents);
