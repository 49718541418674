import React from "react";
import { withTranslation } from 'react-i18next';

class QBResponse extends React.Component {

    componentDidMount() {
        window.opener.postMessage({
            source: "qb-window",
            payload: window.location.href
        });
    }

    render() {
        const {t}=this.props;
        return (
            <div className="container text-center">
                {t("authenticating")}
                {t("window_close_msg")}
            </div>
        );
    }
};
export default withTranslation()(QBResponse);