 import User from "../../../services/User";
 import { changeLanguage,trans } from "../../../services/localizationService";
 
 export const ChooseRoleModal = ({onClose}) => {
  const user=new User();
    return (
        <>
        <div className="alert-modal show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block", zIndex: 9999 }}>
          <div className="alert-modal-dialog alert-modal-dialog-centered" role="document">
            <div className="alert-modal-content">
              <div className="alert-modal-header">
                <h4 className="alert-modal-title" id="exampleModalLabel">{trans("log_in_as_a")}</h4>
              </div>
              <div className="alert-modal-body d-flex flex-column align-items-center text-center ">
                <div className="d-flex mt-2">
                  <button className="btn supplier-outline-btn mr-2" style={{width:100}} onClick={()=>{
                    user.setCurrentUserRole("supplier");
                    onClose("supplier");
                  }}>{trans("roles.supplier")}</button>
                  <button className="btn buyer-outline-btn mr-2"style={{width:100}} onClick={()=>{
                    user.setCurrentUserRole("buyer");
                    onClose("buyer");
                  }}>{trans("roles.buyer")}</button>
                  <button className="btn funder-outline-btn" style={{width:100}} onClick={()=>{
                    user.setCurrentUserRole("funder");
                    onClose("funder");
                  }}>{trans("roles.funder")}</button>
                </div>
              </div>
              <div className="alert-modal-footer justify-content-center">
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </>
    )

  }