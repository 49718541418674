export default class ArrayHelper {
    static findObject(array, key, value){
        if (array) {
            for (let item of array) {
                if (item[key] === value) {
                    return item;
                }
            }
        }

        return null;
    }

    static stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    static getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => this._descendingComparator(a, b, orderBy)
            : (a, b) => -this._descendingComparator(a, b, orderBy);
    }

    static getNumberInStringComparator(order, orderBy) {
        return (a, b) => {
            let result;
            let reA = /[^a-zA-Z]/g;
            let reN = /[^0-9]/g;

            let aValue = String(a[orderBy]);
            let bValue = String(b[orderBy]);

            let aA = aValue.toLocaleLowerCase().replace(reA, "");
            let bA = bValue.toLocaleLowerCase().replace(reA, "");

            if (aA === bA) {
                var aN = parseInt(aValue.toLocaleLowerCase().replace(reN, ""), 10);
                var bN = parseInt(bValue.toLocaleLowerCase().replace(reN, ""), 10);

                result = aN === bN ? 0 : aN > bN ? 1 : -1;
            } else {
                result = aA > bA ? 1 : -1;
            }

            return result * (order === 'desc' ? -1 : 1);
        }
    }

    static _descendingComparator(a, b, orderBy) {
        let aValue = a[orderBy];
        let bValue = b[orderBy];
        const parsed = Date.parse(aValue);

        if(!isNaN(parsed)) {
            // String is valid date.
            aValue=new Date(aValue);
            bValue=new Date(bValue);
        } else {
            // String is not a valid date.
            if (typeof(aValue) === "string") {
                aValue = aValue.toLocaleLowerCase();
            }
            if (typeof(bValue) === "string") {
                bValue = bValue.toLocaleLowerCase();
            }
        }

        if (bValue < aValue) {
            return -1;
        }
        if (bValue > aValue) {
            return 1;
        }
        return 0;
    }
}