import React, { Component } from 'react';
import moment from 'moment';
import { validateEmail,addDaysInSufixForPaymentTerm } from '../../../../services/Utilities';
import FullSpinner from '../../../common/Spinner/FullSpinner';
import AppState from '../../../../contexts/AppState/AppState';
import CommonConfirmationModal, {AddModal} from '../../../common/Modals/CommonConfirmationModal';
import API from '../../../../services/API';
import Popup from '../../../common/Auth/Popup';
import FileAttachmentModal from '../../../common/Modals/FileAttachmentModal';
import CommonPopUpModal from '../../../CommonPopUpModal/CommonPopUpModal';

export default class BuyerSubmitInvoiceModal extends Component {
    api = new API();
    _allowedFileExtensions = [".pdf", ".jpg", ".jpeg", ".png", ".tif", ".tiff", ".gif"];

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            isSendingInvite: false,
            buyers: [],
            erpbuyers: [],
            buyerInInvoice: "",
            buyerInInvoiceId: "",
            buyerIsEmailVerified: false,
            buyeremail: "",
            errMsg: '',
            isEmailAvailable: false,
            invoice: {
                id: 0,
                net: 30,
                paymentTerm: moment(new Date(this.props.data.documentDueDate)).format('MM-DD-YYYY'),
                buyer: 'n/a',
            },
            isAttachMentDocumentsModal:false,
            selectedFiles:[],
            isSignedCopy:false
            
        };
        this.getBuyers = this.getBuyers.bind(this);
        this.getERPBuyers = this.getERPBuyers.bind(this);
        this.changePaymentTerm = this.changePaymentTerm.bind(this);
        this.getInvoiceInformation = this.getInvoiceInformation.bind(this);
        this.changeBuyer = this.changeBuyer.bind(this);
        this.submitInvoice = this.submitInvoice.bind(this);
        this.sendInvite = this.sendInvite.bind(this);
    }

    async componentDidMount() {
        await this.getBuyers();
        await this.getERPBuyers();
        await this.getInvoiceInformation();
    }

    async getBuyers() {
        const buyersResponse = await this.api.getBuyers();
        this.setState({ buyers: buyersResponse.users });
    }
    async getERPBuyers() {
        const buyersResponse = await this.api.getERPBuyers();
        this.setState({ erpbuyers: buyersResponse });
    }
    
    async getInvoiceInformation() {
        let invoice = {};
        const { data } = this.props;
        if (data.invoice.customerDetails) {
            this.setState({
                buyeremail: data.invoice.customerDetails.email,
            })
        }
        invoice = {
            id: data._id,
            net: data.invoice.net,
            cusId: data.invoice.customerId,
            paymentTerm: moment(data.documentDueDate).format('MM-DD-YYYY'),
            buyer: 'n/a',
        };
        this.setState({
            buyerInInvoice: data.invoice.buyerName,
            buyerInInvoiceId: "",
            invoice,
        }, async () => {
            const buyerAppearingInList = this.state.buyers.filter(b_ => ((b_.name == this.state.buyerInInvoice) || (data && data.invoice && data.invoice.customerDetails && (data.invoice.customerDetails.email == b_.email))));
            if (buyerAppearingInList.length > 0) {
                invoice.buyer = buyerAppearingInList[0]._id;
                this.setState({ buyerInInvoiceId: buyerAppearingInList[0]._id, invoice });
                this.setState({ buyerIsEmailVerified: buyerAppearingInList[0].emailVerified })
            } else {
                const erpBuyer = this.state.erpbuyers.filter(b => b.name === this.state.buyerInInvoice);
                if (erpBuyer.length > 0 && erpBuyer[0].buyers.length > 0 && erpBuyer[0].buyers[0].email !== '') {
                    this.setState({ buyeremail: erpBuyer[0].buyers[0].email, isEmailAvailable: true });
                }
            }
        });
    }

    changePaymentTerm(e) {
        const { invoice } = this.state;
        const newInvoice = invoice;
        const invoiceDate = this.props.invoice.createdAt
        newInvoice.net = e.target.value;
        let netDays = 0;
        switch (e.target.value) {
            case '1':
                netDays = 30;
                break;
            case '2':
                netDays = 45;
                break;
            case '3':
                netDays = 60;
                break;
            case '4':
                netDays = 90;
                break;
            case '5':
                netDays = 120;
                break;
            case '6':
                netDays = 180;
                break;
            case '11':
                netDays = 15;
                break;
            default:
                netDays = 30;
        }
        let that = this;
        this.api.getDueDate(invoiceDate, netDays).then(res => {
            newInvoice.paymentTerm = res.dueDate;
            that.setState({ invoice: newInvoice });
        });
    }

    getInvoiceNumber(invoice) {
        if (invoice.documentId) {
            return invoice.documentId
        } else {
            return invoice._id
        }
    }

    changeBuyer(e) {
        const { invoice } = this.state;
        const newInvoice = invoice;
        newInvoice.buyer = e.target.value;
        this.setState({ invoice: newInvoice });
    }

    async submitInvoice() {
        this.setState({ isLoading: true });
        const { invoice } = this.state;
        let inv = {
            "id": invoice.id,
            "net": invoice.net,
            "paymentTerm": invoice.paymentTerm,
            "buyer": invoice.buyer
        }
        await this.api.docSignInvoice(inv);
        this.setState({ isLoading: false });
    }

    async sendInvite(e) {
        e.preventDefault();
        const { invoice, buyerInInvoice, buyeremail } = this.state;
        const customers = {
            "invoiceId": invoice.id,
            "customerId": invoice.cusId,
            "buyerName": buyerInInvoice,
            "buyerEmail": buyeremail,
            "flagFRMDoc": true
        }
        let that = this;

        if (validateEmail(customers.buyerEmail)) {
            this.setState({ isSendingInvite: true });
            this.api.sendInviteToBuyer(customers).then(res => {
                this.setState({ isSendingInvite: false });
                if (res.message === "Validation failed" && res.details.length > 0) {
                    that.setState({ errMsg: res.details[0].message });
                }
                else {
                    that.setState({ errMsg: '' });
                }
            });
        } else {
            AddModal(this.props.t("required_email_address"), this.props.t("kindly_enter_email_address"), null, false);
        }

    }
    handleAttachmentMessage =async (files) => {
        this.setState({isSignedCopy:true});
        const {data}=this.props;
        let formData = new FormData();
        if(data.documentFiles){
            delete data.documentFiles;
        }
        formData.append("invoiceDetails",JSON.stringify(data));
        for (let po_ of files) {
            formData.append("approvedCopies", po_);
        }
       await this.api.uploadBuyerSignedInvoice(formData);
       this.setState({isSignedCopy:false});

    }

    render() {
        const {data}=this.props;
        const { isLoading, isSendingInvite, isSignedCopy,buyers, buyerInInvoice, isEmailAvailable, buyeremail, errMsg, buyerInInvoiceId, buyerIsEmailVerified,invoice: { paymentTerm, buyer } } = this.state;
        let isBuyerInSystem = buyerInInvoice ? buyerInInvoice !== "" && buyerInInvoiceId !== ""  :  true ;
        const isInvalid = buyer === 'n/a';
        if(buyerIsEmailVerified){

        }else{
            isBuyerInSystem = false;
        }
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        {this.state.isAttachMentDocumentsModal && <FileAttachmentModal
                            headerTitle={this.props.t("transactions_page.submit_invoice.upload_buyer_approval")}
                            onSubmitFiles={this.handleAttachmentMessage}
                            fileExtensions={this._allowedFileExtensions}
                            data={[]}
                            fileExtensionAllowMsg={this.props.t("file_notes.pdf_and_image")}
                            onClose={() => {
                                this.setState({ isAttachMentDocumentsModal: false })
                            }}
                            buttonText={this.props.t("button_names.upload")}
                        />}
                        {!this.state.isAttachMentDocumentsModal &&
                            <CommonPopUpModal
                                size="small-medium"
                                title={`${this.props.t("transactions_page.submit_invoice.title")}: #${this.getInvoiceNumber(data)}`}
                                primaryBtn={this.props.t("button_names.submit")}
                                isSubmitBtn={isInvalid}
                                onSubmit={this.submitInvoice}
                                onClose={e => this.props.onClose()}
                                promptOnClose={false}
                                children={
                                    <>
                                        <React.Fragment>
                                            <div>
                                                <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("transactions_page.submit_invoice.invoice_submitted")} callback={e => this.props.onClose()} />
                                                <FullSpinner isSpinning={isSendingInvite} confirmationLabel={this.props.t("transactions_page.submit_invoice.invite_sent")} />
                                                <FullSpinner isSpinning={isSignedCopy} confirmationLabel={this.props.t("transactions_page.submit_invoice.uploaded_buyer_approval_documents")} callback={e => this.props.onClose()} />

                                                <form>
                                                    <div className="row m-0">
                                                        <div className="col-12 d-flex justify-content-between mb-2">
                                                            <div className="form-group">
                                                                <label className="form-label-style mb-2">{this.props.t("invoice_labels.payment_terms")}</label>
                                                                <label className="due-date-label">{addDaysInSufixForPaymentTerm(data.invoice.net || 30)}</label>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="form-label-style mb-2">{this.props.t("invoice_labels.due_date")}</label>
                                                                <label className="due-date-label">{paymentTerm}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            {isBuyerInSystem === true && (
                                                                <div className="form-group mt-2">
                                                                    <label className="form-label-style">{this.props.t("transactions_page.submit_invoice.select_buyer")}</label>
                                                                    <select id="buyerSelect" className="form-control" onChange={this.changeBuyer} disabled={buyerInInvoiceId !== ""}>
                                                                        <option value="n/a">{this.props.t("transactions_page.submit_invoice.select_from_below")}</option>
                                                                        {buyers.map(buyer => <option selected={buyerInInvoiceId !== null && buyerInInvoiceId === buyer._id} value={buyer._id}>{buyer.name}</option>)}
                                                                    </select>
                                                                </div>
                                                            )}
                                                            {isBuyerInSystem === false && (
                                                                <div className="submit-invoice-msg">
                                                                    <p>{buyerInInvoice} {this.props.t("user_not_in_the_system_msg")}</p>
                                                                    <div className="form-group">
                                                                        <label className="form-label-style">{this.props.t("grid_view_header_labels.buyer_email")}</label>
                                                                        <div className="input-group mb-3">
                                                                            <input type="email" value={buyeremail}
                                                                                onChange={e => this.setState({ buyeremail: e.target.value })}
                                                                                className="form-control" ></input>
                                                                            <div className="input-group-append">
                                                                                <button onClick={this.sendInvite} className="btn btn-sm btn-info" >{this.props.t("button_names.invite")}</button>
                                                                            </div>
                                                                        </div>
                                                                        {errMsg && errMsg !== '' && (
                                                                            <div>
                                                                                <span className="formErrors">
                                                                                    {errMsg}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='col-12 text-center'>{this.props.t("or_label")}</div>
                                                        <div className='col-12 text-center'>
                                                            <a className="anchorstyle" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ isAttachMentDocumentsModal: true });
                                                            }}>{this.props.t("transactions_page.submit_invoice.upload_buyer_approval")}</a>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </React.Fragment>
                                    </>
                                }
                            ></CommonPopUpModal>
                        }
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}
