import React, { Component } from 'react';
import AppState from '../../contexts/AppState/AppState';
import ViewCslDataModal from '../common/Modals/ViewCslDataModal';
import {cslDataPreprocessor, getEntity,getNewIndustryList} from '../../services/Utilities';
import { compareRole, enumRole } from '../../configs/GlobalConfig';
import API from '../../services/API';
import { HEADERS } from '../../services/API';
import CommonPopUpModal from '../CommonPopUpModal/CommonPopUpModal';
import { AutoCompleteDropdown } from '../common/InputFields/AutoCompleteDropdown';
import { infoAlert, warningAlert } from '../common/Alerts/materialUIAlerts';

export default class UserDetailsModal extends Component {

    api = new API();

    constructor(props) {
        super(props);
        let isCSLDone = props.data && props.data.cslData && props.data.cslData.cslData;
        if (isCSLDone) {
            cslDataPreprocessor(props.data.cslData.cslData);
        }
        this.state={
            isCSLDone: isCSLDone ? true : false,
            isCSLHasData: isCSLDone && props.data.cslData && props.data.cslData.cslData && props.data.cslData.cslData && props.data.cslData.cslData.results && props.data.cslData.cslData.results.length ? true : false,
            isViewCslModal:false,
            isMatchedFunderByAdmin:props.data && props.data.isMatchedFunderByAdmin ?props.data.isMatchedFunderByAdmin:false,
            isSaveBtnEnabled:false,
            allUsers:[],
            selectedBusiness:[],
            defaultBusiness:[],
            existingManagingBusiness:[],
            isHavingReferralPartner:false,
            selectedBusinessErrorMsg:""
        }
    }
    componentDidMount(){
        let _existingManagedUsers=[];
        let _existingManageUsersIds = new Set();
        let _isHavingReferralPartner=false;
        if(this.props && this.props.allUsersData){
            let _userDetails=this.props.allUsersData.filter(user_=>{
                if (user_.managedUsers) {
                    user_.managedUsers.forEach(element => {
                        if (!_existingManageUsersIds.has(element._id)) {
                            _existingManagedUsers.push(element);
                            _existingManageUsersIds.add(element._id);
                        }
                    });
                }
                if(user_.status==1 && !compareRole(user_.role,enumRole.Funder)){
                    if(this.props && this.props.data && this.props.data._id==user_._id){
                        return false;
                    }
                    if(user_.isInvitedBuyer || user_.isInvitedSupplier){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
            })
            .map((users_)=>{return {_id:users_._id,name:users_.name,role:users_.role}});
            _isHavingReferralPartner=_existingManagedUsers.filter(e_=>e_._id==this.props.data._id).length>0;
            const _managedUsers=this.props && this.props.data && this.props.data.managedUsers && this.props.data.managedUsers.length>0?this.props.data.managedUsers:[];
            this.setState({allUsers:_userDetails,selectedBusiness:_managedUsers,existingManagingBusiness:_existingManagedUsers,isHavingReferralPartner:_isHavingReferralPartner},()=>{
                //this.alreadyHavingReferralPartner();
            })
        }
    }

    alreadyHavingReferralPartner=()=>{
        const {existingManagingBusiness,selectedBusiness}=this.state;
        let _businessName="";
        let _havingReferralPartner=[];
        if(selectedBusiness.length>0){
            selectedBusiness.forEach(sl_=>{
                if(existingManagingBusiness.filter(e=>e._id==sl_._id).length>0){
                    _havingReferralPartner.push(sl_.name);
                }
            })
            _businessName=`${_havingReferralPartner.join(",")} users having already referral partner`;
        }
        this.setState({selectedBusinessErrorMsg:_businessName});
    }

    getIndustryName=(index_)=>{
        const industryList_=getNewIndustryList;
        const industry_=industryList_.find((ind_,i)=>{
            if(i+1==index_){
                return ind_;
            }
        });
        return industry_.industryName?industry_.industryName:"";
    }

    managedBusinessUser=(e,users_)=>{
        this.setState({selectedBusiness:users_,isSaveBtnEnabled:true},()=>{
            //this.alreadyHavingReferralPartner();
        });
    }

    render() {
        const {
            data
        } = this.props;
        const {
            isCSLDone,
            isCSLHasData
        } = this.state;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        {this.state.isViewCslModal &&
                            <ViewCslDataModal
                            data={data.cslData.cslData}
                            onClose={() => {
                                this.setState({ isViewCslModal: false });
                            }}
                            >
                            </ViewCslDataModal>
                        }
                        <CommonPopUpModal
                            title='User Details'
                            size='large'
                            onClose={e => this.props.onClose(false)}
                            primaryBtn={data.role.toLowerCase() != enumRole.Funder ? 'Save' : false}
                            isSubmitBtn={!this.state.isSaveBtnEnabled}
                            promptOnClose={this.state.isSaveBtnEnabled}
                            onSubmit={async (e) => {
                                e.preventDefault();
                                await fetch(this.api.currentUserEndpoint() + '/updateMatchedFunderStatus', {
                                    method: 'POST',
                                    headers: HEADERS(),
                                    body: JSON.stringify({ userId: data._id, isMatchedFunderByAdmin: this.state.isMatchedFunderByAdmin,managedUsers:this.state.selectedBusiness }),
                                    credentials: "include"
                                }).then((result) => {
                                    this.props.onClose(true);
                                });
                            }}
                        >
                            <div className="modal-body">
                                <div className='row w-100 mx-0'>
                                    <div className='col-md-12'>
                                        <div className="form-section">
                                            <label className="form-label">
                                                Business Name  <span className={"badge badge-success"}>
                                                    {data.role == "Supplier" && (
                                                        <>Supplier</>
                                                    )}
                                                    {data.role == "Buyer" && (
                                                        <>Buyer</>
                                                    )}
                                                    {data.role == "Funder" && (
                                                        <>Funder</>
                                                    )}
                                                </span>
                                            </label>
                                            <span className="form-value">
                                                {data.name ? data.name : ""}
                                            </span>
                                        </div>
                                        {data.taxId && data.taxId != "DUMMYTAXID" && <div className="form-section">
                                            <label className="form-label">
                                                Tax ID
                                            </label>
                                            <span className="form-value">
                                                {data.taxId ? data.taxId : ""}
                                            </span>
                                        </div>}
                                        <div className="form-section">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label className="form-label">
                                                        {data.role == "Supplier" && (
                                                            <>Supplier Details</>
                                                        )}
                                                        {data.role == "Buyer" && (
                                                            <>Buyer Details</>
                                                        )}
                                                        {data.role == "Funder" && (
                                                            <>Funder Details</>
                                                        )}
                                                    </label>
                                                    <span className="form-value">
                                                        Full Name: {data.userName ? data.userName : ""}
                                                        <br />
                                                        Job Title: {data.title ? data.title : ""}
                                                        <br />
                                                        Phone: {data.phone}
                                                        <br />
                                                        Founded: {data.year}
                                                        <br />
                                                        {data.entity && getEntity(data.entity)}
                                                        <br />
                                                        {data.industry && (
                                                            <>{this.getIndustryName(data.industry)}<br /></>
                                                        )}
                                                        {data.websiteUrl && (
                                                            <>Website: {data.websiteUrl}<br /></>
                                                        )}

                                                    </span>
                                                </div>
                                                <div className='col-md-6'>
                                                    <label className="form-label">
                                                        {data.role == "Supplier" && (
                                                            <>Supplier Address</>
                                                        )}
                                                        {data.role == "Buyer" && (
                                                            <>Buyer Address</>
                                                        )}
                                                        {data.role == "Funder" && (
                                                            <>Funder Address</>
                                                        )}
                                                    </label>
                                                    <span className="form-value">
                                                        {data.address ? data.address : ""} <br />
                                                        {data.city ? data.city : ""} <br />
                                                        {data.state ? data.state : ""} <br />
                                                        {data.zip ? data.zip : ""} <br />
                                                        {data.country ? data.country : ""}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-section'>
                                            {!compareRole(data.role.toLowerCase(),enumRole.Funder) && (
                                                <div className='row ml-2'>
                                                    <label className='pb-1'>
                                                        <input
                                                            name="Managefunders"
                                                            type="checkbox"
                                                            disabled={this.state.isHavingReferralPartner}
                                                            checked={this.state.isMatchedFunderByAdmin || this.state.isHavingReferralPartner}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    isMatchedFunderByAdmin: e.target.checked,
                                                                    isSaveBtnEnabled: true
                                                                });
                                                            }}
                                                        />
                                                        <span className="ml-2">Manage matched funders list by Admin</span>
                                                    </label>

                                                </div>
                                            )}
                                            {this.state.isHavingReferralPartner && (
                                                <>
                                                    {infoAlert(" This user is already having referral partner so admin will do match funders for this user.")}
                                                </>
                                            )}
                                            <div className='ml-2'>
                                                <label className="form-label">Managed Businesses</label>
                                                <AutoCompleteDropdown
                                                    isMultiple_={true}
                                                    placeholder_="Business"
                                                    data_={this.state.allUsers}
                                                    value_={this.state.selectedBusiness}
                                                    onChange_={(e, value) => this.managedBusinessUser(e,value)}
                                                    noOptionsText_="No Businesses found"
                                                    getOptionLabel_={(data_)=>`${data_.name} (${data_.role})`}
                                                />
                                            </div>
                                            {/* {this.state.selectedBusinessErrorMsg.length > 0 && (
                                                <div className='mt-2'>
                                                    {warningAlert(this.state.selectedBusinessErrorMsg)}
                                                </div>
                                            )} */}
                                        </div>
                                        {/* {data.role.toLowerCase() != enumRole.Funder && (
                                            <div className="form-section">
                                                
                                            </div>
                                        )} */}
                                        {isCSLDone ?
                                            <>
                                                <div
                                                    className={"form-section" + (isCSLHasData ? " clickable-area" : "")}
                                                    onClick={(e) => {
                                                        if (isCSLHasData) {
                                                            this.setState({ isViewCslModal: true });
                                                        }
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <label className="form-label">
                                                        <a className="anchorstyle">
                                                            Consolidated Screening List Details {isCSLHasData ? <span style={{ fontWeight: "normal" }}>(Click anywhere in this section to view details)</span> : null}</a>
                                                    </label>
                                                    <span className="form-value">
                                                        {data.cslData.cslData.sources.length > 0 ? (
                                                            <>
                                                                {data.cslData.cslData.sources && data.cslData.cslData.sources.length > 0 && data.cslData.cslData.sources.map(
                                                                    sr_ => {
                                                                        return (
                                                                            <div>{sr_.value} <span className="badge rounded-pill bg-success" style={{ color: "white" }}>{sr_.count}</span> </div>
                                                                        )
                                                                    }
                                                                )}
                                                            </>) : (<>
                                                                <div>No data found</div>
                                                            </>)}
                                                    </span>
                                                </div>
                                            </> : null
                                        }
                                    </div>
                                </div>
                            </div> 
                        </CommonPopUpModal>   
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}