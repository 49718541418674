
import React, { Component } from 'react'
import Popup from '../Auth/Popup';
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import User from '../../../services/User';
import API from '../../../services/API';
import Papa from "papaparse";
import CsvViewer from './CsvViewer';
import {parse} from "../../../../node_modules/csv/dist/esm/index.js";
import { saveAs } from 'file-saver'

export default class FileViewerModal extends Component {
    user = new User();
    api = new API();
    constructor(props_) {
        super(props_);
        this.state = {
            fileExtension: "",
            csvParsedData:[],
            csvTableRows:[],
            csvTableValues:[],
            htmlStringValue:"",
            fileUrl:"",
            isHideBtn:false,
            fileName:""
        };
    }

    componentDidMount() {
        if (this.props && this.props.data && (this.props.data.filename || this.props.data.fileName || this.props.data.name)) {
            let _fileName=this.props.data.filename?this.props.data.filename:this.props.data.fileName?this.props.data.fileName:this.props.data.name;
            const fileExtention_ = _fileName.substring(_fileName.lastIndexOf(".") + 1).toUpperCase();
            let _fileUrl=this.props.isFinancialDocument?this.props.data.downloadUrl:`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${this.props.data._id}`;
            if(this.props.data.preview){
                _fileUrl=this.props.data.preview;
            }
            if(this.props.data.isCSVString){
                _fileUrl=this.props.data.encodedUri
            }
            this.setState({ fileExtension: fileExtention_, fileName:_fileName,fileUrl:_fileUrl},()=>{
                if(fileExtention_=="CSV"){
                    this.getCSVData();
                }
            }); 
        }
    }

    getCSVData=()=>{
        if(this.props && this.props.data){
            let _fileUrl=this.props.isFinancialDocument?this.props.data.downloadUrl:`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${this.props.data._id}`
            let _isParsedData=false;
            const setCSVData=(headers_, tableData_, parsedData_)=> {
                if(_isParsedData){
                    const _htmlStringData=`<html><head>
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css">
                    </head><body>
                    <div class="table-responsive mt-2">
                    <table class="table table-striped table-bordered table-hover">
                        <thead class="thead-light">
                        <tr>
                        ${headers_.map((rows, index) => {
                            return (`<th key=${index}>${rows}</th>`);
                        }).join('')}
                        </tr>
                        </thead>
                        <tbody>
                        ${tableData_.map((value,index)=>{
                            return (`
                                <tr key=${index}>
                                ${value.map((val,i)=>{
                                    return `<td key=${i}>${val}</td>`;
                                }).join('')}
                                </tr>
                            `);
                        }).join('')}
                        </tbody>
                        </table>
                        </div>
                    </body></html>`;
                    this.setState({
                        csvTableRows: headers_,
                        csvTableValues: tableData_,
                        csvParsedData: parsedData_,
                        htmlStringValue:_htmlStringData
                    })
                }
            }

            if(this.props.data.preview){
                _fileUrl=this.props.data.preview;
                const reader = new FileReader();
                reader.onload = () => {
                    parse(reader.result, {
                        relax_column_count: true
                    }, (err, data) => {
                        const rowsArray = [];
                        const valuesArray = [];
                        data.map((d,i) => {
                          if(i==0){
                              rowsArray.push(Object.values(d));
                          }
                          else{
                              valuesArray.push(Object.values(d));
                          }
                        });
                        _isParsedData=true;
                        setCSVData(rowsArray[0],valuesArray,data);
                    });
                };
                reader.readAsBinaryString(this.props.data);
            }
            else if(this.props.data.isCSVString){
                const rowsArray = [];
                const valuesArray = [];
                if(this.props.data.isBankTransactionHistory){
                    Papa.parse(this.props.data.csvString, {
                        header: false,
                        delimiter: ',',
                        skipEmptyLines: true,
                        complete: function (results) {
                          const rowsArray = [];
                          const valuesArray = [];
                          results.data.map((d,i) => {
                            if(i==0){
                                rowsArray.push(Object.values(d));
                            }
                            else{
                                valuesArray.push(Object.values(d));
                            }
                          });
                          _isParsedData=true;
                          setCSVData(rowsArray[0],valuesArray,results.data);
                        },
                    });
                }
                else{
                    this.props.data.csvData.map((d,i) => {
                        if(i==0){
                            rowsArray.push(Object.values(d));
                        }
                        else{
                            valuesArray.push(Object.values(d));
                        }
                      });
                      _isParsedData=true;
                      setCSVData(rowsArray[0],valuesArray,this.props.data.csvData);
                }

            }
            else{
                Papa.parse(_fileUrl, {
                    header: false,
                    delimiter: ',',
                    skipEmptyLines: true,
                    download: true,
                    complete: function (results) {
                      const rowsArray = [];
                      const valuesArray = [];
                      results.data.map((d,i) => {
                        if(i==0){
                            rowsArray.push(Object.values(d));
                        }
                        else{
                            valuesArray.push(Object.values(d));
                        }
                      });
                      _isParsedData=true;
                      setCSVData(rowsArray[0],valuesArray,results.data);
                    },
                });
            }


            
        }
    }



    render() {
        const {
            mimeType,
            downloadUrl,
            filename,
            _id,
            preview
        } = this.props.data;
        const {fileUrl}=this.state;
        return (<><div className="modal modal-fullscreen fade show modal-large modal-z-index" id="invoiceUploadModal" tabIndex="-1" role="dialog" aria-labelledby="invoiceUploadModal" aria-hidden="true" style={{ "display": "block","zIndex":"999999" }}>
            <div className="modal-dialog p-2" role="document">
                <div className="modal-content">
                    <div className="modal-header pb-0" onClick={() => {
                            this.props.onClose();
                        }}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ cursor: "pointer" }} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.state.fileExtension.length > 0 && <>
                            {(this.state.fileExtension == "XLSX" || this.state.fileExtension == "XLS" || this.state.fileExtension=="DOCX" || this.state.fileExtension =="DOC" || this.state.fileExtension=="PPT" || this.state.fileExtension=="PPTX") && (
                                <>
                                    <iframe id="fileViewer" name="fileViewer" src={`https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`} title="File Viewer"></iframe>
                                </>
                            )}
                            {this.state.fileExtension == "CSV" && (
                                <>
                                    <div className='d-flex flex-column w-100'>
                                        {
                                            !this.state.isHideBtn && (<>
                                                <div className='d-flex justify-content-end mb-2'>
                                                    <div className='mr-2'>
                                                        <a className="btn btn-primary"
                                                            style={{ width: 150, cursor: "pointer" }} href={fileUrl} onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({ isHideBtn: true }, () => {
                                                                    var frm = document.getElementById("fileViewer").contentWindow;
                                                                    // frm.document.write("FileViewer");
                                                                    // frm.document.close(); //important!
                                                                    //frm.focus();// focus on contentWindow is needed on some ie versions
                                                                    frm.print();
                                                                    this.setState({ isHideBtn: false });
                                                                })
                                                            }}>Print</a>
                                                    </div>
                                                    <div className=''>
                                                        <a className="btn btn-primary"
                                                            style={{ width: 150, cursor: "pointer" }} href={fileUrl}>Download</a>
                                                    </div>
                                                </div>
                                            </>)
                                        }

                                        <div className="embed-responsive embed-responsive-16by9">
                                            {this.state.csvTableValues.length > 0 && (
                                                <CsvViewer htmlString={this.state.htmlStringValue} />
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {(this.state.fileExtension != "XLSX" && this.state.fileExtension != "XLS" && this.state.fileExtension != "DOCX" && this.state.fileExtension != "DOC" && this.state.fileExtension != "PPT" && this.state.fileExtension != "PPTX" && this.state.fileExtension != "CSV") && (
                                <div className='d-flex flex-column w-100'>
                                {
                                    (!this.state.isHideBtn && this.state.fileExtension!="PDF") && (<>
                                        <div className='d-flex justify-content-end mb-2'>
                                            <div className='mr-2'>
                                                <a className="btn btn-primary"
                                                    style={{ width: 150, cursor: "pointer" }} href={fileUrl} onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({ isHideBtn: true }, () => {
                                                            window.print();
                                                            this.setState({ isHideBtn: false });
                                                        })
                                                    }}>Print</a>
                                            </div>
                                            <div className=''>
                                                <a className="btn btn-primary" target="_blank" onClick={(e)=>{
                                                    e.preventDefault();
                                                    saveAs(fileUrl,this.state.fileName)
                                                }}
                                                    style={{ width: 150, cursor: "pointer" }} href={""}>Download</a>
                                            </div>
                                        </div>
                                    </>)
                                }

                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe id="fileViewer" name="fileViewer" src={fileUrl}></iframe>
                                </div>
                            </div>
                            )}
                        </>}
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div></>);
    }
}
