import React, { Component } from 'react';
import Popup from '../Auth/Popup';
import { dollarFormatter } from '../../../services/Utilities';
import FullSpinner from '../Spinner/FullSpinner';
import API from '../../../services/API';
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import AppState from '../../../contexts/AppState/AppState';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoIcon from '@mui/icons-material/Info';
import { withRouter, NavLink } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';

const numberMask = createNumberMask({
    prefix: "$",
    suffix: ""
});

export default class CommonCreateFundingRequestModal extends Component {
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            isLoading: false,
            isCreateFundingRequest: true,
            additionalInfo: {
                estimatedFinancingVolume: "",
                funds: "",
                frequency: "",
                otherfrequency: "",
                invoiceAndPaymentProcess: ""
            },
            isOtherTextBox: false,
            isNextBtnEnabled: false,
            isCheckingContract: false,
            existingContractList:[],
            isUsingExistingContract:null,
            selectedExistingContract:null
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
       // this.checkExistContract();
       if(this.props.isGetExistingContractList && this.props && this.props.data && this.props.data.length>0){
         this.getAllContractsByType(this.props.data[0].documentType);
       }
    }

    getAllContractsByType=async(type_)=>{
        const _contracts=await this.api.getAllContractByType(type_);
        this.setState({existingContractList:_contracts});
    }

    checkExistContract = async () => {
        let isExistContract=false;
        if(this.props.data.documentType!="ABL"){
            let buyer = this.props.data.map(doc => {
                if (doc.documentType == "PO") {
                    return doc.buyer
                }
                else {
                    return doc.buyer.id;
                }
            });
            this.setState({ isCheckingContract: true });
            isExistContract = await this.api.isCheckExistContract(buyer);
        }
        if (isExistContract) {
            this.setState({ isCreateFundingRequest: true, isCheckingContract: false });
        }
        else {
            this.setState({ isCheckingContract: false });
        }
    }

    handleChange(event) {
        const { additionalInfo } = this.state;
        const name = event.target.name;
        const value = event.target.value;
        additionalInfo[name] = value;
        const isOther = additionalInfo["frequency"] == "other";
        this.setState({
            additionalInfo: additionalInfo,
            isOtherTextBox: isOther,
            isNextBtnEnabled: additionalInfo.estimatedFinancingVolume.length > 0 && additionalInfo.frequency.length > 0 && additionalInfo.funds.length > 0 && additionalInfo.invoiceAndPaymentProcess.length > 0 && (!isOther || additionalInfo.otherfrequency.length > 0)
        });
    }

    checkCreateFundingRequest=()=>{
        if(this.props && this.props.isGetExistingContractList){
            if(this.state.isUsingExistingContract){
                if(this.state.selectedExistingContract){
                    return false;
                }
                else{
                    return true;
                }
            }
            else{
                if(this.state.isUsingExistingContract==false){
                    return false;
                }
                else{
                    return true;
                }
                
            }
        }
    }

    render() {
        const { data, isLoading, additionalInfo, isCheckingContract } = this.state;
        const isRBtn1 = additionalInfo.frequency === "One Time";
        const isRBtn2 = additionalInfo.frequency === "Monthly";
        const isRBtn3 = additionalInfo.frequency === "Quarterly";
        const isRBtn4 = additionalInfo.frequency === "other";

        return (
            <AppState.Consumer>
                {appState => (
                    <>
                        <FullSpinner isSpinning={isLoading} confirmationLabel={data && data.length>0 && data[0].documentType == "SupplyChain"?this.props.t("buyer_cfr_external_funders"):this.props.t("supplier_cfr_external_funders")} callback={e => this.props.onClose()} />
                        <CommonPopUpModal
                            title={this.props.t("create_funding_request_content.title")}
                            primaryBtn={this.props.t("button_names.create_funding_request")}
                            onClose={e => this.props.onClose()}
                            isSubmitBtn={this.checkCreateFundingRequest()}
                            promptOnClose={!this.checkCreateFundingRequest()}
                            onSubmit={() => {
                                this.setState({ isLoading: true });
                                const { additionalInfo } = this.state;
                                additionalInfo.frequency = additionalInfo.otherfrequency.length > 0 ? additionalInfo.otherfrequency : additionalInfo.frequency;
                                delete additionalInfo.otherfrequency;
                                let _createFundingRequestData = {
                                    documents: Array.isArray(data) ? data : [data],

                                };
                                if (this.props && this.props.isGetExistingContractList) {
                                    _createFundingRequestData.isExistingContract = this.state.isUsingExistingContract;
                                    _createFundingRequestData.selectedExistingContract = this.state.selectedExistingContract;

                                }
                                this.api.commonCreateFundingRequest(_createFundingRequestData)
                                    .then(r => {
                                        this.setState({
                                            isLoading: false
                                        });
                                    });
                            }}
                            children={
                                <>
                                    {this.state.isCreateFundingRequest ? (
                                        <>
                                            <div className="row mx-5" style={{ justifyContent: "center" }}>
                                                <div className="create-funding-invoice-msg col-12">
                                                    <p style={{ textAlign: "center" }}>{this.props.t("create_funding_request_content.description")}</p>
                                                </div>
                                                {this.props && this.props.isGetExistingContractList && (
                                                    <div className='d-flex flex-column align-items-center mb-2'>
                                                        <label className="form-label-style mb-2" htmlFor="name">{this.props.t("create_funding_request_content.select_label")} :</label>
                                                        <div className="form-group">
                                                            <div className='d-flex'>
                                                                <label>
                                                                    <input
                                                                        type='radio'
                                                                        name="isExistingContract"
                                                                        value={true}
                                                                        checked={this.state.isUsingExistingContract == true}
                                                                        onChange={(e) => {
                                                                            this.setState({ isUsingExistingContract: true, selectedExistingContract: null });
                                                                        }}
                                                                    /> {this.props.t("create_funding_request_content.select_label_types.existing")}</label>
                                                                <label style={{ paddingLeft: 10 }}>
                                                                    <input
                                                                        type='radio'
                                                                        name="isExistingContract"
                                                                        value={false}
                                                                        checked={this.state.isUsingExistingContract == false}
                                                                        onChange={(e) => {
                                                                            this.setState({ isUsingExistingContract: false, selectedExistingContract: null });
                                                                        }} /> {this.props.t("create_funding_request_content.select_label_types.new")}</label>
                                                            </div>
                                                        </div>
                                                        {this.state.isUsingExistingContract && (
                                                            <div className="form-group">
                                                                <Select
                                                                    labelId="select-existing-contract"
                                                                    id="select-existing-contract"
                                                                    displayEmpty={true}
                                                                    fullWidth={true}
                                                                    renderValue={value => {
                                                                        return value ? value.contractIdWithName : this.props.t("create_funding_request_content.select_existing_contract")

                                                                    }}
                                                                    onChange={(e) => {
                                                                        this.setState({ selectedExistingContract: e.target.value.fundingContractId })
                                                                    }}
                                                                >
                                                                    <MenuItem disabled value="">
                                                                        <em>{this.props.t("create_funding_request_content.select_existing_contract")}</em>
                                                                    </MenuItem>
                                                                    {this.state.existingContractList.length > 0 && this.state.existingContractList.map(ex_ => {
                                                                        return <MenuItem key={ex_.fundingContractId} value={ex_}>
                                                                            <ListItemText>{ex_.contractIdWithName}
                                                                            </ListItemText>
                                                                            <Tooltip title={this.props.t("create_funding_request_content.view_funding_contract_details")} arrow>
                                                                                <ListItemIcon onClick={(e) => e.stopPropagation()}>
                                                                                    <NavLink className="anchorstyle" target="blank" to="/fundingcontract"><InfoIcon fontSize="small" style={{ color: "#063A52" }} /></NavLink>
                                                                                </ListItemIcon>
                                                                            </Tooltip>

                                                                        </MenuItem>
                                                                    })}
                                                                </Select>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {data && data[0].documentType == "ABL" && (
                                                    <div className="col-md-12 selected-customers">
                                                        {this.props.t("create_funding_request_content.selected_abl")}
                                                    </div>
                                                )}
                                                {data && data[0].documentType != "ABL" && data[0].documentType != "SupplyChain" && (
                                                    <div className="col-md-12 selected-customers">
                                                        {this.props.t("create_funding_request_content.selected_customer_label")}
                                                    </div>
                                                )}
                                                {data && data[0].documentType == "SupplyChain" && (
                                                    <div className="col-md-12 selected-customers">
                                                        {this.props.t("create_funding_request_content.selected_supplier_label")}
                                                    </div>
                                                )}
                                                <div className="col-md-12" style={{ height: 145, background: "#FFFFFF", overflow: "auto" }}>
                                                    {data && (data[0].documentType == "Invoice" || data[0].documentType == "SupplyChain") && (
                                                        <React.Fragment>
                                                            <div className="row py-3" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                                                <div className="col-3">{data[0].documentType == "Invoice" ? this.props.t("grid_view_header_labels.customer_name") : this.props.t("grid_view_header_labels.supplier_name")}</div>
                                                                <div className="col-3">{this.props.t("grid_view_header_labels.invoice_number")}</div>
                                                                <div className="col-3 text-right" >{this.props.t("grid_view_header_labels.invoice_amount")}</div>
                                                            </div>
                                                            {data.map(doc => {
                                                                return (<React.Fragment>
                                                                    {(doc.documentType == "Invoice" || doc.documentType == "SupplyChain") && (<div className="row">
                                                                        <div className="col-3" style={{ color: "#575757", fontSize: 14 }}>{doc.documentType == "Invoice" ? doc.invoice.buyerName : doc.supplierName}</div>
                                                                        <div className="col-3" style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{doc.documentId}</div>
                                                                        <div className="col-3 text-right pr-4" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}> {dollarFormatter.format(doc.total)}</div>
                                                                    </div>
                                                                    )}
                                                                </React.Fragment>)
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                    {data && data[0].documentType == "PO" && (
                                                        <React.Fragment>
                                                            <div className="row py-3" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                                                <div className="col-3">{this.props.t("grid_view_header_labels.po_number")}</div>
                                                                <div className="col-4 text-right" >{this.props.t("grid_view_header_labels.po_amount")}</div>
                                                            </div>
                                                            {data.map(doc => {
                                                                return (<React.Fragment>
                                                                    {doc.documentType == "PO" && (<div className="row">
                                                                        <div className="col-3" style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{doc.documentId}</div>
                                                                        <div className="col-4 text-right pr-4" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}> {dollarFormatter.format(doc.total)}</div>
                                                                    </div>
                                                                    )}
                                                                </React.Fragment>)
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                    {data && data[0].documentType == "ABL" && (
                                                        <React.Fragment>
                                                            <div className="row py-3" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                                                <div className="col-3">Asset #</div>
                                                                <div className="col-4 text-right" >{this.props.t("grid_view_header_labels.collateral_amount")}</div>
                                                            </div>
                                                            {data.map(doc => {
                                                                return (<React.Fragment>
                                                                    {doc.documentType == "ABL" && (<div className="row">
                                                                        <div className="col-3" style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{doc.documentId}</div>
                                                                        <div className="col-4 text-right pr-4" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}> {dollarFormatter.format(doc.total)}</div>
                                                                    </div>
                                                                    )}
                                                                </React.Fragment>)
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                <div className="create-funding-invoice-msg col-12 pt-2" style={{ fontSize: 12 }}>
                                                    <p style={{ textAlign: "center" }}>{this.props.t("create_funding_request_content.create_funding_request_privacy")}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (<>
                                        <div className="create-funding-invoice-msg pt-4">
                                            <p style={{ textAlign: "center" }}>Please provide the following to help potential funders understand your situation:</p>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <div className='d-flex flex-column' style={{ width: "60%" }}>
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">Estimated financing volume</label>
                                                    <MaskedInput
                                                        name="estimatedFinancingVolume"
                                                        value={this.state.additionalInfo.estimatedFinancingVolume}
                                                        onChange={this.handleChange}
                                                        mask={numberMask}
                                                        className="form-control"
                                                        placeholder="$ ..."
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">Frequency</label><br />
                                                    <div className='d-flex'>
                                                        <label>
                                                            <input type='radio'
                                                                name="frequency"
                                                                value="One Time"
                                                                checked={isRBtn1}
                                                                onChange={this.handleChange} /> One Time</label>
                                                        <label style={{ paddingLeft: 10 }}>
                                                            <input type='radio'
                                                                name="frequency"
                                                                value="Monthly"
                                                                checked={isRBtn2}
                                                                onChange={this.handleChange} /> Monthly</label>
                                                        <label style={{ paddingLeft: 10 }}>
                                                            <input type='radio'
                                                                name="frequency"
                                                                value="Quarterly"
                                                                checked={isRBtn3}
                                                                onChange={this.handleChange} /> Quartly</label>
                                                        <label style={{ paddingLeft: 10 }}>
                                                            <input type='radio'
                                                                name="frequency"
                                                                value="other"
                                                                checked={isRBtn4}
                                                                onChange={this.handleChange} /> Other</label>
                                                    </div>
                                                </div>
                                                {this.state.isOtherTextBox && (
                                                    <div className="form-group">
                                                        <label className="form-label-style" htmlFor="name">Other frequency</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="otherfrequency"
                                                            onChange={this.handleChange}
                                                            placeholder="Bi-weekly ..."
                                                        />
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">Use of funds</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        name="funds"
                                                        value={this.state.additionalInfo.funds}
                                                        onChange={this.handleChange}
                                                        placeholder="The funds requested are used for ..."
                                                    ></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">Describe your invoice and payment processes</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        name="invoiceAndPaymentProcess"
                                                        onChange={this.handleChange}
                                                        placeholder="Describe your invoice and payment processes"
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex' style={{ justifyContent: "center" }}>
                                            <div style={{ display: "flex" }}>
                                                <button
                                                    className="btn btn-secondary mt-3"
                                                    style={{ width: 150 }}
                                                    onClick={
                                                        e => this.props.onClose()
                                                    }
                                                >Cancel</button>
                                            </div>
                                            <div style={{ paddingLeft: 10, display: "flex" }}>
                                                <button
                                                    className="btn btn-primary mt-3"
                                                    style={{ width: 150 }}
                                                    //disabled={!this.state.isNextBtnEnabled}
                                                    onClick={() => {
                                                        this.setState({ isCreateFundingRequest: true })
                                                    }}
                                                >Next</button>
                                            </div>

                                        </div>
                                    </>)}
                                </>
                            }
                        ></CommonPopUpModal>    
                    </>
                )}
            </AppState.Consumer>
        )
    }
}