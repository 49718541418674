import React, { Component } from 'react';
import moment from 'moment';
import { validateEmail } from '../../../../services/Utilities';
import FullSpinner from '../../../common/Spinner/FullSpinner';
import AppState from '../../../../contexts/AppState/AppState';
import CommonConfirmationModal, {AddModal} from '../../../common/Modals/CommonConfirmationModal';
import API from '../../../../services/API';
import Popup from '../../../common/Auth/Popup';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CommonPopUpModal from '../../../CommonPopUpModal/CommonPopUpModal';

export default class VieweSupplierDetails extends Component {
    api = new API();

    constructor(props){
        super(props);
        this.state = {
            purchaseOrderDetails:this.props && this.props.data?this.props.data:"",
            isLoading: false,
            isSendingInvite: false,
            errMsg: '',
            isSupplierAvailable:false,
            isshowSupplierDetails:false,
            existingSupplier:this.props && this.props.data?this.props.data:[],
            allSuppliers:[],
            supplierEmail:this.props.data && this.props.data.purchaseOrder && this.props.data.purchaseOrder.supplierEmail?this.props.data.purchaseOrder.supplierEmail:"",
        };
    }



    render() {
        const {data}=this.props;
        const { isLoading, isSendingInvite,existingSupplier,allSuppliers ,isSupplierAvailable,supplierEmail,errMsg ,isshowSupplierDetails} = this.state;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <Popup className={"buyer-submit-details-popup"}
                            content={
                                <React.Fragment>
                                    <CommonPopUpModal
                                        title='View Supplier Details'
                                        onClose={e => this.props.onClose()}
                                        promptOnClose={false}
                                    >
                                        <div className="row m-0 px-5 pt-2 pb-4">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">Supplier Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="po#"
                                                        value={existingSupplier.length > 0 && existingSupplier[0].email ? existingSupplier[0].email : ""}
                                                        disabled={existingSupplier.length > 0}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">Supplier address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="po#"
                                                        value={existingSupplier.length > 0 && existingSupplier[0].address ? existingSupplier[0].address : ""}
                                                        disabled={existingSupplier.length > 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="address">Country</label>
                                                    <CountryDropdown
                                                        value={existingSupplier.length > 0 && existingSupplier[0].country ? existingSupplier[0].country : ""}
                                                        classes="form-control"
                                                        defaultOptionLabel="Please select country"
                                                        whitelist={["AR", "AM", "AU", "AT", "AZ", "BY", "BE", "BA", "BW", "BR", "BG",
                                                            "CA", "CL", "CN", "CO", "CR", "HR", "CY", "CZ", "DK", "DO", "EG",
                                                            "EE", "FI", "FR", "GE", "DE", "GR", "GT", "HN", "HK", "HU", "IN",
                                                            "ID", "IE", "IL", "IT", "JP", "KE", "XK", "LV", "LB", "LT", "LU",
                                                            "MY", "MT", "MU", "MX", "MD", "MA", "MK", "NL", "NZ", "KP", "NO",
                                                            "OM", "PE", "PL", "PT", "RO", "RU", "RS", "SG", "SK", "SL", "ZA",
                                                            "KR", "ES", "LK", "SE", "CH", "TW", "TH", "TN", "TR", "UG", "UA",
                                                            "GB", "AE", "US"]}
                                                        disabled={existingSupplier.length > 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="po#"
                                                        value={existingSupplier.length > 0 && existingSupplier[0].city ? existingSupplier[0].city : ""}
                                                        disabled={existingSupplier.length > 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="state">State / Province</label>
                                                    <RegionDropdown
                                                        country={existingSupplier.length > 0 && existingSupplier[0].country ? existingSupplier[0].country : ""}
                                                        value={existingSupplier.length > 0 && existingSupplier[0].state ? existingSupplier[0].state : ""}
                                                        classes="form-control"
                                                        defaultOptionLabel="Select State / Province"
                                                        blankOptionLabel="Select State / Province"
                                                        blacklist={{
                                                            US: ["Micronesia", "Guam", "Marshall Islands", "Northern Mariana Islands", "Palau", "Puerto Rico"
                                                                , "American Samoa", "District of Columbia", "Virgin Islands", "Armed Forces Americas", "Armed Forces Europe, Canada, Africa and Middle East", "Armed Forces Pacific"]
                                                        }}
                                                        disabled={existingSupplier.length > 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="form-label-style" htmlFor="name">State zip code/ Postal code</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="po#"
                                                        value={existingSupplier.length > 0 && existingSupplier[0].zip ? existingSupplier[0].zip : ""}
                                                        disabled={existingSupplier.length > 0}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='form-group'>
                                                    <label className="form-label-style">Phone number</label>
                                                    <PhoneInput
                                                        country={existingSupplier.length > 0 && existingSupplier[0].country ? existingSupplier[0].country : ""}
                                                        value={existingSupplier.length > 0 && existingSupplier[0].phone ? existingSupplier[0].phone : ""}
                                                        countryCodeEditable={false}
                                                        disableDropdown={true}
                                                        disabled={existingSupplier.length > 0}
                                                        className="mt-2"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </CommonPopUpModal>
                            </React.Fragment>}
                        />
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}
