import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

export const AutoCompleteDropdown = ({
    isMultiple_,//Declare dropdown selection is single or multiple
    data_=[], //Dropdown list value and default value is empty array
    value_=[],//Default selected value and default value is empty array
    onChange_,//Parent change event will fire
    placeholder_="Select...",//Placeholder for dropdown and default value is string value
    noOptionsText_="No options",//If data is empty.This will show in the list
    getOptionLabel_//Selection value label
}) => {
    return (
        <Autocomplete
            multiple={isMultiple_}
            options={data_}
            getOptionLabel={getOptionLabel_}
            value={value_}
            onChange={(event, newValue) => onChange_(event,newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={placeholder_} 

                />
            )}
            noOptionsText={noOptionsText_}
        />
    )
}