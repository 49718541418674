import React, { Component } from "react";
import PropTypes from "prop-types";
import AppState from "./AppState";

import User from "../../services/User";
import API from "../../services/API";

class AppProvider extends Component {
  user = new User();

  api = new API();
  _timer;
  state = {
    // eslint-disable-next-line react/no-unused-state
    currentUser: this.user.currentUser(),
    currentUserRole: this.user.currentUserRole(),
    currentProduct: this.user.currentUserType(),
    isTaxCreditFinancing: this.user.currentUserType() == "TaxCreditFinancing",
    isInvoiceFinancing: this.user.currentUserType() == "InvoiceFinancing",
    isPOFinancing: this.user.currentUserType() == "POFinancing",
    isFinancialStatements:this.user.currentUserType=="FinancialStatements",
    selectedInvoiceIndex: 0,
    notifications: this.getNotifications(),
    unreadNotificationsCount: this.getUnreadNotificationsCount(),
    isNavFromNotifications: false,
    documentationExists: false,
    isNotificationsNotRead: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({ currentUserRole: this.user.currentUserRole() });
      // document.title =
      //   "LedgerFunding " +
      //   this.user.currentUserRole().replace(/^\w/, c => c.toUpperCase());
    }
  }

  componentWillUnmount() {
    clearInterval(this._timer);
  }

  async getNotifications() {
    if (this.user.currentUserRole() !== "" && this.user.currentUserRole()!="admin") {
      try {
        const notificationsResponse = await this.api.getNotifications();
        this.setState({
          notifications: notificationsResponse.notifications || []
        });
      }
      catch(ex_){}
    } else {
      return [];
    }
  }

  async getUnreadNotificationsCount() {
    if (this.user.currentUserRole() !== "" && this.user.currentUserRole()!="admin") {
      try {
        const response = await this.api.getUnreadNotificationsCount();
        this.setState({ unreadNotificationsCount: response.count || 0 });
      }
      catch(ex_){}
      // this._timer = setInterval(async () => {
      //   if (this.user.currentUserRole() !== "" ) {
      //   const response = await this.api.getUnreadNotificationsCount();
      //     this.setState({ unreadNotificationsCount: response.count || 0 });
      //   } else {
      //     return [];
      //   }
      // }, 60000);
    } else {
      return [];
    }
  }

  render() {
    const { children } = this.props;
    return (
      <AppState.Provider
        value={{
          state: this.state,
          setState: (statePropToUpdate, value) =>
            this.setState({ [statePropToUpdate]: value })
        }}
      >
        {children}
      </AppState.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
