import React, { Component } from 'react'
import $ from 'jquery';
import FullSpinner from '../../common/Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import Popup from '../../common/Auth/Popup';
import Dropzone from 'react-dropzone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ToastContainer,toast} from 'react-toastify';
import SignaturePopver from '../../common/PopOver/SignaturePopver';
import CommonConfirmationModal from '../../common/Modals/CommonConfirmationModal';
import BusinessInformationMissingModal from '../../common/Modals/BusinessInformationMissingModal';
import {localDateFormat} from "../../../services/Utilities";
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import FileUploader from '../../common/FileUploader/FileUploader';

export default class SignedAgreementModal extends Component {
    api = new API();

    _countDownTimer;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            file: null,
            isDSVerified:false,
            isTokenGenrated:false,
            isAgreeChecked:false,
            eSignature:"",
            isSendingVCode:false,
            eVerificationDetails:{},
            verificationStatusMsg:this.props.t("verification_code_send_msg"),
            isVerificationTokenExpired:false,
            isEnableSignBtn:false,
            isSignatureAdded:false,
            supplierWalletDetails:null,
            signedAgreementDetails:null,
            minutes: 5,
            seconds: 0,
            isBusinessInfoMissing:false,
            userName:"",
            title:"",
            supplierDetails:null,
            isFileViewerModal:false,
            fileViewerContent:null,
            isFinancialDocument:false
        };
    }

    componentDidMount(){
        if(this.props.data && this.props.data.supplier && (!this.props.data.supplier.userName || !this.props.data.supplier.title)){
            this.setState({isBusinessInfoMissing:true});
        }
    }

    getProfile=async()=>{
        const _supplierDetails=await this.api.getV2Profile();
        this.setState({supplierDetails:_supplierDetails.user});
    }

    async notify(message) {
        toast.info(message)
    }
    submitAgreement = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        if (!this.state.isVerificationTokenExpired) {
            const documentData = new FormData();
            documentData.append("fundingReqId", this.props.data.fundingReqId);
            documentData.append("fundingContractId", this.props.data.contracts[0]._id);
            if (this.state.file) {
                documentData.append("isDigitalSignature", false);
                documentData.append("file", this.state.file);
            }
            else {
                documentData.append("isDigitalSignature", true);
            }
            const _signedAgreementDetails = await this.api.uploadSignedAgreementToContract(documentData);
            if (this.state.file) {
                this.setState({ isLoading: false });
                this.props.onClose();
            }
            else {

                this.setState({ isSignatureAdded: true, signedAgreementDetails: _signedAgreementDetails && _signedAgreementDetails.payload ? _signedAgreementDetails.payload : null,isLoading: false });
            }
        } else {
            this.notify(this.props.t("invalid_verification_code_msg"));
            this.setState({ isLoading: false });
        }
    }

    handleChange=(event)=>{
        const name = event.target.name;
        const value = event.target.value;
        if(name=="eSignature"){
            this.setState({eSignature:value},async()=>{
                const isSignBtnEnabled=await this.verifiyECodeDetails();
                if(isSignBtnEnabled){
                    this.setState({isEnableSignBtn:true});
                }
                else{
                    this.setState({isEnableSignBtn:false});
                }
            });
        }
    }
    onAgreeChange=async (event)=>{
        const isAgreeChecked_=event.target.checked;
        this.setState({isAgreeChecked:isAgreeChecked_});
        if(isAgreeChecked_){
            await this.getVerificationCode(false);
        }
    }

    getVerificationCode=async (isResend_)=>{
        const {data}=this.props;
        this.setState({isSendingVCode:true});
        const _codeDetails={
            isResend:isResend_,
            fundingReqId:data.fundingReqId,
            fundingContractId:data.contractId,
            _id:this.state.eVerificationDetails && this.state.eVerificationDetails._id?this.state.eVerificationDetails._id:""
        }
        const codeStatus=await this.api.getVerificationCode(_codeDetails);
        if(codeStatus && codeStatus.verificationCode){
            this.notify(this.props.t("verification_code_send_msg"));
            this.setState({isTokenGenrated:true,eVerificationDetails:codeStatus,minutes:5,seconds:0,isVerificationTokenExpired:false});
            this._countDownTimer = setInterval(async () => {
                const { seconds, minutes } = this.state

                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this._countDownTimer);
                        this.setState({isVerificationTokenExpired:true});
                        this.notify(this.props.t("expired_verification_code_msg"));
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                    }
                } 
            }, 1000);
        }
        this.setState({isSendingVCode:false});
    }

    verifiyECodeDetails=async()=>{
        const {eVerificationDetails}=this.state;
        const _eSignatureValue=this.state.eSignature;
        let isVerified=false;
        if(eVerificationDetails.verificationCode==_eSignatureValue){
            return true;
        }
        else{
            return false;
        }
        // let _currentMinute = moment().get('minute');
        // console.log(moment(eVerificationDetails.createdAt));
        // let _tokenCreatedMinute=moment(eVerificationDetails.createdAt).get('m');
    }

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };
    
    render() {
        const { data } = this.props;
        const {
            isLoading,
            supplierDetails
        } = this.state;
        const isUploadBtn = data && data.contracts && data.contracts.length > 0 && data.contracts[0].isDigitalSignature && data.contracts[0].isDigitalSignature == "true" && !this.state.isDSVerified
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <ToastContainer />
                        <FullSpinner
                            isSpinning={isLoading}
                            confirmationLabel=""
                        />
                        <FullSpinner
                            isSpinning={this.state.isSendingVCode}
                            confirmationLabel=""
                        />
                        {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                            onClose={() => {
                                this.setState({ isFileViewerModal: false, fileViewerContent: null });
                            }}
                            data={this.state.fileViewerContent}
                            isFinancialDocument={false}
                        ></FileViewerModal>}
                        {this.state.isBusinessInfoMissing && (
                            <BusinessInformationMissingModal
                                data={this.props.data.supplier}
                                onClose={(d) => {
                                    this.setState({ isBusinessInfoMissing: false });
                                    if (d) {
                                        this.props.onClose();
                                    }
                                    else {
                                        this.getProfile();
                                    }
                                }}
                            />)}
                        <div
                            className={data.contracts.length > 0 && data.contracts[0].isDigitalSignature && data.contracts[0].isDigitalSignature == "true" ? "py-3 mb-3 text-center" : " pb-2 mb-3 border-bottom"}
                        >
                        </div>
                        <CommonPopUpModal
                            size='medium'
                            title={`${this.props.t("upload_signed_agreement")}: #${data ? data.fundingReqId : ""}`}
                            primaryBtn={isUploadBtn? false: this.props.t("button_names.upload")}
                            onClose={e => this.props.onClose()}
                            onSubmit={this.submitAgreement}
                            isSubmitBtn={this.state.file == null}
                            promptOnClose={false}
                            children={
                                <>
                                     {data && data.contracts && data.contracts.length > 0 && data.contracts[0].isDigitalSignature && data.contracts[0].isDigitalSignature == "true" && !this.state.isDSVerified && (
                                        <>
                                            <div className="row mx-4 justify-content-center">
                                                <div className=' text-center mb-3 w-100'>
                                                    <FileUploader
                                                        documents={data.contracts[0] && data.contracts[0].fundingAggrement && data.contracts[0].fundingAggrement._id ? data.contracts[0].fundingAggrement : []}
                                                        onView={this.handleView}
                                                        placeholder={this.props.t("agreement_copy_not_upload")}
                                                        isdisabled={true}
                                                        isMultiple={false}
                                                        isExtraction={false}
                                                        overrideFileName={this.props.t("agreement")}
                                                        isDeleteEnable={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mx-2  justify-content-center mt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                                <div className='col-3'>{this.props.t("grid_view_header_labels.company_name")}</div>
                                                <div className='col-4'>{this.props.t("grid_view_header_labels.signer")}</div>
                                                <div className='col-3'>{this.props.t("grid_view_header_labels.signed_verified")}</div>
                                                <div className='col-2'>{this.props.t("grid_view_header_labels.sign_on")}</div>
                                                {/* <div className='col-3'>Signed At</div> */}
                                            </div>
                                            <div className="row mx-2 mt-2  justify-content-center">
                                                <div className='col-3' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{data.contracts[0].funderDetails && data.contracts[0].funderDetails.name ? data.contracts[0].funderDetails.name : ""}</div>
                                                <div className='col-4' >
                                                    <div className='d-flex flex-column'>
                                                        <div className='Signer-font'>
                                                        {data.contracts[0].funderDetails && data.contracts[0].funderDetails.userName ? data.contracts[0].funderDetails.userName : ""}
                                                        </div>
                                                        <div>
                                                        {data.contracts[0].funderDetails && data.contracts[0].funderDetails.title ? data.contracts[0].funderDetails.title : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-3'>
                                                    <div className='d-flex'>
                                                        <SignaturePopver
                                                            userName={data.contracts[0].funderDetails && data.contracts[0].funderDetails.userName ? data.contracts[0].funderDetails.userName : ""}
                                                            signature={data.contracts[0].funderWalletInfo && data.contracts[0].funderWalletInfo.funderSignature ? data.contracts[0].funderWalletInfo.funderSignature : "Not Signed"}
                                                            createdAt={data && data.contracts.length > 0 && data.contracts[0].fundingAgreementAt ? data.contracts[0].fundingAgreementAt : new Date()}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-2'>
                                                    {localDateFormat(data && data.contracts.length>0 && data.contracts[0].fundingAgreementAt?new Date(data.contracts[0].fundingAgreementAt):new Date())}
                                                </div>
                                            </div>
                                            {/* <div className="row mx-2">
                                                <div className='col-3'></div>
                                                <div className='col-9'>
                                                    <label>
                                                        <input type='checkbox'
                                                            name="agreeCondition"
                                                            defaultChecked={true}
                                                            disabled={true} />
                                                        &nbsp;I hereby agree to do business electronically. The signature is electronically recorded and associated with this agreement ({data && data.contractId ? data.contractId : "FC-ID"}), and is designed to be compliant as an "electronic signature" under applicable law.
                                                    </label>
                                                </div>
                                            </div> */}
                                            <div className="row mx-2 mt-4  justify-content-center">
                                                <div className='col-3' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{data.supplier && data.supplier.name ? data.supplier.name : ""}</div>
                                                <div className='col-4' >
                                                    <div className='d-flex flex-column'>
                                                        <div className={this.state.isSignatureAdded ?
                                                            "Signer-font" : ""}>
                                                            {data.supplier && data.supplier.userName ? data.supplier.userName :
                                                                (supplierDetails && supplierDetails.userName) ? supplierDetails.userName : ""}
                                                        </div>
                                                        <div>
                                                            {data.supplier && data.supplier.title ? data.supplier.title :
                                                                (supplierDetails && supplierDetails.title) ? supplierDetails.title : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={this.state.isSignatureAdded ? 'col-3' : "col-5"}>
                                                    {(!this.state.isSignatureAdded && data && data.contracts && data.contracts.length > 0 && data.contracts[0].isDigitalSignature && data.contracts[0].isDigitalSignature == "true" && (this.state.isTokenGenrated && this.state.isAgreeChecked)) && (
                                                        <React.Fragment>
                                                            <div className='row mx-0'>
                                                                <div className="form-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="eSignature"
                                                                        onChange={this.handleChange}
                                                                        placeholder={this.props.t("one_time_password")}
                                                                    />
                                                                </div>
                                                                <div className="form-group pl-3">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        disabled={!this.state.isEnableSignBtn}
                                                                        style={{ width: "100%", height: "95%" }}
                                                                        onClick={(e) => {
                                                                            this.submitAgreement(e);
                                                                        }}
                                                                    >{this.props.t("button_names.sign_now")}</button>
                                                                </div>
                                                                <div className='text-center pl-3'>
                                                                    {this.state.isVerificationTokenExpired ?
                                                                        <a className="nav-link active p-0" style={{ cursor: "pointer" }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.getVerificationCode(true);
                                                                            }}
                                                                        ><h6 className='mt-2'>{this.props.t("button_names.resend_code")}</h6></a>
                                                                        : <h6 className='mt-2' style={{ color: '#999999' }}>{this.props.t("resend_code_in")} {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</h6>}
                                                                </div>

                                                                {/* <div>
                                                                    {this.state.minutes === 0 && this.state.seconds === 0
                                                                        ? <h1>Busted!</h1>
                                                                        : <h1>Time Remaining: {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</h1>
                                                                    }
                                                                </div> */}
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                    {this.state.isSignatureAdded && (
                                                        <>
                                                            <div className='d-flex'>
                                                                <SignaturePopver
                                                                    userName={data.supplier && data.supplier.userName ? data.supplier.userName :
                                                                        (supplierDetails && supplierDetails.userName) ? supplierDetails.userName : ""}
                                                                    signature={data.contracts[0].supplierWalletInfo && data.contracts[0].supplierWalletInfo.supplierSignature ? data.contracts[0].supplierWalletInfo.supplierSignature : "Not Signed"}
                                                                    createdAt={this.state.signedAgreementDetails && this.state.signedAgreementDetails.signedAgreementAt ?this.state.signedAgreementDetails.signedAgreementAt:new Date()}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {this.state.isSignatureAdded && (
                                                    <div className='col-2'>
                                                         {localDateFormat(this.state.signedAgreementDetails && this.state.signedAgreementDetails.signedAgreementAt ? new Date( this.state.signedAgreementDetails.signedAgreementAt):new Date())}
                                                    </div>
                                                )}
   
                                            </div>
                                            {!this.state.isTokenGenrated && (
                                                <div className="row mx-2">
                                                    <div className='col-3'></div>
                                                    <div className='col-9'>
                                                        <label>
                                                            <input type='checkbox'
                                                                name="agreeCondition"
                                                                defaultChecked={false}
                                                                disabled={this.state.isTokenGenrated}
                                                                onChange={this.onAgreeChange} />
                                                            &nbsp;{this.props.t("fundingrequests_page.funding_agreement_content.agreement_check_msg_1")} ({data && data.contractId ? data.contractId : "FC-ID"}), {this.props.t("fundingrequests_page.funding_agreement_content.agreement_check_msg_2")}
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    
                                    {((data && data.contracts && data.contracts.length > 0 && data.contracts[0].isDigitalSignature && data.contracts[0].isDigitalSignature != "true")) && (
                                        <div className="row">
                                            <div className="col-md-6 ">
                                                <div className="alert alert-info" role="alert" style={{ height: '20vh', display: 'flex', alignItems: 'center', borderRadius: "6px" }}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <small>{this.props.t("download_the")}
                                                            <a className="anchorstyle" href="" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.setState({
                                                                    isFinancialDocument: false,
                                                                    isFileViewerModal: true,
                                                                    fileViewerContent: data.contracts[0].fundingAggrement
                                                                })
                                                            }} > {this.props.t("funding_agreement_by_clicking_here_1")}</a>
                                                            , {this.props.t("funding_agreement_by_clicking_here_2")}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <FileUploader
                                                    documents={this.state.file ? this.state.file : []}
                                                    onDocUpload={doc_ => { if (doc_ && doc_.length) { this.setState({ file: doc_[0] }); } }}
                                                    onDelete={(filteredDocs) => this.setState({ file: null })}
                                                    onView={this.handleView}
                                                    namelength={30}
                                                    supportedExt={['.pdf']}
                                                    placeholder={this.props.t("file_upload_msg.upload_signed_agreement")}
                                                    isdisabled={false}
                                                    isMultiple={false}
                                                    isExtraction={false}
                                                    isDeleteEnable={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            }
                        >
                        </CommonPopUpModal>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}