import Alert from '@mui/material/Alert';



export const successAlert=(msg)=>{
    return <Alert severity="success">{msg}</Alert>;
}

export const infoAlert=(msg)=>{
    return <Alert severity="info">{msg}</Alert>;
}

export const warningAlert=(msg)=>{
    return <Alert severity="warning">{msg}</Alert>;
}

export const errorAlert=(msg)=>{
    return <Alert severity="error">{msg}</Alert>;
}