import React from 'react';
import AppState from '../../../contexts/AppState/AppState';

const Modals = ({ invoices }) => (
  <AppState.Consumer>
    {appState => (
      <React.Fragment>
      </React.Fragment>
    )}
  </AppState.Consumer>
);

export default Modals;
