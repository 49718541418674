import React, { Component } from 'react';


import AppState from '../../../../contexts/AppState/AppState';
import AppProvider from '../../../../contexts/AppState/AppStateProvider';
import API,{API_ENDPOINT_SUPPLIER} from '../../../../services/API';
import User from "../../../../services/User";
import FullSpinner from "../../../common/Spinner/FullSpinner";
import DataGrid from '../../../common/DataGrid/DataGrid';
import DataGridFilter from '../../../common/DataGrid/DataGridFilter';
import { dollarFormatter, dateFormatter,localDateFormat ,PaymentTerm,addDaysInSufixForPaymentTerm} from '../../../../services/Utilities';
import FileViewerModal from '../../../common/FileViewerModal/FileViewerModal';
import UploadSupplyChainModal from './UploadSupplyChainModal';
import CommonCreateFundingRequestModal from '../../../common/Modals/CommonCreateFundingRequestModal';
import CommonConfirmationModal ,{AddModal} from '../../../common/Modals/CommonConfirmationModal';
import { NavLink} from "react-router-dom";
import { compareRole ,enumRole, transactionType} from '../../../../configs/GlobalConfig';
import NotificationService from '../../../../services/NotificationService';
import { generateSteps, calculateStatusCounts, StatusStepper } from '../../../../services/StatusStepperService';

export default class SupplierChainList extends Component {
    user = new User();
    api = new API();

    _columnsSpecification = [
        {
            key: "supplierName",
            name: this.props.t("grid_view_header_labels.supplier_name"),
            contentProvider: (datum_) => {
                if(datum_.isInviteSupplier){
                    return (<div>
                        {datum_.supplierName}{" "}
                        <span className="badge badge-pill-1 badge-danger p-2">
                            {this.props.t("not_in_ledgerfunding")}
                        </span>
                    </div>);
                }
                else{
                    return datum_.supplierName;
                }
            }
        },
        {
            key: "documentId",
            name: this.props.t("grid_view_header_labels.invoice_number"),
            eventType: "view"
        },
        {
            key: "total",
            name: this.props.t("grid_view_header_labels.invoice_amount"),
            textAlignment: "right",
            contentProvider: (datum_) => {
                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>;
            }
        },
        {
            key: "documentDate",
            name: this.props.t("grid_view_header_labels.invoice_date"),
            contentProvider: (datum_) => {
                return localDateFormat(datum_.documentDate);
            }
        },
        {
            key: "documentDueDate",
            name: this.props.t("grid_view_header_labels.due_date"),
            contentProvider: (datum_) => {
                return `${localDateFormat(datum_.documentDueDate)} (${addDaysInSufixForPaymentTerm(datum_.supplyChain.net)})`;
            }
        },
        {
            key: "status",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{this.props.t("status_label.submitted")}</div>;
                    case 1:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{this.props.t("status_label.awaiting_approve_reject")}</div>;
                    case 2:
                        return <div className="progress-text-color">{this.props.t("status_label.approved")}</div>;
                    case 3:
                        return <div className="archived-text-color">{this.props.t("status_label.rejected")}</div>;
                    case 99:
                        return <div className="progress-text-color">{this.props.t("status_label.in_funding")}</div>;
                    case 100:
                        return <div className="archived-text-color">{this.props.t("status_label.archived")}</div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
        {
            key: "action",
            name: this.props.t("grid_view_header_labels.type"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>{this.props.t("status_label.open")}</div>;
                    case 1:
                        return this.props.t("status_label.buyer_approval");
                    case 2:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>{this.props.t("status_label.open")}</div>;
                    case 3:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>{this.props.t("status_label.archived")}</div>;
                    case 99:
                        return <div ><NavLink className="anchorstyle" style={{ color: "#5F9FBC"}} to="/fundingrequest">{datum_.fundingReqId}</NavLink></div>;
                    case 100:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>{this.props.t("status_label.archived")}</div>;
                    default:
                        return this.props.t("status_label.unknown");
                }
            },
            eventType: (datum_) => {
                switch (datum_.status) {
                    case 0:
                    case 2:
                    case 3:
                        return "open";
                    case 99:
                    case 100:
                        return "";
                    default:
                        return "";
                }
            }
        }
    ];

    _supplierColumnsSpecification = [
        {
            key: "buyerName",
            name: this.props.t("grid_view_header_labels.buyer_name")
        },
        {
            key: "documentId",
            name: this.props.t("grid_view_header_labels.invoice_number"),
            eventType: "view"
        },
        {
            key: "total",
            name: this.props.t("grid_view_header_labels.invoice_amount"),
            textAlignment: "right",
            contentProvider: (datum_) => {
                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>;
            }
        },
        {
            key: "documentDate",
            name: this.props.t("grid_view_header_labels.invoice_date"),
            contentProvider: (datum_) => {
                return localDateFormat(datum_.documentDate);
            }
        },
        {
            key: "documentDueDate",
            name: this.props.t("grid_view_header_labels.due_date"),
            contentProvider: (datum_) => {
                return `${localDateFormat(datum_.documentDueDate)} (${addDaysInSufixForPaymentTerm(datum_.supplyChain.net)})`;
            }
        },
        {
            key: "status",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{this.props.t("status_label.awaiting_submission")}</div>;
                    case 1:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{this.props.t("status_label.awaiting_approve_reject")}</div>;
                    case 2:
                        return <div className="progress-text-color">{this.props.t("status_label.approved")}</div>;
                    case 3:
                        return <div className="archived-text-color">{this.props.t("status_label.rejected")}</div>;
                    case 99:
                        return <div className="progress-text-color">{this.props.t("status_label.in_funding")}</div>;
                    case 100:
                        return <div className="archived-text-color">{this.props.t("status_label.archived")}</div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
        {
            key: "action",
            name: this.props.t("grid_view_header_labels.type"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                            return <div><button className="submit-invoice-button"
                            >{this.props.t("status_label.submit_invoice")}</button></div>;                      
                    case 1:
                        return this.props.t("status_label.buyer_approval");
                    case 2:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>{this.props.t("status_label.open")}</div>;
                    case 3:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>{this.props.t("status_label.archived")}</div>;
                    case 99:
                        return <div style={{ color: "#5F9FBC", fontWeight: "600" }}>{datum_.fundingReqId}</div>;
                    case 100:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>{this.props.t("status_label.archived")}</div>;
                    default:
                        return this.props.t("status_label.unknown");
                }

            },
            eventType: (datum_) => {
                switch (datum_.status) {
                    case 0:
                            return "SubmitInvoice";
                    case 1:
                        return "";
                    case 2:
                        return "open";
                    case 3:
                    case 99:
                    case 100:
                        return "";
                    default:
                        return "";
                }

            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loadingText: "",
            allSupplyChain: [],
            supplyChains: [],
            selectedItems: [],
            isAllCheckboxEnabled: true,
            suppliers: [],
            buyers:[],
            allBuyerUsers:[],
            isViewPopupOpen: false,
            filtersApplied: {},
            isResetFilterEnabled:false,
            isDeleteInvoice:false,
            isApprovePopupOpen:false,
            isrejectPopupOpen:false,
            isFileViewerModal:false,
            isFinancialDocument:false,
            fileViewerContent:null,
            isUploadSupplyChainOpen:false,
            isCFRModalOpen: false,
            isDeleteSupplyChain:false,
            isSubmitSupplyChainToBuyer:false,
            isInviteSupplier:false,
            selectedSC:null,
            isViewPopupOpen:false,
            isApprovePopupOpen:false,
            isrejectPopupOpen:false,
            invitationMessage: "",
            steps: "",
            activeStep: '',
            statusCounts: [],
        };
    }
    componentDidMount() {
        if(compareRole(this.user.currentUserRole(),enumRole.Supplier)){
            this.getAllBuyerUsers();
        }
        else{
            this.getSupplyChain();
        }
       
    }

    componentDidUpdate(prevProps_, prevState) {
        if (prevProps_.isUpload !== this.props.isUpload) {
            this.setState({ isUploadSupplyChainOpen: this.props.isUpload });
        }
        if (prevProps_.isCreateFundingRequest != this.props.isCreateFundingRequest) {
            if(this.props.isCreateFundingRequest){
                const {selectedItems}=this.state;
                const _creditLimitValidationDetails = selectedItems.map(doc_ => doc_.tradingPartnerId)
                this.validateExistingContract(_creditLimitValidationDetails);
            }
            else{
                this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
            }
        }
    }

    validateExistingContract=async(_tpList)=>{
        const _createFundingRequestDetails=await this.api.checkCreateFundingRequest(_tpList,transactionType.SupplyChain);
        if(_createFundingRequestDetails && _createFundingRequestDetails.isCreateFundingRequest){
            this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest,isContractPresentInSystem: _createFundingRequestDetails && _createFundingRequestDetails.isContractPresentInSystem});
        }
        else{
            AddModal("Invalid Funding request",
                <div className='d-flex flex-column'>
                    <div>
                        {this.props.t("invalid_funding_request_validation_msg")}<br />
                    </div>
                    {_createFundingRequestDetails && _createFundingRequestDetails.withContractTradingPartners && _createFundingRequestDetails.withContractTradingPartners.length > 0 && (
                        <div>
                            <div><b>{this.props.t("following_customer_msg")}:</b></div>
                            <div>
                                <ul style={{listStyleType:"none"}}>
                                    {_createFundingRequestDetails.withContractTradingPartners.map(tr_=>{
                                        return <li>{tr_.name}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                    {_createFundingRequestDetails && _createFundingRequestDetails.withoutContractTradingPartners && _createFundingRequestDetails.withoutContractTradingPartners.length > 0 && (
                        <div>
                            <div><b>{this.props.t("following_customer_without_contract_msg")}:</b></div>
                            <div>
                                <ul style={{listStyleType:"none"}}>
                                    {_createFundingRequestDetails.withoutContractTradingPartners.map(tr_=>{
                                        return <li>{tr_.name}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
                , null, false)
            .then((_isSubmitted)=>{
                this.closeCreateFundingRequestModal();
            })
            .catch(()=>{
                this.closeCreateFundingRequestModal();
            })
        }
    }

    closeCreateFundingRequestModal=()=>{
        this.setState({ isCFRModalOpen: false, selectedItems: null });
        this.props.createFundingRequest();
        this.props.hasCreateFundingRequest(0);
    }

    getAllBuyerUsers=async()=>{
        let _allBuyers=await this.api.getAllFunder({ "role": "Buyer" });
        _allBuyers=_allBuyers.filter(s_=>s_.status==1);
        this.setState({
            allBuyerUsers:_allBuyers
        },()=>{
            this.getSupplyChain();
        })
    }

    async getSupplyChain() {
        const scDocs_ = await this.api.docGetSupplyChain();
        if(compareRole(this.user.currentUserRole(),enumRole.Supplier)){
            this.setSupplyChainForSupplier(scDocs_);
        }
        else{
            this.setSupplyChain(scDocs_);
        }
        // Calculate the counts for each status up to 'statusUpto'
        const countsFor = 'SUPP_SC'
        const statusUpto = 100; //Define the maximum status value to be calculated
        const counts = calculateStatusCounts(scDocs_, statusUpto, countsFor);
        this.setState({ statusCounts: counts });
    }

    /* Bind data value for buyer user */
    async setSupplyChain(docs_) {
        let isAllCheckbox = true;
        let suppliers_ = [];
        const _supplyChain = docs_
            .map(sc_ => {
                if (sc_.status != 100) {
                    let isRowSelect = true;
                    let isExistSupplier = suppliers_.filter(b => b == sc_.supplierName);
                    if (isExistSupplier.length == 0) {
                        suppliers_.push(sc_.supplierName);
                    }
                    if (sc_.status == 0 || sc_.status==2) {
                        isRowSelect = false;
                        isAllCheckbox = false;
                    }
                    sc_.isRowSelect = isRowSelect;
                }

                if (!sc_.supplierName) {
                    sc_.supplierName = "";
                }

                switch (sc_.status) {
                    case 0:
                        sc_.statusText = this.props.t("status_label.submitted");
                        break;
                    case 99:
                        sc_.statusText = this.props.t("status_label.in_funding");
                        break;
                    case 100:
                        sc_.statusText = this.props.t("status_label.archived");
                        break;
                    default:
                        sc_.statusText = this.props.t("status_label.unknown");
                }

                return sc_;
            });
        suppliers_ = suppliers_.sort();
        this.setState({ allSupplyChain: _supplyChain, isAllCheckboxEnabled: isAllCheckbox, suppliers: suppliers_ }, () => {
            this._resetFilter();
            this.onFilterChange();
        });
    }

        /*Bind data value for supplier user */
        async setSupplyChainForSupplier(docs_) {
            const {allBuyerUsers}=this.state;
            let isAllCheckbox = true;
            let buyers_ = [];
            const _supplyChain = docs_
                .map(sc_ => {
                    if (sc_.status != 100) {
                        let isRowSelect = true;
                        let isExistBuyer = buyers_.filter(b => b == sc_.supplierName);
                        if (isExistBuyer.length == 0) {
                            buyers_.push(sc_.buyerName);
                        }
                        if (sc_.status == 0) {
                            isRowSelect = false;
                            isAllCheckbox = false;
                        }
                        sc_.isRowSelect = isRowSelect;
                    }
    
                    if (!sc_.buyerName) {
                        sc_.buyerName = "";
                    }
                    sc_.isBuyerAvailable=allBuyerUsers.filter(b_=>b_.name==sc_.buyerName).length>0;
    
                    switch (sc_.status) {
                        case 0:
                            sc_.statusText = this.props.t("status_label.awaiting_submission");
                            break;
                        case 1:
                            sc_.statusText = this.props.t("status_label.awaiting_buyer_approval");
                            break;
                        case 2:
                            sc_.statusText = this.props.t("status_label.buyer_approved");
                            break;
                        case 3:
                            sc_.statusText = this.props.t("status_label.buyer_rejected");
                            break;
                        case 99:
                            sc_.statusText = this.props.t("status_label.in_funding");
                            break;
                        case 100:
                            sc_.statusText = this.props.t("status_label.archived");
                            break;
                        default:
                            sc_.statusText = this.props.t("status_label.unknown");
                    }
    
                    return sc_;
                });
            buyers_ = buyers_.sort();
            this.setState({ allSupplyChain: _supplyChain, isAllCheckboxEnabled: isAllCheckbox, buyers: buyers_ }, () => {
                this._resetFilter();
                this.onFilterChange();
            });
        }

    archived(e) {
        const { filteredSupplyChain, supplyChains } = this.state;
        const scDocs = filteredSupplyChain.length > 0 ? filteredSupplyChain : supplyChains;
        if (e.target.checked) {
            const archivedInvoices = scDocs.filter(inv => (inv.status == 100 || inv.status == 3));
            this.setState({ filteredSupplyChain: archivedInvoices, isAllCheckboxEnabled: true, isArchived: true });
        }
        else {
            this.getSupplyChain();
        }
    }

    onFilterChange(status) {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        if (status === undefined && this.state.filtersApplied.status?.length) {
            status = this.state.filtersApplied.status[0].value;
        }

        let filteredSupplyChain = this.state.allSupplyChain.filter(sc_ => {
            if (!this.state.filtersApplied.archived) {
                if (sc_.status == 100 || sc_.status == 3) {
                    sc_.isRowSelect = true;
                    return false;
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 100)) {
                    if (![3, 100].includes(sc_.status)) {
                        return false;
                    }
                }
            }

            if(compareRole(this.user.currentUserRole(),"supplier")){
                if (this.state.filtersApplied.buyers && this.state.filtersApplied.buyers.length) {
                    if (sc_.buyerName != this.state.filtersApplied.buyers[0].text) {
                        return false;
                    }
                }
            }
            else{
                if (this.state.filtersApplied.suppliers && this.state.filtersApplied.suppliers.length) {
                    if (sc_.supplierName != this.state.filtersApplied.suppliers[0].text) {
                        return false;
                    }
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 23)) {
                    if (![2].includes(sc_.status)) {
                        return false;
                    }
                }  if (this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 20)) {
                    if (![0, 2].includes(sc_.status)) {
                        return false;
                    }
                }
            }
            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length && status !==23 && status !==20 && status !==100) {
                if (sc_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                }
            }

            if (this.state.filtersApplied.invoiceDate &&
                this.state.filtersApplied.invoiceDate.length &&
                this.state.filtersApplied.invoiceDate[0].startDate) {
                let startDate = this.state.filtersApplied.invoiceDate[0].startDate;
                let endDate = this.state.filtersApplied.invoiceDate[0].endDate;
                endDate.setHours(23);
                endDate.setMinutes(59);
                let date = new Date(sc_.documentDate);
                if (date < startDate || date > endDate) {
                    return false;
                }
            }

            if (filteredText) {
                if (!(sc_.supplierName.toLowerCase().indexOf(filteredText) >= 0 ||
                    sc_.documentId.toLowerCase().indexOf(filteredText) >= 0 ||
                    sc_.statusText.toLowerCase().indexOf(filteredText) >= 0)) {
                    return false;
                }
            }

            return true;
        });

        this.setState({
            supplyChains: filteredSupplyChain
        }, () => {
            this._hasResetFilterEnabled();
            this.openNotificationActionModal();
        });
    }

    openNotificationActionModal=()=>{
        const _notificationIds=NotificationService._id;
        if(_notificationIds && _notificationIds.length==1){
            if(this.state.supplyChains && this.state.supplyChains.length>0){
                const _notificationFR=this.state.supplyChains.filter(f_=>f_.documentId==_notificationIds[0]);
                NotificationService.emit(_notificationIds[0]);
                if (_notificationFR && _notificationFR.length > 0) {
                    const selectedTCF = _notificationFR[0];
                    this.setState({
                        isViewPopupOpen: true,
                        selectedSC: selectedTCF,
                    });
                }
            }
        }
    }

    handleStepClick = (step, status) => {
        this.setState(prevState => {
            const isSameStep = prevState.activeStep === step;
            return {
                activeStep: isSameStep ? "" : step,
                filtersApplied: isSameStep
                    ? {
                        ...prevState.filtersApplied,
                        textFilter: "",
                        archived: false,
                        customers: [],
                        status: []
                    }
                    : {
                        ...prevState.filtersApplied,
                        status: [{ value: status }],
                        archived: status == 3 || status == 100 ? true : false,
                    }
            };
        }, () => {
            this.onFilterChange(status);
        });
    };

    generateStepData = () => {
        const { statusCounts } = this.state;

        let stepTitles;
        let statusToInclude;
        let statuRequiredAction;
        let statusRequiredDropdown;
        let dropdownItems;

        if (compareRole(this.user.currentUserRole(), enumRole.Supplier)) {
            stepTitles = [
            this.props.t("stepper_labels.sup_sc.awaiting_submission"),
            this.props.t("stepper_labels.sup_sc.awaiting_approval"),
            this.props.t("stepper_labels.sup_sc.approved_rejected"),
            this.props.t("stepper_labels.sup_sc.in_funding"),
            this.props.t("stepper_labels.sup_sc.archived"),
        ];
        dropdownItems = {
            100: [
                { label: this.props.t("stepper_labels.buy_sc.drop_down.archived"), countKey: '101' },
                { label: this.props.t("stepper_labels.buy_sc.drop_down.buyer_rejected"), countKey: '3' },
            ],
        };
            statusToInclude = [0, 1, 23, 99, 100];
            statuRequiredAction = [0, 2]; // Status to show Action Icon
            statusRequiredDropdown = [100];
        } else {
            stepTitles = [
                this.props.t("stepper_labels.sup_sc.awaiting_approval"),
                this.props.t("stepper_labels.buy_sc.awaiting_fr_creation"),
                this.props.t("stepper_labels.sup_sc.in_funding"),
                this.props.t("stepper_labels.sup_sc.archived"),
            ];
            dropdownItems = {
                20: [
                    { label: this.props.t("stepper_labels.buy_sc.drop_down.buyer_submitted"), countKey: '0' },
                    { label: this.props.t("stepper_labels.buy_sc.drop_down.buyer_approved"), countKey: '2' },
                ],
                100: [
                    { label: this.props.t("stepper_labels.buy_sc.drop_down.archived"), countKey: '101' },
                    { label: this.props.t("stepper_labels.buy_sc.drop_down.buyer_rejected"), countKey: '3' },
                ],
               
               
            };
            statusToInclude = [1, 20, 99, 100];
            statuRequiredAction = [1, 20]; // Status to show Action Icon
            statusRequiredDropdown = [20, 100];
        }
       
        return generateSteps(statusCounts, stepTitles, statusToInclude, statuRequiredAction, statusRequiredDropdown, dropdownItems);
    }

    render() {
        const { suppliers,
            isLoading,
            isUploadSupplyChainOpen,
            isCFRModalOpen,
            isDeleteSupplyChain,
            isViewPopupOpen,
            isSubmitSupplyChainToBuyer,
            isApprovePopupOpen,
            isrejectPopupOpen,
            isInviteSupplier,
            isInvitionSentToSupplier,
            buyers,
            selectedSC,
            invitationMessage,
            activeStep
        } = this.state;
        const supplyChains = this.state.supplyChains;

        const steps = this.generateStepData(); 

        return (
            <AppProvider>
                <AppState.Consumer>
                    {appState => (
                        <React.Fragment>
                            <FullSpinner
                                isSpinning={isLoading}
                            />
                            <FullSpinner
                                isSpinning={isLoading? false : isInvitionSentToSupplier}
                                confirmationLabel={invitationMessage}
                            />
                            {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                                onClose={() => {
                                    this.setState({ isFileViewerModal: false, fileViewerContent: null });
                                }}
                                data={this.state.fileViewerContent}
                                isFinancialDocument={false}
                            ></FileViewerModal>}
                           {(isUploadSupplyChainOpen  || isCFRModalOpen || isDeleteSupplyChain ||
                            isViewPopupOpen ||isSubmitSupplyChainToBuyer || isApprovePopupOpen ||
                             isrejectPopupOpen || isInviteSupplier) && (
                                <div className="terms-checkbox-container">
                                    {(isUploadSupplyChainOpen || isViewPopupOpen) && <UploadSupplyChainModal
                                        isReadOnly={isViewPopupOpen}
                                        onClose={() => {
                                            this.setState({ isUploadSupplyChainOpen: false ,isViewPopupOpen:false, selectedSC:null });
                                            if(isUploadSupplyChainOpen){
                                                this.props.upload();
                                                this.setState({selectedItems:[]});
                                                this.props.hasCreateFundingRequest(0);
                                            }
                                        }}
                                        supplyChainList={supplyChains}
                                        onSubmit={async(formData) => {
                                            this.api.docSubmitSupplyChain(formData).then(r => {
                                                this.setState({
                                                    isUploadSupplyChainOpen: false,
                                                    selectedItems:[]
                                                }, () => {
                                                    this.getSupplyChain();
                                                });
                                                this.props.upload();
                                                this.props.hasCreateFundingRequest(0);
                                            });
                                        }}
                                        onSupplierInvite={() => {
                                            this.setState({
                                                isInviteSupplier:true,
                                                isViewPopupOpen:false
                                            })
                                        }
                                        }
                                        data={this.state.selectedSC}
                                        t={this.props.t}
                                    />}
                                    {isCFRModalOpen && this.state.selectedItems && <CommonCreateFundingRequestModal
                                        isReadOnly={false}
                                        data={this.state.selectedItems}
                                        isGetExistingContractList={this.state.isContractPresentInSystem}
                                        onClose={() => {
                                            this.getSupplyChain();
                                            this.setState({ isCFRModalOpen: false, selectedItems: [] });
                                            this.props.createFundingRequest();
                                            this.props.hasCreateFundingRequest(0);
                                        }}
                                        t={this.props.t}
                                    />}
                                    {
                                        isDeleteSupplyChain && (
                                            <CommonConfirmationModal
                                                title={this.props.t("transactions_page.supply_chain.delete_supply_chain")+": #" + this.state.selectedSC.documentId}
                                                submitText={this.props.t("button_names.delete")}
                                                onClose={() => {
                                                    this.getSupplyChain();
                                                    this.setState({ isDeleteSupplyChain: false, selectedSC: null,selectedItems:[] });
                                                    this.props.hasCreateFundingRequest(0);
                                                }}
                                                onSubmit={async () => {
                                                    await this.api.deleteDocument(this.state.selectedSC);
                                                    this.setState({selectedItems:[]});
                                                    this.props.hasCreateFundingRequest(0);
                                                }}
                                            >
                                                {this.props.t("transactions_page.supply_chain.delete_supply_chain_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }
                                    {
                                        isSubmitSupplyChainToBuyer && (
                                            <CommonConfirmationModal
                                                title={this.props.t("transactions_page.supply_chain.submit_supply_chain")+": #" + this.state.selectedSC.documentId}
                                                submitText={this.props.t("button_names.submit")}
                                                onClose={() => {
                                                    this.getSupplyChain();
                                                    this.setState({ isSubmitSupplyChainToBuyer: false, selectedSC: null });
                                                }}
                                                onSubmit={async () => {
                                                    await this.api.SubmitSupplyChianDocumentToBuyer(this.state.selectedSC);
                                                }}
                                            >
                                                {this.props.t("transactions_page.supply_chain.submit_supply_chain_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }
                                    {
                                        isApprovePopupOpen && selectedSC && (
                                            <CommonConfirmationModal
                                                title={this.props.t("transactions_page.supply_chain.approve_supply_chain")+": #" + selectedSC.documentId}
                                                submitText={this.props.t("button_names.approve")}
                                                onClose={() => {
                                                    this.setState({ isApprovePopupOpen: false, selectedSC: null });
                                                    this.getSupplyChain();
                                                }}
                                                onSubmit={() => {
                                                    const invoiceToApprove = {
                                                        id: selectedSC._id,
                                                        reject: false,
                                                    };
                                                    return this.api.docApproveSupplyChain(invoiceToApprove).then(r => {
                                                        this.getSupplyChain();
                                                        this.setState({ isApprovePopupOpen: false, selectedSC: null });
                                                    });
                                                }}
                                            >
                                                {this.props.t("transactions_page.supply_chain.approve_supply_chain_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }
                                    {
                                        isrejectPopupOpen && selectedSC && (
                                            <CommonConfirmationModal
                                                title={this.props.t("transactions_page.supply_chain.reject_supply_chain")+": #" + selectedSC.documentId}
                                                submitText={this.props.t("button_names.reject")}
                                                onClose={() => {
                                                    this.setState({ isrejectPopupOpen: false, selectedSC: null });
                                                    this.getSupplyChain();
                                                }}
                                                onSubmit={() => {
                                                    const invoiceToReject = {
                                                        id: selectedSC._id,
                                                        reject: true,
                                                    };
                                                    return this.api.docRejectSupplyChain(invoiceToReject).then(r => {
                                                        this.getSupplyChain();
                                                        this.setState({ isrejectPopupOpen: false, selectedSC: null });
                                                    });
                                                }}
                                            >
                                                {this.props.t("transactions_page.supply_chain.reject_supply_chain_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }
                                    {
                                        isInviteSupplier && selectedSC && (
                                            <CommonConfirmationModal
                                                title={this.props.t("transactions_page.supply_chain.invite_supplier")+": "+ selectedSC.supplyChain.supperDetails.supplierName}
                                                submitText={this.props.t("button_names.invite")}
                                                onClose={() => {
                                                    this.getSupplyChain();
                                                    this.setState({ isInviteSupplier: false, selectedSC: null,isViewPopupOpen:false });
                                                }}
                                                onSubmit={async () => {
                                                    this.setState({isInvitionSentToSupplier:true});
                                                    const customers = {
                                                        "documentId": selectedSC._id,
                                                        "supplierName": selectedSC.supplyChain.supperDetails.supplierName,
                                                        "supplierEmail": selectedSC.supplyChain.supperDetails.supplierEmail,
                                                    }
                                                    this.api.sendInviteToSupplier(customers).then(res => {
                                       
                                                        if (res.message === "Validation failed" && res.details.length > 0) {
                                                            AddModal(this.props.t("transactions_page.supply_chain.invite_failed"), <div>{res.details?res.details[0].message:res.message}</div> , null, false);
                                                            this.setState({isInvitionSentToSupplier:false,invitationMessage:""});
                                                        }
                                                        else{
                                                            this.setState({isInvitionSentToSupplier:false,invitationMessage:this.props.t("transactions_page.supply_chain.invitation_sent")});
                                                        }
                                                    });
                                                }}
                                            >
                                                {this.props.t("transactions_page.supply_chain.invite_supplier_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }
                                </div>
                            )}
                            <StatusStepper
                                activeStep={activeStep}
                                steps={steps}
                                handleStepClick={this.handleStepClick}
                                statusCounts={this.state.statusCounts}
                            />
                            {compareRole(this.user.currentUserRole(), enumRole.Supplier) ? (
                                <>
                                    <DataGrid
                                        title={this.props.t("transactions_page.supply_chain.title")}
                                        emptydataLabel={this.props.t("transactions_page.supply_chain.no_document_msg")}
                                        data={supplyChains}
                                        columns={this._supplierColumnsSpecification}
                                        onSelectionChange={(items) => this._onSelectionChange(items)}
                                        isRowsNonSelectable={true}
                                        isNoPagination={true}
                                        isGridSearch={true}
                                        isNoMoreLink={false}
                                        selectedItems={this.state.selectedItems}
                                        appliedFilter={this.state.filtersApplied}
                                        resetFilters={() => this._resetFilter()}
                                        searchText={(text_) => {
                                            this.state.filtersApplied.textFilter = text_;
                                            this.setState({
                                                filtersApplied: this.state.filtersApplied
                                            }, () => this.onFilterChange());
                                        }}
                                        popoverItems={[
                                            { key: "View", label: this.props.t("grid_view_action_labels.view_supply_chain")},
                                            { key: "deleteSC", label: this.props.t("grid_view_action_labels.delete_supply_chain") }
                                        ]}
                                        onPopoverClick={(key_, row_) => {
                                            if (key_ == "View") {
                                                this.setState({
                                                    isViewPopupOpen: true,
                                                    selectedSC: row_
                                                });
                                            }
                                            else if (key_ == "deleteSC") {
                                                this.setState({
                                                    isDeleteSupplyChain: true,
                                                    selectedSC: row_
                                                })
                                            }
                                        }}
                                        filterComponent={
                                            <React.Fragment>
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.buyer")}
                                                    type="options"
                                                    options={
                                                        buyers.map(cus => {
                                                            return ({ value: cus, text: cus });
                                                        })
                                                    }
                                                    value={this.state.filtersApplied.buyers}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.buyers = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.date_range")}
                                                    type="date_range"
                                                    value={this.state.filtersApplied.invoiceDate}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.invoiceDate = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                        isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                                        handleTableCellClick={(dataRow, type, index) => {
                                            if (type == "view" || type == "open") {
                                                this.setState({
                                                    isViewPopupOpen: true,
                                                    selectedSC: dataRow,
                                                });
                                            }
                                            else if (type == "SubmitInvoice") {
                                                if(dataRow.isBuyerAvailable){
                                                    this.setState({
                                                        isSubmitSupplyChainToBuyer: true,
                                                        selectedSC: dataRow,
                                                    });
                                                }
                                                else{
                                                    AddModal(dataRow.buyerName +" "+this.props.t("is_not_available"), <div>
                                                    <b>{dataRow.buyerName}</b> {this.props.t("buyer_is_not_registered_msg")} <b>support@ledgerfunding.com</b></div> , null, false);
                                                }

                                            }
                                        }}
                                        isResetFilterEnabled={this.state.isResetFilterEnabled}
                                    />
                                </>
                            ) : (
                                <>
                                    <DataGrid
                                        title={this.props.t("transactions_page.supply_chain.buyer_title")}
                                        emptydataLabel={this.props.t("transactions_page.supply_chain.no_document_msg")}
                                        data={supplyChains}
                                        columns={this._columnsSpecification}
                                        onSelectionChange={(items) => this._onSelectionChange(items)}
                                        isRowsNonSelectable={false}
                                        isNoPagination={true}
                                        isGridSearch={true}
                                        isNoMoreLink={false}
                                        selectedItems={this.state.selectedItems}
                                        appliedFilter={this.state.filtersApplied}
                                        resetFilters={() => this._resetFilter()}
                                        searchText={(text_) => {
                                            this.state.filtersApplied.textFilter = text_;
                                            this.setState({
                                                filtersApplied: this.state.filtersApplied
                                            }, () => this.onFilterChange());
                                        }}
                                        popoverItems={[
                                            { key: "View", label: this.props.t("grid_view_action_labels.view_supply_chain") },
                                            { key: "inviteSupplier", label: this.props.t("grid_view_action_labels.invite_supplier") },
                                            { key: "approveSC", label: this.props.t("grid_view_action_labels.approve_supply_chain") },
                                            { key: "rejectSC", label:this.props.t("grid_view_action_labels.reject_supply_chain") },
                                            { key: "deleteSC", label: this.props.t("grid_view_action_labels.delete_supply_chain") }
                                        ]}
                                        onPopoverClick={(key_, row_) => {
                                            if (key_ == "View") {
                                                this.setState({
                                                    isViewPopupOpen: true,
                                                    selectedSC: row_
                                                });
                                            }
                                            else if (key_ == "deleteSC") {
                                                this.setState({
                                                    isDeleteSupplyChain: true,
                                                    selectedSC: row_
                                                })
                                            }
                                            else if (key_ == "approveSC") {
                                                this.setState({
                                                    isApprovePopupOpen: true,
                                                    selectedSC: row_
                                                })
                                            }
                                            else if (key_ == "rejectSC") {
                                                this.setState({
                                                    isrejectPopupOpen: true,
                                                    selectedSC: row_
                                                })
                                            }
                                            else if(key_=="inviteSupplier"){
                                                this.setState({
                                                    isInviteSupplier: true,
                                                    selectedSC: row_
                                                })
                                            }
                                        }}
                                        filterComponent={
                                            <React.Fragment>
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.supplier")}
                                                    type="options"
                                                    options={
                                                        suppliers.map(cus => {
                                                            return ({ value: cus, text: cus });
                                                        })
                                                    }
                                                    value={this.state.filtersApplied.suppliers}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.suppliers = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.date_range")}
                                                    type="date_range"
                                                    value={this.state.filtersApplied.invoiceDate}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.invoiceDate = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                        isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                                        handleTableCellClick={(dataRow, type, index) => {
                                            if (type == "view" || type == "open") {
                                                this.setState({
                                                    isViewPopupOpen: true,
                                                    selectedSC: dataRow,
                                                });
                                            }
                                        }}
                                        isResetFilterEnabled={this.state.isResetFilterEnabled}
                                    />
                                </>
                            )}
                            
                        </React.Fragment>
                    )}
                </AppState.Consumer>
            </AppProvider>
        );
    }

    _onSelectionChange(items) {
        this.setState({ selectedItems: items });
        this.props.hasCreateFundingRequest(items.length);
    }

    _resetFilter = () => {
        this.state.filtersApplied.textFilter = "";
        this.state.filtersApplied.archived = false;
        this.state.filtersApplied.suppliers = [];
        this.state.filtersApplied.buyers = [];
        this.state.filtersApplied.status = [];
        this.state.filtersApplied.invoiceDate = [{
            startDate: null,
            endDate: null,
            key: "selection"
        }];
        this.setState({
            filtersApplied: this.state.filtersApplied,
            activeStep: ""
        }, () => this.onFilterChange());
    };

    _hasResetFilterEnabled = () => {
        if (this.state.filtersApplied.textFilter != "" || this.state.filtersApplied.archived || this.state.filtersApplied.suppliers.length > 0
            || this.state.filtersApplied.status.length > 0 || (this.state.filtersApplied.invoiceDate[0].startDate && this.state.filtersApplied.invoiceDate[0].endDate)) {
            this.setState({ isResetFilterEnabled: true });
        }
        else {
            this.setState({ isResetFilterEnabled: false });
        }
    };
}


