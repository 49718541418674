import React, { Component } from 'react'
import Dropzone from "react-dropzone";
import SignaturePopver from "../PopOver/SignaturePopver";
import API, {API_ENDPOINT_SUPPLIER} from "../../../services/API";
import {toast} from 'react-toastify';
import { localDateFormat } from '../../../services/Utilities';

export default class SignerAgreementDetails extends Component {

    api= new API();

    constructor(props) {
        super(props);
        this.state = {
            eSignature:"",
            isEnableSignBtn:false,
            isAgreeChecked:false,
            eVerificationDetails:{},
            isSendingVCode:false,
            isTokenGenrated:false,
            isVerificationTokenExpired:false,
            minutes:5,
            seconds:0
        }
    }

    componentDidMount(){
        toast.configure();
    }

    async notify(message) {
        toast.info(message)
    }

    handleChange=(event)=>{
        const name = event.target.name;
        const value = event.target.value;
        if(name=="eSignature"){
            this.setState({eSignature:value},async()=>{
                const isSignBtnEnabled=await this.verifiyECodeDetails();
                if(isSignBtnEnabled){
                    this.setState({isEnableSignBtn:true});
                }
                else{
                    this.setState({isEnableSignBtn:false});
                }
            });
        }
    }

    onAgreeChange= async (event)=>{
        const isAgreeChecked_=event.target.checked;
        this.setState({isAgreeChecked:isAgreeChecked_});
        if(isAgreeChecked_){
            await this.getVerificationCode(false);
        }
    }

    getVerificationCode=async (isResend_)=>{
        const {contracts}=this.props;
        this.setState({isSendingVCode:true});
        const _codeDetails={
            isResend:isResend_,
            fundingReqId:contracts.fundingReqId,
            fundingContractId:contracts.fundingContractId,
            _id:this.state.eVerificationDetails && this.state.eVerificationDetails._id?this.state.eVerificationDetails._id:""
        }
        const codeStatus=await this.api.getVerificationCode(_codeDetails);
        if(codeStatus && codeStatus.verificationCode){
            this.notify("Verification code sent your email address. Please check your spam and junk folders as well");
            this.setState({isTokenGenrated:true,eVerificationDetails:codeStatus,minutes:5,seconds:0,isVerificationTokenExpired:false});
            this._countDownTimer = setInterval(async () => {
                const { seconds, minutes } = this.state

                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this._countDownTimer);
                        this.setState({isVerificationTokenExpired:true});
                        this.notify("Verification code is expired. Please click resend code link.");
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                    }
                } 
            }, 1000);
        }
        this.setState({isSendingVCode:false});
    }

    verifiyECodeDetails=async()=>{
        const {eVerificationDetails}=this.state;
        const _eSignatureValue=this.state.eSignature;
        let isVerified=false;
        if(eVerificationDetails.verificationCode==_eSignatureValue){
            return true;
        }
        else{
            return false;
        }
    }

    render() {
        const {
            data,
            isFunderCheckMarkEnabled,
            supplier,
            funder,
            isDropZoneEnabled,
            isSupplierCheckMarkEnabled,
            contracts,
            isSupplierConstent,
            isFunderAgreement
        }=this.props;
        return (
            <React.Fragment>
                {isDropZoneEnabled && (
                    <div className="row mx-0 justify-content-center">
                        <div className=' text-center mb-3 w-100'>
                            <Dropzone
                                className="agreement-digital-dropzone"
                                disabled={true}
                                inputProps={{
                                    accept: "application/pdf"
                                }}
                                multiple={false}
                            >
                                <div className="p-3">
                                    <a className="anchorstyle" href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${data.fundingAggrement._id}`} target="_blank"> Agreement</a>
                                </div>
                            </Dropzone>
                        </div>
                    </div>
                )}
                <div className="row mt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                    <div className='col-3'>Company Name</div>
                    <div className='col-3'>Signer</div>
                    <div className='col-3'>Signed/verified</div>
                    {this.state.isSignatureAdded && (
                        <div className='col-3'>Signed On</div>
                    )}
                </div>
                <div className="row mt-2">
                    <div className='col-3' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{funder && funder.name ? funder.name : ""}</div>
                    <div className='col-3' >
                        <div className='d-flex flex-column'>
                            <div>
                                {funder && funder.userName ? funder.userName : ""}
                            </div>
                            <div>
                                {funder && funder.title ? funder.title : ""}
                            </div>
                        </div>
                    </div>
                    {isFunderCheckMarkEnabled && (
                        <React.Fragment>
                            <div className='col-3'>
                                <SignaturePopver
                                    signature={data.funderWalletInfo && data.funderWalletInfo.funderSignature ? data.funderWalletInfo.funderSignature : "Not Signed"}
                                    userName={funder && funder.userName ? funder.userName : ""}
                                    createdAt={data && data.fundingAgreementAt ? data.fundingAgreementAt : new Date()}
                                />
                            </div>
                            <div className='col-3'>
                                {localDateFormat(data && data.fundingAgreementAt ? new Date(data.fundingAgreementAt) : new Date())}
                            </div>
                        </React.Fragment>
                    )}
                    <div className='col-6'>
                        {(!this.state.isSignatureAdded && (this.state.isTokenGenrated && this.state.isAgreeChecked)) && (
                            <React.Fragment>
                                <div className='row mx-0'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="eSignature"
                                            onChange={this.handleChange}
                                            placeholder="One-Time Password"
                                        />
                                    </div>
                                    <div className="form-group pl-3">
                                        <button
                                            className="btn btn-primary"
                                            disabled={!this.state.isEnableSignBtn}
                                            style={{ width: "100%", height: "95%" }}
                                            onClick={(e) => {
                                                this.submitAgreement(e);
                                            }}
                                        >Sign now</button>
                                    </div>
                                    <div className='text-center pl-3'>
                                        {this.state.isVerificationTokenExpired ?
                                            <a className="nav-link active p-0" style={{ cursor: "pointer" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.getVerificationCode(true);
                                                }}
                                            ><h6 className='mt-2'>Resend Code</h6></a>
                                            : <h6 className='mt-2' style={{ color: '#999999' }}>Resend Code in {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</h6>}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        {this.state.isSignatureAdded && (
                            <>
                                <div className='col-3'>
                                    <SignaturePopver
                                        signature={data.funderWalletInfo && data.funderWalletInfo.funderSignature ? data.funderWalletInfo.funderSignature : "Not Signed"}
                                        userName={funder && funder.userName ? funder.userName : ""}
                                        createdAt={data && data.fundingAgreementAt ? data.fundingAgreementAt : new Date()}
                                    />
                                </div>
                            </>
                        )}

                    </div>
                    <div className='col-3'></div>
                    <div className='col-3'></div>
                </div>
                <div className="row">
                    <div className='col-3' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}></div>
                    <div className='col-9'>
                        <div className='d-flex flex-column'>
                                <label>
                                    <input type='checkbox'
                                        name="agreeCondition"
                                        defaultChecked={false}
                                        disabled={this.state.isTokenGenrated}
                                        onChange={this.onAgreeChange}
                                         />
                                    &nbsp;I hereby agree to do business electronically. The signature is electronically recorded and associated with this agreement ({contracts && contracts.fundingContractId ? contracts.fundingContractId : "FC-ID"}), and is designed to be compliant as an "electronic signature" under applicable law.
                                </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className='col-3' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{supplier && supplier.name ? supplier.name : ""}</div>
                    <div className='col-3' >
                        <div className='d-flex flex-column'>
                            <div className="">
                                {supplier && supplier.userName ? supplier.userName : ""}
                            </div>
                            <div>
                                {supplier && supplier.title ? supplier.title : ""}
                            </div>
                        </div>
                    </div>
                    {isSupplierCheckMarkEnabled && (
                        <React.Fragment>
                            <div className='col-3'>
                                <SignaturePopver
                                    userName={supplier && supplier.userName ? supplier.userName : ""}
                                    signature={data.supplierWalletInfo && data.supplierWalletInfo.supplierSignature ? data.supplierWalletInfo.supplierSignature : "Not Signed"}
                                    createdAt={data && data.signedAggrementAt ? new Date(data.signedAggrementAt) : new Date()}
                                />
                            </div>
                            <div className='col-3'>
                                {localDateFormat(data && data.signedAggrementAt ? new Date(data.signedAggrementAt) : new Date())}
                            </div>
                        </React.Fragment>
                    )}
                    {(this.state.isTokenGenrated && isSupplierConstent) && (
                        <div className='col-6'>
                            {(!this.state.isSignatureAdded && (this.state.isTokenGenrated && this.state.isAgreeChecked)) && (
                                <React.Fragment>
                                    <div className='row mx-0'>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="eSignature"
                                                onChange={this.handleChange}
                                                placeholder="One-Time Password"
                                            />
                                        </div>
                                        <div className="form-group pl-3">
                                            <button
                                                className="btn btn-primary"
                                                disabled={!this.state.isEnableSignBtn}
                                                style={{ width: "100%", height: "95%" }}
                                                onClick={(e) => {
                                                    this.submitFunderAgreement(e);
                                                }}
                                            >Sign now</button>
                                        </div>
                                        <div className='text-center pl-3'>
                                            {this.state.isVerificationTokenExpired ?
                                                <a className="nav-link active p-0" style={{ cursor: "pointer" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.getVerificationCode(true);
                                                    }}
                                                ><h6 className='mt-2'>Resend Code</h6></a>
                                                : <h6 className='mt-2' style={{ color: '#999999' }}>Resend Code in {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</h6>}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                            {this.state.isSignatureAdded && (
                                <>
                                    <div className='col-3'>
                                        <SignaturePopver
                                            signature={data.funderWalletInfo && data.funderWalletInfo.funderSignature ? data.funderWalletInfo.funderSignature : "Not Signed"}
                                            userName={funder && funder.userName ? funder.userName : ""}
                                            createdAt={data && data.fundingAgreementAt ? data.fundingAgreementAt : new Date()}
                                        />
                                    </div>
                                </>
                            )}

                        </div>
                    )}
                    {isFunderAgreement && (
                        <React.Fragment>
                            <div className='col-3' style={{ paddingLeft: 5 }}>
                                <svg height="30" width="40"><circle cx="15" cy="15" r="9" stroke="gray" stroke-width="2" fill="white"></circle></svg>
                                <b>Not Signed</b>
                            </div>
                            <div className='col-3'></div>
                        </React.Fragment>
                    )}
                </div>
                {isSupplierConstent && (
                    <div className="row">
                        <div className='col-3'></div>
                        <div className='col-9'>
                            <label>
                                <input type='checkbox'
                                    name="agreeCondition"
                                    defaultChecked={false}
                                    disabled={false}
                                    onChange={this.onAgreeChange}
                                />
                                &nbsp;I hereby agree to do business electronically. The signature is electronically recorded and associated with this agreement ({contracts && contracts.fundingContractId ? contracts.fundingContractId : "FC-ID"}), and is designed to be compliant as an "electronic signature" under applicable law.
                            </label>
                        </div>
                    </div>
                )}


            </React.Fragment>
        )
    }
};
