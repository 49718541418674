import React from 'react';
import PropTypes from 'prop-types';
import { CountryDropdown } from 'react-country-region-selector';

const CountrySelect = ({ label, name, value, onChange, disabled, required, defaultLabel, errorMsg }) => {
    return (
        <React.Fragment>
            <label htmlFor={name} className={`form-label-style ${required ? 'required' : ''}`}>{label}</label>
            <CountryDropdown
                value={value}
                onChange={(val) => onChange(name, val)}
                classes="form-control"
                defaultOptionLabel={defaultLabel? defaultLabel: "Please select country"}
                disabled={disabled}
            />
            {required && !value && <div className="custom-form-error">{errorMsg? errorMsg: "Please select country"}</div>}
        </React.Fragment>
    );
}

CountrySelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

CountrySelect.defaultProps = {
    disabled: false,
    required: false
};

export default CountrySelect;
