import React, { Component } from 'react'
import Popup from '../../common/Auth/Popup';
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import User from '../../../services/User';
import { dollarFormatter, getIndustryWithId, getEntity, dateFormatter, cslDataPreprocessor, fileStatusBadgeText } from '../../../services/Utilities';
import API from '../../../services/API';
import FullSpinner from '../../common/Spinner/FullSpinner';
import { ShortText, localDateFormat } from '../../../services/Utilities';
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import { withTranslation } from 'react-i18next';

class FinancialDocuments extends Component {
    user = new User();
    api = new API();

    constructor(props_) {
        super(props_);
        this.state = {
            supplierFincicalDocResponse: [],
            agingReport: false,
            bankTransactionHistory: {},
            isAgingfile: true,
            isBankStatement: true,
            isRecievablesDetailsAgingReport: false,
            isPayableDetailsAgingReport: false,
            payDetailAgingReport: {},
            recDetailAgingReport: {},
            payableAgingReport: {},
            isPayableAgingReport: true,
            supplierFinancialAddDocs: [],
            receivableAgingReportData: {},
            payableAgingReportData: {},
            isFinancialDocument: false,
            isFileViewerModal: false,
            fileViewerContent: null
        };
    }

    componentDidMount() {
        if (this.props && this.props.data) {

            this.getFinacialDocumentation();
            if (this.props.data.accountingInformation) {
                if (this.props.data.accountingInformation == "quickbooks") {
                    this.getSupplierbanktransactionhistory();
                    this.getReceivableDetailsAgingReport();
                    this.getPayableDetailsAgingReport();
                    this.getPayablesAgingReport();
                }
                else if (this.props.data.accountingInformation == "D365BC") {
                    this.getReceivableDetailsAgingReport();
                    this.getPayableDetailsAgingReport();
                    this.getPayablesAgingReport();
                }
            }
        }
    }


    async getFinacialDocumentation() {
        const res = await this.api.getFunderSupplierDocs(this.props.data._id);
        let isAging = true;
        let isbankStmt = true;
        let isPayableAging = true;
        let aging = res.items.filter(
            x => x.financialDocumentType === "AGING_REPORT"
        );
        if (aging.length == 0) {

            isAging = false;
        }
        let payableAging = res.items.filter(
            x => x.financialDocumentType === "PAYABLE_AGING_REPORT"
        );
        if (payableAging.length == 0) {
            const accountingType_ = this.props.data.accountingInformation;
            if (accountingType_ == "quickbooks" && accountingType_ == "D365BC") {
                isPayableAging = false;
            }
        }
        let bankStatement = res.items.filter(x => x.financialDocumentType == "BANK_STATEMENT_1");
        if (bankStatement.length == 0) {
            isbankStmt = false;
        }
        let financialAdditionalDocs = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
        res.items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() != "additionaldocument");
        this.setState({
            supplierFincicalDocResponse: res.items ? res.items : 0,
            isBankStatement: isbankStmt,
            isAgingfile: isAging,
            isPayableAgingReport: isPayableAging,
            supplierFinancialAddDocs: financialAdditionalDocs
        }, async () => {
            if (!this.state.isAgingfile) {
                if (this.props.data && this.props.data && this.props.data.email) {
                    let agingResponse;
                    const accountingType_ = this.props.data.accountingInformation;
                    const email = this.props.data.email;
                    if (accountingType_ == "quickbooks") {
                        const agingValue = await this.api.getAgingReportFromQBO(email);
                        if (agingValue && !agingValue.msg) {
                            agingResponse = agingValue;
                        }
                    }
                    else if (accountingType_ == "D365BC") {
                        const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(email);
                        if (agingValue_ && !agingValue_.msg) {
                            if (agingValue_.invoices) {
                                agingResponse = agingValue_;
                            } else {
                                agingResponse = {};
                                agingResponse.invoices = agingValue_;
                            }
                        }
                    }
                    else {
                        const erpAggingReport = await this.api.getAgingReportFromERP(email);
                        if (erpAggingReport && erpAggingReport.invoices) {
                            agingResponse = erpAggingReport;
                        }
                    }
                    if (agingResponse && agingResponse.invoices && agingResponse.invoices.length > 0) {

                        this.setState({ receivableAgingReportData: agingResponse, isAgingfile: false });
                    }
                    else {
                        this.setState({ isAgingfile: true });
                    }
                }
            }

        });
    }

    async getSupplierbanktransactionhistory() {
        const res = await this.api.getsupbanktransactionhistory(this.props.data._id);
        this.setState({ bankTransactionHistory: res });
    }

    getDropBoxProfileContent(files, overrideFileName, isFileName_) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                let fileUrl = d.downloadUrl ? (d.downloadUrl.replace("http//", "http://").replace("https//", "https://")
                    .replace("http://http", "http").replace("https://http", "http")) : "";


                return <div className="col-md-6" key={d.filename}> <span ><a className="anchorstyle" href={""} onClick={(e) => {
                    e.preventDefault();
                    if (d.downloadUrl) {
                        d.downloadUrl = fileUrl;
                    }
                    this.setState(
                        {
                            isFileViewerModal: true,
                            fileViewerContent: d,
                            isFinancialDocument: true
                        }
                    );
                }} >{isFileName_ ? d.filename : d.financialDocumentType}</a></span></div>;


            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>;
            });
        }
    }


    getAggingReportDownload = async (e, email, accountingType_) => {
        e.preventDefault();
        this.setState({ agingReport: true });
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (accountingType_ == "quickbooks") {
            const agingValue = await this.api.getAgingReportFromQBO(email);
            if (agingValue && !agingValue.msg) {
                agingResponse = agingValue;
            }
        }
        else if (accountingType_ == "D365BC") {
            const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(email);
            if (agingValue_ && !agingValue_.msg) {
                if (agingValue_.invoices) {
                    agingResponse = agingValue_;
                } else {
                    agingResponse = {};
                    agingResponse.invoices = agingValue_;
                }
            }
        }
        else {
            const erpAggingReport = await this.api.getAgingReportFromERP(email);
            if (erpAggingReport && erpAggingReport.invoices) {
                agingResponse = erpAggingReport;
            }
        }
        if (agingResponse && agingResponse.invoices && agingResponse.invoices.length > 0) {
            const rows = [
                ["Customer Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
            ];
            agingResponse.invoices.forEach(element => {
                let columns = [];
                columns.push('"' + element.customerName + '"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue ? element.currentDue : 0)
                    + (element.due30Days ? element.due30Days : 0)
                    + (element.due45Days ? element.due45Days : 0)
                    + (element.due60Days ? element.due60Days : 0)
                    + (element.due90Days ? element.due90Days : 0)
                    + (element.due120Days ? element.due120Days : 0)
                    + (element.due180Days ? element.due180Days : 0)
                    + (element.due180pluseDays ? element.due180pluseDays : 0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue ? element.currentDue : 0),
                due30 + (element.due30Days ? element.due30Days : 0),
                due45 + (element.due45Days ? element.due45Days : 0),
                due60 + (element.due60Days ? element.due60Days : 0),
                due90 + (element.due90Days ? element.due90Days : 0),
                due120 + (element.due120Days ? element.due120Days : 0),
                due180 + (element.due180Days ? element.due180Days : 0),
                due180pluse + (element.due180pluseDays ? element.due180pluseDays : 0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL");
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45);
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent,
                csvData: rows,
                filename: "Aging_Report.csv"
            };
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            });

            this.setState({ agingReport: false });
        }
        else {
            this.setState({ agingReport: false });
        }
    };

    getPayableAgingReportDownload = async (e, data_, accountingType_) => {
        e.preventDefault();
        this.setState({ agingReport: true });
        const { payableAgingReport } = this.state;
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (payableAgingReport && payableAgingReport.Payables && payableAgingReport.Payables.length > 0) {
            const rows = [
                ["Vendor Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
            ];
            payableAgingReport.Payables.forEach(element => {
                let columns = [];
                columns.push('"' + element.VendorName + '"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue ? element.currentDue : 0)
                    + (element.due30Days ? element.due30Days : 0)
                    + (element.due45Days ? element.due45Days : 0)
                    + (element.due60Days ? element.due60Days : 0)
                    + (element.due90Days ? element.due90Days : 0)
                    + (element.due120Days ? element.due120Days : 0)
                    + (element.due180Days ? element.due180Days : 0)
                    + (element.due180pluseDays ? element.due180pluseDays : 0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue ? element.currentDue : 0),
                due30 + (element.due30Days ? element.due30Days : 0),
                due45 + (element.due45Days ? element.due45Days : 0),
                due60 + (element.due60Days ? element.due60Days : 0),
                due90 + (element.due90Days ? element.due90Days : 0),
                due120 + (element.due120Days ? element.due120Days : 0),
                due180 + (element.due180Days ? element.due180Days : 0),
                due180pluse + (element.due180pluseDays ? element.due180pluseDays : 0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL");
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45);
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent,
                csvData: rows,
                filename: "Payable_Aging_Report.csv"
            };
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            });
        }
        else {
            this.setState({ agingReport: false });
        }
    };

    getTransactionHistoryDownload = (e, isBuyer_, bankData_) => {
        e.preventDefault();
        const { supplierBankTransactionHistory } = bankData_;
        let csvContent = "data:text/csv;charset=utf-8,";
        const table = {
            indent: 1,
            rows: []
        };
        const readbankTransactionValue = (rowData, indentation_, isGroup) => {
            for (let rowType of Object.keys(rowData)) {
                let row_;
                if (isGroup) {
                    row_ = {
                        indent: indentation_ + 1,
                        columns: [],
                    };
                    table.indent = Math.max(table.indent, indentation_ + 1);
                }
                else {
                    row_ = {
                        indent: indentation_,
                        columns: [],
                    };
                }
                //table.rows.push(row_);
                row_.columns.push('"' + rowType + '"');
                table.rows.push(row_);
                if (rowData[rowType]) {
                    if (typeof rowData[rowType] === 'object' && rowData[rowType] !== null) {
                        Object.keys(rowData[rowType]).forEach((childRowType, i) => {
                            if (rowData[rowType][childRowType].TransactionType) {
                                let ele = rowData[rowType][childRowType];
                                let row_ = {
                                    columns: [],
                                };
                                //table.rows.push(row_);
                                row_.columns.push(ele.TransactionType);
                                row_.columns.push(ele.Date);
                                row_.columns.push(ele.Description);
                                row_.columns.push(ele.Amount);
                                row_.columns.push(ele.Balance);
                                table.rows.push(row_);
                            }
                            else {
                                let newIndentation = indentation_;
                                if (rowData[rowType][childRowType].length > 0 && Array.isArray(rowData[rowType][childRowType])) {
                                    row_ = {
                                        columns: [],
                                        indent: indentation_ + 1,
                                    };
                                    table.indent = Math.max(table.indent, indentation_ + 1);
                                    row_.columns.push('"' + childRowType + '"');
                                    table.rows.push(row_);
                                    rowData[rowType][childRowType].forEach(ele => {
                                        let row_ = {
                                            columns: [],
                                        };
                                        //table.rows.push(row_);
                                        row_.columns.push(ele.TransactionType);
                                        row_.columns.push(ele.Date);
                                        row_.columns.push(ele.Description);
                                        row_.columns.push(ele.Amount);
                                        row_.columns.push(ele.Balance);
                                        table.rows.push(row_);
                                    });
                                }
                                else if (typeof rowData[rowType][childRowType] === 'object' && rowData[rowType][childRowType] !== null) {
                                    ++newIndentation;
                                    let row_ = {
                                        indent: newIndentation,
                                        columns: ['"' + childRowType + '"'],
                                    };
                                    table.rows.push(row_);

                                    table.indent = Math.max(table.indent, newIndentation);
                                    readbankTransactionValue(rowData[rowType][childRowType], newIndentation, true);
                                }
                            }
                        });
                    }
                }
            }
        };

        const readableJsonToCSV = (table_) => {
            let lastRow = null;
            console.log(table_);
            for (let row of table_.rows) {
                if (row.padding && !lastRow.padding) {
                    csvContent += "\r\n";
                }

                for (let c = -table_.indent; c < row.columns.length; ++c) {
                    let col;
                    if (row.indent) {
                        col = row.columns[c + (table_.indent - row.indent + 1)];
                    } else {
                        col = row.columns[c];
                    }

                    if (col) {
                        csvContent += col;
                    }

                    if (c !== row.columns.length - 1) {
                        csvContent += ",";
                    }
                }

                if (row.padding) {
                    csvContent += "\r\n";
                }

                csvContent += "\r\n";

                lastRow = row;
            }

            return csvContent;
        };
        readbankTransactionValue(supplierBankTransactionHistory, 1, false);
        if (table.rows.length > 0) {
            let headerRows_ = {
                columns: ["TransactionType", "Date", "Description", "Amount", "Balance"]
            };
            table.rows.unshift(headerRows_);
            readableJsonToCSV(table);
            console.log(csvContent);
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent.replace("data:text/csv;charset=utf-8,", ""),
                csvData: table.rows,
                filename: "BankTransactionHistory.csv",
                isBankTransactionHistory: true
            };
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            });
        }
    };

    getReceivableDetailsAgingReport = async () => {
        const rDetailReport_ = await this.api.getReceivablesDetailsAgingReport(this.props.data._id);
        if (rDetailReport_ && rDetailReport_.receivablesDetails && rDetailReport_.receivablesDetails.length > 0) {
            this.setState({ recDetailAgingReport: rDetailReport_, isRecievablesDetailsAgingReport: true });
        }
    };

    getPayableDetailsAgingReport = async () => {
        const pDetailReport_ = await this.api.getPayablesDetailsAgingReport(this.props.data._id);
        if (pDetailReport_ && pDetailReport_.payablesDetails && pDetailReport_.payablesDetails.length > 0) {
            this.setState({ payDetailAgingReport: pDetailReport_, isPayableDetailsAgingReport: true });
        }
    };

    getPayablesAgingReport = async () => {
        const pDetailReport_ = await this.api.getPayablesAgingReport(this.props.data._id);

        if (pDetailReport_ && pDetailReport_.Payables && pDetailReport_.Payables.length > 0) {
            this.setState({ payableAgingReport: pDetailReport_ });
        }
    };

    reportElementType = (status) => {
        switch (status) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return netTerms[status];
            case 11:
                return "International";
            default:
                return netTerms[0];
        }
    }


    recDetailAgingReportDownload = (e, data_, accountingType_) => {
        e.preventDefault();
        const rows = [];
        const { receivablesDetails } = data_;
        let columns = [];
        let count;
        let reportDate = `As on ${localDateFormat(data_.pulledDate)}`;
        columns.push(reportDate);
        rows.push(columns);
        rows.push([]);
        if (accountingType_ == "D365BC") {
            rows.push(["Customer ID", "Customer Name", "Due Days ", "Amount"]);
        }
        else {
            rows.push(["Customer ID", "Customer Name", "Due Days ", "Transaction Type", "Transaction ID", "Transaction Date", "Due Date", "Amount"]);
        }

        columns = [];
        receivablesDetails.forEach(element => {
            columns = [];
            count = 0;
            columns.push(element.customerId);
            columns.push('"' + element.customerName + '"');
            let isAlreadyInserted = false;

            if (element.currentDue > 0 && element.currentDuerecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.currentDuerecords, element.currentDue, "CurrentDue");
            }
            if (element.due30Days && element.due30Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due30Daysrecords, element.due30Days, "30 Days");
            }
            if (element.due45Days && element.due45Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due45Daysrecords, element.due45Days, "45 Days");
            }
            if (element.due60Days && element.due60Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due60Daysrecords, element.due60Days, "60 Days");
            }
            if (element.due90Days && element.due90Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due90Daysrecords, element.due90Days, "90 Days");
            }
            if (element.due120Days && element.due120Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due120Daysrecords, element.due120Days, "120 Days");
            }
            if (element.due180Days && element.due180Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180Daysrecords, element.due180Days, "180 Days");
            }
            if (element.due180pluseDays && element.due180pluseDaysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180pluseDaysrecords, element.due180pluseDays, "180 >");
            }
            if (!isAlreadyInserted) {
                if (element.currentDue && element.currentDue > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "CurrentDue", element.currentDue);
                    } else {
                        columns.push("CurrentDue", element.currentDue);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due30Days && element.due30Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "30 Days", element.due30Days);
                    } else {
                        columns.push("30 Days", element.due30Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due45Daysrecords && element.due45Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "45 Days", element.due45Daysrecords);
                    } else {
                        columns.push("45 Days", element.due45Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due60Days && element.due60Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "60 Days", element.due60Days);
                    } else {
                        columns.push("60 Days", element.due60Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due90Daysrecords && element.due90Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "90 Days", element.due90Daysrecords);
                    } else {
                        columns.push("90 Days", element.due90Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due120Days && element.due120Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "120 Days", element.due120Days);
                    } else {
                        columns.push("120 Days", element.due120Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180Days && element.due180Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 Days", element.due180Days);
                    } else {
                        columns.push("180 Days", element.due180Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180pluseDays && element.due180pluseDays > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 >", element.due180pluseDays);
                    } else {
                        columns.push("180 >", element.due180pluseDays);
                    }
                    rows.push(columns);
                    count++;
                }
            }
            // rows.push(columns);
        });
        function dueRecord(records_, dueAmt_, dueType_) {
            records_.forEach((d, i) => {
                if (i > 0 || count != 0) {
                    columns = [];
                    columns.push("");
                    columns.push("");
                }
                columns.push(dueType_);
                // columns.push(dueAmt_);
                columns.push(d.TransactionType);
                columns.push(d.TransactionID);
                columns.push(d.TransactionDate);
                columns.push(d.DueDate);
                columns.push(d.Amount);
                rows.push(columns);
            });
            count++;
        };
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        let _fileContent = {
            encodedUri: encodedUri,
            isCSVString: true,
            csvString: csvContent,
            csvData: rows,
            filename: "Receivable_Details_Aging_Report.csv"
        };
        this.setState({
            isFinancialDocument: false,
            fileViewerContent: _fileContent,
            isFileViewerModal: true
        });

    };

    payDetailAgingReportDownload = (e, data_, accountingType_) => {
        e.preventDefault();
        const rows = [];
        const { payablesDetails } = data_;
        let columns = [];
        let count;
        let reportDate = `As on ${localDateFormat(payablesDetails.pulledDate)}`;
        columns.push(reportDate);
        rows.push(columns);
        rows.push([]);
        if (accountingType_ == "D365BC") {
            rows.push(["Vendor ID", "Vendor Name", "Due Days", "Amount"]);
        } else {
            rows.push(["Vendor ID", "Vendor Name", "Due Days", "Transaction Type", "Transaction ID", "Transaction Date", "Due Date", "Amount"]);
        }
        payablesDetails.forEach(element => {
            columns = [];
            count = 0;
            columns.push(element.vendorId);
            columns.push('"' + element.vendorName + '"');
            let isAlreadyInserted = false;
            if (element.currentDue > 0 && element.currentDuerecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.currentDuerecords, element.currentDue, "CurrentDue");
            }
            if (element.due30Days && element.due30Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due30Daysrecords, element.due30Days, "30 Days");
            }
            if (element.due45Days && element.due45Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due45Daysrecords, element.due45Days, "45 Days");
            }
            if (element.due60Days && element.due60Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due60Daysrecords, element.due60Days, "60 Days");
            }
            if (element.due90Days && element.due90Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due90Daysrecords, element.due90Days, "90 Days");
            }
            if (element.due120Days && element.due120Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due120Daysrecords, element.due120Days, "120 Days");
            }
            if (element.due180Days && element.due180Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180Daysrecords, element.due180Days, "180 Days");
            }
            if (element.due180pluseDays && element.due180pluseDaysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180pluseDaysrecords, element.due180pluseDays, "180 >");
            }
            if (!isAlreadyInserted) {
                if (element.currentDue && element.currentDue > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "CurrentDue", element.currentDue);
                    } else {
                        columns.push("CurrentDue", element.currentDue);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due30Days && element.due30Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "30 Days", element.due30Days);
                    } else {
                        columns.push("30 Days", element.due30Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due45Daysrecords && element.due45Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "45 Days", element.due45Daysrecords);
                    } else {
                        columns.push("45 Days", element.due45Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due60Days && element.due60Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "60 Days", element.due60Days);
                    } else {
                        columns.push("60 Days", element.due60Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due90Daysrecords && element.due90Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "90 Days", element.due90Daysrecords);
                    } else {
                        columns.push("90 Days", element.due90Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due120Days && element.due120Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "120 Days", element.due120Days);
                    } else {
                        columns.push("120 Days", element.due120Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180Days && element.due180Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 Days", element.due180Days);
                    } else {
                        columns.push("180 Days", element.due180Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180pluseDays && element.due180pluseDays > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 >", element.due180pluseDays);
                    } else {
                        columns.push("180 >", element.due180pluseDays);
                    }
                    rows.push(columns);
                    count++;
                }
            }
            // rows.push(columns);
        });
        function dueRecord(records_, dueAmt_, dueType_) {
            records_.forEach((d, i) => {
                if (i > 0 || count != 0) {
                    columns = [];
                    columns.push("");
                    columns.push("");
                }
                columns.push(dueType_);
                // columns.push(dueAmt_);
                columns.push(d.TransactionType);
                columns.push(d.TransactionID);
                columns.push(d.TransactionDate);
                columns.push(d.DueDate);
                columns.push(d.Amount);
                rows.push(columns);
            });
            count++;
        };
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        let _fileContent = {
            encodedUri: encodedUri,
            isCSVString: true,
            csvString: csvContent,
            csvData: rows,
            filename: "Payable_Details_Aging_Report.csv"
        };
        this.setState({
            isFinancialDocument: false,
            fileViewerContent: _fileContent,
            isFileViewerModal: true
        });
    };


    render() {
        const {
            isPayableDetailsAgingReport,
            isRecievablesDetailsAgingReport,
        } = this.state;



        return (<>
            {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                onClose={() => {
                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                }}
                data={this.state.fileViewerContent}
                isFinancialDocument={this.state.isFinancialDocument}
            ></FileViewerModal>}
            <div className="row pb-4">
                {this.state.supplierFincicalDocResponse.length > 0 ? (
                    <div className="col-lg-12">
                        <label className="view-details-header pb-2">Financial Documents
                            {this.state.supplierFincicalDocResponse.length > 0 && (
                                <span className="ml-2 badge badge-success">
                                    {fileStatusBadgeText(this.state.supplierFincicalDocResponse[0] && this.state.supplierFincicalDocResponse[0].source ? this.state.supplierFincicalDocResponse[0].source : "")}
                                </span>
                            )}
                        </label>
                        <span className="form-value">
                            {this.state.supplierFincicalDocResponse.length ?
                                (<>
                                    <div className="row">
                                        {this.getDropBoxProfileContent(this.state.supplierFincicalDocResponse)}
                                        {this.props.data.accountingInformation && this.props.data.accountingInformation == "quickbooks" && (
                                            <>{!this.state.isAgingfile && (
                                                <React.Fragment>
                                                    <div className="col-md-6">
                                                        <span >
                                                            <a
                                                                className="anchorstyle"
                                                                href=""
                                                                onClick={(e) => this.getAggingReportDownload(e, this.props.data.email, this.props.data.accountingInformation, "supplier")}
                                                            >RECEIVABLE AGING REPORT</a>
                                                        </span>
                                                    </div>
                                                </React.Fragment>
                                            )}

                                                {!this.state.isPayableAgingReport && (
                                                    <React.Fragment>
                                                        <div className="col-md-6">
                                                            <span >
                                                                <a
                                                                    className="anchorstyle"
                                                                    href=""
                                                                    onClick={(e) => this.getPayableAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.accountingInformation)}
                                                                >PAYABLE AGING REPORT</a>
                                                            </span>
                                                        </div>
                                                    </React.Fragment>
                                                )}

                                                {(this.props.data.accountingInformation && this.props.data.accountingInformation == "quickbooks") &&
                                                    (<React.Fragment>
                                                        {(this.state.bankTransactionHistory) && (
                                                            <div className="col-md-6">
                                                                <span >
                                                                    <a
                                                                        className="anchorstyle text-uppercase"
                                                                        href=""
                                                                        onClick={(e) => this.getTransactionHistoryDownload(e, true, this.state.bankTransactionHistory)}
                                                                    >
                                                                        Bank Transaction History
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                    )}
                                                {(isPayableDetailsAgingReport && this.props.data.accountingInformation && (this.props.data.accountingInformation == "quickbooks" || this.props.data.accountingInformation == "D365BC")) &&
                                                    <div className="col-md-6">
                                                        <span >
                                                            <a
                                                                className="anchorstyle text-uppercase"
                                                                href=""
                                                                onClick={(e) => this.payDetailAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.accountingInformation)}
                                                            >
                                                                Payable Details Aging Report
                                                            </a>
                                                        </span>
                                                    </div>
                                                }
                                                {(isRecievablesDetailsAgingReport && this.props.data.accountingInformation && (this.props.data.accountingInformation == "quickbooks" || this.props.data.accountingInformation == "D365BC")) &&
                                                    <div className="col-md-6">
                                                        <span >
                                                            <a
                                                                className="anchorstyle text-uppercase"
                                                                href=""
                                                                onClick={(e) => this.recDetailAgingReportDownload(e, this.state.recDetailAgingReport, this.props.data.accountingInformation)}
                                                            >
                                                                Receivable Details Aging Report
                                                            </a>
                                                        </span>
                                                    </div>
                                                }

                                            </>
                                        )}
                                    </div>
                                </>) : (<div>No documents were uploaded</div>)}
                        </span>
                    </div>
                ) : (
                    <>
                        {!(!this.state.isAgingfile ||
                            !this.state.isPayableAgingReport ||
                            isPayableDetailsAgingReport ||
                            isRecievablesDetailsAgingReport) && (<>
                                <div className="col-lg-12">
                                    <label className="view-details-header pb-2">Financial Documents</label>
                                    <span>
                                        No documents were uploaded
                                    </span>
                                </div></>)}
                    </>
                )}
                {(!this.state.isAgingfile ||
                    !this.state.isPayableAgingReport ||
                    isPayableDetailsAgingReport ||
                    isRecievablesDetailsAgingReport) && this.props.data.accountingInformation && this.props.data.accountingInformation != "quickbooks" && (
                        <div className="col-lg-12">
                            <label className="view-details-header pb-2">Financial Documents
                                {(!this.state.isAgingfile ||
                                    !this.state.isPayableAgingReport ||
                                    isPayableDetailsAgingReport ||
                                    isRecievablesDetailsAgingReport
                                ) && (
                                        <span className="ml-2 badge badge-success">
                                            {fileStatusBadgeText(this.props.data.accountingInformation)}
                                        </span>
                                    )}
                            </label>
                            <span className="form-value">
                                <div className="row">
                                    {!this.state.isAgingfile && (
                                        <React.Fragment>
                                            <div className="col-md-6">
                                                <span >
                                                    <a
                                                        className="anchorstyle"
                                                        href=""
                                                        onClick={(e) => this.getAggingReportDownload(e, this.props.data.email, this.props.data.accountingInformation, "supplier")}
                                                    >RECEIVABLE AGING REPORT</a>
                                                </span>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {!this.state.isPayableAgingReport && (
                                        <React.Fragment>
                                            <div className="col-md-6">
                                                <span >
                                                    <a
                                                        className="anchorstyle"
                                                        href=""
                                                        onClick={(e) => this.getPayableAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.accountingInformation)}
                                                    >PAYABLE AGING REPORT</a>
                                                </span>
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {(this.props.data.accountingInformation && this.props.data.accountingInformation == "quickbooks") &&
                                        (<React.Fragment>
                                            {(this.state.bankTransactionHistory) && (
                                                <div className="col-md-6">
                                                    <span >
                                                        <a
                                                            className="anchorstyle text-uppercase"
                                                            href=""
                                                            onClick={(e) => this.getTransactionHistoryDownload(e, true, this.state.bankTransactionHistory)}
                                                        >
                                                            Bank Transaction History
                                                        </a>
                                                    </span>
                                                </div>
                                            )}
                                        </React.Fragment>
                                        )}
                                    {(isPayableDetailsAgingReport && this.props.data.accountingInformation && (this.props.data.accountingInformation == "quickbooks" || this.props.data.accountingInformation == "D365BC")) &&
                                        <div className="col-md-6">
                                            <span >
                                                <a
                                                    className="anchorstyle text-uppercase"
                                                    href=""
                                                    onClick={(e) => this.payDetailAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.accountingInformation)}
                                                >
                                                    Payable Details Aging Report
                                                </a>
                                            </span>
                                        </div>
                                    }
                                    {(isRecievablesDetailsAgingReport && this.props.data.accountingInformation && (this.props.data.accountingInformation == "quickbooks" || this.props.data.accountingInformation == "D365BC")) &&
                                        <div className="col-md-6">
                                            <span >
                                                <a
                                                    className="anchorstyle text-uppercase"
                                                    href=""
                                                    onClick={(e) => this.recDetailAgingReportDownload(e, this.state.recDetailAgingReport, this.props.data.accountingInformation)}
                                                >
                                                    Receivable Details Aging Report
                                                </a>
                                            </span>
                                        </div>
                                    }
                                </div>
                            </span>
                        </div>
                    )}

            </div>
            <div className="row">
                <div className="col-lg-12">
                    <label className="view-details-header pb-2">Additional Documents
                        {this.state.supplierFinancialAddDocs.length > 0 && (
                            <span className="ml-2 badge badge-success">
                                {fileStatusBadgeText("")}
                            </span>
                        )}
                    </label>
                    <span >
                        {this.state.supplierFinancialAddDocs.length
                            ? <div className="row">
                                {this.getDropBoxProfileContent(this.state.supplierFinancialAddDocs, "", true)}
                            </div>
                            : <div>No documents were uploaded</div>
                        }
                    </span>
                </div>
            </div>
        </>);
    }
}

export default withTranslation() (FinancialDocuments);




