//Author, Rajeh Rajendran
import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const MaskedTextInput = ({ label, name, value, onChange, mask, placeholder, disabled, required }) => {
    return (
        <div>
            <label htmlFor={name} className={`form-label-style ${required ? 'required' : ''}`}>{label}</label>
            <MaskedInput
                name={name}
                value={value}
                mask={mask}
                className="form-control"
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                disabled={disabled}
            />
        </div>
    );
};

MaskedTextInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    mask: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

MaskedTextInput.defaultProps = {
    placeholder: '',
    disabled: false
};

export default MaskedTextInput;
