import React, { Component } from 'react';
import FullSpinner from '../../common/Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import Dropzone from 'react-dropzone';
import {ToastContainer, toast } from 'react-toastify';
import SignaturePopver from '../../common/PopOver/SignaturePopver';
import CommonConfirmationModal,{AddModal} from '../../common/Modals/CommonConfirmationModal';
import SignerAgreementDetails from '../../common/Agreement/SignerAgreementDetails';
import User from '../../../services/User';
import BusinessInformationMissingModal from '../../common/Modals/BusinessInformationMissingModal';
import { localDateFormat } from "../../../services/Utilities";
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import FileUploader from '../../common/FileUploader/FileUploader';

export default class FundingAgreementModal extends Component {
    api = new API();
    user = new User();
    _countDownTimer;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            file: null,
            digitalSignature: "",
            eSignature: "",
            isAgreeChecked: false,
            isTokenGenrated: false,
            isUnderstand: false,
            isSendingVCode: false,
            eVerificationDetails: {},
            verificationStatusMsg: this.props.t("verification_code_send_msg"),
            isVerificationTokenExpired: false,
            isEnableSignBtn: false,
            isSignatureAdded: false,
            funderWalletDetails: null,
            contractOfferDetails: null,
            minutes: 5,
            seconds: 0,
            isBusinessInfoMissing: false,
            isSelectedElectronicSignature: false,
            userName: "",
            title: "",
            funderData: this.props && this.props.data ? this.props.data : "",
            funderDetails: null,
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false
        };
    }

    componentDidMount() {
        if (this.props.data && this.props.data.funder && (!this.props.data.funder.userName || !this.props.data.funder.title)) {
            this.setState({ isBusinessInfoMissing: true });
        }

    }

    getProfile = async () => {
        const _funderDetails = await this.api.getProfile();
        this.setState({ funderDetails: _funderDetails.user });
    };

    async notify(message) {
        toast.info(message);
    }

    submitAgreement = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        if (!this.state.isVerificationTokenExpired) {
            const documentData = new FormData();
            documentData.append("fundingReqId", this.props.data.fundingReqId);
            documentData.append("fundingContractId", this.props.data.contracts[0]._id);
            documentData.append("isDigitalSignature", this.state.digitalSignature == "signed");
            documentData.append("file", this.state.file);
            clearInterval(this._countDownTimer);
            const uploadFundingAgreement_ = await this.api.uploadFundingAgreementToContract(documentData);
            //uploadFundingAgreement_.success=="Agreement Uploaded";
            if (uploadFundingAgreement_) {
                if (this.state.digitalSignature == "signed") {
                    this.setState({ isLoading: false, isSignatureAdded: true, contractOfferDetails: uploadFundingAgreement_.payload && uploadFundingAgreement_.payload.value ? uploadFundingAgreement_.payload.value : null });
                    const _funderWalletDetails = await this.api.getWalletInfo();
                    if (!_funderWalletDetails.msg) {
                        this.setState({ funderWalletDetails: _funderWalletDetails });
                    }
                }
                else {
                    this.setState({ isLoading: false });
                    this.props.onClose();
                }
            }
        }
        else {
            this.notify(this.props.t("invalid_verification_code_msg"));
            this.setState({ isLoading: false });
        }
    };

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (name == "digitalSignature") {
            this.setState({ digitalSignature: value });
        }
        if (name == "eSignature") {
            this.setState({ eSignature: value }, async () => {
                const isSignBtnEnabled = await this.verifiyECodeDetails();
                if (isSignBtnEnabled) {
                    this.setState({ isEnableSignBtn: true });
                }
                else {
                    this.setState({ isEnableSignBtn: false });
                }
            });
        }
    };

    onAgreeChange = async (event) => {
        const isAgreeChecked_ = event.target.checked;
        const {file}=this.state;
        if (isAgreeChecked_) {
            this.setState({ isAgreeChecked: isAgreeChecked_ });
            if(!file){
                AddModal(this.props.t("confirm"), this.props.t("without_agreement_upload_msg"))
                .then(async(_isSubmitted)=>{
                            await this.getVerificationCode(false);
                    }
                )
                .catch(()=>{
                    this.setState({ isAgreeChecked: false });
                })
            }
            else{
                await this.getVerificationCode(false);
            }
        }
        else {
            this.setState({ isAgreeChecked: isAgreeChecked_ });
        }
    };

    getVerificationCode = async (isResend_) => {
        const { data } = this.props;
        this.setState({ isSendingVCode: true });
        const _codeDetails = {
            isResend: isResend_,
            fundingReqId: data.fundingReqId,
            fundingContractId: data.contractId,
            _id: this.state.eVerificationDetails && this.state.eVerificationDetails._id ? this.state.eVerificationDetails._id : ""
        };
        const codeStatus = await this.api.getVerificationCode(_codeDetails);
        if (codeStatus && codeStatus.verificationCode) {
            this.notify(this.props.t("verification_code_send_msg"));
            this.setState({ isTokenGenrated: true, eVerificationDetails: codeStatus, minutes: 5, seconds: 0, isVerificationTokenExpired: false });
            this._countDownTimer = setInterval(async () => {
                const { seconds, minutes } = this.state;

                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }));
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this._countDownTimer);
                        this.setState({ isVerificationTokenExpired: true });
                        this.notify(this.props.t("expired_verification_code_msg"));
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }));
                    }
                }
            }, 1000);
        }
        this.setState({ isSendingVCode: false });
    };

    verifiyECodeDetails = async () => {
        const { eVerificationDetails } = this.state;
        const _eSignatureValue = this.state.eSignature;
        let isVerified = false;
        if (eVerificationDetails.verificationCode == _eSignatureValue) {
            return true;
        }
        else {
            return false;
        }
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    render() {
        const { data } = this.props;
        const {
            isLoading,
            contractOfferDetails,
            funderDetails
        } = this.state;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <ToastContainer />
                        <FullSpinner
                            isSpinning={isLoading}
                            confirmationLabel={this.props.t("funding_agreement_signed")}
                        />
                        <FullSpinner
                            isSpinning={this.state.isSendingVCode}
                            confirmationLabel=""
                        />
                        {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                            onClose={() => {
                                this.setState({ isFileViewerModal: false, fileViewerContent: null });
                            }}
                            data={this.state.fileViewerContent}
                            isFinancialDocument={false}
                        ></FileViewerModal>}
                        <CommonPopUpModal
                            size={(this.state.isSelectedElectronicSignature && this.state.digitalSignature == "unsigned" ? "medium" : "large")}
                            title={this.state.isSelectedElectronicSignature ?
                                (<>{this.props.t("fundingrequests_page.funding_agreement_content.upload_funding_agreement")}: #{data ? data.fundingReqId : ""}</>)
                                : (<>{this.props.t("fundingrequests_page.funding_agreement_content.electronic_signature")}</>)
                            }
                            onClose={e => this.props.onClose()}
                            secondaryBtn={this.state.isSelectedElectronicSignature && this.state.digitalSignature == "unsigned" ? false : this.state.isSelectedElectronicSignature ? false : this.props.t("button_names.next")}
                            isSecBtnEnabled={this.state.digitalSignature == ""}
                            onSecBtnClick={(e) => {
                                this.setState({ isSelectedElectronicSignature: true });
                            }}
                            promptOnClose={this.state.isSelectedElectronicSignature ? this.state.file !== null : !(this.state.digitalSignature == "" || this.state.isSignatureAdded)}
                            primaryBtn={this.state.isSelectedElectronicSignature && this.state.digitalSignature == "unsigned" ? this.props.t("button_names.upload") : false}
                            isSubmitBtn={this.state.file == null}
                            onSubmit={(e) => {
                                this.submitAgreement(e);
                            }}
                            children={
                                <>
                                    {this.state.isBusinessInfoMissing && (
                                        <BusinessInformationMissingModal
                                            data={data.funder}
                                            onClose={(d) => {
                                                this.setState({ isBusinessInfoMissing: false });
                                                if (d) {
                                                    this.props.onClose();
                                                }
                                                else {
                                                    this.getProfile();
                                                }
                                            }}
                                        />)
                                    }
                                    <div >
                                        <div className="justify-content-center">
                                            {!this.state.isSelectedElectronicSignature ?
                                                (<>
                                                    <div>
                                                        {this.props.t("electronic_signature_definition")}
                                                    </div>
                                                    <div className="form-group mt-3">
                                                        {/* <label className="form-label-style" htmlFor="name">Document Signature Options</label><br /> */}
                                                        <label className="form-label-style" htmlFor="name">{this.props.t("please_select_one_of_the_following")}:</label><br />
                                                        <div className='d-flex flex-column'>
                                                            <label>
                                                                <input type='radio'
                                                                    name="digitalSignature"
                                                                    checked={this.state.digitalSignature == "signed"}
                                                                    value="signed"
                                                                    onChange={this.handleChange} />
                                                                {/* &nbsp;<b>Opt-in to use Electronic Signature.</b > */}
                                                                &nbsp;<b>{this.props.t("use_electronic_signature")}.</b > {this.props.t("electronic_signature_description")}
                                                            </label>
                                                            <label>
                                                                <input type='radio'
                                                                    name="digitalSignature"
                                                                    value="unsigned"
                                                                    checked={this.state.digitalSignature == "unsigned"}
                                                                    onChange={this.handleChange} />
                                                                {/* &nbsp;<b>Opt-out from Electronic Signature.</b > */}
                                                                &nbsp;<b>{this.props.t("use_manual_signature")}.</b > {this.props.t("manual_signature_description")}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>) : (<>
                                                    {(this.state.digitalSignature == "signed" || this.state.digitalSignature == "unsigned" || this.state.digitalSignature == "") && (
                                                        <>
                                                            <div className=' text-center mb-3 w-100'>
                                                                <FileUploader
                                                                    documents={this.state.file ? this.state.file : []}
                                                                    onDocUpload={doc_ => { if (doc_ && doc_.length) { this.setState({ file: doc_[0] }); } }}
                                                                    onDelete={(filteredDocs) => this.setState({ file: null })}
                                                                    onView={this.handleView}
                                                                    namelength={50}
                                                                    supportedExt={['.pdf']}
                                                                    errorMsgDuplicate={"Document Already Uploaded"}
                                                                    // placeholder={this.state.digitalSignature == "signed" ? this.props.t("file_upload_msg.funding_agreement_signed"):
                                                                    //     this.state.digitalSignature == "unsigned" ? `${this.props.t("file_upload_msg.funding_agreement_unsigned_1")} ${data && data.isSupplyChainFinancing ? this.props.t("roles.buyer") : this.props.t("roles.supplier")} ${this.props.t("file_upload_msg.funding_agreement_unsigned_2")}` : this.props.t("file_upload_msg.upload_funding_agreement")}
                                                                    placeholder={this.props.t("file_upload_msg.funding_agreement_signed")}
                                                                    isdisabled={this.state.digitalSignature == "" || this.state.isSignatureAdded}
                                                                    isMultiple={false}
                                                                    isExtraction={false}
                                                                    isDeleteEnable={true}
                                                                />
                                                            </div>
                                                            {(this.state.digitalSignature == "signed") && (
                                                                <>
                                                                    {/* <SignerAgreementDetails
                                                                                    supplier={data.supplier}
                                                                                    funder={data.funder}
                                                                                    contracts={data.contracts && data.contracts.length >0 ? data.contracts[0]:null}
                                                                                    isSupplierConstent={false}
                                                                                    isFunderAgreement={true}
                                                                                    agreementFile={this.state.file==null}
                                                                                /> */}
                                                                    <div className='d-flex flex-column'>
                                                                        <div className="row mx-0" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                                                            <div className='col-2'>{this.props.t("grid_view_header_labels.company_name")}</div>
                                                                            <div className='col-4'>{this.props.t("grid_view_header_labels.signer")}</div>
                                                                            <div className="col-4">{this.props.t("grid_view_header_labels.signed_verified")}</div>
                                                                            {this.state.isSignatureAdded && (
                                                                                <div className='col-2'>{this.props.t("grid_view_header_labels.sign_on")}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="row mt-2 mx-0">
                                                                            <div className='col-2' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{data.funder && data.funder.name ? data.funder.name : ""}</div>
                                                                            <div className='col-4'>
                                                                                <div className='d-flex flex-column'>
                                                                                    <div className='d-flex'>
                                                                                        <div className='d-flex flex-column'>
                                                                                            <div className={this.state.isSignatureAdded ? "Signer-font" : ""}>
                                                                                                {data.funder && data.funder.userName ? data.funder.userName :
                                                                                                    (funderDetails && funderDetails.userName) ? funderDetails.userName : ""}
                                                                                            </div>
                                                                                            <div>
                                                                                                {data.funder && data.funder.title ? data.funder.title :
                                                                                                    (funderDetails && funderDetails.title) ? funderDetails.title : ""}
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={this.state.isSignatureAdded ? 'col-4' : "col-6"}>
                                                                                {(!this.state.isSignatureAdded && this.state.digitalSignature == "signed" && (this.state.isTokenGenrated && this.state.isAgreeChecked)) && (
                                                                                    <React.Fragment>
                                                                                        <div className='row mx-0'>
                                                                                            <div className="form-group">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    name="eSignature"
                                                                                                    onChange={this.handleChange}
                                                                                                    placeholder={this.props.t("one_time_password")}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="form-group pl-3">
                                                                                                <button
                                                                                                    className="btn btn-primary"
                                                                                                    disabled={!this.state.isEnableSignBtn}
                                                                                                    style={{ width: "100%", height: "95%" }}
                                                                                                    onClick={(e) => {
                                                                                                        this.submitAgreement(e);
                                                                                                    }}
                                                                                                >{this.props.t("button_names.sign_now")}</button>
                                                                                            </div>
                                                                                            <div className='text-center pl-3'>
                                                                                                {this.state.isVerificationTokenExpired ?
                                                                                                    <a className="nav-link active p-0" style={{ cursor: "pointer" }}
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.getVerificationCode(true);
                                                                                                        }}
                                                                                                    ><h6 className='mt-2'>{this.props.t("button_names.resend_code")}</h6></a>
                                                                                                    : <h6 className='mt-2' style={{ color: '#999999' }}>{this.props.t("resend_code_in")} {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</h6>}
                                                                                            </div>
                                                                                            {/* {!this.state.isVerificationTokenExpired && (
                                                                                                    <div style={{color:"red",fontSize:12}}>
                                                                                                        <h6>Time Remaining: {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</h6>
                                                                                                    </div>
                                                                                                )} */}
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {this.state.isSignatureAdded && (
                                                                                    <>
                                                                                        <div className='d-flex'>
                                                                                            <SignaturePopver
                                                                                                userName={data.funder && data.funder.userName ? data.funder.userName :
                                                                                                    (funderDetails && funderDetails.userName) ? funderDetails.userName : ""}
                                                                                                signature={this.state.funderWalletDetails && this.state.funderWalletDetails.funderSignature ? this.state.funderWalletDetails.funderSignature : this.props.t("grid_view_header_labels.not_signed")}
                                                                                                createdAt={this.state.contractOfferDetails && this.state.contractOfferDetails.fundingAgreementAt ? new Date(this.state.contractOfferDetails.fundingAgreementAt) : new Date()}
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            {this.state.isSignatureAdded && (
                                                                                <div className='col-2'>
                                                                                    {localDateFormat(this.state.contractOfferDetails && this.state.contractOfferDetails.fundingAgreementAt ? new Date(this.state.contractOfferDetails.fundingAgreementAt) : new Date())}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {!this.state.isTokenGenrated && (
                                                                            <div className="row mx-0">
                                                                                <div className='col-2' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}></div>
                                                                                <div className='col-10'>
                                                                                    <div className='d-flex flex-column'>
                                                                                        <div>
                                                                                            <label>
                                                                                                <input type='checkbox'
                                                                                                    name="agreeCondition"
                                                                                                    checked={this.state.isAgreeChecked}
                                                                                                    disabled={this.state.isTokenGenrated}
                                                                                                    onChange={this.onAgreeChange} />
                                                                                                &nbsp;{this.props.t("fundingrequests_page.funding_agreement_content.agreement_check_msg_1")} ({data && data.contractId ? data.contractId : "FC-ID"}), {this.props.t("fundingrequests_page.funding_agreement_content.agreement_check_msg_2")}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className="row mt-4 mx-0">
                                                                            <div className='col-2' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{data.requesterDetails && data.requesterDetails.name ? data.requesterDetails.name : ""}</div>
                                                                            <div className='col-4' >
                                                                                {data.requesterDetails && data.requesterDetails.userName ? data.requesterDetails.userName : ""}
                                                                                <br />
                                                                                {data.requesterDetails && data.requesterDetails.title ? data.requesterDetails.title : ""}
                                                                            </div>
                                                                            <div className='col-4' style={{ paddingLeft: 5 }}>
                                                                                <svg height="30" width="40"><circle cx="15" cy="15" r="9" stroke="gray" stroke-width="2" fill="white"></circle></svg>
                                                                                <b>{this.props.t("grid_view_header_labels.not_signed")}</b>
                                                                            </div>
                                                                            <div className='col-2'></div>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                            )}
                                                        </>)}
                                                </>)}
                                        </div>
                                    </div>
                                </>
                            }
                        ></CommonPopUpModal>
                    </React.Fragment>)}
            </AppState.Consumer>
        );
    }
}