export const netTerms = [
    "NET 15", "NET 30", "NET 45", "NET 60", "NET 90", "NET 120", "NET 180", "International"
];

export const transactionType = {
    "ABL": "abl",
    "Invoice": "Invoice",
    "PurchaseOrder": "PO",
    "SupplyChain": "SupplyChain",
    "TCF": "tcf"
};
 

export const enumRole = {
    Admin:"admin",
    Supplier: "supplier",
    Buyer: "buyer",
    Funder: "funder"
};

export const enumUserStatus={
    NotApproved:0,
    Approved:1,
    Rejected:-1,
    Disabled:3
}

export const compareRole=(role_,compareRole_)=>{
    return role_ && role_.toLowerCase()==compareRole_;
}

export const compareTransactionType=(type_,compareType_)=>{
    return type_ && type_.toLowerCase()==compareType_.toLowerCase();
}