import React, { Component } from 'react'
import Popup from '../../common/Auth/Popup';
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import User from '../../../services/User';
import { dollarFormatter, getIndustryWithId, getEntity, localDateFormat, fileStatusBadgeText } from '../../../services/Utilities';
import API from '../../../services/API';
import AppProvider from '../../../contexts/AppState/AppStateProvider';
import AppState from '../../../contexts/AppState/AppState';
import FullSpinner from '../../common/Spinner/FullSpinner';
import SigningStatusModal from './SigningStatusModal';
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import UserDetails from '../../common/User/UserDetails';
import QuestionnaireFundingDetails from "../../common/FundingDetails/QuestionnaireFundingDetails";
import FundingOfferDetails from "../../common/FundingDetails/FundingOfferDetails";
import { Stepper, Step, StepLabel, StepIcon } from '@mui/material';
import { compareRole,enumRole } from '../../../configs/GlobalConfig';


export class NewViewFundingContract extends Component {
    user = new User();
    api = new API();

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            agingReport: false,
            bankTransactionHistory: {},
            isAgingfile: true,
            isBankStatement: true,
            isRecievablesDetailsAgingReport: false,
            isPayableDetailsAgingReport: false,
            payDetailAgingReport: {},
            recDetailAgingReport: {},
            supplierFincicalDocResponse: [],
            isPayableAgingReport: true,
            isSigningPopup: false,
            supplierFinancialAddDocs: [],
            customerList: [],
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false,
            payableAgingReport: {},
        };
    }

    componentDidMount() {
        if (this.user.currentUserRole() === "funder") {
            this.getFinacialDocumentation();
            if (this.props.data.requesterDetails.accountingInformation) {
                if (this.props.data.requesterDetails.accountingInformation == "quickbooks") {
                    this.getSupplierbanktransactionhistory();
                    this.getReceivableDetailsAgingReport();
                    this.getPayableDetailsAgingReport();
                    this.getPayablesAgingReport();
                }
                else if (this.props.data.requesterDetails.accountingInformation == "D365BC") {
                    this.getReceivableDetailsAgingReport();
                    this.getPayableDetailsAgingReport();
                    this.getPayablesAgingReport();
                }
            }
        }
        this.getFinacialDocumentationForBuyer();
    }

    getFinacialDocumentationForBuyer = async () => {
        const { customerList } = this.props.data;
        if (customerList && customerList.length > 0) {
            for (let buyer_ of customerList) {
                const res = await this.api.getFunderSupplierDocs(buyer_._id);
                let isAging = true;
                let isbankStmt = true;
                let isPayableAging = true;
                let aging = res.items.filter(
                    x => x.financialDocumentType === "AGING_REPORT"
                );
                if (aging.length == 0) {
                    isAging = false;
                }
                let payableAging = res.items.filter(
                    x => x.financialDocumentType === "PAYABLE_AGING_REPORT"
                );
                if (payableAging.length == 0) {
                    isPayableAging = false;
                }
                let bankStatement = res.items.filter(x => x.financialDocumentType == "BANK_STATEMENT_1");
                if (bankStatement.length == 0) {
                    isbankStmt = false;
                }
                let financialAdditionalDocs = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
                res.items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() != "additionaldocument");
                buyer_.financialDocuments = res.items ? res.items : [];
                buyer_.isAgingfile = isAging;
                buyer_.additionalDocuments = financialAdditionalDocs && financialAdditionalDocs.length > 0 ? financialAdditionalDocs : [];
                if (buyer_.accountingInformation && buyer_.accountingInformation == "quickbooks") {
                    if (!isbankStmt) {
                        const bankTransactionHistory_ = await this.api.getsupbanktransactionhistory(buyer_._id);
                        buyer_.bankStatement = bankTransactionHistory_;
                    }
                }
                if (buyer_.accountingInformation && buyer_.accountingInformation != "erpinfo") {
                    if (!isPayableAging) {
                        const pDetailReport_ = await this.api.getPayablesAgingReport(buyer_._id);
                        if (pDetailReport_ && pDetailReport_.Payables &&  pDetailReport_.Payables.length > 0) {
                            buyer_.payableAging = pDetailReport_;
                        }
                    }
                    const rDetailReport_ = await this.api.getReceivablesDetailsAgingReport(buyer_._id);
                    if (rDetailReport_ && rDetailReport_.receivablesDetails && rDetailReport_.receivablesDetails.length > 0) {
                        buyer_.receivableDetailsAging = rDetailReport_;
                    }

                    const pDetailReport_ = await this.api.getPayablesDetailsAgingReport(buyer_._id);
                    if (pDetailReport_ && pDetailReport_.payablesDetails && pDetailReport_.payablesDetails.length > 0) {
                        buyer_.payablesDetailsAging = pDetailReport_;
                    }
                }
            }
            this.setState({
                customerList: customerList
            })
        }
    }

    getDropBoxProfileContent = (files, overrideFileName, isFileName_) => {
        if (this.props.isReadOnly) {
            return files.map(d => {
                let fileUrl = d.downloadUrl ? (d.downloadUrl.replace("http//", "http://").replace("https//", "https://")
                    .replace("http://http", "http").replace("https://http", "http")) : "";

                if (this.user.currentUserRole() == "funder") {
                    return <div className="col-md-3" key={d.filename}> <span><a className="anchorstyle" href={""} onClick={(e) => {
                        e.preventDefault();
                        if (d.downloadUrl) {
                            d.downloadUrl = fileUrl;
                        }
                        this.setState(
                            {
                                isFileViewerModal: true,
                                fileViewerContent: d,
                                isFinancialDocument: true
                            }
                        )
                    }}>{isFileName_ ? d.filename : d.financialDocumentType}</a></span></div>
                } else {
                    return <div className="col-md-6 pl-5" key={d.filename}><a className="anchorstyle" href={""} onClick={(e) => {
                        e.preventDefault();
                        if (d.downloadUrl) {
                            d.downloadUrl = fileUrl;
                        }
                        this.setState(
                            {
                                isFileViewerModal: true,
                                fileViewerContent: d,
                                isFinancialDocument: true
                            }
                        )
                    }}>{isFileName_ ? d.filename : d.financialDocumentType}</a></div>
                }

            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    getDropBoxContent = (files, type, overrideFileName) => {
        if (this.props.isReadOnly) {
            return files.map(d => {
                return <div key={d.filename}><a className="anchorstyle" href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${d._id}`} target="_blank" download={d.filename}>{overrideFileName ? overrideFileName : d.filename}</a></div>
            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    getFinacialDocumentation = async () => {
        const res = await this.api.getFunderSupplierDocs(this.props.data.requesterDetails._id);
        let isAging = true;
        let isbankStmt = true;
        let isPayableAging = true;
        let aging = res.items.filter(
            x => x.financialDocumentType === "AGING_REPORT"
        );
        if (aging.length == 0) {
            isAging = false;
        }
        let payableAging = res.items.filter(
            x => x.financialDocumentType === "PAYABLE_AGING_REPORT"
        );
        if (payableAging.length == 0) {
            isPayableAging = false;
        }
        let bankStatement = res.items.filter(x => x.financialDocumentType == "BANK_STATEMENT_1");
        if (bankStatement.length == 0) {
            isbankStmt = false;
        }
        let financialAdditionalDocs = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
        res.items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() != "additionaldocument");
        this.setState({
            supplierFincicalDocResponse: res.items ? res.items : 0,
            isBankStatement: isbankStmt,
            isAgingfile: isAging,
            isPayableAgingReport: isPayableAging,
            supplierFinancialAddDocs: financialAdditionalDocs
        }, async () => {
            if (!this.state.isAgingfile) {
                if (this.props.data && this.props.data.requesterDetails && this.props.data.requesterDetails.email) {
                    let agingResponse;
                    const accountingType_ = this.props.data.requesterDetails.accountingInformation;
                    const email = this.props.data.requesterDetails.email;
                    if (accountingType_ == "quickbooks") {
                        const agingValue = await this.api.getAgingReportFromQBO(email);
                        if (agingValue && !agingValue.msg) {
                            agingResponse = agingValue;
                        }
                    }
                    else if (accountingType_ == "D365BC") {
                        const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(email);
                        if (agingValue_ && !agingValue_.msg) {
                            if (agingValue_.invoices) {
                                agingResponse = agingValue_;
                            } else {
                                agingResponse = {};
                                agingResponse.invoices = agingValue_;
                            }
                        }
                    }
                    else {
                        const erpAggingReport = await this.api.getAgingReportFromERP(email);
                        if (erpAggingReport && erpAggingReport.invoices) {
                            agingResponse = erpAggingReport;
                        }
                    }
                    if (agingResponse && agingResponse.invoices && agingResponse.invoices.length > 0) {

                        this.setState({ receivableAgingReportData: agingResponse, isAgingfile: false });
                    }
                    else {
                        this.setState({ isAgingfile: true });
                    }
                }
            }
        });
    }



    async getSupplierbanktransactionhistory() {
        const res = await this.api.getsupbanktransactionhistory(this.props.data.requesterDetails._id);
        this.setState({ bankTransactionHistory: res });
    }


    getPayablesAgingReport = async () => {
        const { requesterDetails } = this.props.data;
        const pDetailReport_ = await this.api.getPayablesAgingReport(requesterDetails._id);

        if (pDetailReport_ && pDetailReport_.Payables && pDetailReport_.Payables.length > 0) {
            this.setState({ payableAgingReport: pDetailReport_ });
        }
    }

    getAggingReportDownload = async (e) => {
        e.preventDefault();
        this.setState({ agingReport: true });
        const { requesterDetails } = this.props.data;
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (requesterDetails.accountingInformation == "quickbooks") {
            const agingValue = await this.api.getAgingReportFromQBO(requesterDetails.email);
            if (agingValue && !agingValue.msg) {
                agingResponse = agingValue;
            }
        }
        else if (requesterDetails.accountingInformation == "D365BC") {
            const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(requesterDetails.email);
            if (agingValue_ && !agingValue_.msg) {
                if (agingValue_.invoices) {
                    agingResponse = agingValue_;
                } else {
                    agingResponse = {};
                    agingResponse.invoices = agingValue_;
                }
            }
        }
        else {
            const erpAggingReport = await this.api.getAgingReportFromERP(requesterDetails.email);
            if (erpAggingReport && erpAggingReport.invoices) {
                agingResponse = erpAggingReport;
            }
        }
        if (agingResponse && agingResponse.invoices && agingResponse.invoices.length > 0) {
            const rows = [
                ["Customer Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
            ];
            agingResponse.invoices.forEach(element => {
                let columns = [];
                columns.push('"' + element.customerName + '"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue ? element.currentDue : 0)
                    + (element.due30Days ? element.due30Days : 0)
                    + (element.due45Days ? element.due45Days : 0)
                    + (element.due60Days ? element.due60Days : 0)
                    + (element.due90Days ? element.due90Days : 0)
                    + (element.due120Days ? element.due120Days : 0)
                    + (element.due180Days ? element.due180Days : 0)
                    + (element.due180pluseDays ? element.due180pluseDays : 0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue ? element.currentDue : 0),
                due30 + (element.due30Days ? element.due30Days : 0),
                due45 + (element.due45Days ? element.due45Days : 0),
                due60 + (element.due60Days ? element.due60Days : 0),
                due90 + (element.due90Days ? element.due90Days : 0),
                due120 + (element.due120Days ? element.due120Days : 0),
                due180 + (element.due180Days ? element.due180Days : 0),
                due180pluse + (element.due180pluseDays ? element.due180pluseDays : 0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL")
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45)
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent,
                csvData: rows,
                filename: "Aging_Report.csv"
            }
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            })
            this.setState({ agingReport: false })
        }
        else {
            this.setState({ agingReport: false });
        }
    }

    getPayableAgingReportDownload = async (e) => {
        e.preventDefault();
        this.setState({ agingReport: true });
        const { requesterDetails } = this.props.data;
        const { payableAgingReport } = this.state;
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (payableAgingReport && payableAgingReport.Payables && payableAgingReport.Payables.length > 0) {
            const rows = [
                ["Vendor Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
            ];
            payableAgingReport.Payables.forEach(element => {
                let columns = [];
                columns.push('"' + element.VendorName + '"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue ? element.currentDue : 0)
                    + (element.due30Days ? element.due30Days : 0)
                    + (element.due45Days ? element.due45Days : 0)
                    + (element.due60Days ? element.due60Days : 0)
                    + (element.due90Days ? element.due90Days : 0)
                    + (element.due120Days ? element.due120Days : 0)
                    + (element.due180Days ? element.due180Days : 0)
                    + (element.due180pluseDays ? element.due180pluseDays : 0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue ? element.currentDue : 0),
                due30 + (element.due30Days ? element.due30Days : 0),
                due45 + (element.due45Days ? element.due45Days : 0),
                due60 + (element.due60Days ? element.due60Days : 0),
                due90 + (element.due90Days ? element.due90Days : 0),
                due120 + (element.due120Days ? element.due120Days : 0),
                due180 + (element.due180Days ? element.due180Days : 0),
                due180pluse + (element.due180pluseDays ? element.due180pluseDays : 0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL")
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45)
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent,
                csvData: rows,
                filename: "Payable_Aging_Report.csv"
            }
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            })
            this.setState({ agingReport: false })
        }
        else {
            this.setState({ agingReport: false });
        }
    }

    getTransactionHistoryDownload = (e, isBuyer_, bankData_) => {
        e.preventDefault();
        const { supplierBankTransactionHistory } = bankData_;
        let csvContent = "data:text/csv;charset=utf-8,";
        const table = {
            indent: 1,
            rows: []
        }
        const readbankTransactionValue = (rowData, indentation_, isGroup) => {
            for (let rowType of Object.keys(rowData)) {
                let row_;
                if (isGroup) {
                    row_ = {
                        indent: indentation_ + 1,
                        columns: [],
                    };
                    table.indent = Math.max(table.indent, indentation_ + 1);
                }
                else {
                    row_ = {
                        indent: indentation_,
                        columns: [],
                    };
                }
                //table.rows.push(row_);
                row_.columns.push('"' + rowType + '"');
                table.rows.push(row_);
                if (rowData[rowType]) {
                    if (typeof rowData[rowType] === 'object' && rowData[rowType] !== null) {
                        Object.keys(rowData[rowType]).forEach((childRowType, i) => {
                            if (rowData[rowType][childRowType].TransactionType) {
                                let ele = rowData[rowType][childRowType];
                                let row_ = {
                                    columns: [],
                                };
                                //table.rows.push(row_);
                                row_.columns.push(ele.TransactionType);
                                row_.columns.push(ele.Date);
                                row_.columns.push(ele.Description);
                                row_.columns.push(ele.Amount);
                                row_.columns.push(ele.Balance);
                                table.rows.push(row_);
                            }
                            else {
                                let newIndentation = indentation_;
                                if (rowData[rowType][childRowType].length > 0 && Array.isArray(rowData[rowType][childRowType])) {
                                    row_ = {
                                        columns: [],
                                        indent: indentation_ + 1,
                                    };
                                    table.indent = Math.max(table.indent, indentation_ + 1);
                                    row_.columns.push('"' + childRowType + '"');
                                    table.rows.push(row_);
                                    rowData[rowType][childRowType].forEach(ele => {
                                        let row_ = {
                                            columns: [],
                                        };
                                        //table.rows.push(row_);
                                        row_.columns.push(ele.TransactionType);
                                        row_.columns.push(ele.Date);
                                        row_.columns.push(ele.Description);
                                        row_.columns.push(ele.Amount);
                                        row_.columns.push(ele.Balance);
                                        table.rows.push(row_);
                                    })
                                }
                                else if (typeof rowData[rowType][childRowType] === 'object' && rowData[rowType][childRowType] !== null) {
                                    ++newIndentation;
                                    let row_ = {
                                        indent: newIndentation,
                                        columns: ['"' + childRowType + '"'],
                                    };
                                    table.rows.push(row_);

                                    table.indent = Math.max(table.indent, newIndentation);
                                    readbankTransactionValue(rowData[rowType][childRowType], newIndentation, true);
                                }
                            }
                        })
                    }
                }
            }
        }

        const readableJsonToCSV = (table_) => {
            let lastRow = null;
            console.log(table_);
            for (let row of table_.rows) {
                if (row.padding && !lastRow.padding) {
                    csvContent += "\r\n";
                }

                for (let c = -table_.indent; c < row.columns.length; ++c) {
                    let col;
                    if (row.indent) {
                        col = row.columns[c + (table_.indent - row.indent + 1)];
                    } else {
                        col = row.columns[c];
                    }

                    if (col) {
                        csvContent += col;
                    }

                    if (c !== row.columns.length - 1) {
                        csvContent += ",";
                    }
                }

                if (row.padding) {
                    csvContent += "\r\n";
                }

                csvContent += "\r\n";

                lastRow = row;
            }

            return csvContent;
        };
        readbankTransactionValue(supplierBankTransactionHistory, 1, false);
        if (table.rows.length > 0) {
            let headerRows_ = {
                columns: ["TransactionType", "Date", "Description", "Amount", "Balance"]
            }
            table.rows.unshift(headerRows_);
            readableJsonToCSV(table);
            console.log(csvContent);
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent.replace("data:text/csv;charset=utf-8,", ""),
                csvData: table.rows,
                filename: "BankTransactionHistory.csv",
                isBankTransactionHistory: true
            }
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            })
        }
    }

    getReceivableDetailsAgingReport = async () => {
        const { requesterDetails } = this.props.data;
        const rDetailReport_ = await this.api.getReceivablesDetailsAgingReport(requesterDetails._id);
        if (rDetailReport_ && rDetailReport_.receivablesDetails && rDetailReport_.receivablesDetails.length > 0) {
            this.setState({ recDetailAgingReport: rDetailReport_, isRecievablesDetailsAgingReport: true });
        }
    }

    getPayableDetailsAgingReport = async () => {
        const { requesterDetails } = this.props.data;
        const pDetailReport_ = await this.api.getPayablesDetailsAgingReport(requesterDetails._id);
        if (pDetailReport_ && pDetailReport_.payablesDetails && pDetailReport_.payablesDetails.length > 0) {
            this.setState({ payDetailAgingReport: pDetailReport_, isPayableDetailsAgingReport: true });
        }
    }

    recDetailAgingReportDownload = (e, data_, accountingType_) => {
        e.preventDefault();
        const rows = [];
        const { receivablesDetails } = data_;
        let columns = [];
        let count;
        let reportDate = `As on ${localDateFormat(data_.pulledDate)}`;
        columns.push(reportDate);
        rows.push(columns);
        rows.push([]);
        if (accountingType_ == "D365BC") {
            rows.push(["Customer ID", "Customer Name", "Due Days ", "Amount"])
        }
        else {
            rows.push(["Customer ID", "Customer Name", "Due Days ", "Transaction Type", "Transaction ID", "Transaction Date", "Due Date", "Amount"])
        }

        columns = [];
        receivablesDetails.forEach(element => {
            columns = [];
            count = 0;
            columns.push(element.customerId);
            columns.push('"' + element.customerName + '"');
            let isAlreadyInserted = false;
            if (element.currentDue > 0 && element.currentDuerecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.currentDuerecords, element.currentDue, "CurrentDue");
            }
            if (element.due30Days && element.due30Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due30Daysrecords, element.due30Days, "30 Days");
            }
            if (element.due45Days && element.due45Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due45Daysrecords, element.due45Days, "45 Days");
            }
            if (element.due60Days && element.due60Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due60Daysrecords, element.due60Days, "60 Days");
            }
            if (element.due90Days && element.due90Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due90Daysrecords, element.due90Days, "90 Days");
            }
            if (element.due120Days && element.due120Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due120Daysrecords, element.due120Days, "120 Days");
            }
            if (element.due180Days && element.due180Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180Daysrecords, element.due180Days, "180 Days");
            }
            if (element.due180pluseDays && element.due180pluseDaysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180pluseDaysrecords, element.due180pluseDays, "180 >");
            }
            if (!isAlreadyInserted) {
                if (element.currentDue && element.currentDue > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "CurrentDue", element.currentDue)
                    } else {
                        columns.push("CurrentDue", element.currentDue)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due30Days && element.due30Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "30 Days", element.due30Days)
                    } else {
                        columns.push("30 Days", element.due30Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due45Daysrecords && element.due45Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "45 Days", element.due45Daysrecords)
                    } else {
                        columns.push("45 Days", element.due45Daysrecords)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due60Days && element.due60Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "60 Days", element.due60Days)
                    } else {
                        columns.push("60 Days", element.due60Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due90Daysrecords && element.due90Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "90 Days", element.due90Daysrecords)
                    } else {
                        columns.push("90 Days", element.due90Daysrecords)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due120Days && element.due120Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "120 Days", element.due120Days)
                    } else {
                        columns.push("120 Days", element.due120Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180Days && element.due180Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 Days", element.due180Days)
                    } else {
                        columns.push("180 Days", element.due180Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180pluseDays && element.due180pluseDays > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 >", element.due180pluseDays)
                    } else {
                        columns.push("180 >", element.due180pluseDays)
                    }
                    rows.push(columns);
                    count++;
                }
            }
            // rows.push(columns);
        });
        function dueRecord(records_, dueAmt_, dueType_) {
            records_.forEach((d, i) => {
                if (i > 0 || count != 0) {
                    columns = [];
                    columns.push("");
                    columns.push("");
                }
                columns.push(dueType_);
                // columns.push(dueAmt_);
                columns.push(d.TransactionType);
                columns.push(d.TransactionID);
                columns.push(d.TransactionDate);
                columns.push(d.DueDate);
                columns.push(d.Amount);
                rows.push(columns);
            });
            count++;
        };
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        let _fileContent = {
            encodedUri: encodedUri,
            isCSVString: true,
            csvString: csvContent,
            csvData: rows,
            filename: "Receivable_Details_Aging_Report.csv"
        }
        this.setState({
            isFinancialDocument: false,
            fileViewerContent: _fileContent,
            isFileViewerModal: true
        })

    }

    payDetailAgingReportDownload = (e, data_, accountingType_) => {
        e.preventDefault();
        const rows = [];
        const { payablesDetails } = data_;
        let columns = [];
        let count;
        let reportDate = `As on ${localDateFormat(payablesDetails.pulledDate)}`;
        columns.push(reportDate);
        rows.push(columns);
        rows.push([]);
        if (accountingType_ == "D365BC") {
            rows.push(["Vendor ID", "Vendor Name", "Due Days", "Amount"]);
        } else {
            rows.push(["Vendor ID", "Vendor Name", "Due Days", "Transaction Type", "Transaction ID", "Transaction Date", "Due Date", "Amount"]);
        }
        payablesDetails.forEach(element => {
            columns = [];
            count = 0;
            columns.push(element.vendorId);
            columns.push('"' + element.vendorName + '"');
            let isAlreadyInserted = false;
            if (element.currentDue > 0 && element.currentDuerecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.currentDuerecords, element.currentDue, "CurrentDue");
            }
            if (element.due30Days && element.due30Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due30Daysrecords, element.due30Days, "30 Days");
            }
            if (element.due45Days && element.due45Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due45Daysrecords, element.due45Days, "45 Days");
            }
            if (element.due60Days && element.due60Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due60Daysrecords, element.due60Days, "60 Days");
            }
            if (element.due90Days && element.due90Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due90Daysrecords, element.due90Days, "90 Days");
            }
            if (element.due120Days && element.due120Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due120Daysrecords, element.due120Days, "120 Days");
            }
            if (element.due180Days && element.due180Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180Daysrecords, element.due180Days, "180 Days");
            }
            if (element.due180pluseDays && element.due180pluseDaysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180pluseDaysrecords, element.due180pluseDays, "180 >");
            }
            if (!isAlreadyInserted) {
                if (element.currentDue && element.currentDue > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "CurrentDue", element.currentDue)
                    } else {
                        columns.push("CurrentDue", element.currentDue)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due30Days && element.due30Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "30 Days", element.due30Days)
                    } else {
                        columns.push("30 Days", element.due30Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due45Daysrecords && element.due45Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "45 Days", element.due45Daysrecords)
                    } else {
                        columns.push("45 Days", element.due45Daysrecords)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due60Days && element.due60Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "60 Days", element.due60Days)
                    } else {
                        columns.push("60 Days", element.due60Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due90Daysrecords && element.due90Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "90 Days", element.due90Daysrecords)
                    } else {
                        columns.push("90 Days", element.due90Daysrecords)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due120Days && element.due120Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "120 Days", element.due120Days)
                    } else {
                        columns.push("120 Days", element.due120Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180Days && element.due180Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 Days", element.due180Days)
                    } else {
                        columns.push("180 Days", element.due180Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180pluseDays && element.due180pluseDays > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 >", element.due180pluseDays)
                    } else {
                        columns.push("180 >", element.due180pluseDays)
                    }
                    rows.push(columns);
                    count++;
                }
            }
            // rows.push(columns);
        });
        function dueRecord(records_, dueAmt_, dueType_) {
            records_.forEach((d, i) => {
                if (i > 0 || count != 0) {
                    columns = [];
                    columns.push("");
                    columns.push("");
                }
                columns.push(dueType_);
                // columns.push(dueAmt_);
                columns.push(d.TransactionType);
                columns.push(d.TransactionID);
                columns.push(d.TransactionDate);
                columns.push(d.DueDate);
                columns.push(d.Amount);
                rows.push(columns);
            })
            count++;
        };
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        let _fileContent = {
            encodedUri: encodedUri,
            isCSVString: true,
            csvString: csvContent,
            csvData: rows,
            filename: "Payable_Details_Aging_Report.csv"
        }
        this.setState({
            isFinancialDocument: false,
            fileViewerContent: _fileContent,
            isFileViewerModal: true
        })
    }

    handleNext = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    handleStepper = (value) => {
        this.setState({ activeStep: value });
    }

    render() {
        const {
            fundingContractId,
            funder,
            customerList,
            status,
            terms,
            contractDueDate,
            createdAt,
            requesterDetails,
            fundingAggrement,
            signedAggrements,
            funderAdditionalInfo,
            signedAggrementAt,
            isDigitalSignature,
            contractType,
            creditLimitForTradingPartner
        } = this.props.data;
        const {
            isPayableDetailsAgingReport,
            isRecievablesDetailsAgingReport,
            activeStep
        } = this.state;

        const steps = [
            { label: (this.user.currentUserRole() =="funder")?this.props.t("requester_details"): this.props.t("funder_details") },
            { label: this.props.t("fundingContracts_page.funding_request_details") },
            { label: this.user.currentUserRole() == "buyer" ? this.props.t("supplier_details") : this.user.currentUserRole() == "supplier" ? this.props.t("buyer_details") : this.props.t("trading_partner_details.title") },
            { label: this.props.t("contract_details") }
        ];
        return (
            <>
                {this.state.isSigningPopup && this.props.data && <SigningStatusModal
                    isReadOnly={true}
                    onClose={() => { this.setState({ isSigningPopup: false }) }}
                    data={this.props.data}
                />}
                {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                    onClose={() => {
                        this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                    }}
                    data={this.state.fileViewerContent}
                    isFinancialDocument={this.state.isFinancialDocument}
                ></FileViewerModal>}
                <div className="modal modal-fullscreen fade show modal-large" id="viewFundingRequestModal" tabIndex="-1" role="dialog" aria-labelledby="invoiceUploadModal" aria-hidden="true" style={{ "display": "block", "zIndex": "999" }}>
                    <div className="modal-dialog p-2" role="document">
                        <div className="modal-content">
                            <div className="modal-header pb-0" >
                                <h1>
                                    #{fundingContractId}
                                    <span className={"verfication-badge badge " + (status == 3 ? "badge-danger" : (status == 2 && fundingAggrement && signedAggrements) ? "badge-success" : "badge-warning")}>
                                        {status == 3 ? "Terminated" :
                                            (status == 2 && fundingAggrement && signedAggrements) ? this.props.t("active") :
                                                (status == 2 && !fundingAggrement && !signedAggrements) ? this.props.t("waiting_for_funder_to_upload"):
                                                    this.props.t("waiting_for_signed_agreement")}
                                    </span>
                                </h1>
                                <button type="button" className="close" onClick={() => {
                                    this.props.onClose();
                                }} data-dismiss="modal" aria-label="Close" style={{ cursor: "pointer" }} >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ overflowY: "none" }}>
                                <div className="terms-list-container w-100" style={{ display: "flex", height: "100%", justifyContent: "flex-end" }}>
                                    <div className='left-modal-tabs'>
                                        <Stepper activeStep={activeStep} data-testid="vertical-stepper" orientation="vertical">
                                            {steps.map((step, index) => {
                                                return (
                                                    <Step key={index} data-testid="step" onClick={() => this.handleStepper(index)}>
                                                        <StepLabel StepIconComponent={StepperIcon}>
                                                            {step.label}
                                                        </StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </div>
                                    <div className='right-modal-content ml-3'>
                                        {activeStep === 0 && (
                                            <div className='row mx-0 py-4'>
                                                <div className='col-md-4'>
                                                    {(this.user.currentUserRole() == "supplier" || this.user.currentUserRole() == "buyer") ?
                                                        (<>
                                                            <label className="view-details-header pb-2">{this.props.t("funder_details")}</label>
                                                            <UserDetails userDetails={funder} />
                                                        </>) :
                                                        (<React.Fragment><>
                                                            <label className="view-details-header pb-2">{this.props.t("requester_details")}</label>
                                                            <UserDetails userDetails={requesterDetails} />
                                                        </>
                                                        </React.Fragment>)
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        {activeStep === 1 && (
                                            <>
                                                <div className='row mx-0 py-4'>
                                                    <div className="col-md-12">
                                                        {/* {funderAdditionalInfo && Object.keys(funderAdditionalInfo).length>0 && (<>
                                                            <label className="view-details-header pb-2">Additional details for funding request</label>
                                                            <QuestionnaireFundingDetails questionnaireDetails={funderAdditionalInfo} />
                                                        </>)} */}
                                                        <label className="view-details-header pb-2">{this.props.t("grid_view_header_labels.funding_details")} {contractType.indexOf("ABL") == 0 ? " for Asset-Based Lending" : ""}</label>
                                                        <div className="row pb-4">
                                                            <FundingOfferDetails
                                                                fundingType_={contractType}
                                                                offerDetails={terms}
                                                            />
                                                        </div>
                                                        {(fundingAggrement || signedAggrements) && (
                                                            <>
                                                                <label className="view-details-header pb-2">{this.props.t("funding_offer_details.funding_agreement_title")}</label>
                                                                <div className="row pb-4">
                                                                    {(isDigitalSignature && isDigitalSignature == "true") ?
                                                                        (<>
                                                                            <div className="col-md-6">
                                                                                <span className="form-value">
                                                                                    <a className="anchorstyle" href="" onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.setState({ isSigningPopup: true });
                                                                                    }}>{this.props.t("funding_offer_details.signing_status")}</a>
                                                                                </span>
                                                                            </div>
                                                                        </>)
                                                                        : (<>
                                                                            {
                                                                                fundingAggrement && (
                                                                                    <div className="col-md-6">
                                                                                        <span className="form-value">
                                                                                            <a className="anchorstyle" href={""} onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({
                                                                                                    isFileViewerModal: true,
                                                                                                    isFinancialDocument: false,
                                                                                                    fileViewerContent: fundingAggrement
                                                                                                })
                                                                                            }}>{this.props.t("funding_offer_details.funding_agreement_label")}</a>
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                signedAggrements && (
                                                                                    <div className="col-md-6">
                                                                                        <span className="form-value">
                                                                                            <a className="anchorstyle" href={""} onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({
                                                                                                    isFileViewerModal: true,
                                                                                                    isFinancialDocument: false,
                                                                                                    fileViewerContent: signedAggrements
                                                                                                })
                                                                                            }}>{this.props.t("funding_offer_details.signed_copy_label")}</a>
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </>)}
                                                                </div>
                                                            </>
                                                        )}
                                                        {this.user.currentUserRole() == "funder" && (

                                                            <>
                                                                <div className="row pb-4">
                                                                    {this.state.supplierFincicalDocResponse.length > 0 ? (
                                                                        <div className="col-lg-12">
                                                                            <label className="view-details-header pb-2">{this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.financial_documents")}
                                                                                {this.state.supplierFincicalDocResponse.length > 0 && (
                                                                                    <span className="ml-2 badge badge-success">
                                                                                        {fileStatusBadgeText(this.state.supplierFincicalDocResponse[0] && this.state.supplierFincicalDocResponse[0].source ? this.state.supplierFincicalDocResponse[0].source : "")}
                                                                                    </span>
                                                                                )}
                                                                            </label>
                                                                            <span className="form-value">
                                                                                {this.state.supplierFincicalDocResponse.length ?
                                                                                    (<>
                                                                                        <div className="row">
                                                                                            {this.getDropBoxProfileContent(this.state.supplierFincicalDocResponse)}
                                                                                            {requesterDetails.accountingInformation && requesterDetails.accountingInformation == "quickbooks" && (
                                                                                                <>{!this.state.isAgingfile && (
                                                                                                    <React.Fragment>
                                                                                                        <div className="col-md-3">
                                                                                                              <span>
                                                                                                                <a
                                                                                                                    className="anchorstyle text-uppercase"
                                                                                                                    href=""
                                                                                                                    onClick={(e) => this.getAggingReportDownload(e, this.props.data.requesterDetails.email, this.props.data.requesterDetails.accountingInformation, "supplier")}
                                                                                                                >{this.props.t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </React.Fragment>
                                                                                                )}

                                                                                                    {!this.state.isPayableAgingReport && (
                                                                                                        <React.Fragment>
                                                                                                            <div className="col-md-3">
                                                                                                                  <span>
                                                                                                                    <a
                                                                                                                        className="anchorstyle text-uppercase"
                                                                                                                        href=""
                                                                                                                        onClick={(e) => this.getPayableAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.requesterDetails.accountingInformation)}
                                                                                                                    >{this.props.t("profile_page.financial_information_content.payable_aging_label")}</a>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    )}

                                                                                                    {(requesterDetails.accountingInformation && requesterDetails.accountingInformation == "quickbooks") &&
                                                                                                        (<React.Fragment>
                                                                                                            {(this.state.bankTransactionHistory) && (
                                                                                                                <div className="col-md-3">
                                                                                                                      <span>
                                                                                                                        <a
                                                                                                                            className="anchorstyle text-uppercase"
                                                                                                                            href=""
                                                                                                                            onClick={(e) => this.getTransactionHistoryDownload(e, true, this.state.bankTransactionHistory)}
                                                                                                                        >
                                                                                                                            {this.props.t("profile_page.financial_information_content.bank_transaction_label")}
                                                                                                                        </a>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </React.Fragment>
                                                                                                        )}
                                                                                                    {(isPayableDetailsAgingReport && requesterDetails.accountingInformation && (requesterDetails.accountingInformation == "quickbooks" || requesterDetails.accountingInformation == "D365BC")) &&
                                                                                                        <div className="col-md-3">
                                                                                                              <span>
                                                                                                                <a
                                                                                                                    className="anchorstyle"
                                                                                                                    href=""
                                                                                                                    onClick={(e) => this.payDetailAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.requesterDetails.accountingInformation)}
                                                                                                                >
                                                                                                                    {this.props.t("profile_page.financial_information_content.payable_details_aging_label")}
                                                                                                                </a>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {(isRecievablesDetailsAgingReport && requesterDetails.accountingInformation && (requesterDetails.accountingInformation == "quickbooks" || requesterDetails.accountingInformation == "D365BC")) &&
                                                                                                        <div className="col-md-3">
                                                                                                              <span>
                                                                                                                <a
                                                                                                                    className="anchorstyle text-uppercase"
                                                                                                                    href=""
                                                                                                                    onClick={(e) => this.recDetailAgingReportDownload(e, this.state.recDetailAgingReport, this.props.data.requesterDetails.accountingInformation)}
                                                                                                                >
                                                                                                                    {this.props.t("profile_page.financial_information_content.receivable_details_aging_label")}
                                                                                                                </a>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    }

                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </>) : (<div>{this.props.t("documents_not_found_msg")}</div>)}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            {!(!this.state.isAgingfile ||
                                                                                !this.state.isPayableAgingReport ||
                                                                                isPayableDetailsAgingReport ||
                                                                                isRecievablesDetailsAgingReport) && (<>
                                                                                    <div className="col-lg-12">
                                                                                        <label className="view-details-header pb-2">{this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.financial_documents")}</label>
                                                                                        <span className='form-value'>
                                                                                            {this.props.t("documents_not_found_msg")}
                                                                                        </span>
                                                                                    </div></>)}
                                                                        </>
                                                                    )}
                                                                    {(!this.state.isAgingfile ||
                                                                        !this.state.isPayableAgingReport ||
                                                                        isPayableDetailsAgingReport ||
                                                                        isRecievablesDetailsAgingReport) && requesterDetails.accountingInformation && requesterDetails.accountingInformation != "quickbooks" && (
                                                                            <div className="col-lg-12">
                                                                                <label className="view-details-header pb-2">{this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.financial_documents")}
                                                                                    {(!this.state.isAgingfile ||
                                                                                        !this.state.isPayableAgingReport ||
                                                                                        isPayableDetailsAgingReport ||
                                                                                        isRecievablesDetailsAgingReport
                                                                                    ) && (
                                                                                            <span className="ml-2 badge badge-success">
                                                                                                {fileStatusBadgeText(requesterDetails.accountingInformation)}
                                                                                            </span>
                                                                                        )}
                                                                                </label>
                                                                                <span className="form-value">
                                                                                    <div className="row">
                                                                                        {!this.state.isAgingfile && (
                                                                                            <React.Fragment>
                                                                                                <div className="col-md-3">
                                                                                                      <span>
                                                                                                        <a
                                                                                                            className="anchorstyle"
                                                                                                            href=""
                                                                                                            onClick={(e) => this.getAggingReportDownload(e, this.props.data.requesterDetails.email, this.props.data.requesterDetails.accountingInformation, "supplier")}
                                                                                                        >{this.props.t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        )}

                                                                                        {!this.state.isPayableAgingReport && (
                                                                                            <React.Fragment>
                                                                                                <div className="col-md-3">
                                                                                                      <span>
                                                                                                        <a
                                                                                                            className="anchorstyle text-uppercase"
                                                                                                            href=""
                                                                                                            onClick={(e) => this.getPayableAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.requesterDetails.accountingInformation)}
                                                                                                        >{this.props.t("profile_page.financial_information_content.payable_aging_label")}</a>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        )}

                                                                                        {(requesterDetails.accountingInformation && requesterDetails.accountingInformation == "quickbooks") &&
                                                                                            (<React.Fragment>
                                                                                                {(this.state.bankTransactionHistory) && (
                                                                                                    <div className="col-md-3">
                                                                                                          <span>
                                                                                                            <a
                                                                                                                className="anchorstyle text-uppercase"
                                                                                                                href=""
                                                                                                                onClick={(e) => this.getTransactionHistoryDownload(e, true, this.state.bankTransactionHistory)}
                                                                                                            >
                                                                                                                {this.props.t("profile_page.financial_information_content.bank_transaction_label")}
                                                                                                            </a>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </React.Fragment>
                                                                                            )}
                                                                                        {(isPayableDetailsAgingReport && requesterDetails.accountingInformation && (requesterDetails.accountingInformation == "quickbooks" || requesterDetails.accountingInformation == "D365BC")) &&
                                                                                            <div className="col-md-3">
                                                                                                  <span>
                                                                                                    <a
                                                                                                        className="anchorstyle text-uppercase"
                                                                                                        href=""
                                                                                                        onClick={(e) => this.payDetailAgingReportDownload(e, this.state.payDetailAgingReport, this.props.data.requesterDetails.accountingInformation)}
                                                                                                    >
                                                                                                        {this.props.t("profile_page.financial_information_content.payable_details_aging_label")}
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                        {(isRecievablesDetailsAgingReport && requesterDetails.accountingInformation && (requesterDetails.accountingInformation == "quickbooks" || requesterDetails.accountingInformation == "D365BC")) &&
                                                                                            <div className="col-md-3">
                                                                                                 <span>
                                                                                                    <a
                                                                                                        className="anchorstyle text-uppercase"
                                                                                                        href=""
                                                                                                        onClick={(e) => this.recDetailAgingReportDownload(e, this.state.recDetailAgingReport, this.props.data.requesterDetails.accountingInformation)}
                                                                                                    >
                                                                                                        {this.props.t("profile_page.financial_information_content.receivable_details_aging_label")}
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                </div>


                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <label className="view-details-header pb-2">{this.props.t("profile_page.financial_information_content.requester_additional_documents")}
                                                                            {this.state.supplierFinancialAddDocs.length > 0 && (
                                                                                <span className="ml-2 badge badge-success">
                                                                                    {fileStatusBadgeText("")}
                                                                                </span>
                                                                            )}
                                                                        </label>
                                                                          <span>
                                                                            {this.state.supplierFinancialAddDocs.length
                                                                                ? <div className="row">
                                                                                    {this.getDropBoxProfileContent(this.state.supplierFinancialAddDocs, "", true)}
                                                                                </div>
                                                                                : <div>{this.props.t("documents_not_found_msg")}</div>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>)}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {activeStep === 2 && (
                                            <div className='row mx-0 py-4'>
                                                <div className='col-md-12'>
                                                    <label className="view-details-header pb-2">{this.user.currentUserRole()=="supplier"?this.props.t("buyer_details"):this.user.currentUserRole()=="buyer"?this.props.t("supplier_details"):this.props.t("trading_partner_details.title")}</label>
                                                    {contractType != "ABL" && (
                                                        <>
                                                            <div className="row">
                                                                {customerList && customerList.map(item => (
                                                                    <div className="col-lg-6">
                                                                        <UserDetails userDetails={item} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {(creditLimitForTradingPartner && process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED=="true") && (
                                                        <>
                                                            <label className="view-details-header pb-2">Credit limit for {compareRole(this.user.currentUserRole(), enumRole.Supplier) ? "buyer(s)" : "trading partner(s)"}</label>
                                                            <div className="row pb-4">
                                                                {Object.keys(creditLimitForTradingPartner).map((tp_, i) => {
                                                                    return (
                                                                        <>
                                                                            <div key={i} className='col-3'>
                                                                                <label>{creditLimitForTradingPartner[tp_].tradingPartnerName}</label>
                                                                            </div>
                                                                            <div key={i} className='col-9'>
                                                                                <strong className="form-value link-text-color">
                                                                                    {creditLimitForTradingPartner[tp_].creditLimitValue == 0 ? "No Credit Limit" : dollarFormatter.format(creditLimitForTradingPartner[tp_].creditLimitValue)}
                                                                                </strong>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                    )}
                                                    {contractType != "ABL" && contractType != "TCF" && this.state.customerList.length > 0 && this.state.customerList.map(buyer_ => {
                                                        return (
                                                            <>
                                                                {buyer_.financialDocuments.length > 0 ? (<>
                                                                    <div className="row pt-4">
                                                                        <div className="col-lg-12">
                                                                            <label className="view-details-header pb-2">{buyer_.name ? buyer_.name : ""} &nbsp;{this.props.t("financial_documents")}
                                                                                <span className="ml-2 badge badge-primary">
                                                                                    {buyer_.role && buyer_.role == "Supplier" ? "Supplier" : "Buyer"}
                                                                                </span>
                                                                                <span className="ml-2 badge badge-success">
                                                                                    {fileStatusBadgeText(buyer_.financialDocuments.length[0] && buyer_.financialDocuments.length[0].source ? buyer_.financialDocuments.length[0].source : "")}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {buyer_.financialDocuments.length
                                                                        ? <div className="row">
                                                                            {this.getDropBoxProfileContent(buyer_.financialDocuments)}
                                                                            {buyer_.accountingInformation && buyer_.accountingInformation == "quickbooks" && (<>
                                                                                {!buyer_.isAgingfile && (
                                                                                    <React.Fragment>
                                                                                        <div className="col-md-6 pl-5">
                                                                                            <span>
                                                                                                <a
                                                                                                    className="anchorstyle"
                                                                                                    href=""
                                                                                                    onClick={(e) => this.getAggingReportDownload(e, buyer_.email, buyer_.accountingInformation)}
                                                                                                >{this.props.t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                                                                            </span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {buyer_.payableAging && (
                                                                                    <React.Fragment>
                                                                                        <div className="col-md-6 pl-5">
                                                                                            <span>
                                                                                                <a
                                                                                                    className="anchorstyle text-uppercase"
                                                                                                    href=""
                                                                                                    onClick={(e) => this.getPayableAgingReportDownload(e, buyer_.payableAging, buyer_.accountingInformation)}
                                                                                                >{this.props.t("profile_page.financial_information_content.payable_aging_label")}</a>
                                                                                            </span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {(buyer_.accountingInformation && buyer_.accountingInformation == "quickbooks") &&
                                                                                    (<React.Fragment>
                                                                                        {(buyer_.bankStatement) && (
                                                                                            <div className="col-md-6 pl-5">
                                                                                                <span>
                                                                                                    <a
                                                                                                        className="anchorstyle text-uppercase"
                                                                                                        href=""
                                                                                                        onClick={(e) => this.getTransactionHistoryDownload(e, true, buyer_.bankStatement)}
                                                                                                    >
                                                                                                        {this.props.t("profile_page.financial_information_content.bank_transaction_label")}
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                    )}
                                                                                {(buyer_.payablesDetailsAging && buyer_.accountingInformation && (buyer_.accountingInformation == "quickbooks" || buyer_.accountingInformation == "D365BC")) &&
                                                                                    <div className="col-md-6 pl-5">
                                                                                        <span>
                                                                                            <a
                                                                                                className="anchorstyle text-uppercase"
                                                                                                href=""
                                                                                                onClick={(e) => this.payDetailAgingReportDownload(e, this.state.payDetailAgingReport, buyer_.accountingInformation)}
                                                                                            >
                                                                                                {this.props.t("profile_page.financial_information_content.payable_details_aging_label")}
                                                                                            </a>
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                                {(buyer_.receivableDetailsAging && buyer_.accountingInformation && (buyer_.accountingInformation == "quickbooks" || buyer_.accountingInformation == "D365BC")) &&
                                                                                    <div className="col-md-6 pl-5">
                                                                                        <span>
                                                                                            <a
                                                                                                className="anchorstyle text-uppercase"
                                                                                                href=""
                                                                                                onClick={(e) => this.recDetailAgingReportDownload(e, this.state.recDetailAgingReport, buyer_.accountingInformation)}
                                                                                            >
                                                                                                {this.props.t("profile_page.financial_information_content.receivable_details_aging_label")}
                                                                                            </a>
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                            </>)}

                                                                        </div>
                                                                        : <div className='pl-5'>{this.props.t("documents_not_found_msg")}</div>
                                                                    }
                                                                </>) :
                                                                    (<>
                                                                        {!(!buyer_.isAgingfile ||
                                                                            buyer_.payableAging ||
                                                                            buyer_.payablesDetailsAging ||
                                                                            buyer_.receivableDetailsAging) && (<>
                                                                                <div className="col-lg-12">
                                                                                    <label className="view-details-header pb-2">{buyer_.name ? buyer_.name : ""} {this.props.t("financial_documents")}</label>
                                                                                    <span className='form-value'>
                                                                                        {this.props.t("documents_not_found_msg")}
                                                                                    </span>
                                                                                </div></>)}

                                                                    </>)}
                                                                {(!buyer_.isAgingfile ||
                                                                    buyer_.payableAging ||
                                                                    buyer_.payablesDetailsAging ||
                                                                    buyer_.receivableDetailsAging) && buyer_.accountingInformation && buyer_.accountingInformation != "quickbooks" ? (
                                                                    <>

                                                                        <div className="row pt-4">
                                                                            <div className="col-lg-12">
                                                                                <label className="view-details-header pb-2">{buyer_.name ? buyer_.name : ""} {this.props.t("financial_documents")}
                                                                                    <span className="ml-2 badge badge-primary">
                                                                                        {buyer_.role == "Supplier" ? "Supplier" : "Buyer"}
                                                                                    </span>
                                                                                    {(!buyer_.isAgingfile ||
                                                                                        buyer_.payableAging ||
                                                                                        buyer_.payablesDetailsAging ||
                                                                                        buyer_.receivableDetailsAging
                                                                                    ) && (
                                                                                            <span className="ml-2 badge badge-success">
                                                                                                {fileStatusBadgeText(buyer_.accountingInformation)}
                                                                                            </span>
                                                                                        )}
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row pb-2">
                                                                            {!buyer_.isAgingfile && (
                                                                                <React.Fragment>
                                                                                    <div className="col-md-6 pl-5">
                                                                                        <span>
                                                                                            <a
                                                                                                className="anchorstyle text-uppercase"
                                                                                                href=""
                                                                                                onClick={(e) => this.getAggingReportDownload(e, buyer_.email, buyer_.accountingInformation)}
                                                                                            >{this.props.t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                                                                        </span>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {buyer_.payableAging && (
                                                                                <React.Fragment>
                                                                                    <div className="col-md-6 pl-5">
                                                                                        <span>
                                                                                            <a
                                                                                                className="anchorstyle text-uppercase"
                                                                                                href=""
                                                                                                onClick={(e) => this.getPayableAgingReportDownload(e, buyer_.payableAging, buyer_.accountingInformation)}
                                                                                            >{this.props.t("profile_page.financial_information_content.payable_aging_label")}</a>
                                                                                        </span>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {(buyer_.accountingInformation && buyer_.accountingInformation == "quickbooks") &&
                                                                                (<React.Fragment>
                                                                                    {(buyer_.bankStatement) && (
                                                                                        <div className="col-md-6 pl-5">
                                                                                            <span>
                                                                                                <a
                                                                                                    className="anchorstyle text-uppercase"
                                                                                                    href=""
                                                                                                    onClick={(e) => this.getTransactionHistoryDownload(e, true, buyer_.bankStatement)}
                                                                                                >
                                                                                                    {this.props.t("profile_page.financial_information_content.bank_transaction_label")}
                                                                                                </a>
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                </React.Fragment>
                                                                                )}
                                                                            {(buyer_.payablesDetailsAging && buyer_.accountingInformation && (buyer_.accountingInformation == "quickbooks" || buyer_.accountingInformation == "D365BC")) &&
                                                                                <div className="col-md-6 pl-5">
                                                                                    <span>
                                                                                        <a
                                                                                            className="anchorstyle text-uppercase"
                                                                                            href=""
                                                                                            onClick={(e) => this.payDetailAgingReportDownload(e, this.state.payDetailAgingReport, buyer_.accountingInformation)}
                                                                                        >
                                                                                            {this.props.t("profile_page.financial_information_content.payable_details_aging_label")}
                                                                                        </a>
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                            {(buyer_.receivableDetailsAging && buyer_.accountingInformation && (buyer_.accountingInformation == "quickbooks" || buyer_.accountingInformation == "D365BC")) &&
                                                                                <div className="col-md-6 pl-5">
                                                                                    <span>
                                                                                        <a
                                                                                            className="anchorstyle text-uppercase"
                                                                                            href=""
                                                                                            onClick={(e) => this.recDetailAgingReportDownload(e, this.state.recDetailAgingReport, buyer_.accountingInformation)}
                                                                                        >
                                                                                            {this.props.t("profile_page.financial_information_content.receivable_details_aging_label")}
                                                                                        </a>
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    
                                                                        <label className="view-details-header pb-2">{buyer_.name ? buyer_.name : ""}  {this.props.t("financial_documents")}
                                                                        <span className="ml-2 badge badge-primary">
                                                                                    {buyer_.role && buyer_.role == "Supplier" ? "Supplier" : "Buyer"}
                                                                                </span>
                                                                        </label>
                                                                        <span >
                                                                            {this.props.t("documents_not_found_msg")}
                                                                        </span>
                                                                    </>
                                                                )}

                                                                <div className="row pt-4">
                                                                    <div className="col-lg-12">
                                                                        <label className="view-details-header pb-2">{buyer_.name ? buyer_.name : ""}  {this.props.t("profile_page.financial_information_content.additional_document_title")}  <span className="ml-2 badge badge-primary">
                                                                            {buyer_.role && buyer_.role == "Supplier" ? "Supplier" : "Buyer"}
                                                                        </span>
                                                                            {buyer_.additionalDocuments && buyer_.additionalDocuments.length > 0 && (
                                                                                <span className="ml-2 badge badge-success">
                                                                                    {fileStatusBadgeText("")}
                                                                                </span>
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                {buyer_.additionalDocuments && buyer_.additionalDocuments.length > 0 ? (<>
                                                                    <div className="row pb-2">
                                                                        {this.getDropBoxProfileContent(buyer_.additionalDocuments, "", true)}
                                                                    </div>
                                                                </>) : (<div className='pb-2'>{this.props.t("documents_not_found_msg")}</div>)}

                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {activeStep === 3 && (

                                            <div className='row mx-0 py-4'>
                                                <div className='col-md-12'>
                                                    {(fundingAggrement || signedAggrements) && (
                                                        <>
                                                            <label className="view-details-header pb-2">{this.props.t("funding_offer_details.funding_agreement_title")}</label>
                                                            <div className="row pb-4">
                                                                {(isDigitalSignature && isDigitalSignature == "true") ?
                                                                    (<>
                                                                        <div className="col-md-6">
                                                                            <span className="form-value">
                                                                                <a className="anchorstyle" href="" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({ isSigningPopup: true });
                                                                                }}>{this.props.t("funding_offer_details.signing_status")}</a>
                                                                            </span>
                                                                        </div>
                                                                    </>)
                                                                    : (<>
                                                                        {
                                                                            fundingAggrement && (
                                                                                <div className="col-md-6">
                                                                                    <span className="form-value">
                                                                                        <a className="anchorstyle" href={""} onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.setState({
                                                                                                isFileViewerModal: true,
                                                                                                isFinancialDocument: false,
                                                                                                fileViewerContent: fundingAggrement
                                                                                            })
                                                                                        }}>{this.props.t("funding_offer_details.funding_agreement_label")}</a>
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            signedAggrements && (
                                                                                <div className="col-md-6">
                                                                                    <span className="form-value">
                                                                                        <a className="anchorstyle" href={""} onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.setState({
                                                                                                isFileViewerModal: true,
                                                                                                isFinancialDocument: false,
                                                                                                fileViewerContent: signedAggrements
                                                                                            })
                                                                                        }}>{this.props.t("funding_offer_details.signed_copy_label")}</a>
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </>)}
                                                            </div>
                                                        </>
                                                    )}
                                                    <label className="view-details-header pb-2">{this.props.t("contract_duration_details")}</label>
                                                    <div className='row'>
                                                        <div className="col-md-4">
                                                            <label className="form-label">{this.props.t("contract_created")}</label>
                                                            <span className="form-value">
                                                                {signedAggrementAt ? (<>
                                                                    {localDateFormat(signedAggrementAt)}
                                                                </>) : (<>
                                                                    {"__/__/____"}
                                                                </>)}
                                                            </span>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">{this.props.t("contract_valid_till")}</label>
                                                            <span className="form-value">
                                                                {signedAggrementAt ? (
                                                                    <>{localDateFormat(contractDueDate)}</>
                                                                ) : (<>
                                                                {"__/__/____"}
                                                                </>)}
                                                                
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button className='btn btn-primary' disabled={activeStep === 0} onClick={this.handleBack}>
                                    {this.props.t("button_names.previous")}
                                </button>
                                <button className='btn btn-primary' disabled={activeStep === steps.length - 1} onClick={this.handleNext}>
                                {this.props.t("button_names.next")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>

        )
    }
}

//.. added the customized css color ..//
const StepperIcon = (props) => {
    const { active, completed } = props;
    const user=new User();
    const colorValue=user.currentUserRole()=="buyer"?"#0572B0":user.currentUserRole()=="supplier"?"#255f79":"green";
    return (
        <StepIcon
            {...props}
            classes={{
                active: 'MuiStepIcon-active',
                completed: 'MuiStepIcon-completed',

            }}
            style={{
                color: active || completed ?colorValue : '',

            }}
        />
    );
};


export default NewViewFundingContract
