import React, { Component } from 'react';
import FullSpinner from '../Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import Popup from '../Auth/Popup';
import Dropzone from 'react-dropzone';
import { ShortText } from '../../../services/Utilities';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddModal } from './CommonConfirmationModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import FileUploader from '../FileUploader/FileUploader';
import FileViewerModal from '../FileViewerModal/FileViewerModal';
import { withTranslation } from 'react-i18next';

var AgingReportPostdata = {};
class SupplierBankStatementModal extends Component {
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isBalanceStatement: false,
            isShortName: false,
            balanceStatement: [],
            deletedBalanceStatement: [],
            newBankStatement: [],
            isUpdateBankStatement: false,
            balanceStmt: {
                balanceStmt_1: {
                    fileName: "",
                    downloadUrl: ""
                },
                balanceStmt_2: {
                    fileName: "",
                    downloadUrl: ""
                },
                balanceStmt_3: {
                    fileName: "",
                    downloadUrl: ""
                }
            },
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false
        };
    }
    componentDidMount() {
        if (this.props.data && this.props.data.length > 0) {
            this.setState({ balanceStatement: this.props.data });
        }
    }

    async uploadFinancialHistory() {
        const { balanceStatement, deletedBalanceStatement, newBankStatement } = this.state;
        let isValidBankStat = true;
        if (balanceStatement.length > 0) {
            balanceStatement.forEach(b_ => {
                let fileExtention = (b_.filename ? b_.filename : b_.name).substring((b_.filename ? b_.filename : b_.name).lastIndexOf(".") + 1).toUpperCase();
                if (!(fileExtention == 'CSV' || fileExtention == "PDF")) {
                    isValidBankStat = false;
                }
            });
        }
        if (isValidBankStat) {
             this.setState({ isLoading: true });
            const documentData = new FormData();
            if (balanceStatement.length == 1) {
                documentData.append("BANK_STATEMENT_1", balanceStatement[0]);
            }
            else {
                balanceStatement.forEach((stmt, i) => {
                    documentData.append("BANK_STATEMENT_" + (i + 1), stmt);
                });
            }
            if (deletedBalanceStatement.length > 0 || (newBankStatement.length > 0 && this.props.data.length > 0)) {
                for (let bsmt_ of deletedBalanceStatement) {
                    documentData.append('deleteBankStatement[]', JSON.stringify(bsmt_));
                }
                await this.api.updateFinancialBankStatementUpload(documentData);
            } else {
                await this.api.financialBankStatementUpload(documentData);
            }
            //this.props.onClose();

             this.setState({isLoading:false});
        }
        else {
            AddModal(this.props.t("file_upload_msg.unsupported_format_title"), this.props.t("file_upload_msg.accept_pdf_and_csv_only_msg"), null, false);
        }

    }

    onDropBankStatement = (type, documents) => {
        this.setState({
            balanceStatement: documents,
            isBalanceStatement: true,
            isShortName: true,
            newBankStatement: documents
        });
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    handleDelete = (filteredDocs, selectedDoc) => {
        selectedDoc.version && selectedDoc.downloadUrl ?
            this.setState(prevState => ({
                balanceStatement: filteredDocs,
                deletedBalanceStatement: [...prevState.deletedBalanceStatement, selectedDoc],
                isBalanceStatement: true,
            })) :

            this.setState(prevState => ({
                balanceStatement: filteredDocs,
                newBankStatement: filteredDocs,
            }));
    };

    render() {
        const {
            isLoading,
            isBalanceStatement,
            balanceStatement,
            balanceStmt
        } = this.state;

        const isFinishBtnEnabled = !(isBalanceStatement)
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("bank_statement_uploaded")} callback={e => this.props.onClose()} />
                        {this.state.isFileViewerModal && this.state.fileViewerContent &&
                            <FileViewerModal
                                onClose={() => {
                                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                                }}
                                data={this.state.fileViewerContent}
                                isFinancialDocument={this.state.isFinancialDocument}
                            ></FileViewerModal>
                        }
                        <CommonPopUpModal
                        size="small-medium"
                        title={this.props.t("bank_statement_upload_modal_title")}
                        onClose={e => this.props.onClose()}
                        primaryBtn={this.props.t("button_names.finish")}
                        isSubmitBtn={isFinishBtnEnabled}
                        onSubmit={() => this.uploadFinancialHistory()}
                        promptOnClose={!isFinishBtnEnabled}
                        >
                            <div className="d-flex flex-column">
                                <div className="d-flex flex-column justify-content-center">
                                    <FileUploader
                                        documents={this.state.balanceStatement.length ? this.state.balanceStatement : []}
                                        onDocUpload={doc => this.onDropBankStatement("Bank_Statements", doc)}
                                        onDelete={this.handleDelete}
                                        onView={this.handleView}
                                        namelength={50}
                                        supportedExt={['.pdf', '.csv']}
                                        placeholder={this.props.t("file_upload_msg.common_upload_msg")}
                                        isdisabled={false}
                                        isMultiple={true}
                                        isExtraction={false}
                                        supportNotes={true}
                                        customHeight={"35vh"}
                                    />
                                </div>
                            </div>
                        </CommonPopUpModal>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        );
    }
}

export default withTranslation() (SupplierBankStatementModal);