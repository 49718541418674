// Author: Rajesh Rajendran
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import FullSpinner from '../Spinner/FullSpinner.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AddModal } from '../Modals/CommonConfirmationModal.js';
import { convertUTF8String, getFileName, DownloadLink } from "../../../services/ConversionService.js";
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API.js';
import { processFile } from "../../../services/azureFormRecognizerService.js";
import { useTranslation } from 'react-i18next';
import CommonConfirmationModal from '../Modals/CommonConfirmationModal.js';
import FileMetaDataPopover from '../PopOver/FileMetaDataPopover.js';

const FileUploader = ({
    documents,
    onDocUpload,
    onDelete,
    onView,
    namelength,
    supportedExt,
    errorMsgType,
    placeholder,
    errorMsgDuplicate,
    isdisabled,
    fileToExtract,
    isMultiple,
    isExtraction,
    convertToJson,
    onLoadingError,
    onFileExtracted,
    overrideFileName,
    isDeleteEnable,
    customHeight,
    isExampleFile,
    exampleFileContent,
    exampleFileName,
    supportNotes = true
}) => {
    const [loadedFile, setLoadedFile] = useState(fileToExtract);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isDataUploaded, setIsDataUploaded] = useState(false);
    const [isPdfParsing, setIsPdfParsing] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [pendingData, setPendingData] = useState(null);
    const { t, i18n } = useTranslation();

    const api = new API();
    const defaultExt = ['.pdf'];
    const defaultErrorMsg = t("file_upload_msg.please_provide_supported_file_format");
    const defaultduplicateErrorMsg = t("file_upload_msg.default_error_msg");
    const extensions = supportedExt || defaultExt;
    const fileTypeError = errorMsgType || defaultErrorMsg;
    const duplicateErrorMsg = errorMsgDuplicate || defaultduplicateErrorMsg;
    const fileNameLength = namelength || 50;

    const processDocumentFiles = async (docFiles) => {
        if (docFiles.length > 0) {
            const formData = new FormData();
            docFiles.forEach(file => {
                formData.append("TemporaryFiles", file, file.name);
            });
            const _temporaryFiles = await api.saveTemporaryFileInServer(formData);
            docFiles.forEach((file, index) => {
                file.preview = `${API_ENDPOINT_SUPPLIER}/tcf/download-document/${_temporaryFiles[index]._id}`;
            });
        }
        return docFiles;
    };

    const handleDocumentUpload = async (data) => {

        isExtraction ? onFileExtracted({ data: "" }) : null

        // if (!isMultiple && !isExtraction && Object.keys(documents).length > 0 && isDeleteEnable) {
        //     AddModal(t("file_upload_msg.file_limit_exceed"), <div>{t("file_upload_msg.one_file_accept_msg")}</div>, null, false);
        //     setIsDragActive(false);
        //     return;
        // }

        if (!isMultiple && !isExtraction && Object.keys(documents).length > 0 && isDeleteEnable) {
        setIsConfirm(true);
        setPendingData(data);
        setIsDragActive(false);
        return;
        }

        await continueDocumentUpload(data);
    }

    const continueDocumentUpload = async (data) => {

        let unsupportedFiles = [];
        let fileData = !isExtraction ? (Object.keys(documents).length > 0 ? documents : [...documents]) : loadedFile;
        let extractedData = {}

        data.forEach(element => {
            element.id = Math.random().toString(36).slice(2);
            const fileExtension = element.name.toLowerCase().split('.').pop();
            if (!extensions.includes(`.${fileExtension}`)) {
                unsupportedFiles.push(element);
            }
        });

        if (unsupportedFiles.length > 0 || data.length == 0) {
            AddModal(t("file_upload_msg.unsupported_format_title"), <div> {fileTypeError} <br /> {<strong>{extensions.join(', ')}</strong>} </div>, null, false);
            setIsDragActive(false);
            return;
        }

        if (isExtraction) {
            setIsPdfParsing(true);
            setIsDragActive(false);
            setLoadedFile(data);

            try {
                await processFile(data[0], convertToJson, data, (invoiceData) => {
                    extractedData = invoiceData;
                });
            } catch (error) {
                console.error('Invoice extraction failed:', error);
                setIsPdfParsing(false);
                onLoadingError({ data: null, loadedFile: null, isPdfParsing: false });
            }
        } else {
            try {
                let matchedFiles = [];
                const filesToCheck = Array.isArray(fileData) ? fileData : [fileData];
                for (let existingDoc of filesToCheck) {
                    for (let file of data) {
                        if (file.name === (existingDoc.name || existingDoc.filename || existingDoc.fileName)) {
                            matchedFiles.push(file);
                        }
                    }
                }

                if (matchedFiles.length > 0) {
                    let displayText = matchedFiles.slice(0, 3).map(file => file.name).join(", ");
                    if (matchedFiles.length > 3) {
                        displayText += ", and more";
                    }
                    AddModal(duplicateErrorMsg, <div>{displayText}</div>, null, false)
                    setIsDragActive(false);
                    data = data.filter(file => !matchedFiles.some(matchedFile => matchedFile.name === file.name));
                    return
                }

                const docExtensions = ['.xlsx', '.xls', '.doc', '.docx', '.ppt', '.pptx']; 

                const docFiles = data.filter(file => docExtensions.some(ext => file.name.toLowerCase().endsWith(ext)));
                const otherFiles = data.filter(file => !docExtensions.some(ext => file.name.toLowerCase().endsWith(ext)));

                let processedDocFiles = [];
                if (docFiles.length > 0) {
                    processedDocFiles = await processDocumentFiles(docFiles);
                }

                data = otherFiles.concat(processedDocFiles);
            } catch (error) {
                console.error("Error uploading temporary files:", error);
            }
        }

        if (!isMultiple && isExtraction) {
            fileData = extractedData;
        } else if (isMultiple) {
            fileData = fileData.concat(data);
        } else {
            fileData = data;
        }

        isExtraction ? onFileExtracted(fileData) : onDocUpload(fileData);

        setIsDragActive(false);
        setIsPdfParsing(false);
    }

    const handlePopupConfirm = (confirm) => {
        if (confirm) {
            setIsConfirm(false);
            continueDocumentUpload(pendingData);
        } else {
            setIsConfirm(false);
            setPendingData(null);
        }
    };    

    const renderFiles = () => {
        if (Array.isArray(documents) && documents.length > 0) {
            return documents.map((d) => {
                debugger;
                const fileName = getFileName(d.filename, d.name);
                const displayFileName = fileName.length > fileNameLength ? `${fileName.slice(0, fileNameLength)}...` : fileName;
                const isZipFile = (d.mimeType || d.type) === "application/x-zip-compressed";
                return (
                    <div key={d.id || d._id || d.fileId} className='d-flex align-items-center'>
                        {isZipFile? 
                        <DownloadLink downloadUrl={d.downloadUrl || d.preview } filename={displayFileName} className = {"mr-2"}/> :
                        <a href="" className='mr-2' onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            onView(d);
                        }}>
                            {displayFileName}
                        </a>}
                        {(d.filename || d.fileName) && (
                            <FileMetaDataPopover data={d} />
                        )}
                        <span
                            onClick={(e) => {
                                if (isdisabled) return;
                                e.stopPropagation();
                                const { _id, id, fileId } = d;
                                const remainingDocs = documents.filter(doc =>
                                    (doc._id && doc._id !== _id) ||
                                    (doc.id && doc.id !== id) ||
                                    (doc.fileId && doc.fileId !== fileId)
                                );
                                onDelete(remainingDocs, d);
                                setIsDataUploaded(false);
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                    </div>
                );
            });
        } else if ((typeof documents === 'object' && Object.keys(documents).length > 0)) {
            // Handle single document object
            debugger;
            const d = documents;
            let fileName = getFileName(d.filename, d.fileName, d.name);
            fileName = overrideFileName || fileName;
            const displayFileName = fileName.length > fileNameLength ? `${fileName.slice(0, fileNameLength)}...` : fileName;

            return (
                <div key={d.id || d._id || d.fileId || d.downloadUrl} className='d-flex align-items-center'>
                    <a
                        href=""
                        className='mr-2'
                        style={!isDeleteEnable ? { marginRight: '0px' } : {}}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onView(d);
                        }}>
                        {displayFileName}
                    </a>
                    {(d.filename || d.fileName) && (
                        <FileMetaDataPopover data={d} />
                    )}
                    {isDeleteEnable && <span
                        onClick={(e) => {
                            if (isdisabled) return;
                            e.stopPropagation();
                            onDelete([], d); // Pass empty array as remainingDocs since there's only one document
                            setIsDataUploaded(false);
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </span>}
                </div>
            );
        } else 

        if (loadedFile && loadedFile.length > 0) {
            debugger;
            return (
                <div className='d-flex align-items-center'><a className='pr-2'
                    style={{ marginRight: '0px' }}
                    href=""
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onView(loadedFile[0]);
                        setIsDataUploaded(false);
                    }}
                >
                    {getFileName(loadedFile[0].filename, loadedFile[0].name)}
                </a>
                    {(loadedFile[0].filename || loadedFile[0].fileName) && (
                        <FileMetaDataPopover data={loadedFile[0]} />
                    )}
                </div>
            );
        }

        return null;
    };

    const onDragEnter = useCallback(() => {
        setIsDragActive(true);
    }, []);

    const onDragLeave = useCallback(() => {
        setIsDragActive(false);
    }, []);

    const onDrop = useCallback((data) => {
        handleDocumentUpload(data);
    }, [handleDocumentUpload]);

    return (
        <>
        <Dropzone
            className={`customDropzone ${isDragActive && !isDataUploaded ? 'active' : ''}`}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            disabledClassName="disabledDropzone"
            disabled={isdisabled}
            multiple={isMultiple}
            accept= {extensions}
            style={{ height: customHeight ? customHeight : {} }}
        >
            {((Array.isArray(documents) && documents.length > 0) || loadedFile?.length > 0 || (typeof documents === 'object' && Object.keys(documents).length > 0)) ? (
                <div className="scrollableDropzoneContent">
                    <div className="filesContainer">
                        {renderFiles()}
                    </div>
                </div>
            ) : (
                <div>
                    <div className="placeholder-font">{placeholder}</div>
                </div>
            )}
            <FullSpinner isSpinning={isPdfParsing} />
        </Dropzone>
        {supportNotes && <div className="supportText">{t("file_upload_msg.supported_file_types")}: {extensions.join(', ')}</div>}
        {isConfirm && <CommonConfirmationModal
            title={t("file_upload_msg.confirmation")}
            submitText={t("button_names.ok")}
            onClose={() => handlePopupConfirm(false)}
            onSubmit={() => handlePopupConfirm(true)}
        >
            {t("file_upload_msg.existing_file_replace_msg")}
        </CommonConfirmationModal>}
        </>
    );
};

FileUploader.propTypes = {
    fileToExtract: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
    })),
    onDocUpload: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onView: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    namelength: PropTypes.number,
    supportedExt: PropTypes.arrayOf(PropTypes.string),
    errorMsgType: PropTypes.string,
    errorMsgDuplicate: PropTypes.string,
    isdisabled: PropTypes.bool,
    isMultiple: PropTypes.bool,
    isExtraction: PropTypes.bool,
    convertToJson: PropTypes.string,
    onLoadingError: PropTypes.func,
    onFileExtracted: PropTypes.func
};

export default FileUploader;
