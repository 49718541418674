const EnumFundingRequestStatus = {
    Submitted: 0,
    MidDeskVerification:1,
    CreateFundingRequest: 2,
    AwaitingFundingOffer:3,
    FundingOffered: 4,
    FundingRejected:5,
    FundingAccepted:6,
    Repaid: 7,
    RejectedFundingRequestOffer:8,
    FundingRequestApprovedExistingContract:11
}

export default EnumFundingRequestStatus;