export const CONTINENTWITHCOUNTRY = [
    {
        "continentName": "Africa",
        "countries": [
            "Botswana",
            "Egypt",
            "Kenya",
            "Mauritius",
            "Morocco",
            "South Africa",
            "Tunisia",
            "Uganda"
        ]
    },
    {
        "continentName": "Asia Pacific",
        "countries": [
            "Australia",
            "China",
            "Hong Kong",
            "India",
            "Indonesia",
            "Japan",
            "Korea",
            "Malaysia",
            "New Zealand",
            "Singapore",
            "Sri Lanka",
            "Taiwan",
            "Thailand",
            "Vietnam"
        ]
    },
    {
        "continentName": "Europe",
        "countries": [
            "Armenia",
            "Austria",
            "Azerbaijan",
            "Belarus",
            "Belgium",
            "Bosnia Herzegovina",
            "Bulgaria",
            "Croatia",
            "Cyprus",
            "Czech Republic",
            "Denmark",
            "Estonia",
            "Finland",
            "France",
            "Georgia",
            "Germany",
            "Greece",
            "Hungary",
            "Ireland",
            "Italy",
            "Kosova",
            "Latvia",
            "Lithuania",
            "Luxembourg",
            "Malta",
            "Moldova",
            "Netherlands",
            "North Macedonia",
            "Norway",
            "Poland",
            "Portugal",
            "Romania",
            "Russia",
            "Serbia",
            "Slovakia",
            "Slovenia",
            "Spain",
            "Sweden",
            "Switzerland",
            "Turkey",
            "Ukraine",
            "United Kingdom"
        ]
    },
    {
        "continentName": "Middle East",
        "countries": [
            "Israel",
            "Lebanon",
            "Oman",
            "United Arab Emirates"
        ]
    },
    {
        "continentName": "North America",
        "countries": [
            "Canada",
            "United States"
        ]
    },
    {
        "continentName": "Latin America",
        "countries": [
            "Argentina",
            "Brazil",
            "Chile",
            "Colombia",
            "Costa Rica",
            "Dominican Republic",
            "Guatemala",
            "Honduras",
            "Mexico",
            "Peru",
            "Uruguay"
        ]
    },
];
