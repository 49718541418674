import React, { Component } from 'react';
import Popup from '../Auth/Popup';
import User from '../../../services/User';
import FullSpinner from '../Spinner/FullSpinner';
import API from '../../../services/API';

export default class ViewCslDataModal extends Component {
    user = new User();
    api = new API();
    constructor(props_) {
        super(props_);
        this.state = {
            cslData:this.props.data?this.props.data:{}
        }
    }

    
    render() {
        return (<Popup className="big modal-z-index"
            content={<>
                <div className="popup ">

                    <div>
                        <label className="popup-close-icon" onClick={e => this.props.onClose()}></label>
                        <h1>
                            Consolidated Screening List
                        </h1>
                    </div>
                    <div style={{ position: "relative", maxHeight: "calc(100% - 60px)", overflowY: "auto", overflowX: "hidden" }}>
                        <div className="row">
                            <div className="col-md-12">
                                {this.state.cslData && this.state.cslData.results && this.state.cslData.results.length > 0 && this.state.cslData.results.map(dt_ => {
                                    if (!dt_) return null;
                                    return (<>
                                        <div> <b>{dt_.source?dt_.source:""}</b></div>
                                        <table className="table table-bordered csl-table">
                                            <tr>
                                                <td><b>Source</b></td>
                                                <td>{dt_.name?dt_.name:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Entity Number</b></td>
                                                <td>{dt_.entity_number?dt_.entity_number:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Type</b></td>
                                                <td>{dt_.type?dt_.type:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Name</b></td>
                                                <td>{dt_.name?dt_.name:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Remarks</b></td>
                                                <td>{dt_.remarks?dt_.remarks:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Score</b></td>
                                                <td>{dt_.score?dt_.score:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Source List URL</b></td>
                                                <td>{dt_.source_list_url?dt_.source_list_url:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Source Information URL</b></td>
                                                <td>{dt_.source_information_url?dt_.source_information_url:""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Programs</b></td>
                                                <td>{dt_.programs && dt_.programs.length > 0 ? <ul>
                                                    {(dt_.programs.map(pr_ => {
                                                        return <li>{pr_}</li>
                                                    }))}
                                                </ul> : ""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Alternative Names</b></td>
                                                <td>{dt_.alt_names && dt_.alt_names.length > 0 ? <ul>
                                                    {(dt_.alt_names.map(pr_ => {
                                                      return  <li>{pr_}</li>
                                                    }))}
                                                </ul> : ""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Addresses</b></td>
                                                <td>{dt_.addresses && dt_.addresses.length > 0 ?
                                                    (dt_.addresses.map(ad_ => {
                                                       return <div className='d-flex flex-column'> 
                                                           {ad_.address && (
                                                               <>
                                                                   <div>{ad_.address ? ad_.address : ""}</div>
                                                               </>
                                                           )}
                                                           {ad_.city && (
                                                               <>
                                                                   <div>{ad_.city ? ad_.city : ""}</div>
                                                               </>
                                                           )}
                                                           {ad_.state && (
                                                               <>
                                                                   <div>{ad_.state ? ad_.state : ""}</div>
                                                               </>
                                                           )}
                                                           {ad_.postal_code && (
                                                               <>
                                                                   <div>{ad_.postal_code ? ad_.postal_code : ""}</div>
                                                               </>
                                                           )}
                                                           {ad_.country && (
                                                               <>
                                                                   <div>{ad_.address ? ad_.country : ""}</div><br />
                                                               </>
                                                           )}
                                                        </div>
                                                    }))
                                                    : ""}</td>
                                            </tr>
                                            <tr>
                                                <td><b>IDs</b></td>
                                                <td>{dt_.ids && dt_.ids.length > 0 ?
                                                    (dt_.ids.map(ad_ => {
                                                        return <div className='d-flex flex-column'>
                                                            {ad_.type && (
                                                                <>
                                                                    <div>{ad_.type ? ad_.type : ""}</div>
                                                                </>
                                                            )}
                                                            {ad_.country && (
                                                                <>
                                                                    <div>{ad_.country ? ad_.country : ""}</div>
                                                                </>
                                                            )}
                                                            {ad_.expiration_date && (
                                                                <>
                                                                    <div>{ad_.expiration_date ? ad_.expiration_date : ""}</div>
                                                                </>
                                                            )}
                                                            {ad_.issue_date && (
                                                                <>
                                                                    <div>{ad_.issue_date ? ad_.issue_date : ""}</div>
                                                                </>
                                                            )}
                                                            {ad_.number && (
                                                                <>
                                                                    <div>{ad_.number ? ad_.number : ""}</div><br />
                                                                </>
                                                            )}
                                                        </div>
                                                    }))
                                                    : ""}</td>
                                            </tr>
                                        </table>
                                    </>)
                                })}
                                
                               
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            handleClose={e => this.props.onClose()}
        />);
    }
}