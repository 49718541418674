import React, { Component } from 'react';
import API from '../../../services/API';
import FullSpinner from '../../common/Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import MaskedInput from "react-text-mask";
import Popup from '../../common/Auth/Popup';
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { transactionType, compareTransactionType } from '../../../configs/GlobalConfig';
import Dropzone from "react-dropzone";
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import { AddModal } from '../../common/Modals/CommonConfirmationModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import FileUploader from '../../common/FileUploader/FileUploader';

const numberMask = createNumberMask({
    prefix: "$",
    suffix: "",
    allowDecimal: true
});

export default class MakeOfferModal extends Component {
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            offerDetails: {
                advanceRate: 0,
                factoringRate: 0.0,
                factoringRatePerDay: 0,
                isRecourse: false,
                fundingOfferNote: '',
                isProceedToUpload: false
            },
            ablOfferDetails: {
                advanceRateOnEligibleAR: null,
                advanceRateOnEligibleInventory: null,
                lineOfCredit: "",
                totalFinancingFee: '',
                loan: '',
                isABLProceedToUpload: false
            },
            isLoading: false,
            fundingType: "",
            lineOfCreditOrLoanType: "",
            creditLimit: {},
            tradingPartnerList: [],
            termSheetFile: [],
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false,
            closePrompt: false,
            isManualTermSheetUpload:false,
            digitalSignature: "",
        };
        this.createFundingRequest = this.createFundingRequest.bind(this);
        this.handleOfferField = this.handleOfferField.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;
        const { creditLimit } = this.state;
        if (data) {
            let _tradingPartnerDetailsList = [];
            if (data.fundingDocuments && data.fundingDocuments.length > 0) {
                data.fundingDocuments.forEach(doc_ => {
                    if (doc_.customerDetails) {
                        let _filteredTradingPartnerList = _tradingPartnerDetailsList.filter(t_ => t_.name == doc_.customerDetails.name);
                        if (_tradingPartnerDetailsList.length == 0 || (_filteredTradingPartnerList.length == 0)) {
                            _tradingPartnerDetailsList.push(doc_.customerDetails);
                            creditLimit[doc_.customerDetails._id] = {};
                            creditLimit[doc_.customerDetails._id].creditLimitValue = 0;
                            creditLimit[doc_.customerDetails._id].fundingAmountValue = doc_.total;
                            creditLimit[doc_.customerDetails._id].tradingPartnerName = doc_.customerDetails.name;
                            creditLimit[doc_.customerDetails._id].maxCreditLimitValue = doc_.total;
                        }
                        else if (_filteredTradingPartnerList.length > 0) {
                            creditLimit[doc_.customerDetails._id].fundingAmountValue = creditLimit[doc_.customerDetails._id].fundingAmountValue + doc_.total;
                            creditLimit[doc_.customerDetails._id].maxCreditLimitValue = creditLimit[doc_.customerDetails._id].maxCreditLimitValue + doc_.total;
                        }

                    }
                });

                this.setState({ tradingPartnerList: _tradingPartnerDetailsList, creditLimit });
            }
            else if (data.isPreFundingRequest && data.tradingPartnerLists && data.tradingPartnerLists.length > 0) {
                _tradingPartnerDetailsList = data.tradingPartnerLists;
                data.tradingPartnerLists.forEach(doc_ => {
                    creditLimit[doc_._id] = {};
                    creditLimit[doc_._id].creditLimitValue = 0;
                    creditLimit[doc_._id].fundingAmountValue = 0;
                    creditLimit[doc_._id].tradingPartnerName = doc_.name;
                    creditLimit[doc_._id].maxCreditLimitValue = 0;
                })
                this.setState({ tradingPartnerList: _tradingPartnerDetailsList, creditLimit });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props.data != undefined && this.props.data != null) {
            this.setState({ fundingType: this.props.data.fundingType });
        }
    }

    handleOfferField(fieldName, value) {
        const { offerDetails } = this.state;
        const { fundingType } = this.props.data;
        offerDetails[fieldName] = value;
        this.setState({ offerDetails, closePrompt: true }, () => {
            this.enableMakeOfferBtn();
        });
    }

    handleABLOfferField = (fieldName, value) => {
        const { ablOfferDetails } = this.state;
        const { fundingType } = this.props.data;
        ablOfferDetails[fieldName] = value;
        if (ablOfferDetails.advanceRateOnEligibleAR > 0 && ablOfferDetails.advanceRateOnEligibleInventory > 0
            && (ablOfferDetails.lineOfCredit || ablOfferDetails.loan) && ablOfferDetails.totalFinancingFee) {
            ablOfferDetails.isABLProceedToUpload = true;
        }
        this.setState({ ablOfferDetails, closePrompt: true });
    };


    isNumberKey(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    onChange(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ value: e.target.value });
        }
    }


    handleBlur(e) {
        if (e.currentTarget.value === '0') e.currentTarget.value = '1';
    }

    handleKeypress(e) {
        const characterCode = e.key;
        if (characterCode === 'Backspace' || characterCode === "Tab") return;
        const characterNumber = Number(characterCode);
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
                return;
            } else if (characterNumber === 0) {
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }


    async createFundingRequest(e) {
        e.preventDefault();
        this.setState({ isLoading: true });
        const { data } = this.props;
        const { offerDetails, ablOfferDetails, creditLimit, termSheetFile } = this.state;
        let buyerslist = [];
        let suppliersList = [];
        let _isManualTermSheetUpload=data.isCreatedByImpersonateUser?true:this.state.isManualTermSheetUpload;
        const documentData = new FormData();
        if (!compareTransactionType(data.fundingType[0], transactionType.ABL)) {
            if (data.isPreFundingRequest) {
                data.tradingPartnerLists.map(item => buyerslist.push(item._id));
            }
            else {
                data.fundingDocuments.map(item => buyerslist.push(item.customerDetails._id));
            }
            let buyers = Array.from(new Set(buyerslist));
            for (let buyer of buyers) {
                documentData.append('buyerid[]', buyer);
            }

        }
        else {
            documentData.append('buyerid[]', buyerslist);
        }
        documentData.append('requesterId', data.requesterDetails._id);
        documentData.append('documentType', data.fundingType);
        documentData.append('_id', data._id);
        documentData.append('fundingReqId', data.fundingReqId);
        documentData.append("termSheetFile", termSheetFile[0]);
        documentData.append("isManualTermSheetUpload",_isManualTermSheetUpload);
        if (compareTransactionType(data.fundingType[0], transactionType.ABL)) {
            documentData.append('advanceRateOnEligibleAR', ablOfferDetails.advanceRateOnEligibleAR);
            documentData.append('advanceRateOnEligibleInventory', ablOfferDetails.advanceRateOnEligibleInventory);
            documentData.append('lineOfCredit', ablOfferDetails.lineOfCredit);
            documentData.append('loan', ablOfferDetails.loan);
            documentData.append('totalFinancingFee', ablOfferDetails.totalFinancingFee);
        }
        else {
            documentData.append('advanceRate', offerDetails.advanceRate);
            documentData.append('factoringRate', offerDetails.factoringRate);
            documentData.append('LengthOfTimeForPayment', offerDetails.factoringRatePerDay);
            documentData.append('isRecourse', offerDetails.isRecourse);
            documentData.append('fundingOfferNote', offerDetails.fundingOfferNote);
            if (compareTransactionType(data.fundingType[0], transactionType.Invoice)) {
                documentData.append('creditLimitForTradingPartner', JSON.stringify(creditLimit));
            }
        }
        await this.api.makeAnOffer(documentData);
        this.setState({
            isLoading: false
        });
    }

    handleCreditLimit = (e, data_) => {
        e.preventDefault();
        let { creditLimit } = this.state;
        if (!creditLimit[e.target.name]) {
            creditLimit[e.target.name] = {};
        }
        creditLimit[e.target.name].creditLimitValue = Number(e.target.value.replace(/[^0-9.-]+/g, ""));
        creditLimit[e.target.name].tradingPartnerName = data_.name;
        this.setState({ creditLimit }, () => {
            this.enableMakeOfferBtn();
        });
    }

    enableMakeOfferBtn = () => {
        const { offerDetails, creditLimit, termSheetFile ,digitalSignature} = this.state;
        const { data } = this.props;
        if (offerDetails.advanceRate > 0 && offerDetails.factoringRate > 0
            && offerDetails.factoringRatePerDay > 0 && offerDetails.fundingOfferNote && (data.isCreatedByImpersonateUser || (digitalSignature!=""))) {
            offerDetails.isProceedToUpload = true;
            // if (compareTransactionType(data.fundingType[0], transactionType.Invoice)) {
            //     let _isValidAllCreditLimit = true;
            //     Object.keys(creditLimit).forEach(cID_ => {
            //         if (creditLimit[cID_].creditLimitValue > 0 && creditLimit[cID_].creditLimitValue < creditLimit[cID_].maxCreditLimitValue) {
            //             _isValidAllCreditLimit = false;
            //         }
            //     })
            //     offerDetails.isProceedToUpload = _isValidAllCreditLimit;
            // }
        }
        else if (compareTransactionType(data.fundingType[0], transactionType.SupplyChain) && offerDetails.fundingOfferNote && (data.isCreatedByImpersonateUser || (digitalSignature!=""))) {
            offerDetails.isProceedToUpload = true;
        }
        else if (compareTransactionType(data.fundingType[0], transactionType.TCF) && offerDetails.factoringRate > 0 && (data.isCreatedByImpersonateUser || (digitalSignature!=""))) {
            offerDetails.isProceedToUpload = true;
        }
        else {
            offerDetails.isProceedToUpload = false;
        }
        if (termSheetFile.length == 0) {
            offerDetails.isProceedToUpload = false;
        }
        this.setState({ offerDetails });
    }

    validateCreditLimitValue = (tpId_) => {
        const { creditLimit } = this.state;
        if (creditLimit[tpId_].creditLimitValue == 0) {
            return false;
        }
        else {
            return !(creditLimit[tpId_].creditLimitValue > creditLimit[tpId_].maxCreditLimitValue);
        }
    }

    handleTermSheet = async (acceptedFiles) => {
        let fileExtention = '';
        if (acceptedFiles.length > 0 && acceptedFiles[0].name && acceptedFiles[0].name.indexOf('.') > -1) {
            fileExtention = acceptedFiles[0].name.substring(acceptedFiles[0].name.lastIndexOf(".") + 1).toUpperCase();
        }
        if (fileExtention != "PDF") {
            AddModal(this.props.t("file_upload_msg.unsupported_format_title"), this.props.t("file_upload_msg.only_pdf_file_msg"), false, false);
            return;
        } else {
            this.setState({ termSheetFile: acceptedFiles }, () => {
                this.enableMakeOfferBtn();
            });
        }
    }

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    render() {
        const {
            offerDetails: {
                advanceRate,
                factoringRate,
                factoringRatePerDay,
                isRecourse,
                fundingOfferNote,
                isProceedToUpload
            },
            ablOfferDetails: {
                advanceRateOnEligibleAR,
                advanceRateOnEligibleInventory,
                lineOfCredit,
                totalFinancingFee,
                loan,
                isABLProceedToUpload
            },
            isLoading,
            fundingType,
            isPreFundingRequest,
            closePrompt
        } = this.state;
        const { data } = this.props;

        const isABL = compareTransactionType(data.fundingType[0], transactionType.ABL);
        const isDisabled = isABL ? !isABLProceedToUpload : !isProceedToUpload;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        {this.state.isFileViewerModal && this.state.fileViewerContent &&
                            <FileViewerModal
                                onClose={() => {
                                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                                }}
                                data={this.state.fileViewerContent}
                                isFinancialDocument={this.state.isFinancialDocument}
                            ></FileViewerModal>
                        }
                        <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("status_label.funding_offer_sent")} callback={e => this.props.onClose()} />
                        <CommonPopUpModal
                            title={
                                <>
                                    {compareTransactionType(data.fundingType[0], transactionType.ABL) && (
                                        <>{this.props.t("make_offer_title_1")} # {data ? data.fundingReqId : ""} {this.props.t("make_offer_title_2")}
                                        </>
                                    )}
                                    {
                                        !compareTransactionType(data.fundingType[0], transactionType.ABL) && (
                                            <>
                                                {this.props.t("make_offer_title_3")}: # {data ? data.fundingReqId : ""}
                                            </>
                                        )
                                    }
                                </>
                            }
                            size='medium'
                            onClose={e => this.props.onClose()}
                            promptOnClose={closePrompt || !isDisabled}
                            primaryBtn={this.props.t("button_names.make_an_offer")}
                            isSubmitBtn={isDisabled}
                            onSubmit={e => this.createFundingRequest(e)}
                        >
                            <React.Fragment>
                                <div>
                                    <div className="row mx-auto">
                                        {compareTransactionType(data.fundingType[0], transactionType.TCF) && (
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label htmlFor="factoringRate">{this.props.t("funding_offer_details.monthly_rate")}</label>
                                                    <div className="input-group mb-3">
                                                        <input type="number" autoComplete="off" required name="factoringRate"
                                                            className="form-control"
                                                            min="0" max="100"
                                                            value={factoringRate} step=".01"
                                                            onChange={e =>
                                                                this.handleOfferField(
                                                                    "factoringRate",
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(!compareTransactionType(data.fundingType[0], transactionType.ABL) &&
                                            !compareTransactionType(data.fundingType[0], transactionType.TCF) &&
                                            !compareTransactionType(data.fundingType[0], transactionType.SupplyChain)) && (
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label htmlFor="advRate">{this.props.t("funding_offer_details.advance_rate")}</label>
                                                        <div className="input-group mb-3">
                                                            <input autoComplete="off" type="number" required name="advRate"
                                                                className="form-control"
                                                                min="0" max="100"
                                                                value={advanceRate}
                                                                onChange={e =>
                                                                    this.handleOfferField(
                                                                        "advanceRate",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" >%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {compareTransactionType(data.fundingType[0], transactionType.ABL) && (
                                            <>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="advRate">{this.props.t("funding_offer_details.abl_advance_rate")}</label>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className="input-group mb-3">
                                                                    <input autoComplete="off" type="text"
                                                                        onKeyDown={this.handleKeypress}
                                                                        required name="advanceRateOnEligibleAR"
                                                                        className="form-control"
                                                                        value={advanceRateOnEligibleAR}
                                                                        pattern="[0-9]"
                                                                        onChange={e =>
                                                                            this.handleABLOfferField(
                                                                                "advanceRateOnEligibleAR",
                                                                                e.target.value,

                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text" >%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="advRate">{this.props.t("funding_offer_details.abl_eligible_inventory")}</label>
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className="input-group mb-3">
                                                                    <input autoComplete="off" type="text" onKeyDown={this.handleKeypress} required name="advanceRateOnEligibleInventory"
                                                                        className="form-control"
                                                                        value={advanceRateOnEligibleInventory}
                                                                        pattern="[0-9]"
                                                                        onChange={e =>
                                                                            this.handleABLOfferField(
                                                                                "advanceRateOnEligibleInventory",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text" >%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <div className="form-group">
                                                        <div className='d-flex'>
                                                            <label>
                                                                <input type='radio'
                                                                    name="lineOfCreditOrLoanType"
                                                                    value="lineOfCredit"
                                                                    checked={this.state.lineOfCreditOrLoanType == "lineOfCredit"}
                                                                    onChange={(e) => this.setState({ lineOfCreditOrLoanType: e.target.value })} /> {this.props.t("funding_offer_details.line_of_credit")}</label>
                                                            <label style={{ paddingLeft: 10 }}>
                                                                <input type='radio'
                                                                    name="lineOfCreditOrLoanType"
                                                                    value="loan"
                                                                    checked={this.state.lineOfCreditOrLoanType == "loan"}
                                                                    onChange={(e) => this.setState({ lineOfCreditOrLoanType: e.target.value })} /> {this.props.t("funding_offer_details.loan")}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.lineOfCreditOrLoanType.length > 0 && (
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-0">
                                                            <div className='row'>
                                                                <div className='col-lg-6'>
                                                                    <div className="input-group mb-3">
                                                                        {this.state.lineOfCreditOrLoanType == "lineOfCredit" ? (<>
                                                                            <MaskedInput
                                                                                name="lineOfCredit"
                                                                                value={lineOfCredit}
                                                                                onChange={e =>
                                                                                    this.handleABLOfferField(
                                                                                        "lineOfCredit",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                                mask={numberMask}
                                                                                className="form-control"
                                                                                placeholder="$ ..."
                                                                            />
                                                                        </>) : (<>
                                                                            <MaskedInput
                                                                                name="loan"
                                                                                value={loan}
                                                                                onChange={e =>
                                                                                    this.handleABLOfferField(
                                                                                        "loan",
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                                mask={numberMask}
                                                                                className="form-control"
                                                                                placeholder="$ ..."
                                                                            />
                                                                        </>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {!compareTransactionType(data.fundingType[0], transactionType.SupplyChain) && (
                                        <div className="row mx-auto">
                                            {(!compareTransactionType(data.fundingType[0], transactionType.TCF) &&
                                                !compareTransactionType(data.fundingType[0], transactionType.ABL)) && (
                                                    <React.Fragment>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label htmlFor="factoringRate">{this.props.t("funding_offer_details.factoring_rate")}</label>
                                                                <div className="input-group mb-3">
                                                                    <input type="number" autoComplete="off" required name="factoringRate"
                                                                        className="form-control"
                                                                        min="0" max="100"
                                                                        value={factoringRate} step=".01"
                                                                        onChange={e =>
                                                                            this.handleOfferField(
                                                                                "factoringRate",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label htmlFor="factoringRatePerDay">{this.props.t("funding_offer_details.time_period")}</label>
                                                                <div className="input-group mb-3">
                                                                    <input type="number"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        min="0"
                                                                        max="735"
                                                                        required
                                                                        value={factoringRatePerDay}
                                                                        name="factoringRatePerDay"
                                                                        id="factoringRatePerDay"
                                                                        onKeyDown={this.handleKeypress}
                                                                        onChange={e => {
                                                                            if (e.target.value.length <= 3 && e.target.value <= 735) {
                                                                                this.handleOfferField(
                                                                                    "factoringRatePerDay",
                                                                                    e.target.value.replace(/[^0-9]/g, "")
                                                                                )
                                                                            }
                                                                            else {
                                                                                e.preventDefault();
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text text-lowercase">{this.props.t("common_names.days")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                        </div>
                                    )}
                                    <div className="row mx-auto" >
                                        {!compareTransactionType(data.fundingType[0], transactionType.ABL) ? (
                                            <div className="col">
                                                <div className="form-group">
                                                    <label htmlFor="fundingOfferNote">{!compareTransactionType(data.fundingType[0], transactionType.ABL) && !compareTransactionType(data.fundingType[0], transactionType.SupplyChain) ? this.props.t("funding_offer_details.other_fees") : this.props.t("funding_offer_details.offer_details_title")}</label>
                                                    <textarea
                                                        rows="3" cols="50" maxLength="1000"
                                                        value={fundingOfferNote}
                                                        name="fundingOfferNote"
                                                        id="fundingOfferNote"
                                                        className="form-control"
                                                        onChange={e =>
                                                            this.handleOfferField(
                                                                "fundingOfferNote",
                                                                e.target.value
                                                            )
                                                        }>
                                                    </textarea>
                                                    {compareTransactionType(data.fundingType[0], transactionType.Invoice) && (
                                                        <div className="mt-2"><input id="chk_isrecourse" type="checkbox" checked={isRecourse}
                                                            onChange={e =>
                                                                this.handleOfferField(
                                                                    "isRecourse",
                                                                    e.target.checked
                                                                )
                                                            }
                                                        ></input> <label for="chk_isrecourse"> {this.props.t("funding_offer_details.is_recourse")}</label></div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <label htmlFor="fundingOfferNote">{this.props.t("funding_offer_details.total_financing_fees")}</label>
                                                        <textarea
                                                            rows="3" cols="50" maxLength="1000"
                                                            value={totalFinancingFee}
                                                            name="totalFinancingFee"
                                                            id="totalFinancingFee"
                                                            className="form-control"
                                                            onChange={e =>
                                                                this.handleABLOfferField(
                                                                    "totalFinancingFee",
                                                                    e.target.value
                                                                )
                                                            }>
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {this.props && !this.props.data.isCreatedByImpersonateUser && (
                                        <div className='row flex-column mx-auto'>
                                            <div className="col">
                                                <div>{this.props.t("electronic_signature_definition")}</div>
                                                <div className="form-group mt-3">
                                                    <label className="form-label-style" htmlFor="name">{this.props.t("please_select_one_of_the_following")}:</label><br />
                                                    <div className='d-flex flex-column'>
                                                            <label>
                                                                <input type='radio'
                                                                    name="digitalSignature"
                                                                    checked={this.state.digitalSignature == "signed"}
                                                                    value="signed"
                                                                    onChange={(e)=>{
                                                                        this.setState({digitalSignature: e.target.value,isManualTermSheetUpload:false},()=>{
                                                                            this.enableMakeOfferBtn();
                                                                        });
                                                                    }} />
                                                                &nbsp;<b>{this.props.t("use_electronic_signature")}.</b > {this.props.t("term_sheet_electronic_signature_description")}
                                                            </label>
                                                            <label>
                                                                <input type='radio'
                                                                    name="digitalSignature"
                                                                    value="unsigned"
                                                                    checked={this.state.digitalSignature == "unsigned"}
                                                                    onChange={(e)=>{
                                                                        this.setState({digitalSignature: e.target.value,isManualTermSheetUpload:true},()=>{
                                                                            this.enableMakeOfferBtn();
                                                                        });
                                                                    }} />
                                                                &nbsp;<b>{this.props.t("use_manual_signature")}.</b > {this.props.t("term_sheet_manual_signature_description")}
                                                            </label>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='row mx-auto'>
                                        <div className="col">
                                            <FileUploader
                                                documents={this.state.termSheetFile && this.state.termSheetFile.length > 0 && this.state.termSheetFile[0] ? this.state.termSheetFile[0] : []}
                                                onDocUpload={this.handleTermSheet}
                                                onDelete={filteredDocs => { this.setState({ termSheetFile: [] }, () => { this.enableMakeOfferBtn(); }); }}
                                                onView={this.handleView}
                                                namelength={50}
                                                supportedExt={['.pdf']}
                                                placeholder={this.props.t("file_upload_msg.upload_term_sheet")}
                                                isdisabled={false}
                                                isMultiple={false}
                                                isExtraction={false}
                                                isDeleteEnable={true}
                                            />
                                        </div>
                                    </div>
                                    {(compareTransactionType(data.fundingType[0], transactionType.Invoice) && (process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED == "true")) && (
                                        <>
                                            <label className="form-label-style ml-3" htmlFor="creditLimit">{this.props.t("credit_limit_for_trading_partners")}</label>
                                            {this.state.tradingPartnerList.map((t_, index) => {
                                                return (
                                                    <div className="row mb-3 mx-auto" key={index}>
                                                        <div className="col-md-5">{t_.name}</div>
                                                        <div className="col-md-7">
                                                            <div className='flex-column'>
                                                                <MaskedInput
                                                                    name={t_._id}
                                                                    onChange={e => this.handleCreditLimit(e, t_)}
                                                                    mask={numberMask}
                                                                    className="form-control"
                                                                    placeholder="$ ..."
                                                                />
                                                            </div>
                                                            {this.validateCreditLimitValue(t_._id) && (
                                                                <div className="formErrors">
                                                                   {this.props.t("credit_limit_exceed_msg")}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                            </React.Fragment>
                        </CommonPopUpModal>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        );
    }
}
