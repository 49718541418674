import React, { Component } from 'react'
import FullSpinner from '../../common/Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import { ShortText } from '../../../services/Utilities';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SignaturePopver from '../../common/PopOver/SignaturePopver';
import Dropzone from 'react-dropzone';
import {ToastContainer,toast} from 'react-toastify';
import User from '../../../services/User';
import BusinessInformationMissingModal from '../../common/Modals/BusinessInformationMissingModal';
import {localDateFormat} from "../../../services/Utilities";
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import { withTranslation } from 'react-i18next';


 class SigningStatusModal extends Component {
    api = new API();
    user =new User();

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isTokenGenrated:false,
            isAgreeChecked:false,
            eSignature:"",
            isSendingVCode:false,
            eVerificationDetails:{},
            isVerificationTokenExpired:false,
            isEnableSignBtn:false,
            isSignatureAdded:false,
            signedAgreementDetails:null,
            minutes: 5,
            seconds: 0,
            supplierWalletDetails:null,
            isBusinessInfoMissing:false,
            supplierDetails:null,
            isFinancialDocument:false,
            isFileViewerModal:false,
            fileViewerContent:null,
            
        };
    }

    componentDidMount(){
        if(this.props.data &&  this.props.data.signedAggrements || this.user.currentUserRole()=="funder" || this.props.data.termSheetFile){
            this.setState({isAgreeChecked:true});
        }
        if(this.user.currentUserRole()=="supplier" && this.props.data && this.props.data.requesterDetails && (!this.props.data.requesterDetails.userName || !this.props.data.requesterDetails.title)){
            this.setState({isBusinessInfoMissing:true});
        }
    }

    async notify(message) {
        toast.info(message)
    }

    getProfile=async()=>{
        const _supplierDetails=await this.api.getV2Profile();
        this.setState({supplierDetails:_supplierDetails.user});
    }

    submitAgreement = async (e) => {
        e.preventDefault();
         this.setState({ isLoading: true });
        if (!this.state.isVerificationTokenExpired) {
            const documentData = new FormData();
            documentData.append("fundingReqId", this.props.data.fundingReqId);
            documentData.append("fundingContractId", this.props.data._id);
            documentData.append("isDigitalSignature", true);
            clearInterval(this._countDownTimer);
            const _signedAgreementDetails = await this.api.uploadSignedAgreementToContract(documentData);
            this.setState({ isSignatureAdded: true, signedAgreementDetails: _signedAgreementDetails && _signedAgreementDetails.payload ? _signedAgreementDetails.payload : null ,isLoading: false});
            const _supplierWalletDetails = await this.api.getWalletInfo();
            if (!_supplierWalletDetails.msg) {
                this.setState({ supplierWalletDetails: _supplierWalletDetails });
            }
        } else {
            this.notify(this.props.t("invalid_verification_code_msg"));
             this.setState({ isLoading: false });
        }
    }

    handleChange=(event)=>{
        const name = event.target.name;
        const value = event.target.value;
        if(name=="eSignature"){
            this.setState({eSignature:value},async()=>{
                const isSignBtnEnabled=await this.verifiyECodeDetails();
                if(isSignBtnEnabled){
                    this.setState({isEnableSignBtn:true});
                }
                else{
                    this.setState({isEnableSignBtn:false});
                }
            });
        }
    }
    onAgreeChange=async (event)=>{
        const isAgreeChecked_=event.target.checked;
        this.setState({isAgreeChecked:isAgreeChecked_});
        if(isAgreeChecked_){
            await this.getVerificationCode(false);
        }
    }

    getVerificationCode=async (isResend_)=>{
        const {data}=this.props;
        this.setState({isSendingVCode:true});
        const _codeDetails={
            isResend:isResend_,
            fundingReqId:data.fundingReqId,
            fundingContractId:data.contractId,
            _id:this.state.eVerificationDetails && this.state.eVerificationDetails._id?this.state.eVerificationDetails._id:""
        }
        const codeStatus=await this.api.getVerificationCode(_codeDetails);
        if(codeStatus && codeStatus.verificationCode){
            this.notify(this.props.t("verification_code_send_msg"));
            this.setState({isTokenGenrated:true,eVerificationDetails:codeStatus,minutes:5,seconds:0,isVerificationTokenExpired:false});
            this._countDownTimer = setInterval(async () => {
                const { seconds, minutes } = this.state

                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this._countDownTimer);
                        this.setState({isVerificationTokenExpired:true});
                        this.notify(this.props.t("expired_verification_code_msg"));
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                    }
                } 
            }, 1000);
        }
        this.setState({isSendingVCode:false});
    }

    verifiyECodeDetails=async()=>{
        const {eVerificationDetails}=this.state;
        const _eSignatureValue=this.state.eSignature;
        let isVerified=false;
        if(eVerificationDetails.verificationCode==_eSignatureValue){
            return true;
        }
        else{
            return false;
        }
    }


    render() {
        let { data } = this.props;
        let {
            isLoading,
            signedAgreementDetails,
            supplierDetails,
            requesterDetails
        } = this.state;
        supplierDetails=supplierDetails?supplierDetails:data.requesterDetails;
        data.supplier=data && data.supplier &&  data.supplier.userName?data.supplier:data.requesterDetails;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <ToastContainer />
                        <FullSpinner
                            isSpinning={isLoading}
                            confirmationLabel=""
                        />
                        <FullSpinner
                            isSpinning={this.state.isSendingVCode}
                            confirmationLabel=""
                        />
                        {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                            onClose={() => {
                                this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                            }}
                            data={this.state.fileViewerContent}
                            isFinancialDocument={this.state.isFinancialDocument}
                        ></FileViewerModal>}
                        <CommonPopUpModal
                            title={<>
                                {!this.props.isTermSheetAvailable ? `${this.props.t("funding_offer_details.signing_status")}:` : ""}
                                #{data ? data.fundingContractId : ""}
                            </>
                            }
                            onClose={e => this.props.onClose()}
                            promptOnClose={false}
                            style={{zIndex:"99999"}}
                        >
                            <div className="flex-column">
                                {this.state.isBusinessInfoMissing && (
                                    <BusinessInformationMissingModal
                                        data={data.supplier}
                                        onClose={(d) => {
                                            this.setState({ isBusinessInfoMissing: false });
                                            if (d) {
                                                this.props.onClose();
                                            }
                                            else{
                                                this.getProfile();
                                            }
                                        }}
                                    />)}
                                {!this.props.isTermSheetAvailable && (
                                    <div className="row mx-0 justify-content-center">
                                        <div className=' text-center mb-3 w-100'>
                                            <Dropzone
                                                disabled={true}
                                                style={{ height: '12vh', borderWidth: 2, borderColor: 'grey', borderStyle: 'dashed' }}
                                                inputProps={{
                                                    accept: "application/pdf"
                                                }}
                                                multiple={false}
                                            >
                                                <div className="p-3">
                                                    {data.fundingAggrement && data.fundingAggrement._id ? (<>
                                                        <a className="anchorstyle" onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                isFileViewerModal: true,
                                                                isFinancialDocument: false,
                                                                fileViewerContent: data.fundingAggrement
                                                            })
                                                        }} href={""} > {this.props.t("agreement")}</a>
                                                    </>) : this.props.t("agreement_copy_not_upload")}

                                                </div>
                                            </Dropzone>
                                        </div>

                                    </div>
                                )}
                                <div className="row mt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                    <div className='col-3'>{this.props.t("grid_view_header_labels.company_name")}</div>
                                    <div className='col-4'>{this.props.t("grid_view_header_labels.signer")}</div>
                                    <div className='col-3'>{this.props.t("grid_view_header_labels.signed_verified")}</div>
                                    <div className='col-2'>{this.props.t("grid_view_header_labels.sign_on")}</div>
                                    {/* <div className='col-3'>Signed At</div> */}
                                </div>
                                <div className="row mt-2">
                                    <div className='col-3' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{data.funder && data.funder.name?data.funder.name:""}</div>
                                    <div className='col-4' >
                                        <div className='d-flex flex-column'>
                                            <div className='Signer-font'>
                                                {data.funder && data.funder.userName ? data.funder.userName : ""}
                                            </div>
                                            <div>
                                                {data.funder && data.funder.title ? data.funder.title : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className='d-flex'>
                                            <SignaturePopver
                                                signature={data.funderWalletInfo && data.funderWalletInfo.funderSignature ? data.funderWalletInfo.funderSignature : this.props.t("grid_view_header_labels.not_signed")}
                                                userName={data.funder && data.funder.userName ? data.funder.userName : ""}
                                                createdAt={this.props.isTermSheetAvailable?data.createdAt:data && data.fundingAgreementAt ? data.fundingAgreementAt : new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        {localDateFormat(this.props.isTermSheetAvailable?new Date(data.createdAt):data && data.fundingAgreementAt?new Date(data.fundingAgreementAt):new Date())}
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className='col-3'></div>
                                    <div className='col-9'>
                                        <label>
                                            <input type='checkbox'
                                                name="agreeCondition"
                                                defaultChecked={true}
                                                disabled={true} />
                                            &nbsp;I hereby agree to do business electronically. The signature is electronically recorded and associated with this agreement ({data && data.fundingContractId ? data.fundingContractId : "FC-ID"}), and is designed to be compliant as an "electronic signature" under applicable law.
                                        </label>
                                    </div>
                                </div> */}
                                <div className="row mt-4">
                                    <div className='col-3' style={{ color: "#5F9FBC", fontWeight: 600, fontSize: 14 }}>{data.supplier && data.supplier.name?data.supplier.name:""}</div>
                                    <div className='col-4' >
                                        <div className='d-flex flex-column'>
                                            <div className={((data.supplierWalletInfo && data.supplierWalletInfo.supplierSignature && (data.signedAggrements || data.termSheetFile)) ||(this.state.supplierWalletDetails && this.state.supplierWalletDetails.supplierSignature))
                                            ?"Signer-font":""}>
                                                {data.supplier && data.supplier.userName ? data.supplier.userName :
                                                    (supplierDetails && supplierDetails.userName) ? supplierDetails.userName : ""}
                                            </div>
                                            <div>
                                                {data.supplier && data.supplier.title ? data.supplier.title :
                                                (supplierDetails && supplierDetails.title)?supplierDetails.title: ""}
                                            </div>
                                        </div>
                                    </div>
                                    {(data.supplierWalletInfo && data.supplierWalletInfo.supplierSignature && (data.signedAggrements || data.termSheetFile)) ?
                                        (<>
                                            <div className='col-3'>
                                                <div className='d-flex'>
                                                    <SignaturePopver
                                                        userName={data.supplier && data.supplier.userName ? data.supplier.userName :
                                                            (supplierDetails && supplierDetails.userName) ? supplierDetails.userName : ""}
                                                        signature={data.supplierWalletInfo && data.supplierWalletInfo.supplierSignature ? data.supplierWalletInfo.supplierSignature :this.props.t("grid_view_header_labels.not_signed")}
                                                        createdAt={this.props.isTermSheetAvailable?data.acceptedOfferAt:data && data.signedAggrementAt ? data.signedAggrementAt : new Date()}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-2'>
                                                {localDateFormat(this.props.isTermSheetAvailable?new Date(data.acceptedOfferAt):data && data.signedAggrementAt ? new Date(data.signedAggrementAt) : new Date())}
                                            </div>
                                        </>) :
                                        (<>
                                            {((!this.state.isSignatureAdded && data && data.isDigitalSignature && data.isDigitalSignature == "true" && (this.state.isTokenGenrated && this.state.isAgreeChecked))) ? (
                                                <React.Fragment>
                                                    <div className='col-5'>
                                                        <div className='row mx-0'>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="eSignature"
                                                                    onChange={this.handleChange}
                                                                    placeholder={this.props.t("one_time_password")}
                                                                />
                                                            </div>
                                                            <div className="form-group pl-3">
                                                                <button
                                                                    className="btn btn-primary"
                                                                    disabled={!this.state.isEnableSignBtn}
                                                                    style={{ width: "100%", height: "95%" }}
                                                                    onClick={(e) => {
                                                                        this.submitAgreement(e);
                                                                    }}
                                                                >{this.props.t("button_names.sign_now")}</button>
                                                            </div>
                                                            <div className='text-center pl-3'>
                                                                {this.state.isVerificationTokenExpired ?
                                                                    <a className="nav-link active p-0" style={{ cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.getVerificationCode(true);
                                                                        }}
                                                                    ><h6 className='mt-2'>{this.props.t("button_names.resend_code")}</h6></a>
                                                                    : <h6 className='mt-2' style={{ color: '#999999' }}>{this.props.t("resend_code_in")} {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</h6>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ) : (<>
                                                    {this.state.isSignatureAdded ? (
                                                        <>
                                                            <div className='col-3'>
                                                                <div className='d-flex'>
                                                                    <SignaturePopver
                                                                        userName={data.supplier && data.supplier.userName ? data.supplier.userName :
                                                                            (supplierDetails && supplierDetails.userName) ? supplierDetails.userName : ""}
                                                                        signature={this.state.supplierWalletDetails && this.state.supplierWalletDetails.supplierSignature ? this.state.supplierWalletDetails.supplierSignature : this.props.t("grid_view_header_labels.not_signed")}
                                                                        createdAt={this.state.signedAgreementDetails && this.state.signedAgreementDetails.signedAgreementAt ? this.state.signedAgreementDetails.signedAgreementAt : new Date()}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                {localDateFormat(this.state.signedAgreementDetails && this.state.signedAgreementDetails.signedAgreementAt ? new Date(this.state.signedAgreementDetails.signedAgreementAt) : new Date())}
                                                            </div>
                                                        </>
                                                    ) : (<>
                                                        <div className='col-3'>
                                                            <svg height="30" width="30"><circle cx="10" cy="15" r="9" stroke="gray" stroke-width="2" fill="white"></circle></svg>
                                                            <b>{this.props.t("grid_view_header_labels.not_signed")}</b>
                                                        </div>
                                                        <div className='col-3'>
                                                        </div>
                                                    </>)}
                                            </>)}
                                        </>)
                                    }
                                </div>
                                {!this.state.isAgreeChecked && (
                                    <div className="row">
                                        <div className='col-3'></div>
                                        <div className='col-9'>
                                            <label>
                                                <input type='checkbox'
                                                    name="agreeCondition"
                                                    defaultChecked={data.supplierWalletInfo && data.supplierWalletInfo.supplierSignature && data.signedAggrements ? true : false}
                                                    disabled={this.state.isAgreeChecked}
                                                    onChange={this.onAgreeChange} />
                                                &nbsp;{this.props.t("fundingrequests_page.funding_agreement_content.agreement_check_msg_1")} ({data && data.fundingContractId ? data.fundingContractId : "FC-ID"}), {this.props.t("fundingrequests_page.funding_agreement_content.agreement_check_msg_2")}
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </CommonPopUpModal>
                        <div className="modal-backdrop fade show"></div>
                    </React.Fragment>)}
            </AppState.Consumer>
        )
    }
}

export default withTranslation() (SigningStatusModal);