import React, { Component } from 'react';
import FullSpinner from '../Spinner/FullSpinner';
import Popup from '../Auth/Popup';
import Dropzone from 'react-dropzone';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileViewerModal from '../FileViewerModal/FileViewerModal';
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import FileUploader from '../FileUploader/FileUploader';
import { withTranslation } from 'react-i18next';

class FileAttachmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isMessageStatement: false,
            attachmentStatement: [],
            isFileViewerModal:false,
            isFinancialDocument:false,
            fileViewerContent:null
        }
    }

    componentDidMount() {
        if (this.props.data && this.props.data.length > 0) {
            this.setState({ attachmentStatement: this.props.data })
        }
    }

    onDropAttachmentStatement =async (type, document) => {
        this.setState({
            attachmentStatement: document,
            isMessageStatement: true,
            isShortName: true,
        });
    }

    handleDelete = (filteredDocs, selectedDoc) => {
        console.log(filteredDocs)
        this.setState(prevState => ({
            attachmentStatement: filteredDocs,
            isMessageStatement: true
        }));
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    render() {
        const {
            isLoading,
            isMessageStatement
        } = this.state;

        return (
            <React.Fragment> 
                <React.Fragment>
                    {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                        onClose={() => {
                            this.setState({ isFileViewerModal: false, fileViewerContent: null });
                        }}
                        data={this.state.fileViewerContent}
                        isFinancialDocument={false}
                    ></FileViewerModal>}
                    <CommonPopUpModal
                        size="small-medium"
                        title={this.props && this.props.headerTitle ? this.props.headerTitle : this.props.t("file_attachments")}
                        promptOnClose={this.state.attachmentStatement.length && isMessageStatement}
                        onClose={e => this.props.onClose()}
                        primaryBtn={this.props && this.props.buttonText ? this.props.buttonText : this.props.t("button_names.save")}
                        onSubmit={() => this.props.onSubmitFiles(this.state.attachmentStatement, this.props.onClose())}
                        isSubmitBtn={this.props && this.props.buttonText ?!this.state.attachmentStatement.length:false}
                        >
                        <>
                            <div>
                                <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("attachment_links_uploaded")} callback={e => this.props.onClose()} />
                                <div className="d-flex flex-column">
                                    <FileUploader
                                        documents={this.state.attachmentStatement.length ? this.state.attachmentStatement : []}
                                        onDocUpload={(newDocs) => this.onDropAttachmentStatement("attachment_Links", newDocs)}
                                        onDelete={this.handleDelete}
                                        onView={this.handleView}
                                        namelength={50}
                                        supportedExt={this.props.fileExtensionAllowMsg && this.props.fileExtensionAllowMsg.includes('CSV')
                                        ? ['.pdf', '.csv', '.xls', '.xlsx', '.jpg', '.jpeg', '.png']
                                        : ['.pdf', '.jpg', '.jpeg', '.png']}
                                        placeholder={this.props.t("file_upload_msg.common_upload_msg")}
                                        isdisabled={this.props.isReadOnly}
                                        isMultiple={true}
                                        isExtraction={false}
                                        supportNotes={true}
                                    />
                                </div>
                            </div>
                        </>
                    </CommonPopUpModal>
                </React.Fragment>      
            </React.Fragment>
        )
    }
}

export default withTranslation() (FileAttachmentModal);