import React, { Component } from 'react'

import AppState from '../../../../contexts/AppState/AppState';
import AppProvider from '../../../../contexts/AppState/AppStateProvider';
import API from '../../../../services/API';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faExclamationTriangle, faSortUp, faSortDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { TaxCreditModal } from './TaxCreditModal';
import { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import CreateFundRequestTCFModal from './CreateFundRequestTCFModal';
import FullSpinner from "../../../common/Spinner/FullSpinner";
import DataGrid from '../../../common/DataGrid/DataGrid';
import DataGridFilter from '../../../common/DataGrid/DataGridFilter';
import { dollarFormatter, dateFormatter } from '../../../../services/Utilities';
import { NavLink } from 'react-router-dom';

export default class TaxcreditList extends Component {
    api = new API();

    _columnsSpecification = [
        {
            key: "customerName",
            name: "Company Name"
        },
        {
            key: "tin",
            name: "TIN"
        },
        {
            key: "documentId",
            name: "Tax Year",
            eventType: "view"
        },
        {
            key: "total",
            name: "Tax credit to be pre-funded",
            textAlignment: "right",
            contentProvider: (datum_) => {
                return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>
            }
        },
        {
            key: "documentDate",
            name: "Uploaded Date",
            contentProvider: (datum_) => {
                return dateFormatter.format(new Date(datum_.documentDate));
            }
        },
        {
            key: "status",
            name: "Status",
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{datum_.statusText}</div>;
                    case 1:
                        return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{datum_.statusText}</div>;
                    case 2:
                        return <div className="progress-text-color">{datum_.statusText}</div>;
                    case 3:
                        return <div className="archived-text-color">{datum_.statusText}</div>;
                    case 99:
                        return <div className="progress-text-color">{datum_.statusText}</div>;
                    case 100:
                        return <div className="archived-text-color">{datum_.statusText}</div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
        {
            key: "action",
            name: "Type/Request",
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                    case 1:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>Open</div>;
                    case 2:
                        return <div><button className="submit-invoice-button big"
                        >Create Funding Request</button></div>;
                    case 3:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>Archived</div>;
                    case 99:
                        return <div><NavLink className="anchorstyle" style={{ color: "#5F9FBC"}} to="/fundingrequest">{datum_.fundingReqId}</NavLink></div>;
                    case 100:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>Archived</div>;
                    default:
                        return "Unknown";
                }
            },
            eventType: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return "";
                    case 1:
                        return "";
                    case 2:
                        return "SubmitTCF";
                    case 3:
                    case 99:
                    case 100:
                        return "";
                    default:
                        return "";
                }
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isUploadPopupOpen: false,
            isViewPopupOpen: false,
            isShowSAFRModel: false,
            isCreateFundingRequestModalOpen: false,
            isOpenMessageModal: false,
            selectedTCF: null,
            isLoading: false,
            filtersApplied: {
                archived: true
            },
            tcf: [],
            allTcf: [],
            isResetFilterEnabled:false
        };

    }

    alreadySubmittedModal() {
        AddModal("Already Submitted", "You have already submitted the tax credit financing request for this year.", null, false);
    }

    loadTCFs() {
        this.api.docGetTCF().then(tcfs_ => {
            tcfs_ = tcfs_.map(t => {
                if (t.status == 0) {
                    t.statusText = "Submitted";
                }
                else if (t.status == 1) {
                    t.statusText = "Bussiness Verification";
                }
                else if (t.status == 2) {
                    t.statusText = "Pre Qualification Approved";
                }
                else if (t.status == 3) {
                    t.statusText = "Pre Qualification Rejected";
                }
                else if (t.status == 99) {
                    t.statusText = "In Funding";
                }
                else if (t.status == 100) {
                    t.statusText = "Archived";
                }

                if (t.middeskresponse) {
                    t.customerName = t.middeskresponse.name;
                    t.tin = t.middeskresponse.tin.tin;
                }

                return t;
            });
            this.setState({ allTcf: tcfs_ }, () => {
                this.onFilterChange();
            });
        });
    }

    componentDidMount() {
        this.loadTCFs();
    }

    componentDidUpdate(prevProps_, prevState) {
        if (prevProps_.isUpload !== this.props.isUpload) {
            this.setState({ isUploadPopupOpen: this.props.isUpload });
        }
        console.log(this.state.isUploadPopupOpen);
    }

    render() {
        const {
            isUploadPopupOpen,
            tcf,
            isLoading,
        } = this.state;
        return (
            <AppProvider>
                <AppState.Consumer>
                    {appState => (
                        <React.Fragment>
                            <FullSpinner
                                isSpinning={isLoading}
                                confirmationLabel="Tax Credit Pre_Funding is Created"
                                callback={appState.state.loadTCFs}
                            />
                            <div className="row mt-2">
                                <div className="col">
                                    {/* <button
                                        className="btn btn-primary"
                                        style={{ width: 150 }}
                                        onClick={() => {
                                            let alreadyUploaded = false;
                                            for (let tcf of [].concat(this.state.tcf)) {
                                                if (tcf.tcf.taxyear == new Date().getFullYear() - 1 && tcf.status != 3) {
                                                    alreadyUploaded = true;
                                                    break;
                                                }
                                            }
                                            if (alreadyUploaded) {
                                                this.alreadySubmittedModal();
                                            }
                                            else {
                                                this.setState({ isUploadPopupOpen: true });
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faFileUpload}
                                        />Upload</button> */}
                                </div>
                                {
                                    (isUploadPopupOpen || this.state.isViewPopupOpen || this.state.isCreateFundingRequestModalOpen) && (
                                        <div className="terms-checkbox-container">
                                            {isUploadPopupOpen && <TaxCreditModal
                                                isReadOnly={false}
                                                onClose={() => {
                                                    this.setState({ isUploadPopupOpen: false });
                                                    this.props.upload();
                                                }}
                                                onSubmit={(formData) => {
                                                    this.setState({
                                                        isUploadPopupOpen: false,
                                                        isLoading: true
                                                    });
                                                    this.api.docSubmitTCF(formData).then(r => {
                                                        if (r.error && r.message == "ALREADY_SUBMITTED") {
                                                            this.alreadySubmittedModal();
                                                            this.setState({
                                                                isUploadPopupOpen: false,
                                                                isLoading: false
                                                            });
                                                        }
                                                        else {
                                                            this.setState({
                                                                isUploadPopupOpen: false,
                                                                isLoading: false
                                                            }, () => {
                                                                this.loadTCFs();
                                                            });
                                                        }
                                                    });
                                                }} />
                                            }
                                            {this.state.isViewPopupOpen && this.state.selectedTCF && <TaxCreditModal
                                                isReadOnly={true}
                                                onClose={() => { this.setState({ isViewPopupOpen: false, selectedTCF: null }) }}
                                                data={this.state.selectedTCF}
                                            />}
                                            {this.state.isCreateFundingRequestModalOpen && this.state.selectedTCF && <CreateFundRequestTCFModal
                                                data={this.state.selectedTCF}
                                                onClose={() => this.setState({ isCreateFundingRequestModalOpen: false, selectedTCF: null })}
                                                onSubmit={(formData) => {
                                                    let preFudingAmount = 0;
                                                    for (let [key, value] of formData) {
                                                        if (key === "preFudingAmount") {
                                                            preFudingAmount = `${value}`;
                                                        }
                                                    }

                                                    const CFRData = {
                                                        "_id": this.state.selectedTCF._id,
                                                        "supplier": this.state.selectedTCF.supplier,
                                                        "total": preFudingAmount
                                                    }
                                                    //update TCF document
                                                    this.api.docTCFUpdate(formData).then(r => {
                                                        const additionalInfo={
                                                            estimatedFinancingVolume:0,
                                                            funds:"",
                                                            frequency:""
                                                        }
                                                        this.api.commonCreateFundingRequest(CFRData,additionalInfo).then(r => {
                                                            this.setState({
                                                                isCreateFundingRequestModalOpen: false
                                                            })
                                                            this.loadTCFs();
                                                        });
                                                    });
                                                }}
                                            />}
                                        </div>
                                    )
                                }
                              
                            </div>
                            {
                                tcf &&
                                <DataGrid
                                    title="Tax Credit Pre-Funding"
                                    data={tcf}
                                    columns={this._columnsSpecification}
                                    isNoPagination={true}
                                    isRowsNonSelectable={true}
                                    isGridSearch={true}
                                    isNoMoreLink={true}
                                    appliedFilter={this.state.filtersApplied}
                                    resetFilters={() => this._resetFilter()}
                                    searchText={(text_) => {
                                        this.state.filtersApplied.textFilter = text_;
                                        this.setState({
                                            filtersApplied: this.state.filtersApplied
                                        }, () => this.onFilterChange());
                                    }}
                                    filterComponent={
                                        <React.Fragment>
                                            <DataGridFilter
                                                label="Year"
                                                type="options"
                                                options={[
                                                    {
                                                        text: "2019",
                                                        value: "2019"
                                                    },
                                                    {
                                                        text: "2020",
                                                        value: "2020"
                                                    },
                                                    {
                                                        text: "2021",
                                                        value: "2021"
                                                    }
                                                ]}
                                                value={this.state.filtersApplied.year}
                                                change={(filter_) => {
                                                    this.state.filtersApplied.year = filter_;
                                                    this.setState({
                                                        filtersApplied: this.state.filtersApplied
                                                    }, () => this.onFilterChange());
                                                }}
                                            />
                                            <DataGridFilter
                                                label="Show Archived"
                                                type="boolean"
                                                value={this.state.filtersApplied.archived}
                                                change={(filter_) => {
                                                    this.state.filtersApplied.archived = filter_;
                                                    this.setState({
                                                        filtersApplied: this.state.filtersApplied
                                                    }, () => this.onFilterChange());
                                                }}
                                            />
                                            <DataGridFilter
                                                label="Status"
                                                type="options"
                                                options={
                                                    [
                                                        { value: 0, text: "Submitted" },
                                                        { value: 1, text: "Bussiness Verification" },
                                                        { value: 2, text: "Pre Qualification Approved" },
                                                        { value: 3, text: "Pre Qualification Rejected" },
                                                        { value: 99, text: "In Funding" },
                                                        { value: 100, text: "Archived" }
                                                    ]
                                                }
                                                value={this.state.filtersApplied.status}
                                                change={(filter_) => {
                                                    this.state.filtersApplied.status = filter_;
                                                    this.setState({
                                                        filtersApplied: this.state.filtersApplied
                                                    }, () => this.onFilterChange());
                                                }}
                                            />
                                        </React.Fragment>
                                    }
                                    isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                                    handleTableCellClick={(dataRow, type, index) => {
                                        if (type == "view" || type == "open") {
                                            this.setState({
                                                isViewPopupOpen: true,
                                                selectedTCF: dataRow,
                                            });
                                        }
                                        else if (type == "SubmitTCF") {
                                            this.setState({
                                                isCreateFundingRequestModalOpen: true,
                                                selectedTCF: dataRow,
                                            });
                                        }
                                    }}
                                    isResetFilterEnabled={this.state.isResetFilterEnabled}
                                    emptydataLabel="No Tax Credit Found"
                                />
                            }
                        </React.Fragment>
                    )}
                </AppState.Consumer>
            </AppProvider>
        )
    }

    _resetFilter = () => {
        this.state.filtersApplied.textFilter = "";
        this.state.filtersApplied.archived = true;
        this.state.filtersApplied.year = [];
        this.state.filtersApplied.status = [];

        this.setState({
            filtersApplied: this.state.filtersApplied
        }, () => this.onFilterChange());
    }

    onFilterChange() {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        let filteredTcfs = this.state.allTcf.filter(tcf_ => {
            if (!this.state.filtersApplied.archived) {
                if (tcf_.status == 100 || tcf_.status == 3) {
                    return false;
                }
            }

            if (this.state.filtersApplied.year && this.state.filtersApplied.year.length) {
                if (tcf_.documentId != this.state.filtersApplied.year[0].text) {
                    return false;
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (tcf_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                }
            }

            if (filteredText) {
                if (!(tcf_.documentId.toLowerCase().indexOf(filteredText) >= 0 ||
                    tcf_.statusText.toLowerCase().indexOf(filteredText) >= 0)) {
                    return false;
                }
            }

            return true;
        });

        this.setState({
            tcf: filteredTcfs
        },() => this._hasResetFilterEnabled());
    }

    _hasResetFilterEnabled=()=>{
        if(( this.state.filtersApplied.textFilter && this.state.filtersApplied.textFilter!="") || !this.state.filtersApplied.archived ||this.state.filtersApplied.year.length>0 
        || this.state.filtersApplied.status.length>0){
            this.setState({isResetFilterEnabled:true});
        }
        else{
            this.setState({isResetFilterEnabled:false});
        }
    }
}