//Author, Rajesh Rajendran
import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';

const DateInput = ({ label, name, value, onChange, format, disabled, required }) => {
    return (
        <div >
            <label htmlFor={name} className={`form-label-style ${required ? 'required' : ''}`}>{label}</label>
            <DatePicker
                className="form-control"
                format={format? format : "MM/dd/y"}
                value={value}
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    );
};

DateInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

DateInput.defaultProps = {
    disabled: false,
    required: false
};

export default DateInput;
