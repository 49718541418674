export default [
  'Agriculture',
  'Apparel',
  'Computer Hardware',
  'Computer Software',
  'Construction',
  'Consulting',
  'Distribution',
  'Energy-Related',
  'Food',
  'Import Export',
  'Industrial Fabrication',
  'Legal',
  'Manufacturing',
  'Medical',
  'Retail',
  'Service Industry',
  'Telecommunication',
  'Textile',
  'Transportation',
  'Other'
];
