import React, { Component } from 'react';
import {
  withRouter,
} from 'react-router-dom';
import '../List.css';
import {localDateFormat} from "../../../../services/Utilities";

import AppState from '../../../../contexts/AppState/AppState';
import API from '../../../../services/API';

const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

class MarketplaceInvoiceListItem extends Component {
  api = new API();

  constructor(props) {
    super(props);
    this.state = {
      customersDocs:{}
    };
  }
  componentDidMount(){
    const {Record:{invoices}}=this.props;
    const customersDocs=invoices.reduce((cus,docs)=>{
      let customerName=docs.buyerId;
      if(!cus[customerName]){
          cus[customerName]=[];
      }
      cus[customerName].push(docs);
      return cus;
  },{});
  this.setState({customersDocs:customersDocs});
  }

  render() {
    const { index, _id, Record: { amount, buyerRevenue, supplierIndustryStr, paymentTerm, netStr,fundingType,pos } } = this.props;
    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <div className="card my-2">
                  <div className="card-body">
                    <div className="row d-flex align-self-center">
                      <div className="col-1  text-muted">
                        #{index + 1}
                      </div>
                      <div className="col-3  text-muted">
                        <strong>{dollarFormatter.format(amount)}</strong>
                      </div>
                      <div className="col-3  text-muted">
                        Supplier Industry: {supplierIndustryStr}
                      </div>
                      
                      <div className="col-5  text-left">
                        {fundingType == "Invoice" && (
                          <React.Fragment>
                            {this.state.customersDocs && Object.keys(this.state.customersDocs).length > 0 && (
                              <React.Fragment>
                                {Object.keys(this.state.customersDocs).map((key, i) => (
                                  <React.Fragment>
                                    <strong>Buyer {" "}{i + 1}'s Revenue  :</strong>{" "}  <strong><span className="text-muted">{dollarFormatter.format(this.state.customersDocs[key][0].buyerRevenue.replaceAll(',', ''))}</span></strong>
                                    <br />
                                    <div className="pt-1">
                                      {this.state.customersDocs[key].map((invoice, n) => (
                                        <div className="pl-5">
                                          <strong>Invoice{" "}{n + 1}:</strong><span className="text-uppercase">{" "} ({invoice.netStr})</span> <strong> Due Date:</strong> <span className="mr-2">{" "}{localDateFormat(invoice.paymentTerm)}</span>
                                        </div>
                                      ))}
                                    </div>
                                  </React.Fragment>
                                )
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                        {fundingType == "PO" && (
                          <React.Fragment>
                            {pos && pos.length > 0 && (
                              <React.Fragment>
                                {pos.map((key, i) => (
                                  <React.Fragment>
                                      <strong> Purchase Order {" "}{i + 1}'s  Date:</strong> <span className="mr-2">{" "}{localDateFormat(pos[0].poDate)}</span>
                                    <br />
                                  </React.Fragment>
                                )
                                )}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default withRouter(MarketplaceInvoiceListItem);
