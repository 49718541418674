import React from "react";
import { Route } from "react-router-dom";

import AppProvider from "../../contexts/AppState/AppStateProvider";
import AppState from "../../contexts/AppState/AppState";

import Sidebar from "../common/Navigation/Sidebar";
import Dashboard from "../common/Dashboard/Dashboard";
import Marketplace from "../funder/Marketplace/Marketplace";
import Profile from "../common/Profile/Profile";
import Notifications from "../common/Notifications/Notifications";
import FundingSettings from "../funder/FundingSettings/FundingSettings";
import { ModalContainer } from "../common/Modals/CommonConfirmationModal";
import Documents from "../supplier/Documents/Documents";
import FundingRequest from "../supplier/FundingRequest/FundingRequest";
import FundingContract from "../supplier/FundingContract/FundingContract";
import NewDashboard from "../common/Dashboard/NewDashboard";
import Header from "../common/Navigation/Header";
import UserList from "../admin/UserList";

const Main = () => (
  <AppProvider>
    <AppState.Consumer>
      {appState => (
        <React.Fragment>
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <Sidebar />
            <main
              role="main"
              className="ml-sm-auto"
            >
              <Route
               exact
               path="/userList"
               render={() => <UserList appState={appState} />}
             /> 
              {
                appState.state.currentUserRole == "supplier" ?
                  <React.Fragment>
                    <Route
                      exact
                      path="/olddashboard"
                      render={() => <Dashboard appState={appState} />}
                    />
                    <Route
                      exact
                      path="/dashboard"
                      render={() => <NewDashboard appState={appState} />}
                    />
                  </React.Fragment>
                  : <React.Fragment>
                    <Route
                      exact
                      path="/dashboard/:tab?"
                      render={() => <Dashboard appState={appState} />}
                    />
                  </React.Fragment>
              }

              <Route
                exact
                path="/invoices"
                render={() => <Invoices appState={appState} />}
              />
              <Route
                exact
                path="/profile/:tab?"
                render={() => <Profile appState={appState} />}
              />
              <Route
                exact
                path="/notifications"
                render={() => <Notifications appState={appState} />}
              />
              <Route
                exact
                path="/marketplace"
                render={() => <Marketplace appState={appState} />}
              />
              <Route
                exact
                path="/settings"
                render={() => <FundingSettings appState={appState} />}
              />
              <Route
                exact
                path="/document/:tab?"
                render={() => <Documents appState={appState} />}
              />
              <Route
                exact
                path="/fundingrequest/:tab?"
                render={() => <FundingRequest appState={appState} />}
              />
              <Route
                exact
                path="/fundingcontract"
                render={() => <FundingContract appState={appState} />}
              />
            </main>
          </div>
          {/* <ModalContainer /> */}
        </React.Fragment>
      )}
    </AppState.Consumer>
  </AppProvider>
);

export default Main;
