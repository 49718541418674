import React, { Component } from 'react';
import Popup from '../Auth/Popup';
import { dollarFormatter } from '../../../services/Utilities';
import FullSpinner from '../Spinner/FullSpinner';
import API from '../../../services/API';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';

export default class ConfirmSelectedMatchFundersModal extends Component {
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            isLoading: false,
        };
    }
//..No method calls...///
    async submitSelectedFunders() {
        this.setState({ isLoading: true });
        const { data } = this.props;
        const { selectedFunders } = this.state;
        const request = {
            fundingReqId: data._id,
            userId:data.requesterId,
            selectedFunders,
        };
        await this.api.addSelectFunders(request);
        this.setState({ isLoading: false });
    }

    render() {
        const { data, isLoading } = this.state;
        return (
            <>
                <FullSpinner
                    isSpinning={isLoading} confirmationLabel={this.props.t("fundingrequests_page.match_funders.request_sent_to_funders")}
                    callback={e => this.props.onClose(true)}
                />
                <CommonPopUpModal
                    onClose={e => this.props.onClose(false)}
                    size="large"
                    title={this.props.t("fundingrequests_page.match_funders.confirm_match_funders_title")}
                    primaryBtn={this.props.t("fundingrequests_page.match_funders.confirm_and_submit")}
                    onSubmit={
                        () => {
                            this.setState({ isLoading: true });
                            const { data, funderIDs } = this.props;
                            const selectedFunders = funderIDs;
                            const request = {
                                fundingReqId: data._id,
                                userId: data.requesterId,
                                selectedFunders,
                            };
                            this.api.addSelectFunders(request).then(r => {
                                this.setState({
                                    isLoading: false
                                });
                            });
                        }
                    }
                    children={
                        <>
                            <div style={{ justifyContent: "center" }}>
                                <div className="col-md-12 selected-customers mb-2">
                                {this.props.t("fundingrequests_page.match_funders.selected_funders")}
                                </div>
                                <div className="col-md-12" style={{ height: "47vh", background: "#FFFFFF" }}>
                                    {this.props.selectedItems && (
                                        <React.Fragment>
                                            <div className="row py-3" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                                <div className="col-4">{this.props.t("grid_view_header_labels.funder_name")}</div>
                                                <div className='col-6'>{this.props.t("fundingrequests_page.match_funders.description")}</div>
                                                {/* <div className="col-4">City</div>
                                                <div className="col-4">State</div> */}
                                            </div>
                                            {this.props.selectedItems.map(doc =>
                                                <React.Fragment>
                                                    <div className="row" style={{ backgroundColor: doc.offerStatus != "" ? "#255F79" : "#5F9FBC" }}>
                                                        <div className="col-4" style={{ color: "white", fontSize: 14 }}>{doc.name}</div>
                                                        {/* <div className="col-4" style={{color:"white",fontSize:14}}>{doc.city}</div> */}
                                                        {/* <div className="col-4" style={{color:"white",fontSize:14}}>{doc.state}</div> */}
                                                        <div className="col-8" style={{ color: "white", fontSize: 14 }}>{doc.description ? doc.description : ""}</div>
                                                    </div>

                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </>
                    }
                />
            </>
        );
    }
}