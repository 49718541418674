import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {localDateFormatWithoutBracketTime} from "../../../services/Utilities"
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  popover: {
    pointerEvents: 'none',

  }
});

class SignaturePopver extends React.Component {
  state = {
    anchorEl: null,
  };

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes,
    userName,
    signature,
    createdAt,

     } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
          className={"signature-zIndex"}
        >
          <CheckCircleIcon htmlColor={'#008000'} />
        </Typography>
        <Popover
          id="mouse-over-popover"
          PaperProps={{className:"signature-popover-width"}}
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
                <Typography>
                    <div className='signature-tooltip'>
                        <div style={{ fontWeight: "bold" }}>
                            {this.props.t("funding_offer_details.signature")}
                        </div>
                        <div style={{ wordBreak: "break-all", fontSize: 12 }}>
                            {signature}
                        </div>
                        <div style={{ fontWeight: "bold" }}>
                        {this.props.t("funding_offer_details.signer")}
                        </div>
                        <div>
                            {userName}
                        </div>
                        <div style={{ fontWeight: "bold" }}>
                        {this.props.t("funding_offer_details.date_of_signature")}
                        </div>
                        <div>
                            {localDateFormatWithoutBracketTime(new Date(createdAt))}
                        </div>
                    </div>
                </Typography>
        </Popover>
      </div>
    );
  }
}

SignaturePopver.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles),withTranslation())(SignaturePopver);
