import React, { Component } from 'react';

import API from '../../../services/API';
import AppState from "../../../contexts/AppState/AppState";
import TopBuyersWidget from './TopBuyersWidget';
import DocumentsWidget from './DocumentsWidget';
import TaskListWidget from './TaskListWidget';
import AgingReportWidget from './AgingReportWidget';
import CustomerHistoryWidget from './CustomerHistoryWidget';
import CustomerAgingDetailWidget from "./CustomerAgingDetailWidget";
import TopBuyerswithHighestDBTWidget from './TopBuyerswithHighestDBTWidget';
import User from '../../../services/User';
import { AddModal } from "../Modals/CommonConfirmationModal";
import { compareRole, enumRole } from '../../../configs/GlobalConfig';
import { withTranslation } from 'react-i18next';

class NewDashboard extends Component {

  api = new API();
  user = new User();

  constructor(props) {
    super(props);
    this.state = {
      user:this.props && this.props.data?this.props.data: {},
      isAccountingInformation: this.props && this.props.data && this.props.data.accountingInformation && this.props.data.accountingInformation=="other"?false: true,
      agingSelectedIndex: -1,
      agingSelectedDataIndex: -1,
    };

    this.setagingSelectedIndex = this.setagingSelectedIndex.bind(this);
  }

  componentDidMount() {
    if(!compareRole(this.user.currentUserRole(), enumRole.Funder)){
      document.title = `Dashboard - LedgerFunding ${this.user.currentUserRole()}`;
      this.getProfile();
    }
    // this.api.runErpBackgroundJobs();
  }
  setagingSelectedIndex(index, datasetIndex) {
    const title = datasetIndex == 0 ?
      "Customer Receivables Details"
      : "Customer Payables Details";
    AddModal(title, <CustomerAgingDetailWidget
      agingSelectedIndex={index}
      agingSelectedDataIndex={datasetIndex}
    />, "Close", false)
  }
  async getProfile() {
    const profileResponse = await this.getV2Profile();
    let isAccountingInfo = true;
    if (profileResponse.user.accountingInformation == "other") {
      isAccountingInfo = false;
    }

    this.setState({
      user: profileResponse.user,
      isAccountingInformation: isAccountingInfo,
    });
  }

  async getV2Profile() {
    return await this.api.getV2Profile();
  }
  render() {
    const { t } = this.props;
    return (
      <AppState.Consumer>
        {(appState) => (
          <React.Fragment>
            {!compareRole(this.user.currentUserRole(), enumRole.Funder) && (
              <div className="header-titlebar">
                <h3>{t("page_titles.dashboard")}</h3>
              </div>
            )}
            <div className={compareRole(this.user.currentUserRole(), enumRole.Funder) ? "row flex-container mr-0" : "row flex-container content-container header-titlebar"}>
              <DocumentsWidget
                data={this.state.user}
                t={t}
              />
              {!compareRole(this.user.currentUserRole(), enumRole.Funder) && (
                <>
                  <TaskListWidget t={t} />
                </>
              )}
              <TopBuyersWidget
               t={t}
                data={this.state.user}
              />
              {compareRole(this.user.currentUserRole(), enumRole.Funder) && this.state.isAccountingInformation && (
                <React.Fragment>
                  <TopBuyerswithHighestDBTWidget
                  t={t}
                    data={this.state.user} />
                </React.Fragment>
              )}
              <AgingReportWidget
              t={t}
                setagingSelectedIndex={this.setagingSelectedIndex}
                appState={appState}
                isAccountingInfo={this.state.isAccountingInformation}
                data={this.state.user}
              />
              {this.state.isAccountingInformation && (
                <React.Fragment>
                  {!compareRole(this.user.currentUserRole(), enumRole.Funder) && (
                    <><TopBuyerswithHighestDBTWidget
                    t={t}
                      data={this.state.user} /></>
                  )}
                  <CustomerHistoryWidget
                    data={this.state.user}
                    t={t} />
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default withTranslation() (NewDashboard);
