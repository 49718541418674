export const getAgingReportDownload = async (receivablesAging, payablesAging, type) => {
    let rows;
    let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (type === "payables") {
        rows = [
            ["Vendor Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
        ];
        reportGenerate(payablesAging.Payables, type);
    } else if (type === "receivable") {
        rows = [
            ["Customer Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
        ];
        reportGenerate(receivablesAging.invoices, type);
    }

    function reportGenerate(aging, type) {
        aging.forEach(element => {
            let columns = [];
            if (type === "payables") {
                columns.push('"' + element.VendorName + '"');
            } else {
                columns.push('"' + element.customerName + '"');
            }
            columns.push(element.currentDue);
            columns.push(element.due30Days);
            columns.push(element.due45Days);
            columns.push(element.due60Days);
            columns.push(element.due90Days);
            columns.push(element.due120Days);
            columns.push(element.due180Days);
            columns.push(element.due180pluseDays);
            let totalDueValue = (element.currentDue || 0)
                + (element.due30Days || 0)
                + (element.due45Days || 0)
                + (element.due60Days || 0)
                + (element.due90Days || 0)
                + (element.due120Days || 0)
                + (element.due180Days || 0)
                + (element.due180pluseDays || 0);
            columns.push(totalDueValue);
            [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue || 0),
            due30 + (element.due30Days || 0),
            due45 + (element.due45Days || 0),
            due60 + (element.due60Days || 0),
            due90 + (element.due90Days || 0),
            due120 + (element.due120Days || 0),
            due180 + (element.due180Days || 0),
            due180pluse + (element.due180pluseDays || 0),
            totalDue + totalDueValue];
            rows.push(columns);
        });
    }

    let columns = [];
    columns.push("TOTAL");
    columns.push(currentDues);
    columns.push(due30);
    columns.push(due45);
    columns.push(due60);
    columns.push(due90);
    columns.push(due120);
    columns.push(due180);
    columns.push(due180pluse);
    columns.push(totalDue);
    rows.push(columns);
    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach((rowArray) => {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });
    let _csvContentString = csvContent;

    var encodedUri = encodeURI(csvContent);
    let _fileContent = {
        encodedUri: encodedUri,
        isCSVString: true,
        csvString: _csvContentString,
        csvData: rows,
        filename: type === "payables" ? "Payables_Aging_Report.csv" : "Receivable_Aging_Report.csv"
    };

    return _fileContent;
};