import React, { Component } from 'react';
import API, { API_ENDPOINT_SUPPLIER } from "../../../services/API";
import User from '../../../services/User';
import FullSpinner from '../Spinner/FullSpinner';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';

export default class BusinessInformationMissingModal extends Component {

    api = new API();
    user = new User();

    constructor(props) {
        super(props);
        this.state = {
            userName: "",
            title: "",
            user: this.props && this.props.data ? this.props.data : "",
            isSubmitBtnEnabled: false,
            isLoading: false
        };
    }

    saveProfileInformation = async () => {
        this.setState({ isLoading: true });
        const {
            user: {
                name,
                address,
                city,
                state,
                zip,
                phone,
                year,
                taxId,
                entity,
                industry,
                balance,
                revenue,
                accountingInformation,
                websiteUrl,
                country,
                userName,
                title

            }
        } = this.state;
        const userToSave = {
            name,
            address,
            city,
            state,
            zip,
            phone,
            year,
            entity,
            industry,
            taxId,
            balance,
            revenue,
            accountingInformation,
            websiteUrl,
            country,
            userName: this.state.userName,
            title: this.state.title
        };
        if (this.user.currentUserRole() == "supplier") {

            const v2Profile = {
                name: userToSave.name,
                address: {
                    address1: userToSave.address,
                    address2: "",
                    city: userToSave.city,
                    state: userToSave.state,
                    zip: userToSave.zip,
                    country: userToSave.country
                },
                phone: userToSave.phone,
                incorporationYear: userToSave.year,
                entity: userToSave.entity,
                industry: userToSave.industry,
                accountingInformation: userToSave.accountingInformation,
                websiteUrl: websiteUrl,
                userName: userToSave.userName,
                title: userToSave.title
            };
            await this.api.updateV2Profile(v2Profile);
            this.setState({ isLoading: false });
           
        }
        else {
            await this.api.updateProfile(userToSave);
            this.setState({ isLoading: false });
           
        }
    };


    render() {
        const { data } = this.props;

        return (
            <>
                <FullSpinner
                    isSpinning={this.state.isLoading}
                    confirmationLabel="Business Information Updated"
                />
                <CommonPopUpModal
                    size='large'
                    title='Incomplete Business Information'
                    onClose={e => this.props.onClose(true)}
                    primaryBtn='Submit'
                    isSubmitBtn={!this.state.isSubmitBtnEnabled}
                    onSubmit={(e) => {
                        this.saveProfileInformation();
                    }}
                >
                    <div className="modal-body flex-column">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label-style" htmlFor="name">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="fullName"
                                        required
                                        onChange={(e) => {

                                            if (this.state.userName.length > 0 && this.state.title.length > 0) {
                                                this.setState({ isSubmitBtnEnabled: true, userName: e.target.value });
                                            }
                                            else {
                                                this.setState({ userName: e.target.value });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label-style" htmlFor="name">Job Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        required
                                        onChange={(e) => {

                                            if (this.state.userName.length > 0 && this.state.title.length > 0) {
                                                this.setState({ isSubmitBtnEnabled: true, title: e.target.value });
                                            }
                                            else {
                                                this.setState({ title: e.target.value });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CommonPopUpModal>           
            </>
        );
    }
}