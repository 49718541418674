import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import Chart from "react-apexcharts";
import List from "../List/List";
import InvoiceListItem from "../List/ListType/InvoiceListItem";
import Modals from "../Modals/Modals";

import AppState from "../../../contexts/AppState/AppState";
import API from "../../../services/API";
import Load from '../../../assets/images/load-small.gif';
import ViewInvoiceModal from "../../supplier/Documents/Invoices/ViewInvoiceModal";
import TaskListWidget from "./TaskListWidget";
import { withRouter } from "react-router-dom";
import CommonConfirmationModal from "../Modals/CommonConfirmationModal";
import { localDateFormatWithTime } from "../../../services/Utilities";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";

const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

// Test data for creating a static graph on the supplier dashboard


class Dashboard extends Component {
  api = new API();

  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      funderDashboard: {
        requests: 0,
        accepted: 0,
        acceptedAmount: 0,
        requestAmount: 0
      },
      supplierDashboard: {
        outstanding: 0,
        approved: 0,
        outstandingAmount: 0,
        approvedAmount: 0,
        chartData: null,
        reportAsOn: null,
        DSO: 0,
        buyerCount: 0
      },
      invSort: {
        sortBy: '',
        sort: ''
      },
      financeDocs: {
        balanceSheet: {
          fileName: "",
          downloadUrl: ""
        },
        incomeStmt: {
          fileName: "",
          downloadUrl: ""
        },
        cashflow: {
          fileName: "",
          downloadUrl: ""
        },
        agingfile: {
          fileName: "",
          downloadUrl: ""
        }
      },
      isViewPopupOpen:  false,
      selectedTCF:  null,
      currentTab: "",
      isApproveInvoice: false,
      isRejectInvoice: false
    };
    this.getInvoices = this.getInvoices.bind(this);
    this.getFunderDashboard = this.getFunderDashboard.bind(this);
    this.getSupplierDashboard = this.getSupplierDashboard.bind(this);
    this.importAgingReport = this.importAgingReport.bind(this);
    this.loadAgingChart = this.loadAgingChart.bind(this);
    this.checkIfSupplierUploadedDocumentation = this.checkIfSupplierUploadedDocumentation.bind(
      this
    );
    this.props.appState.setState("getInvoices", this.getInvoices);
  }

  componentDidMount() {
    const { appState } = this.props;
    if (this.props.match.params.tab) {
      this.setState({ currentTab: this.props.match.params.tab });
    }
    else {
      this.setState({ currentTab: "to-do" });
    }
    if (appState.state.currentUserRole === "funder") {
      this.getFunderDashboard();
    } else if (appState.state.currentUserRole === "supplier") {
      this.getInvoices();
      this.getSupplierDashboard();
    }
    else {
      this.getInvoices();
    }
    if (appState.state.currentUserRole === "supplier") {
      this.checkIfSupplierUploadedDocumentation();
    }
    this.onInvoiceSorting = this.onInvoiceSorting.bind(this);
  }

  async getInvoices() {
    const { appState } = this.props;
    let invoicesResponse = [];
    invoicesResponse = await this.api.getInvoices();
    this.setState({
      invoices: this.filterInvoicesForRole(invoicesResponse.invoices)
    });

  }

  _getBuyerNameFromInvoice(invoice_) {
    const { buyer, customerDetails, buyerName } = invoice_;
    return (buyer && buyer.name) ? buyer.name :
      ((customerDetails && customerDetails.name) ? customerDetails.name : buyerName)
  }

  onInvoiceSorting(event, invoices) {
    event.preventDefault();
    let sort = event.currentTarget.getAttribute('sort');
    let sortBy = event.currentTarget.getAttribute('sortby');
    this.setState({ invSort: { sortBy: sortBy, sort: sort } });
    const { appState } = this.props;
    if (sort === 'asc') {
      event.currentTarget.setAttribute('sort', 'desc');

      this.setState({
        invoices: invoices.sort(
          (a, b) => {
            if (sortBy === 'name') {
              event.currentTarget.setAttribute('title', 'Click To Sort Customer Name By Descending');
              var aName, bName;
              if (appState.state.currentUserRole !== 'supplier') {
                aName = a.supplier.name ? a.supplier.name : '';
                bName = b.supplier.name ? b.supplier.name : '';
              }
              else {
                aName = this._getBuyerNameFromInvoice(a);
                bName = this._getBuyerNameFromInvoice(b);
              }
              if (aName < bName) return -1;
              else if (aName > bName) return 1;
              return 0;
            }
            else if (sortBy === 'amt') {
              event.currentTarget.setAttribute('title', 'Click To Sort Customer Name By Descending');
              return a.total - b.total;
            }
            else if (sortBy === 'date') {
              event.currentTarget.setAttribute('title', 'Click To Sort Invoice Date By Descending');
              // let aDate = a.paymentTerm ? new Date(a.createdAt) : new Date('01-01-1999');
              // let bDate = b.paymentTerm ? new Date(b.createdAt) : new Date('01-01-1999');
              return new Date(a.createdAt) - new Date(b.createdAt);
            }
          }
        )
      });
    }
    else {
      event.currentTarget.setAttribute('sort', 'asc');
      this.setState({
        invoices: invoices.sort(
          (a, b) => {
            if (sortBy === 'name') {
              event.currentTarget.setAttribute('title', 'Click To Sort Customer Name By Ascending');
              let aName = '';
              let bName = '';
              if (appState.state.currentUserRole !== 'supplier') {
                aName = a.supplier.name ? a.supplier.name : '';
                bName = b.supplier.name ? b.supplier.name : '';
              }
              else {
                aName = this._getBuyerNameFromInvoice(a);
                bName = this._getBuyerNameFromInvoice(b);
              }
              if (aName > bName) return -1;
              else if (aName < bName) return 1;
              return 0;
            }
            else if (sortBy === 'amt') {
              event.currentTarget.setAttribute('title', 'Click To Sort Customer Name By Ascending');
              return b.total - a.total;
            }
            else if (sortBy === 'date') {
              event.currentTarget.setAttribute('title', 'Click To Sort Invoice Date By Ascending');
              // let aDate = a.paymentTerm ? new Date(a.paymentTerm) : new Date('01-01-1999');
              // let bDate = b.paymentTerm ? new Date(b.paymentTerm) : new Date('01-01-1999');
              return new Date(b.createdAt) - new Date(a.createdAt);
            }
          }
        )
      })
    }
  }

  async getFunderDashboard() {
    const { funderDashboard } = this.state;
    let fundingRequest = await this.api.getFundingReqForDashboard();
    const openResponse = fundingRequest.filter(value => (value.status >= 3 && value.status <= 4));
    const fundingResponse = fundingRequest.filter(value => (value.status == 6 || value.status == 11));
    const archiveResponse = fundingRequest.filter(value => value.status == 7);
    let filteredToDoList = fundingRequest.filter(value => {
      return (value.status == 3 ||
        value.status == 5 ||
        (value.status == 6 && value.contracts && value.contracts.length && !value.contracts[0].fundingAggrement && !value.contracts[0].signedAggrements) ||
        (value.status == 6 && value.contracts && value.contracts.length && value.contracts[0].fundingAggrement && value.contracts[0].signedAggrements)
      )
    });
    if (fundingRequest.length > 0) {
      funderDashboard.requests = openResponse.length;
      funderDashboard.accepted =
          fundingResponse.length + archiveResponse.length;
      const totalAcceptedInvoices = [
        ...fundingResponse,
        ...archiveResponse
      ];
      funderDashboard.acceptedAmount = totalAcceptedInvoices.reduce(
        (a, b) => a + Number(b.totalfundingAmount.toString().replace(/[^0-9.-]+/g, "")) ,
        0
      );
      funderDashboard.requestAmount = openResponse.reduce(
        (a, b) => a + Number(b.totalfundingAmount.toString().replace(/[^0-9.-]+/g, "")),
        0
      );
      this.setState({
        funderDashboard, fundingRequestList: filteredToDoList
      });
    }
    // const matchedResponse = await this.api.getMatchedInvoices();
    // const openInvoices = await this.api.getMyInProgressInvoices();
    // const archivedInvoicesResponse = await this.api.getClosedInvoices();
    // if (matchedResponse.invoices) {
    //   funderDashboard.requests = matchedResponse.invoices.length;
    //   funderDashboard.accepted =
    //     openInvoices.invoices.length + archivedInvoicesResponse.invoices.length;
    //   const totalAcceptedInvoices = [
    //     ...openInvoices.invoices,
    //     ...archivedInvoicesResponse.invoices
    //   ];
    //   funderDashboard.acceptedAmount = totalAcceptedInvoices.reduce(
    //     (a, b) => a + b.total,
    //     0
    //   );
    //   funderDashboard.requestAmount = matchedResponse.invoices.reduce(
    //     (a, b) => a + b.total,
    //     0
    //   );
    //   this.setState({
    //     funderDashboard
    //   });
    // }
  }


  getFundingRequest = async () => {
    let fundingRequest = await this.api.getFundingReqForDashboard();
    fundingRequest = fundingRequest.filter(value => {
      return (value.status == 3 ||
        value.status == 5 ||
        (value.status == 6 && value.contracts && value.contracts.length && !value.contracts[0].fundingAggrement && !value.contracts[0].signedAggrements) ||
        (value.status == 6 && value.contracts && value.contracts.length && value.contracts[0].fundingAggrement && value.contracts[0].signedAggrements)
      )
    });
    this.setState({  fundingRequestList:  fundingRequest  });
  }

  importAgingReport = async () => {
    const response = await this.api.getAgingReportFromERP(this.props.appState.state.currentUser.email);
    if (response.message && response.message !== '') {
      alert(response.message);
    }
    else {
      await this.api.submitAgingReportToLF(response);
    }
    this.setState({ isImporting: false });
  }
  getBuyerCount(invoices) {
    let buyersList = [];
    if (invoices) {
      invoices.forEach(inv => {
        if (buyersList.indexOf(inv.customerDetails.name) === -1) {
          buyersList.push(inv.customerDetails.name);
        }
      });
    }
    return buyersList.length;
  }
  async getSupplierDashboard() {
    const { supplierDashboard } = this.state;
    const invoicesResponse = "";//await this.api.getInvoices();
    const archivedResponse = "";// await this.api.getArchivedInvoices();

    const profileResponse = await this.api.getV2Profile();
    if (profileResponse.user.invoiceAgingReport &&
      profileResponse.user.invoiceAgingReport.invoices.length > 0) {
      this.loadAgingChart(profileResponse);
    }
    else {
      this.api.getAgingReportFromERP(this.props.appState.state.currentUser.email)
          .then((res) => {
            this.api.submitAgingReportToLF(res)
                .then(aRes => {
                  this.api.getV2Profile().then(profile => this.loadAgingChart(profile));
                });
          }).catch(ex => {
            this.loadAgingChart(profileResponse);
          })
    }

    // if (archivedResponse.invoices && invoicesResponse.invoices) {
    //   const totalApprovedInvoices = [
    //     ...archivedResponse.invoices,
    //     ...invoicesResponse.invoices.filter(invoice => invoice.status >= 5)
    //   ];
    //   const outstandingInvoices = invoicesResponse.invoices.filter(
    //     invoice => invoice.status < 5
    //   );
    //   supplierDashboard.outstanding = outstandingInvoices.length;
    //   //supplierDashboard.approved = totalApprovedInvoices.length;
    //   supplierDashboard.outstandingAmount = outstandingInvoices.reduce(
    //     (a, b) => a + b.total,
    //     0
    //   );

    //   supplierDashboard.DSO = (supplierDashboard.outstandingAmount / profileResponse.user.revenue) * 365;
    //   supplierDashboard.DSO = isNaN(supplierDashboard.DSO) ? 0 : supplierDashboard.DSO;
    //   supplierDashboard.buyerCount = this.getBuyerCount(invoicesResponse.invoices.filter(invoice => invoice.customerDetails && invoice.customerDetails.name !== null));

    //   this.setState({
    //     supplierDashboard
    //   });

    //   if (profileResponse.user.invoiceAgingReport &&
    //     profileResponse.user.invoiceAgingReport.invoices.length > 0) {
    //     this.loadAgingChart(profileResponse);
    //   }
    //   else {
    //     this.api.getAgingReportFromERP(this.props.appState.state.currentUser.email)
    //     .then((res) => {
    //       this.api.submitAgingReportToLF(res)
    //       .then(aRes => {
    //         this.api.getV2Profile().then(profile => this.loadAgingChart(profile));
    //       });
    //     }).catch(ex => {
    //       this.loadAgingChart(profileResponse);
    //     })
    //   }
    // }
  }

  loadAgingChart = (profileResponse) => {

    const { supplierDashboard } = this.state;
    let numOfOpenInvoices, currentDue, due30Days, due45Days, due60Days, due90Days, due120Days, due180Days, due180pluseDays;

    numOfOpenInvoices = 0;
    currentDue = 0;
    due30Days = 0;
    due45Days = 0;
    due60Days = 0;
    due90Days = 0;
    due120Days = 0
    due180Days = 0;
    due180pluseDays = 0;
    if (profileResponse.user.invoiceAgingReport &&
      profileResponse.user.invoiceAgingReport.invoices.length > 0) {
      let dt = new Date(profileResponse.user.invoiceAgingReport.pulledDate);
      supplierDashboard.reportAsOn = localDateFormatWithTime(dt);

      profileResponse.user.invoiceAgingReport.invoices.forEach(invoices => {
        numOfOpenInvoices += parseInt(invoices.numOfOpenInvoices);
        currentDue += parseInt(invoices.currentDue);
        due30Days += parseInt(invoices.due30Days);
        due60Days += parseInt(invoices.due60Days) + parseInt(invoices.due45Days);
        due90Days += parseInt(invoices.due90Days);
        due120Days += parseInt(invoices.due120Days);
        due180Days += parseInt(invoices.due180Days);
        due180pluseDays += parseInt(invoices.due180pluseDays);
      });
    }
    supplierDashboard.approved = numOfOpenInvoices;
    supplierDashboard.approvedAmount = (currentDue + due30Days + due60Days + due90Days + due120Days + due180Days + due180pluseDays);
    supplierDashboard.chartData = {
      options: {
        chart: {
          id: "basic-bar"
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['Current', '30', '60', '90', '120', '180', '> 180']
        },
        yaxis: {
          labels: {
            formatter: (value) => dollarFormatter.format(value)
          }
        }
      },
      series: [{
        data: [currentDue, due30Days, due60Days, due90Days, due120Days, due180Days, due180pluseDays]
      }]
    }
    this.setState({
      supplierDashboard
    });
  }

  async refreshAgingChart() {

    const profileResponse = await this.api.getV2Profile();

  }

  filterInvoicesForRole(invoices) {
    const { appState } = this.props;
    let filteredInvoices = invoices;
    if (appState.state.currentUserRole === "funder" && filteredInvoices) {
      filteredInvoices = filteredInvoices.filter(
        invoice => invoice.status === 4
      );
    } else if (appState.state.currentUserRole === "supplier") {
      if (filteredInvoices) {
        filteredInvoices = filteredInvoices.filter(
          invoice =>
            invoice.status === 0 ||
            invoice.status === 2 ||
            invoice.status === 4 ||
            invoice.status === 5
        );
      }
    } else {
      if (filteredInvoices) {
        filteredInvoices = filteredInvoices.filter(
          invoice => invoice.status === 1 || invoice.status === 7
        );
      }
    }
    return filteredInvoices.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }


  async checkIfSupplierUploadedDocumentation() {
    const { appState } = this.props;
    var result = {}
    if (appState.state.currentUserRole === "supplier") {
      var documentationResponse = {}
      const res = await this.api.getSupplierDocs();
      if (res.items && res.items.length > 0) {
        const { financeDocs } = this.state;
        let items = res.items.filter(
          x => x.financialDocumentType === "BALANCE_SHEET"
        );
        if (items.length > 0) {
          financeDocs.balanceSheet.fileName = items[0].filename;
          financeDocs.balanceSheet.downloadUrl = items[0].downloadUrl;
        }
        items = res.items.filter(
          x => x.financialDocumentType === "INCOME_STATEMENT"
        );
        if (items.length > 0) {
          financeDocs.incomeStmt.fileName = items[0].filename;
          financeDocs.incomeStmt.downloadUrl = items[0].downloadUrl;
        }
        items = res.items.filter(x => x.financialDocumentType === "CASH_FLOW");
        if (items.length > 0) {
          financeDocs.cashflow.fileName = items[0].filename;
          financeDocs.cashflow.downloadUrl = items[0].downloadUrl;
        }
        items = res.items.filter(x => x.financialDocumentType === "AGING_REPORT");
        if (items.length > 0) {
          financeDocs.agingfile.fileName = items[0].filename;
          financeDocs.agingfile.downloadUrl = items[0].downloadUrl;
        }
        var status = {}
        status = { "AgingDoc": financeDocs.agingfile.fileName ? true : false, "FinancialDoc": financeDocs.balanceSheet.fileName ? true : false }
        result = { "fileExists": status }
        documentationResponse = result;


      } else {
        var status = {}
        status = { "AgingDoc": false, "FinancialDoc": false }
        result = { "fileExists": status }
        documentationResponse = result;
      }
      appState.setState(
        "documentationExists",
        documentationResponse
      );
    }
  }

  render() {
    const {
      invoices, funderDashboard, supplierDashboard, invSort, financeDocs, selectedTCF, isViewPopupOpen,
      isApproveInvoice,
      isRejectInvoice } = this.state;
    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>

            {appState.state.currentUserRole == "supplier" && (
              <div className="pt-3 pb-2 mb-3 border-bottom">
                <h4>Dashboard</h4>
              </div>
            )}
            {appState.state.currentUserRole == "funder" && (
              <div className="header-titlebar">
                <h3>{this.props.t("page_titles.dashboard")}</h3>
              </div>
            )}
            {(isViewPopupOpen || isApproveInvoice || isRejectInvoice) && (
              <div className="terms-checkbox-container">
                {isViewPopupOpen && selectedTCF && <ViewInvoiceModal
                  isReadOnly={false}
                  data={selectedTCF}
                  onClose={() => {
                    this.setState({ isViewPopupOpen: false, selectedTCF: null });
                  }}
                />}
                {
                  isApproveInvoice && selectedTCF && (
                    <CommonConfirmationModal
                      title={"Approve Invoice: #" + this.state.selectedTCF.documentId}
                      submitText="Approve"
                      onClose={() => {
                        this.setState({ isApproveInvoice: false, selectedTCF: null })
                        this.getInvoices();
                      }}
                      onSubmit={() => {
                        const invoiceToApprove = {
                          id: this.state.selectedTCF._id,
                          reject: false,
                        };
                        return this.api.docApproveInvoice(invoiceToApprove).then(r => {
                          this.getInvoices();
                        });
                      }}
                    >
                      Are you sure you want to approve this invoice?
                    </CommonConfirmationModal>
                  )
                }
                {
                  isRejectInvoice && selectedTCF && (
                    <CommonConfirmationModal
                      title={"Reject Invoice: #" + this.state.selectedTCF.documentId}
                      submitText="Reject"
                      onClose={() => {
                        this.setState({ isRejectInvoice: false, selectedTCF: null })
                        this.getInvoices();
                      }}
                      onSubmit={() => {
                        const invoiceToReject = {
                          id: this.state.selectedTCF._id,
                          reject: true,
                        };
                        return this.api.docRejectInvoice(invoiceToReject).then(r => {
                          this.getInvoices();
                        });
                      }}
                    >
                      Are you sure you want to reject this invoice?
                    </CommonConfirmationModal>
                  )
                }
              </div>
            )}
            {appState.state.currentUserRole === "funder" && (
              <FunderDashboard {...funderDashboard} t={this.props.t} />
            )}
            {appState.state.currentUserRole === "supplier" && (
              <SupplierDashboard {...supplierDashboard} />
            )}
            {appState.state.currentUserRole == "buyer" && (
              <React.Fragment>
                <div className="header-titlebar">
                  <h3>Dashboard</h3>
                  <ul className="nav nav-tabs mt-2" id="productTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className={"nav-link" + (this.state.currentTab == "to-do" ? " active" : "")}
                        id="home-tab"
                        data-toggle="tab"
                        href="#erpimport"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        To-do
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        className={"nav-link" + (this.state.currentTab == "my-invoices" ? " active" : "")}
                        id="profile-tab"
                        data-toggle="tab"
                        href="#fileUpload"
                        role="tab"
                        aria-controls="password"
                        aria-selected="false"
                      >
                        My Invoices
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="content-container documents-list">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={"tab-pane show" + (this.state.currentTab == "to-do" ? " active" : "")}
                      id="erpimport"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row card mt-2">
                        <div className="col-sm mb-4">
                          {
                            invoices.length > 0 && (
                              <div className="row grid-header ">
                                <div className="col">
                                  <div className="">
                                    <div className="row">
                                      {appState.state.currentUserRole == "buyer" && (
                                        <React.Fragment>
                                          <div className="col-2 align-self-center text-center">
                                            <span><a sort="asc" className="text-white font-weight-bold" title='Click To Sort Customer Name By Ascending' sortby='name' href="#" onClick={(event) => {
                                              this.onInvoiceSorting(event, invoices);
                                            }}>Customer Name</a>
                                              {invSort.sortBy !== 'name' && (
                                                <SortingControl />
                                              )}
                                              {invSort.sortBy === 'name' && (
                                                <SortingControl orderBy={invSort.sort} />
                                              )}
                                            </span>
                                          </div>
                                          <div className="col-2 align-self-center text-center">
                                            <span><a sort="asc" className="text-white font-weight-bold" title='Click To Sort Invoice Amount By Ascending' sortby='amt' href="#" onClick={(event) => {
                                              this.onInvoiceSorting(event, invoices);
                                            }}>Invoice Amount</a></span>
                                            {invSort.sortBy !== 'amt' && (
                                              <SortingControl />
                                            )}
                                            {invSort.sortBy === 'amt' && (
                                              <SortingControl orderBy={invSort.sort} />
                                            )}
                                          </div>
                                          <div className="col-2 align-self-center text-center">
                                            <span><a sort="asc" className="text-white font-weight-bold" title='Click To Sort Invoice Date By Ascending' sortby='date' href="#" onClick={(event) => {
                                              this.onInvoiceSorting(event, invoices);
                                            }}>Invoice Date</a></span>
                                            {invSort.sortBy !== 'date' && (
                                              <SortingControl />
                                            )}
                                            {invSort.sortBy === 'date' && (
                                              <SortingControl orderBy={invSort.sort} />
                                            )}
                                          </div>
                                          <div className="col-3 align-self-center text-center">
                                            <span className="text-white font-weight-bold" >Status</span>
                                          </div>
                                          <div className="col-2 align-self-center text-center">
                                          </div>
                                        </React.Fragment>
                                      )}

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          <div className="grid-rows">
                            {appState.state.currentUserRole == "buyer" && (
                              <React.Fragment>
                                <List
                                  data={invoices}
                                  isLoading={false}
                                  emptyListLabel="No Items Requiring Your Action"
                                  itemType={InvoiceListItem}
                                  callback={(action, item, documentindex) => {
                                    let docInvoices = invoices;
                                    let fundingModalData = docInvoices.filter((r, i) => i == item);
                                    if (action == "viewinvoicemodal") {
                                      this.setState({
                                        isViewPopupOpen: true,
                                        selectedTCF: fundingModalData.length > 0 ? fundingModalData[0] : null,
                                      })
                                    }
                                    else if (action == "approveInvoice") {
                                      this.setState({
                                        isApproveInvoice: true,
                                        selectedTCF: fundingModalData.length > 0 ? fundingModalData[0] : null,
                                      })
                                    }
                                    else if (action == "rejectInvoice") {
                                      this.setState({
                                        isRejectInvoice: true,
                                        selectedTCF: fundingModalData.length > 0 ? fundingModalData[0] : null,
                                      })
                                    }
                                  }}
                                />
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={"tab-pane show" + (this.state.currentTab == "my-invoices" ? " active" : "")}
                      id="fileUpload"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                     
                    </div>
                  </div>
                </div>
                {/* <div className="row mt-3">
                  <div className="col-12">
                    <h5 className="lead">To-do</h5>
                  </div>
                </div> */}

              </React.Fragment>
            )}
            {this.filterInvoicesForRole(invoices) && (
              <Modals invoices={invoices} />
            )}
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default compose( withRouter , withTranslation())(Dashboard);

const SupplierDashboard = ({
  outstanding,
  approved,
  outstandingAmount,
  approvedAmount,
  chartData,
  DSO,
  reportAsOn,
  buyerCount
}) => (
  <div className="row">
    <div className="col-lg-4">
      <div className="card card-info card-height col-lg-12  p-3">
        <div className="row">
          <div className="col-lg-6 ">
            <span>Open Invoices</span>
            <h2>{approved}</h2>
            <span>Total Amount</span>
            <h2 className="p-a">{dollarFormatter.format(approvedAmount)}</h2>
          </div>
          <div className="col-lg-6">
            <div className="ml-5">
              <span>Customers</span>
              <h2>{buyerCount}</h2>
            </div>
          </div>
        </div>
        <div className="row row-space-7em">
          <div className="col-lg-8">
            <span>Days Sales Outstanding</span>
            <h2>{Math.round(DSO)}</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-8 card card-height">
      <div className="col-lg-12 p-3 ">
        <div className="row">
          <div className="col">
            <h5>Aging {!reportAsOn && (<span title="Please add ERP information in the profile to enable diagram"><FontAwesomeIcon className="ml-2" icon={faInfoCircle} /></span>)}  </h5>
            {!chartData && (
              <div className="loadProgress">
                <img className="logo" src={Load} alt="Refreshing Aging Information" />
                <br />
                <span className="small font-italic">Loading Aging Information</span>
              </div>
            )}
            {chartData && (
              <div>
                <div className="mixed-chart">
                  <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    width="100%"
                    height="300"
                  />
                </div>
                <div className="small text-center legend-pos">
                  <strong>Days</strong>
                </div>
                {reportAsOn && (
                  <div className="small text-center">
                    <strong>Report As On</strong> {reportAsOn}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

const FunderDashboard = ({
  requests,
  accepted,
  requestAmount,
  acceptedAmount,
  t
}) => (
  <div className="content-container bg-color funder-dashboard">
    <div className="row">
      <div className="col-sm col-lg-7 mb-4">
        <div className="bg-white card p-3">
          <div className="row">
            <div className="col">
              <h6>{t("dashboard_page.widget_titles.running_total_match_funding_request")}</h6>
              <h2 >{requests}</h2>
              <p>{t("common_names.total_amount")}: {dollarFormatter.format(requestAmount)}</p>
            </div>
            <div className="col">
              <h6>{t("dashboard_page.widget_titles.running_total_of_accepted_offers")}</h6>
              <h2>{accepted}</h2>
              <p>{t("common_names.total_amount")}: {dollarFormatter.format(acceptedAmount)}</p>
            </div>
          </div>
        </div>
      </div>
      <TaskListWidget t={t}/>
    </div>
  </div>
);

const SortingControl = ({ orderBy }) => {
  if (orderBy === 'asc') {
    return (<span>
      <FontAwesomeIcon className="sort-icon-asc" icon={faSortUp} />
      <FontAwesomeIcon className="sort-icon-desc sort-disable" icon={faSortDown} />
    </span>);
  }
  else if (orderBy === 'desc') {
    return (<span>
      <FontAwesomeIcon className="sort-icon-asc sort-disable" icon={faSortUp} />
      <FontAwesomeIcon className="sort-icon-desc" icon={faSortDown} />
    </span>);
  }

  return (<span>
    <FontAwesomeIcon className="sort-icon-asc sort-disable" icon={faSortUp} />
    <FontAwesomeIcon className="sort-icon-desc sort-disable" icon={faSortDown} />
  </span>);
}