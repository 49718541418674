import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "../List.css";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSortDown
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import Stepper from "react-stepper-horizontal";
import CheckmarkIcon from "../../../../assets/icons/checkmark.png";

import AppState from "../../../../contexts/AppState/AppState";

import API from "../../../../services/API";
import {
  getStepForTimeline,
  getStepForBuyerTimeline,
  getStepForFunderTimeline,
  localDateFormat
} from "../../../../services/Utilities";
import MessageModal from '../../Modals/MessageModal';

const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

class InvoiceListItem extends Component {
  api = new API();
  _isLive = false;

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      buyerStatus: "Buyer Approved"
    };
  }
  componentDidMount() {
    if (this.props.status === 3) {
      this.setState({ buyerStatus: "Buyer Rejected" });
    }
    if (this.props.status === 2) {
      this.setState({ buyerStatus: "Buyer approved" });
    }

    this._isLive = true;

    setTimeout(() => {
      if (!this._isLive) {
        return;
      }

      let autoMessageInfo = sessionStorage.getItem("auto-message-info");
      if (autoMessageInfo) {
        autoMessageInfo = JSON.parse(autoMessageInfo);
        if (this.props.invoiceNum == autoMessageInfo.invoice) {
          this.setState({
            isOpenedInvoiceMessage: true,
            autoMessageRecipient: autoMessageInfo.sender
          });
          sessionStorage.removeItem("auto-message-info");
        }
      }
    }, 2000);
  }
  componentWillUnmount() {
    this._isLive = false;
  }
  showNoDocAlert() {
    //alert("Kindly upload all your Financial documents");
    AddModal("Financial Documents", "Kindly upload all your Financial documents.", null, false);

  }
  getDueDate = (invoiceDate, net) => {
    let invDate = localDateFormat(invoiceDate);
    let netDays = 0;
    if (net === 1) {
      netDays = 30;
    } else if (net === 2) {
      netDays = 45;
    } else if (net === 3) {
      netDays = 60;
    } else if (net === 4) {
      netDays = 90;
    } else if (net === 6) {
      netDays = 180;
    } else {
      netDays = 120;
    }
    return moment(new Date(invDate))
      .add(netDays, "days")
      .format("MM-DD-YYYY");
  };

  render() {
    const {
      index,
      _id,
      total,
      status,
      paymentTerm,
      createdAt,
      net,
      invoiceNum,
      buyer,
      buyerName,
      customerDetails,
      supplier,
      documentDate,
    } = this.props;
    const { isExpanded, buyerStatus } = this.state;
    const conditionForChangingOffer = status === 4 || status === 5;
    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <div className="bg-white-1 wmy-2">
                  <div className="card-body">
                    <div className="row d-flex align-self-center">
                      <div className="col-2 align-self-center text-muted cusName">
                        {appState.state.currentUserRole !== "supplier" && (
                          <React.Fragment>
                            { supplier && supplier.name && (
                              <label className="namelbl">{supplier.name}</label>
                            )}
                            <div>{invoiceNum !== null ? invoiceNum : _id}</div>
                          </React.Fragment>
                        )}
                        {appState.state.currentUserRole == "supplier" && (
                          <React.Fragment>
                            <label className="namelbl">{(buyer && buyer.name) ? buyer.name :
                              ((customerDetails && customerDetails.name) ? customerDetails.name : buyerName)}</label>
                            <div>{invoiceNum !== null ? invoiceNum : _id}</div>
                          </React.Fragment>
                        )}
                      </div>
                      <div className="col-2 align-self-center text-center">
                        <strong>{dollarFormatter.format(total)}</strong>
                      </div>
                      <div className="col-2 align-self-center text-center">
                        <React.Fragment>
                          Invoice Date:{" "}
                          <strong>
                            {/* {moment.utc(createdAt).format("MM-DD-YYYY")} */}
                            {localDateFormat(documentDate)}
                            
                          </strong>
                          <br />
                          {paymentTerm && (
                            <div>
                              Due Date:{" "}
                              <strong>
                                {localDateFormat(paymentTerm)}
                              </strong>
                            </div>
                          )}
                          {net && (
                            <div>
                              <strong className="text-muted">
                                <PaymentTerm status={net} />
                              </strong>
                            </div>
                          )}
                        </React.Fragment>
                      </div>
                      <div className="col-3 align-self-center text-center">
                        {appState.state.currentUserRole === "funder" &&
                          status === 6 ? (
                          <span>
                            Buyer Approval ID: <br />
                            <small>{this.props.buyerApprovalId}</small>
                          </span>
                        ) : (
                          <StatusBadge
                            status={status}
                            currentUserRole={appState.state.currentUserRole}
                          />
                        )}
                      </div>
                      <div
                        className="col-1 align-self-center text-center"
                        onClick={() =>
                          this.setState({ isExpanded: !isExpanded })
                        }
                      >
                        {isExpanded ? (
                          <FontAwesomeIcon icon={faChevronUp} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronDown} />
                        )}
                      </div>
                      <div className="col-2 d-flex justify-content-center align-self-center">
                        <div className="btn-group actionBtn">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Actions <FontAwesomeIcon icon={faSortDown} />
                          </button>
                          <div className="dropdown-menu">
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => {
                                if (this.props.callback) {
                                    this.props.callback("viewinvoicemodal", index);
                                }
                            }}
                            >
                              View Invoice
                            </button>
                            {appState.state.currentUserRole === "supplier" &&
                              status === 0 &&
                              moment().isBefore(
                                this.getDueDate(createdAt, net)
                              ) && (
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#submitInvoiceModal"
                                  onClick={() =>
                                    appState.setState(
                                      "selectedInvoiceIndex",
                                      index
                                    )
                                  }
                                >
                                  Submit Invoice
                                </button>
                              )}
                            {(appState.state.currentUserRole === "funder") &&
                              status >= 4 && (
                                <div>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      this.setState({
                                        isOpenedInvoiceMessage: true
                                      });
                                    }}
                                  >
                                    Message Supplier
                                </button>
                                </div>
                              )}
                            {(appState.state.currentUserRole === "supplier") &&
                              status >= 4 && (
                                <div>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      this.setState({
                                        isOpenedInvoiceMessage: true
                                      });
                                    }}
                                  >
                                    Message Funder
                                </button>
                                </div>
                              )}
                            {appState.state.currentUserRole === "supplier" &&
                              status === 2 &&
                              appState.state.documentationExists.fileExists &&
                              moment().isBefore(paymentTerm) && (
                                appState.state.documentationExists.fileExists.FinancialDoc && appState.state.documentationExists.fileExists.AgingDoc ?
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#createFundingRequestModal"
                                    onClick={({ }) =>
                                      appState.setState(
                                        "selectedInvoiceIndex",
                                        index
                                      )
                                    }
                                  >
                                    Create Funding Request
                                  </button>
                                  :<button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={this.showNoDocAlert.bind(this)}
                                  >
                                    Create Funding Request
                                  </button>
                            )}
                            {appState.state.currentUserRole === "supplier" &&
                              conditionForChangingOffer && (
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#matchedFundersModal"
                                  onClick={() =>
                                    appState.setState(
                                      "selectedInvoiceIndex",
                                      index
                                    )
                                  }
                                >
                                  View Matched Funders
                                </button>
                              )}
                            {appState.state.currentUserRole === "supplier" &&
                              status === 5 && (
                                <React.Fragment>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#acceptFundRequestModal"
                                    onClick={() =>
                                      appState.setState(
                                        "selectedInvoiceIndex",
                                        index
                                      )
                                    }
                                  >
                                    View Funding Offers
                                  </button>
                                </React.Fragment>
                              )}
                            {appState.state.currentUserRole === "supplier" &&
                              status > 5 && (
                                <React.Fragment>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      appState.setState(
                                        "selectedInvoiceIndex",
                                        index
                                      );
                                      setTimeout(function () {
                                        appState.state.viewSignedRPAAsSupplier();
                                      }, 500);
                                    }}
                                  >
                                    View Signed Funding Agreement
                                  </button>
                                </React.Fragment>
                              )}
                            {appState.state.currentUserRole === "funder" &&
                              status >= 5 && (
                                <React.Fragment>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    onClick={() => {
                                      appState.setState(
                                        "selectedInvoiceIndex",
                                        index
                                      );
                                      if (status === 5) {
                                        setTimeout(function () {
                                          appState.state.viewUnsignedRPAAsFunder();
                                        }, 100);
                                      } else {
                                        setTimeout(function () {
                                          appState.state.viewSignedRPAAsFunder();
                                        }, 100);
                                      }
                                    }}
                                  >
                                    {status === 5
                                      ? "View Funding Agreement"
                                      : "View Signed Funding Agreement"}
                                  </button>
                                </React.Fragment>
                              )}
                            {appState.state.currentUserRole === "buyer" && (
                              <React.Fragment>
                                {status === 1 && (
                                  <React.Fragment>
                                    <button className="dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        if (this.props.callback) {
                                          this.props.callback("approveInvoice", index);
                                        }
                                      }}
                                    >
                                       Approve Invoice
                                    </button>
                                    <button className="dropdown-item"
                                      type="button"
                                      onClick={() => {
                                        if (this.props.callback) {
                                          this.props.callback("rejectInvoice", index);
                                        }
                                      }}
                                    >
                                      Reject Invoice
                                    </button>
                                  </React.Fragment>
                                )}
                                {status === 7 && (
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#payInvoiceModal"
                                    onClick={() =>
                                      appState.setState(
                                        "selectedInvoiceIndex",
                                        index
                                      )
                                    }
                                  >
                                    Pay Invoice
                                  </button>
                                )}
                              </React.Fragment>
                            )}
                            {appState.state.currentUserRole === "funder" &&
                              status === 4 && (
                                <React.Fragment>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#fundInvoiceModal"
                                    onClick={() =>
                                      appState.setState(
                                        "selectedInvoiceIndex",
                                        index
                                      )
                                    }
                                  >
                                    Make Offer
                                  </button>
                                </React.Fragment>
                              )}
                          </div>
                        </div>
                      </div>

                    </div>
                    {isExpanded && (
                      <div className="row mt-3 d-flex align-self-center">
                        <div className="col align-self-center">
                          {appState.state.currentUserRole === "supplier" && (
                            <Stepper
                              circleFontSize="6"
                              completeColor="#002f53"
                              activeColor="#002f53"
                              defaultColor="#fff"
                              defaultBorderColor="#e0e0e0"
                              defaultBorderStyle="solid"
                              defaultBorderWidth={1}
                              steps={[
                                {
                                  title: "Awaiting Submission",
                                  icon: CheckmarkIcon
                                },
                                {
                                  title: "Awaiting Buyer Approval",
                                  icon: CheckmarkIcon
                                },
                                { title: buyerStatus, icon: CheckmarkIcon },
                                {
                                  title:
                                    appState.state.currentUserRole === "buyer"
                                      ? "Supplier Created Funding Request to External Funders"
                                      : "Funding Request Created",
                                  icon: CheckmarkIcon
                                },
                                {
                                  title:
                                    appState.state.currentUserRole === "buyer"
                                      ? "Supplier Received Funding Offers"
                                      : appState.state.currentUserRole ===
                                        "funder"
                                        ? "Funding Offer Received"
                                        : "Funding Offers Received",
                                  icon: CheckmarkIcon
                                },
                                {
                                  title:
                                    "Supplier Accepted Funding Offer; Awaiting Buyer Payment to Funder",
                                  icon: CheckmarkIcon
                                },
                                { title: "Buyer Paid", icon: CheckmarkIcon }
                              ]}
                              activeStep={getStepForTimeline(status)}
                            />
                          )}
                          {appState.state.currentUserRole === "buyer" && (
                            <Stepper
                              circleFontSize="6"
                              completeColor="#002f53"
                              activeColor="#002f53"
                              defaultColor="#fff"
                              defaultBorderColor="#e0e0e0"
                              defaultBorderStyle="solid"
                              defaultBorderWidth={1}
                              steps={[
                                {
                                  title: "Awaiting Buyer Approval",
                                  icon: CheckmarkIcon
                                },
                                { title: buyerStatus, icon: CheckmarkIcon },
                                {
                                  title:
                                    appState.state.currentUserRole === "buyer"
                                      ? "Supplier Created Funding Request to External Funders"
                                      : "Funding Request Created",
                                  icon: CheckmarkIcon
                                },
                                {
                                  title:
                                    "Supplier Accepted Funding Offer; Awaiting Buyer Payment to Funder",
                                  icon: CheckmarkIcon
                                },
                                { title: "Buyer Paid", icon: CheckmarkIcon }
                              ]}
                              activeStep={getStepForBuyerTimeline(status)}
                            />
                          )}
                          {appState.state.currentUserRole === "funder" && (
                            <Stepper
                              circleFontSize="6"
                              completeColor="#002f53"
                              activeColor="#002f53"
                              defaultColor="#fff"
                              defaultBorderColor="#e0e0e0"
                              defaultBorderStyle="solid"
                              defaultBorderWidth={1}
                              steps={[
                                {
                                  title:
                                    appState.state.currentUserRole === "buyer"
                                      ? "Supplier Created Funding Request to External Funders"
                                      : "Funding Request Created",
                                  icon: CheckmarkIcon
                                },
                                {
                                  title:
                                    appState.state.currentUserRole === "buyer"
                                      ? "Supplier Received Funding Offers"
                                      : appState.state.currentUserRole ===
                                        "funder"
                                        ? "Funding Offer Received"
                                        : "Funding Offers Received",
                                  icon: CheckmarkIcon
                                },
                                {
                                  title:
                                    "Supplier Accepted Funding Offer; Awaiting Buyer Payment to Funder",
                                  icon: CheckmarkIcon
                                },
                                { title: "Buyer Paid", icon: CheckmarkIcon }
                              ]}
                              activeStep={getStepForFunderTimeline(status)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {
                      (this.state.isOpenedInvoiceMessage) &&
                      <MessageModal
                        messageType="invoice"
                        title={`Message Invoice: #${this.props.invoiceNum}`}
                        item={this.props}
                        recipientId={this.state.autoMessageRecipient}
                        userRole={appState.state.currentUserRole}
                        onClose={() => {
                          this.setState({
                            isOpenedInvoiceMessage: false
                          });
                        }} />
                    }
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default withRouter(InvoiceListItem);

const StatusBadge = ({ status, currentUserRole }) => {
  switch (status) {
    case 0:
      return (
        <span className="badge badge-pill-1 badge-secondary p-2">
          Awaiting Submission
        </span>
      );
    case 1:
      return (
        <span className="badge badge-pill-1 badge-warning p-2">
          Awaiting Buyer Approval
        </span>
      );
    case 2:
      return (
        <span className="badge badge-pill-1 badge-success p-2">
          Buyer Approved
        </span>
      );
    case 3:
      return (
        <span className="badge badge-pill-1 badge-danger p-2">
          Buyer Rejected
        </span>
      );
    case 4:
      return (
        <span className="badge badge-pill-1 badge-primary p-2">
          {currentUserRole === "buyer"
            ? "Supplier Created Funding Request to External Funders"
            : "Funding Request Created"}
        </span>
      );
    case 5:
      return (
        <span className="badge badge-pill-1 badge-success p-2">
          {currentUserRole === "buyer"
            ? "Supplier Received Funding Offers"
            : currentUserRole === "funder"
              ? "Funding Offer Made"
              : "Funding Offer Received"}
        </span>
      );
    case 6:
      return (
        <span className="badge badge-pill-1 badge-success p-2">Buyer Paid</span>
      );
    case 7:
      return (
        <span className="badge badge-pill-1 badge-primary p-2">
          Supplier Accepted Funding Offer;
          <br />
          Awaiting Buyer Payment to Funder
        </span>
      );
    case 99:
      return (
        <span className="badge badge-pill-1 badge-primary p-2">
          {currentUserRole === "buyer"
            ? "Supplier Created Funding Request to External Funders"
            : "Funding Request Created"}
        </span>
      );
    case 100:
      return (
        <span className="badge badge-pill-1 badge-success p-2">Buyer Paid</span>
      );
    case 101:
      return (
        <span className="badge badge-pill-1 badge-primary p-2">
          Supplier Accepted Funding Offer;
          <br />
          Awaiting Buyer Payment to Funder
        </span>
      );
    default:
      return (
        <span className="badge badge-pill-1 badge-primary p-2">
          Buyer Approved
        </span>
      );
  }
};

const PaymentTerm = ({ status }) => {
  switch (status) {
    case 1:
      return <span>Net 30</span>;
    case 2:
      return <span>Net 45</span>;
    case 3:
      return <span>Net 60</span>;
    case 4:
      return <span>Net 90</span>;
    case 5:
      return <span>Net 120</span>;
    case 6:
      return <span>Net 180</span>;
    default:
      return <span>Net 30</span>;
  }
};
