import React from "react";
import "./FormErrors.css";
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
export const FormErrors = ({ formErrors }) => (
  <div className="formErrors">
    {Object.keys(formErrors).map((fieldName, i) => {
      if (formErrors[fieldName].length > 0) {
        return (
          <p key={i} className="form-error">
            {formErrors[fieldName]}
          </p>
        );
      } else {
        return "";
      }
    })}
  </div>
);

export const renderPasswordValidation = (passwordValidation, t) => {
  return (
    <div className="alert alert-light mt-2 p-2" role="alert">
      <span>{t("password_criterias.password_must_include")}</span>
      {[
        { isValid: passwordValidation.lengthValid, message: t("password_criterias.8-20 characters") },
        { isValid: passwordValidation.capitalValid, message: t("password_criterias.at_least_one_capital_letter") },
        { isValid: passwordValidation.numberValid, message: t("password_criterias.at_least_one_number") },
        { isValid: passwordValidation.noSpaces, message: t("password_criterias.no_spaces") }
      ].map((item, index) => renderValidationMessage(item.isValid, item.message))}
    </div>
  );
};

export const renderValidationMessage = (isValid, message) => {
  return (
    <div style={{ color: isValid ? "green" : "red", display: "flex", alignItems: "center" }}>
      {isValid ? <TaskAltSharpIcon/> : <ErrorOutlineSharpIcon/>}
      <span style={{ marginLeft: "8px" }}>{message}</span>
    </div>
  );
};
