import React, { Component } from 'react';
import Popup from '../../Auth/Popup';
import { Bar } from 'react-chartjs-2';
import API from '../../../../services/API';
import { shortDollarFormatter } from '../../../../services/Utilities';
import { withTranslation } from 'react-i18next';
import CommonPopUpModal from '../../../CommonPopUpModal/CommonPopUpModal';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement
  } from 'chart.js';

// Register the necessary components
ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    BarElement
);

class DocumentsWidgetViewMore extends Component {
    api=new API();
    chartOptions = {
        maintainAspectRatio: true,
        scales: {
          y: {
            ticks: {
              callback: function (value, index, values) {
                return shortDollarFormatter.format(value);
              }
            },
            stacked: true,
            grouped: false,
          },
          x:
          {
            stacked: true,
            // title: {
            //   display: true,
            //   text: 'In Dollars',
            //   align: 'center',
            //   color: "#4F4F4F",
            //   font: {
            //     size: 14,
            //     weight: 900,
            //     family: "Cario",
            //   }
            // },
          },
        },
      layout: {
        padding: {
          left:20,
          right:20,
          bottom:25,
          top:10
        }
      }
      };
    constructor(props_){
        super(props_);
        this.state={
            data:props_.data,
            invoices:props_.invoices,
            chartData:null,
            chartOptions:null,
            customers:[]
        }
    }
    componentDidMount(){
        this.getDocumentTypeChart("All");
    }

    async getDocumentTypeChart(buyer) {
        const {invoices}=this.state;
        let buyers=invoices.map(docs => {
            if(docs.documentType != "TCF"){
                return  docs.buyerName ? docs.buyerName : docs.invoice.buyerName;
            }
        }).filter(inv=>inv);
        buyers=[...new Set(buyers)].sort();
        if (buyer == "All") {
            this.setState({ chartData: this.state.data,customers:buyers });
        }
        else{
            let amountGroupedByStatus = invoices.filter(docs => {
               
                if(docs.documentType != "TCF"){
                    let customerName = docs.buyerName ? docs.buyerName : docs.invoice.buyerName;
                    if( customerName==buyer){
                        return docs;
                    }
                }
            }).reduce((cus, docs) => {
                let status = docs.status;
    
                if (status != 99 && status != 100) {
                    status = 0;
                }
    
                if (!cus[docs.documentType]) {
                    cus[docs.documentType]={}
                    if(!cus[docs.documentType][status]){
                      cus[docs.documentType][status] = 0;
                    }
                }
                cus[docs.documentType][status] = (cus[docs.documentType][status] ? cus[docs.documentType][status] : 0) + docs.total;
                return cus;
            }, {});
            this.setState({customers:buyers,chartData:{
                labels: [this.props.t("dashboard_page.transactions_content.type_lables.invoice"), this.props.t("dashboard_page.transactions_content.type_lables.po")],
                datasets: [
                  {
                    label: '$ of Open',
                    data: [
                      amountGroupedByStatus["Invoice"] && amountGroupedByStatus["Invoice"]["0"]  ? amountGroupedByStatus["Invoice"]["0"] : 0,
                      amountGroupedByStatus["PO"] && amountGroupedByStatus["PO"]["0"] ? amountGroupedByStatus["PO"]["0"] : 0
                    ],
                    backgroundColor: 'rgb(255,180,91)',
                    stack:1
                  },
                  {
                    label: '$ of In Funding',
                    data: [
                      amountGroupedByStatus["Invoice"] && amountGroupedByStatus["Invoice"]["99"] ? amountGroupedByStatus["Invoice"]["99"] : 0,
                      amountGroupedByStatus["PO"] && amountGroupedByStatus["PO"]["99"] ? amountGroupedByStatus["PO"]["99"] : 0
                    ],
                    backgroundColor: 'rgb(79,152,79)',
                    stack:1
                  },
                  // {
                  //   label: '$ of Archived',
                  //   data: [
                  //     amountGroupedByStatus["Invoice"] && amountGroupedByStatus["Invoice"]["100"] ? amountGroupedByStatus["Invoice"]["100"] : 0,
                  //     amountGroupedByStatus["PO"] && amountGroupedByStatus["PO"]["100"] ? amountGroupedByStatus["PO"]["100"] : 0
                  //   ],
                  //   backgroundColor: 'rgb(37,95,121)',
                  //   stack:1
                  // },
                ],
              }});
        }
    }

    render(){
        const{chartData,chartoptions,customers}=this.state;
        const {t}=this.props;
        return (
            <CommonPopUpModal
                title={t("dashboard_page.widget_titles.transaction_status")}
                onClose={e => this.props.onClose()}
                size='medium'
                promptOnClose={false}
            >
                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <label className="form-label">{t("dashboard_page.transactions_content.select_customer")}</label>
                            <select
                                id="documentSelect"
                                className="form-control"
                                onChange={e =>
                                    this.getDocumentTypeChart(e.target.value)
                                }
                            >
                                <option value="All">{t("grid_view_filter_names.all")}</option>
                                {customers.map(cus => {
                                    return <option value={cus}>{cus}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {
                            chartData &&
                            <div >
                                <Bar data={chartData} options={this.chartOptions} />
                            </div>
                        }
                    </div>
                </div>
            </CommonPopUpModal>
        )
    }
}

export default withTranslation() (DocumentsWidgetViewMore);