import React, { Component } from 'react'
import Popup from '../../../common/Auth/Popup'
import { dollarFormatter, localDateFormat, addDaysInSufixForPaymentTerm } from '../../../../services/Utilities';
import User from '../../../../services/User';
import { API_ENDPOINT_SUPPLIER } from '../../../../services/API';
import FileViewerModal from '../../../common/FileViewerModal/FileViewerModal';
import { compareRole, enumRole } from '../../../../configs/GlobalConfig';
import CommonPopUpModal from '../../../CommonPopUpModal/CommonPopUpModal';
import UserDetails from '../../../common/User/UserDetails';
import FileMetaDataPopover from '../../../common/PopOver/FileMetaDataPopover';

export default class ViewInvoiceModal extends Component {
    user = new User();

    constructor(props_) {
        super(props_);
        this.state = {
            invoice: props_.data,
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false
        }
    }

    getInvoiceNumber(invoice) {
        if (invoice.documentId) {
            return invoice.documentId
        } else {
            return invoice._id
        }
    }

    render() {
        const { invoice } = this.state;
        return (
            <React.Fragment>
                {this.state.isFileViewerModal && this.state.fileViewerContent &&
                    <FileViewerModal
                        onClose={() => {
                            this.setState({ isFileViewerModal: false, fileViewerContent: null });
                        }}
                        data={this.state.fileViewerContent}
                        isFinancialDocument={false}
                    ></FileViewerModal>
                }
                <CommonPopUpModal
                    title={`${this.props.t("invoice_details")}: #${this.getInvoiceNumber(invoice)}`}
                    onClose={e => this.props.onClose()}
                    promptOnClose={false}
                >
                    <div>
                        {(compareRole(this.user.currentUserRole(), enumRole.Buyer)) && (
                            <div>
                                <UserDetails userDetails={invoice.supplier} />
                            </div>
                        )}
                        <div className="row mt-3">
                            <div className="col-6">
                                <label className="due-date-label my-2">{this.props.t("grid_view_header_labels.invoice_date")}:  {localDateFormat(invoice.documentDate)}</label>
                            </div>
                            <div className="col-6 text-right">
                                <label className="due-date-label my-2">{this.props.t("grid_view_header_labels.due_date")}:  {localDateFormat(invoice.documentDueDate)}</label>
                            </div>
                            <div className="col-4">
                                <label className="due-date-label my-2">{this.props.t("invoice_items")}:</label>
                            </div>
                            <div className="col-4">
                                <div className='d-flex flex-column align-items-center'>
                                    <>
                                        {
                                            invoice.documentFiles &&
                                            invoice.documentFiles.map((document_, index) => {
                                                return <div className='d-flex view-invoice-file'><label className="due-date-label pt-2 mt-2"
                                                    key={index}>
                                                    <a key={index} className="anchorstyle due-date-label" href={""} onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            isFileViewerModal: true,
                                                            isFinancialDocument: false,
                                                            fileViewerContent: document_
                                                        })
                                                    }}>{this.props.t("view_invoice_file")} </a>
                                                </label>
                                                    <div className='pl-2 pt-2 mt-2'>
                                                        <FileMetaDataPopover
                                                            key={index}
                                                            data={document_}
                                                        />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </>
                                    <div className='d-flex view-invoice-file'>
                                        {
                                            invoice.buyerApprovedDocs &&
                                            invoice.buyerApprovedDocs.map((document_, index) => {
                                                return <><label className="due-date-label pb-2 mb-2"
                                                    key={index}>
                                                    <a key={index} className="anchorstyle due-date-label" href={""} onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({
                                                            isFileViewerModal: true,
                                                            isFinancialDocument: false,
                                                            fileViewerContent: document_
                                                        })
                                                    }}>{this.props.t("view_buyer_approval_file")}</a>
                                                </label>
                                                <div className='pl-2 pb-2 mb-2'>
                                                        <FileMetaDataPopover
                                                            key={index}
                                                            data={document_}
                                                        />
                                                    </div>
                                                </>
                                            })
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="col-4 text-right">
                                <label className="due-date-label my-2">
                                    {invoice.invoice.net && `${this.props.t("invoice_labels.payment_terms")}: ${addDaysInSufixForPaymentTerm(invoice.invoice.net)}`}
                                </label>
                            </div>
                            <div className="col-12">
                                <div className="table-responsive" style={{ overflow: "auto" }}>
                                    <table className="table">
                                        <thead className="thead-light" style={{ position: "sticky", top: 0 }}>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">{this.props.t("invoice_labels.product_service")}</th>
                                                <th scope="col">{this.props.t("invoice_labels.description")}</th>
                                                <th scope="col" className="text-right">{this.props.t("invoice_labels.quantity")}</th>
                                                <th scope="col" className="text-right">{this.props.t("invoice_labels.price")}</th>
                                                {invoice.invoice.extraTax > 0 && invoice.invoice.extraTax !== 0 && invoice.invoice.extraTax != undefined && (<th scope="col" className="text-right">{this.props.t("invoice_labels.tax")} (%)</th>)}
                                                <th className="text-right" scope="col">{this.props.t("invoice_labels.total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoice.invoice.items.map((item, i) => (
                                                <Row index={i} item={item} extraTax={invoice.invoice.extraTax} count={invoice.invoice.items.length} totalValue={invoice.total} t={this.props.t} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </CommonPopUpModal>
            </React.Fragment>
        );
    }
}

const Row = ({ index, item: { descr, qty, price, tax, subTotal, productName, item }, extraTax, count, totalValue, t }) => (
    <React.Fragment>
        <tr>
            <th scope="row">{index + 1}</th>
            <td>{productName ? productName : item ? item : ""}</td>
            <td>{descr}</td>
            <td className="text-right">{qty}</td>
            <td className="text-right">{price}</td>
            {extraTax > 0 && extraTax !== 0 && extraTax != undefined && (<td className="text-right">{!isNaN(parseFloat(tax)) ? dollarFormatter.format(parseFloat(tax).toFixed(3)) : ""}</td>)}
            <td className="text-right">{dollarFormatter.format(subTotal)}</td>
        </tr>
        {index == count - 1 && (
            <React.Fragment>
                {extraTax > 0 && extraTax !== 0 && extraTax != undefined && (
                    <tr>
                        <th></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {extraTax > 0 && extraTax !== 0 && extraTax != undefined && (<td className="text-right"><b>{t("invoice_labels.tax")}</b></td>)}
                        {extraTax > 0 && extraTax !== 0 && extraTax != undefined && (<td className="text-right">{!isNaN(parseFloat(extraTax)) ? dollarFormatter.format(parseFloat(extraTax).toFixed(3)) : ""}</td>)}
                    </tr>)}
                <tr className={extraTax > 0 && extraTax !== 0 && extraTax != undefined ? "tableTotal" : ""}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {extraTax > 0 && extraTax !== 0 && extraTax != undefined && (<td></td>)}
                    <td className="text-right"><b>{t("invoice_labels.grand_total")}</b></td>
                    <td className="text-right">{dollarFormatter.format(totalValue)}</td>
                </tr>
            </React.Fragment>
        )}
    </React.Fragment>
);