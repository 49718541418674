import React from "react";

import { withRouter } from "react-router-dom";
import "../List.css";
import AppState from "../../../../contexts/AppState/AppState";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {localDateFormatWithoutBracketTime} from "../../../../services/Utilities";
import { compareRole,enumRole } from "../../../../configs/GlobalConfig";
import NotificationService from "../../../../services/NotificationService";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isChangeManageUser,switchManageUser,isAdminForManagedUser } from "../../../../services/userImpersonationService";

function getInvoiceNumber(invoice) {
  if (invoice.invoiceNum) {
    return invoice.invoiceNum;
  } else {
    return invoice._id;
  }
}

const notificationContent = (type_, param_, t) => {
  switch (type_) {
    case 10:
      if (param_.title == "Transaction Volume Updated") {
        if(param_.message.indexOf("Weekly for funding contract")!=-1){
          if(param_.message.indexOf("QuickBooks Online")!=-1){
            return i18next.t("notifications_page.transaction_volume_weekly_contract_msg", { contractId:param_.id });
          }
          else if(param_.message.indexOf("Microsoft Dynamics 365")!=-1){
            return i18next.t("notifications_page.transaction_volume_weekly_d365Finance_msg", { contractId:param_.id });
          }
          else{
            return param_.message;
          }
        }
        else{
          return i18next.t("notifications_page.qbo_transaction_volume_msg", { contractId:param_.id });
        }
      }
      else if(param_.title == "Receivables Aging Details Report Updated"){
        if(param_.message.indexOf("Weekly for funding contract")!=-1){
          return i18next.t("notifications_page.receivables_aging_weekly_contract_msg", { contractId:param_.id });
        }
        else{
          return i18next.t("notifications_page.receivables_aging_qbo_msg", { contractId:param_.id });
        }
      }
      else if(param_.title == "Payables Aging Details Report Updated"){
        if(param_.message.indexOf("Weekly for funding contract")!=-1){
          return i18next.t("notifications_page.payables_aging_weekly_contract_msg", { contractId:param_.id });
        }
        else{
          return i18next.t("notifications_page.payable_aging_qbo_msg", { contractId:param_.id });
        }
      }
      else {
        return param_.message;
      }
    case 102:
    case 201:
      if(param_.title=="Supplier Accepted Funding Offer"){
        return i18next.t("notifications_page.change_in_payment_info", { documentId: param_.invoiceId?param_.invoiceId:"", date:param_.date?param_.date:"" });
      }
      else{
        return i18next.t("notifications_page.invoice_submitted_msg", { documentId: param_.id, name: param_.supplierName ? param_.supplierName : "" });
      }
    case 202:
      const _approverName = param_.message.split("for supply chain was approved by");
      return i18next.t("notifications_page.supply_chain_approved_msg", { documentId: param_.id, name: _approverName[1] });
    case 501:
      if (param_.message.indexOf("review and you may select the matched funders now.") != -1) {
        return i18next.t("notifications_page.funding_request_reviewed_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
      }
      else {
        return i18next.t("notifications_page.funding_request_review_for_admin_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
      }
    case 502:
    case 503:
      if (param_.message.indexOf("waiting for accept the funding request") != -1) {
        return i18next.t("notifications_page.funding_request_waiting_for_accept_offer_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
      }
      else {
        return i18next.t("notifications_page.funding_request_waiting_for_offer_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
      }
    case 504:
      if (param_.title == "Funding Offer is Accepted") {
        return i18next.t("notifications_page.funding_offer_accepted_msg_content", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
      }
      else if(param_.title=="Admin selected the matched funders"){
        return i18next.t("notifications_page.admin_selected_funding_request_to_requester_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
      }
      else {
        return i18next.t("notifications_page.funding_offer_received_msg_content", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
      }
    case 505:
      const _data=param_.message.split("accepted other funding offer for the funding request");
      return i18next.t("notifications_page.accepted_other_funding_offer_msg", {name:_data[0], fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
    case 506:
      return i18next.t("notifications_page.funding_agreement_signed_msg_content", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
    case 507:
      const _name = param_.message.indexOf("Supplier") != -1 ? "supplier_signed_agreement_msg" : "buyer_signed_agreement_msg";
      return i18next.t(`notifications_page.${_name}`, { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
    case 508:
      return i18next.t("notifications_page.funder_marked_as_paid_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
    case 510:
      return i18next.t("notifications_page.funding_request_approved_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
    case 511:
      const _declineName=param_.message.split("declined the following funding request");
      return i18next.t("notifications_page.declined_funding_request_msg", { name:_declineName[0],fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
    case 550:
      return i18next.t("notifications_page.funding_request_updated_msg", { fundingReqId: param_.id ? param_.id : param_.fundingRequestId });
    case 601:
      const _nameData = param_.message.split("account is disabled by LedgerFunding");
      return i18next.t("notifications_page.disabled_account_msg", { name: _nameData[0]});
    case 701:
      const _terminatedData = param_.message.split("has been terminated by the");
      let _role = (param_ && param_.role ? param_.role : _terminatedData[1]).toLowerCase();
      if (_role == "supplier") {
        _role = i18next.t("roles.supplier");
      }
      else if (_role == "buyer") {
        _role = i18next.t("roles.buyer");
      }
      else {
        _role = i18next.t("roles.funder");
      }
      return i18next.t("notifications_page.terminate_contract_msg", { contractID: param_ && param_.contractID ? param_.contractID : _terminatedData[0], role: _role });
    case 702:
      return i18next.t("notifications_page.supplier_financial_document_updated_content", { contractId: param_ && param_.id?param_.id:"" });
    default:
      return param_.message;
  }
}

const notificationTitle = (title_, t) => {
  switch (title_) {
    case "Funding Request Reviewed":
      return t("notifications_page.funding_request_reviewed");
    case "Funding Agreement":
      return t("notifications_page.funding_agreement");
    case "Funding Offer is Received":
      return t("notifications_page.funding_offer_is_received");
    case "Funding Request is Approved":
      return t("notifications_page.funding_request_approved");
    case "Funder Marked as Paid":
      return t("notifications_page.funder_marked_as_paid");
    case "Signed Agreement":
      return t("notifications_page.signed_agreement");
    case "Funding Offer is Accepted":
      return t("notifications_page.funding_offer_accepted");
    case "Funding Request Created":
      return t("notifications_page.funding_request_created");
    case "Funding Request Reviewed":
      return t("notifications_page.funding_request_reviewed");
    case "Invoice submitted for approval":
      return t("notifications_page.invoice_submitted_for_approval");
    case "Supply Chain Approved":
      return t("notifications_page.supply_chain_approved");
    case "Accepted other funding offer":
      return t("notifications_page.accepted_other_funding_offer");
    case "Funding request mapped into existing contract":
      return t("notifications_page.funding_request_mapped_into_existing_contract");
    case "Admin selected the matched funders":
      return t("notifications_page.admin_selected_the_matched_funders");
    case "Contract has been terminated":
      return t("notifications_page.contract_has_been_terminated");
    case "Supplier Financial Documents Updated":
      return t("notifications_page.supplier_financial_documents_updated");
    case "Aging Report Updated":
      return t("notifications_page.aging_report_updated");
    case "Transaction Volume Updated":
      return t("notifications_page.transaction_volume_updated");
    case "Financial documents and annual sales":
      return t("notifications_page.financial_documents_and_annual_sales");
    case "Payables Aging Report Updated":
      return t("notifications_page.payables_aging_report_updated");
    case "Receivables Aging Details Report Updated":
      return t("notifications_page.receivables_aging_details_report_updated");
    case "Payables Aging Details Report Updated":
      return t("notifications_page.payables_aging_details_report_updated");
    case "Requester account is disabled":
      return t("notifications_page.requester_account_is_disabled");
    case "Invoice for supply chain submitted for approval":
      return t("notifications_page.invoice_for_supply_chain_submitted_for_approval");
    case "Funding Request is Declined":
      return t("notifications_page.funding_request_is_declined");
    case "Supplier registered in Supplier portal":
      return t("notifications_page.supplier_registered_in_supplier_portal");
    case "Invoice values adjusted for funding request":
      return t("notifications_page.invoice_values_adjusted_for_funding_request");
    case "Funding request updated":
      return t("notifications_page.funding_request_updated");
    case "Supplier Accepted Funding Offer":
      return t("status_label.supplier_accepted_funding_offer");
    default:
      if(title_.indexOf("New Message from")!=-1){
        const _messagerName=title_.split("New Message from");
        return t("notifications_page.new_message_from",{name:_messagerName[1]});
      }
      else{
        return title_;
      }

  }
}

const NotificationListItem = ({
  createdAt,
  type,
  read,
  params,
  history,
  userType,
  userBy,
  businessName,
  user,
  createdByDetails,
  isCreatedByRepresentative
}) => {
  const {t}=useTranslation();
  const _localStorageManageUsers = localStorage.getItem("managedUsers")
  return (
  <div className="row">
    <div className="col">
      <AppState.Consumer>
        {appState => (
          <div
            className="card my-2 notification-card"
            onClick={async() => {
              const _isAdmin =isAdminForManagedUser();
              let _isChangeUserDetails = await isChangeManageUser(_isAdmin && createdByDetails && createdByDetails._id && createdByDetails.name?createdByDetails._id:user);
              if(_isChangeUserDetails.isChangeUser){
                await switchManageUser(createdByDetails && createdByDetails._id?createdByDetails._id:user)
              }
              if (_isChangeUserDetails.isAdmin || _isChangeUserDetails.isChangeUser) {
                if (type > 100 && type <= 150) {
                  NotificationService.subscribe(params.id ? params.id : params.invoiceId);
                  history.push("/document/InvoiceFinancing");
                }
                else if (type > 150 && type <= 200) {
                  NotificationService.subscribe(params.id ? params.id : params.invoiceId);
                  history.push("/document/POFinancing");
                }
                else if (type > 200 && type <= 250) {
                  NotificationService.subscribe(params.id ? params.id : params.invoiceId);
                  history.push("/document/SupplyChain");
                }
                else if (type > 500 && type <= 700) {
                  if (type != 601) {
                    NotificationService.subscribe(params.id ? params.id : params.fundingReqId);
                    if (type == 600) {
                      NotificationService._isMessageModal = true;
                    }
                  }
                  history.push(`/fundingrequest`);
                }
                else if (type > 700) {
                  //NotificationService.subscribe(params.id);
                  history.push(`/fundingcontract`);
                }
                else if (type == 1) {
                  history.push('/profile/financialInformation');
                }
                else if (type === 6 || type === 10) {
                  appState.setState("isNavFromNotifications", true);
                  history.push("/dashboard");
                }
                else {
                  appState.setState("isNavFromNotifications", true);
                  history.push("/document");
                }
                if(_isChangeUserDetails.isChangeUser){
                  window.location.reload();
                }
              }
            }}
          >
            <div className="card-body">
              <div className="row d-flex align-self-center">

                  <div className="col-12 align-self-center">
                    <p>
                      {(compareRole(userType, enumRole.Funder) && createdByDetails && createdByDetails.adminName) && (
                        <span className="badge badge-pill badge-primary p-2 mr-2">
                          {`${createdByDetails.adminName} ${t("on_behalf_of")} ${ createdByDetails.name}`}
                        </span>
                      )}
                      {(type == 103 || type == 104 || type ==151 ||type==203 ) ? (<>
                        <p>
                          {(isAdminForManagedUser() && _localStorageManageUsers && businessName) && (
                            <span className="badge badge-pill badge-primary p-2 mr-2">
                              {businessName}
                            </span>
                          )}
                          <NotificationLabel type={type} userType={userType} t={t}/>
                          {read == true ? <CheckCircleIcon htmlColor={'#008000'} /> : ""}
                          <p> {`${t("invoice_labels.invoice_id")}:`} {params.invoiceId?params.invoiceId:params.id}</p>
                        </p>
                      </>) : (<>
                          {(isAdminForManagedUser() && _localStorageManageUsers && businessName) && (
                            <span className="badge badge-pill badge-primary p-2 mr-2">
                              {businessName}
                            </span>
                          )}
                        <span className="badge badge-pill badge-success p-2">
                         {notificationTitle(params.title,t)}
                        </span>
                        {read == true ? <CheckCircleIcon htmlColor={'#008000'} /> : ""}
                        <p>{notificationContent(type, params,t)}</p>
                      </>)}
                    </p>
                    <p className="text-muted">
                      {localDateFormatWithoutBracketTime(createdAt)}
                    </p>
                  </div>
                {/* {
                  ((((type >= 100 && userType!="buyer") || ((type ==102 || type==103) && userType=="buyer")) && type!=301) || (type==101 && userType=="buyer") || (type==104 || type==105 || (type==101 && params && params.title=="Payables Aging Report Updated") || (type==101 && params && params.title=="Supplier registered in Supplier portal"))) ?
                  
                    <div className="col-12 align-self-center">
                      <p>
                        <>
                          <span className="badge badge-pill badge-success p-2">
                            {params.title}
                          </span>
                          {read == true?<CheckCircleIcon htmlColor={'#008000'} /> :""}
                        </>
                      </p>
                      <p>{notificationContent(type, params)}</p>
                      <p className="text-muted">
                        {localDateFormatWithoutBracketTime(createdAt)}
                      </p>
                    </div>

                    : 
                     <div className="col-12 align-self-center">
                      
                        {type == 100 && userType == "buyer" ? (
                          <>
                          <p>
                            <span className="badge badge-pill badge-success p-2">
                              {params.title}
                            </span>
                            </p>
                            <p>{notificationContent(type, params)}</p>
                          </>
                        ) :
                          <p>
                            <NotificationLabel type={type} userType={userType} />
                            {read == true ? <CheckCircleIcon htmlColor={'#008000'} /> : ""}
                            <p> {type >=300 ?"Purchase Order # :":"Invoice ID:"} {params.invoiceId}</p>
                            </p>}
                      
                      
                      <p className="text-muted">
                        {localDateFormatWithoutBracketTime(createdAt)}
                      </p>
                    </div>
                } */}
              </div>
            </div>
          </div>
        )}
      </AppState.Consumer>
    </div>
  </div>
  )
}

export default withRouter(NotificationListItem);

const NotificationLabel = ({ type, userType, t }) => {
  switch (type) {
    case 0:
      return (
        <span className="badge badge-pill badge-secondary p-2">
          {t("notifications_page.waiting_for_invoice_submit")}
        </span>
      );
    case 1:
      return (
        <span className="badge badge-pill badge-warning p-2">
          {t("notifications_page.invoice_received")}
        </span>
      );
    case 4:
      // if (userType !== "buyer") {
      //   return (
      //     <span className="badge badge-pill badge-primary p-2">
      //       Funding Request Created
      //     </span>
      //   );
      // } else {
      return (
        <span className="badge badge-pill badge-primary p-2">
          {t("notifications_page.supplier_received_funding_offers")}
        </span>
      );
    // }
    case 5:
      if (userType === "buyer") {
        return (
          <span className="badge badge-pill badge-success p-2">
            {t("notifications_page.supplier_received_funding_offers")}
          </span>
        );
      } else if (userType === "funder") {
        return (
          <span className="badge badge-pill badge-success p-2">
            {t("notifications_page.funding_offer_received")}
          </span>
        );
      } else {
        return (
          <span className="badge badge-pill badge-success p-2">
            {t("notifications_page.funding_offer_received")}
          </span>
        );
      }

    case 6:
      return (
        <span className="badge badge-pill badge-success p-2">{t("notifications_page.buyer_paid")}</span>
      );
    case 7:
      if (userType === "buyer") {
        return (
          <span className="badge badge-pill badge-primary p-2">
            {t("notifications_page.supplier_accepted_funding_offer_for_buyer")}
          </span>
        );
      } else {
        return (
          <span className="badge badge-pill badge-primary p-2">
            {t("notifications_page.supplier_accepted_funding_offer_for_buyer")}
          </span>
        );
      }
    case 8:
      if (userType === "buyer") {
        return (
          <span className="badge badge-pill badge-primary p-2">
            {t("notifications_page.supplier_accepted_funding_offer_for_buyer")}
          </span>
        );
      } else {
        return (
          <span className="badge badge-pill badge-success p-2">
            {t("notifications_page.funding_request_created")}
          </span>
        );
      }
    case 101:
      return (
        <span className="badge badge-pill badge-warning p-2">
          {t("notifications_page.invoice_received")}
        </span>
      );
    case 103:
      return (
        <span className="badge badge-pill badge-success p-2">
          {t("notifications_page.buyer_approved")}
        </span>
      );
    case 104:
      return (
        <span className="badge badge-pill badge-danger p-2">
         {t("notifications_page.buyer_rejected")}
        </span>
      );
    case 201:
      if (userType === "buyer") {
        return (
          <span className="badge badge-pill badge-primary p-2">
             {t("notifications_page.supplier_accepted_funding_offer_for_buyer")}
          </span>
        );
      }
    case 151:
      return (
        <span className="badge badge-pill badge-primary p-2">
          {t("notifications_page.purchase_order_submitted")}
        </span>
      );
    case 203:
      return (
        <span className="badge badge-pill badge-danger p-2">
          {t("notifications_page.supply_chain_rejected")}
        </span>
      );

    default:
      return (
        <span className="badge badge-pill badge-primary p-2">{t("notifications_page.pending")}</span>
      );
  }
};
