import React, { Component } from 'react';
import FullSpinner from '../Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import Popup from '../Auth/Popup';
import Dropzone from 'react-dropzone';
import { ShortText } from '../../../services/Utilities';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import FileUploader from '../FileUploader/FileUploader';
import FileViewerModal from '../FileViewerModal/FileViewerModal';
import { withTranslation } from 'react-i18next';

class FinancialStatementsModal extends Component {
    api = new API();
  
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        financeDocs: {
            balanceSheet: {
              fileName: "",
              downloadUrl: ""
            },
            incomeStmt: {
              fileName: "",
              downloadUrl: ""
            },
            cashflow: {
              fileName: "",
              downloadUrl: ""
            },
            agingfile: {
              fileName: "",
              downloadUrl: ""
            }
          },
          flagBalSta: false,
          flagIncSta: false,
          flagCasSta: false,
          isLoading: false,
          isFileViewerModal: false,
          fileViewerContent: null,
          isFinancialDocument: false
      };
      this.onDropFinancialDoc = this.onDropFinancialDoc.bind(this);
    }

    componentDidMount(){
        this.getSupplierDocs();
    }

    async getBalanceStatement(fileName, fileURL) {
        fetch(fileURL, {
            "method": "GET",
            "mode": "cors",
            "credentials": "include"
        })
            .then(r => r.blob())
            .then(b => {
                let file = new File([b], fileName);
                console.log("balance test", file);
                this.setState({ fileToUpload: file });
            });
    }
    async getIncomeStatement(fileName, fileURL) {
        fetch(fileURL, {
            "method": "GET",
            "mode": "cors",
            "credentials": "include"
        })
            .then(r => r.blob())
            .then(b => {
                let file = new File([b], fileName);
                console.log("balance test", file);
                this.setState({ fileToUpload1: file });
            });
    }
    async getCashFlowStatement(fileName, fileURL) {
        fetch(fileURL, {
            "method": "GET",
            "mode": "cors",
            "credentials": "include"
        })
            .then(r => r.blob())
            .then(b => {
                let file = new File([b], fileName);
                this.setState({ fileToUpload2: file });
            });
    }

    async getSupplierDocs() {
        const res = await this.api.getSupplierDocs();
    
        if (res.items && res.items.length > 0) {
          const { financeDocs } = this.state;
          let items = res.items.filter(
            x => x.financialDocumentType === "BALANCE_SHEET"
          );
          if (items.length > 0) {
            financeDocs.balanceSheet.fileName = items[0].filename;
            financeDocs.balanceSheet.downloadUrl = items[0].downloadUrl;
            financeDocs.balanceSheet.uploadDate=items[0].createdDate;
            this.getBalanceStatement(items[0].filename,items[0].downloadUrl)
          }
          items = res.items.filter(
            x => x.financialDocumentType === "INCOME_STATEMENT"
          );
          if (items.length > 0) {
            financeDocs.incomeStmt.fileName = items[0].filename;
            financeDocs.incomeStmt.downloadUrl = items[0].downloadUrl;
            financeDocs.incomeStmt.uploadDate=items[0].createdDate;
            this.getIncomeStatement(items[0].filename,items[0].downloadUrl)
          }
          items = res.items.filter(x => x.financialDocumentType === "CASH_FLOW");
          if (items.length > 0) {
            financeDocs.cashflow.fileName = items[0].filename;
            financeDocs.cashflow.downloadUrl = items[0].downloadUrl;
            financeDocs.cashflow.uploadDate=items[0].createdDate;
            this.getCashFlowStatement(items[0].filename,items[0].downloadUrl)
          }
          items = res.items.filter(x => x.financialDocumentType === "AGING_REPORT");
          if (items.length > 0) {
            financeDocs.agingfile.fileName = items[0].filename;
            financeDocs.agingfile.downloadUrl = items[0].downloadUrl;
            financeDocs.agingfile.uploadDate=items[0].createdDate;
          }
          this.setState({ financeDocs });
        }
      }

    onDropFinancialDoc(type, documents) {
        switch (type) {
          case "Balance_Sheet":
            this.setState({ fileToUpload: documents[0] });
            this.setState({ flagBalSta: true });
            console.log("drop down file", documents[0]);
            break;
          case "Income_Statement":
            this.setState({ fileToUpload1: documents[0] });
            this.setState({ flagIncSta: true });
            break;
          case "Cash_Flow_Statement":
            this.setState({ fileToUpload2: documents[0] });
            this.setState({ flagCasSta: true });
            break;
          case "Aging_Report":
            this.setState({ fileToUpload3: documents[0] });
            this.handleInvoiceLoad(documents);
            break;
        }
      }

    async uploadFinancialHistory() {
        const { fileToUpload, fileToUpload1, fileToUpload2 } = this.state;
        this.setState({ isLoading: true });
        const documentData = new FormData();
        documentData.append("BALANCE_SHEET", fileToUpload);
        documentData.append("INCOME_STATEMENT", fileToUpload1);
        documentData.append("CASH_FLOW", fileToUpload2);
        await this.api.financialDocHistoryUpload(documentData);
        this.setState({isLoading:false});
    }

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    // handleDelete = (docType) => {
    //     switch (docType) {
    //         case 'balanceSheet':
    //             this.setState({
    //                 financeDocs: {
    //                     ...this.state.financeDocs,
    //                     balanceSheet: {
    //                         fileName: "",
    //                         downloadUrl: ""
    //                     }
    //                 },
    //                 fileToUpload: null
    //             });
    //             break;
    //         case 'incomeStmt':
    //             this.setState({
    //                 financeDocs: {
    //                     ...this.state.financeDocs,
    //                     incomeStmt: {
    //                         fileName: "",
    //                         downloadUrl: ""
    //                     }
    //                 },
    //                 fileToUpload1: null
    //             });
    //             break;
    //         case 'cashflow':
    //             this.setState({
    //                 financeDocs: {
    //                     ...this.state.financeDocs,
    //                     cashflow: {
    //                         fileName: "",
    //                         downloadUrl: ""
    //                     }
    //                 },
    //                 fileToUpload2: null
    //             });
    //             break;
    //         default:
    //             break;
    //     }
    // }

    render() {
        const {
            financeDocs,
            fileToUpload,
            fileToUpload1,
            fileToUpload2,
            isLoading
        }=this.state;

        const isSubmitButtonEnabled = !(
            (fileToUpload && fileToUpload1 && fileToUpload2) &&
            (this.state.flagBalSta || this.state.flagCasSta || this.state.flagIncSta)
          );

        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                         <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("document_upload_msg")}  callback={e => this.props.onClose()} />
                        {this.state.isFileViewerModal && this.state.fileViewerContent &&
                            <FileViewerModal
                                onClose={() => {
                                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                                }}
                                data={this.state.fileViewerContent}
                                isFinancialDocument={this.state.isFinancialDocument}
                            ></FileViewerModal>
                        }
                        <CommonPopUpModal
                            title={this.props.t("upload_financial_statements")}
                            size='medium'
                            onClose={e => this.props.onClose()}
                            primaryBtn={this.props.t("button_names.finish")}
                            isSubmitBtn={isSubmitButtonEnabled}
                            promptOnClose={!isSubmitButtonEnabled}
                            onSubmit={() => this.uploadFinancialHistory()}
                        >
                            <div style={{ width: "100%" }}>
                                <div className="row justify-content-center">
                                    <div className="col">
                                        <legend className="form-label-style required">{this.props.t("profile_page.financial_information_content.balance_sheet_title")}</legend>
                                        <FileUploader
                                            documents={ fileToUpload && this.state.flagBalSta === true ? { fileName: fileToUpload.name, downloadUrl: URL.createObjectURL(fileToUpload) } : financeDocs && financeDocs.balanceSheet.fileName !== "" ? financeDocs.balanceSheet:[]}
                                            onDocUpload={doc => this.onDropFinancialDoc("Balance_Sheet", doc)}
                                            onView={this.handleView}
                                            namelength={100}
                                            supportedExt={['.pdf', '.csv']}
                                            //onDelete={() => this.handleDelete('balanceSheet')}
                                            placeholder={this.props.t("file_upload_msg.upload_file")}
                                            isdisabled={false}
                                            isMultiple={false}
                                            isExtraction={false}
                                            //isDeleteEnable={true}
                                            supportNotes={false}
                                            customHeight={'13vh'}
                                        />
                                        <legend className="form-label-style required mt-3">{this.props.t("profile_page.financial_information_content.income_statement_title")}</legend>
                                        <FileUploader
                                            documents={ fileToUpload1 && this.state.flagIncSta === true ? { fileName: fileToUpload1.name, downloadUrl: URL.createObjectURL(fileToUpload1) } : financeDocs && financeDocs.incomeStmt.fileName !== "" ? financeDocs.incomeStmt:[]}
                                            onDocUpload={doc => this.onDropFinancialDoc("Income_Statement", doc)}
                                            onView={this.handleView}
                                            namelength={100}
                                            //onDelete={() => this.handleDelete('incomeStmt')}
                                            supportedExt={['.pdf', '.csv']}
                                            placeholder={this.props.t("file_upload_msg.upload_file")}
                                            isdisabled={false}
                                            isMultiple={false}
                                            isExtraction={false}
                                            //isDeleteEnable={true}
                                            supportNotes={false}
                                            customHeight={'13vh'}
                                        />
                                        <legend className="form-label-style required mt-3">{this.props.t("profile_page.financial_information_content.cash_flow_statement")}</legend>
                                        <FileUploader
                                            documents={ fileToUpload2 && this.state.flagCasSta === true ? { fileName: fileToUpload2.name, downloadUrl: URL.createObjectURL(fileToUpload2) } : financeDocs && financeDocs.cashflow.fileName !== "" ? financeDocs.cashflow:[]}
                                            onDocUpload={doc => this.onDropFinancialDoc("Cash_Flow_Statement", doc)}
                                            onView={this.handleView}
                                            //onDelete={() => this.handleDelete('cashflow')}
                                            namelength={100}
                                            supportedExt={['.pdf', '.csv']}
                                            placeholder={this.props.t("file_upload_msg.upload_file")}
                                            isdisabled={false}
                                            isMultiple={false}
                                            isExtraction={false}
                                            //isDeleteEnable={true}
                                            supportNotes={false}
                                            customHeight={'13vh'}
                                        />
                                    </div>
                                </div>
                                <div className="submit-invoice-msg text-center mt-3">
                                {`${this.props.t("file_upload_msg.supported_file_types")}: .pdf, .csv`}
                                </div>
                            </div>
                        </CommonPopUpModal>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}

export default withTranslation() (FinancialStatementsModal);