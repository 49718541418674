//Author, Rajesh Rajendran
import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../CustomInputs/TextInput';
import CountrySelect from '../../CustomInputs/CountrySelect';
import RegionSelect from '../../CustomInputs/RegionSelect';
import { Grid } from '@mui/material';

const InvoiceCustomerData = ({ data, onDataUpdate, isFieldDisabled ,t}) => {

    const handleChange = (name, value) => {
        let updatedDetails = { ...data, [name]: value };

        if (name === 'country') {
            updatedDetails = {
                ...updatedDetails,
                city: "",
                region: "",
                postalCode: "",
                phone: ""
            };
        } else if (name === 'region') {
            updatedDetails = {
                ...updatedDetails,
                postalCode: ""
            };
        }

        onDataUpdate(updatedDetails);
    }

    return (
        <div className="custom-form-container">
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput
                        label={t("user_labels.business_name")}
                        name="name"
                        value={data.name}
                        onChange={handleChange}
                        placeholder={t("user_labels.business_name")}
                        maxLength={50}
                        disabled={isFieldDisabled}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput
                        label={t("user_labels.business_email")}
                        name="email"
                        value={data.email}
                        onChange={handleChange}
                        placeholder={t("user_labels.business_email")}
                        maxLength={50}
                        disabled={isFieldDisabled}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput
                        label={t("user_labels.business_Address")}
                        name="address1"
                        value={data.address1}
                        onChange={handleChange}
                        placeholder={t("user_labels.address")}
                        maxLength={100}
                        disabled={isFieldDisabled}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CountrySelect
                        label={t("user_labels.country")}
                        name="country"
                        value={data.country}
                        onChange={handleChange}
                        disabled={isFieldDisabled}
                        defaultLabel="Please select country"
                        errorMsg="Please select country"
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <RegionSelect
                        label={t("user_labels.state")}
                        name="region"
                        country={data.country}
                        value={data.region}
                        onChange={handleChange}
                        disabled={isFieldDisabled}
                        defaultLabel={t("user_labels.state")}
                        errorMsg={t("user_labels.state")}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput
                        label={t("user_labels.zip_code")}
                        name="postalCode"
                        value={data.postalCode}
                        onChange={handleChange}
                        placeholder={t("user_labels.zip_code")}
                        maxLength={10}
                        disabled={isFieldDisabled}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <TextInput
                        label={t("user_labels.city")}
                        name="city"
                        value={data.city}
                        onChange={handleChange}
                        placeholder={t("user_labels.city")} 
                        maxLength={50}
                        disabled={isFieldDisabled}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

const defaultData = {
    name: '',
    email: '',
    address1: '',
    country: '',
    region: '',
    postalCode: '',
    city: ''
};

InvoiceCustomerData.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        address1: PropTypes.string,
        country: PropTypes.string,
        region: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string
    }).isRequired,
    onDataUpdate: PropTypes.func.isRequired,
    isFieldDisabled: PropTypes.bool.isRequired
};

InvoiceCustomerData.defaultProps = {
    data: defaultData
};

export default InvoiceCustomerData;
