import React, { Component } from "react";
import Popup from '../../../common/Auth/Popup';
import './POList.css';
import { API_ENDPOINT_SUPPLIER } from "../../../../services/API";
import User from "../../../../services/User";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import API from "../../../../services/API";
import EnumPOStatus from "./EnumPOStatus";
import SampleFile from "../Invoices/SampleInvoice.csv";
import Chance from "chance";
import { ShortText,localDateFormat } from '../../../../services/Utilities';
import FullSpinner from "../../../common/Spinner/FullSpinner";
import MaskedInput from "react-text-mask";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ReactTable from "react-table";
import FileViewerModal from "../../../common/FileViewerModal/FileViewerModal";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import DatePicker from 'react-date-picker';

import CommonPopUpModal from "../../../CommonPopUpModal/CommonPopUpModal";
import FileUploader from "../../../common/FileUploader/FileUploader";
import fileUploadConfig from "../../../../configs/FileUploader";

const {
    supportedFileTypes,
    placeholders,
    duplicateFileMessage
} = fileUploadConfig;

const getFileConfig = (type) => {
    const fileTypeConfig = supportedFileTypes[type] || {};
    return {
        supportedExt: fileTypeConfig.extensions || [],
        errorMsgType: fileTypeConfig.message || '',
        placeholder: placeholders[type] || '',
        errorMsgDuplicate: duplicateFileMessage || ''
    };
};

const chance = new Chance();
const CheckboxTable = checkboxHOC(ReactTable);
let invoicesResponse = [];
const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
})

const numberMask = createNumberMask({
    prefix: "$",
    suffix: "",
    allowDecimal: true
});

export class POUploadModal extends React.Component {
    user = new User();
    api = new API();
    taxYears = [
        {
            value: "2017",
            text: "2017"
        },
        {
            value: "2018",
            text: "2018"
        },
        {
            value: "2019",
            text: "2019"
        },
        {
            value: "2020",
            text: "2020"
        },
        {
            value: "2021",
            text: "2021"
        },
    ];

    constructor(props_) {
        super(props_);
        this.state = {
            isCreateFundingRequestModalOpen: false,
            purchaseorder: props_.data ? props_.data.documentId : "",
            purchaseamount: props_.data ? props_.data.total : "",
            purchaseDocuments: props_.data ? props_.data.documentFiles : [],
            taxId: null,
            poLists: this.props && this.props.poList ? this.props.poList : [],
            uploadFileName: "Drop PO file to upload",
            fileToUpload: null,
            selection: [],
            selectAll: false,
            invoiceFile: null,
            uploadPOadditionalDocs: "Upload sales contract and any other pertinent documents",
            additionalPODocs: [],
            isFileViewerModal: false,
            isFinancialDocument: false,
            fileViewerContent: null,
            data: null,
            isPdfParsing:false
        };
        this.state.invoiceConfig = getFileConfig('invoice');
        this.state.poConfig = getFileConfig('po');
        this.state.addDocConfig = getFileConfig('additionalDocx'); 
    }

    componentDidMount() {
        //this.loadPOs();
    }
    // loadPOs() {
    //     this.api.docGetPO().then(invoices => {
    //         this.setState({ poLists: invoices });
    //     });
    // }

    componentWillMount() {
        var currentYear = new Date().getFullYear();
        this.setState({
            taxYear: currentYear - 1
        });

    }
    convertToJSONInvoice(columns, data) {
        let invObj = {};
        for (let i in columns) {
            switch (columns[i]) {
                case "INV":
                    invObj["descr"] = data[i];
                    break;
                case "Invoice Amount":
                    invObj["subTotal"] = data[i];
                    invObj["total"] = data[i];
                    break;
                case "Customer Name":
                    invObj["buyerName"] = data[i];
                    break;
                case "Invoice Date":
                    invObj["createdAt"] = data[i];
                    break;
                case "Customer Email":
                    invObj["email"] = data[i];
                    break;
                case "Customer Address1":
                    invObj["address"] = data[i];
                    break;
                case "Customer City":
                    invObj["city"] = data[i];
                    break;
                case "Customer State":
                    invObj["state"] = data[i];
                    break;
                case "Customer Zip":
                    invObj["zip"] = data[i];
                    break;
                case "Customer Phone":
                    invObj["phone"] = data[i];
                    break;
                case "Invoice#":
                    invObj[columns[i]] = data[i];
                    break;

                default:
                    invObj[columns[i]] = data[i];

            }
        }
        let customerDetails = {};
        customerDetails["name"] = invObj["buyerName"];
        customerDetails["email"] = invObj["email"];
        customerDetails["address1"] = invObj["address"];
        customerDetails["city"] = invObj["city"];
        customerDetails["region"] = invObj["state"];
        customerDetails["postalCode"] = invObj["zip"];
        customerDetails["country"] = "US"
        invObj["customerDetails"] = customerDetails;
        return invObj;
    }
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original._id);
            });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {

        /*
          Implementation of how to manage the selection state is up to the developer.
          This implementation uses an array stored in the component state.
          Other implementations could use object keys, a Javascript Set, or Redux... etc.
        */
        // start off with the existing state
        key = key.substring(7);
        let selection = [...this.state.selection];
        var keyIndex = selection.indexOf(key);
        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        // update the state
        this.setState({ selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    handleInputChange = (e) => {
        e.preventDefault();
        const { data } = this.state;
        if(e.target.name=="buyerName"){
            data[0].customerDetails.name=e.target.value;
            data[0]["buyerName"]=e.target.value;
        }
        else{
            data[0][e.target.name] = e.target.value;
        }
       
        this.setState({ data }, () => {
            this.setState({
                purchaseamount: data[0]["total"],
                purchaseorder: data[0]["po#"]
            })
        });
    }

    handleDataChange = (updatedData) => {
        this.setState({ data: updatedData }, () => {
          this.setState({
            purchaseamount: updatedData[0]["total"],
            purchaseorder: updatedData[0]["po#"]
          });
        });
      };

    handleInvoiceLoaded = (documentData) => {
        this.setState({
            data: documentData.data,
            invoiceFile: documentData.invoiceFile,
            purchaseamount: documentData.poAmount,
            purchaseorder: documentData.poNumber,
            isPdfParsing: documentData.isPdfParsing,
        });
    };

    handleLoadingError = (errorData) => {
        console.log(errorData)
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    handleModalClose = () => {
        this.props.onClose();
    }

    handleAddDocUpload = (newDocs, stateToUpdate) => {
        this.setState(prevState => ({
            [stateToUpdate]: newDocs,
            isAddedAdditionalFiles: true
        }));
    };

    render() {
        const { history } = this.props;
        const { toggleSelection, toggleAll, isSelected, logSelection } = this;
        const { data, selectAll, lastSyncOn, erpMessage } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox"
        };
        const columns = [
            {
                Header: "PO Number",
                accessor: "po#"
            },
            {
                Header: "PO Date",
                accessor: "createdAt"
            },
            {
                Header: "Customer Name",
                accessor: "SupplierCompanyName"
            },
            {
                Header: "PO Amount",
                accessor: "total",
                Cell: props => <div className='text-right'>{dollarFormatter.format(props.value)}</div>
            }
        ]; 
        const { invoiceConfig, poConfig, addDocConfig } = this.state;
        return (
            <>
                {
                    (this.state.validationError && this.state.validationError.length) &&
                    <CommonConfirmationModal
                        title={this.props.t("transactions_page.po_content.invalid_inputs")}
                        onClose={() => { this.setState({ validationError: null }) }}
                        noCancel={true}
                    >
                        <ul>
                            {this.state.validationError.map(t => <li>{t}</li>)}
                        </ul>
                    </CommonConfirmationModal>
                }
                {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                    onClose={() => {
                        this.setState({ isFileViewerModal: false, fileViewerContent: null });
                    }}
                    data={this.state.fileViewerContent}
                    isFinancialDocument={false}
                ></FileViewerModal>}
                <FullSpinner
                    isSpinning={this.state.isErpLastSync}
                />
                <FullSpinner isSpinning={this.state.isPdfParsing} />
                <FullSpinner
                    isSpinning={this.state.isLoading}
                    confirmationLabel="Invoice(s) Uploaded "
                    // callback={() => history.push("/invoices")}
                    callback={e => this.props.onClose()}
                />
                <FullSpinner
                    isSpinning={this.state.isCreatingInvoice}
                    confirmationLabel="Invoice(s) Created"
                    // callback={() => history.push("/dashboard")}
                    callback={e => this.props.onClose()}
                />
                <CommonPopUpModal
                    onClose={this.handleModalClose}
                    onSubmit={() => {
                        let purchaseOrder = this.state.purchaseorder;
                        let purchaseAmount = this.state.purchaseamount;
                        let purchaseOrderDate = this.state.data[0].createdAt;
                        let errorMessage = [];
                        const duplicatePo = this.state.poLists.filter(po => po.documentId == purchaseOrder);
                        if (duplicatePo.length > 0) {
                            errorMessage.push(this.props.t("transactions_page.po_content.po_is_already_imported"));
                        }
                        if (!purchaseOrder) {
                            errorMessage.push(this.props.t("transactions_page.po_content.po_number_invalid"));
                        }
                        if (!purchaseAmount) {
                            errorMessage.push(this.props.t("transactions_page.po_content.valid_financing_volume"));
                        }
                        if (errorMessage.length > 0) {
                            this.setState({
                                validationError: errorMessage
                            });
                        }
                        else {
                            let formData = new FormData();
                            formData.append("purchaseorder", this.state.purchaseorder);
                            formData.append("purchaseamount", this.state.purchaseamount.toString().replace(/[^0-9.]/g, ""));
                            formData.append("createdAt", purchaseOrderDate);
                            for (let file of this.state.invoiceFile) {
                                formData.append("PUR_DOC", file, file.name);
                            }
                            for (let file of this.state.additionalPODocs) {
                                formData.append("Additional_PO_DOC", file, file.name);
                            }
                            let parsedata = this.state.data;
                            if (parsedata.length) {
                                // For the PO, the position of buyer and supplier name/address is swapped
                                formData.append("buyerdetails", JSON.stringify(parsedata[0].customerDetails));
                            }
                            this.props.onSubmit(formData);
                        }
                    }}
                    title={this.props.t("transactions_page.po_content.title")}
                    primaryBtn={this.props.t("transactions_page.po_content.upload_btn")}
                    isSubmitBtn={this.state.data ? false : true}
                    size="large"
                    promptOnClose={this.state.data ? true : false}
                    uploadInfo={this.state.data ? false : true}
                    children={
                        <>
                            <div className="row justify-content-center">
                                <div className='col-md'>
                                    <FileUploader
                                        onFileExtracted={this.handleInvoiceLoaded}
                                        onLoadingError={this.handleLoadingError}
                                        onView={this.handleView}
                                        convertToJson="po"
                                        fileToExtract={this.state.invoiceFile}
                                        supportedExt={invoiceConfig.supportedExt}
                                        //errorMsgType={this.props.t("file_upload_msg.only_pdf_file_msg")}
                                        placeholder={this.props.t("file_upload_msg.po_placeholder")}
                                        isdisabled={false}
                                        isMultiple={false}
                                        isExtraction={true}
                                    />
                                </div>
                                <div className='col-md'>
                                    <FileUploader
                                        documents={this.state.additionalPODocs}
                                        onDocUpload={(newDocs) => this.handleAddDocUpload(newDocs, 'additionalPODocs')}
                                        onDelete={(filteredDocs)=>this.setState({additionalPODocs: filteredDocs})}
                                        onView={this.handleView}
                                        namelength={50}
                                        supportedExt={addDocConfig.supportedExt}
                                        //errorMsgType={addDocConfig.errorMsgType}
                                        errorMsgDuplicate={addDocConfig.errorMsgDuplicate}
                                        placeholder={this.props.t("file_upload_msg.additionalDocx_placeholder")}
                                        isdisabled={false}
                                        isMultiple={true}
                                        isExtraction={false}
                                   />
                                </div>
                            </div>
                            {this.state.data && this.state.data.length > 0 && (
                                <>
                                    <div className="d-flex mt-4">
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("po_label.po_number")}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="po#"
                                                onChange={this.handleInputChange}
                                                value={data.length > 0 && data[0]["po#"] ? data[0]["po#"] : ""}
                                                disabled={this.props.isReadOnly}
                                            />
                                        </div>
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("po_label.po_amount")}</label>
                                            <MaskedInput
                                                name="total"
                                                value={data.length > 0 && data[0].total ? data[0].total : ""}
                                                mask={numberMask}
                                                className="form-control"
                                                placeholder="$ ..."
                                                onChange={this.handleInputChange}
                                                disabled={this.props.isReadOnly}
                                            />
                                        </div>
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("po_label.po_date")}</label>
                                            <DatePicker
                                                className={["form-control"]}
                                                format="MM/dd/y"
                                                value={data.length > 0 && data[0].createdAt ? new Date(data[0].createdAt) : ""}
                                                onChange={(e) => {
                                                    const { data } = this.state;
                                                    if (data.length > 0) {
                                                        if (e) {
                                                            data[0].createdAt = localDateFormat(e);
                                                        }
                                                        else {
                                                            data[0].createdAt = null;
                                                        }
                                                        this.setState({ data }, () => {
                                                            // this.validateAllFields();
                                                        });
                                                    }
                                                }}
                                                disabled={this.props.isReadOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("customer_labels.customer_name")}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="buyerName"
                                                value={data.length > 0 && data[0].buyerName ? data[0].buyerName : ""}
                                                onChange={this.handleInputChange}
                                                disabled={this.props.isReadOnly}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    }
                >
                </CommonPopUpModal>
            </>
        );
    }

    _formatDollars(text_) {
        if (text_ != "") {
            text_ = text_.replace(/[^0-9.]/g, "");
            let value = parseFloat(text_);
            if (!isNaN(value)) {
                return dollarFormatter.format(value);
            }
        }

        return "";
    }

    getDropBoxContent(files) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                return <div key={d.filename}><a href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${d._id}`} target="_blank" download={d.filename}>{d.filename}</a></div>
            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    onDropFinancialDoc(type, documents) {
        let existingDocuments = [];
        switch (type) {
            case "Purchase_Documents":
                existingDocuments = this.state.purchaseDocuments;
                if (this.user.currentUserType() == "POFinancing") {
                    this.setState({ purchaseDocuments: documents });
                } else {
                    existingDocuments = existingDocuments.concat(documents);
                    this.setState({ purchaseDocuments: existingDocuments });
                }


                break;
            case "Tax_Document":
                existingDocuments = this.state.taxDocuments;
                existingDocuments = existingDocuments.concat(documents);
                this.setState({ taxDocuments: existingDocuments });
                break;
        }
    }
}