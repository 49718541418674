import React from "react";
import "./List.css";
import Spinner from "../Spinner/Spinner";
import { UUID } from "../../../services/Utilities";

const List = ({
  data,
  isLoading,
  emptyListLabel,
  itemType,
  removeItem,
  userData,
  userType,
  callback,
}) => {
  const ListItemType = itemType;
  if (isLoading) {
    return <Spinner />;
  }
  if (!data) {
    return <div>No data</div>;
  }
  if (data.length > 0) {
    return data.map((itemData, i) => (
      <ListItemType key={UUID()} index={i} {...itemData} userType={userType} callback={callback} />
    ));
  }
  return <EmptyListView label={emptyListLabel} remove={removeItem} />;
};

export default List;

const EmptyListView = ({ label }) => (
  <div className="row mt-3">
    <div className="col-12 d-flex justify-content-center">
      <p className="text-muted">{label}</p>
    </div>
  </div>
);
