import React, { Component } from 'react';
import AppState from '../../../contexts/AppState/AppState';
import AppProvider from '../../../contexts/AppState/AppStateProvider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faExclamationTriangle, faSortUp, faSortDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import List from '../../common/List/List';
import FundingContractListItem from './FundingContractListItem';
import API from '../../../services/API';
import CommonConfirmationModal from '../../common/Modals/CommonConfirmationModal';
import DataGrid from '../../common/DataGrid/DataGrid';
import DataGridFilter from '../../common/DataGrid/DataGridFilter';
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import User from '../../../services/User';
import SigningStatusModal from './SigningStatusModal';
import {localDateFormat} from "../../../services/Utilities";
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import FullSpinner from '../../common/Spinner/FullSpinner';
import NewViewFundingContract from './NewViewFundingContract';
import UpdateCreditLimitModal from './UpdateCreditLimitModal';
import { withTranslation } from 'react-i18next';
import { generateSteps, calculateStatusCounts, StatusStepper } from '../../../services/StatusStepperService';

 class FundingContract extends Component {
    api = new API();
    user = new User();

    _columnsSpecification = [
        {
            key: "fundingContractId",
            name: this.props.t("grid_view_header_labels.fc_id"),
            contentProvider: (datum_) => {
                return <div>
                    <span className="theme-color" style={{ fontWeight: 600 }}>{datum_.funder ? datum_.funder.name : datum_.supplier.name}</span><br />
                    <span className="link-text-color" style={{ fontWeight: 600 }}>{datum_.fundingContractId}</span>
                </div>;
            }
        },
        {
            key: "terms",
            name: this.props.t("grid_view_header_labels.funding_details"),
            contentProvider: (datum_) => {
                if (datum_.contractType == "ABL") {
                    return <div><React.Fragment>
                        <span style={{ color: "#575757" }}>Advance Rate on Eligible Accounts Receivable:{" "}</span>
                        <span style={{ color: "#255F79", fontWeight: 600 }}>
                            {datum_.terms.advanceRateOnEligibleAR}
                        </span>
                        <br />
                        <span style={{ color: "#575757" }}>Advance Rate on Eligible Inventory:{" "}</span>
                        <span style={{ color: "#255F79", fontWeight: 600 }}>
                            {datum_.terms.advanceRateOnEligibleInventory}
                        </span>
                        <br />
                        {datum_.terms.lineOfCredit.length > 0 && (
                            <>
                                <span style={{ color: "#575757" }}>Line of Credit:{" "}</span>
                                <span style={{ color: "#255F79", fontWeight: 600 }}>
                                    {datum_.terms.lineOfCredit}
                                </span>
                                <br />
                            </>
                        )}
                        {datum_.terms.loan.length > 0 && (
                            <>
                                <span style={{ color: "#575757" }}>Loan:{" "}</span>
                                <span style={{ color: "#255F79", fontWeight: 600 }}>
                                    {datum_.terms.loan}
                                </span>
                                <br />
                            </>
                        )}
                        <span style={{ color: "#575757" }}>Total Financing Fees:{" "}</span>
                        <span style={{ color: "#255F79", fontWeight: 600 }}>
                            {datum_.terms.totalFinancingFee}
                        </span>

                    </React.Fragment></div>;
                }
                else if(datum_.contractType == "SupplyChain"){
                    return <div><React.Fragment>
                    <span style={{color:"#575757"}}>Offer Details:{" "}</span>
                     <span style={{color:"#255F79",fontWeight:600}}>
                         {datum_.terms.offernote}
                     </span>
                 </React.Fragment></div>
                }
                else {
                    return <div><React.Fragment>
                    <span style={{color:"#575757"}}>Advance Rate:{" "}</span>
                     <span style={{color:"#255F79",fontWeight:600}}>
                         {datum_.terms.advancerate}
                     </span>
                     <br />
                     <span style={{color:"#575757"}}>Factoring Rate:{" "}</span>
                     <span style={{color:"#255F79",fontWeight:600}}>
                         {datum_.terms.factoringrate}
                     </span>
                     <br />
                     <span style={{color:"#575757"}}>Repayment Days:{" "}</span>
                     <span style={{color:"#255F79",fontWeight:600}}>
                         {datum_.terms.lengthoftimeforpayment=="15"?"International":datum_.terms.lengthoftimeforpayment}
                     </span>
                 </React.Fragment></div>
                }

            }
        },
        {
            key: "fundingAggrement",
            name: this.props.t("grid_view_header_labels.funding_agreement"),
            contentProvider: (datum_) => {
                if (datum_ && datum_.isDigitalSignature == "true") {
                    return <div>
                        {
                            datum_.fundingAggrement && <a className="link-text-color anchorstyle" style={{ fontWeight: 600, cursor: "pointer" }}
                            >Signing Status</a>
                        }
                    </div>;
                }
                else {
                    return <div>
                    {
                        datum_.fundingAggrement && <a className="link-text-color anchorstyle" style={{fontWeight:600,cursor:"pointer"}} onClick={(e)=>e.preventDefault()} href="">Funding Agreement</a>
                    }
                    <br />
                    {
                        datum_.signedAggrements && <a className="link-text-color anchorstyle" style={{fontWeight:600,cursor:"pointer"}} href="" onClick={(e)=>e.preventDefault()}>Signed Copy</a>
                    }
                </div>
                }
            },
            eventType: "signingStatusModal"
        },
        {
            key: "customerList",
            name: this.props.t("grid_view_header_labels.customers"),
            contentProvider: (datum_) => {
                return (<React.Fragment>
                    {datum_.customerList && datum_.customerList.map(cus => {
                        return <React.Fragment><div className="awaiting-text-color" style={{ fontWeight: 600 }}>{cus && cus.name ? cus.name : ""}</div><br /></React.Fragment>;
                    })}
                </React.Fragment>);
            }
        },
        {
            key: "status",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_) => {
                if (datum_.status == 3 || (datum_.fundingAggrement && datum_.signedAggrements)) {
                    switch (datum_.status) {
                        case 2:
                            return <div className="progress-text-color">{this.props.t("valid_till")}: {localDateFormat(datum_.contractDueDate)}</div>;
                        case 3:
                            return <div className="archived-text-color">{this.props.t("contract_terminated")}</div>;
                        default:
                            return "Unknown";
                    }
                }
                else if (datum_.status == 2 && !datum_.fundingAggrement && !datum_.signedAggrements) {
                    return <span className="badge badge-pill-1 badge-warning p-2">
                        {this.props.t("waiting_for_funder_to_upload")}
                    </span>;
                }
                else if (datum_.status == 2 && datum_.fundingAggrement && !datum_.signedAggrements) {
                    return <span className="badge badge-pill-1 badge-warning p-2">
                        {this.props.t("waiting_for_signed_agreement")}
                    </span>;
                }
            }
        },
    ];
    constructor(props) {
        super(props);
        this.state = {
            allFundingContract: [],
            fundingContract: [],
            isContractTerminate: false,
            isViewContract: false,
            selectedTCF: null,
            filtersApplied: {},
            allfunders:[],
            isResetFilterEnabled:false,
            isSigningPopup:false,
            isFileViewerModal:false,
            fileViewerContent:null,
            isFinancialDocument:false,
            isUpdateCreditLimitModal: false,
            steps: "",
            activeStep: '',
            statusCounts: [],
        };
        this.getContract = this.getContract.bind(this);
    }

    componentDidMount() {
        document.title = `Funding Contract - LedgerFunding ${this.user.currentUserRole()}`;
        this.getContract();
    }
    async getContract() {
        // this.setState({isLoading: true})
        const contractResponse = await this.api.getContracts();
        let funders = [];
        contractResponse.forEach(element => {
            if (element.funder && funders.filter(name => name == element.funder.name).length == 0) {
                funders.push((element.funder.name));
            }
        });
        // Calculate the counts for each status up to 'statusUpto'
        const countsFor = "F_CON"
        const statusUpto = 12; //Define the maximum status value to be calculated
        const counts = calculateStatusCounts(contractResponse, statusUpto, countsFor);
        this.setState({ statusCounts: counts });

        funders = funders.sort();
        this.setState({ allFundingContract: contractResponse, allfunders: funders }, () => {
            this.onFilterChange();
           // this.onFilterGridItems();
            this._resetFilter();
        });
    }

    onFilterChange(status) {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        if (status === undefined && this.state.filtersApplied.status?.length) {
            status = this.state.filtersApplied.status[0].value;
        }

        let filteredInvoices = this.state.allFundingContract.filter(contract_ => {

            if (!this.state.filtersApplied.archived) {
                if (contract_.status == 3) {
                    return false;
                }
            }
            if (filteredText) {
                let userName = contract_.funder ? contract_.funder.name : contract_.supplier.name;
                let isCustomers = false;
                for (let buyer_ of contract_.customerList) {
                    if (buyer_.name.toLowerCase().indexOf(filteredText) >= 0) {
                        isCustomers = true;
                        break;
                    }
                }
                if (!(userName.toLowerCase().indexOf(filteredText) >= 0 ||
                    isCustomers)) {
                    return false;
                }
            }
            if (this.state.filtersApplied.funders && this.state.filtersApplied.funders.length) {
                if (contract_.funder.name != this.state.filtersApplied.funders[0].text) {
                    return false;
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (contract_.status === 2 || (status === 21 || status === 22)) {
                    if (!contract_.fundingAggrement && !contract_.signedAggrements) {
                        return this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 21);
                    } else if (contract_.fundingAggrement && !contract_.signedAggrements) {
                        return this.state.filtersApplied.status.some(statusFilter => statusFilter.value === 22);
                    }
                }

                else if (!this.state.filtersApplied.status.some(statusFilter => statusFilter.value === contract_.status)) {
                    return false;
                }
            }
            if (status !== 2 && this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (contract_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                }
            }
            return true;
        });

        this.setState({
            fundingContract: filteredInvoices
        }, () => this._hasResetFilterEnabled());
    }

    _resetFilter = () => {
        this.state.filtersApplied.textFilter = "";
        this.state.filtersApplied.funders = [];
        this.state.filtersApplied.status = [];
        this.state.filtersApplied.archived = false;
        this.setState({
            filtersApplied: this.state.filtersApplied,
            activeStep:""
        }, () => this.onFilterChange());
    };

    _hasResetFilterEnabled = () => {
        if (this.state.filtersApplied.textFilter != "" || this.state.filtersApplied.funders.length > 0 || this.state.activeStep !=="" ) {
            this.setState({ isResetFilterEnabled: true });
        }
        else {
            this.setState({ isResetFilterEnabled: false });
        }
    };

    handleStepClick = (step, status) => {//
        this.setState(prevState => {
            const isSameStep = prevState.activeStep === step;
            return {
                activeStep: isSameStep ? "" : step, // Do not change this "" 
                filtersApplied: isSameStep
                    ? {
                        ...prevState.filtersApplied,
                        textFilter: "",
                        archived: false,
                        funders: [],
                        status: []
                    }
                    : {
                        ...prevState.filtersApplied,
                        status: [{ value: status }],
                        archived: status==3 ? true : false,
                    }
            };
        }, () => { this.onFilterChange(status);
        });
    };

     generateStepData = () => {
         const { statusCounts } = this.state;

         const stepTitles = [
            this.props.t("stepper_labels.fun_con.awaiting_funder_agreement"),
            this.props.t("stepper_labels.fun_con.awaiting_countersign"),
            this.props.t("stepper_labels.fun_con.valid_contracts"),
            this.props.t("stepper_labels.fun_con.terminated_contracts"),
         ];
         // Status codes to include in the steps
         const statusToInclude = [21, 22, 2, 3];
         return generateSteps(statusCounts, stepTitles, statusToInclude);
     }

    render() {
        const { fundingContract, isContractTerminate, isViewContract, isLoading, activeStep } = this.state;

        const steps = this.generateStepData(); 

        return (
            <AppProvider>
                <AppState.Consumer>
                    {appState => (
                        <React.Fragment>
                            <FullSpinner isSpinning={isLoading} />
                            <div className="header-titlebar">
                                <h3>{this.props.t("page_titles.fundingContracts")}</h3>
                            </div>
                            <div className="content-container contract-list">
                                {(isContractTerminate || isViewContract ||this.state.isSigningPopup || this.state.isFileViewerModal || this.state.isUpdateCreditLimitModal) && (
                                    <div className="terms-checkbox-container">
                                        {
                                            this.state.isContractTerminate && (
                                                <CommonConfirmationModal
                                                    title={this.props.t("terminate_contract")}
                                                    submitText={this.props.t("button_names.terminate")}
                                                    onClose={() => {
                                                        this.setState({ isContractTerminate: false, selectedTCF: null });
                                                        this.getContract();
                                                    }}
                                                    onSubmit={() => {
                                                        return this.api.terminateContract(this.state.selectedTCF._id).then(r => {
                                                            this.getContract();
                                                        });
                                                    }}
                                                    isPrimaryButtonDanger={true}
                                                >
                                                    {this.props.t("terminate_contract_msg")}
                                                </CommonConfirmationModal>

                                            )
                                        }
                                        {this.state.isViewContract && this.state.selectedTCF && <NewViewFundingContract
                                        t={this.props.t}
                                            isReadOnly={true}
                                            onClose={() => { this.setState({ isViewContract: false, selectedTCF: null }); }}
                                            data={this.state.selectedTCF}
                                        />}
                                        {this.state.isSigningPopup && this.state.selectedTCF && <SigningStatusModal
                                        t={this.props.t}
                                            isReadOnly={true}
                                            onClose={() => {
                                                this.setState({ isSigningPopup: false, selectedTCF: null });
                                                this.getContract();
                                            }}
                                            data={this.state.selectedTCF}
                                        />}
                                        {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                                            onClose={() => {
                                                this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                                            }}
                                            data={this.state.fileViewerContent}
                                            isFinancialDocument={this.state.isFinancialDocument}
                                        ></FileViewerModal>}
                                        {this.state.isUpdateCreditLimitModal && this.state.selectedTCF && (
                                            <UpdateCreditLimitModal
                                            t={this.props.t}
                                                isReadOnly={true}
                                                onClose={() => { 
                                                    this.setState({ isUpdateCreditLimitModal: false, selectedTCF: null }); 
                                                    this.getContract();
                                            }}
                                                data={this.state.selectedTCF}
                                            />
                                        )}
                                    </div>
                                )}
                                <StatusStepper
                                    activeStep={activeStep}
                                    steps={steps}
                                    handleStepClick={this.handleStepClick}
                                />
                                {(appState.state.currentUserRole === "supplier" || appState.state.currentUserRole === "buyer") && (
                                    <DataGrid
                                        title="Funding Contract"
                                        data={fundingContract}
                                        columns={this._columnsSpecification}
                                        isNoPagination={true}
                                        isRowsNonSelectable={true}
                                        isNoMoreLink={false}
                                        isGridSearch={true}
                                        appliedFilter={this.state.filtersApplied}
                                        resetFilters={() => this._resetFilter()}
                                        searchText={(text_) => {
                                            this.state.filtersApplied.textFilter = text_;
                                            this.setState({
                                                filtersApplied: this.state.filtersApplied
                                            }, () => this.onFilterChange());
                                        }}

                                        popoverItems={[
                                            { key: "viewContract", label: this.props.t("grid_view_action_labels.view_funding_contract"), role: appState.state.currentUserRole },
                                            { key: "terminateContract", label: this.props.t("terminate_contract"), role: appState.state.currentUserRole },
                                        ]}
                                        onPopoverClick={(key_, row_) => {
                                            if (key_ == "viewContract") {
                                                this.setState({
                                                    isViewContract: true,
                                                    selectedTCF: row_,
                                                });
                                            }
                                            else if (key_ == "terminateContract") {
                                                this.setState({
                                                    isContractTerminate: true,
                                                    selectedTCF: row_,
                                                });
                                            }
                                        }}

                                        filterComponent={
                                            <React.Fragment>
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.funder")}
                                                    type="options"
                                                    options={this.state.allfunders.map(f => { return { text: f, value: f }; })}
                                                    value={this.state.filtersApplied.funders}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.funders = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                        handleTableCellClick={(dataRow, type, index) => {
                                            if (type == "signingStatusModal"
                                                && dataRow &&
                                                dataRow.isDigitalSignature
                                                && dataRow.isDigitalSignature == "true"
                                            ) {
                                                this.setState({
                                                    isSigningPopup: true,
                                                    selectedTCF: dataRow,
                                                });
                                            }
                                            else{
                                                if(dataRow.fundingAggrement){
                                                    this.setState({
                                                        isFileViewerModal:true,
                                                        fileViewerContent:dataRow.fundingAggrement,
                                                        isFinancialDocument:false
                                                     });
                                                }
                                                else if(dataRow.signedAggrements) {
                                                    this.setState({
                                                        isFileViewerModal:true,
                                                        fileViewerContent:dataRow.signedAggrements,
                                                        isFinancialDocument:false
                                                     });
                                                }

                                            }
                                        }}
                                        isResetFilterEnabled={this.state.isResetFilterEnabled}
                                        emptydataLabel={this.props.t("fundingContracts_page.empty_grid_view_msg")}
                                    />)}


                                {appState.state.currentUserRole === "funder" && (
                                    <div className="row card">
                                        <div className="col">
                                            <GridHeader t={this.props.t} />
                                            <div className="grid-rows">
                                                <List
                                                    data={fundingContract}
                                                    isLoading={false}
                                                    emptyListLabel={this.props.t("fundingContracts_page.empty_grid_view_msg")}
                                                    itemType={FundingContractListItem}
                                                    callback={(action, item) => {
                                                        let fundingModalData = fundingContract.filter((r, i) => i == item);
                                                        if (action == "VIEW") {
                                                            this.setState({
                                                                isViewContract: true,
                                                                selectedTCF: fundingModalData.length > 0 ? fundingModalData[0] : null,
                                                            });
                                                        }
                                                        else if (action == "Terminate") {
                                                            this.setState({
                                                                isContractTerminate: true,
                                                                selectedTCF: fundingModalData.length > 0 ? fundingModalData[0] : null,
                                                            });
                                                        }
                                                        else if (action == "signingStatusModal") {
                                                            this.setState({
                                                                isSigningPopup: true,
                                                                selectedTCF: fundingModalData.length > 0 ? fundingModalData[0] : null,
                                                            });
                                                        }
                                                        else if(action=="fundingAgreementModal"){
                                                            this.setState({
                                                                isFileViewerModal: true,
                                                                fileViewerContent: fundingModalData.length > 0 ? fundingModalData[0].fundingAggrement : null,
                                                                isFinancialDocument:false
                                                            });
                                                        }
                                                        else if(action=="signedAgreementModal"){
                                                            this.setState({
                                                                isFileViewerModal: true,
                                                                fileViewerContent: fundingModalData.length > 0 ? fundingModalData[0].signedAggrements : null,
                                                                isFinancialDocument:false
                                                            });
                                                        }
                                                        else if(action=="updateCreditLimit"){
                                                            this.setState({
                                                                isUpdateCreditLimitModal: true,
                                                                selectedTCF: fundingModalData.length > 0 ? fundingModalData[0] : null
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                        </React.Fragment>
                    )}
                </AppState.Consumer>
            </AppProvider>
        );
    }
}

export default withTranslation() (FundingContract);

const GridHeader = ({ currentUserRole,t }) => {
    return (
        <div className="row grid-header ">
            <div className="col">
                <div className="row">
                    <div className="col-2 align-self-center text-center">
                        <span><a sort="asc" className="text-white font-weight-bold" title='Click To Sort FC-ID By Ascending' sortby='name' href="#"
                        >{t("grid_view_header_labels.fc_id")}</a>
                        </span>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <span><a sort="asc" className="text-white font-weight-bold" title='Click To Sort Requested Amount By Ascending' sortby='name' href="#"
                        >{t("grid_view_header_labels.funding_details")}</a>
                        </span>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <span><a sort="asc" className="text-white font-weight-bold" title='Click To Sort Funding Documents By Ascending' sortby='name' href="#"
                        >{t("grid_view_header_labels.funding_agreement")}</a>
                        </span>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <span><a sort="asc" className="text-white font-weight-bold" title='Click To Sort Customer Name By Ascending' sortby='name' href="#"
                        >{t("grid_view_header_labels.customers")}</a>
                        </span>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <span
                            type="button"
                            className="font-weight-bold"
                            data-toggle="dropdown"
                        >
                            {t("grid_view_header_labels.status")} <FontAwesomeIcon icon={faSortDown} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
