import React, { Component } from 'react'
import FullSpinner from '../../common/Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import Popup from '../../common/Auth/Popup';
import {localDateFormatWithoutBracketTime,dollarFormatter} from "../../../services/Utilities";
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import { compareTransactionType,transactionType } from '../../../configs/GlobalConfig';
import Dropzone from 'react-dropzone';
import { AddModal } from '../../common/Modals/CommonConfirmationModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import SigningStatusModal from '../FundingContract/SigningStatusModal';
import FileUploader from '../../common/FileUploader/FileUploader';
import FundingOfferDetails from '../../common/FundingDetails/FundingOfferDetails';

export default class ViewFundingOfferModal extends Component {
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedFunder: "",
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false,
            signedTermSheetFile:[],
            selectedContractValue:null,
            signingContractValue:null,
            isTermSheetAvailable:false,
            isNextButtonEnabled:false,
            selectedContractValue:null,
            isManualTermSheetUpload:this.props && this.props.data && this.props.data.isManualTermSheetUpload?this.props.data.isManualTermSheetUpload:false,
        };
        this.selectFunder = this.selectFunder.bind(this);
        this.acceptFundingOffer = this.acceptFundingOffer.bind(this);
    }

    selectFunder(event) {
        const {data}=this.props;
        let _selectedContractValue=null;
        if(data.contracts && data.contracts.length>0){
            _selectedContractValue=data.contracts.filter(c_=>c_._id==event.target.value)[0];
        }
        this.setState({ selectedFunder: event.target.value,selectedContractValue: _selectedContractValue});
    }

    async acceptFundingOffer() {
        this.setState({ isLoading: true });
        const {
            data: { _id }
        } = this.props;

        const { selectedFunder } = this.state;
        const documentData = new FormData();
        documentData.append('fundingReqId', _id);
        documentData.append('contractId', selectedFunder);
        //Added signed term sheet in formdata
        documentData.append('signedTermsheetFile',this.state.signedTermSheetFile[0]);
        await this.api.acceptoffer(documentData);
        this.setState({
           isLoading: false,
        });
        this.props.onClose();
    }

    FileViewerModalClick = (e, file_) => {
        e.preventDefault();
        this.setState({ isFileViewerModal: true, isFinancialDocument: false, fileViewerContent: file_ });
    }

    showSigningPopuModal=(contract_)=>{
        const {
            data
        } = this.props;
        let _cont = contract_;
        _cont["supplier"] = data.supplier;
        _cont["funder"] = contract_.funderDetails ? contract_.funderDetails : contract_.funder;
        this.setState({ isSigningPopup: true, signingContractValue: _cont, isTermSheetAvailable: true });
    }

    //Validate and added signed term sheet
    handleSignedTermSheet = async (acceptedFiles) => {
        let fileExtention = '';
        if (acceptedFiles.length > 0 && acceptedFiles[0].name && acceptedFiles[0].name.indexOf('.') > -1) {
            fileExtention = acceptedFiles[0].name.substring(acceptedFiles[0].name.lastIndexOf(".") + 1).toUpperCase();
        }
        if (fileExtention != "PDF") {
            AddModal(this.props.t("file_upload_msg.unsupported_format_title"), this.props.t("file_upload_msg.only_pdf_file_msg"), false, false);
            return;
        } else {
            this.setState({signedTermSheetFile: acceptedFiles});
        }
    }

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };


    render() {
        const { data } = this.props;
        const {
            isLoading,
            selectedFunder,
            selectedContractValue
        } = this.state;
        const {isCreatedByImpersonateUser}=data;
        let _isCreditLimitShow=compareTransactionType(data.fundingType[0],transactionType.Invoice) && (process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED=="true");
        return (
            <>
                {this.state.fileViewerContent && (
                    <FileViewerModal
                        onClose={() => {
                            this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                        }}
                        data={this.state.fileViewerContent}
                        isFinancialDocument={this.state.isFinancialDocument}
                    ></FileViewerModal>
                )}
                {this.state.isSigningPopup && this.state.signingContractValue && <SigningStatusModal
                    isReadOnly={true}
                    onClose={() => {
                        this.setState({ isSigningPopup: false, signingContractValue: null })
                    }}
                    data={this.state.signingContractValue}
                    isTermSheetAvailable={this.state.isTermSheetAvailable}
                />}
                <FullSpinner
                    isSpinning={isLoading}
                    confirmationLabel={this.props.t("view_funding_offer.accept_funding_offer_msg")}
                    callback={e => this.props.onClose()}
                />
                <CommonPopUpModal
                    title={
                        <>
                            {data.fundingType && data.fundingType == "ABL" && (
                                <>{this.props.t("view_funding_offer.title_1")}:  # {data ? data.fundingReqId : ""} - {this.props.t("view_funding_offer.title_2")}
                                </>
                            )}
                            {
                                data.fundingType && data.fundingType != "ABL" && (
                                    <>
                                        {this.props.t("view_funding_offer.title_1")}: # {data ? data.fundingReqId : ""}
                                    </>
                                )
                            }
                        </>
                    }
                    size={!this.state.isNextButtonEnabled ? "large" : "large"}
                    secondaryBtn={selectedContractValue && selectedContractValue.isManualTermSheetUpload? this.state.isNextButtonEnabled ? this.props.t("button_names.back") : this.props.t("button_names.next"):false}
                    onSecBtnClick={() => {
                        if(this.state.isNextButtonEnabled){
                            this.setState({selectedFunder:null,selectedContractValue:null,isNextButtonEnabled:false,signedTermSheetFile: [] })
                        }
                        else{
                            this.setState({ isNextButtonEnabled: !this.state.isNextButtonEnabled, signedTermSheetFile: [] })
                        }

                    }}
                    isSecBtnEnabled={!selectedFunder}
                    onSubmit={this.acceptFundingOffer}
                    submitLabel={this.props.t("view_funding_offer.submit_label")}
                    primaryBtn={this.props.t("view_funding_offer.submit_label")}
                    isSubmitBtn={selectedContractValue && selectedContractValue.isManualTermSheetUpload?this.state.signedTermSheetFile.length == 0:!selectedFunder}
                    promptOnClose={!this.state.signedTermSheetFile.length == 0}
                    onClose={e => this.props.onClose()}
                    children={
                        <div>
                            {!this.state.isNextButtonEnabled ? (
                                <>

                                    <p className="text-muted">
                                        {this.props.t("grid_view_header_labels.created")}{" "}
                                        {localDateFormatWithoutBracketTime(data.createdAt)}
                                    </p>
                                    <table className="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" />
                                                <th scope="col">{this.props.t("grid_view_header_labels.name")}</th>
                                                <th scope="col">{this.props.t("grid_view_header_labels.address")}</th>
                                                {data.fundingType.indexOf("ABL") == 0 ? (<>
                                                    <th scope="col">{this.props.t("funding_offer_details.abl_advance_rate")}</th>
                                                    <th scope="col">{this.props.t("funding_offer_details.abl_eligible_inventory")}</th>
                                                    <th scope="col">{this.props.t("funding_offer_details.line_of_credit")} / {this.props.t("funding_offer_details.loan")}</th>
                                                    <th scope="col">{this.props.t("funding_offer_details.total_financing_fees_abl")}</th>
                                                </>) : (<>
                                                    {(data.fundingType.indexOf("TCF") != 0 && data.fundingType.indexOf("SupplyChain") != 0) && (
                                                        <th scope="col">{this.props.t("funding_offer_details.advance_rate")} (%)</th>
                                                    )}
                                                    {data.fundingType.indexOf("SupplyChain") != 0 && (
                                                        <th scope="col">{this.props.t("funding_offer_details.factoring_rate")} (%)</th>
                                                    )}
                                                    {(data.fundingType.indexOf("TCF") != 0 && data.fundingType.indexOf("SupplyChain") != 0) && (
                                                        <th scope="col">{this.props.t("funding_offer_details.is_recourse")} ?</th>
                                                    )}
                                                    <th scope="col">{data.fundingType.indexOf("SupplyChain") != 0 ? this.props.t("funding_offer_details.other_fees") : this.props.t("funding_offer_details.offer_details_title")}</th>
                                                    {_isCreditLimitShow && (
                                                        <th scope="col">{this.props.t("credit_limit_for_buyers")}</th>
                                                    )}
                                                    <th scope="col">{this.props.t("funding_offer_details.term_sheet_label")}</th>
                                                </>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.contracts.map((offer, i) => (
                                                <Row
                                                    index={i}
                                                    currentUserEndpoint={
                                                        this.api.currentUserEndpoint
                                                    }
                                                    fundingId={data._id}
                                                    offerDetails={offer.terms}
                                                    funder={offer.funderDetails}
                                                    contractId={offer._id}
                                                    selectFunder={this.selectFunder}
                                                    fundingAggrement={offer.fundingAggrement}
                                                    contractType={offer.contractType}
                                                    creditLimit={offer.creditLimitForTradingPartner}
                                                    FileViewerModalClick={this.FileViewerModalClick}
                                                    contractDetails={offer}
                                                    showCreditLimit={_isCreditLimitShow}
                                                    selectedContractId={this.state.selectedFunder}
                                                    showSigningPopuModal={this.showSigningPopuModal}
                                                    t={this.props.t}
                                                    isManualTermSheetUpload={offer.isManualTermSheetUpload}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </>) : (
                                <>
                                    <div className='row mx-3'>
                                        <div className='col-6'>
                                            {this.state.selectedContractValue && (<>
                                                <>
                                                    <label className="view-details-header pb-2">{this.props.t("view_funding_offer.selected_funding_offer_details")}</label>
                                                    {this.props.t("grid_view_header_labels.name")}:{" "}
                                                    <strong className="link-text-color">
                                                        {this.state.selectedContractValue.funderDetails.name}
                                                    </strong>
                                                    <br />
                                                    {this.props.t("grid_view_header_labels.address")}:{" "}
                                                    <strong className="link-text-color">
                                                        {this.state.selectedContractValue.funderDetails.address}
                                                    </strong>
                                                    <br />
                                                        {this.state.selectedContractValue.contractType.indexOf("SupplyChain")== 0 ? (
                                                            <>
                                                                {this.props.t("funding_offer_details.other_note")}:{" "}
                                                                <strong className="link-text-color">
                                                                    {this.state.selectedContractValue.terms.offernote}
                                                                </strong>
                                                                <br />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {this.props.t("funding_offer_details.advance_rate")}:{" "}
                                                                <strong className="link-text-color">
                                                                    {this.state.selectedContractValue.terms.advancerate}%
                                                                </strong>
                                                                <br />
                                                                {this.props.t("funding_offer_details.factoring_rate")}:{" "}
                                                                <strong className="link-text-color">
                                                                    {this.state.selectedContractValue.terms.factoringrate}%
                                                                </strong>
                                                                <br />
                                                                {this.props.t("funding_offer_details.repayment_days")} :{" "}
                                                                <strong className="link-text-color">
                                                                    {this.state.selectedContractValue.terms.lengthoftimeforpayment == "15" ? "International" : this.state.selectedContractValue.terms.lengthoftimeforpayment + " days"}
                                                                </strong>
                                                                <br />
                                                                {this.props.t("funding_offer_details.recourse")}:{" "}
                                                                <strong className="link-text-color">
                                                                    {((this.state.selectedContractValue.terms.isRecourse).toString() == "false") ? "No" : "Yes"}
                                                                </strong>
                                                                <br />
                                                                {this.props.t("funding_offer_details.other_fees")}:{" "}
                                                                <strong className="link-text-color">
                                                                    {this.state.selectedContractValue.terms.offernote}
                                                                </strong>
                                                                <br />
                                                            </>
                                                        )}

                                                    {this.props.t("funding_offer_details.term_sheet_label")}:{" "}
                                                    {(this.state.selectedContractValue.termSheetFile) ? (

                                                        <a className="anchorstyle" href={""} onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                isFileViewerModal: true,
                                                                isFinancialDocument: false,
                                                                fileViewerContent: this.state.selectedContractValue.termSheetFile
                                                            })
                                                        }}>{this.props.t("funding_offer_details.view_term_sheet")}</a>
                                                    ) : (
                                                        <strong className="link-text-color">{this.props.t("funding_offer_details.no_term_sheet_msg")}</strong>
                                                    )}
                                                </>
                                            </>)}
                                        </div>
                                        <div className='col-6'>
                                            <label className="view-details-header pb-2">{this.props.t("funding_offer_details.Upload_signed_term_sheet")} </label>
                                                <FileUploader
                                                    documents={this.state.signedTermSheetFile && this.state.signedTermSheetFile.length > 0 && this.state.signedTermSheetFile[0] ? this.state.signedTermSheetFile[0] : []}
                                                    onDocUpload={this.handleSignedTermSheet}
                                                    onDelete={filteredDocs => { this.setState({ signedTermSheetFile: [] }); }}
                                                    onView={this.handleView}
                                                    namelength={50}
                                                    supportedExt={['.pdf']}
                                                    placeholder={this.props.t("file_upload_msg.signed_term_sheet_placeholder")}
                                                    isdisabled={false}
                                                    isMultiple={false}
                                                    isExtraction={false}
                                                    isDeleteEnable={true}
                                                    customHeight={"30vh"}
                                                />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    }
                />
            </>
        )
    }
}


const Row = ({
    currentUserEndpoint,
    fundingId,
    offerDetails,
    funder: { _id, name, address, city, state, zip,country },
    selectFunder,
    contractId,
    fundingAggrement,
    contractType,
    creditLimit,
    FileViewerModalClick,
    contractDetails,
    showCreditLimit,
    selectedContractId,
    showSigningPopuModal,
    t,
    isManualTermSheetUpload
}) => (
    <tr>
        <th scope="row">
            <input
                type="radio"
                name="funderRadios"
                id="funderRadio"
                checked={contractId==selectedContractId}
                value={contractId}
                onChange={e => selectFunder(e)}
            />
        </th>
        <td>{name}</td>
        <td>{address}, {city}<br />{state}-{zip},<br/>
        {country?country:""}</td>
        {contractType.indexOf("ABL") == 0 ? (<>
            <td>{offerDetails.advanceRateOnEligibleAR}%</td>
            <td>{offerDetails.advanceRateOnEligibleInventory}%</td>
            {offerDetails.lineOfCredit.length > 0 && (
                <td><b>{t("funding_offer_details.line_of_credit")}: </b>{offerDetails.lineOfCredit}</td>
            )}
            {offerDetails.loan.length > 0 && (
                <td><b>{t("funding_offer_details.loan")}: </b>{offerDetails.loan}</td>
            )}
            <td>{offerDetails.totalFinancingFee}</td>
        </>) : (<>
            {contractType.indexOf("TCF") != 0 && contractType.indexOf("SupplyChain") != 0 && (
                <td>{offerDetails.advancerate}</td>
            )}
                {contractType.indexOf("SupplyChain") != 0 && (
                    <td>{offerDetails.factoringrate} <br /> {offerDetails.lengthoftimeforpayment && contractType.indexOf("TCF") != 0 && (
                        <i>{t("funding_offer_details.length_of_time_for_payment")}: {offerDetails.lengthoftimeforpayment == "15" ? "International" : offerDetails.lengthoftimeforpayment +" "+t("common_names.days")}</i>
                    )}</td>
                )}
            {contractType.indexOf("TCF") != 0  && contractType.indexOf("SupplyChain") != 0 && (
                <td>
                    {offerDetails.isRecourse && (<span>{offerDetails.isRecourse === "true" ? 'Yes' : 'No'}</span>)}
                </td>
            )}
            {offerDetails.offernote.length > 100 ? <td className='offerNote-width' title={offerDetails.offernote} >{offerDetails.offernote.substring(0, 100) + '...'}</td> : <td className='offerNote-width' >{offerDetails.offernote}</td>}
            {showCreditLimit && (
                <td>
                    <div className='d-flex flex-column'>
                        {creditLimit && Object.keys(creditLimit).map((tp_, i) => {
                            return (<>
                                <div> {creditLimit[tp_].tradingPartnerName} - {creditLimit[tp_].creditLimitValue == 0 ? "No Credit Limit" : dollarFormatter.format(creditLimit[tp_].creditLimitValue)}</div>
                            </>)
                        })}
                    </div>
                </td>
            )}
        </>)}
        <td>
            {contractDetails && contractDetails.termSheetFile ?
                <><a className="anchorstyle" href={""} onClick={e => FileViewerModalClick(e, contractDetails.termSheetFile)}>{t("funding_offer_details.view_term_sheet")}</a>
                    &nbsp;
                    {!isManualTermSheetUpload && (
                        <>
                            <Tooltip title={t("funding_offer_details.click_to_view_electronic_signature")} arrow id='signed-term-sheet'>
                                <InfoIcon fontSize="small" style={{ color: "#063A52", cursor: "pointer" }} onClick={(e) => {
                                    showSigningPopuModal(contractDetails)
                                }} />
                            </Tooltip>
                        </>
                    )}
                </>
                : t("funding_offer_details.no_term_sheet_msg")}
        </td>

    </tr>
);

