import { Component } from "react";
import React from 'react';
import User from "../../services/User";
import DataGrid from '../common/DataGrid/DataGrid';
import DataGridFilter from '../common/DataGrid/DataGridFilter';
import API, { HEADERS } from "../../services/API";
import Popup from "../common/Auth/Popup";
import CommonConfirmationModal, { AddModal } from "../common/Modals/CommonConfirmationModal";
import FullSpinner from "../common/Spinner/FullSpinner";
import { localDateFormatWithTime, getEntity, getNewIndustryList } from "../../services/Utilities";
import ViewFunderDetails from "./ViewFunderDetails";
import UserDetailsModal from "./UserDetailsModal";
import './userList.css';
import { SpinnerService } from "../common/Spinner/FullSpinner";
import FinancialDocumentsModal from "../common/FinancialDocuments/FinancialDocumentsModal";
import {compareRole,enumRole,enumUserStatus} from "../../configs/GlobalConfig";
import FinancialInformationModal from "./FinancialInformationModal";
import CommonPopUpModal from "../CommonPopUpModal/CommonPopUpModal";
import { withTranslation } from "react-i18next";


async function fetchWrapper(url_, options_, responseReader_) {
    SpinnerService.emit(true);
    const response = await fetch(url_, options_);
    if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        SpinnerService.emit(false);
        throw new Error(message);
    }
    if (responseReader_) {
        let finalResponse = await responseReader_(response);
        SpinnerService.emit(false);
        return finalResponse;
    }

    SpinnerService.emit(false);
    return response;
};


class UserList extends Component {
    user = new User();
    api = new API();

    roles = [
        {
            value: "Supplier",
            text: "Supplier"
        },
        {
            value: "Funder",
            text: "Funder"
        },
        {
            value: "Buyer",
            text: "Buyer"
        }
    ];

    _columnsSpecification = [
        {
            key: "name",
            name: "Name",
            contentProvider: (datum_) => {
                return <div><a onClick={() => { this.isPopupOpen(datum_); }} href='#'>{datum_['name']}</a></div>;
            }
        },
        {
            key: "email",
            name: "Email",
            // eventType: "view",
            contentProvider: (datum_) => {
                if (((compareRole(datum_["role"],enumRole.Buyer)) && datum_["status"] == undefined)) {
                    return  <div></div>;
                }
                else{
                    return <div>{datum_['email']}</div>;
                }
               
            }
        },
        {
            key: "role",
            name: "Role",
            // eventType: "view",
            contentProvider: (datum_) => {
                if (datum_.role) {
                    datum_.isNoMoreLink = true;
                    return <div>{datum_['role']}</div>;
                }
                // else {
                //     datum_.isNoMoreLink = false;
                //     return <div>{datum_['role']}</div>;
                // }

            }
        },
        {
            key: "phone",
            name: "Phone",
            // eventType: "view",
            contentProvider: (datum_) => {
                return <div>{datum_['phone']}</div>;
            }
        },
        {
            key: "status",
            name: "Action",
            // eventType: "view",
            contentProvider: (datum_) => {
                if (((compareRole(datum_["role"],enumRole.Buyer) || compareRole(datum_["role"],enumRole.Supplier)) && datum_["status"] == undefined)) {
                    return <div >
                        <p style={{ color: "#32CD32" }}> N/A</p>
                    </div>;
                }
                if ((datum_.isInvitedBuyer && datum_.status!=3) || (datum_.isInvitedSupplier && datum_.status!=3)) {
                    return <div >
                        <p style={{ color: "#32CD32" }}> Invitation Accepted</p>
                    </div>;
                }

                if (datum_['status'] == 0) {
                    return <div style={{ width: "200px" }}>
                        <button className="submit-invoice-button"
                            onClick={() => { this.changeUserStatus(datum_['email'], 1); }}>Approve</button>
                        &nbsp;
                        <button
                            className="submit-invoice-button"
                            onClick={() => { this.changeUserStatus(datum_['email'], -1); }}>Reject</button>
                    </div>;
                }
                else if (datum_['status'] == enumUserStatus.Approved) {
                    return <div >
                        <p style={{ color: "#32CD32" }}> Approved</p>
                    </div>;
                }
                else if (datum_['status'] == enumUserStatus.Rejected) {
                    return <div >
                        <p style={{ color: "#ff7f7f" }}>Rejected</p>
                    </div>;
                }
                else if (datum_['status'] == enumUserStatus.Disabled) {
                    return <div >
                        <p style={{ color: "#ff7f7f" }}>Disabled by Admin</p>
                    </div>;
                }

            }
        },
        {
            key: "createdAt",
            name: "Created Date",
            // eventType: "view",
            contentProvider: (datum_) => {
                return localDateFormatWithTime(datum_.createdAt);
            }
        }

    ];

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            isPopupOpen: false,
            clickedUser: {},
            filtersApplied: {
                archived: true,
                roles: [],
                textFilter: ""
            },
            filteredUsers: [],
            isResetFilterEnabled: false,
            isAddFunderDescription: false,
            selectedUser: null,
            funderDescription: "",
            isLoading: false,
            isFunderUserDetails: false,
            isShowFinancialDocuments:false,
            isShowDisableUserPopup:false
        };
        this.getuserList = this.getuserList.bind(this);
        this.changeUserStatus = this.changeUserStatus.bind(this);
        this.isPopupOpen = this.isPopupOpen.bind(this);
        this.popupContent = this.popupContent.bind(this);
    }

    componentDidMount() {
        this.getuserList();
    }

    async isPopupOpen(clickedUser) {
        try {
            const selectedUserObject = await this.api.selectedUserWithCsl(clickedUser._id)
            if (clickedUser.role === "Funder" && clickedUser.midDesk) {
                this.setState({
                    isFunderUserDetails: true,
                    clickedUser: selectedUserObject,
                });
            } else {
                this.setState({ isPopupOpen: !this.state.isPopupOpen, clickedUser: selectedUserObject });
            }
        } catch (err) {
            console.error("Error fetching user information:", err);
        }
    }

    async handleShowFinancialInformation(row) {
        try {
            const selectedUserObject = await this.api.selectedUserInformation(row._id);
            this.setState({
                isShowFinancialInformation: true,
                selectedUser: selectedUserObject,
            });
        } catch (err) {
            console.error("Error fetching user information:", err);
        }
    };

    getuserList() {
        fetchWrapper(this.api.currentUserEndpoint() + '/allUsers', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Basic ' + this.user.currentUser()['token'],
                "content-type": "application/json",
                "cache-control": "no-cache",
                "access-control-allow-credentials": true,
                "access-control-allow-origin": `${process.env.REACT_APP_UI_URL}`
            }),
            credentials: "include"
        }, async (request_) => await request_.json()).then((userList) => {
            //console.log(userList);
            this.setState({ users: [...userList], filteredUsers: [] }, () => {
                this.onFilterChange();
            });
        }).catch((err) => {
            localStorage.removeItem('LEDGER_FUNDING_CURRENT_USER');
            window.location.href = '/admin';
        });
    }
    changeUserStatus(email, status) {
        AddModal("Confirm",
        <div className="d-flex flex-column align-items-center text-center">
            <div>{`Are you sure you want to ${ status == 1 ? "approve":"reject"}?`}</div>
            <div className="wordBreak"><b>{email}</b></div>
        </div>
        ,)
            .then(r => {
                fetch(this.api.currentUserEndpoint() + '/editUserStatus', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Basic ' + this.user.currentUser()['token'],
                        "content-type": "application/json",
                        "cache-control": "no-cache",
                        "access-control-allow-credentials": true,
                        "access-control-allow-origin": `${process.env.REACT_APP_UI_URL}`
                    }),
                    body: JSON.stringify({ email: email, status: status }),
                    credentials: "include"
                }).then((result) => {
                    return result.json();
                }).then((updatedUser) => {
                    this.getuserList();
                });
            })
            .catch(()=>{
            });
    }

    _hasResetFilterEnabled = () => {
        if (!this.state.filtersApplied.archived || this.state.filtersApplied.roles.length || this.state.filtersApplied.textFilter) {
            this.setState({ isResetFilterEnabled: true });
        }
        else {
            this.setState({ isResetFilterEnabled: false });
        }
    };

    onFilterChange() {
        let filterText = "";
        if (this.state.filtersApplied.textFilter) {
            filterText = this.state.filtersApplied.textFilter.toLowerCase();
        }
        let filteredUsers = this.state.users.filter(user => {
            if (this.state.filtersApplied.archived) {
                if (((compareRole(user.role,enumRole.Buyer) || compareRole(user.role,enumRole.Supplier)) && user.status == undefined) || (user.status == 1 || user.status == -1)) {
                    return false;
                }
            }
            if (this.state.filtersApplied.roles.length && this.state.filtersApplied.roles.map(r => r.value).indexOf(user.role) == -1) {
                return false;
            }
            if (filterText) {
                return user.name.toLowerCase().indexOf(filterText) != -1 ||
                    user.email.toLowerCase().indexOf(filterText) != -1;
            }

            return true;
        });
        this.setState({ filteredUsers: filteredUsers }, () => {
            this._hasResetFilterEnabled();
        });
    }

    _resetFilter = () => {
        this.state.filtersApplied.archived = true;
        this.state.filtersApplied.roles.splice(0);
        this.setState({
            filtersApplied: this.state.filtersApplied,
            roles: this.state.filtersApplied.roles,
            textFilter: ""
        }, () => this.onFilterChange());
    };

    render() {
        const { users, filteredUsers} = this.state;
        const funderData_ = this.state.selectedUser
        return (
            <React.Fragment>
                <FullSpinner
                    isSpinning={this.state.isLoading}
                    confirmationLabel="Added funder description"
                />
                {/* {this.state.isPopupOpen && <Popup className="small"
                    content={this.popupContent()}></Popup>} */}
                {this.state.isPopupOpen &&
                    <UserDetailsModal
                        data={this.state.clickedUser}
                        onClose={(isGetUserCall_) => {
                            this.setState({ clickedUser: {}, isPopupOpen: !this.state.isPopupOpen },()=>{
                                if(isGetUserCall_){
                                    this.getuserList();
                                }
                            })
                        }}
                        allUsersData={this.state.users}
                    ></UserDetailsModal>}
                {this.state.isAddFunderDescription && this.state.selectedUser &&
                    <CommonPopUpModal
                        title="Funder Description"
                        primaryBtn='Submit'
                        onClose={() => {
                            this.setState({
                                isAddFunderDescription: !this.state.isAddFunderDescription, clickedUser: {}
                                , funderDescription: ""
                            });
                        }}
                        isSubmitBtn={this.state.funderDescription.length == 0}
                        promptOnClose={!this.state.funderDescription.length == 0}
                        onSubmit={async () => {
                            this.setState({ isLoading: true });
                            const getFunderDescriptionStatus = await fetch(this.api.currentUserEndpoint() + '/addFunderDescription', {
                                method: 'POST',
                                headers: HEADERS(),
                                body: JSON.stringify({ email: funderData_.email, description: this.state.funderDescription }),
                                credentials: "include"
                            }).then((result) => {
                                return result.json();
                            });

                            this.setState({ isLoading: false, isAddFunderDescription: !this.state.isAddFunderDescription, clickedUser: {}, funderDescription: "" });
                            this.getuserList();
                        }}


                    >
                        <div className="user-data-row-1">
                            <textarea
                                style={{ resize: "none" }}
                                type="text"
                                className="form-control"
                                name="funds"
                                rows={7}
                                value={this.state.funderDescription.length > 0 ? this.state.funderDescription : funderData_.description}
                                onChange={(e) => {
                                    this.setState({ funderDescription: e.target.value });
                                }}
                                placeholder="Funder Description"
                            ></textarea>
                        </div>
                    </CommonPopUpModal>
                }
                {
                    this.state.isFunderUserDetails && (<ViewFunderDetails
                        data={this.state.clickedUser}
                        onClose={() => {
                            this.setState({ clickedUser: {}, isFunderUserDetails: false })
                        }}
                    />)
                }
                {this.state.isShowFinancialDocuments && this.state.selectedUser
                    && (
                        <FinancialDocumentsModal
                            isReadOnly={true}
                            data={this.state.selectedUser}
                            onClose={() => {
                                this.setState({ isShowFinancialDocuments: false, selectedUser: null })
                            }}
                        />

                       
                    )
                }
                {this.state.isShowFinancialInformation && this.state.selectedUser
                    && (
                        <FinancialInformationModal
                            isReadOnly={true}
                            data={this.state.selectedUser}
                            onClose={() => {
                                this.setState({ isShowFinancialInformation: false, selectedUser: null })
                            }}
                        />


                    )
                }
                <div className="header-titlebar">
                    <h3>
                        Users List
                    </h3>
                </div>
                <div className="content-container user-list">
                    <DataGrid
                        title="Users"
                        // emptydataLabel="No Invoice(s) Found"
                        data={filteredUsers}
                        columns={this._columnsSpecification}
                        isNoPagination={true}
                        isRowsNonSelectable={true}
                        isNoMoreLink={false}
                        isGridSearch={true}
                        appliedFilter={this.state.filtersApplied}
                        resetFilters={() => this._resetFilter()}
                        searchText={(text_) => {
                            this.state.filtersApplied.textFilter = text_;
                            this.setState({
                                filtersApplied: this.state.filtersApplied
                            }, () => this.onFilterChange());
                        }}
                        filterComponent={
                            <React.Fragment>
                                <DataGridFilter
                                    label="Role"
                                    type="options"
                                    options={this.roles}
                                    value={this.state.filtersApplied.roles}
                                    change={(filter_) => {
                                        this.state.filtersApplied.roles = filter_;
                                        this.setState({
                                            filtersApplied: this.state.filtersApplied
                                        }, () => this.onFilterChange());
                                    }}
                                />
                                <DataGridFilter
                                    label="New Users Only"
                                    type="boolean"
                                    value={this.state.filtersApplied.archived}
                                    change={(filter_) => {
                                        this.state.filtersApplied.archived = filter_;
                                        this.setState({
                                            filtersApplied: this.state.filtersApplied
                                        }, () => this.onFilterChange());
                                    }}
                                />
                            </React.Fragment>
                        }
                        popoverItems={

                            [
                                { key: "addFunderDescription", label: "Funder Description" },
                                { key: "showFinancialInformation", label: "Financial Information" },
                                { key: "showFinancialDocuments", label: "Transactions" },
                                { key: "disableUser",label:"Disable user"}
                            ]
                        }
                        onPopoverClick={(key_, row_) => {
                            if (key_ == "addFunderDescription") {
                                this.setState({
                                    isAddFunderDescription: true,
                                    selectedUser: row_,
                                })
                            }
                            else if(key_=="showFinancialDocuments"){
                                this.setState({
                                    isShowFinancialDocuments: true,
                                    selectedUser: row_,
                                })
                            }
                            else if (key_ == "showFinancialInformation") {
                                // this.setState({
                                //     isShowFinancialInformation: true,
                                //     selectedUser: row_,
                                // })
                                this.handleShowFinancialInformation(row_);
                            }
                            else if(key_=="disableUser"){
                                AddModal("Disable User", <div className="row">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <div>{`Are you sure you want to disable?`}</div>
                                        <div className="wordBreak"><b>{row_.email}</b></div>
                                    </div>
                                </div>,)
                                    .then(async (r) => {
                                        if(r){
                                          await this.api.disableUser(row_._id,row_.name);
                                          this.getuserList();
                                        }
                                    })            
                                    .catch(()=>{
                                    });
                            }
                        }}
                        isResetFilterEnabled={this.state.isResetFilterEnabled}
                    />
                </div>
            </React.Fragment>
        );
    }

    getIndustryName = (index_) => {
        const industryList_ = getNewIndustryList;
        const industry_ = industryList_.find((ind_, i) => {
            if (i + 1 == index_) {
                return ind_;
            }
        });
        return industry_.industryName ? industry_.industryName : "";
    };

    popupContent() {
        return (<div className="popup-container">
            <div className="popup-header-container">
                <h3>
                    User Details
                </h3>
                <div className="cross-icon">
                    <label className="popup-close-icon" onClick={() => {
                        this.setState({ isPopupOpen: !this.state.isPopupOpen, clickedUser: {} });
                    }} />
                </div>
            </div>
            <div className="user-data-row-1">
                <div className="padding-right">
                    <label>Email :</label>
                    <p>{this.state.clickedUser['email']}</p>
                </div>
                <div className="padding-right">
                    <label>Business Name:</label>
                    <p>{this.state.clickedUser['name']}</p>
                </div>
                <div className="padding-right">
                    <label>Role:</label>
                    <p>{this.state.clickedUser['role']}</p>
                </div>
                <div className="padding-right">
                    <label>Status:</label>
                    <p>{this.state.clickedUser['status'] == 0 ? 'Waiting For Approval' :
                        (this.state.clickedUser['status'] == 1) ? 'Approved' : "Rejected"}</p>
                </div>
                <div className="padding-right">
                    <label>Tax ID:</label>
                    <p>{this.state.clickedUser['taxId']}</p>
                </div>
            </div>
            <div className="user-data-row-1">
                {/* <div className="padding-right">
                    <label>Email verified:</label>
                    <p>{this.state.clickedUser['emailVerified'] ? 'Verified' : 'Not yet'}</p>
                </div> */}
                <div className="padding-right">
                    <label>Full Name:</label>
                    <p>{this.state.clickedUser['userName']}</p>
                </div>
                <div className="padding-right">
                    <label>Job Title :</label>
                    <p>{this.state.clickedUser['title']}</p>
                </div>
                <div className="padding-right">
                    <label>Launched year:</label>
                    <p>{this.state.clickedUser['year']}</p>
                </div>
                <div className="padding-right">
                    <label>City :</label>
                    <p>{this.state.clickedUser['city']}</p>
                </div>
                <div className="padding-right">
                    <label>State:</label>
                    <p>{this.state.clickedUser['state']}</p>
                </div>
                <div className="padding-right">
                    <label>Zip:</label>
                    <p>{this.state.clickedUser['zip']}</p>
                </div>
                <div className="padding-right">
                    <label>Country:</label>
                    <p>{this.state.clickedUser.country ? this.state.clickedUser.country : ""}</p>
                </div>
            </div>
            <div className="user-data-row-1">
                <div className="padding-right">
                    <label>Address:</label>
                    <p>{this.state.clickedUser['address']}</p>
                </div>
                <div className="padding-right">
                    <label>Website:</label>
                    <p>{this.state.clickedUser['websiteUrl']}</p>
                </div>
                <div className="padding-right">
                    <label>Entity:</label>
                    <p>{getEntity(this.state.clickedUser['entity'])}</p>
                </div>
                {this.state.clickedUser.industry && (
                    <div className="padding-right">
                        <label>Industry:</label>
                        <p>{this.getIndustryName(this.state.clickedUser.industry)}</p>
                    </div>
                )}
                <div className="padding-right">
                    <label>Phone Number:</label>
                    <p>{this.state.clickedUser['phone']}</p>
                </div>
            </div>
        </div>);
    }
}
export default withTranslation() (UserList);