import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faExclamationTriangle, faSortUp, faSortDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

export default function NavigationTabs() {
    return (
        <div>

        </div>
    )
}

export const DocumentProductNavTabs = ({ openInvoices, inFundingInvoices, archivedInvoices, uploadBtn, activeProgressTab, uploadInvoices, tabNameChange, activeTabName}) => {
    const {t}=useTranslation();
    return (
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li
                className="nav-item"
                onClick={() =>
                    activeProgressTab("openInvoices")
                }
            >
                <a
                    className={"nav-link" + (activeTabName == "openInvoices" ? " active" : "")}
                    id="nav-open-tab"
                    data-toggle="tab"
                    href="#nav-open"
                    role="tab"
                    aria-controls="nav-open"
                    aria-selected="true"
                >
                    {tabNameChange ? t("tab_names.matched") : t("tab_names.open")} ({openInvoices})
                </a>
            </li>
            <li
                className="nav-item"
                onClick={() =>
                    activeProgressTab("inFundingInvoices")
                }
            >
                <a
                    className={"nav-link" + (activeTabName == "inFundingInvoices" ? " active" : "")}
                    id="nav-inFunding-tab"
                    data-toggle="tab"
                    href="#nav-inFunding"
                    role="tab"
                    aria-controls="nav-inFunding"
                    aria-selected="true"
                >
                    {t("tab_names.in_funding")} ({inFundingInvoices})
                </a>
            </li>
            <li
                className="nav-item"
                onClick={() =>
                    activeProgressTab("archivedInvoices")
                }
            >
                <a
                     className={"nav-link" + (activeTabName == "archivedInvoices" ? " active" : "")}
                    id="nav-archived-tab"
                    data-toggle="tab"
                    href="#nav-archived"
                    role="tab"
                    aria-controls="nav-archived"
                    aria-selected="true"
                >
                    {t("tab_names.archived")} ({archivedInvoices})
                </a>
            </li>
            {uploadBtn ?
                <li className="nav-item" style={{ right: 0, marginRight: 15, position: "absolute" }}>
                    <button
                        className="btn btn-primary"
                        style={{ width: 150 }}
                        onClick={() => {
                            uploadInvoices()
                        }}
                    >
                        <FontAwesomeIcon
                            className="mr-2"
                            icon={faFileUpload}
                        />Upload</button>
                </li> : null}

        </ul>);
}