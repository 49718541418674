import API,{
    API_ENDPOINT_SUPPLIER,
    API_ENDPOINT_BUYER,
    V2_API_ENDPOINT_SUPPLIER,
    V2_API_ENDPOINT_BUYER
} from "./API";
import User from "./User";
import { AddModal } from "../components/common/Modals/CommonConfirmationModal";
import { compareRole,enumRole } from "../configs/GlobalConfig";


export const isChangeManageUser = async (userId_) => {
    let _localStorageManageUsers = localStorage.getItem("managedUsers");
    const _localStorageSelectedManageUser = localStorage.getItem("selectedManagedUser");
    _localStorageManageUsers = _localStorageManageUsers ? JSON.parse(_localStorageManageUsers) : _localStorageManageUsers;
    if(_localStorageManageUsers){
        const _currentSelectedUser = _localStorageManageUsers[_localStorageSelectedManageUser];
        if (_currentSelectedUser && _currentSelectedUser.isAdmin) {
            if(_currentSelectedUser._id==userId_){
                return {isAdmin:true,isChangeUser:false};
            }
            else {
                const _isConfirm=await AddModal("Confirm","Are you sure you want to switch the manager of this user?");
                if(_isConfirm){
                    return {isAdmin:false,isChangeUser:true};
                }
                else{
                    return {isAdmin:false,isChangeUser:false};
                }
            }
        }
        else {
            if(_currentSelectedUser._id==userId_){
                return {isAdmin:true,isChangeUser:false};
            }
            else{
                AddModal("Confirm",
                    "Are you sure you want to switch the manager of this user?"
                    ,)
                    .then(r => {
                        return {isAdmin:false,isChangeUser:true};
                    })
                    .catch(() => {
                        return {isAdmin:false,isChangeUser:false};
                    });
            }

        }
    }
    else{
        return {isAdmin:true,isChangeUser:false};
    }

}

export const switchManageUser = async (userId_) => {
    debugger;
    let _api=new API();
    let _user=new User();
    let _switchedUserDetails=null;
    let _manageUserDetails=null;
    let _localStorageManageUsers = localStorage.getItem("managedUsers");
    _localStorageManageUsers = _localStorageManageUsers ? JSON.parse(_localStorageManageUsers) : _localStorageManageUsers;
    _localStorageManageUsers.forEach((element,i) => {
        if(element._id==userId_){
            localStorage.setItem("selectedManagedUser",i);
            _manageUserDetails=element;
        }
    });
    if (_manageUserDetails) {
        _switchedUserDetails = await _api.changeManagedUserAccount(_manageUserDetails);
        if (_switchedUserDetails.verified) {
            if (_manageUserDetails.isAdmin) {
                let _existingAdminUser = localStorage.getItem("LEDGER_FUNDING_ADMIN_USER");
                localStorage.setItem("LEDGER_FUNDING_CURRENT_USER", _existingAdminUser);
                localStorage.removeItem("LEDGER_FUNDING_ADMIN_USER");
            }
            else {
                let _isExistingAdminData = localStorage.getItem("LEDGER_FUNDING_ADMIN_USER");
                if (!_isExistingAdminData) {
                    let _adminUser = localStorage.getItem("LEDGER_FUNDING_CURRENT_USER");
                    localStorage.setItem("LEDGER_FUNDING_ADMIN_USER", _adminUser);
                }
                localStorage.setItem("LEDGER_FUNDING_CURRENT_USER", JSON.stringify(_switchedUserDetails));
            }
        }
        if (compareRole(_manageUserDetails.role, enumRole.Supplier)) {
            _user.setCurrentUserRole(enumRole.Supplier);
            _api.setCurrentUserEndpoint(API_ENDPOINT_SUPPLIER);
            _api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_SUPPLIER);
        }
        else if (compareRole(_manageUserDetails.role, enumRole.Buyer)) {
            _user.setCurrentUserRole(enumRole.Buyer);
            _api.setCurrentUserEndpoint(API_ENDPOINT_BUYER);
            _api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_BUYER);
        }
        return _switchedUserDetails;
    }
}


export const clearImpersonationData=()=>{
    localStorage.removeItem("managedUsers");
    localStorage.removeItem("selectedManagedUser");
    localStorage.removeItem("LEDGER_FUNDING_ADMIN_USER");
}


export const isAdminForManagedUser=()=>{
    let _localStorageManageUsers = localStorage.getItem("managedUsers");
    const _localStorageSelectedManageUser = localStorage.getItem("selectedManagedUser");
    _localStorageManageUsers = _localStorageManageUsers ? JSON.parse(_localStorageManageUsers) : _localStorageManageUsers;
    if(_localStorageManageUsers){
        const _currentSelectedUser = _localStorageManageUsers[_localStorageSelectedManageUser];
        if (_currentSelectedUser && _currentSelectedUser.isAdmin) {
            return true;
        }
        else{
            return false;
        }
    }
    else{
        return true;
    }

}