import { SpinnerService } from "../components/common/Spinner/FullSpinner";
import { compareRole,enumRole } from "../configs/GlobalConfig";

async function fetchWrapper(url_, options_, responseReader_) {
  SpinnerService.emit(true);
  const response = await fetch(url_, options_);
  if (responseReader_) {
    let finalResponse = await responseReader_(response);
    SpinnerService.emit(false);
    return finalResponse;
  }

  SpinnerService.emit(false);
  return response;
};



export const HEADERS = () =>
  new Headers({
    "content-type": "application/json",
    "cache-control": "no-cache",
    "access-control-allow-credentials": true,
    "access-control-allow-origin": `${process.env.REACT_APP_UI_URL}`
  });

export const FILE_HEADERS = () =>
  new Headers({
    "cache-control": "no-cache",
    "access-control-allow-credentials": true,
    "access-control-allow-origin": `${process.env.REACT_APP_UI_URL}`
  });

const localStorageCurrentUserEndpoint = "LEDGER_FUNDING_CURRENT_USER_ENDPOINT";
const v2localStorageCurrentUserEndpoint = "V2_LEDGER_FUNDING_CURRENT_USER_ENDPOINT";
const TCFlocalStorageCurrentUserEndpoint = "TCF_LEDGER_FUNDING_CURRENT_USER_ENDPOINT";
const v2TCFlocalStorageCurrentUserEndpoint = "V2_TCF_LEDGER_FUNDING_CURRENT_USER_ENDPOINT";

export const API_ENDPOINT_SUPPLIER = `${process.env.REACT_APP_SUPPLIER_URL}/api/v1`;
export const API_ENDPOINT_FUNDER = `${process.env.REACT_APP_FUNDER_URL}/api/v1`;
export const API_ENDPOINT_BUYER = `${process.env.REACT_APP_BUYER_URL}/api/v1`;
export const API_ENDPOINT_ADMIN = `${process.env.REACT_APP_ADMIN_URL}/api`;

//New V2 API CALLS
export const V2_API_ENDPOINT_SUPPLIER = `${process.env.REACT_APP_SUPPLIER_URL}/api/v2`;
export const V2_API_ENDPOINT_FUNDER = `${process.env.REACT_APP_FUNDER_URL}/api/v2`;
export const V2_API_ENDPOINT_BUYER = `${process.env.REACT_APP_BUYER_URL}/api/v2`;

//ERP API CALLS
const V2_API_ENDPOINT_ERP_SUPPLIER = `${process.env.REACT_APP_ERP_URL}/api/v1`;

//QUICKBOOKS API CALLS
const V2_API_ENDPOINT_QUICKBOOK_SUPPLIER = `${process.env.REACT_APP_QB_URL}/api`;

//BUSINESS CENTRAL API CALLS
const V2_API_ENDPOINT_D365BC_SUPPLIER = `${process.env.REACT_APP_D365BC_URL}/api`;


export default class API {
  currentUserType = () => localStorage.getItem('LEDGER_FUNDING-CURRENT-USER-TYPE') || '';

  currentUserRole = () => localStorage.getItem('LEDGER_FUNDING_CURRENT_USER_ROLE') || '';

  // Get / Set the user endpoint
  getSupplierEndPointV2 = () => V2_API_ENDPOINT_SUPPLIER;

  setCurrentUserEndpoint = endpoint =>
    localStorage.setItem(localStorageCurrentUserEndpoint, endpoint);

  currentUserEndpoint = () =>
    localStorage.getItem(localStorageCurrentUserEndpoint) || "";

  setV2CurrentUserEndpoint = endpoint =>
    localStorage.setItem(v2localStorageCurrentUserEndpoint, endpoint);

  v2CurrentUserEndpoint = () =>
    localStorage.getItem(v2localStorageCurrentUserEndpoint) || "";

  setTaxcreditUserEndpoint = endpoint =>
    localStorage.setItem(TCFlocalStorageCurrentUserEndpoint, endpoint);

  taxCreditUserEndPoint = endpoint =>
    localStorage.getItem(TCFlocalStorageCurrentUserEndpoint, endpoint);

  setV2TaxcreditUserEndpoint = endpoint =>
    localStorage.setItem(v2TCFlocalStorageCurrentUserEndpoint, endpoint);

  v2taxCreditUserEndPoint = endpoint =>
    localStorage.getItem(v2TCFlocalStorageCurrentUserEndpoint, endpoint);

  currentUserRole = () => localStorage.getItem('LEDGER_FUNDING_CURRENT_USER_ROLE') || '';


  fetchWithTimeOut = (url, options, timeout = 8000) => {
    return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error('timeout')), timeout)
      )
    ]);
  };

  // Authentication
  logInWithEmailAndPassword = async user => {
    return (await fetchWrapper(`${this.currentUserEndpoint()}/auth/login`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(user),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };
  signUp = async user =>
    (await fetch(`${this.currentUserEndpoint()}/auth/signup`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(user),
      credentials: "include"
    })).json();

  v2SignUp = async user =>
  (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/signup`, {
    headers: HEADERS(),
    method: "POST",
    body: JSON.stringify(user),
    credentials: "include"
  })).json();

  sendApprovalEmail = async id =>
    await fetchWrapper(`${this.getSupplierEndPointV2()}/suppliers/approval`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify({
        userId: id
      }),
      credentials: "include"
    }, async (request_) => await request_.json());

  checkExistingUser = async (email) =>
    (await fetch(`${this.getSupplierEndPointV2()}/suppliers/checkemail?email=${email}&role=${this.currentUserRole()}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  changePassword = async password =>
    (await fetch(`${this.currentUserEndpoint()}/profile`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(password),
      credentials: "include"
    })).json();

  v2ChangePassword = async uptPassword =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/profile/password`, {
      headers: HEADERS(),
      method: "PATCH",
      body: JSON.stringify(uptPassword),
      credentials: "include"
    })).json();

  getAllEmailAddress = async () =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/allemailid`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

      // Authentication
  changeManagedUserAccount = async user => {
    return (await fetchWrapper(`${this.currentUserEndpoint()}/auth/switchManageUser`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(user),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  changeFundingAmounts = async amounts =>
    (await fetchWrapper(`${this.currentUserEndpoint()}/profile/filter-amounts`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(amounts),
      credentials: "include"
    }, async (request_) => await request_.json()));
  updateMinimumAnnualRevenue = async amounts => {
    return (await fetchWrapper(`${V2_API_ENDPOINT_FUNDER}/fundingcriterias/minimum-annual-revenue`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(amounts),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  updateMaxDebtorConcentration = async amounts =>
    (await fetchWrapper(`${V2_API_ENDPOINT_FUNDER}/fundingcriterias/maximum-debtor-concentration`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(amounts),
      credentials: "include"
    }, async (request_) => await request_.json()));

  UpdateAllFundingCriteria = async filters =>
    (await fetchWrapper(`${V2_API_ENDPOINT_FUNDER}/fundingcriterias/UpdateAllFilters`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(filters),
      credentials: "include"
    }, async (request_) => await request_.json()));

  filterPayTerms = async term =>
    (await fetch(`${this.currentUserEndpoint()}/profile/filter-pay-term`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(term),
      credentials: "include"
    })).json();

  filterIndustry = async industry =>
    (await fetch(`${this.currentUserEndpoint()}/profile/filter-industry`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(industry),
      credentials: "include"
    })).json();

  filterInvoice = async transcation => {
    console.log("filterInvoice called", JSON.stringify(transcation));
    (await fetch(`${this.currentUserEndpoint()}/profile/filter-invoice`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(transcation),
      credentials: "include"
    })).json();
  };

  filterPo = async transcation => {
    console.log("filterpo called", JSON.stringify(transcation));
    (await fetch(`${this.currentUserEndpoint()}/profile/filter-po`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(transcation),
      credentials: "include"
    })).json();
  };

  // TCF Invoices
  submitTCFInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/tcfinvoice`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();

  getTCFInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/tcfinvoice`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  // Invoices
  submitInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/invoice`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();



  getInvoices = async () => {
    if (this.currentUserType() == "TaxCreditFinancing") {
      return (await fetchWrapper(`${this.currentUserEndpoint()}/tcfinvoice`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
    else {
      if (this.currentUserRole() == "supplier") {
        return (await fetchWrapper(`${this.currentUserEndpoint()}/invoice`, {
          headers: HEADERS(),
          method: "GET",
          credentials: "include"
        }, async (request_) => await request_.json()));
      }
      else if (this.currentUserRole() == "buyer") {
        return (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document`, {
          headers: HEADERS(),
          method: "GET",
          credentials: "include"
        }, async (request_) => await request_.json()));
      }
    }
  };
  docGetInvoices = async () => {
    if (this.currentUserType() == "TaxCreditFinancing") {
      return (await fetchWrapper(`${this.currentUserEndpoint()}/tcfinvoice`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
    else {
      return (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document/invoice`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
  };

  getalldocuments = async () => {
    return (await fetch(`${this.currentUserEndpoint()}/funding-document/documents`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };

  getalldocForDashboard = async (requesterId_) => {
    let _url=requesterId_?`${API_ENDPOINT_SUPPLIER}/dashboard/all-docs/${requesterId_}`:`${API_ENDPOINT_SUPPLIER}/dashboard/all-docs`;
    return (await fetch(_url, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  getallInvdocForDashboard = async () => {
    return (await fetch(`${this.currentUserEndpoint()}/dashboard/invoice-docs`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };

  getallpaiddocuments = async () => {
    return (await fetch(`${this.currentUserEndpoint()}/funding-document/paid-document`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };

  createAGNonErpCustomers = async (customerId) =>
    (await fetch(`${this.currentUserEndpoint()}/funding-document/create-agingreport/${customerId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  docSignInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/funding-document/sign`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();


  uploadBuyerSignedInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/funding-document/upload-buyer-approved`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: invoice,
      credentials: "include"
    })).json();

  SubmitPurchaseOrderToSupplier = async po_ =>
    (await fetch(`${this.currentUserEndpoint()}/funding-document/submitsupplier`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(po_),
      credentials: "include"
    })).json();


  getArchivedInvoices = async () => {
    if (this.currentUserRole() == "supplier") {
      (await fetch(`${this.currentUserEndpoint()}/invoice/archived`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      })).json();
    }
    else if (this.currentUserRole() == "buyer") {
      return (await fetch(`${this.currentUserEndpoint()}/funding-document/archived`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      })).json();
    }
  };

  getAvailableInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/open/available`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getInProgressInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/open/in-progress`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getClosedMarketplaceInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/open/closed`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  approveInvoice = async invoice =>
    (await fetch(
      `${this.currentUserEndpoint()}/invoice/approve/${invoice.id}`,
      {
        headers: HEADERS(),
        method: "PUT",
        body: JSON.stringify(invoice),
        credentials: "include"
      }
    )).json();

  docApproveInvoice = async invoice =>
    (await fetchWrapper(
      `${this.currentUserEndpoint()}/funding-document/approve/${invoice.id}`,
      {
        headers: HEADERS(),
        method: "PUT",
        body: JSON.stringify(invoice),
        credentials: "include"
      }, async (request_) => await request_.json()
    ));

  docRejectInvoice = async invoice =>
    (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document/reject/${invoice.id}`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(invoice),
      credentials: "include"
    }, async (request_) => await request_.json()));


    docApproveSupplyChain = async invoice =>
    (await fetchWrapper(
      `${this.currentUserEndpoint()}/funding-document/supply-chain/approve/${invoice.id}`,
      {
        headers: HEADERS(),
        method: "PUT",
        body: JSON.stringify(invoice),
        credentials: "include"
      }, async (request_) => await request_.json()
    ));

    docRejectSupplyChain = async invoice =>
    (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document/supply-chain/reject/${invoice.id}`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(invoice),
      credentials: "include"
    }, async (request_) => await request_.json()));

  rejectInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/reject/${invoice.id}`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();

  signInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/sign`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();

  deleteDocument = async invoice =>
  (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-document/delete-document`, {
    headers: HEADERS(),
    method: "POST",
    body: JSON.stringify(invoice),
    credentials: "include"
  }, async (request_) => await request_.json()));

  SubmitSupplyChianDocumentToBuyer = async invoice =>
  (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-document/submit-sc-document`, {
    headers: HEADERS(),
    method: "POST",
    body: JSON.stringify(invoice),
    credentials: "include"
  }, async (request_) => await request_.json()));

  postTransactionVolume = async request =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/transactionvolume`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(request),
      credentials: "include"
    })).json();

  postAVGCollectionPeriod = async request =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/averagecollectionperiod`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(request),
      credentials: "include"
    })).json();


  //Universal flow   
  addSelectFunders = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-request/selectFunders`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(document),
      credentials: "include"
    })).json();
  };
  makeAnOffer = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-contract/makeoffer`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };
  acceptoffer = async (document) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-contract/acceptoffer`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    }, async (request_) => await request_.json()));
  };
  uploadFundingAgreementToContract = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-contract/upload-funding-agreement`, {
      headers:  FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  }
  uploadSignedAgreementToContract = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-contract/upload-signed-agreement`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };

  updateCreditLimitValue = async (document) => {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-contract/updateCreditLimit` , {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(document),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  getContracts = async () => {
    if (this.currentUserRole() != "funder" ) {
      return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-contract/getcontractbyrole`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
    else {
      return (await fetchWrapper(`${this.currentUserEndpoint()}/invoice/getContract`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
  };

  getContractsForDashboard = async () => {
    if (this.currentUserRole() != "funder") {
      return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/dashboard/supp-all-contract`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
  }

  getAllContracts = async () => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/funding-contract/getallcontract`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };

  acceptFundingRequest = async (data_) => {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-request/accept-funding`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(data_),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  declineFundingRequest = async (id) => {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-request/reject-funding/reject/${id}`, {
      headers: HEADERS(),
      method: "PUT",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };


  markasPaidFundingRequest = async (id) => {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-request/markaspaid/${id}`, {
      headers: HEADERS(),
      method: "PUT",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  getLatestMiddeskResponse = async (id) => {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-request//middesk-response/${id}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  terminateContract = async (id) => {
    if (this.currentUserRole() == "supplier" || this.currentUserRole() == "buyer") {
      return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-contract/terminate/${id}`, {
        headers: HEADERS(),
        method: "PUT",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
    else {
      return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-contract/terminate-funder/${id}`, {
        headers: HEADERS(),
        method: "PUT",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
  };


  getMatchedInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/open/matched`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getTCFMatchedInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/tcfinvoice/open/matched`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getOpenInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/open/in-progress`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getClosedInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/my/closed`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getMyInProgressInvoices = async () =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/my/in-progress`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  // Profile
  getNotifications = async () =>
    (await fetch(`${this.currentUserEndpoint()}/notification`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getUnreadNotificationsCount = async () =>
    (await fetch(`${this.currentUserEndpoint()}/notification/unread-count`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  putReadAllUnread = async () =>
    (await fetch(`${this.currentUserEndpoint()}/notification/read-all-unread`, {
      headers: HEADERS(),
      method: "PUT",
      credentials: "include"
    })).json();

  getProfile = async () =>
    (await fetch(`${this.currentUserEndpoint()}/profile`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getV2Profile = async (userId_) =>{
    let _url =userId_?`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile/getById/${userId_}`:`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile`;
    return (await fetch(_url, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  }



  updateProfile = async profile =>
    (await fetch(`${this.currentUserEndpoint()}/profile`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(profile),
      credentials: "include"
    })).json();

    saveAndUpdateQuestionnaire = async (details_,isCreate_) =>
     (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/profile/saveQuestionnarie`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(
        {
          "questionnaire":details_,
          "isCreate":isCreate_
        }),
      credentials: "include"
    }, async (request_) => await request_.json()));

  updateV2Profile = async profile =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/profile`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(profile),
      credentials: "include"
    })).json();

  checkIfDocumentationExists = async () =>
    (await fetch(`${this.currentUserEndpoint()}/file/isFileExists`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  checkV2IfDocumentationExists = async () =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile/arefinancialdocumentsexists`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();


  getfullAgingReport = async () =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/profile/getagingreportonly`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getfullPayableAgingReport = async () =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/profile/getpayableagingreportonly`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  uploadDocument = async document =>
    (await fetch(`${this.currentUserEndpoint()}/file/upload`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();

  UploadRPADocument = async (document, oid) =>
    (await fetch(`${V2_API_ENDPOINT_FUNDER}/fundingrequests/${oid}/fundingoffers`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();

  UploadSignedRPADocument = async (document, invoiceId) =>
    (await fetch(`${this.currentUserEndpoint()}/file/signedRPA/${invoiceId}`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();

  UploadTCFRPADocument = async (document, oid) =>
    (await fetch(`${V2_API_ENDPOINT_FUNDER}/fundingrequests/${oid}/fundingoffers`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();

  UploadTCFSignedRPADocument = async (document, invoiceId) =>
    (await fetch(`${this.currentUserEndpoint()}/file/signedRPA/${invoiceId}`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();

  fundInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/fund/${invoice.id}`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();


  payInvoice = async invoice =>
    (await fetch(`${this.currentUserEndpoint()}/invoice/pay/${invoice.id}`, {
      headers: HEADERS(),
      method: "PUT",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();

  uploadInvoices = async invoices => {
    if (this.currentUserType() == "TaxCreditFinancing") {
      return (await fetch(`${this.currentUserEndpoint()}/tcfinvoice/uploadInvoice`, {
        headers: HEADERS(),
        method: "POST",
        body: JSON.stringify(invoices),
        credentials: "include"
      })).json();
    }
    else {
      return (await fetch(`${this.currentUserEndpoint()}/invoice/uploadInvoice`, {
        headers: HEADERS(),
        method: "POST",
        body: JSON.stringify(invoices),
        credentials: "include"
      })).json();
    }
  };

  docUploadInvoices = async invoices => {
    if (this.currentUserType() == "TaxCreditFinancing") {
      return (await fetch(`${this.currentUserEndpoint()}/tcfinvoice/uploadInvoice`, {
        headers: HEADERS(),
        method: "POST",
        body: JSON.stringify(invoices),
        credentials: "include"
      })).json();
    }
    else {
      return (await fetch(`${this.currentUserEndpoint()}/funding-document/invoice/uploadInvoice`, {
        headers: FILE_HEADERS(),
        method: "POST",
        body: invoices,
        credentials: "include"
      })).json();
    }
  };

  inviteBuyers = async buyers =>
    (await fetch(
      `${this.currentUserEndpoint()}/invoice/uploadInvoiceWithNewBuyer`,
      {
        headers: HEADERS(),
        method: "POST",
        body: JSON.stringify(buyers),
        credentials: "include"
      }
    )).json();


  docInviteBuyers = async buyers =>
    (await fetch(
      `${this.currentUserEndpoint()}/funding-document/invoice/uploadInvoiceWithNewBuyer`,
      {
        headers: HEADERS(),
        method: "POST",
        body: JSON.stringify(buyers),
        credentials: "include"
      }
    )).json();
  // Users
  checkBuyer = async email =>
  (await fetchWrapper(
    `${this.currentUserEndpoint()}/auth/getBuyerDetails/${email}`,
    {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }
    , async (request_) => await request_.json()));

  getBuyers = async () =>
    (await fetch(`${this.currentUserEndpoint()}/user/Buyer`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

    getERPBuyers = async (userId_) => {
    let _url= userId_?`${V2_API_ENDPOINT_SUPPLIER}/suppliers/allerpbuyers/${userId_}`:`${V2_API_ENDPOINT_SUPPLIER}/suppliers/buyers`;
    return (await fetch(_url, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };

  getStates = async (countryCode) =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/countries/${countryCode}/regions`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getBuyersDetailsByToken = async (token) =>
  (fetchWrapper(`${V2_API_ENDPOINT_SUPPLIER}/buyers/token`, {
    headers: HEADERS(),
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  }, async (request_) => await request_.json()));

  getSuppliersDetailsByToken = async (token) =>
  (await fetchWrapper(`${V2_API_ENDPOINT_SUPPLIER}/buyers/suppliertoken`, {
    headers: HEADERS(),
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  }, async (request_) => await request_.json()));

  sendInviteToBuyer = async customers =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/buyers/invite`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(customers),
      credentials: "include"
    }, async (request_) => await request_.json()));


  sendInviteToSupplier = async customers =>
    (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document/invite`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(customers),
      credentials: "include"
    })).json();

  getBuyersByName = async (name) =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/buyers?name=${name}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  downloadFinancialDoc = async () =>
    (await fetch(`${this.currentUserEndpoint()}/file`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getErpInfo = async (supplierId) =>
    (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/info/${supplierId}`, {
      method: "GET",
      header: {
        "content-type": "application/json",
        "cache-control": "no-cache"
      },
    })).json();

  getSupplierDocs = async () =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile/financialdocuments`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getSupplierCommonDocs = async (fundingReqId="") =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile/commondocuments?fundingReqId=${fundingReqId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getPreFundingCommonDocsForFunder = async (fundingReqId = "",supplierId) =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/commondocuments?fundingReqId=${fundingReqId}&supplierId=${supplierId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getFunderSupplierDocs = async (supplierId) =>
    (await fetch(`${this.getSupplierEndPointV2()}/suppliers/funderfinancialdocuments/${supplierId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getSupplierAgingReportDoc = async () =>
  (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/profile/financialdocuments/AGING_REPORT`, {
    headers: HEADERS(),
    method: "GET",
    credentials: "include"
  }));

  getDueDate = async (invDate, days) =>
    (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/utility/getduedate?invDate=${invDate}&days=${days}`, {
      method: "GET",
      header: {
        "content-type": "application/json",
        "cache-control": "no-cache"
      }
    })).json();

  //import invoice from erp
  getInvoicesFromERP = async (emailId) =>
    (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/import/invoice?emailId=${emailId}`, {
      method: "GET",
      header: {
        "content-type": "application/json",
        "cache-control": "no-cache"
      }
    })).json();

  getDataPackageFromERP = async (cusId, emailId) =>
    (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/import/datapackage/${cusId}?emailId=${emailId}`, {
      method: "GET",
      header: {
        "content-type": "application/json",
        "cache-control": "no-cache"
      }
    })).json();

  getClosedInvoicesFromERP = async (emailId, invList) =>
    (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/import/invoice/closed?emailId=${emailId}`, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify(invList)
    })).json();

  // Invoices
  submitInvoiceToLF = async invoice =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/invoice/import`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();

  docSubmitInvoiceToLF = async invoice =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/funding-document/import`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();

  submitCustomersToLF = async customers =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/buyers/import`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(customers),
      credentials: "include"
    })).json();

  submitClosedInvoiceToLF = async invoice =>
    (await fetch(`${this.v2CurrentUserEndpoint()}/invoice/import/closed`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(invoice),
      credentials: "include"
    })).json();

  //import invoice from erp
  getAgingReportFromERP = async (emailId) =>
    (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/import/invoiceaging?emailId=${emailId}`, {
      method: "GET",
      header: {
        "content-type": "application/json",
        "cache-control": "no-cache"
      }
    })).json();


  // Invoices
  submitAgingReportToLF = async agingReport =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile/invoiceagingreport`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(agingReport),
      credentials: "include"
    })).json();

  submitPayableAgingReportToLF = async pagingReport =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile/payableagingreport`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(pagingReport),
      credentials: "include"
    })).json();

  //Messaging
  storeMessage = async message =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/messaging-add`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: message,
      credentials: "include"
    })).json();

  getMessage = async (messageType, invoiceId, recipient) =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/messaging-get/"non"/${invoiceId}/${recipient}`, {
      headers: FILE_HEADERS(),
      method: "POST",
      credentials: "include"
    })).json();

  storeMessageFunder = async message =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/messaging-add-funder`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: message,
      credentials: "include"
    })).json();

  getMessageFunder = async (messageType, invoiceId, recipient) =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/messaging-get-funder/"non"/${invoiceId}/${recipient}`, {
      headers: FILE_HEADERS(),
      method: "POST",
      credentials: "include"
    })).json();


  saveErpInfo = async (emailId, erpInfo) => {
    return (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/info/${emailId}`, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify(erpInfo)
    })).json();
  };

  saveAdvancedInfo = async (advInfo) => {
    return (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/advancedinfo`, {
      method: "PUT",
      headers: HEADERS(),
      body: JSON.stringify(advInfo),
      credentials: "include"
    })).json();
  };

  updateTaxId = async (taxId) => {
    return (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/updateTaxId/${taxId}`, {
      method: "POST",
      credentials: "include"
    })).json();
  };

  updateERPLastSyncTime = async (emailId) => {
    return (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/updateLastSyncTime/${emailId}`, {
      method: "PUT",
      headers: HEADERS()
    })).json();
  };

  updateErpInfo = async (supplierId, erpInfo) =>
    (await fetch(V2_API_ENDPOINT_ERP_SUPPLIER + `/erp/info/${supplierId}`, {
      method: "PUT",
      header: {
        "content-type": "application/json",
        "cache-control": "no-cache"
      },
      body: JSON.stringify(erpInfo)
    })).json();
  //PO Stuff
  submitPO = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/po`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };
  docSubmitPO = async (document) => {
    return (await fetch(`${this.currentUserEndpoint()}/funding-document/po`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };
  getPO = async () => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/po`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  docGetPO = async () => {
    return (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document/po`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };
  getallPO = async () => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/po/all`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };

  // TCF Stuff
  submitTCF = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/tcf`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };
  docSubmitTCF = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-document/taxcredit`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };

  docTCFUpdate = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-document/tcf/update-document`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };

  //Asset-based Lending

  docGetABL = async () => {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-document/abl`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  docSubmitABL = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-document/abl`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };


  docSubmitSupplyChain = async (document) => {
    return (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document/supplyChain`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  getallTypeOfDocuments = async (userId,role) => {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-document/getAllTypeTransactions?userId=${userId}&role=${role}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  //common creact request funding for TCF,PO,Invoice;
  commonCreateFundingRequest = async (data_) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/funding-request/request-funding`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(data_),
      credentials: "include"
    })).json();
  };

    //common creact request funding for wihtout documents;
    commonCreatePreFundingRequest = async (data_) => {
      return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-request/pre-request-funding`, {
        headers: FILE_HEADERS(),
        method: "POST",
        body: data_,
        credentials: "include"
      }, async (request_) => await request_.json()));
    };

  isCheckExistContract = async (buyers) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-request/check-contract-buyer`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(buyers),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  getAllContractByType = async (type_) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-contract/getAllContractByType?type=${type_}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  isValidateCreditLimit = async (tradingPartnerDetails_) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-request/check-credit-limit`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify({
        tradingPartnerDetails:tradingPartnerDetails_
      }),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  checkCreateFundingRequest = async (tradingPartnerList_,transactionType_) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-contract/validate-create-FR`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify({
        tradingPartnerList:tradingPartnerList_,
        transactionType:transactionType_
      }),
      credentials: "include"
    }, async (request_) => await request_.json()));
  };


  getAllFunder = async (role) =>
    (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/user/role`,
      {
        headers: HEADERS(),
        method: "POST",
        body: JSON.stringify(role),
        credentials: "include"
      }, async (request_) => await request_.json()
    ));


  getUserById = async (id) =>
  (await fetchWrapper(`${this.currentUserEndpoint()}/user/id`,
    {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(id),
      credentials: "include"
    }, async (request_) => await request_.json()));



  getFundingRequest = async () => {
    if (this.currentUserRole() == "supplier") {
      return (
        await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-request`, {
          headers: HEADERS(),
          method: "GET",
          credentials: "include"
        }, async (request_) => await request_.json()));
    }
    else if(this.currentUserRole()=="buyer"){
      return (
        await fetchWrapper(`${this.currentUserEndpoint()}/funding-request`, {
          headers: HEADERS(),
          method: "GET",
          credentials: "include"
        }, async (request_) => await request_.json()));
    }
    else {
      return (await fetchWrapper(`${this.currentUserEndpoint()}/invoice/funding-invoices`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
  };

  getFundingReqForDashboard = async () => {
    if (this.currentUserRole() == "supplier") {
      return (
        await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/dashboard/supp-fund-req`, {
          headers: HEADERS(),
          method: "GET",
          credentials: "include"
        }, async (request_) => await request_.json()));
    }
    else if (this.currentUserRole() == "buyer") {
      return (
        await fetchWrapper(`${this.currentUserEndpoint()}/funding-request`, {
          headers: HEADERS(),
          method: "GET",
          credentials: "include"
        }, async (request_) => await request_.json()));
    }
    else {
      return (await fetchWrapper(`${this.currentUserEndpoint()}/dashboard/dash-fun-funding-invoices`, {
        headers: HEADERS(),
        method: "GET",
        credentials: "include"
      }, async (request_) => await request_.json()));
    }
  };

  getAllFundingRequest = async () => {
   return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-request/all-funding-request`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };


  getTCFFunders = async (tcfId) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/tcf/funders/${tcfId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  getOPFunders = async (tcfId) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/po/funders/${tcfId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  getFunders = async () => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-document/funders`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };
  makeOfferPO = async (document) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/po/maketco/`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    }, async (request_) => await request_.json()));
  };
  acceptOfferPO = async (document) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/po/acceptpoo/`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    }, async (request_) => await request_.json()));
  };
  markasPaidPO = async (poid) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/po/markaspaid/${poid}`, {
      headers: FILE_HEADERS(),
      method: "POST",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };
  // fundInvoice = async invoice =>
  // (await fetch(`${this.currentUserEndpoint()}/invoice/fund/${invoice.id}`, {
  //   headers: HEADERS(),
  //   method: "PUT",
  //   body: JSON.stringify(invoice),
  //   credentials: "include"
  // })).json();
  markasPaidTCF = async (tcfid) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/tcf/markaspaid/${tcfid}`, {
      headers: FILE_HEADERS(),
      method: "POST",
      credentials: "include"
    })).json();
  };
  makeOfferTCF = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/tcf/maketco/`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };
  acceptOfferTCF = async (document) => {
    return (await fetch(API_ENDPOINT_SUPPLIER + `/tcf/accepttco/`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  };
  getTCF = async () => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/tcf`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  // TCF Stuff
  docGetTCF = async () => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/funding-document/tcf`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  docGetallTCF = async () => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/funding-document/tcf/all`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  getallTCF = async () => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/tcf/all`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  };
  rejectTCFRequest = async (tcfId_) => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/tcf/reject/${tcfId_}`, {
      headers: HEADERS(),
      method: "PUT",
      credentials: "include"
    })).json();
  };
  docRejectTCFRequest = async (tcfId_) => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/funding-document/tcf/reject/${tcfId_}`, {
      headers: HEADERS(),
      method: "PUT",
      credentials: "include"
    })).json();
  };
  approveTCFRequest = async (tcfId_) => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/tcf/approve/${tcfId_}`, {
      headers: HEADERS(),
      method: "PUT",
      credentials: "include"
    })).json();
  };
  docApproveTCFRequest = async (tcfId_) => {
    return (await fetch(`${API_ENDPOINT_SUPPLIER}/funding-document/tcf/approve/${tcfId_}`, {
      headers: HEADERS(),
      method: "PUT",
      credentials: "include"
    })).json();
  };
  financialDocHistoryUpload = async (document) => {
    return (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/financialdocuments`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();


    // if (this.currentUserType() == "TaxCreditFinancing") {
    //   return (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/tcffinancialdocuments`, {
    //     headers: FILE_HEADERS(),
    //     method: "POST",
    //     body: document,
    //     credentials: "include"
    //   })).json();
    // }
    // else {
    //   return (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/financialdocuments`, {
    //     headers: FILE_HEADERS(),
    //     method: "POST",
    //     body: document,
    //     credentials: "include"
    //   })).json();
    // }
  };

  getSupplierByNameAndEmail = async (name_, email_) =>
    (await fetch(`${this.currentUserEndpoint()}/user?name=${name_}&email=${email_}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

    updateV2Profile = async profile =>
      (await fetch(`${this.v2CurrentUserEndpoint()}/suppliers/profile`, {
        headers: HEADERS(),
        method: "PUT",
        body: JSON.stringify(profile),
        credentials: "include"
      })).json();

  updateLanguageForAllUser = async (lang_) => {
    return (await fetchWrapper(V2_API_ENDPOINT_SUPPLIER + `/suppliers/update-language?language=${lang_}`, {
      headers: FILE_HEADERS(),
      method: "PUT",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  financialAgingReportUpload = async (document) =>
    (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/agingreport`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();
  getSupplierAging = async (oid) =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/profile/agingreport/${oid}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getTransactionVolume = async (supplierId, customerId) =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/${supplierId}/buyers/${customerId}/transactionvolumereport`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getAverageCollectionPeriod = async (supplierId, customerId) =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/${supplierId}/buyers/${customerId}/averagecollectionperiod`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getAllAverageCollectionPeriod = async () =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/averagecollectionperiod`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getallErpAverageCollectionPeriod = async (userId_) =>{
    let _url = userId_?`${V2_API_ENDPOINT_SUPPLIER}/suppliers/erpaveragecollectionperiod/${userId_}`:`${V2_API_ENDPOINT_SUPPLIER}/suppliers/erpaveragecollectionperiod`;
    return (await fetch(_url, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();
  }

  runErpBackgroundJobs = async jobkeyId =>
    (await fetch(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/erpjobs`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify({
        "jobKey": jobkeyId
      }),
      credentials: "include"
    })).json();

  getJobStatusByKey = async jobkeyId =>
    (await fetch(`${API_ENDPOINT_SUPPLIER}/jobs/getbykey`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify({
        "jobKey": jobkeyId
      }),
      credentials: "include"
    })).json();

  forgotPassword = async emailId =>
    (await fetchWrapper(`${this.currentUserEndpoint()}/auth/forgot-password`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify({
        "email": emailId
      }),
      credentials: "include"
    }, async (request_) => await request_.json()));

  resetPassword = async pwdDetails =>
  (fetchWrapper(`${this.currentUserEndpoint()}/auth/forgot-password`, {
    headers: HEADERS(),
    method: "PUT",
    body: JSON.stringify(pwdDetails),
    credentials: "include"
  },async (request_) => request_));

  getAvailableMarketplaceData = async (status) =>
  (await fetchWrapper(`${this.v2CurrentUserEndpoint()}/fundingrequests/bystatus?status=${status}`, {
    headers: HEADERS(),
    method: "GET",
    credentials: "include"
  }, async (request_) => await request_.json()));

  getMatchedFundersData = async (fundingRequestId, fundingType,userId) =>
    (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-request/get-matchedfunder?fundReqId=${fundingRequestId}&fundingType=${fundingType}&userId=${userId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));

  financialBankStatementUpload = async (document) =>
    (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/bankStatement`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();

  updateFinancialBankStatementUpload = async (document) =>
    (await fetch(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/updateBankStatement`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    })).json();

  financialAdditionalDocumentsUpload = async (document) =>
    (await fetchWrapper(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/financialAddDoc`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    }, async (request_) => await request_.json()));

  updateFinancialAdditionalDocumentsUpload = async (document) =>
    (await fetchWrapper(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/updateFinancialAddDoc`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    }, async (request_) => await request_.json()));

  getReceivablesDetailsAgingReport = async (supplierId) =>
    (await fetch(`${this.getSupplierEndPointV2()}/suppliers/receivablesDetailsAgingReport/${supplierId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  getPayablesDetailsAgingReport = async (supplierId) =>
    (await fetch(`${this.getSupplierEndPointV2()}/suppliers/payablesDetailsAgingReport/${supplierId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    })).json();

  financialPayableAgingReportUpload = async (document) =>
    (await fetchWrapper(V2_API_ENDPOINT_SUPPLIER + `/suppliers/profile/payableAgingReportUpload`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: document,
      credentials: "include"
    }, async (request_) => await request_.json()));

  getVerificationCode = async code =>
    (await fetch(`${API_ENDPOINT_SUPPLIER}/funding-contract/sendVerificationCode`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(code),
      credentials: "include"
    })).json();

  getWalletInfo = async () =>
  (await fetchWrapper(`${V2_API_ENDPOINT_SUPPLIER}/suppliers/userWalletInfo`, {
    headers: HEADERS(),
    method: "GET",
    credentials: "include"
  }, async (request_) => await request_.json()));

  docGetSupplyChain = async () => {
    return (await fetchWrapper(`${this.currentUserEndpoint()}/funding-document/supplyChain`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  };

  disableUser = async (id_,name_) =>
  (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/admin/supplier/disableUser`, {
    headers: HEADERS(),
    method: "POST",
    body: JSON.stringify({
      "userId": id_,
      "name":name_
    }),
    credentials: "include"
  }, async (request_) => await request_.json()));

  selectedUserWithCsl = async (_id) => {
    return (await fetchWrapper(`${this.currentUserEndpoint()}/selectedUser-csl?_id=${_id}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  }

  selectedUserInformation = async (_id) => {
    return (await fetchWrapper(`${this.currentUserEndpoint()}/selectedUser-ques?_id=${_id}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  }

  getAllTradingPartnersWithoutContract=async()=> {
    return (await fetchWrapper(`${API_ENDPOINT_SUPPLIER}/funding-contract/trading-partner-list`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));
  }

  createTradingPartnerWithParitialData = async (data_) =>{
    let _url = `${this.currentUserEndpoint()}/user/${compareRole(this.currentUserRole(),enumRole.Supplier)?"create-partial-buyer":"create-partial-supplier"}`;
    return (await fetchWrapper(`${_url}`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(data_),
      credentials: "include"
    }, async (request_) => await request_.json()));
  }

  saveTemporaryFileInServer = async (files) => {
    return (await fetchWrapper(API_ENDPOINT_SUPPLIER + `/funding-request/save-temporary-file`, {
      headers: FILE_HEADERS(),
      method: "POST",
      body: files,
      credentials: "include"
    }, async (request_) => await request_.json()));
  };




  //QUICKBOOKS API
  GetQBloginurl = async () =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/GetQBloginurl`, {
      method: "GET",
      header: {
        "content-type": "application/json",
        "cache-control": "no-cache"
      }
    })).text();

  getQBAccessToken = async (url, email) =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/getaccesstoken`, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({
        "callbackurl": url,
        "email": email
      })
    })).text();

  refreshtokenfromQB = async (email) =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/refreshtoken/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).text();

  getinvoicefromQB = async (email) =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/getinvoice/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  gettokenfromQB = async (email) =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/gettoken/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  getAgingReportFromQBO = async (email) =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/getinvoiceforagingreport/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  QBOBackgroundJobs = async (email, supplierId) =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/qbbackendjob/${email}/${supplierId}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  getsupbanktransactionhistory = async (supplierId) =>
    (await fetch(V2_API_ENDPOINT_QUICKBOOK_SUPPLIER + `/getsupbanktransactionhistory/${supplierId}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  checkCountry = async (ip_) => {
    return (await fetch(API_ENDPOINT_ADMIN + `/get-request-country/${ip_}`, {
      method: "GET",
      headers: HEADERS()
    })).json();
  };

  getPayablesAgingReport = async (supplierId) =>
    (await fetchWrapper(`${this.getSupplierEndPointV2()}/suppliers/payablesAgingReport/${supplierId}`, {
      headers: HEADERS(),
      method: "GET",
      credentials: "include"
    }, async (request_) => await request_.json()));

  //--------------------D365 Business Central API calls------------------------------

  saveBusinessCentralInfo = async (businessCentralUserInfo) => {
    return (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/saved365bcconfig`, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify(businessCentralUserInfo)
    })).text();
  };

  getD365BCUrl = async (email) =>
    (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/Getd365bcloginurl/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).text();

    getBusinessCentralAccessToken = async (url, email) =>
    (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/Getd365bcgetaccesstoken`, {
      method: "POST",
      headers: HEADERS(),
      body: JSON.stringify({
        "callbackurl": url,
        "email": email
      })
    })).text();

  getinvoicefromD365BC = async (email) =>
    (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/d365bcgetinvoice/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  d365BCBackgroundJobs = async (email, supplierId) =>
    (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/d65bcbackendjob/${email}/${supplierId}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  getReceivableAgingReportFromD365BC = async (email) =>
    (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/getreceivablesagingreport/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  getD365BCDetails = async (email) =>
    (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/getD365BCDetails/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

    Getd365bcchartofaccounts = async (email) =>
    (await fetch(V2_API_ENDPOINT_D365BC_SUPPLIER + `/Getd365bcchartofaccounts/${email}`, {
      method: "GET",
      headers: HEADERS()
    })).json();

  //--------------------END D365 Business Central API calls------------------------------
}