import React, { Component } from "react";
import "./Sidebar.css";
import { NavLink, withRouter } from "react-router-dom";
import $ from "jquery";
import { UUID } from "../../../services/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faMoneyBillAlt,
  faUniversity,
  faSlidersH,
  faSignOutAlt,
  faFile,
  faPlus,
  faFax,
  faHandshake,
  faPowerOff,
  faSyncAlt,
  faUserFriends
} from "@fortawesome/free-solid-svg-icons";

import AppIcon from "../../../assets/icons/newlogo.png";

import AppState from "../../../contexts/AppState/AppState";
import User from "../../../services/User";
import API from "../../../services/API";
import CommonConfirmationModal from "../Modals/CommonConfirmationModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { compareRole, enumRole } from "../../../configs/GlobalConfig";

class Sidebar extends Component {
  user = new User();
  api = new API();
  _timer;
  _jobUUID;

  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    this.state = {
      isERPSync: false,
      isERP: false,
      user: {}
    }
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover"
    });
    this.getProfile()
      .then(() => {
        this.erp();
      });
  }

  async notify(message) {
    // Calling toast method by passing string
    toast.info(message)
  }

  async getProfile() {
    const profileResponse = await this.getV2Profile();
    if (profileResponse.user) {
      this.setState({ user: profileResponse.user });
    }
  }

  async getV2Profile() {
    if (this.user.currentUserRole() == "funder" || this.user.currentUserRole() == "buyer" || this.user.currentUserRole() == "admin") {
      return await this.api.getProfile();
    }
    else {
      return await this.api.getV2Profile();
    }


  }

  async erp() {
    if (this.state.user.accountingInformation) {
      if (this.state.user.accountingInformation == "quickbooks") {
        const qbInfo = await this.api.gettokenfromQB(this.state.user.email);
        if (qbInfo && !qbInfo.msg) {
          this.setState({
            isERP: true
          })
        }
      }
      else if (this.state.user.accountingInformation == "D365BC") {
        const qbInfo = await this.api.getD365BCDetails(this.state.user.email);
        if (qbInfo && !qbInfo.accesstoken) {
          this.setState({
            isERP: true
          })
        }
      }
      else {
        const ERPInfo = await this.api.getErpInfo(this.state.user.email);
        if (ERPInfo && !ERPInfo.message) {
          this.setState({
            isERP: true
          })
        }
      }
    }
  }

  signOut() {
    window.location.href = `/${this.user.currentUserRole()}`;
    this.user.logOut();
  }

  renderFundingRequestNavItem = () => {
    return (
      <React.Fragment>
        <li
          className="nav-item my-1 d-flex"
          data-toggle="tooltip"
          data-placement="right"
          title={this.props.t("page_titles.fundingrequests")}
        >
          <NavLink className="nav-link" to="/fundingrequest" activeClassName="active">
            <span className="font-icon-width"><FontAwesomeIcon icon={faMoneyBillAlt} /></span>
            <span className="pl-1 d-none">{this.props.t("page_titles.fundingrequests")}</span>
          </NavLink>
        </li>
      </React.Fragment>
    );
  };

  render() {
    const { user } = this.state;
    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>
            {this.state.isERPSync && (
              <div className="terms-checkbox-container">
                {
                  this.state.isERPSync && (
                    <CommonConfirmationModal
                      title={(user.accountingInformation && user.accountingInformation == "quickbooks") ? "Global QuickBooks Online Sync" : user.accountingInformation && user.accountingInformation == "D365BC" ? "Global D365 Business Central Sync" : "Global ERP Sync"}
                      submitText="OK"
                      onClose={() => {
                        this.setState({ isERPSync: false })
                      }}
                      onSubmit={async () => {
                        clearInterval(this._timer);
                        this._jobUUID = UUID()
                        this.api.runErpBackgroundJobs(this._jobUUID);
                        localStorage.setItem("BackGround_Job_ID", this._jobUUID);
                        localStorage.setItem("UI_JOB", true);
                        this.setState({ isERPSync: false }, () => {
                        })
                      }}
                    >
                      {(user.accountingInformation && user.accountingInformation == "quickbooks") ? "Are you sure you want to sync from QuickBooks Online? " :
                        (user.accountingInformation && user.accountingInformation == "D365BC") ? "Are you sure you want to sync from D365 Business Central? " : "Are you sure you want to sync from ERP? "}
                      Please note that this could take few minutes to complete.
                    </CommonConfirmationModal>
                  )
                }
              </div>
            )}
            <nav className="side-navbar sidebar">
              <ul className="nav sidebar-nav flex-column">
                {appState.state.currentUserRole == 'admin' && (
                  <li
                    className="nav-item my-1 d-flex"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={this.props.t("page_titles.userList")}
                  >
                    <NavLink
                      className="nav-link"
                      to="/userList"
                      activeClassName="active"
                    >
                      <span className="font-icon-width">
                        <FontAwesomeIcon icon={faUserFriends} />
                      </span>
                      <span className="pl-1 d-none">{this.props.t("page_titles.userList")}</span>
                    </NavLink>
                  </li>
                )}
                {compareRole(appState.state.currentUserRole, enumRole.Supplier) && this.renderFundingRequestNavItem()}
                {(appState.state.currentUserRole != "buyer" && appState.state.currentUserRole != "admin") && (
                  <li
                    className="nav-item my-1 d-flex"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={this.props.t("page_titles.dashboard")}
                  >
                    <NavLink
                      className="nav-link"
                      to="/dashboard"
                      activeClassName="active"
                    >
                      <span className="font-icon-width">
                        <FontAwesomeIcon icon={faTachometerAlt} />
                      </span>
                      <span className="pl-1 d-none">{this.props.t("page_titles.dashboard")}</span>
                    </NavLink>
                  </li>
                )}
                {appState.state.currentUserRole != "funder" && appState.state.currentUserRole != "admin" && (
                  <>
                    <li
                      className="nav-item my-1 d-flex"
                      data-toggle="tooltip"
                      data-placement="right"
                      title={this.props.t("page_titles.transactions")}
                    >

                      <NavLink className="nav-link" to="/document" activeClassName="active">
                        <span className="font-icon-width"><FontAwesomeIcon icon={faFile} /></span><span className="pl-1 d-none">{this.props.t("page_titles.transactions")}</span>
                      </NavLink>
                    </li>
                  </>
                )}
                {(compareRole(appState.state.currentUserRole, enumRole.Admin) || compareRole(appState.state.currentUserRole, enumRole.Buyer)) && this.renderFundingRequestNavItem()}
                {appState.state.currentUserRole === "funder" && (
                  <FunderNavigation t={this.props.t} />
                )}
                {(appState.state.currentUserRole != "admin") && (
                  <li
                    className="nav-item my-1 d-flex"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={this.props.t("page_titles.fundingContracts")}
                  >
                    <NavLink className="nav-link" to="/fundingcontract" activeClassName="active">
                      <span className="font-icon-width"> <FontAwesomeIcon icon={faHandshake} /></span>
                      <span className="pl-1 d-none">{this.props.t("page_titles.fundingContracts")}</span>
                    </NavLink>
                  </li>
                )}
                {appState.state.currentUserRole == "supplier" && (
                  <li
                    className="nav-item my-1 d-flex"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={this.state.isERP ? "Global ERP/Accounting System Sync" : ""}
                    style={{ position: "fixed", bottom: 200 }}
                  >
                    <a className={"nav-link" + (this.state.isERP ? "" : " disabled")}
                      onClick={() => {
                        // this.api.runErpBackgroundJobs();
                        this.setState({ isERPSync: true });
                      }}>
                      <span className="font-icon-width"><FontAwesomeIcon icon={faSyncAlt} /></span>
                      <span className="pl-1 d-none"> {(user.accountingInformation && user.accountingInformation == "quickbooks") ? "Global QuickBooks Online Sync" : "Global ERP Sync"}</span>
                    </a>
                  </li>
                )}
                <li
                  className="nav-item my-1 d-flex"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={this.props.t("page_titles.logout")}
                  onClick={this.signOut}
                  style={{ position: "fixed", bottom: 0 }}
                >
                  <NavLink className="nav-link" to="/"
                    activeClassName="_active">
                    <span className="font-icon-width"> <FontAwesomeIcon icon={faPowerOff} /></span>
                    <span className="pl-1 d-none">{this.props.t("page_titles.logout")}</span>
                  </NavLink>
                </li>

              </ul>
            </nav>
            <ToastContainer />
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default compose( withRouter,withTranslation())(Sidebar);

const FunderNavigation = ({t}) => (

  <React.Fragment>
    <li
      className="nav-item my-1 d-flex"
      data-toggle="tooltip"
      data-placement="right"
      title={t("page_titles.fundingoperations")}
    >
      <NavLink className="nav-link" to="/fundingrequest" activeClassName="active">
        <span className="font-icon-width"> <FontAwesomeIcon icon={faMoneyBillAlt} /></span>
        <span className="pl-1 d-none">{t("page_titles.fundingoperations")}</span>
      </NavLink>
    </li>

    {/* <li
      className="nav-item my-1 d-flex"
      data-toggle="tooltip"
      data-placement="right"
      title="Operations"
    >
      <NavLink className="nav-link" to="/invoices" activeClassName="active">
        <FontAwesomeIcon icon={faMoneyBillAlt} />
      </NavLink>
    </li> */}
    <li
      className="nav-item my-1 d-flex"
      data-toggle="tooltip"
      data-placement="right"
      title={t("page_titles.marketplace")}
    >
      <NavLink className="nav-link" to="/marketplace" activeClassName="active">
        <span className="font-icon-width"><FontAwesomeIcon icon={faUniversity} /></span>
        <span className="pl-1 d-none">{t("page_titles.marketplace")}</span>
      </NavLink>
    </li>
    {/* <li
      className="nav-item my-1 d-flex"
      data-toggle="tooltip"
      data-placement="right"
      title="TaxCredit"
    >
      <NavLink className="nav-link" to="/fundingtaxcredit" activeClassName="active">
        <span className="font-icon-width"><FontAwesomeIcon icon={faFax} /></span>
        <span className="pl-1 d-none">TaxCredit</span>
      </NavLink>
    </li> */}
    <li
      className="nav-item my-1 d-flex"
      data-toggle="tooltip"
      data-placement="right"
      title={t("page_titles.fundingCriteria")}
    >
      <NavLink className="nav-link" to="/settings" activeClassName="active">
        <span className="font-icon-width"><FontAwesomeIcon icon={faSlidersH} /></span>
        <span className="pl-1 d-none" >{t("page_titles.fundingCriteria")}</span>
      </NavLink>
    </li>
  </React.Fragment>
);
