import React, { Component } from 'react';
import DashboardWidgetContainer from './DashboardWidgetContainer';
import API from '../../../services/API';
import { shortDollarFormatter } from '../../../services/Utilities';
import { Bar } from 'react-chartjs-2';
import DocumentsWidgetViewMore from './Viewmore/DocumentsWidgetViewMore';
import User from '../../../services/User';
import { compareRole,enumRole } from '../../../configs/GlobalConfig';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement
);

export default class DocumentsWidget extends Component {
  api = new API();
  user =new User();
  
  chartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return shortDollarFormatter.format(value);
          }
        },
        stacked: true,
        grouped: false,
      },
      x:
      {
        stacked: true,
        // title: {
        //   display: true,
        //   text: 'In Dollars',
        //   align: 'center',
        //   color: "#4F4F4F",
        //   font: {
        //     size: 14,
        //     weight: 900,
        //     family: "Cario",
        //   }
        // },
      },
    }
  };

  constructor(props_) {
    super(props_);

    this.state = {
      chartData: null,
      isViewMore: false,
      invoices: []
    };
  }


  componentDidMount() {
    this.getDocumentDetails();
  }

  async getDocumentDetails() {
    let _userId= null;
    if(this.props && this.props.data && compareRole( this.user.currentUserRole(),enumRole.Funder)){
      _userId = this.props.data._id;
    }
    const invoicesResponse = await this.api.getalldocForDashboard(_userId);
    let amountGroupedByStatus = invoicesResponse.invoices.reduce((cus, docs) => {
      let status = docs.status;

      if (status != 99 && status != 100) {
        status = 0;
      }

      if (!cus[docs.documentType]) {
        cus[docs.documentType] = {}
        if (!cus[docs.documentType][status]) {
          cus[docs.documentType][status] = 0;
        }
      }
      cus[docs.documentType][status] = (cus[docs.documentType][status] ? cus[docs.documentType][status] : 0) + docs.total;
      return cus;
    }, {});
    this.setState({
      invoices: invoicesResponse.invoices,
      chartData: {
        //labels: ['Invoice', 'PO','TCF'],
        labels: [this.props.t("dashboard_page.transactions_content.type_lables.invoice"), this.props.t("dashboard_page.transactions_content.type_lables.po")],
        datasets: [
          {
            label: this.props.t("dashboard_page.transactions_content.status_lables.open"),
            data: [
              amountGroupedByStatus["Invoice"] && amountGroupedByStatus["Invoice"]["0"] ? amountGroupedByStatus["Invoice"]["0"] : 0,
              amountGroupedByStatus["PO"] && amountGroupedByStatus["PO"]["0"] ? amountGroupedByStatus["PO"]["0"] : 0,
             // amountGroupedByStatus["TCF"] && amountGroupedByStatus["TCF"]["0"] ? amountGroupedByStatus["TCF"]["0"] : 0
            ],
            backgroundColor: 'rgb(255,180,91)',
            stack: 1
          },
          {
            label:this.props.t("dashboard_page.transactions_content.status_lables.infunding"),
            data: [
              amountGroupedByStatus["Invoice"] && amountGroupedByStatus["Invoice"]["99"] ? amountGroupedByStatus["Invoice"]["99"] : 0,
              amountGroupedByStatus["PO"] && amountGroupedByStatus["PO"]["99"] ? amountGroupedByStatus["PO"]["99"] : 0,
             // amountGroupedByStatus["TCF"] && amountGroupedByStatus["TCF"]["99"] ? amountGroupedByStatus["TCF"]["99"] : 0
            ],
            backgroundColor: 'rgb(79,152,79)',
            stack: 1
          },
          // {
          //   label: 'Archived',
          //   data: [
          //     amountGroupedByStatus["Invoice"] && amountGroupedByStatus["Invoice"]["100"] ? amountGroupedByStatus["Invoice"]["100"] : 0,
          //     amountGroupedByStatus["PO"] && amountGroupedByStatus["PO"]["100"] ? amountGroupedByStatus["PO"]["100"] : 0,
          //     amountGroupedByStatus["TCF"] && amountGroupedByStatus["TCF"]["100"] ? amountGroupedByStatus["TCF"]["100"] : 0
          //   ],
          //   backgroundColor: 'rgb(37,95,121)',
          //   stack: 1
          // },
        ],
      }
    });

  }


  render() {
    // let chartData = this.state.chartData;
    const { chartData, isViewMore, invoices } = this.state;
    return (
      <DashboardWidgetContainer title={this.props.t("dashboard_page.widget_titles.transaction_status")} size="col-md-4">
        {isViewMore && (
          <DocumentsWidgetViewMore
            onClose={() => { this.setState({ isViewMore: !isViewMore }) }}
            data={chartData}
            invoices={invoices}
          />
        )}
        {
          chartData &&
          <div style={{ height: "calc(100% - 24px)" }}>
            <Bar data={chartData} options={this.chartOptions} height={200} />
          </div>
        }
        {invoices.length > 0 && (
          <div className="row">
            <div className="col-md-12 text-right">
              <a className="anchorstyle" href="" onClick={(e) => {
                e.preventDefault();
                this.setState({ isViewMore: !isViewMore })
              }
              }>{this.props.t("dashboard_page.transactions_content.view_more")}</a>
            </div>
          </div>
        )}

      </DashboardWidgetContainer>
    );
  }
}

