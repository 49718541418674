import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const CommonPopUpModal = ({ uploadInfo,
  onClose,
  onSubmit,
  onSecBtnClick,
  isSecBtnEnabled,
  title,
  children,
  secondaryBtn,
  primaryBtn,
  isSubmitBtn,
  size,
  promptOnClose,
  style
}) => {
  const [showUnsavedChangesPrompt, setShowUnsavedChangesPrompt] = useState(false);
  const { t, i18n } = useTranslation();

  const modalSizeClass = classNames({
    'modal-sm': size === 'small',
    'modal-sm-md': size === 'small-medium',
    'modal-lg': size === 'medium',
    'modal-xl': size === 'large',
    'modal-full': size === 'full',
  });

  const modalBodyRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (modalBodyRef.current) {
        const viewportHeight = window.innerHeight;
        const headerHeight = modalBodyRef.current.previousElementSibling.offsetHeight;
        const footerHeight = modalBodyRef.current.nextElementSibling.offsetHeight;
        const maxHeight = viewportHeight - headerHeight - footerHeight - (modalSizeClass == 'modal-full' ? 0 : 20)
        modalBodyRef.current.style.maxHeight = `${maxHeight}px`;
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOnClose = () => {
    if (promptOnClose) {
      setShowUnsavedChangesPrompt(true);
    } else {
      onClose();
    }
  };

  const confirmClose = (shouldClose) => {
    setShowUnsavedChangesPrompt(false);
    if (shouldClose) {
      onClose();
    }
  };

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div className="common-popup-modal d-flex align-items-center justify-content-center" tabIndex="-1" role="dialog" aria-labelledby="customModal" aria-hidden="true" style={style?style:{}}>
        <div className={`common-popup-dialog ${modalSizeClass}`} role="document">
        <div className={`common-popup-content ${modalSizeClass === 'modal-full' ? 'no-border-radius' : ''}`}>
            <div className="common-popup-header">
              <div className="common-popup-title">{title}</div>
              <button type="button" className="common-popup-close" aria-label="Close" onClick={handleOnClose}>
                &times;
              </button>
            </div>
            <div className="common-popup-body custom-scrollable-body" ref={modalBodyRef}>
              {children}
              {/* {uploadInfo && (
              <div className="privacy-message mt-3">
                <h2 className="privacy-title">Information:</h2>
                Once uploaded, we'll automatically extract and display its details for your review. Editing and saving your changes will be a breeze once extraction is complete. Please be patient during this process; it may take a few moments.
              </div>
            )} */}
            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-secondary" onClick={handleOnClose}>
              {t("button_names.close")}
              </button>
              {secondaryBtn && <button
                className="btn btn-primary"
                onClick={onSecBtnClick}
                disabled={isSecBtnEnabled}
              >
                {secondaryBtn}
              </button>}
              {primaryBtn && <button
                className="btn btn-primary "
                onClick={onSubmit}
                disabled={isSubmitBtn}
              >
                {primaryBtn}
              </button>}
            </div>
          </div>
        </div>
        {showUnsavedChangesPrompt && (
          <div className="unsaved-changes-modal">
            <div className="unsaved-changes-content">
              <p>{t("close_confirmation_msg")}</p>
              <div>
                <button className="btn btn-outline-secondary" onClick={() => confirmClose(false)}>{t("button_names.cancel")}</button>
                <button className="btn btn-primary" onClick={() => confirmClose(true)}>{t("button_names.yes")}</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

CommonPopUpModal.propTypes = {
  //uploadInfo: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  primaryBtn: PropTypes.string.isRequired,
  isSubmitBtn: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  promptOnClose: PropTypes.bool,
};

CommonPopUpModal.defaultProps = {
  //uploadInfo: false,
  isSubmitBtn: false,
  size: 'medium',
  promptOnClose: true,
};

export default CommonPopUpModal;
