import React from "react";
import "./popup.css";

const Popup = props => {
  let defaultClass = ["popup-box"];

  if (props.className) {
    defaultClass = defaultClass.concat(props.className.split(" "));
  }

  return (
    <div className={defaultClass.join(" ")}>
      <div className="box">
        {props.content}
      </div>
    </div>
  );
};
export default Popup;