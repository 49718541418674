import React, { Component } from 'react';
import DashboardWidgetContainer from './DashboardWidgetContainer';
import Chart from "react-apexcharts";
import API from '../../../services/API';
import { shortDollarFormatter, dateFormatter, localDateFormat } from '../../../services/Utilities';
import { compareRole, enumRole } from '../../../configs/GlobalConfig';
import User from '../../../services/User';

export default class CustomerHistoryWidget extends Component {
    api = new API();
    user =new User();
    constructor(props_) {
        super(props_);

        this.state = {
            user: this.props && this.props.data?this.props.data:{},
            buyers: [],
            KPIInfo: { paymentTerm: "NET30" },
            invoices: []
        };
    }

    componentDidMount() {
        if(this.props && this.props.data && compareRole(this.user.currentUserRole(),enumRole.Funder)){
            this.getERPBuyers();
        }
        else{
            this.getProfile()
            .then(() => {
                this.getERPBuyers();
            });
        }
    }


    async getProfile() {
        const profileResponse = await this.getV2Profile();
        this.setState({ user: profileResponse.user });
    }

    async getV2Profile() {
        return await this.api.getV2Profile();
        // return await this.api.getProfile();
    }

    async getERPBuyers() {
        let _userId= null;
        if(this.props && this.props.data && compareRole( this.user.currentUserRole(),enumRole.Funder)){
          _userId = this.props.data._id;
        }
        const erpBuyersResponse = (await this.api.getERPBuyers(_userId)).map(val => ({ ...val, isERP: true }))
            .sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                else if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });
        // const nonErpBuyers=(await this.api.getBuyers()).users.map(val=>({...val,isERP:false}));
        // const allBuyers=erpBuyersResponse.concat(nonErpBuyers);

        this.setState({ buyers: erpBuyersResponse }, () => {
            if (this.state.buyers && this.state.buyers.length) {
                this.getKPIbyCustomer(this.state.buyers[0])
            }
        });
    }

    async getKPIbyCustomer(val) {
        const { KPIInfo, invoices } = this.state;
        let supplierId =this.props && this.props.data ?this.props.data._id: this.state.user._id;
        let cusId;
        let res;
        this.setState({
            isImporting: true
        });
        if (val.isERP) {
            cusId = val.buyers[0].customerId.trim();
            // ERP Sync
            // res = await this.api.getDataPackageFromERP(
            //     cusId,
            //     this.state.user.email
            // );
            KPIInfo.errlbl = "";
            const transactionVol = await this.api.getTransactionVolume(supplierId, cusId);
            if (transactionVol.message) {
                KPIInfo.errlbl = transactionVol.message;
            }
            else if (transactionVol.transactionVolumeByMonth) {
                KPIInfo.reportAsOn = localDateFormat(transactionVol.retrievalTimeStamp);
                KPIInfo.chartData = {
                    options: {
                        chart: {
                            id: "basic-bar",
                        },
                        fill: {
                            colors: ['#063A52']
                        },
                        dataLabels: {
                            enabled: false
                        },
                        xaxis: {
                            title: {
                                text:this.props.t("common_names.days")
                            },
                            categories: [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec"
                            ]
                        },
                        yaxis: {
                            title: {
                                text: this.props.t("dashboard_page.transactions_volume")
                            },
                            labels: {
                                formatter: value => shortDollarFormatter.format(value)
                            }
                        }
                    },
                    series: [
                        {
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        }
                    ]
                };

                if (
                    transactionVol &&
                    transactionVol.transactionVolumeByMonth.length > 0
                ) {
                    let category = [];
                    let seriesData = [];
                    transactionVol.transactionVolumeByMonth.reverse().forEach(elmt => {
                        category.push(elmt.monthName.substring(0, 3).toUpperCase());
                        seriesData.push(elmt.transactionVolume);
                    });
                    KPIInfo.chartData.options.xaxis.categories = category;
                    KPIInfo.chartData.series[0].data = seriesData;
                    if (
                        transactionVol.transactionVolumeByMonth[0].year ===
                        transactionVol.transactionVolumeByMonth[11].year
                    ) {
                        KPIInfo.chartData.options.xaxis.title.text = `${this.props.t("dashboard_page.months_in")} ${transactionVol.transactionVolumeByMonth[0].year}`;
                    } else {
                        KPIInfo.chartData.options.xaxis.title.text = `${this.props.t("dashboard_page.months_between")} ${transactionVol.transactionVolumeByMonth[0].year}-${transactionVol.transactionVolumeByMonth[11].year}`;
                    }
                }
                KPIInfo.paymentTerm = transactionVol.paymentTerm;
                this.setState({
                    KPIInfo
                });
            }
            else {
                KPIInfo.chartData = null;
                KPIInfo.errlbl = "No transactions found";
            }
            let avgPeriod;
            avgPeriod = await this.api.getAverageCollectionPeriod(supplierId, cusId);
            if (avgPeriod) {
                KPIInfo.avgCollectionPeriod = avgPeriod.avgCollectionPeriod;
            }
        }
        // else {
        //     cusId = val.name.trim();
        //     const paidedDocuments = await this.api.getallpaiddocuments();
        //     res = {};
        //     res.invoiceJournalEntries = paidedDocuments.filter(doc => {
        //         let buyerName = doc.buyerName ? doc.buyerName : doc.invoice.buyerName;
        //         return cusId == buyerName;
        //     });
        //     const agingReport = await this.api.createAGNonErpCustomers(cusId);
        //     res.agingReport = {};
        //     res.agingReport.invoices = agingReport;
        //     res.agingReport.pulledDate = (new Date()).toISOString();
        //     res.agingReport.currencyCode = "USD";
        // }
        this.setState({
            isImporting: false
        });
        this.setState({
            KPIInfo
        });
    }

    getTermName(net) {
        switch (net) {
            case 1:
                return "Net30";
            case 2:
                return "Net45";
            case 3:
                return "Net60";
            case 4:
                return "Net90";
            case 5:
                return "Net120";
            case 6:
                return "Net180";
        }
        return "Net30";
    }


    render() {
        const {t}=this.props;
        const buyers = this.state.buyers;
        const { chartData, reportAsOn, avgCollectionPeriod, paymentTerm, errlbl } = this.state.KPIInfo;
        return (
            <DashboardWidgetContainer title={t("dashboard_page.widget_titles.customer_transaction_history")} size="col-md-12"
                titleContent={
                    <select
                        id="buyerSelect"
                        style={{ float: "right", width: "200px", margin: "8px" }}
                        onChange={e => {
                            this.getKPIbyCustomer(JSON.parse(e.target.value))
                        }
                        }
                        className="form-control"
                    >
                        {/* <option value="n/a">
                            Select customer...
                        </option> */}
                        {buyers.map(buyer => (
                            <option
                                value={JSON.stringify(buyer)}
                            >
                                {buyer.name}
                            </option>
                        ))}
                    </select>
                }
                isReady={!this.state.isImporting}
            >
                {chartData && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="mixed-chart">
                                    <Chart
                                        span="Month"
                                        options={chartData.options}
                                        series={chartData.series}
                                        type="bar"
                                        width="100%"
                                        height="225"
                                    />
                                </div>
                                {reportAsOn && (
                                    <div className="small text-center">
                                        <strong>{t("report_as_on")} </strong>{reportAsOn}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-3">
                                <div className="row">
                                    {/* {(avgCollectionPeriod || avgCollectionPeriod==0) &&
                                        <div className="col-md-12">
                                            <div>Average Collection Period</div>
                                            <h4>
                                                {Math.round(avgCollectionPeriod)} Days
                                            </h4>
                                        </div>
                                    } */}
                                    {(avgCollectionPeriod || avgCollectionPeriod == 0) &&
                                        <div className="col-md-12">
                                            <div>{t("dashboard_page.days_beyond_term")}</div>
                                            <h4>
                                                {Math.round(avgCollectionPeriod)}
                                                {/* {(this.state.user.accountingInformation && this.state.user.accountingInformation == "quickbooks") ? Math.round(avgCollectionPeriod) : Math.round(avgCollectionPeriod) - parseInt(paymentTerm.replace(/^\D+/g, ''))} */}
                                                {/* {Math.round(avgCollectionPeriod) - parseInt(paymentTerm.replace(/^\D+/g, ''))} */}
                                            </h4>
                                        </div>
                                    }
                                    <div className="col-md-12">
                                        <div>{t("invoice_labels.payment_terms")}</div>
                                        <h4>
                                            {paymentTerm}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>)
                }
                {
                    (!chartData && !errlbl) && (
                        <div className="awaiting-text-color text-center" style={{ fontSize: 14, fontWeight: 600 }}>
                            {t("common_names.no_record")}
                        </div>
                    )
                }
                {errlbl !== "" && (
                    <div className="text-center">
                        <span className="formErrors">
                            {errlbl}
                        </span>
                    </div>
                )}
            </DashboardWidgetContainer>
        );
    }
}
