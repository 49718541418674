import i18n from "../i18next";

//Change language based on user selection
export const changeLanguage=(lang_)=>{
    i18n.changeLanguage(lang_);
}

//Convert original string into chosen language
export const trans=(c_)=>{
    return i18n.t(c_);
}