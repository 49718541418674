import React from "react";
import { InputAdornment, TextField, Paper, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, IconButton, Grid, TablePagination, TableHead, TableSortLabel, Popover, Box, Typography, Button } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import ArrayHelper from "./ArrayHelper";
import './DataGrid.css';
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EnumFundingRequestStatus from "../../supplier/FundingRequest/EnumFundingRequestStatus";
import { withTranslation } from "react-i18next";

// interface IDataGridProps<D> {
//     title: string;
//     data: D[];
//     columns: IDataGridColumn<D>[];
//     appliedFilter?: IAppliedFilter;
//     appliedSearch?: string;
//     isRowsNonSelectable?: boolean;
//     isNoMoreLink?: boolean;
//     isNoPagination?: boolean;
//     defaultSortColumnKey?: string;
//     defaultSortDirection?: "asc" | "desc";
//     selectedItems?: D[];
//     popoverItems?: IPopoverItemData[];
//     multiPopoverItems?: IPopoverItemData[];
//     customPageSize?: number[];
//     isNoRowsPerPage?: boolean;
//     onRowClick?: (data_: D) => void;
//     onFilterTagRemoved?: (appliedFilter_: IAppliedFilter) => void;
//     onMoreClick?: (data_: D, ev_: React.MouseEvent<HTMLButtonElement>) => IPopoverItemData[] | void;
//     onMultiMoreClick?: (data_: D[]) => void;
//     onSelectionChange?: (selected_: D[]) => void;
//     onPopoverClick?: (key_: string, item_: D) => void;
//     onMultiPopoverClick?: (key_: string, selectedItems_: D[]) => void;
// }

 class DataGrid extends React.Component {
    _pageSizes = this.props.customPageSize ? this.props.customPageSize : [10, 25, 50];

    constructor(props_) {
        super(props_);

        let state = {
            selectedItems:this.props.selectedItems && this.props.selectedItems.length>0?this.props.selectedItems:this.props.defaultselectedItems && this.props.defaultselectedItems.length>0?[...this.props.defaultselectedItems]: [],
            data: props_.data,
            viewData: [],
            pageNumber: 1,
            pageSize: this.props.customPageSize ? this._pageSizes[0] : this._pageSizes[1],
            appliedFilter: props_.appliedFilter ? props_.appliedFilter : {},
            sortColumnKey: props_.defaultSortColumnKey,
            sortDirection: props_.defaultSortDirection ? props_.defaultSortDirection : "asc",
            popOverItem: null,
            popOverTargetElement: null,
            isAllCheckboxEnabled: props_.isAllCheckboxEnabled ? props_.isAllCheckboxEnabled : false,
            selectedRowExpandId:[]
        }
        state.viewData = this._sortAndSliceItems(props_.data, state);
        this.state = state;
    }

    componentDidUpdate(oldProps_) {
        if (oldProps_.appliedFilter != this.props.appliedFilter) {
            this.setState({
                appliedFilter: this.props.appliedFilter ? this.props.appliedFilter : {},
                pageNumber: 1
            }, () => {
                if (oldProps_.data != this.props.data) {
                    this._updateListViewData(this.props.data);
                }
            });
        }
        else if (oldProps_.data != this.props.data) {
            this._updateListViewData(this.props.data);
        }
        if (oldProps_.selectedItems != this.props.selectedItems) {
            this.setState({
                selectedItems: this.props.selectedItems ? this.props.selectedItems : []
            });
        }
        if (oldProps_.isAllCheckboxEnabled != this.props.isAllCheckboxEnabled) {
            this.setState({
                isAllCheckboxEnabled: this.props.isAllCheckboxEnabled ? this.props.isAllCheckboxEnabled : false
            });
        }
    }

    render() {
        const { isPrefunding } = this.props
        const {selectedRowExpandId}=this.state;
        return (
            <div style={
                this.props.height
                  ? {
                      minHeight: this.props.height,
                      height: `calc(${this.props.height} + 10vh)`, 
                      maxHeight: "100vh",
                    }
                  : {}
              }
              className={'table-container-list data-grid-table-container-list'}>
                <div className="data-grid-header">
                    <div className="filter-criterias">
                        {
                            this.props.filterComponent ?
                                this.props.filterComponent
                                : null
                        }
                    </div>
                    {this.props.isGridSearch && (
                        <React.Fragment>
                            <div className="filter-reset">
                                {this.props.isResetFilterEnabled  ?
                                    <a className="nav-link active p-0" style={{ cursor: "pointer" }}
                                        onClick={() => this.props.resetFilters()}
                                    ><h6>{this.props.t("grid_view_filter_names.reset_filter")}</h6></a>
                                    :<h6 style={{ color:'#999999' }}>{this.props.t("grid_view_filter_names.reset_filter")}</h6> }
                            </div>
                            <div className="grid-search">
                                <TextField
                                    id="input-with-icon-textfield"
                                    variant="standard"
                                    label={this.props.t("grid_view_filter_names.search")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={this.state.appliedFilter.textFilter}
                                    onChange={(ev_) => this.props.searchText(ev_.target.value)}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <Paper className="data-grid-paper-container">
                    <TableContainer className="data-grid-table-container" style={{ width: this.props.width ? this.props.width + "%" : "" }}>
                        <Table
                            aria-labelledby={this.props.title}
                            size={'medium'}
                            stickyHeader
                            aria-label="sticky table"
                        >
                            <TableHead className={'th-list-thead'}>
                                <TableRow>
                                    {
                                        !this.props.isRowsNonSelectable ?
                                            <TableCell padding="none" className={'th-list-head'}>
                                                <Checkbox
                                                    indeterminate={this._hasAnySelected() && !this._hasAllSelected()}
                                                    checked={this._hasAllSelected()}
                                                    onChange={this._selectAllRows}
                                                    className="check-box-table pl-0"
                                                    disabled={this.state.isAllCheckboxEnabled}
                                                />
                                            </TableCell>
                                            : null
                                    }
                                    {
                                        this.props.columns.map(column_ => (
                                            <TableCell
                                                className="data-grid-th"
                                                key={column_.key}
                                                align={column_.textAlignment}
                                                padding={'normal'}
                                                sortDirection={
                                                    this.state.sortColumnKey === column_.key ?
                                                        this.state.sortDirection
                                                        : undefined}
                                                onClick={() => this._sortByColumn(column_)}
                                                style={{zIndex:"auto"}}
                                            >
                                                <TableSortLabel
                                                    active={this.state.sortColumnKey === column_.key}
                                                    direction={this.state.sortDirection}
                                                    align="left"
                                                    IconComponent={KeyboardArrowUpIcon}
                                                >
                                                    {column_.name}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))
                                    }
                                    {
                                        !this.props.isNoMoreLink ?
                                            <TableCell padding="none" className={'data-grid-th th-list-head'} style={{zIndex:"auto"}}>
                                                {/* <IconButton aria-label="More" disabled={this.state.selectedItems.length === 0}
                                                    onClick={(ev_) => {
                                                        if (this.props.onMultiMoreClick) {
                                                            this.props.onMultiMoreClick(this.state.selectedItems)
                                                        }
                                                        else if (this.props.multiPopoverItems && this.props.multiPopoverItems.length) {
                                                            this.setState({
                                                                popOverTargetElement: ev_.target
                                                            });
                                                        }
                                                    }}>
                                                    <MoreHorizIcon />
                                                </IconButton> */}
                                                {this.props.t("grid_view_header_labels.actions")}
                                                {
                                                    this.props.multiPopoverItems != undefined &&
                                                    <Popover
                                                        open={this.state.popOverItem == null && this.state.popOverTargetElement != null}
                                                        anchorEl={this.state.popOverTargetElement}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        onClose={() => {
                                                            this.setState({
                                                                popOverTargetElement: null,
                                                                popOverItem: null
                                                            });
                                                        }}
                                                    >
                                                        {this._buildPopoverItems(this.props.multiPopoverItems, null)}
                                                    </Popover>
                                                }
                                            </TableCell>
                                            : null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.viewData
                                        .map((row_, index_) => {
                                            const isItemSelected = this._rowSelectedIndex(row_) > -1;
                                            const labelId = `enhanced-table-checkbox-${index_}`; 
                                            return (
                                                <>
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={index_}
                                                        selected={isItemSelected}
                                                        className={(row_.isRowSelect && this.props.title == "Select Matched Funders") ? "gridSelectedRow" : ""}
                                                        onClick={() => {
                                                            if (this.props.onRowClick && this.state.popOverTargetElement == null) {
                                                                this.props.onRowClick(row_);
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            !this.props.isRowsNonSelectable ?
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        onClick={(ev_) => {
                                                                            this._selectRow(row_);
                                                                            ev_.stopPropagation();
                                                                        }}
                                                                        checked={isItemSelected}
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        className="check-box-table"
                                                                        disabled={row_.isRowSelect}
                                                                    />
                                                                </TableCell>
                                                                : null
                                                        }
                                                        {
                                                            this.props.columns.map((column_, i) => {
                                                                return <TableCell
                                                                    align={column_.textAlignment}
                                                                    key={i}
                                                                    className={`table-list-cell p-2 ${column_.staticWidthClass?column_.staticWidthClass:""}`}
                                                                    style={{ padding: "none" }}
                                                                    onClick={() => {
                                                                        let eventType = (typeof column_.eventType === 'function') ? column_.eventType(row_, column_.key) : column_.eventType;
                                                                        if (eventType) {
                                                                            this.tableCellClick(row_, eventType, i);
                                                                        }
                                                                        //Added row expand condition for particular cell

                                                                        if(column_.isExpandCell){
                                                                            if(selectedRowExpandId.filter(s_=>s_.id==row_._id && s_.type==column_.key).length==0){
                                                                                this.setState({selectedRowExpandId:[...selectedRowExpandId,{id:row_._id,type:column_.key}]});
                                                                            }
                                                                            else{
                                                                                const _filteredItems=selectedRowExpandId.filter(item=>{
                                                                                    if(item.type==column_.key){
                                                                                        return item.id!=row_._id;
                                                                                    }
                                                                                    else if(item.type!=column_.key){
                                                                                        return true;
                                                                                    }
                                                                                })
                                                                                this.setState({selectedRowExpandId:_filteredItems})
                                                                            }                                                                           
                                                                        }
                                                                        
                                                                    }}
                                                                >
                                                                    {
                                                                        column_.contentProvider ?
                                                                            column_.contentProvider(row_, column_.key,selectedRowExpandId.filter(s_=>s_.id==row_._id && s_.type==column_.key).length==0)
                                                                            : <span className={"data-grid-table-cell " + column_.eventType && column_.eventType == "view" ? " data-grid-document cursor-pointer" : ""}>{(row_[column_.key] ? row_[column_.key] : "").toString()}</span>
                                                                    }
                                                                </TableCell>
                                                            })
                                                        }
                                                        {
                                                            !this.props.isNoMoreLink && (row_.isNoMoreLink || (row_.isNoMoreLink == undefined)) ?
                                                                <TableCell padding="checkbox">
                                                                    <IconButton aria-label="More"
                                                                        // disabled={!isItemSelected}
                                                                        onClick={(ev_) => {
                                                                            if (this.props.onMoreClick) {
                                                                                let popoverItems = this.props.onMoreClick(row_, ev_);
                                                                                if (popoverItems && popoverItems.length) {
                                                                                    this.setState({
                                                                                        tempPopoverItems: popoverItems,
                                                                                        popOverTargetElement: ev_.target,
                                                                                        popOverItem: row_
                                                                                    });
                                                                                }
                                                                            }
                                                                            else if (this.props.popoverItems && this.props.popoverItems.length) {
                                                                                this.setState({
                                                                                    popOverTargetElement: ev_.target,
                                                                                    popOverItem: row_
                                                                                });
                                                                            }
                                                                            ev_.stopPropagation();
                                                                        }}>
                                                                        <MoreHorizIcon />
                                                                    </IconButton>
                                                                    {
                                                                        (this.state.tempPopoverItems != undefined || this.props.popoverItems != undefined) &&
                                                                        <Popover
                                                                            open={this.state.popOverItem == row_ && this.state.popOverTargetElement != null}
                                                                            anchorEl={this.state.popOverTargetElement}
                                                                            anchorOrigin={{
                                                                                vertical: 'bottom',
                                                                                horizontal: 'center',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'left',
                                                                            }}
                                                                            onClose={() => {
                                                                                this.setState({
                                                                                    popOverTargetElement: null,
                                                                                    popOverItem: null
                                                                                });
                                                                            }}
                                                                            PaperProps={isPrefunding ? { style: { width: '150px' } } : undefined}
                                                                        >
                                                                            {this._buildPopoverItems((this.state.tempPopoverItems ? this.state.tempPopoverItems : this.props.popoverItems), row_)}
                                                                        </Popover>
                                                                    }
                                                                </TableCell>
                                                                : null
                                                        }
                                                    </TableRow>
                                                    {(selectedRowExpandId.filter(item => item.id == row_._id).length > 0) && this.props && this.props.buildExpandRowItems && (
                                                        <>
                                                            {this.props.buildExpandRowItems(row_, selectedRowExpandId.filter(item => item.id == row_._id))}
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })}
                            </TableBody>
                        </Table>
                        {this.state.viewData.length == 0 && this.props.emptydataLabel && (
                                <div className="text-center p-3">{this.props.emptydataLabel}</div>
                            )}
                    </TableContainer>
                </Paper>
                {
                    !this.props.isNoPagination ?
                        <Grid container>
                            <Grid item>
                                {
                                    !this.props.isNoRowsPerPage &&
                                    <TablePagination
                                        rowsPerPageOptions={this._pageSizes}
                                        component="div"
                                        count={this.state.data.length}
                                        rowsPerPage={this.state.pageSize}
                                        page={this.state.pageNumber - 1}
                                        onChangePage={(e_, pageNumber_) => { this._changePage(pageNumber_) }}
                                        onChangeRowsPerPage={(e_) => this._changePageSize(parseInt(e_.target.value))}
                                        className="table-lis-footer"
                                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} results`}
                                    />
                                }
                            </Grid>
                            <Grid item xs className="footer-pagenation">
                                <Pagination
                                    page={this.state.pageNumber}
                                    className="Pagination"
                                    showFirstButton
                                    showLastButton
                                    onChange={(e_, pageNumber_) => this._changePage(pageNumber_)}
                                    count={Math.ceil(this.state.data.length / this.state.pageSize)}
                                />
                            </Grid>
                        </Grid> : null
                }

            </div>
        );
    }

    _sortAndSliceItems = (data_, state_, autoPageChangeCallback_) => {
        if (state_.sortDirection && state_.sortColumnKey) {
            let coloumn = ArrayHelper.findObject(this.props.columns, "key", state_.sortColumnKey);
            let comparator = coloumn.getComparator ? coloumn.getComparator(state_.sortDirection, state_.sortColumnKey) : ArrayHelper.getComparator(state_.sortDirection, state_.sortColumnKey);
            data_ = ArrayHelper.stableSort(data_, comparator);
        }

        if (!this.props.isNoPagination) {
            let viewStartRowIndex = 0;
            let pageNumber = state_.pageNumber;

            do {
                viewStartRowIndex = (pageNumber - 1) * state_.pageSize;
                if (data_.length && viewStartRowIndex >= data_.length && pageNumber > 0) {
                    --pageNumber;
                }
                else {
                    break;
                }
            }
            while (true);

            let activeViewData = data_.slice(viewStartRowIndex, viewStartRowIndex + state_.pageSize);

            if (autoPageChangeCallback_ && pageNumber != state_.pageNumber) {
                autoPageChangeCallback_(pageNumber);
            }

            return activeViewData;
        } else {
            return data_;
        }
    }

    _hasAnySelected = () => {
        return this.state.selectedItems.length > 0;
    }

    _hasAllSelected = () => {
        return this._hasAnySelected() && this.state.selectedItems.length === this.state.data.length;
    }

    _rowSelectedIndex = (row_) => {
        if(this.props.isPrefunding){
            let filteredEntries = this.state.selectedItems.map((item_, index_) => { return { idx: index_, item: item_ } }).filter(data_=>data_.item._id==row_._id);
            if (filteredEntries.length) {
                return filteredEntries[0].idx;
            }
            return -1;
        }
        else{
            return this.state.selectedItems.indexOf(row_);
        }
    }

    _updateListViewData = (updatedData_) => {
        if (!updatedData_) {
            this.setState({
                viewData: this._sortAndSliceItems(this.state.data, this.state)
            });
        }
        else {
            let pageNumber = this.state.pageNumber;
            let viewData = this._sortAndSliceItems(updatedData_, this.state, (newPageNumber_) => pageNumber = newPageNumber_);

            this.setState({
                data: updatedData_,
                viewData: viewData,
                pageNumber: pageNumber
            });
        }
    }

    _selectRow = (row_) => {
        let selectedRows = this.state.selectedItems;
        let rowSelectionIndex = this._rowSelectedIndex(row_);

        // If not selected already, then select
        if (rowSelectionIndex == -1) {
            selectedRows.push(row_);
        }
        // else, deselect
        else {
            selectedRows.splice(rowSelectionIndex, 1);
        }

        this.setState({
            selectedItems: selectedRows
        }, () => {
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(selectedRows);
            }
        });
    }

    _selectAllRows = () => {
        let selectedItems = [];
        // If none are already selected, then select all
        if (!this._hasAnySelected()) {
            const selectedRow = this.state.data.filter(doc => !doc.isRowSelect);
            selectedItems = selectedRow.slice(0);
        }
        // else, deselect all
        else {
            selectedItems = [];
        }

        this.setState({
            selectedItems: selectedItems
        }, () => {
            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(selectedItems);
            }
        });
    }

    _sortByColumn = (column_) => {
        this.setState({
            sortColumnKey: column_.key,
            sortDirection: this.state.sortColumnKey === column_.key ?
                (this.state.sortDirection === "asc" ? "desc" : "asc") // if already sorted by same column
                : "asc" // sorting new column
        }, this._updateListViewData);
    }

    _changePage = (pageNumber_) => {
        this.setState({
            pageNumber: pageNumber_
        }, this._updateListViewData);
    }

    _changePageSize = (pageSize_) => {
        this.setState({
            pageNumber: 1,
            pageSize: pageSize_
        }, this._updateListViewData);
    }

    tableCellClick = (row, type, index) => {
        this.props.handleTableCellClick(row, type, index);
    }

    _buildPopoverItems = (popoverData_, row_) => {
        return (
            <Paper style={{ width: "200px" }}>
                {
                    popoverData_.map(d => {
                        if (d.key == "View") {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if (d.key == "PreFunding" && row_.status == 0 && d.role!="admin" ) {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if (d.key == "editPreFunding" && row_.isPreFundingRequest &&
                            (((row_.status >= 1 && row_.status < EnumFundingRequestStatus.FundingAccepted) && d.role != "admin") ||
                                (row_.status == EnumFundingRequestStatus.FundingAccepted && (row_.contracts && row_.contracts.length && !row_.contracts[0].fundingAggrement)))
                        ) {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if (d.key == "ViewFunders" && !row_.contractId && row_.status > 1 && row_.status < 5) {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if (d.key == "Message" && row_.status >= 3 && d.role!="admin"  && !row_.isCreatedByImpersonateUser) {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if (d.key == "ViewFundersOffer" && row_.status == 4 && d.role!="admin") {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if (d.key == "viewContract") {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.key=="terminateContract" && row_.status<3 && row_.fundingAggrement && row_.signedAggrements){
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.role!="admin" && d.key=="SignAgreement" && row_.status == EnumFundingRequestStatus.FundingAccepted &&
                            (row_.contracts && row_.contracts.length && row_.contracts[0].fundingAggrement && !row_.contracts[0].signedAggrements)){
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.key=="addFunderDescription" && row_.role=="Funder"){
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.key=="showFinancialDocuments"  && (row_.role=="Supplier" || row_.role =="Buyer")){
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.key=="showFinancialInformation"  && (row_.role=="Supplier" || row_.role =="Buyer")){
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.key=="disableUser"  && ((row_.role=="Supplier" && row_.status!=3) || (row_.role =="Buyer" && row_.status!=3))){
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.key =="deleteInvoice" && row_.status < 99 && row_.invoice && (!row_.invoice.source || (row_.invoice.source && row_.invoice.source=="file"))){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="deletePO" && row_.status < 99 && row_.purchaseOrder && (!row_.purchaseOrder.source || (row_.purchaseOrder.source && row_.purchaseOrder.source=="file"))){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="deleteSC" && ( row_.status ==0)  && row_.supplyChain && (!row_.supplyChain.source || (row_.supplyChain.source && row_.supplyChain.source=="file"))){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="approveSC" && row_.status ==1 && row_.supplyChain && (!row_.supplyChain.source || (row_.supplyChain.source && row_.supplyChain.source=="file"))){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="rejectSC" && row_.status ==1 && row_.supplyChain && (!row_.supplyChain.source || (row_.supplyChain.source && row_.supplyChain.source=="file"))){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="inviteSupplier" && row_.isInviteSupplier && row_.supplyChain && (!row_.supplyChain.source || (row_.supplyChain.source && row_.supplyChain.source=="file"))){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if((d.key =="approveInvoice" || d.key=="rejectInvoice") && row_.status ==1){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if((d.key =="updatePreFundingInvoice" || d.key=="deletePreFundingInvoice")){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if (d.key == "viewFundingRequest") {
                            return this._buildPopoverButtonItems(d, row_)
                        }
                        else if(d.key =="makeOffer" && row_.status == EnumFundingRequestStatus.AwaitingFundingOffer && !row_.contractId){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="acceptReq" && row_.status == EnumFundingRequestStatus.AwaitingFundingOffer && row_.contractId){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="declineReq" && row_.status == EnumFundingRequestStatus.AwaitingFundingOffer){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="fundingAgreement" && row_.status == EnumFundingRequestStatus.FundingAccepted && row_.contracts && row_.contracts.length && !row_.contracts[0].fundingAggrement ){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                        else if(d.key =="markAsPaid" && row_.contracts && row_.contracts.length && row_.contracts[0].fundingAggrement && row_.contracts[0].signedAggrements &&
                             (row_.status ==  EnumFundingRequestStatus.FundingAccepted || row_.status == EnumFundingRequestStatus.FundingRequestApprovedExistingContract)){
                            return this._buildPopoverButtonItems(d,row_);
                        }
                    })
                }
            </Paper>
        );
    }

    _buildPopoverButtonItems = (d, row_) => {
        return <Button
            className="w-100 multiPopoverButton"
            onClick={() => {
                this.setState({
                    popOverTargetElement: null,
                    popOverItem: null
                });
                if (row_ == null) {
                    if (this.props.onMultiPopoverClick) {
                        this.props.onMultiPopoverClick(d.key, this.state.selectedItems);
                    }
                }
                else if (this.props.onPopoverClick) {
                    this.props.onPopoverClick(d.key, row_);
                }
            }}
        >{d.label == 'Active' || d.label == 'Inactive' ? <div className={'list-status dropdown-status ' + d.key}></div> : null} {d.label}</Button>
    }
}

export default withTranslation()(DataGrid);
