import React, { Component } from "react";
import Dropzone from "react-dropzone";

import { API_ENDPOINT_SUPPLIER } from "../../../../services/API";
import User from "../../../../services/User";
import API from "../../../../services/API";
import FullSpinner from "../../../common/Spinner/FullSpinner";
import CommonConfirmationModal,{AddModal} from "../../../common/Modals/CommonConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt,faCloudUploadAlt} from "@fortawesome/free-solid-svg-icons";
import Popup from "../../../common/Auth/Popup";
import "./ABL.css";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import{localDateFormat} from "../../../../services/Utilities";
import {parse} from "../../../../../node_modules/csv/dist/esm/index.js";
import { read, utils } from "xlsx";
import SampleInventoryCSVFile from  "./sampleInventorySummary.csv";
import SampleAgingDetailCSVFile from "./sampleReceivableAgingDetailsReport.csv";

const numberMask = createNumberMask({
    prefix: "$",
    suffix: ""
});


export default class ABLUploadModal extends React.Component {
    user = new User();
    api = new API();

    constructor(props_) {
        super(props_);
        this.state = {
            agingReportReceivableDocuments:[],
            inventoryReport:[],
            collateralAmount:0,
            isSubmitbtnEnabled:false,
            user:{},
            isErpSystem:false,
            isAccountingSystemValue:false,
            isSpinner:false
        };
        this.handleInventoryReportRUpload=this.handleInventoryReportRUpload.bind(this);
    }

    componentDidMount(){
        this.getProfile();
    }
    
    //..Not Changed..//
    getProfile=async()=>{
        this.setState({isSpinner:true});
        const profileResponse = await this.api.getV2Profile();
        if(profileResponse.user && profileResponse.user.accountingInformation){
            this.setState({user:profileResponse.user});
            if(profileResponse.user.accountingInformation=="quickbooks"){
                const qbInfo=await this.api.gettokenfromQB(profileResponse.user.email);
                if(qbInfo && !qbInfo.msg){
                  this.setState({
                    isErpSystem:true
                })
                }
              }
              else if(profileResponse.user.accountingInformation=="D365BC"){
                const qbInfo=await this.api.getD365BCDetails(profileResponse.user.email);
                if(qbInfo && !qbInfo.accesstoken){
                  this.setState({
                    isErpSystem:true
                })
                }
              }
              else{
                const ERPInfo = await this.api.getErpInfo(profileResponse.user.email);
                if(ERPInfo && !ERPInfo.message){
                  this.setState({
                    isErpSystem:true
                  })
                }
              }
        }
        this.setState({isSpinner:false});
    }

    render(){
        const {
            collateralAmount,
            agingReportReceivableDocuments,
            inventoryReport,
            isErpSystem,
            isAccountingSystemValue,
            isSpinner
        }=this.state;

        return (<Popup className={"po-upload-popup"}
        content={<React.Fragment>
            <FullSpinner
                isSpinning={isSpinner}
            />
            <div className="popup">
                <div className="d-flex flex-column">
                    <div>
                        <label className="popup-close-icon" onClick={e => this.props.onClose()}></label>
                        <h2>Asset-Based Lending </h2>
                    </div>
                    <div className="text-center mt-3">
                        <button className="btn btn-primary" type="submit" 
                        disabled={!isErpSystem}
                        onClick={e => this.importFromERP(e)}>
                            <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
                            Import from ERP/Accounting System
                        </button>&nbsp; or Upload manually
                    </div>
                    <div  className="d-flex mt-3" style={{justifyContent:"space-evenly"}}>
                        <div className="form-label-style pl-2"  style={{width:"45%",fontWeight:800}}>
                            Inventory Detail Report
                        </div>
                        <div className="form-label-style" style={{width:"45%",fontWeight:800}}>
                            Accounts Receivable Aging Detail Report
                        </div>
                    </div>
                    <div className="mt-2 ablupload d-flex" style={{justifyContent:"space-evenly"}}>
                        <Dropzone onDrop={this.handleInventoryReportRUpload} >
                            {this.state.inventoryReport && this.state.inventoryReport.length > 0 ? <div>
                                {
                                    this.getReports(this.state.inventoryReport ,"InventoryReport")
                                }
                            </div> : "Upload Inventory Detail Report"}
                        </Dropzone>
                        <Dropzone 
                            onDrop={this.handleAgingReportRUpload} >
                            {this.state.agingReportReceivableDocuments && this.state.agingReportReceivableDocuments.length>0 ?<div>
                                {
                                    this.getReports(this.state.agingReportReceivableDocuments,"AgingReport")
                                }
                            </div>:"Upload Accounts Receivable Aging Detail Report"}
                        </Dropzone>
                    </div>
                    <div  className="d-flex mt-1" style={{justifyContent:"space-evenly"}}>
                        <div className="form-label-style pl-2" style={{ width: "45%", fontWeight: 800 }}>
                            <p className="example-text">Example Inventory File: </p>
                            <a href={SampleInventoryCSVFile} htmLDownload={SampleInventoryCSVFile}>
                                Sample Inventory Report
                            </a>
                        </div>
                        <div className="form-label-style" style={{width:"45%",fontWeight:800}}>
                        <p className="example-text">Example Receivable File: </p>
                            <a href={SampleAgingDetailCSVFile} htmLDownload={SampleAgingDetailCSVFile}>
                                Sample Receivable Details Aging Report
                            </a>
                        </div>
                    </div>
                    <div className="mt-3 d-flex" style={{ justifyContent: "space-evenly" }}>
                        <div className="pl-2" style={{ width: "45%" }}>
                            <div className="form-group ">
                                <label className="form-label-style" htmlFor="collaeralAmt">Collateral Amount</label>
                                {(agingReportReceivableDocuments.length > 0 && inventoryReport.length > 0) ?
                                    (<>
                                        <MaskedInput
                                            name="collateralAmount"
                                            value={collateralAmount}
                                            onChange={e =>
                                                this.setState({ collateralAmount: e.target.value }, () => {
                                                    if (this.state.collateralAmount.length > 0 && this.state.agingReportReceivableDocuments.length > 0 && this.state.inventoryReport.length > 0) {
                                                        this.setState({ isSubmitbtnEnabled: true });
                                                    }
                                                })
                                            }
                                            mask={numberMask}
                                            disabled={isAccountingSystemValue}
                                            className="form-control"
                                            placeholder="$ ..."
                                        />
                                    </>)
                                    : (<>
                                        <MaskedInput
                                            name="collateralAmount"
                                            onChange={e =>
                                                this.setState({ collateralAmount: e.target.value }, () => {
                                                    if (this.state.collateralAmount.length > 0 && this.state.agingReportReceivableDocuments.length > 0 && this.state.inventoryReport.length > 0) {
                                                        this.setState({ isSubmitbtnEnabled: true });
                                                    }
                                                })
                                            }
                                            mask={numberMask}
                                            disabled={isAccountingSystemValue}
                                            className="form-control"
                                            placeholder="$ ..."
                                        />
                                    </>)}

                            </div>
                        </div>
                        <div style={{ width: "45%" }}>

                        </div>
                    </div>
                    <div className="col-12 mt-1">
                        <button
                            type="button"
                            onClick={() => {
                                let formData = new FormData();
                                let newDate = new Date()
                                let _assetId ="ABL-"+newDate.getFullYear()+newDate.getMonth()+newDate.getDate();
                                formData.append("assetId", _assetId);
                                formData.append("assetAmount", this.state.collateralAmount.toString().replace(/[^0-9.]/g, ""));
                                for(let file of this.state.agingReportReceivableDocuments){
                                    if(file.source){
                                        var contentType = 'text/csv';
                                        var csvFile = new Blob([file.csvContent], { type: contentType });
                                        formData.append("AccountReceivable_DOC",csvFile,(file && file.name)?file.name:file.filename);
                                    }
                                    else{
                                        formData.append("AccountReceivable_DOC",file,(file && file.name)?file.name:file.filename);
                                    }
                                }
                                for (let file of this.state.inventoryReport) {
                                    if(file.source){
                                        formData.append("Inventory_DOC", file.blobfile, (file && file.name)?file.name:file.filename);
                                    }
                                    else{
                                        formData.append("Inventory_DOC", file, (file && file.name)?file.name:file.filename);
                                    }

                                }

                                this.props.onSubmit(formData);
                            }}
                            disabled={!this.state.isSubmitbtnEnabled}
                            className="btn btn-outline-secondary btn-lg import-invoice-btn mr-4"
                        >
                            Upload Asset-based Lending
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>}
        handleClose={e => this.props.onClose()}
    />);
    }

    handleAgingReportRUpload=(data)=>{
        const {collateralAmount,inventoryReport,agingReportReceivableDocuments}=this.state;
        let existingDocuments = [];
        data.forEach(element => {
            element.id=Math.random().toString(36).slice(2);
        });
        for (let exisitingDoc_ of this.state.agingReportReceivableDocuments){
            for (let file of data){
                if(file.name==exisitingDoc_.name){
                    AddModal("The following document is already imported");
                    return
                }
            }
        }
        existingDocuments=this.state.agingReportReceivableDocuments;
        existingDocuments=existingDocuments.concat(data);
        const _isSaveEnabled=inventoryReport.length>0 && collateralAmount.toString().length>0 && existingDocuments.length>0;
        this.setState({agingReportReceivableDocuments:existingDocuments,isSubmitbtnEnabled:_isSaveEnabled})
        this.handleagingReportDetailsUploadRead(existingDocuments);
    }

    handleInventoryReportRUpload(data){
        const {collateralAmount,inventoryReport,agingReportReceivableDocuments}=this.state;
        let existingDocuments = [];
        data.forEach(element => {
            element.id=Math.random().toString(36).slice(2);
        });
        for (let exisitingDoc_ of this.state.inventoryReport){
            for (let file of data){
                if(file.name==exisitingDoc_.name){
                    AddModal("The following document is already imported");
                    return
                }
            }
        }
        existingDocuments=this.state.inventoryReport;
        existingDocuments=existingDocuments.concat(data);
        const _isSaveEnabled=agingReportReceivableDocuments.length>0 && collateralAmount.toString().length>0 && existingDocuments.length>0;
        this.handleInventoryReportUploadRead(existingDocuments);
        this.setState({inventoryReport:existingDocuments,isSubmitbtnEnabled:_isSaveEnabled},()=>{
            
        });
    }

    getReports=(data,type_)=>{
        return data && data.map(d=>{
            if(d.source && (d.source=="QBO" ||d.source=="D365BC")){
                return <div key={d.filename}>
                <a href={d.downloadUrl} target="_blank" onClick={e=>e.stopPropagation()}>{d.filename}</a>
                <span style={{ float: "right", marginRight: "25px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        const filteredDocs_=data.filter(doc=>doc.fileId!=d.fileId);
                        if(type_=="InventoryReport"){
                            this.setState({inventoryReport:filteredDocs_,isAccountingSystemValue:false,isSubmitbtnEnabled:!(filteredDocs_.length==0)},()=>{
                                this.calculateCollateralAmt();
                            });
                        }
                        else{
                            this.setState({agingReportReceivableDocuments:filteredDocs_,isAccountingSystemValue:false,isSubmitbtnEnabled:!(filteredDocs_.length==0)},()=>{
                                this.calculateCollateralAmt();
                            });
                        }
                    }}>
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                </span>
            </div>
            }
            else{
                return <div key={d.name}>
                <a href={d.preview} target="_blank" onClick={e=>e.stopPropagation()}>{d.name}</a>
                <span style={{ float: "right", marginRight: "25px" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        const filteredDocs_=data.filter(doc=>doc.id!=d.id);
                        if(type_=="InventoryReport"){
                            this.setState({inventoryReport:filteredDocs_},()=>{
                                this.calculateCollateralAmt();
                            });
                        }
                        else{
                            this.setState({agingReportReceivableDocuments:filteredDocs_},()=>{
                                this.calculateCollateralAmt();
                            });
                        }
                    }}>
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                </span>
            </div>
            }
           
        })
    }

    importFromERP= async (e)=>{
        if(this.state.user && (this.state.user.accountingInformation=="quickbooks" ||this.state.user.accountingInformation=="D365BC")){
            this.setState({isSpinner:true});
            const res = await this.api.getSupplierDocs();
            if(res && res.items && res.items.length>0){
                let inventoryReport_=res.items.filter(f_=>f_.financialDocumentType=="INVENTORY_VALUATION_SUMMARY");
                if(inventoryReport_.length>0){
                    const textResponse_ =await fetch(inventoryReport_[0].downloadUrl, {
                        "method": "GET",
                        "mode": "cors",
                        "credentials": "include"
                    });
                    const csvContext_=await textResponse_.text();
                    const blobResponse_ =await fetch(inventoryReport_[0].downloadUrl, {
                        "method": "GET",
                        "mode": "cors",
                        "credentials": "include"
                    });
                    const blobFileContent_=await blobResponse_.blob();
                    let blobfile_ = new File([blobFileContent_], inventoryReport_[0].filename);
                    inventoryReport_[0].blobfile = blobfile_;
                    inventoryReport_[0].csvContent=csvContext_;
                    this.setState({inventoryReport:inventoryReport_},()=>{
                        this.handleInventoryReportRead();
                    });
                }
            }
            await this.getReceivableDetailsAgingReport();
            this.setState({isSpinner:false});
        }
    }

    handleInventoryReportRead=()=>{
        const {inventoryReport}=this.state;
        let fileExtention = '';
        if(inventoryReport.length>0 && inventoryReport[0].source && (inventoryReport[0].source=="QBO" || inventoryReport[0].source=="D365B")){
            fileExtention = inventoryReport[0].filename.substring(inventoryReport[0].filename.lastIndexOf(".") + 1).toUpperCase();
            if (fileExtention == 'CSV') {
                let inventoryTotal_=0;
                parse(inventoryReport[0].csvContent, (err, data_) => {
                    if(data_ && data_.length>0){
                        data_.forEach(element => {
                            if(element[0]=="TOTAL"){
                               inventoryTotal_= (element[3].length>0)?parseInt(element[3]):0;
                               inventoryReport[0].totalAmt=inventoryTotal_;
                            }
                        });
                    }
                    this.setState({inventoryReport});
                });

            }
        }
    }

    handleInventoryReportUploadRead = async (data) => {
        let fileExtention = '';
        if (data.length > 0) {
            let inventoryReportList_ = [];
            let readFiles = await Promise.all(data.map(async (files_) => {
                if ( files_.source && files_.source == "QBO" || files_.source == "D365BC") {
                    return files_;
                }
                else {

                    if (files_.name && files_.name.indexOf('.') > -1) {
                        fileExtention = files_.name.substring(files_.name.lastIndexOf(".") + 1).toUpperCase();
                    }
                    if (fileExtention == "CSV") {
                        let readerValue_ = await this.readFileAsync(files_, "CSV");
                        files_.fileContent = readerValue_;
                        files_.fileExtention = "CSV";
                        return files_;
                    }
                    else if (fileExtention == "XLSX") {
                        let readerValue_ = await this.readFileAsync(files_, "XLSX");
                        files_.fileContent = readerValue_;
                        files_.fileExtention = "XLSX";
                        return files_;
                    }

                }

            }));
            if (readFiles && readFiles.length > 0) {
                await Promise.all(
                    readFiles.map(async (inventory_, i) => {
                        if (inventory_.source && (inventory_.source == "QBO" || inventory_.source == "D365BC")) {
                            inventoryReportList_.push(inventory_);
                            return inventory_;
                        }
                        else {
                            if (inventory_.fileExtention && inventory_.fileExtention == "CSV") {
                                if (inventory_.fileContent) {
                                    let totalAmtValue_ = await this.readCSVFileWithType(inventory_.fileContent, "CSV", "IN");
                                    if (totalAmtValue_) {
                                        inventory_.totalAmt = totalAmtValue_;
                                        inventoryReportList_.push(inventory_);
                                        return inventory_;
                                    }
                                }
                            }
                            else if (inventory_.fileExtention && inventory_.fileExtention == "XLSX") {
                                if (inventory_.fileContent) {
                                    const bstr = inventory_.fileContent;
                                    const wb = read(bstr, { type: "binary" });
                                    const wsname = wb.SheetNames[0];
                                    const ws = wb.Sheets[wsname];
                                    const excelData_ = utils.sheet_to_json(ws, { header: 1 });
                                    let totalColumnIndex_ = 0;
                                    let isColumnFound_ = false;
                                    let inventoryTotalAmt_ = 0;
                                    excelData_.forEach(rowData_ => {
                                        if (rowData_.length > 2) {
                                            rowData_.forEach((coloumnData_, i) => {
                                                if (coloumnData_ == "Asset Value") {
                                                    totalColumnIndex_ = i;
                                                    isColumnFound_ = true;
                                                }

                                            })
                                        }
                                        if (isColumnFound_ && rowData_.length >= totalColumnIndex_) {
                                            if (rowData_[0] == "TOTAL") {
                                                inventoryTotalAmt_ += rowData_[totalColumnIndex_];
                                                inventory_.totalAmt = inventoryTotalAmt_;
                                                inventoryReportList_.push(inventory_);
                                                return inventory_;
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    })
                )
            }
            if (inventoryReportList_.length > 0) {
                this.setState({ inventoryReport: inventoryReportList_ }, () => {
                    this.calculateCollateralAmt();
                });
            }
        }
    }

    handleagingReportDetailsUploadRead = async (data) => {
        let fileExtention = '';
        if (data.length > 0) {
            let agingDetailsReportList_ = [];
            const readFiles_ = await Promise.all(data.map(async (files_) => {
                if (files_.source && files_.source == "QBO" || files_.source == "D365BC") {
                    return files_;
                }
                else {

                    if (files_.name && files_.name.indexOf('.') > -1) {
                        fileExtention = files_.name.substring(files_.name.lastIndexOf(".") + 1).toUpperCase();
                    }
                    if (fileExtention == "CSV") {
                        let readerValue_ = await this.readFileAsync(files_, "CSV");
                        files_.fileContent = readerValue_;
                        files_.fileExtention = "CSV";
                        return files_;
                    }
                    else if (fileExtention == "XLSX") {
                        let readerValue_ = await this.readFileAsync(files_, "XLSX");
                        files_.fileContent = readerValue_;
                        files_.fileExtention = "XLSX";
                        return files_;
                    }
                }

            }));
            if (readFiles_ && readFiles_.length > 0) {
                await Promise.all(readFiles_.map(async (agingDetails_, i) => {
                    if (agingDetails_.source && (agingDetails_.source == "QBO" || agingDetails_.source == "D365BC")) {
                        agingDetailsReportList_.push(agingDetails_);
                        return agingDetails_;
                    }
                    else {
                        if (agingDetails_.fileExtention && agingDetails_.fileExtention == "CSV") {
                            let totalAmtValue_ = await this.readCSVFileWithType(agingDetails_.fileContent, "CSV", "AG");
                            if (totalAmtValue_) {
                                agingDetails_.totalAmt = totalAmtValue_;
                                agingDetailsReportList_.push(agingDetails_);
                                return agingDetails_;
                            }
                        }
                        else if (agingDetails_.fileExtention && agingDetails_.fileExtention == "XLSX") {
                            if (agingDetails_.fileContent) {
                                const bstr = agingDetails_.fileContent;
                                const wb = read(bstr, { type: "binary" });
                                const wsname = wb.SheetNames[0];
                                const ws = wb.Sheets[wsname];
                                const excelData_ = utils.sheet_to_json(ws, { header: 1 });
                                let totalColumnIndex_ = 0;
                                let isColumnFound_ = false;
                                let agingDetailTotal_ = 0;
                                excelData_.forEach(rowData_ => {
                                    if (rowData_.length > 2) {
                                        rowData_.forEach((coloumnData_, i) => {
                                            if (coloumnData_ == "Amount") {
                                                totalColumnIndex_ = i;
                                                isColumnFound_ = true;
                                            }

                                        })
                                    }
                                    if (isColumnFound_ && rowData_.length >= totalColumnIndex_) {
                                        if (rowData_[0] == "TOTAL") {
                                            agingDetailTotal_ += rowData_[totalColumnIndex_];
                                            agingDetails_.totalAmt = agingDetailTotal_;
                                            agingDetailsReportList_.push(agingDetails_);
                                            return agingDetails_;
                                        }
                                    }
                                })
                            }
                        }
                    }
                }));

            }
            if (agingDetailsReportList_.length > 0) {
                this.setState({ agingReportReceivableDocuments: agingDetailsReportList_ }, () => {
                    this.calculateCollateralAmt();
                });
            }
        }
    }

    readCSVFileWithType = async (filecontent_, mimeType_, reportType_) => {
        return await new Promise(async (resolve, reject) => {
            if (filecontent_ && mimeType_ == "CSV" && reportType_ == "IN") {
                let inventoryTotal_=0;
                let calculateInventoryTotal_=0;
                parse(filecontent_, (err, data_) => {
                    if (data_ && data_.length > 0) {
                        data_.forEach(element => {
                            if (element[0] == "TOTAL") {
                                inventoryTotal_ = (element[3].length > 0) ? parseInt(element[3]) : 0;
                                resolve(inventoryTotal_);
                            }
                            else{
                                calculateInventoryTotal_=(element[3].length > 0) ? parseInt(element[3]) : 0;
                            }
                        });
                    }
                    if(calculateInventoryTotal_>0){
                        resolve(calculateInventoryTotal_);
                    }
                })
            }
            if (filecontent_ && mimeType_ == "CSV" && reportType_ == "AG") {
                parse(filecontent_, (err, data_) => {
                    if (data_ && data_.length > 0) {
                        let agingDetailTotalAmt_ = 0;
                        data_.forEach(element => {
                            if (element[7].length > 0 && !(isNaN(parseInt(element[7])))) {
                                agingDetailTotalAmt_ += parseInt(element[7]);
                            }
                        });
                        resolve(agingDetailTotalAmt_);
                    }
                })
            }
        })
    }

    readFileAsync = async (file_, mimeType_) => {
        return await new Promise(async(resolve, reject) => {
            let reader = new FileReader();
            if (mimeType_ == "CSV") {
                 (reader.readAsText(file_));
            }
            else if (mimeType_ == "XLSX") {
                (reader.readAsArrayBuffer(file_));
            }
            reader.onload = (e) => {
                if(mimeType_=="CSV"){
                    resolve(reader.result);
                }
                else if(mimeType_=="XLSX"){
                    resolve(e.target.result);
                }
            };
            reader.onerror = reject;
        })
    }

    getReceivableDetailsAgingReport=async()=>{
        const receivableDetailsReport_=await this.api.getReceivablesDetailsAgingReport();
        const rows = [];
        let columns = [];
        let receivableAmt_=0;
        let count;
        if(receivableDetailsReport_.receivablesDetails && receivableDetailsReport_.receivablesDetails.length>0){
            let reportDate=`As on ${localDateFormat(receivableDetailsReport_.pulledDate)}`;
            columns.push(reportDate);
            rows.push(columns);
            rows.push([]);
            if(this.state.user.accountingInformation=="D365BC"){
                rows.push(["Customer ID", "Customer Name", "Due Days ", "Amount"])
            }
            else{
                rows.push(["Customer ID", "Customer Name", "Due Days ","Transaction Type", "Transaction ID", "Transaction Date", "Due Date", "Amount"])
            }
            columns=[];
            receivableDetailsReport_.receivablesDetails && receivableDetailsReport_.receivablesDetails.forEach(element => {
            columns = [];
            count=0;
            columns.push(element.customerId);
            columns.push('"'+element.customerName+'"');
            let isAlreadyInserted=false;
            if(element.currentDue>0 && element.currentDuerecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.currentDuerecords,element.currentDue,"CurrentDue");
            }
            if(element.due30Days && element.due30Daysrecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.due30Daysrecords,element.due30Days,"30 Days");
            }
            if(element.due45Days && element.due45Daysrecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.due45Daysrecords,element.due45Days,"45 Days");
            }
            if(element.due60Days && element.due60Daysrecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.due60Daysrecords,element.due60Days,"60 Days");
            }
            if(element.due90Days && element.due90Daysrecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.due90Daysrecords,element.due90Days,"90 Days");
            }
            if(element.due120Days && element.due120Daysrecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.due120Daysrecords, element.due120Days,"120 Days");
            }
            if(element.due180Days && element.due180Daysrecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.due180Daysrecords,element.due180Days,"180 Days");
            }
            if(element.due180pluseDays && element.due180pluseDaysrecords.length>0){
                if(!isAlreadyInserted){
                    isAlreadyInserted=true;
                }
                dueRecord(element.due180pluseDaysrecords,element.due180pluseDays,"180 >");
            }
            if(!isAlreadyInserted){
                if(element.currentDue && element.currentDue>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","CurrentDue",element.currentDue)
                    }else{
                        columns.push("CurrentDue",element.currentDue)
                    }
                    rows.push(columns);
                    count++;
                }
                if(element.due30Days && element.due30Days>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","30 Days",element.due30Days)
                    }else{
                        columns.push("30 Days",element.due30Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if(element.due45Daysrecords && element.due45Daysrecords>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","45 Days",element.due45Daysrecords)
                    }else{
                        columns.push("45 Days",element.due45Daysrecords)
                    }
                    rows.push(columns);
                    count++;
                }
                if(element.due60Days && element.due60Days>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","60 Days",element.due60Days)
                    }else{
                        columns.push("60 Days",element.due60Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if(element.due90Daysrecords && element.due90Daysrecords>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","90 Days",element.due90Daysrecords)
                    }else{
                        columns.push("90 Days",element.due90Daysrecords)
                    }
                    rows.push(columns);
                    count++;
                }
                if(element.due120Days && element.due120Days>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","120 Days",element.due120Days)
                    }else{
                        columns.push("120 Days",element.due120Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if(element.due180Days && element.due180Days>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","180 Days",element.due180Days)
                    }else{
                        columns.push("180 Days",element.due180Days)
                    }
                    rows.push(columns);
                    count++;
                }
                if(element.due180pluseDays && element.due180pluseDays>0){
                    if(count>0){
                        columns = [];
                        columns.push("","","180 >",element.due180pluseDays)
                    }else{
                        columns.push("180 >",element.due180pluseDays)
                    }
                    rows.push(columns);
                    count++;
                }
            }
           // rows.push(columns);
        });
        function dueRecord(records_,dueAmt_,dueType_){
            records_.forEach((d,i)=>{
                if(i>0 || count!=0){
                    columns=[];
                    columns.push("");
                    columns.push("");
                }
                columns.push(dueType_);
                // columns.push(dueAmt_);
                columns.push(d.TransactionType);
                columns.push(d.TransactionID);
                columns.push(d.TransactionDate);
                columns.push(d.DueDate);
                columns.push(d.Amount);
                rows.push(columns);
                receivableAmt_+=((d.Amount && d.Amount.length>0)?parseInt(d.Amount):0);
            });
            count++;
        };
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        let receivableDetailsAgingReport_={}
        if(this.state.user.accountingInformation=="quickbooks"){
            receivableDetailsAgingReport_.source="QBO";
        }
        else{
            receivableDetailsAgingReport_.source=this.state.accountingInformation;
        }
        receivableDetailsAgingReport_.filename=`Receivable_Details_Aging_Report.csv`;
        receivableDetailsAgingReport_.downloadUrl=encodedUri;
        receivableDetailsAgingReport_.fileId= Math.random().toString(36).slice(2);
        receivableDetailsAgingReport_.csvContent= csvContent.replace("data:text/csv;charset=utf-8,","");
        receivableDetailsAgingReport_.totalAmt=receivableAmt_;
        this.setState({agingReportReceivableDocuments:[receivableDetailsAgingReport_]},()=>{
           // this.calculateCollateralAmt();
            if(this.state.agingReportReceivableDocuments.length>0 && this.state.inventoryReport.length>0){
                const collateralAmount_=this.state.agingReportReceivableDocuments[0].totalAmt +this.state.inventoryReport[0].totalAmt;
                this.setState({
                    collateralAmount: collateralAmount_,
                    isSubmitbtnEnabled: true,
                    isAccountingSystemValue:true
                });
            }
        });
        }
    }

    calculateCollateralAmt = () => {
        const {
            inventoryReport,
            agingReportReceivableDocuments
        } = this.state;
        let collateralAmount_ = 0;
        if (inventoryReport.length > 0 && agingReportReceivableDocuments.length > 0) {
            inventoryReport.filter(invent_ => {
                collateralAmount_ += (invent_.totalAmt)?invent_.totalAmt:0;
            });
            agingReportReceivableDocuments.filter(aging_ => {
                collateralAmount_ += (aging_.totalAmt)?aging_.totalAmt:0;
            })
            this.setState({ collateralAmount: collateralAmount_ });
        }
    }
}