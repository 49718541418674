import React from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { dateFormatter } from '../../../services/Utilities';
import { withTranslation } from "react-i18next";
import * as locales from 'react-date-range/dist/locale';
import i18next from "i18next";
 class DataGridFilter extends React.Component {
    timeSelectorClicks = 0;

    constructor(props_) {
        super(props_);

        if (props_.type == "date_range") {
            this.state = {
                value: [{
                    startDate: null,
                    endDate: null,
                    key: "selection"
                }],
            }
        }
        else if (props_.type == "boolean") {
            this.state = {
                value: props_.value ? props_.value : false
            };
        }
        else {
            this.state = {
                value: props_.value ? props_.value : [],
                isCollapsed: true
            };
        }

        props_.change(this.state.value);
    }

    componentDidUpdate(oldProps_){
        if (oldProps_.value != this.props.value && this.props.value != this.state.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    render() {
        return (
            <span className="filter-control-group">
                <span className="filter-label">{this.props.label}: </span>
                <span className="filter-control">
                    {
                        this.props.type == "options" &&
                        <Autocomplete
                            id="tags-standard"
                            size="small"
                            options={this.props.options}
                            getOptionLabel={(option) => option.text}
                            value={this.state.value && this.state.value.length ? this.state.value[0] : null}
                            onChange={(event, newValue) => {
                                let value = newValue ? [newValue] : [];

                                this.setState({
                                    value: value
                                }, () => {
                                    this.props.change(this.state.value);
                                });
                            }}
                            onFocus={() => {
                                this.setState({
                                    isCollapsed: false
                                });
                            }}
                            onBlur={() => {
                                this.setState({
                                    isCollapsed: !(this.state.value && this.state.value.length != 0)
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    style={{ width: 200 }}
                                    {...params}
                                    variant="standard"
                                    placeholder={this.props.t("grid_view_filter_names.all")}
                                />
                            )}
                        />

                        // <select onChange={(ev_) => {
                        //     this.props.onChange(ev_.target.value);
                        // }}>
                        //     {this.props.options.map(o => <option value={o.value}>{o.text}</option>)}
                        // </select>
                    }
                    {
                        this.props.type == "date_range" &&
                        <span className="date-range-control-container">
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    this.setState({
                                        isOpen: !this.state.isOpen
                                    });
                                }}>
                                {this._getSelectedDateRangeText()}
                                <KeyboardArrowDownIcon />
                            </span>
                            {
                                this.state.isOpen &&
                                <React.Fragment>
                                    <div className="screen-blocker" onClick={() => {
                                        this.setState({
                                            isOpen: false
                                        }, () => {
                                            if (this.timeSelectorClicks != 0) {
                                                this.timeSelectorClicks = 0;
                                                this.props.change(this.state.value);
                                            }
                                        });
                                    }}></div>
                                    <DateRange
                                        locale={localStorage.getItem("i18nextLng")=="zh-CN"?locales["zhCN"]:locales["enUS"]}
                                        className="date-range-control"
                                        ranges={this.state.value}
                                        onChange={(range_) => {
                                            this.setState({
                                                value: [range_.selection]
                                            }, () => {
                                                if (++this.timeSelectorClicks % 2 == 0) {
                                                    this.timeSelectorClicks = 0;
                                                    this.setState({
                                                        isOpen: false
                                                    }, () => {
                                                        this.props.change(this.state.value);
                                                    });
                                                }
                                            });
                                        }}
                                    />
                                </React.Fragment>
                            }
                        </span>
                    }
                    {
                        this.props.type == "boolean" &&
                        <input type="checkbox"
                            checked={this.state.value}
                            onChange={(e) => {
                                this.setState({
                                    value: e.target.checked
                                }, () => {
                                    this.props.change(this.state.value);
                                });
                            }} />
                    }
                </span>
            </span>
        );
    }

    _getSelectedDateRangeText() {
        if (this.state.value && this.state.value.length) {
            if (this.state.value[0].startDate || this.state.value[0].endDate) {
                if (this.state.value[0].startDate == this.state.value[0].endDate) {
                    return dateFormatter.format(this.state.value[0].startDate);
                }
                else {
                    return `${dateFormatter.format(this.state.value[0].startDate)} - ${dateFormatter.format(this.state.value[0].endDate)}`;
                }
            }
        }

        return i18next.t("grid_view_filter_names.all");
    }
}

export default withTranslation() (DataGridFilter);