import React, { Component } from "react";
import Popup from '../../../common/Auth/Popup';
import './POList.css';
import Dropzone, { useDropzone } from "react-dropzone";
import User from "../../../../services/User";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import API,{API_ENDPOINT_SUPPLIER} from "../../../../services/API";
import Chance from "chance";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ReactTable from "react-table";
import { ShortText,validateEmail,localDateFormat} from '../../../../services/Utilities';
import PdfPaser from "../../../../services/PdfPaser";
import {parse} from "../../../../../node_modules/csv/dist/esm/index.js";
import Samplepo from "./samplepurchaseorder.csv";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import DatePicker from 'react-date-picker';
import moment from "moment";
import FullSpinner from "../../../common/Spinner/FullSpinner";
import PdfParserConfig from "../../../../configs/PdfParserConfig";
import FileViewerModal from "../../../common/FileViewerModal/FileViewerModal";
import { extractPdfFile,convertToJSONPoBuyerData,extractEmailAddresses } from "../../../../services/azureFormRecognizerService";
import CommonPopUpModal from "../../../CommonPopUpModal/CommonPopUpModal.js";
import FileUploader from "../../../common/FileUploader/FileUploader.js";
import fileUploadConfig from "../../../../configs/FileUploader";

const {
    supportedFileTypes,
    placeholders,
    duplicateFileMessage
} = fileUploadConfig;

const getFileConfig = (type) => {
    const fileTypeConfig = supportedFileTypes[type] || {};
    return {
        supportedExt: fileTypeConfig.extensions || [],
        errorMsgType: fileTypeConfig.message || '',
        placeholder: placeholders[type] || '',
        errorMsgDuplicate: duplicateFileMessage || ''
    };
};

const chance = new Chance();
const CheckboxTable = checkboxHOC(ReactTable);

const numberMask = createNumberMask({
    prefix: "$",
    suffix: "",
    allowDecimal:true
});

const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
})

export default class BuyerPOUploadModal extends React.Component {
    user = new User();
    api = new API();

    constructor(props_){
        super(props_);
        this.state={
            uploadFileName: this.props.t("transactions_page.po_content.upload_dropbox_msg"),
            purchaseOrderDocuments:this.props.data && this.props.data.documentFiles && this.props.data.documentFiles.length>0?this.props.data.documentFiles:[],
            poLists: [],
            data:[],
            columns: null,
            selection: [],
            selectAll: false,
            isValidEmail:true,
            isValidPoNumber:true,
            isValidPoAmt:true,
            isValidPoDate:true,
            isValidSupplierName:true,
            isSubmitEnabled:false,
            isLoading:false,
            isFileViewerModal:false,
            isFinancialDocument:false,
            fileViewerContent:null,
            isPdfParsing:false
        }
        this.state.invoiceConfig = getFileConfig('invoice');
        this.state.poConfig = getFileConfig('po');
        this.state.addDocConfig = getFileConfig('additionalDocx');
    }

    componentDidMount(){
        this.loadPOs();
    }

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original._id);
            });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        key = key.substring(7);
        let selection = [...this.state.selection];
        var keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection });
    };





    render() {

        const { toggleSelection, toggleAll, isSelected, logSelection } = this;
        const { data, selectAll} = this.state;
        const {isReadOnly}=this.props
        let checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox"
        };
        const columns = [
            {
                Header: "PO Number",
                accessor: "poNumber"
            },
            {
                Header: "PO Date",
                accessor: "poDate",
                Cell: props => <div>{localDateFormat(props.value)}</div>
            },
            {
                Header: "Supplier Name",
                accessor: "supplierName"
            },
            {
                Header: "PO Amount",
                accessor: "poAmount",
                Cell: props => <div className='text-right'>{dollarFormatter.format(props.value)}</div>
            },
        ];

        const { invoiceConfig, poConfig, addDocConfig } = this.state;
        return (
            <React.Fragment>
                {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                    onClose={() => {
                        this.setState({ isFileViewerModal: false, fileViewerContent: null });
                    }}
                    data={this.state.fileViewerContent}
                    isFinancialDocument={false}
                ></FileViewerModal>}

                    <FullSpinner isSpinning={this.state.isLoading}></FullSpinner>
                    <FullSpinner isSpinning={this.state.isPdfParsing} />
                    {
                        
                        (this.state.validationError && this.state.validationError.length) &&
                        <CommonConfirmationModal
                            title={this.props.t("transactions_page.po_content.invalid_inputs")}
                            noCancel={true}
                            onClose={() => { this.setState({ validationError: null }) }}
                        >
                                {this.state.validationError.length == 1 ? (<>
                                    {this.state.validationError[0]}</>) : (
                                    <>
                                        <ul>
                                            {this.state.validationError.map(t => <li>{t}</li>)}
                                        </ul>
                                    </>
                                )}

                        </CommonConfirmationModal>
                }
                <div>
                    <CommonPopUpModal
                        size='medium'
                        title={this.props.t("transactions_page.po_content.buyer_po_upload")}
                        onClose={e => this.props.onClose()}
                        primaryBtn={!this.props.isReadOnly ? "Upload PO(s)" : false}
                        isSubmitBtn={!this.state.isSubmitEnabled}
                        promptOnClose={this.state.isSubmitEnabled}
                        onSubmit={() => {
                            let purchaseOrder = this.state.data[0]["poNumber"];
                            let purchaseAmount = this.state.data[0]["poAmount"];
                            let errorMessage = [];
                            const duplicatePo = this.state.poLists.filter(po => po.documentId == purchaseOrder);
                            if (duplicatePo.length > 0) {
                                const msg_ = `Purchase order ${duplicatePo[0].documentId} ${this.props.t("transactions_page.po_content.is_already_uploaded")}`;
                                errorMessage.push(msg_);
                            }
                            if (!purchaseOrder) {
                                errorMessage.push(this.props.t("transactions_page.po_content.po_number_invalid"));
                            }
                            if (!purchaseAmount) {
                                errorMessage.push(this.props.t("transactions_page.po_content.po_amount_invalid"));
                            }
                            if (errorMessage.length > 0) {
                                this.setState({
                                    validationError: errorMessage
                                });
                            }
                            else {
                                let formData = new FormData();

                                for (let po_ of this.state.data) {
                                    formData.append("purchaseOrder[]", JSON.stringify(po_));
                                }
                                for (let file of this.state.purchaseOrderDocuments) {
                                    formData.append("PUR_DOC", file, file.name);
                                }
                                this.props.onSubmit(formData);
                            }
                        }}
                    >
                        <FileUploader
                            onFileExtracted={this.handleInvoiceLoaded}
                            onLoadingError={this.handleLoadingError}
                            onView={this.handleView}
                            convertToJson="poBuyer"
                            fileToExtract={this.state.purchaseOrderDocuments}
                            supportedExt={invoiceConfig.supportedExt}
                            //errorMsgType={this.props.t("file_upload_msg.only_pdf_file_msg")}
                            placeholder={this.props.t("file_upload_msg.po_placeholder")}
                            isdisabled={this.props.isReadOnly}
                            isMultiple={false}
                            isExtraction={true}
                        />
                        <div className="mt-4">
                            {this.state.data && this.state.data.length > 1 && (
                                <>
                                    <div className="row react-table-style">
                                        <CheckboxTable
                                            ref={r => (this.checkboxTable = r)}
                                            data={data}
                                            columns={columns}
                                            defaultPageSize={5}
                                            className="col-12 -striped -highlight"
                                            {...checkboxProps}
                                        />
                                        <div className="col-12">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const filteredSelection = [];
                                                    let formData = new FormData();
                                                    for (let po_ of this.state.data) {
                                                        formData.append("purchaseOrder", JSON.stringify(po_));
                                                    }
                                                    for (let file of this.state.purchaseOrderDocuments) {
                                                        formData.append("PUR_DOC", file, file.name);
                                                    }
                                                    this.props.onSubmit(formData);
                                                }}
                                                className="btn btn-outline-secondary btn-sm import-invoice-btn"
                                                disabled={this.state.selection.length == 0}
                                            >
                                                {this.props.t("transactions_page.po_content.upload_btn")}
                                            </button>
                                        </div>
                                    </div>

                                </>
                            )}
                            {(this.state.data.length == 1 || this.props.isReadOnly) && (
                                <>
                                    <div className="d-flex mt-2">
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("po_label.po_number")}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="poNumber"
                                                value={this.props.isReadOnly ? this.props.data.documentId : data.length > 0 && data[0].poNumber ? data[0].poNumber : ""}
                                                onChange={this.handleInputChange}
                                                disabled={this.state.purchaseOrderDocuments.length == 0 || this.props.isReadOnly}
                                            />
                                            {!this.state.isValidPoNumber && (
                                                <>
                                                    <div className="formErrors">
                                                        {data.length > 0 && data[0].poNumber && data[0].poNumber.length > 25 ? "PO Number should be less than 25 characters" : "PO Number is required"}

                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("po_label.po_amount")}</label>
                                            <MaskedInput
                                                name="poAmount"
                                                value={this.props.isReadOnly ? this.props.data.total : data.length > 0 && data[0].poAmount ? data[0].poAmount : ""}
                                                onChange={this.handleInputChange}
                                                mask={numberMask}
                                                className="form-control"
                                                placeholder="$ ..."
                                                disabled={this.state.purchaseOrderDocuments.length == 0 || this.props.isReadOnly}
                                            />
                                            {!this.state.isValidPoAmt && (
                                                <div className="formErrors">
                                                    {data.length > 0 && data[0].poAmount && data[0].poAmount > 1000000000 ? "PO Amount should be less than 15 characters" : "PO Amount is required"}

                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("po_label.po_date")}</label>
                                            <DatePicker
                                                className={["form-control"]}
                                                format="MM/dd/y"
                                                disabled={this.state.purchaseOrderDocuments.length == 0 || this.props.isReadOnly}
                                                value={this.props.isReadOnly ? localDateFormat(this.props.data.documentDate) : data.length > 0 && data[0].poDate ? new Date(data[0].poDate) : ""}
                                                onChange={(e) => {
                                                    const { data } = this.state;
                                                    if (data.length > 0) {
                                                        if (e) {
                                                            data[0].poDate = localDateFormat(e);
                                                        }
                                                        else {
                                                            data[0].poDate = null;
                                                        }
                                                        this.setState({ data }, () => {
                                                            this.validateAllFields();
                                                        });
                                                    }
                                                }}

                                            />
                                            {!this.state.isValidPoDate && (
                                                <div className="formErrors">
                                                    PO Date is invalid
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex mt-2 ">
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("grid_view_header_labels.supplier_name")}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="supplierName"
                                                value={this.props.isReadOnly ? this.props.data.purchaseOrder.supplierName : data.length > 0 && data[0].supplierName ? data[0].supplierName : ""}
                                                onChange={this.handleInputChange}
                                                disabled={this.state.purchaseOrderDocuments.length == 0 || this.props.isReadOnly}
                                            />
                                            {!this.state.isValidSupplierName && (
                                                <div className="formErrors">
                                                    {data.length > 0 && data[0].supplierName && data[0].supplierName.length > 50 ? "Supplier Name should be less than 50 characters" : " Supplier Name is required"}

                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group col-4">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("grid_view_header_labels.supplier_email_address")}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="supplierEmail"
                                                value={this.props.isReadOnly ? this.props.data.purchaseOrder.supplierEmail : data.length > 0 && data[0].supplierEmail ? data[0].supplierEmail : ""}
                                                onChange={this.handleInputChange}
                                                disabled={this.state.purchaseOrderDocuments.length == 0 || this.props.isReadOnly}
                                            />
                                            {!this.state.isValidEmail && (
                                                <div className="formErrors">
                                                    {data.length > 0 && data[0].supplierEmail && data[0].supplierEmail.length > 0 ? "Email address is invalid." : "Email address is required."}

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </CommonPopUpModal>
                </div>
            </React.Fragment>
        );
    }

    loadPOs() {
        this.api.docGetPO().then(invoices => {
            this.setState({ poLists: invoices });
        });
    }


    convertToJSONInvoice(columns, data) {
        let poObj = {};
        for (let i in columns) {
            poObj[columns[i]] = data[i];
        }
        let supplierDetails = {};
        supplierDetails["name"] = poObj["Supplier Name"];
        supplierDetails["email"] = poObj["Supplier Email"];
        supplierDetails["address1"] = poObj["Supplier Address1"];
        supplierDetails["city"] = poObj["Supplier City"];
        supplierDetails["region"] = poObj["Supplier State"];
        supplierDetails["postalCode"] = poObj["Supplier Zip"];
        supplierDetails["country"] = poObj["Supplier Country"];
        supplierDetails["phone"] = poObj["Supplier Phone"];
        poObj["supplierDetails"] = supplierDetails;
        return poObj;
    }

    handleInputChange=(e)=>{
        e.preventDefault();
        const{data,poDetails}=this.state;
        if (e.target.name == "poAmount") {
            data[0][e.target.name] = Number(
                e.target.value.replace(/[^0-9.-]+/g, "")
            )
        }else{
            data[0][e.target.name]=e.target.value;
            data[0]["supplierDetails"].name= (e.target.name =="supplierName")?e.target.value:data[0]["supplierDetails"].name
        }
        this.setState({data},()=>{
            this.validateAllFields();
        });
    }

    validateAllFields=()=>{
        const {data}=this.state;
        if(data.length>0){
            let poDetails_=data[0];
            let isValidEmail_=(data[0].supplierEmail).match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            let _poNumber=poDetails_.poNumber?poDetails_.poNumber.toString():"";
            let _supplierCompanyName=poDetails_.supplierName?poDetails_.supplierName.toString():"";
            const isValidPoDate_=moment(poDetails_.poDate).isValid();
            const isValidPoNumber=_poNumber.length>0 && _poNumber.length <25;
            const isValidSupplierName=_supplierCompanyName.length>0 &&_supplierCompanyName.length < 50;
            const isValidPoAmount=poDetails_.poAmount>0 && poDetails_.poAmount < 1000000000;
            if(isValidPoNumber && isValidPoAmount && isValidPoDate_ &&  isValidSupplierName && isValidEmail_){
                this.setState({isSubmitEnabled:true,
                    isValidEmail:true,
                    isValidSupplierName:true,
                    isValidPoNumber:true,
                    isValidPoAmt:true,
                    isValidPoDate:true});
            }
            else{
                this.setState({
                    isSubmitEnabled:false,
                    isValidEmail:isValidEmail_?true:false,
                    isValidSupplierName:isValidSupplierName,
                    isValidPoNumber:isValidPoNumber,
                    isValidPoAmt:isValidPoAmount,
                    isValidPoDate:isValidPoDate_
                })
            }
        }
    }

    handleInvoiceLoaded = (documentData) => {
        this.setState({
            purchaseOrderDocuments: documentData.invoiceFile,
            fileToUpload: documentData.invoiceFile,
            data: documentData.data,
            uploadFileName: documentData.uploadFileName,
            isPdfParsing: documentData.isPdfParsing,
        }, () => {
            if (documentData.data !== "") {
                this.validateAllFields();
            }
        }
        );
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    handleLoadingError = (errorData) => {
        console.log(errorData)
    };
}