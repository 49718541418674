
import React, { Component } from 'react';
import { API_ENDPOINT_SUPPLIER } from '../../services/API';
import User from '../../services/User';
import API from '../../services/API';
import FinancialInformation from '../common/Profile/FinancialInformation';
import CommonPopUpModal from '../CommonPopUpModal/CommonPopUpModal';

export default class FinancialInformationModal extends Component {
    user = new User();
    api = new API();
    constructor(props_) {
        super(props_);
        this.state = {

        };
    }

    componentDidMount() {
 
    }

    render() {
        return (
            <>
                <CommonPopUpModal
                    size='large'
                    title='Financial Information Details'
                    onClose={e => this.props.onClose()}
                    promptOnClose={false}
                >
                    <div className="modal-body w-100">
                        <FinancialInformation data={this.props.data} />
                    </div>
                </CommonPopUpModal>
            </>
        )
    }
}
