import React, { Component } from "react";
import API from "../../../services/API";
import User from "../../../services/User";
import QuestionnaireModal from "../Modals/QuestionnaireModal";
import FileViewerModal from "../FileViewerModal/FileViewerModal";
import { localDateFormat } from "../../../services/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faExclamationTriangle,
  faFileUpload
} from "@fortawesome/free-solid-svg-icons";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";
import FullSpinner from "../Spinner/FullSpinner";
import { enumRole,compareRole } from "../../../configs/GlobalConfig";
import { withTranslation } from "react-i18next";
import FileMetaDataPopover from "../PopOver/FileMetaDataPopover";

const numberMask = createNumberMask({
  prefix: "$",
  suffix: "",
  allowDecimal: true
});


 class FinancialInformation extends Component {
  user = new User();
  api = new API();
  constructor(props) {
    super(props);
    this.state = {
      isQuestionnaireModal: false,
      isFileViewerModal: false,
      receivablesAging: {},
      payablesAging: {},
      bankTransactionHistory: {},
      fileViewerContent: null,
      isLoading: false,
      financeDocs: {
        balanceSheet: {
          fileName: "",
          downloadUrl: ""
        },
        incomeStmt: {
          fileName: "",
          downloadUrl: ""
        },
        cashflow: {
          fileName: "",
          downloadUrl: ""
        },
        agingfile: {
          fileName: "",
          downloadUrl: ""
        },
        payableAgingFile: {
          fileName: "",
          downloadUrl: ""
        }
      },
      balanceStatement: {
        balanceStmt_1: {
          fileName: "",
          downloadUrl: ""
        },
        balanceStmt_2: {
          fileName: "",
          downloadUrl: ""
        },
        balanceStmt_3: {
          fileName: "",
          downloadUrl: ""
        }
      }
    }
  }

  componentDidMount() {
    this.getFinancialDocuments();
    if (this.props.data.accountingInformation) {

      if (this.props.data.accountingInformation == "quickbooks") {
        this.getReceivableAgingReport();
        this.getPayablesAgingReport();
        this.getSupplierbanktransactionhistory();
      }
      else if (this.props.data.accountingInformation == "D365BC") {
        this.getReceivableAgingReport();
        this.getPayablesAgingReport();
      }
      else if (this.props.data.accountingInformation == "erpinfo") {
        this.getReceivableAgingReport();
      }
    }
  }

  async getSupplierbanktransactionhistory() {
    this.setState({ isLoading: true });
    const res = await this.api.getsupbanktransactionhistory(this.props.data._id);
    this.setState({ bankTransactionHistory: res, isLoading: false });
  }

  getPayablesAgingReport = async () => {
    this.setState({ isLoading: true });
    const pDetailReport_ = await this.api.getPayablesAgingReport(this.props.data._id);

    if (pDetailReport_ && pDetailReport_.Payables && pDetailReport_.Payables.length > 0) {
      this.setState({ payablesAging: pDetailReport_, isLoading: false });
    }
  };

  getReceivableAgingReport = async () => {
    let receivablesAging = {};
    const { email } = this.props.data;
    this.setState({ isLoading: true });
    if (this.props.data.accountingInformation == "quickbooks") {
      const agingValue = await this.api.getAgingReportFromQBO(email);
      if (agingValue && !agingValue.msg) {
        receivablesAging = agingValue;
      }

    }
    else if (this.props.data.accountingInformation == "D365BC") {
      const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(email);
      if (agingValue_ && !agingValue_.msg) {
        if (agingValue_.invoices) {
          receivablesAging = agingValue_;
        } else {
          receivablesAging = {};
          receivablesAging.invoices = agingValue_;
        }
      }
    }
    else if (this.props.data.accountingInformation == "erpinfo") {
      const erpAggingReport = await this.api.getAgingReportFromERP(email);
      if (erpAggingReport && erpAggingReport.invoices) {
        receivablesAging = erpAggingReport;
      }
    }
    this.setState({ receivablesAging: receivablesAging, isLoading: false });
  }

  getFinancialDocuments = async () => {
    const res = await this.api.getFunderSupplierDocs(this.props.data._id);
    if (res.items && res.items.length > 0) {
      const { financeDocs, balanceStatement } = this.state;
      let items = res.items.filter(
        x => x.financialDocumentType === "BALANCE_SHEET"
      );
      if (items.length > 0) {
        financeDocs.balanceSheet.fileName = items[0].filename;
        financeDocs.balanceSheet.downloadUrl = items[0].downloadUrl;
        financeDocs.balanceSheet.uploadDate = items[0].createdDate;
        financeDocs.balanceSheet.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.balanceSheet.userDetails = items[0].userDetails;
        // this.setState({ fileToUpload: financeDocs.balanceSheet });
      }
      items = res.items.filter(
        x => x.financialDocumentType === "INCOME_STATEMENT"
      );
      if (items.length > 0) {
        financeDocs.incomeStmt.fileName = items[0].filename;
        financeDocs.incomeStmt.downloadUrl = items[0].downloadUrl;
        financeDocs.incomeStmt.uploadDate = items[0].createdDate;
        financeDocs.incomeStmt.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.incomeStmt.userDetails = items[0].userDetails;
      }
      items = res.items.filter(x => x.financialDocumentType === "CASH_FLOW");
      if (items.length > 0) {
        financeDocs.cashflow.fileName = items[0].filename;
        financeDocs.cashflow.downloadUrl = items[0].downloadUrl;
        financeDocs.cashflow.uploadDate = items[0].createdDate;
        financeDocs.cashflow.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.cashflow.userDetails = items[0].userDetails;

      }
      items = res.items.filter(x => x.financialDocumentType === "AGING_REPORT");
      if (items.length > 0) {
        financeDocs.agingfile.fileName = items[0].filename;
        financeDocs.agingfile.downloadUrl = items[0].downloadUrl;
        financeDocs.agingfile.uploadDate = items[0].createdDate;
        financeDocs.agingfile.pulledDate = items[0].pulledDate;
        financeDocs.agingfile.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.agingfile.userDetails = items[0].userDetails;
      }
      items = res.items.filter(x => x.financialDocumentType === "PAYABLE_AGING_REPORT");
      if (items.length > 0) {
        financeDocs.payableAgingFile.fileName = items[0].filename;
        financeDocs.payableAgingFile.downloadUrl = items[0].downloadUrl;
        financeDocs.payableAgingFile.uploadDate = items[0].createdDate;
        financeDocs.payableAgingFile.pulledDate = items[0].pulledDate;
        financeDocs.payableAgingFile.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.payableAgingFile.userDetails = items[0].userDetails;
      }
      this.setState({ financeDocs });
      items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "bankstatement");
      this.setState({ allBankStatements: items });
      items = res.items.filter(x => x.financialDocumentType === "BANK_STATEMENT_1");
      if (items.length > 0) {
        balanceStatement.balanceStmt_1.fileName = items[0].filename;
        balanceStatement.balanceStmt_1.downloadUrl = items[0].downloadUrl;
        balanceStatement.balanceStmt_1.uploadDate = items[0].createdDate;
        balanceStatement.balanceStmt_1.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        balanceStatement.balanceStmt_1.userDetails = items[0].userDetails;
      }
      items = res.items.filter(x => x.financialDocumentType === "BANK_STATEMENT_2");
      if (items.length > 0) {
        balanceStatement.balanceStmt_2.fileName = items[0].filename;
        balanceStatement.balanceStmt_2.downloadUrl = items[0].downloadUrl;
        balanceStatement.balanceStmt_2.uploadDate = items[0].createdDate;
        balanceStatement.balanceStmt_2.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        balanceStatement.balanceStmt_2.userDetails = items[0].userDetails;
      }
      items = res.items.filter(x => x.financialDocumentType === "BANK_STATEMENT_3");
      if (items.length > 0) {
        balanceStatement.balanceStmt_3.fileName = items[0].filename;
        balanceStatement.balanceStmt_3.downloadUrl = items[0].downloadUrl;
        balanceStatement.balanceStmt_3.uploadDate = items[0].createdDate;
        balanceStatement.balanceStmt_3.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        balanceStatement.balanceStmt_3.userDetails = items[0].userDetails;
      }
      this.setState({ balanceStatement: balanceStatement });
      items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
      this.setState({ allAdditionalDocuments: items });
    }
  }


  getTransactionHistoryDownload = async (e) => {
    e.preventDefault();
    const { bankTransactionHistory: { supplierBankTransactionHistory } } = this.state;
    let csvContent = "data:text/csv;charset=utf-8,";
    const table = {
      indent: 1,
      rows: []
    };
    const readbankTransactionValue = (rowData, indentation_, isGroup) => {
      for (let rowType of Object.keys(rowData)) {
        let row_;
        if (isGroup) {
          row_ = {
            indent: indentation_ + 1,
            columns: [],
          };
          table.indent = Math.max(table.indent, indentation_ + 1);
        }
        else {
          row_ = {
            indent: indentation_,
            columns: [],
          };
        }
        //table.rows.push(row_);
        row_.columns.push('"' + rowType + '"');
        table.rows.push(row_);
        if (rowData[rowType]) {
          if (typeof rowData[rowType] === 'object' && rowData[rowType] !== null) {
            Object.keys(rowData[rowType]).forEach((childRowType, i) => {
              if (rowData[rowType][childRowType].TransactionType) {
                let ele = rowData[rowType][childRowType];
                let row_ = {
                  columns: [],
                };
                //table.rows.push(row_);
                row_.columns.push(ele.TransactionType);
                row_.columns.push(ele.Date);
                row_.columns.push(ele.Description);
                row_.columns.push(ele.Amount);
                row_.columns.push(ele.Balance);
                table.rows.push(row_);
              }
              else {
                let newIndentation = indentation_;
                if (rowData[rowType][childRowType].length > 0 && Array.isArray(rowData[rowType][childRowType])) {
                  row_ = {
                    columns: [],
                    indent: indentation_ + 1,
                  };
                  table.indent = Math.max(table.indent, indentation_ + 1);
                  row_.columns.push('"' + childRowType + '"');
                  table.rows.push(row_);
                  rowData[rowType][childRowType].forEach(ele => {
                    let row_ = {
                      columns: [],
                    };
                    //table.rows.push(row_);
                    row_.columns.push(ele.TransactionType);
                    row_.columns.push(ele.Date);
                    row_.columns.push(ele.Description);
                    row_.columns.push(ele.Amount);
                    row_.columns.push(ele.Balance);
                    table.rows.push(row_);
                  });
                }
                else if (typeof rowData[rowType][childRowType] === 'object' && rowData[rowType][childRowType] !== null) {
                  ++newIndentation;
                  let row_ = {
                    indent: newIndentation,
                    columns: ['"' + childRowType + '"'],
                  };
                  table.rows.push(row_);

                  table.indent = Math.max(table.indent, newIndentation);
                  readbankTransactionValue(rowData[rowType][childRowType], newIndentation, true);
                }
              }
            });
          }
        }
      }
    };

    const readableJsonToCSV = (table_) => {
      let lastRow = null;
      console.log(table_);
      for (let row of table_.rows) {
        if (row.padding && !lastRow.padding) {
          csvContent += "\r\n";
        }

        for (let c = -table_.indent; c < row.columns.length; ++c) {
          let col;
          if (row.indent) {
            col = row.columns[c + (table_.indent - row.indent + 1)];
          } else {
            col = row.columns[c];
          }

          if (col) {
            csvContent += col;
          }

          if (c !== row.columns.length - 1) {
            csvContent += ",";
          }
        }

        if (row.padding) {
          csvContent += "\r\n";
        }

        csvContent += "\r\n";

        lastRow = row;
      }

      return csvContent;
    };
    readbankTransactionValue(supplierBankTransactionHistory, 1, false);
    if (table.rows.length > 0) {
      let headerRows_ = {
        columns: ["TransactionType", "Date", "Description", "Amount", "Balance"]
      };
      table.rows.unshift(headerRows_);
      readableJsonToCSV(table);
      console.log(csvContent);
      var encodedUri = encodeURI(csvContent);
      let _fileContent = {
        encodedUri: encodedUri,
        isCSVString: true,
        csvString: csvContent.replace("data:text/csv;charset=utf-8,", ""),
        csvData: table.rows,
        filename: "BankTransactionHistory.csv",
        isBankTransactionHistory: true
      }
      this.setState({
        isFinancialDocument: false,
        fileViewerContent: _fileContent,
        isFileViewerModal: true
      })
    }
  };

  getAggingReportDownload = async (e, type) => {
    const { user, receivablesAging, payablesAging } = this.state;
    let rows;
    let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (type == "payables") {
      rows = [
        ["Vendor Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
      ];
      reportGenerate(payablesAging.Payables, type);
    }
    if (type == "receivable") {
      rows = [
        ["Customer Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
      ];
      reportGenerate(receivablesAging.invoices, type);
    }
    function reportGenerate(aging, type) {
      aging.forEach(element => {
        let columns = [];
        if (type == "payables") {
          columns.push('"' + element.VendorName + '"');
        } else {
          columns.push('"' + element.customerName + '"');
        }
        columns.push(element.currentDue);
        columns.push(element.due30Days);
        columns.push(element.due45Days);
        columns.push(element.due60Days);
        columns.push(element.due90Days);
        columns.push(element.due120Days);
        columns.push(element.due180Days);
        columns.push(element.due180pluseDays);
        let totalDueValue = (element.currentDue ? element.currentDue : 0)
          + (element.due30Days ? element.due30Days : 0)
          + (element.due45Days ? element.due45Days : 0)
          + (element.due60Days ? element.due60Days : 0)
          + (element.due90Days ? element.due90Days : 0)
          + (element.due120Days ? element.due120Days : 0)
          + (element.due180Days ? element.due180Days : 0)
          + (element.due180pluseDays ? element.due180pluseDays : 0);
        columns.push(totalDueValue);
        [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue ? element.currentDue : 0),
        due30 + (element.due30Days ? element.due30Days : 0),
        due45 + (element.due45Days ? element.due45Days : 0),
        due60 + (element.due60Days ? element.due60Days : 0),
        due90 + (element.due90Days ? element.due90Days : 0),
        due120 + (element.due120Days ? element.due120Days : 0),
        due180 + (element.due180Days ? element.due180Days : 0),
        due180pluse + (element.due180pluseDays ? element.due180pluseDays : 0),
        totalDue + totalDueValue];
        rows.push(columns);
      });
    }
    let columns = [];
    columns.push("TOTAL");
    columns.push(currentDues);
    columns.push(due30);
    columns.push(due45);
    columns.push(due60);
    columns.push(due90);
    columns.push(due120);
    columns.push(due180);
    columns.push(due180pluse);
    columns.push(totalDue);
    rows.push(columns);
    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach((rowArray) => {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });
    let _csvContentString = csvContent;

    var encodedUri = encodeURI(csvContent);
    let _fileContent = {
      encodedUri: encodedUri,
      isCSVString: true,
      csvString: _csvContentString,
      csvData: rows,
      filename: type == "payables" ? "Payables_Aging_Report.csv" : "Receivable_Aging_Report.csv"
    }
    this.setState({
      isFinancialDocument: false,
      fileViewerContent: _fileContent,
      isFileViewerModal: true
    })
  }

  render() {
    const {
      financeDocs,
    } = this.state;
    const {
      country,
      taxId,
      revenue,
      questionnaires
    } = this.props.data;
    const {t} =this.props;
    return (<>
      <FullSpinner isSpinning={this.state.isLoading} />
      {this.state.isQuestionnaireModal && <QuestionnaireModal
        questions={questionnaires && questionnaires.length > 0 ? questionnaires : []}
        isCreate={questionnaires && questionnaires.length > 0 ? false : true}
        isDisableFields={true}
        isShowUploadButton={false}
        onClose={() => {
          this.setState({ isQuestionnaireModal: false });
        }}
      >
      </QuestionnaireModal>}
      {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
        onClose={() => {
          this.setState({ isFileViewerModal: false, fileViewerContent: null });
        }}
        data={this.state.fileViewerContent}
        isFinancialDocument={true}
      ></FileViewerModal>}
      <div className="row w-100">
        <div className="col-12 col-md-12 col-lg-6">
          <h4>{t("profile_page.financial_information_content.balance_sheet_title")}</h4>
          {financeDocs && financeDocs.balanceSheet.fileName !== "" ?
            <React.Fragment>
              <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                <div className="col-6">{t("common_names.document_label")}</div>
                <div className="col-3">{t("common_names.upload_date")}</div>
              </div>
              <div className="row pt-3 pb-4">
                <div className="col-6 d-flex">
                  <a className="anchorstyle mr-2"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        isFileViewerModal: true,
                        fileViewerContent: financeDocs.balanceSheet
                      })
                    }}
                    href={
                      ""
                    }
                    target="_blank"
                  >
                    {financeDocs.balanceSheet.fileName}
                  </a><FileMetaDataPopover data={financeDocs.balanceSheet} /></div>
                <div className="col-3 normal-text-color">{localDateFormat(financeDocs.balanceSheet.uploadDate)}</div>
              </div>

            </React.Fragment>
            :
            <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
          }

          <h4>{t("profile_page.financial_information_content.income_statement_title")}</h4>
          {financeDocs && financeDocs.incomeStmt.fileName !== "" ?
            <React.Fragment>
              <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                <div className="col-6">{t("common_names.document_label")}</div>
                <div className="col-3">{t("common_names.upload_date")}</div>
              </div>
              <div className="row pt-3 pb-4">
                <div className="col-6 d-flex">
                  <a className="anchorstyle mr-2" onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      isFileViewerModal: true,
                      fileViewerContent: financeDocs.incomeStmt
                    })
                  }}
                    href={
                      ""
                    }

                  >
                    {financeDocs.incomeStmt.fileName}
                  </a>
                  <FileMetaDataPopover data={financeDocs.incomeStmt} /></div>
                <div className="col-3 normal-text-color">{localDateFormat(financeDocs.incomeStmt.uploadDate)}</div>
              </div>

            </React.Fragment>
            :
            <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
          }
          <h4>{t("profile_page.financial_information_content.cash_flow_statement_title")}</h4>
          {financeDocs && financeDocs.cashflow.fileName !== "" ?
            <React.Fragment>
              <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                <div className="col-6">{t("common_names.document_label")}</div>
                <div className="col-3">{t("common_names.upload_date")}</div>
              </div>
              <div className="row pt-3 pb-4">
                <div className="col-6 d-flex">
                  <a className="anchorstyle mr-2"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        isFileViewerModal: true,
                        fileViewerContent: financeDocs.cashflow
                      })
                    }}
                    href={
                      ""
                    }
                    target="_blank"
                  >
                    {financeDocs.cashflow.fileName}
                  </a><FileMetaDataPopover data={financeDocs.cashflow} /></div>
                <div className="col-3 normal-text-color">{localDateFormat(financeDocs.cashflow.uploadDate)}</div>
              </div>

            </React.Fragment>
            :
            <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
          }
          <h4 className="mt-5">{t("profile_page.financial_information_content.account_receivable_title")}</h4>
          {(financeDocs && financeDocs.agingfile.fileName !== "" || (this.state.receivablesAging && this.state.receivablesAging.invoices)) ?
            <React.Fragment>
              <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                <div className="col-6">{t("common_names.document_label")}</div>
                <div className="col-3">{t("common_names.report_label")}</div>
                <div className="col-3">{t("common_names.upload_date")}</div>
              </div>
              <div className="row pt-3 pb-4">
                {(this.state.receivablesAging && this.state.receivablesAging.invoices) ? (<React.Fragment>
                  <div className="col-6">
                    <a
                      download
                      className="anchorstyle"
                      onClick={(e) => this.getAggingReportDownload(e, "receivable")}
                    >{t("profile_page.financial_information_content.receivable_aging_label")}</a>
                  </div>
                  <div className="col-3 normal-text-color">{localDateFormat(this.state.receivablesAging.pulledDate ? this.state.receivablesAging.pulledDate : this.props.t("no_report_date"))}</div>
                  <div className="col-3 normal-text-color">{localDateFormat(this.state.receivablesAging.uploadedDate ? this.state.receivablesAging.uploadedDate : this.props.t("no_uploaded_date"))}</div>
                </React.Fragment>) :
                  (<React.Fragment>
                    <div className="col-6">
                      <a className="anchorstyle"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            isFileViewerModal: true,
                            fileViewerContent: financeDocs.agingfile
                          })
                        }}
                        href={
                          ""
                        }
                        target="_blank"
                      >
                        {financeDocs.agingfile.fileName}
                      </a></div>
                    <div className="col-3 normal-text-color">{financeDocs.agingfile.pulledDate ? localDateFormat(financeDocs.agingfile.pulledDate) : this.props.t("no_report_date")}</div>
                    <div className="col-3 normal-text-color">{financeDocs.agingfile.uploadDate ? localDateFormat(financeDocs.agingfile.uploadDate) : this.props.t("no_uploaded_date")}</div>
                  </React.Fragment>)}
              </div>

            </React.Fragment>
            :
            <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
          }
          <h4 className="mt-5">{t("profile_page.financial_information_content.account_payable_title")}</h4>
          {(financeDocs && financeDocs.payableAgingFile.fileName !== "" || (this.state.payablesAging && this.state.payablesAging.Payables)) ?
            <React.Fragment>
              <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                <div className="col-6">{t("common_names.document_label")}</div>
                <div className="col-3">{t("common_names.report_label")}</div>
                <div className="col-3">{t("common_names.upload_date")}</div>
              </div>
              <div className="row pt-3 pb-4">
                {(financeDocs && financeDocs.payableAgingFile.fileName !== "") ?
                  (<React.Fragment>
                    <div className="col-6">
                      <a className="anchorstyle"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            isFileViewerModal: true,
                            fileViewerContent: financeDocs.payableAgingFile
                          })
                        }}
                        href={
                          ""
                        }
                        target="_blank"
                      >
                        {financeDocs.payableAgingFile.fileName}
                      </a></div>
                    <div className="col-3 normal-text-color">{this.state.payablesAging.pulledDate ? localDateFormat(this.state.payablesAging.pulledDate) : this.props.t("no_report_date")}</div>
                    <div className="col-3 normal-text-color">{this.state.payablesAging.uploadedDate ? localDateFormat(this.state.payablesAging.uploadedDate) : this.props.t("no_uploaded_date")}</div>
                  </React.Fragment>) : (<React.Fragment>
                    <div className="col-6">
                      <a
                        download
                        className="anchorstyle"
                        onClick={(e) => this.getAggingReportDownload(e, "payables")}
                      >{t("profile_page.financial_information_content.payable_aging_label")}</a>
                    </div>
                    <div className="col-3 normal-text-color">{financeDocs.payableAgingFile.pulledDate ? localDateFormat(financeDocs.payableAgingFile.pulledDate) : this.props.t("no_report_date")}</div>
                    <div className="col-3 normal-text-color">{financeDocs.payableAgingFile.uploadDate ? localDateFormat(financeDocs.payableAgingFile.uploadDate) : this.props.t("no_uploaded_date")}</div>
                  </React.Fragment>)}
              </div>

            </React.Fragment>
            :
            <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
          }
          <h4 className="mt-5">{t("profile_page.financial_information_content.bank_statement_title")}</h4>
          {this.state.allBankStatements && this.state.allBankStatements.length > 0 || (this.state.bankTransactionHistory && this.state.bankTransactionHistory.supplierBankTransactionHistory) ?
            <React.Fragment>
              <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                <div className="col-6">{t("common_names.document_label")}</div>
                <div className="col-3">{t("common_names.upload_date")}</div>
              </div>
              {(this.state.bankTransactionHistory && this.state.bankTransactionHistory.supplierBankTransactionHistory) &&
                (<React.Fragment>
                  <div className="row pt-3">
                    <div className="col-6">
                      <a
                        download
                        className="anchorstyle"
                        onClick={(e) => this.getTransactionHistoryDownload(e)}
                      >
                        {t("profile_page.financial_information_content.bank_transaction_label")}
                      </a></div>
                    <div className="col-3 normal-text-color">{localDateFormat(this.state.bankTransactionHistory.retrievalTimeStamp)}</div>
                  </div>
                </React.Fragment>)}
              {(<React.Fragment>
                {this.state.allBankStatements && this.state.allBankStatements.length > 0 && this.state.allBankStatements.map(doc => (
                  <React.Fragment>
                    <div className="row pt-3">
                      <div className="col-6 d-flex">
                        <a className="anchorstyle mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              isFileViewerModal: true,
                              fileViewerContent: doc
                            })
                          }}
                          href=""
                          target="_blank"
                        >
                          {doc.filename}
                        </a><FileMetaDataPopover data={doc} /></div>
                      <div className="col-3 normal-text-color">{localDateFormat(doc.createdDate)}</div>
                    </div>
                  </React.Fragment>
                ))}
              </React.Fragment>)}

            </React.Fragment>
            :
            <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
          }
        </div>
        <div className="flex-column col-12 col-md-12 col-lg-6">
          <div className="col-12 col-md-12 col-lg-9 mb-4">
            <h4 className="mb-3">{t("profile_page.financial_information_content.financial_information_title")}</h4>
            <form
            >

              <label className="form-label-style" htmlFor="tax">{t("profile_page.financial_information_content.tax_id_label")}</label>
              {country && (country == "United States" || country == "United States of America") ? (<>
                <MaskedInput
                  className="form-control mb-4"
                  name="taxId"
                  id="taxId"
                  value={taxId}
                  disabled
                  mask={[
                    /[1-9]/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                />
              </>) : (<>
                <input
                  type="text"
                  className="form-control mb-4"
                  name="taxId"
                  id="taxId"
                  value={taxId ? taxId.replace(/[^0-9aA-zZ.]/g, "") : taxId}
                  maxLength={15}
                  disabled
                />
              </>)}
              <label className="form-label-style" htmlFor="tax">{t("profile_page.financial_information_content.annual_sales_label")}</label>
              <MaskedInput
                name="revenue"
                id="revenue"
                value={revenue}
                mask={numberMask}
                className="form-control mb-4"
                disabled
              />
            </form>
          </div>
          
          {/* {this.props.data.role && !compareRole(this.props.data.role,enumRole.Funder) && (
            <div className="col-12 col-md-12 col-lg-9 mb-4">
              <h4 className="mb-3">Questionnaire<span style={{ color: "red" }}> *</span></h4>
              <button className="btn btn-primary mt-2" type="submit" onClick={(e) => {
                this.setState({ isQuestionnaireModal: true });
              }}>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faSave}
                />
                View Questionnaire
              </button>

            </div>
          )} */}
          <div className="col-12 col-md-12">
            <h4 className="mt-5">{t("profile_page.financial_information_content.additional_document_title")}</h4>
            {(this.state.allAdditionalDocuments && this.state.allAdditionalDocuments.length > 0) ?
              <React.Fragment>
                <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                  <div className="col-6">{t("common_names.document_label")}</div>
                  <div className="col-3">{t("common_names.upload_date")}</div>
                </div>
                {this.state.allAdditionalDocuments && this.state.allAdditionalDocuments.length > 0 && this.state.allAdditionalDocuments.map(doc => (
                  <React.Fragment>
                    <div className="row pt-3">
                      <div className="col-6 d-flex">
                        <a className="anchorstyle mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              isFileViewerModal: true,
                              fileViewerContent: doc
                            })
                          }}
                          href={""}
                          target="_blank"
                        >
                          {doc.filename}
                        </a><FileMetaDataPopover data={doc} /></div>
                      <div className="col-3 normal-text-color">{localDateFormat(doc.createdDate)}</div>
                    </div>
                  </React.Fragment>
                ))}

              </React.Fragment>
              :
              <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
            }
          </div>
        </div>
      </div>
    </>)
  }
}

export default withTranslation() (FinancialInformation);