import React, { Component } from 'react'
import Popup from '../../../common/Auth/Popup';
import Dropzone, { useDropzone } from "react-dropzone";
import { API_ENDPOINT_SUPPLIER } from "../../../../services/API";
import User from "../../../../services/User";
import CommonConfirmationModal from "../../../common/Modals/CommonConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt} from "@fortawesome/free-solid-svg-icons";
const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
})

export default class CreateFundRequestTCFModal extends Component {
    user = new User();

    constructor(props_){
        super(props_);

        this.state = {
            taxAmount: (props_.data && props_.data.total) ? props_.data.total : "",
            preFudingAmount:"",
            financialDocuments:props_.data ? props_.data.tcf.findoc : [],
            taxDocuments:props_.data ? props_.data.documentFiles : [],
        }
    }

    render() {
        return (<Popup className={"tcf-popup"}
            content={<>
                <div className="popup">
                    {
                        (this.state.validationError && this.state.validationError.length) &&
                        <CommonConfirmationModal
                            title="Invalid Inputs"
                            onClose={() => { this.setState({ validationError: null }) }}
                        >
                            <ul>
                                {this.state.validationError.map(t => <li>{t}</li>)}
                            </ul>
                        </CommonConfirmationModal>
                    }
                    <div>
                        <label className="popup-close-icon" onClick={e => this.props.onClose()}></label>
                        <h2>Create Funding Request</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            Please be sure to upload final tax documents in order to create the funding request.
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="taxamount">Total tax credit</label>
                                <input name="taxamount" className="form-control" type="text" style={{ textAlign: "right" }}
                                    placeholder="$0.00"
                                    value={this.state.taxAmount}
                                    onChange={(ev_) => {
                                        let prefundingAmt = this._formatDollars(parseFloat(ev_.target.value.toString().replace(/\$|,/g, '')) * 2 / 3);
                                        this.setState({
                                            taxAmount: this._formatDollars(ev_.target.value),
                                            preFudingAmount: prefundingAmt
                                        });
                                    }}
                                ></input>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label for="prefundingamount">Pre-funding available</label>
                                <input name="preFudingAmount" className="form-control" readOnly style={{ textAlign: "right" }}
                                    placeholder="$0.00" value={this.state.preFudingAmount}
                                ></input>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 dropbox-container">
                            <label>Financial Statements</label>
                            <Dropzone
                                onDrop={doc =>
                                    this.onDropFinancialDoc(
                                        "Financial_Documents",
                                        doc
                                    )
                                }
                            >
                                {this.state.financialDocuments.length
                                    ? <div>
                                        {
                                            this.getDropBoxContent(this.state.financialDocuments, "Financial_Documents")
                                        }
                                    </div>
                                    : null
                                }
                            </Dropzone>

                        </div>
                        <div className="col-md-6 dropbox-container">
                            <label>Tax Documents</label>
                            <Dropzone
                                onDrop={doc =>
                                    this.onDropFinancialDoc(
                                        "Tax_Document",
                                        doc
                                    )
                                }
                            >
                                {this.state.taxDocuments.length
                                    ? <div>
                                        {
                                            this.getDropBoxContent(this.state.taxDocuments, "Tax_Document")
                                        }
                                    </div>
                                    : null
                                }
                            </Dropzone>
                        </div>
                    </div>
                    {
                        <div className="row">
                            <div className="col-md-9"></div>
                            <div className="col-md-3">
                                <button
                                    className="btn btn-primary btn-block mt-1"
                                    type="submit"
                                    //disabled={(!this.state.taxAmount)}
                                    onClick={() => {
                                        let taxAmount = this.state.taxAmount.replace(/[^0-9.]/g, "");
                                        let preFundingAmt = this.state.preFudingAmount.replace(/[^0-9.]/g, "");
                                        let errorMessage = [];

                                        if (!taxAmount) {
                                            errorMessage.push("Provide valid total tax credit amount");
                                        }

                                        if (errorMessage.length > 0) {
                                            this.setState({
                                                validationError: errorMessage
                                            });
                                        }
                                        else {
                                            let formData = new FormData();
                                            formData.append("_id",this.props.data._id);
                                            formData.append("taxAmount", taxAmount);
                                            formData.append("preFudingAmount", preFundingAmt);

                                            for (let file of this.state.financialDocuments) {
                                                if (file.name) {
                                                    formData.append("FIN_DOC", file, file.name);
                                                }
                                            }
                                            for (let file of this.state.taxDocuments) {
                                                if (file.name) {
                                                    formData.append("TAX_DOC", file, file.name);
                                                }
                                            }
                                            this.props.onSubmit(formData);
                                        }
                                    }}
                                >Submit</button>
                            </div>
                        </div>
                    }
                </div>
            </>}
            handleClose={e => this.props.onClose()}
        />);
    }
    
    _formatDollars(text_) {
        if (text_ != "") {
            text_ = text_.toString();
            text_ = text_.replace(/[^0-9.]/g, "");
            let value = parseFloat(text_);
            if (!isNaN(value)) {
                return dollarFormatter.format(value);
            }
        }

        return "";
    }

    getDropBoxContent(files, type) {
        return files.map(d => {
            return <div key={
                d.filename ? d.filename : d.name
            }>{
                    d.filename ? <a href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${d._id}`} target="_blank" download={d.filename}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}>{d.filename}</a>
                        : <React.Fragment>
                            {d.name} <span
                                style={{ float: "right", marginRight: "25px" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    switch (type) {
                                        case "Financial_Documents":
                                            let financialdocs_ = this.state.financialDocuments.filter((doc) => {
                                                return doc.id != d.id
                                            });
                                            this.setState({ financialDocuments: financialdocs_ })
                                            break;
                                        case "Tax_Document":
                                            let taxdocs_ = this.state.taxDocuments.filter((doc) => {
                                                return doc.id != d.id
                                            });
                                            this.setState({ taxDocuments: taxdocs_ })
                                            break;
                                    }
                                }}>
                                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                            </span>
                        </React.Fragment>
                }
            </div>
        });
    }

    onDropFinancialDoc(type, documents) {
        let existingDocuments = [];
        let errorMessage = [];
        documents.forEach(element => {
            element.id = Math.random().toString(36).slice(2);
        });
        if (this.state.financialDocuments.length > 0 || this.state.taxDocuments.length > 0) {
            for (let existingDoc_ of (type == "Financial_Documents" ? this.state.financialDocuments : this.state.taxDocuments)) {
                for (let document_ of documents) {
                    if (document_.name == (existingDoc_.name ? existingDoc_.name : existingDoc_.filename)) {
                        errorMessage.push("The following document is already imported");
                        this.setState({
                            validationError: errorMessage
                        });
                        return;
                    }
                }
            }
        }
        switch (type) {
            case "Financial_Documents":
                existingDocuments = this.state.financialDocuments;
                existingDocuments = existingDocuments.concat(documents);
                this.setState({ financialDocuments: existingDocuments });
                break;
            case "Tax_Document":
                existingDocuments = this.state.taxDocuments;
                existingDocuments = existingDocuments.concat(documents);
                this.setState({ taxDocuments: existingDocuments });
                break;
        }
    }
}
