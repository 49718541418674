import React, { Component } from 'react';
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { TextField } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import DatePicker from 'react-date-picker';
import API from '../../../services/API';
import { Calendar } from 'react-date-range';
import { localDateFormat } from '../../../services/Utilities';
const numberMask = createNumberMask({
    prefix: "$",
    suffix: "",
    allowDecimal: true
});


export default class QuestionnaireModal extends Component {
    api = new API();
    constructor(props_) {
        super(props_);
        this.state = {
            questionnaire: this.props.questions && this.props.questions.length > 0 ? this.props.questions[0].questionnaire : {
                estimatedFinancingVolume: "",
                funds: "",
                frequency: "",
                otherfrequency: "",
                invoiceAndPaymentProcess: "",
                isExistingLoan: "",
                existingLoanDetails: {
                    nameOfCommercialFinacne: "",
                    existingLoanAmount: "",
                    maturity: ""
                },
                ownerShipDetails:[
                    {
                        ownerShipName:"",
                        ownerShipPercentage:"",
                        governmentId:""
                    },
                    {
                        ownerShipName:"",
                        ownerShipPercentage:"",
                        governmentId:""
                    },
                    {
                        ownerShipName:"",
                        ownerShipPercentage:"",
                        governmentId:""
                    }
                ],
                isBankruptcy: "",
                bankruptcyDetails: "",
                isLegcalClaims: "",
                legcalClaimsDetails: "",
                isOweTaxes: "",
                oweTaxesDetails: "",
                bankName: "",
                bankAddress: "",
                bankAccountNumber: "",
                routingNo: "",
                swiftCodeNo: ""

            },
            isSaveQuestionnaire:false
        };
    }

    componentDidMount() {

    }

    questionnarieHandleChange = (event) => {
        const { questionnaire } = this.state;
        const name = event.target.name;
        const value = event.target.value;
        questionnaire[name] = value;
        const isOther = questionnaire["frequency"] == "other";
        questionnaire.funds=questionnaire.funds.trimStart();
        questionnaire.invoiceAndPaymentProcess=questionnaire.invoiceAndPaymentProcess.trimStart();
        //questionnaire.funds=questionnaire.funds.replace(/\s/g, '');
        //questionnaire.invoiceAndPaymentProcess=questionnaire.invoiceAndPaymentProcess.replace(/\s/g, '');
        if(questionnaire.isBankruptcy=="no"){
            questionnaire.bankruptcyDetails="";
        }
        if(questionnaire.isLegcalClaims=="no"){
            questionnaire.legcalClaimsDetails="";
        }
        if(questionnaire.isOweTaxes=="no"){
            questionnaire.oweTaxesDetails="";
        }
        this.setState({ questionnaire: questionnaire,
            isSaveQuestionnaire: questionnaire.estimatedFinancingVolume.length > 0 && questionnaire.frequency.length > 0 && questionnaire.funds.length > 0 && questionnaire.invoiceAndPaymentProcess.length > 0 && (!isOther || questionnaire.otherfrequency.length > 0) });
    }
    existingLoanHandleChange=(event)=>{
        const { questionnaire } = this.state;
        const name = event.target.name;
        const value = event.target.value;
        if(name=="isExistingLoan"){
            questionnaire.isExistingLoan=value;
            if(questionnaire.isExistingLoan=="no"){
                questionnaire.existingLoanDetails= {
                    nameOfCommercialFinacne: "",
                    existingLoanAmount: "",
                    maturity: ""
                }
            }
        }
        else{
            questionnaire.existingLoanDetails[name]=value;
        }
        const isOther = questionnaire["frequency"] == "other";
        this.setState({ questionnaire: questionnaire,
            isSaveQuestionnaire: questionnaire.estimatedFinancingVolume.length > 0 && questionnaire.frequency.length > 0 && questionnaire.funds.length > 0 && questionnaire.invoiceAndPaymentProcess.length > 0 && (!isOther || questionnaire.otherfrequency.length > 0) });
    }

    ownershipHandleChange=(event,i)=>{

        const {questionnaire}=this.state;
        const name = event.target.name;
        const value = event.target.value;
        questionnaire.ownerShipDetails[i][name]=value;
        const isOther = questionnaire["frequency"] == "other";
        this.setState({questionnaire:questionnaire,
            isSaveQuestionnaire: questionnaire.estimatedFinancingVolume.length > 0 && questionnaire.frequency.length > 0 && questionnaire.funds.length > 0 && questionnaire.invoiceAndPaymentProcess.length > 0 && (!isOther || questionnaire.otherfrequency.length > 0) });
    }

    maturityDateChange=(event)=>{
        const {questionnaire}=this.state;
        let questionnaireDetails_=questionnaire
        if(event){
            questionnaireDetails_.existingLoanDetails["maturity"] =localDateFormat(event.toUTCString());
        }
        else{
           delete questionnaireDetails_.existingLoanDetails.maturity;
           questionnaireDetails_.existingLoanDetails.maturity="";
        }
        const isOther = questionnaire["frequency"] == "other";
        this.setState({questionnaire:questionnaireDetails_,
            isSaveQuestionnaire: questionnaire.estimatedFinancingVolume.length > 0 && questionnaire.frequency.length > 0 && questionnaire.funds.length > 0 && questionnaire.invoiceAndPaymentProcess.length > 0 && (!isOther || questionnaire.otherfrequency.length > 0) });
    }

    render() {
        return (
            <>
                <div className="modal fade show modal-large" id="questionnaireModal" tabIndex="-1" role="dialog" aria-labelledby="invoiceUploadModal" aria-hidden="true" style={{ "display": "block" }}>
                    <div className="modal-dialog modal-xl p-2" role="document">
                        <div className="modal-content">
                            <div className="modal-header pb-0">
                                <h2>{this.props.isShowUploadButton?"Add Questionnaire":"View Questionnaire"} </h2>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ cursor: "pointer" }} onClick={() => {
                                    this.props.onClose();
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body flex-column"  style={{ height: "75vh",overflowY:"auto"}}>
                                <div className='d-flex'>
                                    <div className='form-group w-50'>
                                        <label className="form-label-style" htmlFor="tax">Estimated financing volume<span style={{ color: "red" }}> *</span></label>
                                        <MaskedInput
                                            name="estimatedFinancingVolume"
                                            value={this.state.questionnaire.estimatedFinancingVolume}
                                            onChange={this.questionnarieHandleChange}
                                            mask={numberMask}
                                            className="form-control form-control-border-botttom"
                                            placeholder="$ ..."
                                            disabled={this.props.isDisableFields}
                                            required
                                        />
                                    </div>
                                    <div className='pl-4 form-group'>
                                        <label className="form-label-style" htmlFor="tax">Frequency<span style={{ color: "red" }}> *</span></label>
                                        <div className='d-flex'>
                                            <label>
                                                <input type='radio'
                                                    name="frequency"
                                                    value="One Time"
                                                    checked={this.state.questionnaire.frequency == "One Time"}
                                                    disabled={this.props.isDisableFields}
                                                    onChange={this.questionnarieHandleChange} /> One Time</label>
                                            <label style={{ paddingLeft: 10 }}>
                                                <input type='radio'
                                                    name="frequency"
                                                    value="Monthly"
                                                    checked={this.state.questionnaire.frequency == "Monthly"}
                                                    disabled={this.props.isDisableFields}
                                                    onChange={this.questionnarieHandleChange} /> Monthly</label>
                                            <label style={{ paddingLeft: 10 }}>
                                                <input type='radio'
                                                    name="frequency"
                                                    value="Quarterly"
                                                    checked={this.state.questionnaire.frequency == "Quarterly"}
                                                    disabled={this.props.isDisableFields}
                                                    onChange={this.questionnarieHandleChange} /> Quarterly</label>
                                            <label style={{ paddingLeft: 10 }}>
                                                <input type='radio'
                                                    name="frequency"
                                                    value="other"
                                                    checked={this.state.questionnaire.frequency == "other"}
                                                    disabled={this.props.isDisableFields}
                                                    onChange={this.questionnarieHandleChange} /> Other</label>
                                        </div>
                                    </div>
                                </div>
                                {this.state.questionnaire.frequency == "other" && (
                                    <>
                                        <label className="form-label-style" htmlFor="name">Other frequency</label>
                                        <input
                                            type="text"
                                            className='form-control form-control-border-botttom '
                                            name="otherfrequency"
                                            onChange={this.questionnarieHandleChange}
                                            variant="standard"
                                            placeholder="Bi-weekly ..."
                                            value={this.state.questionnaire.otherfrequency}
                                            disabled={this.props.isDisableFields}
                                        />
                                    </>
                                )}
                                <div className="form-group">
                                    <label className="form-label-style" htmlFor="name">Use of funds<span  style={{color:"red"}}> *</span></label>
                                    <textarea
                                        type="text"
                                        name="funds"
                                        className="form-control form-control-border-botttom"
                                        value={this.state.questionnaire.funds}
                                        onChange={this.questionnarieHandleChange}
                                        placeholder="The funds requested are used for ..."
                                        disabled={this.props.isDisableFields}
                                        required
                                    ></textarea>
                                </div>
                                <div className="form-group">
                                    <label className="form-label-style" htmlFor="name">Describe your invoice and payment processes<span  style={{color:"red"}}> *</span></label>
                                    <textarea
                                        type="text"
                                        className="form-control form-control-border-botttom"
                                        name="invoiceAndPaymentProcess"
                                        onChange={this.questionnarieHandleChange}
                                        value={this.state.questionnaire.invoiceAndPaymentProcess}
                                        disabled={this.props.isDisableFields}
                                        placeholder="Describe your invoice and payment processes"
                                        required
                                    ></textarea>
                                </div>
                                <div className="form-group">
                                    <label className="form-label-style" htmlFor="name">Does the company have any existing loan, line of credit or factoring arrangement ?</label>
                                    <div className='d-flex'>
                                        <label>
                                            <input type='radio'
                                                name="isExistingLoan"
                                                value="yes"
                                                checked={this.state.questionnaire.isExistingLoan == "yes"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.existingLoanHandleChange} /> Yes</label>
                                        <label style={{ paddingLeft: 10 }}>
                                            <input type='radio'
                                                name="isExistingLoan"
                                                value="no"
                                                checked={this.state.questionnaire.isExistingLoan == "no"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.existingLoanHandleChange} /> No</label>
                                    </div>
                                </div>

                                <div className="pl-5 input-group mb-3">
                                    <label className="form-label-style pr-3" htmlFor="name">If yes, Name of commercial finance company or institution</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-border-botttom"
                                        name="nameOfCommercialFinacne"
                                        disabled={(this.state.questionnaire.isExistingLoan == "no" || this.state.questionnaire.isExistingLoan == "" ||  this.props.isDisableFields)}
                                        value={this.state.questionnaire.existingLoanDetails.nameOfCommercialFinacne}
                                        onChange={this.existingLoanHandleChange}
                                    />
                                </div>
                                <div className="pl-5 input-group">
                                    <label className="form-label-style pr-3" htmlFor="name">Amount</label>
                                    <MaskedInput
                                        name="existingLoanAmount"
                                        disabled={(this.state.questionnaire.isExistingLoan == "no" || this.state.questionnaire.isExistingLoan == "" || this.props.isDisableFields)}
                                        value={this.state.questionnaire.existingLoanDetails.existingLoanAmount}
                                        onChange={this.existingLoanHandleChange}
                                        mask={numberMask}
                                        className="form-control form-control-border-botttom"
                                        placeholder="$ ..."
                                    />
                                    <label className="form-label-style pr-3" htmlFor="name">Maturity date</label>
                                    {/* <Calendar
                                        date={new Date()}
                                        //onChange={this.handleSelect}
                                    /> */}
                                    <DatePicker
                                        disabled={(this.state.questionnaire.isExistingLoan == "no" || this.state.questionnaire.isExistingLoan == "" || this.props.isDisableFields)}
                                        value={this.state.questionnaire.existingLoanDetails.maturity.length>0?new Date(this.state.questionnaire.existingLoanDetails.maturity):""}
                                        onChange={this.maturityDateChange}
                                        format="MM/dd/y"
                                        // clearIcon={null}
                                        className={["form-control form-control-border-botttom "]}
                                    />
                                </div>
                                <hr />
                                <p className='my-3 ' style={{ fontFamily: "Roboto", fontSize: 16, color: "#575757", marginBottom: 0 }}>Ownership / Management</p>
                                {this.state.questionnaire.ownerShipDetails.map((owner_,i) => {
                                   return <div className="input-group" key={i}>
                                        <label className="form-label-style pr-3" htmlFor="name">Name</label>
                                        <input
                                            name="ownerShipName"
                                            value={owner_.ownerShipName}
                                            onChange={(e)=>this.ownershipHandleChange(e,i)}
                                            disabled={this.props.isDisableFields}
                                            className="form-control form-control-border-botttom  pr-3"
                                        />
                                        <label className="form-label-style  px-3" htmlFor="name">% Ownership</label>
                                        <input
                                            name="ownerShipPercentage"
                                            value={owner_.ownerShipPercentage}
                                            onChange={(e)=>this.ownershipHandleChange(e,i)}
                                            disabled={this.props.isDisableFields}
                                            className="form-control form-control-border-botttom  pr-3"
                                        />
                                        <label className="form-label-style  px-3" htmlFor="name">Government ID</label>
                                        <input
                                            name="governmentId"
                                            value={owner_.governmentId}
                                            onChange={(e)=>this.ownershipHandleChange(e,i)}
                                            disabled={this.props.isDisableFields}
                                            className="form-control form-control-border-botttom"
                                        />
                                    </div>
                                })}
                                <hr/>
                                <p className='my-3' style={{ fontFamily: "Roboto", fontSize: 16, color: "#575757" ,marginBottom:0 }}>Have the owners/managers, or any business entity in which they have been associated with</p>
                                <div className='form-group'>
                                    <label className="form-label-style" htmlFor="name">ever filed for bankruptcy?</label>
                                    <div className='d-flex'>
                                        <label>
                                            <input type='radio'
                                                name="isBankruptcy"
                                                value="yes"
                                                checked={this.state.questionnaire.isBankruptcy == "yes"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.questionnarieHandleChange} /> Yes</label>
                                        <label style={{ paddingLeft: 10 }}>
                                            <input type='radio'
                                                name="isBankruptcy"
                                                value="no"
                                                checked={this.state.questionnaire.isBankruptcy == "no"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.questionnarieHandleChange} /> No</label>
                                    </div>
                                </div>
                                <div className="pl-5 input-group mb-3">
                                    <label className="form-label-style pr-3" htmlFor="name">if yes , Please explain</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-border-botttom"
                                        name="bankruptcyDetails"
                                        disabled={(this.state.questionnaire.isBankruptcy == "no" || this.state.questionnaire.isBankruptcy == "" || this.props.isDisableFields)}
                                        value={this.state.questionnaire.bankruptcyDetails}
                                        onChange={this.questionnarieHandleChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label className="form-label-style" htmlFor="name">owe any taxes that are past due?</label>
                                    <div className='d-flex'>
                                        <label>
                                            <input type='radio'
                                                name="isOweTaxes"
                                                value="yes"
                                                checked={this.state.questionnaire.isOweTaxes == "yes"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.questionnarieHandleChange} /> Yes</label>
                                        <label style={{ paddingLeft: 10 }}>
                                            <input type='radio'
                                                name="isOweTaxes"
                                                value="no"
                                                checked={this.state.questionnaire.isOweTaxes == "no"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.questionnarieHandleChange} /> No</label>
                                    </div>
                                </div>
                                <div className="pl-5 input-group mb-3">
                                    <label className="form-label-style pr-3" htmlFor="name">if yes , Please explain</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-border-botttom"
                                        name="oweTaxesDetails"
                                        disabled={(this.state.questionnaire.isOweTaxes == "no" || this.state.questionnaire.isOweTaxes == "" || this.props.isDisableFields)}
                                        value={this.state.questionnaire.oweTaxesDetails}
                                        onChange={this.questionnarieHandleChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label className="form-label-style" htmlFor="name">any legal claims, lawsuits or judgements pending?</label>
                                    <div className='d-flex'>
                                        <label>
                                            <input type='radio'
                                                name="isLegcalClaims"
                                                value="yes"
                                                checked={this.state.questionnaire.isLegcalClaims == "yes"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.questionnarieHandleChange} /> Yes</label>
                                        <label style={{ paddingLeft: 10 }}>
                                            <input type='radio'
                                                name="isLegcalClaims"
                                                value="no"
                                                checked={this.state.questionnaire.isLegcalClaims == "no"}
                                                disabled={this.props.isDisableFields}
                                                onChange={this.questionnarieHandleChange} /> No</label>
                                    </div>
                                </div>
                                <div className="pl-5 input-group mb-3">
                                    <label className="form-label-style pr-3" htmlFor="name">if yes , Please explain</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-border-botttom"
                                        name="legcalClaimsDetails"
                                        disabled={(this.state.questionnaire.isLegcalClaims == "no" || this.state.questionnaire.isLegcalClaims == "" || this.props.isDisableFields)}
                                        value={this.state.questionnaire.legcalClaimsDetails}
                                        onChange={this.questionnarieHandleChange}
                                    />
                                </div>
                                <hr />
                                <p className='my-3' style={{ fontFamily: "Roboto", fontSize: 16, color: "#575757" ,marginBottom:0 }}>Bank Information</p>
                                <div className='row'>
                                    <div className='col-2'>
                                        <label className="form-label-style" htmlFor="name">Bank Name</label>
                                    </div>
                                    <div className='col-7'>
                                        <input
                                        type="text"
                                            name="bankName"
                                            onChange={this.questionnarieHandleChange}
                                            value={this.state.questionnaire.bankName}
                                            className="form-control form-control-border-botttom"
                                            disabled={this.props.isDisableFields}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <label className="form-label-style" htmlFor="name">Bank Address</label>
                                    </div>
                                    <div className='col-7'>
                                        <input
                                        type="text"
                                            name="bankAddress"
                                            onChange={this.questionnarieHandleChange}
                                            value={this.state.questionnaire.bankAddress}
                                            disabled={this.props.isDisableFields}
                                            className="form-control form-control-border-botttom"
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <label className="form-label-style" htmlFor="name">SWIFT Code No.</label>
                                    </div>
                                    <div className='col-7'>
                                        <input
                                        type="text"
                                            name="swiftCodeNo"
                                            onChange={this.questionnarieHandleChange}
                                            value={this.state.questionnaire.swiftCodeNo}
                                            className="form-control form-control-border-botttom"
                                            disabled={this.props.isDisableFields}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <label className="form-label-style" htmlFor="name">ABA/Routing No.</label>
                                    </div>
                                    <div className='col-7'>
                                        <input
                                        type="text"
                                            name="routingNo"
                                            onChange={this.questionnarieHandleChange}
                                            value={this.state.questionnaire.routingNo}
                                            className="form-control form-control-border-botttom"
                                            disabled={this.props.isDisableFields}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'>
                                        <label className="form-label-style" htmlFor="name">Account No.</label>
                                    </div>
                                    <div className='col-7'>
                                        <input
                                            type="text"
                                            name="bankAccountNumber"
                                            onChange={this.questionnarieHandleChange}
                                            value={this.state.questionnaire.bankAccountNumber}
                                            className="form-control form-control-border-botttom"
                                            disabled={this.props.isDisableFields}
                                        />
                                    </div>
                                </div>
                                {this.props.isShowUploadButton && (
                                    <div style={{ textAlign: "center" }}>
                                        <button
                                            className="btn btn-primary mt-3"
                                            style={{ width: 205 }}
                                            disabled={!this.state.isSaveQuestionnaire}
                                            onClick={async () => {
                                                await this.api.saveAndUpdateQuestionnaire(this.state.questionnaire, this.props.isCreate);
                                                this.props.onClose();
                                            }}
                                        >Save Questionnaire</button>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>
        )
    }
}


function AmountMaskedInput(props) {
    const { inputRef, ...other } = props;
  
    return (
        <MaskedInput
        {...other}
        ref={inputRef}
        name="estimatedFinancingVolume"
        mask={numberMask}
      
      />
    );
}