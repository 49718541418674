import React, { Component } from 'react'

import API from '../../../../services/API';
import AppState from '../../../../contexts/AppState/AppState';
import User from '../../../../services/User';
import DataGrid from '../../../common/DataGrid/DataGrid';
import DataGridFilter from '../../../common/DataGrid/DataGridFilter';
import FullSpinner from '../../../common/Spinner/FullSpinner';
import { dollarFormatter,localDateFormat, } from '../../../../services/Utilities';
import  ABLUploadModal  from './ABLUploadModal';
import CommonCreateFundingRequestModal from '../../../common/Modals/CommonCreateFundingRequestModal';
import { NavLink} from "react-router-dom";
import { AddModal } from '../../../common/Modals/CommonConfirmationModal';


export default class AssetBasedLendingList extends Component {
    user = new User();

    api = new API();

    _columnsSpecification = [
        {
            key: "documentId",
            name: "Asset #"
        },
        {
            key: "total",
            name: "Collateral Amount",
            textAlignment:"right",
            contentProvider: (datum_) => {
                return <div style={{color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>
            }
        },
        {
            key: "documentDate",
            name: "Asset Created Date",
            contentProvider: (datum_) => {
                return localDateFormat(datum_.documentDate);
            }
        },
        {
            key: "status",
            name: "Status",
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div className="awaiting-text-color" style={{fontWeight:"600"}}>Submitted</div>;
                    case 99:
                        return <div className="progress-text-color">In Funding</div>;
                    case 100:
                        return <div className="archived-text-color">Archived</div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
        {
            key: "action",
            name: "Type/Request",
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>Open</div>;
                    case 99:
                    case 100:
                        return <div><NavLink className="anchorstyle" style={{ color: "#5F9FBC" }} to="/fundingrequest">{datum_.fundingReqId}</NavLink></div>;
                    default:
                        return "Unknown";
                }
            },
            eventType: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return "open";
                    case 99:
                    case 100:
                        return "";
                    default:
                        return "";
                }
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isUploadPopupOpen: props.isUpload,
            isLoading: false,
            isCFRModalOpen:false,
            loadingText: "",
            isAllCheckboxEnabled:false,
            allAbl:[],
            selectedItems:[],
            assetBasedLendingList:[],
            filtersApplied: {},
            isResetFilterEnabled:false,
            isSubmitABL:false
        }

    }
    componentDidMount() {
        this.loadABL();
    }

    componentDidUpdate(prevProps_, prevState) {
        if (prevProps_.isUpload !== this.props.isUpload) {
            if(this.props.isUpload){
                this.checkABL();
            }
            else{
                this.setState({ isUploadPopupOpen: this.props.isUpload });
            }
        }
        if (prevProps_.isCreateFundingRequest != this.props.isCreateFundingRequest) {
            this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
        }
    }

    async loadABL() {
        const ablResponse= await this.api.docGetABL();
        this.setAssetBasedLending(ablResponse);
    }
    async checkABL(){
        const ablResponse=await this.api.docGetABL();
        if(ablResponse && ablResponse.length>0){
            let isUpload_=true;
            ablResponse.forEach(abl_ => {
                if(abl_.status<=99){
                    isUpload_=false;
                }
            });
            if(isUpload_){
                this.setState({ isUploadPopupOpen: this.props.isUpload });
            }
            else {
                this.props.upload();
                await AddModal('Incomplete Asset-Based Lending', <div>
                    There is already Asset-Based lending is in progress, complete the process and then initiate new Asset-Based Lending</div>, null, false)
            }
        }
        else{
            this.setState({ isUploadPopupOpen: this.props.isUpload });
        }
    }

    

    render() {
        const {
            isLoading,
            allAbl,
            selectedItems,
            isUploadPopupOpen,
            isCFRModalOpen,
            isSubmitABL,
            assetBasedLendingList
        }=this.state;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <FullSpinner
                            isSpinning={isLoading}
                            confirmationLabel=""
                        />
                        <FullSpinner
                            isSpinning={isLoading? false : isSubmitABL}
                            confirmationLabel="Asset-Based Lending Uploaded"
                        />
                        {(isUploadPopupOpen || isCFRModalOpen) && (
                            <>
                                {isUploadPopupOpen && (
                                    <ABLUploadModal
                                        onClose={() => {
                                            this.setState({ isUploadPopupOpen: false });
                                            this.props.upload();
                                        }}
                                        onSubmit={(formData) => {
                                            this.setState({ isSubmitABL: true });
                                            this.api.docSubmitABL(formData).then(r => {
                                                if (r.error && r.message == "ALREADY_SUBMITTED") {
                                                    this.setState({
                                                        isUploadPopupOpen: false,
                                                        isSubmitABL: false
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        isUploadPopupOpen: false,
                                                        isSubmitABL: false
                                                    }, () => {
                                                        this.loadABL();
                                                    });
                                                }
                                                this.props.upload();
                                            });
                                        }}
                                    />
                                )}

                                {isCFRModalOpen && selectedItems && <CommonCreateFundingRequestModal
                                    data={selectedItems}
                                    onClose={() => {
                                        this.loadABL();
                                        this.setState({ isCFRModalOpen: false, selectedItems: null });
                                        this.props.createFundingRequest();
                                        this.props.hasCreateFundingRequest(0);
                                    }}
                                />}
                            </>

                        )}
                        <DataGrid
                            title="Asset-Based Lending(s)"
                            emptydataLabel="No Asset-Based Lending(s) Found"
                            data={assetBasedLendingList}
                            columns={this._columnsSpecification}
                            isNoPagination={true}
                            onSelectionChange={(items) => this._onSelectionChange(items)}
                            isRowsNonSelectable={false}
                            isGridSearch={true}
                            isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                            width="95"
                            selectedItems={selectedItems}
                            isNoMoreLink={true}
                            appliedFilter={this.state.filtersApplied}
                            resetFilters={() => this._resetFilter()}
                            searchText={(text_) => {

                            }}
                            handleTableCellClick={(dataRow, type, index) => {

                            }}
                            filterComponent={
                                <React.Fragment>
                                    <DataGridFilter
                                        label="Show Archived"
                                        type="boolean"
                                        value={this.state.filtersApplied.archived}
                                        change={(filter_) => {
                                            this.state.filtersApplied.archived = filter_;
                                            this.setState({
                                                filtersApplied: this.state.filtersApplied
                                            }, () => this.onFilterChange());
                                        }}
                                    />
                                    <DataGridFilter
                                        label="Status"
                                        type="options"
                                        options={
                                            [
                                                { value: 0, text: "Submitted" },
                                                { value: 99, text: "In Funding" },
                                                { value: 100, text: "Archived" }
                                            ]
                                        }
                                        value={this.state.filtersApplied.status}
                                        change={(filter_) => {
                                            this.state.filtersApplied.status = filter_;
                                            this.setState({
                                                filtersApplied: this.state.filtersApplied
                                            }, () => this.onFilterChange());
                                        }}
                                    />
                                </React.Fragment>
                            }
                            isResetFilterEnabled={this.state.isResetFilterEnabled}
                        />
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }

    
    _onSelectionChange(items) {
        this.setState({ selectedItems: items });
        this.props.hasCreateFundingRequest(items.length);
    }

    async setAssetBasedLending(ablResponse) {
        let isAllCheckbox = true;
        const assetBasedLending_ = ablResponse
            .map(inv => {
                let isRowSelect = true;
                if (inv.status == 0) {
                    isRowSelect = false;
                    isAllCheckbox = false;
                }
                inv.isRowSelect = isRowSelect;
                switch (inv.status) {
                    case 0:
                        inv.statusText = "Submitted";
                        break;
                    case 99:
                        inv.statusText = "In Funding";
                        break;
                    case 100:
                        inv.statusText = "Archived";
                        break;
                    default:
                        inv.statusText = "Unknown";
                }

                return inv;
            });
        this.setState({ allAbl: assetBasedLending_, isAllCheckboxEnabled: isAllCheckbox}, () => {
            this._resetFilter();
            this.onFilterChange();
        });
    }

    onFilterChange() {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        let filteredAbl = this.state.allAbl.filter(invoice_ => {
            if (!this.state.filtersApplied.archived) {
                if (invoice_.status == 100) {
                    return false;
                }
            }
            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (invoice_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                }
            }
            return true;
        });

        this.setState({
            assetBasedLendingList: filteredAbl
        },() => this._hasResetFilterEnabled());
    }

    _resetFilter = () => {

    }

    _hasResetFilterEnabled=()=>{

    }
}
