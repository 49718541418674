import React, { Component } from 'react';
import Popup from '../../common/Auth/Popup';
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import User from '../../../services/User';
import { dollarFormatter ,getIndustryWithId, getEntity } from '../../../services/Utilities';
import API from '../../../services/API';
import EnumFundingRequestStatus from './EnumFundingRequestStatus';
import SigningStatusModal from '../FundingContract/SigningStatusModal';
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import UserDetails from '../../common/User/UserDetails';
import UploadSupplyChainModal from '../Documents/SupplyChain/UploadSupplyChainModal';
import { enumRole,transactionType,compareTransactionType, compareRole } from '../../../configs/GlobalConfig';
import { convertUTF8String, getFileName, DownloadLink } from '../../../services/ConversionService';
 import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
 import InfoIcon from '@mui/icons-material/Info';
 import Tooltip from '@mui/material/Tooltip';
import FileMetaDataPopover from '../../common/PopOver/FileMetaDataPopover';


 var _salesContractLabel = "sales_contract";
var _shippingDocumentLabel= "shipping_documents";
var _paymentConfirmationLabel= "payment_confirmation";

export default class ViewSupplierFundingRequestModal extends Component {
    user = new User();
    viewData = {};
    api = new API();

    constructor(props_) {
        super(props_);
        this.state = {
            customerDocuments:{},
            contracts:[],
            signingContractValue:null,
            isFileViewerModal:false,
            fileViewerContent:null,
            isFinancialDocument:false,
            isViewSupplyChain:false,
            viewDocumentData:null,
            isTermSheetAvailable:false,
            preFundingDocs:{}
        }
    }
    componentDidMount(){
        let fundingRequest=this.props.data;
        if(this.props.data.isPreFundingRequest){
            this.getPreFundingDocs();
        }
        const customersDocs=fundingRequest.fundingDocuments.reduce((cus,docs)=>{
            let customerName=docs.customerDetails?docs.customerDetails.name:docs.buyerName?docs.buyerName:"";
            if(!cus[customerName]){
                cus[customerName]=[];
            }
            cus[customerName].push(docs);
            return cus;
        },{});

        let acceptedContract;
        if(fundingRequest.status>5){
            acceptedContract=fundingRequest.contracts.filter(contract=>{
                if(contract.acceptedFunder){
                    return contract;
                }
            })
        }
        else{
            acceptedContract=fundingRequest.contracts;
        }
        this.setState({customerDocuments:customersDocs,contracts:acceptedContract},()=>{
            this.getFinacialDocumentationForBuyer();
        });
    }

    getPreFundingDocs=async()=>{
        if(this.props && this.props.data){
            let res = await this.api.getSupplierCommonDocs(this.props.data.fundingReqId);
            if(res && res.items && res.items.length>0){
                let _docs0 = res.items.filter(x => x.financialDocumentType === "CONSOLIDATED_PRE_FUN_DOCUMENTS_ZIP");
                let _docs1= res.items.filter(x => x.financialDocumentType === "FINANCING_APPLICATION");
                let _docs2= res.items.filter(x => x.financialDocumentType === "LAST_TWO_YEAR_FINANCIAL_STATEMENTS");
                let _docs3= res.items.filter(x => x.financialDocumentType === "AGING_REPORTS");
                let _docs4= res.items.filter(x => x.financialDocumentType === "ARTICLE_OF_INCORPORATION");
                let _docs5= res.items.filter(x => x.financialDocumentType === "COPY_OF_PRINCIPAL_ID");
                let _docs6= res.items.filter(x => x.financialDocumentType === "PAYMENT_HISTORY");
                let _docs7= res.items.filter(x => x.financialDocumentType === "SALES_PROJECTION");
                let _docs8= res.items.filter(x => x.financialDocumentType === "OTHER_PRE_FUNDING_DOCUMENTS");
                this.setState({
                    preFundingDocs: {
                        "CONSOLIDATED_PRE_FUN_DOCUMENTS_ZIP": _docs0,
                        "FINANCING_APPLICATION": _docs1,
                        "LAST_TWO_YEAR_FINANCIAL_STATEMENTS": _docs2,
                        "AGING_REPORTS": _docs3,
                        "ARTICLE_OF_INCORPORATION": _docs4,
                        "COPY_OF_PRINCIPAL_ID": _docs5,
                        "PAYMENT_HISTORY": _docs6,
                        "SALES_PROJECTION": _docs7,
                        "OTHER_PRE_FUNDING_DOCUMENTS":_docs8
                    }
                });
            }
        }
    }

    async getFinacialDocumentationForBuyer() {
        let { customerDocuments } = this.state;
        let customerNames_ = Object.keys(customerDocuments);
        if (customerDocuments && Object.keys(customerDocuments).length > 0) {
            for (let cDocs_ of customerNames_) {
                if (customerDocuments[cDocs_][0].customerDetails) {
                    const res = await this.api.getFunderSupplierDocs(customerDocuments[cDocs_][0].customerDetails._id);
                    let isAging = true;
                    let isbankStmt = true;
                    let isPayableAging = true;
                    let aging = res.items.filter(
                        x => x.financialDocumentType === "AGING_REPORT"
                    );
                    if (aging.length == 0) {
                        isAging = false;
                    }
                    let payableAging = res.items.filter(
                        x => x.financialDocumentType === "PAYABLE_AGING_REPORT"
                    );
                    if (payableAging.length == 0) {
                        isPayableAging = false;
                    }
                    let bankStatement = res.items.filter(x => x.financialDocumentType == "BANK_STATEMENT_1");
                    if (bankStatement.length == 0) {
                        isbankStmt = false;
                    }
                    let financialAdditionalDocs = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
                    res.items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() != "additionaldocument");
                    customerDocuments[cDocs_][0].customerDetails.financialDocuments = res.items ? res.items : [];
                    customerDocuments[cDocs_][0].customerDetails.isAgingfile = isAging;
                    customerDocuments[cDocs_][0].customerDetails.additionalDocuments = financialAdditionalDocs && financialAdditionalDocs.length > 0 ? financialAdditionalDocs : [];
                    if (customerDocuments[cDocs_][0].customerDetails.accountingInformation && customerDocuments[cDocs_][0].customerDetails.accountingInformation == "quickbooks") {
                        if (!isbankStmt) {
                            const bankTransactionHistory_ = await this.api.getsupbanktransactionhistory(customerDocuments[cDocs_][0].customerDetails._id);
                            customerDocuments[cDocs_][0].customerDetails.bankStatement = bankTransactionHistory_;
                        }
                    }
                    if (customerDocuments[cDocs_][0].customerDetails.accountingInformation && customerDocuments[cDocs_][0].customerDetails.accountingInformation != "erpinfo") {
                        if (!isPayableAging) {
                            const pDetailReport_ = await this.api.getPayablesAgingReport(customerDocuments[cDocs_][0].customerDetails._id);
                            if (pDetailReport_ && pDetailReport_.Payables && pDetailReport_.Payables.length > 0) {
                                customerDocuments[cDocs_][0].customerDetails.payableAging = pDetailReport_;
                            }
                        }
                        const rDetailReport_ = await this.api.getReceivablesDetailsAgingReport(customerDocuments[cDocs_][0].customerDetails._id);
                        if (rDetailReport_ && rDetailReport_.receivablesDetails && rDetailReport_.receivablesDetails.length > 0) {
                            customerDocuments[cDocs_][0].customerDetails.receivableDetailsAging = rDetailReport_;
                        }

                        const pDetailReport_ = await this.api.getPayablesDetailsAgingReport(customerDocuments[cDocs_][0].customerDetails._id);
                        if (pDetailReport_ && pDetailReport_.payablesDetails && pDetailReport_.payablesDetails.length > 0) {
                            customerDocuments[cDocs_][0].customerDetails.payablesDetailsAging = pDetailReport_;
                        }
                    }
                }
            }

            this.setState({
                customerDocuments: customerDocuments
            });
        }

    }

    getAggingReportDownload=async (e,email,accountingType_)=>{
        e.preventDefault();
        this.setState({ agingReport: true });
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if(accountingType_=="quickbooks"){
            const agingValue = await this.api.getAgingReportFromQBO(email);
            if(agingValue && !agingValue.msg){
                agingResponse=agingValue;
            }
        }
        else if(accountingType_=="D365BC"){
            const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(email);
            if(agingValue_ && !agingValue_.msg){
                if(agingValue_.invoices){
                    agingResponse=agingValue_;
                }else{
                    agingResponse={};
                    agingResponse.invoices=agingValue_;
                }
            }
        }
        else{
            const erpAggingReport=await this.api.getAgingReportFromERP(email);
            if(erpAggingReport && erpAggingReport.invoices){
                agingResponse=erpAggingReport;
            }
        }
        if(agingResponse && agingResponse.invoices && agingResponse.invoices.length>0){
            const rows = [
                ["Customer Name", "Current Due", "Due 30 Days","Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days","Total"]
            ];
            agingResponse.invoices.forEach(element => {
                let columns = [];
                columns.push('"'+element.customerName+'"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue?element.currentDue:0)
                + (element.due30Days?element.due30Days:0)
                + (element.due45Days?element.due45Days:0)
                + (element.due60Days?element.due60Days:0)
                + (element.due90Days?element.due90Days:0)
                + (element.due120Days?element.due120Days:0)
                + (element.due180Days?element.due180Days:0)
                + (element.due180pluseDays?element.due180pluseDays:0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] =  [currentDues + (element.currentDue?element.currentDue:0),
                    due30 + (element.due30Days?element.due30Days:0),
                    due45 + (element.due45Days?element.due45Days:0),
                    due60 + (element.due60Days?element.due60Days:0),
                    due90 + (element.due90Days?element.due90Days:0),
                    due120 + (element.due120Days?element.due120Days:0),
                    due180 + (element.due180Days?element.due180Days:0),
                    due180pluse + (element.due180pluseDays?element.due180pluseDays:0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL")
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45)
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent={
                encodedUri:encodedUri,
                isCSVString:true,
                csvString:csvContent,
                csvData:rows,
                filename:"Aging_Report.csv"
              }
              this.setState({
                isFinancialDocument:false,
                fileViewerContent:_fileContent,
                isFileViewerModal:true
              })
            this.setState({ agingReport: false })
        }
        else{
            this.setState({agingReport:false});
        }
    }

    getPayableAgingReportDownload=async (e,data_,accountingType_)=>{
        e.preventDefault();
        this.setState({ agingReport: true });
        const {supplier} = this.props.data;
        const {payableAgingReport}=this.state;
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if(payableAgingReport && payableAgingReport.Payables && payableAgingReport.Payables.length>0){
            const rows = [
                ["Vendor Name", "Current Due", "Due 30 Days","Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days","Total"]
            ];
            payableAgingReport.Payables.forEach(element => {
                let columns = [];
                columns.push('"'+element.VendorName+'"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue?element.currentDue:0)
                + (element.due30Days?element.due30Days:0)
                + (element.due45Days?element.due45Days:0)
                + (element.due60Days?element.due60Days:0)
                + (element.due90Days?element.due90Days:0)
                + (element.due120Days?element.due120Days:0)
                + (element.due180Days?element.due180Days:0)
                + (element.due180pluseDays?element.due180pluseDays:0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue?element.currentDue:0),
                    due30 + (element.due30Days?element.due30Days:0),
                    due45 + (element.due45Days?element.due45Days:0),
                    due60 + (element.due60Days?element.due60Days:0),
                    due90 + (element.due90Days?element.due90Days:0),
                    due120 + (element.due120Days?element.due120Days:0),
                    due180 + (element.due180Days?element.due180Days:0),
                    due180pluse + (element.due180pluseDays?element.due180pluseDays:0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL")
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45)
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent={
                encodedUri:encodedUri,
                isCSVString:true,
                csvString:csvContent,
                csvData:rows,
                filename:"Payable_Aging_Report.csv"
              }
              this.setState({
                isFinancialDocument:false,
                fileViewerContent:_fileContent,
                isFileViewerModal:true
              })
            this.setState({ agingReport: false })
        }
        else{
            this.setState({agingReport:false});
        }
    }

    getTransactionHistoryDownload = (e,isBuyer_,bankData_) => {
        e.preventDefault();
        const { supplierBankTransactionHistory  } = bankData_;
        let csvContent = "data:text/csv;charset=utf-8,";
        const table = {
            indent: 1,
            rows: []
        }
        const readbankTransactionValue = (rowData, indentation_, isGroup) => {
            for (let rowType of Object.keys(rowData)) {
                let row_;
                if (isGroup) {
                    row_ = {
                        indent: indentation_ + 1,
                        columns: [],
                    };
                    table.indent = Math.max(table.indent, indentation_ + 1);
                }
                else {
                    row_ = {
                        indent: indentation_,
                        columns: [],
                    };
                }
                //table.rows.push(row_);
                row_.columns.push('"'+rowType+'"');
                table.rows.push(row_);
                if (rowData[rowType]) {
                    if (typeof rowData[rowType] === 'object' && rowData[rowType] !== null) {
                        Object.keys(rowData[rowType]).forEach((childRowType, i) => {
                            if (rowData[rowType][childRowType].TransactionType) {
                                let ele = rowData[rowType][childRowType];
                                let row_ = {
                                    columns: [],
                                };
                                //table.rows.push(row_);
                                row_.columns.push(ele.TransactionType);
                                row_.columns.push(ele.Date);
                                row_.columns.push(ele.Description);
                                row_.columns.push(ele.Amount);
                                row_.columns.push(ele.Balance);
                                table.rows.push(row_);
                            }
                            else {
                                let newIndentation = indentation_;
                                if (rowData[rowType][childRowType].length > 0 && Array.isArray(rowData[rowType][childRowType])) {
                                    row_ = {
                                        columns: [],
                                        indent: indentation_ + 1,
                                    };
                                    table.indent = Math.max(table.indent, indentation_ + 1);
                                    row_.columns.push('"'+childRowType+'"');
                                    table.rows.push(row_);
                                    rowData[rowType][childRowType].forEach(ele => {
                                        let row_ = {
                                            columns: [],
                                        };
                                        //table.rows.push(row_);
                                        row_.columns.push(ele.TransactionType);
                                        row_.columns.push(ele.Date);
                                        row_.columns.push(ele.Description);
                                        row_.columns.push(ele.Amount);
                                        row_.columns.push(ele.Balance);
                                        table.rows.push(row_);
                                    })
                                }
                                else if (typeof rowData[rowType][childRowType] === 'object' && rowData[rowType][childRowType] !== null) {
                                    ++newIndentation;
                                    let row_ = {
                                        indent: newIndentation,
                                        columns: ['"'+childRowType+'"'],
                                    };
                                    table.rows.push(row_);

                                    table.indent = Math.max(table.indent, newIndentation);
                                    readbankTransactionValue(rowData[rowType][childRowType], newIndentation, true);
                                }
                            }
                        })
                    }
                }
            }
        }

        const readableJsonToCSV = (table_) => {
            let lastRow = null;
            console.log(table_);
            for (let row of table_.rows) {
                if (row.padding && !lastRow.padding) {
                    csvContent += "\r\n";
                }

                for (let c = -table_.indent; c < row.columns.length; ++c) {
                    let col;
                    if (row.indent) {
                        col = row.columns[c + (table_.indent - row.indent + 1)];
                    } else {
                        col = row.columns[c];
                    }

                    if (col) {
                        csvContent += col;
                    }

                    if (c !== row.columns.length - 1) {
                        csvContent += ",";
                    }
                }

                if (row.padding) {
                    csvContent += "\r\n";
                }

                csvContent += "\r\n";

                lastRow = row;
            }

            return csvContent;
        };
        readbankTransactionValue(supplierBankTransactionHistory, 1, false);
        if (table.rows.length > 0) {
            let headerRows_ = {
                columns: ["TransactionType", "Date", "Description", "Amount", "Balance"]
            }
            table.rows.unshift(headerRows_);
            readableJsonToCSV(table);
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent.replace("data:text/csv;charset=utf-8,", ""),
                csvData: table.rows,
                filename: "BankTransactionHistory.csv",
                isBankTransactionHistory: true
            }
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            })
        }
    }

    getDropBoxProfileContent=(files, overrideFileName,isFileName_)=> {
        if (this.props.isReadOnly) {
            return files.map(d => {
                let fileUrl = d.downloadUrl ? (d.downloadUrl.replace("http//", "http://").replace("https//", "https://")
                    .replace("http://http", "http").replace("https://http", "http")) : "";

                if (this.user.currentUserRole() == "funder") {
                    return <div className="col-md-6" key={d.filename}> <span className="form-value"><a className="anchorstyle" href='' onClick={(e)=>{
                        e.preventDefault();
                        if(d.downloadUrl){
                            d.downloadUrl=fileUrl;
                        }
                        this.setState(
                            {
                                isFileViewerModal: true,
                                fileViewerContent:d,
                                isFinancialDocument:true
                            }
                        )}}>{isFileName_?d.filename:d.financialDocumentType}</a></span></div>
                } else {
                    return <div className="col-md-6" key={d.filename} style={{wordBreak:"break-all"}}><span><a className="anchorstyle" href='' onClick={(e)=>{
                        e.preventDefault();
                        if(d.downloadUrl){
                            d.downloadUrl=fileUrl;
                        }
                        this.setState(
                            {
                                isFileViewerModal: true,
                                fileViewerContent:d,
                                isFinancialDocument:true
                            }
                        )}}>{isFileName_?d.filename:d.financialDocumentType}</a></span></div>
                }

            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    getDropBoxContent(files,type,overrideFileName) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                return <div key={d.filename}><a href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${d._id}`} target="_blank" download={d.filename}>{overrideFileName ? overrideFileName : d.filename}</a></div>
            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    FileViewerModalClick=(e,file_)=>{
        e.preventDefault();
        this.setState({isFileViewerModal:true, isFinancialDocument:false,fileViewerContent:file_});
    }

    ViewSupplyChainModalClick=(e,document_)=>{
        e.preventDefault();
        this.setState({isViewSupplyChain:true, viewDocumentData:document_ });
    }

    getPreFundingDocumentLabels=(type_)=>{
        switch(type_){
            case "CONSOLIDATED_PRE_FUN_DOCUMENTS_ZIP":
                return this.props.t("file_upload_label.label13");
            case "FINANCING_APPLICATION":
                return this.props.t("file_upload_label.label01");
            case "LAST_TWO_YEAR_FINANCIAL_STATEMENTS":
                return this.props.t("file_upload_label.label02");
            case "AGING_REPORTS":
                return this.props.t("file_upload_label.label03");
            case "ARTICLE_OF_INCORPORATION":
                return this.props.t("file_upload_label.label04");
            case "COPY_OF_PRINCIPAL_ID":
                return this.props.t("file_upload_label.label05");
            case "PAYMENT_HISTORY":
                return this.props.t("file_upload_label.label06");
            case "SALES_PROJECTION":
                return this.props.t("file_upload_label.label07");
            case "OTHER_PRE_FUNDING_DOCUMENTS":
                return this.props.t("file_upload_label.label12");
            default:
                return "";
        }
    }

    getPreFundingAdditionalDocs=(additionalDocs_)=>{
        const _docs1=additionalDocs_.filter(f_=>f_.fileCategory==_salesContractLabel);
        const _docs2=additionalDocs_.filter(f_=>f_.fileCategory==_shippingDocumentLabel);
        const _docs3=additionalDocs_.filter(f_=>f_.fileCategory==_paymentConfirmationLabel);
        const _additionalDocs={
            "sales_contract":_docs1,
            "shipping_documents":_docs2,
            "payment_confirmation":_docs3
        }
        return (
            <>
                {(_docs1.length > 0 || _docs2.length > 0 || _docs3.length > 0 )? (<>
                {Object.keys(_additionalDocs).map((type_,i)=>{
                    return (
                        <>
                            <label className="form-label mt-2">
                                {type_ == _salesContractLabel && (
                                    <>
                                        {this.props.t("file_upload_label.label09")}
                                    </>
                                )}
                                {type_ == _shippingDocumentLabel && (
                                    <>
                                        {this.props.t("file_upload_label.label10")}
                                    </>
                                )}
                                {type_ == _paymentConfirmationLabel && (
                                    <>
                                        {this.props.t("file_upload_label.label11")}
                                    </>
                                )}
                            </label>
                            <>
                                {_additionalDocs[type_].length > 0 ? (<>
                                    {_additionalDocs[type_].map((doc_, index) => {
                                        return <span
                                            key={index} className="form-value p-0 d-flex">
                                            <a className="anchorstyle mr-2" href={""} onClick={e => this.FileViewerModalClick(e, doc_)}>{convertUTF8String(doc_.filename)}</a>
                                            <FileMetaDataPopover data={doc_} />
                                        </span>
                                    })}
                                </>) : (<div className='pl-4'>{this.props.t("documents_not_found_msg")}</div>)}
                            </>
                        </>
                    )
                })}
                </>) : (<>
                    <label className="form-label mt-2">
                        {this.props.t("previous_completed_transaction_documents")}
                    </label>
                    <>
                        {additionalDocs_.map((document_, index) => {
                            return <span
                                key={index} className="form-value p-0 d-flex">
                                <a className="anchorstyle mr-2" href={""} onClick={e => this.FileViewerModalClick(e, document_)}>{convertUTF8String(document_.filename)}</a>
                                <FileMetaDataPopover data={document_} />
                            </span>
                        })}
                    </>
                </>)}
            </>
        )
    }

    render() {
        const {
            fundingReqId,
            totalfundingAmount,
            supplier,
            midDesk,
            fundingDocuments,
            fundingType,
            status,
            isPreFundingRequest,
            tradingPartnerLists
        }=this.props.data;
        const{customerDocuments,contracts}=this.state;
        return (
            <>
                {(this.state.isSigningPopup || this.state.isFileViewerModal || this.state.isViewSupplyChain) && (
                    <div className="terms-checkbox-container">
                        {this.state.isSigningPopup && this.state.signingContractValue && <SigningStatusModal
                            isReadOnly={true}
                            onClose={() => {
                                this.setState({ isSigningPopup: false, signingContractValue: null })
                            }}
                            data={this.state.signingContractValue}
                                isTermSheetAvailable={this.state.isTermSheetAvailable}
                        />}
                        {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                            onClose={() => {
                                this.setState({ isFileViewerModal: false, fileViewerContent: null });
                            }}
                            data={this.state.fileViewerContent}
                            isFinancialDocument={this.state.isFinancialDocument}
                        ></FileViewerModal>}
                        {(this.state.isViewSupplyChain) && <UploadSupplyChainModal
                            isReadOnly={this.state.isViewSupplyChain}
                            t={this.props.t}
                            onClose={() => {
                                this.setState({ isViewSupplyChain: false, viewDocumentData: null });
                            }}
                            data={this.state.viewDocumentData}
                        />}
                    </div>
                )}
                <CommonPopUpModal
                    size="large"
                    title={`${this.props.t("page_titles.transactions")}: #${fundingReqId}`}
                    onClose={e => this.props.onClose()}
                    promptOnClose={false}
                    children={
                        <>
                            <div className="row px-1">
                                {compareRole(this.user.currentUserRole(), enumRole.Admin) && (
                                    <div className="col-md-6">
                                        <div className="view-transaction-details">
                                            <div className="row pt-4">
                                                <div className="col-lg-6 pl-5">
                                                    <label className="view-details-header pb-2">{this.props.t("requester_details")}</label>
                                                    <UserDetails userDetails={this.props.data.supplier} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                                <div className="col-md-6">
                                    {fundingType.map(type => {
                                        if (type == "TCF") {
                                            return fundingDocuments.map(item => (
                                                <React.Fragment>
                                                    {item.total && (
                                                        <div className="view-transaction-details">
                                                            <div className="row py-4">
                                                                <div className="col-md-12 px-5">
                                                                    <label className="view-details-header pb-1">
                                                                    {this.props.t("fundingrequests_page.view_funding_request_content.tax_credit_label")}
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-12 px-5">
                                                                    <span className="view-transaction-amt" style={{ fontSize: 30 }}>
                                                                        {dollarFormatter.format(item.total)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="view-transaction-details pb-2">
                                                        <div className="row">
                                                            <div className="col-md-6 files-container px-5">
                                                                <label className="view-details-header pb-2">{this.props.t("fundingrequests_page.view_funding_request_content.financial_statements_title")}</label>
                                                                {item.tcf.findoc.length
                                                                    ? <div>
                                                                        {
                                                                            this.getDropBoxContent(item.tcf.findoc, type.toLowerCase())
                                                                        }
                                                                    </div>
                                                                    : <div>{this.props.t("documents_not_found_msg")}</div>
                                                                }
                                                            </div>
                                                            <div className="col-md-6 files-container px-5">
                                                                <label className="view-details-header pb-2">{this.props.t("fundingrequests_page.view_funding_request_content.tax_documents_label")}</label>
                                                                {item.documentFiles.length
                                                                    ? <div>
                                                                        {
                                                                            this.getDropBoxContent(item.documentFiles, type.toLowerCase())
                                                                        }
                                                                    </div>
                                                                    : <div>{this.props.t("documents_not_found_msg")}</div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }
                                        else if (type != "ABL") {
                                            return (
                                                <div className="view-transaction-details">
                                                    {isPreFundingRequest ? (
                                                        <div className="row pt-4">
                                                            {tradingPartnerLists && tradingPartnerLists.map(t => {
                                                                return <>
                                                                    <div className="col-lg-6 pl-5">
                                                                        <label className="view-details-header pb-2">{compareRole(this.user.currentUserRole(), enumRole.Admin)?this.props.t("trading_partner_details.title"): this.props.t("buyer_details")}</label>
                                                                        <UserDetails userDetails={t} />
                                                                    </div>
                                                                    <div className="col-lg-6 pr-5">
                                                                        {t.invoiceFile && t.invoiceFile.map((document_, index) => {
                                                                            return <span
                                                                                key={index} className="d-flex">
                                                                                <a className="anchorstyle mr-2" href={""} onClick={e => this.FileViewerModalClick(e, document_)}>{this.props.t("view_invoice")}</a>
                                                                                <FileMetaDataPopover data={document_} />
                                                                            </span>
                                                                        })}
                                                                        {(t.additionalDocs && t.additionalDocs.length > 0) && (
                                                                            <>
                                                                                {this.getPreFundingAdditionalDocs(t.additionalDocs)}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            })}

                                                            <div className="col-lg-12 pl-5 mb-2">
                                                                <label className="view-details-header pb-2">{this.props.t("pre_funding_documents")}</label>
                                                                    {Object.keys(this.state.preFundingDocs).length>0 ?(<>
                                                                    {Object.keys(this.state.preFundingDocs).map((type_, index) => {
                                                                        return (
                                                                            <>
                                                                                
                                                                                <label className="form-label mt-2">{this.getPreFundingDocumentLabels(type_)}</label>
                                                                                    {this.state.preFundingDocs[type_] && this.state.preFundingDocs[type_].length == 0 ? (
                                                                                        <>{this.props.t("documents_not_found_msg")}</>
                                                                                    ) : (
                                                                                        <>
                                                                                            {this.state.preFundingDocs[type_].map((d_, i) => {
                                                                                                const fileName = getFileName(d_.filename, d_.name);
                                                                                                const isZipFile = d_.mimeType === "application/x-zip-compressed";
                                                                                                return (
                                                                                                    <>
                                                                                                        
                                                                                                            <span className="form-value d-flex">
                                                                                                            {isZipFile ?
                                                                                                                <DownloadLink downloadUrl={d_.downloadUrl} filename={fileName} className={"anchorstyle mr-2"} /> :
                                                                                                                <a className="anchorstyle mr-2" href={""} onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    this.setState({
                                                                                                                        isFileViewerModal: true,
                                                                                                                        isFinancialDocument: true,
                                                                                                                        fileViewerContent: d_
                                                                                                                    })
                                                                                                                }} >{convertUTF8String(d_.filename)}</a>
                                                                                                            }
                                                                                                                <FileMetaDataPopover data={d_} />
                                                                                                            </span>
                                                                                                        
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                    )}
                                                                                
                                                                            </>
                                                                        )
                                                                    })
                                                                    }
                                                                    </>):(<>{this.props.t("documents_not_found_msg")}</>)}
                                                                
                                                            </div>
                                                            


                                                        </div>
                                                    ) : (<>
                                                        <DocumentDetails
                                                            t={this.props.t}
                                                            customerDocuments={customerDocuments}
                                                            FileViewerModalClick={this.FileViewerModalClick}
                                                            ViewSupplyChainModalClick={this.ViewSupplyChainModalClick}
                                                            role={this.user.currentUserRole()}
                                                            creditLimitForTradingPartner={this.props.data && this.props.data.contracts && this.props.data.contracts.length > 0 && this.props.data.contracts[0].creditLimitForTradingPartner ? this.props.data.contracts[0].creditLimitForTradingPartner : {}} />
                                                        <div className='row pt-4'>
                                                            {Object.keys(customerDocuments).map(item => (
                                                                <>
                                                                    {customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.financialDocuments && customerDocuments[item][0].customerDetails.financialDocuments.length > 0 && (
                                                                        <>
                                                                            <div className='col-lg-12 pl-5'>
                                                                                <label className="view-details-header pb-2">{customerDocuments[item][0].customerDetails.name} {this.props.t("financial_documents")}
                                                                                    <span className="ml-2 badge badge-primary">
                                                                                        {customerDocuments[item][0].customerDetails.role.toLowerCase() == "buyer" ? "Buyer" : "Supplier"}
                                                                                    </span></label>
                                                                            </div>

                                                                            <div className="row pl-5">
                                                                                {this.getDropBoxProfileContent(customerDocuments[item][0].customerDetails.financialDocuments)}
                                                                                {!customerDocuments[item][0].customerDetails.isAgingfile && (
                                                                                    <React.Fragment>
                                                                                        <div className="col-md-6" style={{ wordBreak: "break-all" }}>
                                                                                            <span>
                                                                                                <a
                                                                                                    className="anchorstyle"
                                                                                                    href=""
                                                                                                    onClick={(e) => this.getAggingReportDownload(e, customerDocuments[item][0].customerDetails.email, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                >{this.props.t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                                                                            </span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {customerDocuments[item][0].customerDetails.payableAging && (
                                                                                    <React.Fragment>
                                                                                        <div className="col-md-6" style={{ wordBreak: "break-all" }}>
                                                                                            <span>
                                                                                                <a
                                                                                                    className="anchorstyle"
                                                                                                    href=""
                                                                                                    onClick={(e) => this.getPayableAgingReportDownload(e, customerDocuments[item][0].customerDetails.payableAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                >{this.props.t("profile_page.financial_information_content.payable_aging_label")}</a>
                                                                                            </span>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {(customerDocuments[item][0].customerDetails.accountingInformation && customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks") &&
                                                                                    (<React.Fragment>
                                                                                        {(customerDocuments[item][0].customerDetails.bankStatement) && (
                                                                                            <div className="col-md-6" style={{ wordBreak: "break-all" }}>
                                                                                                <span>
                                                                                                    <a
                                                                                                        className="anchorstyle text-uppercase"
                                                                                                        href=""
                                                                                                        onClick={(e) => this.getTransactionHistoryDownload(e, true, customerDocuments[item][0].customerDetails.bankStatement)}
                                                                                                    >
                                                                                                        {this.props.t("profile_page.financial_information_content.bank_transaction_label")}
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                    )}
                                                                                {(customerDocuments[item][0].customerDetails.payablesDetailsAging && customerDocuments[item][0].customerDetails.accountingInformation && (customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks" || customerDocuments[item][0].customerDetails.accountingInformation == "D365BC")) &&
                                                                                    <div className="col-md-6" style={{ wordBreak: "break-all" }}>
                                                                                        <span>
                                                                                            <a
                                                                                                className="anchorstyle text-uppercase"
                                                                                                href=""
                                                                                                onClick={(e) => this.payDetailAgingReportDownload(e, customerDocuments[item][0].customerDetails.payablesDetailsAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                            >
                                                                                                {this.props.t("profile_page.financial_information_content.payable_details_aging_label")}
                                                                                            </a>
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                                {(customerDocuments[item][0].customerDetails.receivableDetailsAging && customerDocuments[item][0].customerDetails.accountingInformation && (customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks" || customerDocuments[item][0].customerDetails.accountingInformation == "D365BC")) &&
                                                                                    <div className="col-md-6" style={{ wordBreak: "break-all" }}>
                                                                                        <span>
                                                                                            <a
                                                                                                className="anchorstyle text-uppercase"
                                                                                                href=""
                                                                                                onClick={(e) => this.recDetailAgingReportDownload(e, customerDocuments[item][0].customerDetails.receivableDetailsAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                            >
                                                                                                {this.props.t("profile_page.financial_information_content.receivable_details_aging_label")}
                                                                                            </a>
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className="row pl-5">
                                                                                <div className="col-lg-12">
                                                                                    <label className="form-label">{this.props.t("profile_page.financial_information_content.additional_document_title")}</label>
                                                                                </div>
                                                                                {customerDocuments[item][0].customerDetails.additionalDocuments && customerDocuments[item][0].customerDetails.additionalDocuments.length > 0
                                                                                    ? (<>
                                                                                        {this.getDropBoxProfileContent(customerDocuments[item][0].customerDetails.additionalDocuments, "", true)}
                                                                                    </>)
                                                                                    : (<div>{this.props.t("documents_not_found_msg")}</div>)
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ))}
                                                        </div>
                                                    </>)}
                                                </div>
                                            )
                                        }
                                    })}
                                    <div className="view-transaction-details mt-4" >
                                        <div className="row py-4">
                                            <div className="col-md-12 px-5">
                                                <label className="view-details-header pb-1">
                                                    {this.props.t("requested_total_amount")}
                                                </label>
                                            </div>
                                            <div className="col-md-12 px-5">
                                                <span className="view-transaction-amt" style={{ fontSize: 30 }}>
                                                    {dollarFormatter.format(totalfundingAmount)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(!compareRole(this.user.currentUserRole(), enumRole.Admin)) && (<>
                                    <div className="col-md-6">
                                        {status > 3 && (
                                            <React.Fragment>
                                                {contracts.map(contract => {
                                                    return (
                                                        <React.Fragment>
                                                            <div className="view-transaction-details">
                                                                <div className="row pt-4">
                                                                    <div className="col-lg-6 pl-5">
                                                                        <label className="view-details-header pb-2">{this.props.t("funder_details")}</label>
                                                                        <UserDetails userDetails={contract.funderDetails} />
                                                                    </div>
                                                                    <div className="col-lg-6 pr-5">
                                                                        <label className="view-details-header pb-2">{this.props.t("funding_offer_details.funding_offer_title")}</label>
                                                                        {contract.contractType.indexOf("ABL") == 0 && (
                                                                            <>
                                                                                {this.props.t("funding_offer_details.abl_advance_rate")}:{" "}
                                                                                <strong className="link-text-color">
                                                                                    {contract.terms.advanceRateOnEligibleAR}%
                                                                                </strong>
                                                                                <br />
                                                                                {this.props.t("funding_offer_details.abl_eligible_inventory")}:{" "}
                                                                                <strong className="link-text-color">
                                                                                    {contract.terms.advanceRateOnEligibleInventory}%
                                                                                </strong>
                                                                                <br />
                                                                                {contract.terms.lineOfCredit && (
                                                                                    <>
                                                                                        {this.props.t("funding_offer_details.line_of_credit")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {contract.terms.lineOfCredit}
                                                                                        </strong>
                                                                                        <br />
                                                                                    </>
                                                                                )}
                                                                                {contract.terms.loan && (
                                                                                    <>
                                                                                        {this.props.t("funding_offer_details.loan")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {contract.terms.loan}
                                                                                        </strong>
                                                                                        <br />
                                                                                    </>
                                                                                )}
                                                                                {this.props.t("funding_offer_details.total_financing_fees")}:{" "}
                                                                                <strong className="link-text-color">
                                                                                    {contract.terms.totalFinancingFee}
                                                                                </strong>
                                                                            </>
                                                                        )}
                                                                        {contract.contractType.indexOf("ABL") != 0 && contract.contractType.indexOf("SupplyChain") != 0 && (
                                                                            <>
                                                                                {contract.contractType.indexOf("TCF") == 0 ?
                                                                                    <React.Fragment>
                                                                                        {this.props.t("funding_offer_details.factoring_rate")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {contract.terms.factoringrate}%
                                                                                        </strong>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <React.Fragment>
                                                                                        {this.props.t("funding_offer_details.advance_rate")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {contract.terms.advancerate}%
                                                                                        </strong>
                                                                                        <br />
                                                                                        {this.props.t("funding_offer_details.factoring_rate")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {contract.terms.factoringrate}%
                                                                                        </strong>
                                                                                        <br />
                                                                                        {this.props.t("funding_offer_details.repayment_days")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {contract.terms.lengthoftimeforpayment == "15" ? "International" : contract.terms.lengthoftimeforpayment + " days"}
                                                                                        </strong>
                                                                                        <br />
                                                                                        {this.props.t("funding_offer_details.recourse")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {((contract.terms.isRecourse).toString() == "false") ? "No" : "Yes"}
                                                                                        </strong>
                                                                                        <br />
                                                                                        {this.props.t("funding_offer_details.other_fees")}:{" "}
                                                                                        <strong className="link-text-color">
                                                                                            {contract.terms.offernote}
                                                                                        </strong>
                                                                                        <br />
                                                                                    </React.Fragment>
                                                                                }
                                                                            </>
                                                                        )}
                                                                        {contract.contractType.indexOf("SupplyChain") == 0 &&
                                                                            (<>
                                                                                {this.props.t("funding_offer_details.offer_details_title")}:{" "}
                                                                                <strong className="link-text-color">
                                                                                    {contract.terms.offernote}
                                                                                </strong>
                                                                                <br />
                                                                            </>
                                                                            )}
                                                                        {this.props.t("funding_offer_details.term_sheet_label")}:{" "} <br />{(contract.termSheetFile) ? (
                                                                            <>
                                                                                <div className='d-flex'>
                                                                                    <a className="anchorstyle pl-4 pr-2" href={""} onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.setState({
                                                                                            isFileViewerModal: true,
                                                                                            isFinancialDocument: false,
                                                                                            fileViewerContent: contract.termSheetFile
                                                                                        })
                                                                                    }}>{this.props.t("funding_offer_details.term_sheet_label")}</a> {contract.isManualTermSheetUpload && (<FileMetaDataPopover data={contract.termSheetFile} />)} &nbsp;
                                                                                    {!contract.isManualTermSheetUpload && (
                                                                                        <Tooltip title={this.props.t("funding_offer_details.click_to_view_electronic_signature")} arrow id='signed-term-sheet'>
                                                                                        <InfoIcon fontSize="small" style={{ color: "#063A52", cursor: "pointer" }} onClick={(e) => {
                                                                                            let _cont = contract;
                                                                                            _cont["supplier"] = supplier;
                                                                                            _cont["funder"] = contract.funderDetails ? contract.funderDetails : contract.funder;
                                                                                            this.setState({ isSigningPopup: true, signingContractValue: _cont, isTermSheetAvailable: true });
                                                                                        }} />
                                                                                    </Tooltip>
                                                                                    )}
                                                                                </div>
                                                                                <div className='d-flex'>
                                                                                        {contract.isManualTermSheetUpload && contract.signedTermSheetFile && (<>
                                                                                            <br />
                                                                                            <a className="anchorstyle pl-4 pr-2" href={""} onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({
                                                                                                    isFileViewerModal: true,
                                                                                                    isFinancialDocument: false,
                                                                                                    fileViewerContent: contract.signedTermSheetFile
                                                                                                })
                                                                                            }}>{this.props.t("funding_offer_details.signed_term_sheet_label")}</a> <FileMetaDataPopover data={contract.signedTermSheetFile} />&nbsp;
                                                                                        </>)}
                                                                                </div>
                                                                            </>

                                                                        ) : (
                                                                            <strong className="link-text-color">{this.props.t("funding_offer_details.no_term_sheet_msg")}</strong>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(status >= EnumFundingRequestStatus.FundingOffered && contract.fundingAggrement != null) && (
                                                                <div className="view-transaction-details pb-4 mt-4">
                                                                    <div className="row pt-4">
                                                                        <div className="col-lg-12 px-5">
                                                                            <label className="view-details-header pb-2">{this.props.t("funding_offer_details.funding_agreement_title")}</label>
                                                                            <span className="">
                                                                                <div className="row">
                                                                                    {(status >= EnumFundingRequestStatus.FundingOffered && contract.fundingAggrement != null && contract.isDigitalSignature && contract.isDigitalSignature == "true") ?
                                                                                        (<div className="col-md-6 pb-2">
                                                                                            <span className="">
                                                                                                <a className="anchorstyle" href={""} onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    let _cont = contract;
                                                                                                    _cont["supplier"] = supplier;
                                                                                                    _cont["funder"] = contract.funderDetails ? contract.funderDetails : contract.funder;
                                                                                                    this.setState({ isSigningPopup: true, signingContractValue: _cont });
                                                                                                }}>{this.props.t("funding_offer_details.signing_status")}</a>
                                                                                            </span>
                                                                                        </div>
                                                                                        ) : (<>
                                                                                            {(status >= EnumFundingRequestStatus.FundingOffered && contract.fundingAggrement != null) && (
                                                                                                <div className="col-md-6 pb-2">
                                                                                                    <span className="d-flex">
                                                                                                        <a className="anchorstyle mr-2" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ isFileViewerModal: true, fileViewerContent: contract.fundingAggrement, isFinancialDocument: false })
                                                                                                        }} href={""} target="_blank">{this.props.t("funding_offer_details.funding_agreement_label")}</a>
                                                                                                         <FileMetaDataPopover data={contract.fundingAggrement} />
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                            {(status >= EnumFundingRequestStatus.FundingAccepted && contract.signedAggrements != null) && (
                                                                                                <div className="col-md-6 pb-2">
                                                                                                    <span className="d-flex">
                                                                                                        <a className="anchorstyle mr-2" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            this.setState({ isFileViewerModal: true, fileViewerContent: contract.signedAggrements, isFinancialDocument: false })
                                                                                                        }} href={""} target="_blank">{this.props.t("funding_offer_details.signed_copy_label")}</a>
                                                                                                        <FileMetaDataPopover data={contract.signedAggrements} />
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                        </>)
                                                                                    }
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </>)}
                            </div>
                        </>}>
                </CommonPopUpModal>
            </>
        )
    }
}


const DocumentDetails = ({ customerDocuments ,FileViewerModalClick,ViewSupplyChainModalClick,role,creditLimitForTradingPartner,t}) => {
    let user = new User();
    return (
        <React.Fragment>
            {Object.keys(customerDocuments).map((item,i) => (
                <div key={i} className="row pt-4">
                    <div className="col-lg-6 pl-5">
                        <label className="view-details-header pb-4"> {compareRole(user.currentUserRole(),enumRole.Admin)?t("trading_partner_details.title"): role.toLowerCase()=="supplier"?t("buyer_details"):t("supplier_details")}</label>
                        {customerDocuments[item][0].customerDetails ? (
                                <UserDetails userDetails={customerDocuments[item][0].customerDetails} />
                        ):(
                            <strong>{item}</strong>
                        )}
                    </div>
                    <div className="col-lg-6 pr-5">
                        {customerDocuments[item].map((doc,docIndex_) => (
                            <React.Fragment>
                                <label className="view-details-header pb-2">
                                    {doc.documentType == "PO" ? t("purchase_order_to_be_financed") :  t("grid_view_header_labels.invoice_amount")}
                                </label>
                                <span className="view-transaction-amt">
                                    {dollarFormatter.format(doc.total)}
                                </span>
                                <label className="form-label">
                                    {doc.documentType == "PO" ? t("po_label.po_number") : t("invoice_labels.invoice_number")}<span className="link-text-color">{doc.documentId}</span>
                                </label>
                                {doc.documentType == "SupplyChain" ? (<>
                                    <a className="anchorstyle" href={""} onClick={e => ViewSupplyChainModalClick(e, doc)}>{t("view_invoice_supporting_documents_label")}</a>
                                </>) : (<>
                                    {
                                        doc.documentFiles &&
                                        doc.documentFiles.map((document_, index) => {
                                            return <span
                                                key={index} className="d-flex">
                                                <a className="anchorstyle mr-2" href={""} onClick={e => FileViewerModalClick(e, document_)}>{doc.documentType == "PO" ? t("view_purchase_order") : t("view_invoice")}</a>
                                                <FileMetaDataPopover data={document_} />
                                            </span>
                                        })
                                    }
                                </>)}
                                
                                {doc.buyerApprovedDocs && (
                                    <label className="form-label">
                                        {t("uploaded_buyer_approval_documents_label")} 
                                    </label>
                                )}
                                {doc.buyerApprovedDocs &&
                                    doc.buyerApprovedDocs.map((document_, index) => {
                                        return <span
                                            key={index} className="form-value d-flex">
                                          <a className="anchorstyle mr-2" href={""} onClick={e=>FileViewerModalClick(e,document_)}>{convertUTF8String(document_.filename)}</a>
                                          <FileMetaDataPopover data={document_} />
                                        </span>
                                    })
                                }
                                {doc.purchaseOrder && doc.purchaseOrder.additionalDocs && doc.purchaseOrder.additionalDocs.length > 0 && (
                                    <>
                                        <label className="form-label mt-2">
                                        {t("supporting_documents_label")}
                                        </label>
                                        <>
                                            {doc.purchaseOrder.additionalDocs.map((document_, index) => {
                                                return <span
                                                    key={index} className="form-value p-0 d-flex">
                                                       <a className="anchorstyle" href={""} onClick={e=>FileViewerModalClick(e,document_)}>{convertUTF8String(document_.filename)}</a>
                                                       <FileMetaDataPopover data={document_} />
                                                </span>
                                            })}
                                        </>
                                    </>
                                )}
                                {/*Ling want to comment in view transactions modal. Show only in view funding request */}
                                {/* {customerDocuments[item] && customerDocuments[item].length > 0 && (customerDocuments[item].length - 1==docIndex_) && (
                                    <>
                                        {compareTransactionType(doc.documentType, transactionType.Invoice) && Object.keys(creditLimitForTradingPartner).length > 0 && (
                                            <div className='d-flex'>
                                                <div><b>Credit Limit:</b></div><strong className='form-value link-text-color'>{creditLimitForTradingPartner[doc.tradingPartnerId].creditLimitValue == 0 ? "No Credit Limit" : dollarFormatter.format(creditLimitForTradingPartner[doc.tradingPartnerId].creditLimitValue)}
                                                </strong>
                                            </div>
                                        )}
                                    </>
                                )} */}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}
