import React, { Component } from 'react';
import './Button.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faBan,
} from '@fortawesome/free-solid-svg-icons';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingButtons: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { isShowingButtons } = this.state;
    const { isDisabled } = this.props;
    if (isDisabled) {
      return;
    }
    this.setState({ isShowingButtons: !isShowingButtons });
  }

  render() {
    const { isShowingButtons } = this.state;
    const {
      actionClickHandler,
      buttonStyle,
      buttonColor,
      isDisabled,
      defaultLabel,
      clickedLabel,
    } = this.props;
    return (
      <div className="btn-group" role="group">
        <button type="button" className={`btn ${buttonStyle}-${buttonColor}`} disabled={isDisabled || isShowingButtons} onClick={this.handleClick}>
          {!isShowingButtons ? defaultLabel : clickedLabel}
        </button>
        {isShowingButtons && (
          <React.Fragment>
            <button type="button" className={`btn btn-${buttonColor} dark`} alt="delete" onClick={() => { actionClickHandler(); this.setState({ isShowingButtons: false }); }}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
            <button type="button" className={`btn btn-${buttonColor}`} alt="cancel" onClick={() => this.setState({ isShowingButtons: false })}>
              <FontAwesomeIcon icon={faBan} />
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Button;
