import React, { Component } from "react";
import Popup from '../../../common/Auth/Popup';
import './InvoicesList';
import Dropzone, { useDropzone } from "react-dropzone";
import { API_ENDPOINT_SUPPLIER } from "../../../../services/API";
import User from "../../../../services/User";
import API from "../../../../services/API";
import PdfPaser from "../../../../services/PdfPaser";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./UploadInvoice.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
import Chance from "chance";
import MaskedInput from "react-text-mask";
import FullSpinner from "../../../common/Spinner/FullSpinner";
import {parse} from "../../../../../node_modules/csv/dist/esm/index.js";
import SampleFile from "./SampleInvoice.csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { ShortText, localDateFormat,} from '../../../../services/Utilities';
import FileViewerModal from "../../../common/FileViewerModal/FileViewerModal";
import InvoiceDetails from "../../../common/InvoiceComponents/InvoiceDetails.js"
import FileUploader from "../../../common/FileUploader/FileUploader.js";
import fileUploadConfig from "../../../../configs/FileUploader.js";
import CommonPopUpModal from "../../../CommonPopUpModal/CommonPopUpModal.js";

const chance = new Chance();
const CheckboxTable = checkboxHOC(ReactTable);
let invoicesResponse = [];
const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
});

const {
    supportedFileTypes,
    placeholders,
    duplicateFileMessage
} = fileUploadConfig;

const getFileConfig = (type) => {
    const fileTypeConfig = supportedFileTypes[type] || {};
    return {
        supportedExt: fileTypeConfig.extensions || [],
        errorMsgType: fileTypeConfig.message || '',
        placeholder: placeholders[type] || '',
        errorMsgDuplicate: duplicateFileMessage || ''
    };
};

export class InvoiceModal extends React.Component {
    user = new User();
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            invoices: [],
            fromDate: '',
            toDate: '',
            data: null,
            columns: null,
            selection: [],
            selectAll: false,
            isLoading: false,
            error: null,
            unknownBuyers: null,
            isCreatingInvoice: false,
            uploadFileName: this.props.t("transactions_page.import_invoice_content.dropbox_main_title"),
            fileToUpload: null,
            invoiceFile: null,
            isErpLastSync: false,
            createInvoiceLabel: "",
            isAccountingSystemConnected: false,
            isFileViewerModal:false,
            fileViewerContent:null,
            isValidInvoiceNo:true,
            isPdfParsing:false
        };
        this.handleChangeField = this.handleChangeField.bind(this);
       // this.getInvoices = this.getInvoices.bind(this);
        this.getERPInfo = this.getERPInfo.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getV2Profile = this.getV2Profile.bind(this);
        this.uploadInvoices = this.uploadInvoices.bind(this);
        this.state.invoiceConfig = getFileConfig('invoice');
        this.state.poConfig = getFileConfig('po');
        this.state.addDocConfig = getFileConfig('additionalDocx');
    }

    componentDidMount() {

        this.getProfile();
        if(this.props.invoices){
            invoicesResponse=this.props.invoices;
        }
        // this.getERPInfo(this.props.appState.state.currentUser.email);
        //this.getInvoices();
    }
    async getProfile() {
        this.setState({ isErpLastSync: true });
        const profileResponse = await this.getV2Profile();
        this.setState({ user: profileResponse.user },()=>{
            if (this.state.user.accountingInformation == "quickbooks") {
                this.getQBInfo();
                this.setState({ isErpLastSync: false });
            }
            else if (this.state.user.accountingInformation == "D365BC") {
                this.getD365BusinessCentralDetails();
                this.setState({ isErpLastSync: false });
            }
            else if (this.state.user.accountingInformation == "erpinfo") {
                this.getERPInfo(this.state.user.email);
            }
            else{
                this.setState({ isErpLastSync: false });
            }
        });
    }

    async getQBInfo() {
        const qbInfo = await this.api.gettokenfromQB(this.state.user.email);
        if (qbInfo && qbInfo.EmailID) {
            this.setState({
                isAccountingSystemConnected: true
            })
        }
    }

    async getD365BusinessCentralDetails() {
        const businessCentralInfo_ = await this.api.getD365BCDetails(this.state.user.email);
        if (businessCentralInfo_ &&
            businessCentralInfo_.accountaccountpayablesname &&
            businessCentralInfo_.accountaccountpayablesname.length > 0 &&
            businessCentralInfo_.accountaccountreceivablesname &&
            businessCentralInfo_.accountaccountreceivablesname.length > 0 &&
            businessCentralInfo_.accountinventoryname &&
            businessCentralInfo_.accountinventoryname.length > 0) {
            this.setState({ isAccountingSystemConnected: true })
        }
    }

    async getV2Profile() {
        return await this.api.getV2Profile();
    }
    async getInvoices() {
        invoicesResponse = await this.api.docGetInvoices();
        //this.setState({ invoices: invoicesResponse.invoices.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) });
    }

    async getERPInfo(email) {
        const ERPInfo = await this.api.getErpInfo(email);
        if (ERPInfo && ERPInfo.lastSyncOn) {
            let dt = new Date(ERPInfo.lastSyncOn);
            this.setState({
                lastSyncOn: localDateFormat(dt), isAccountingSystemConnected: true
            });
        }
        else if (ERPInfo && ERPInfo.message) {
            this.setState({
                erpMessage: ERPInfo.message, isAccountingSystemConnected: false
            })
        }
        else if (ERPInfo && ERPInfo.emailId) {
            this.setState({
                isAccountingSystemConnected: true
            })
        }
        this.setState({ isErpLastSync: false });
    }

    async inviteBuyers() {
        this.setState({ isLoading: true });
        const buyersToInvite = {
            invoices: this.state.data,
        };
        const buyersResponse = await this.api.docInviteBuyers(buyersToInvite);
        this.setState({ isLoading: false });
    }

    async uploadInvoices() {
        //Here to Write Logic to upload invoice file..
        const { fileToUpload } = this.state;
        // const documentData = new FormData();
        // documentData.append("documents", fileToUpload);
        //await this.api.uploadInvoiceDocument(documentData);
        const filteredSelection = [];
        let formData = new FormData();
        this.setState({ isCreatingInvoice: true });
        if (this.state.selection.length == 0 && this.state.data && this.state.data.length == 1) {
            this.state.data.filter((item, index) => {
                filteredSelection.push(item);
                formData.append(0, JSON.stringify(item));
                for (let file of this.state.invoiceFile) {
                    formData.append(0, file, file.name);
                }
            });
        }
        else {
            for (let p = 0; this.state.selection.length > p; p++) {
                this.state.data.filter((item, index) => {
                    if (item._id === this.state.selection[p]) {
                        filteredSelection.push(item);
                        formData.append(p, JSON.stringify(item));
                        for (let file of this.state.invoiceFile) {
                            formData.append(p, file, file.name);
                        }

                    }
                });
            }
        }

        const buyersResponse = await this.api.docUploadInvoices(
            formData
        );
        const updatedFilteredSelection = {
            invoices: filteredSelection,
        };
        if (buyersResponse.unknownBuyers) {
            const matchedBuyers = [];
            for (var i = 0; buyersResponse.unknownBuyers.length > i; i++) {
                filteredSelection.filter((item, index) => {
                    if (
                        item.email === buyersResponse.unknownBuyers[i] &&
                        Number(item.total)
                    ) {
                        matchedBuyers.push(item);
                    } else if (!item.email) {
                        matchedBuyers.push(item);
                    }
                });
            }
            this.setState({ data: matchedBuyers });
        } else {
            if (buyersResponse.duplicateInvoices) {
                AddModal(this.props.t("transactions_page.import_invoice_content.duplicate_invoice"),
                    <div>
                        {buyersResponse.duplicateInvoices && buyersResponse.duplicateInvoices.map(doc =>
                            <ul>
                                <li>
                                    {this.props.t("grid_view_header_labels.invoice_number")}: <b>{doc.documentId}</b> {this.props.t("transactions_page.import_invoice_content.and_buyer_name")}: <b>{doc.buyerName}</b>
                                </li>
                            </ul>
                        )}
                    </div>, false);
            }
            let _message="";
            if(buyersResponse && buyersResponse.message){
                switch(buyersResponse.message){
                    case "Invoice(s) uploaded":
                        _message = this.props.t("invoice_uploaded_msg");
                        break;
                    case "Some of the invoice(s) are created":
                        _message = this.props.t("some_of_the_invoice_msg");
                        break;
                    case "All the invoice(s) already created":
                        _message = this.props.t("all_the_invoice");
                        break;
                    default:
                        _message = buyersResponse.message;
                }
            }
            this.setState({ isCreatingInvoice: false, createInvoiceLabel: _message });
        }
        this.setState({
            unknownBuyers: buyersResponse
        });
    }
    toggleSelection = (key, shift, row) => {

        /*
          Implementation of how to manage the selection state is up to the developer.
          This implementation uses an array stored in the component state.
          Other implementations could use object keys, a Javascript Set, or Redux... etc.
        */
        // start off with the existing state
        key = key.substring(7);
        let selection = [...this.state.selection];
        var keyIndex = selection.indexOf(key);
        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            selection.push(key);
        }
        // update the state
        this.setState({ selection });
    };
    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original._id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    downloadFile() {
        window.location.assign(SampleFile);
    }

    importFromERP = async (e) => {
        e.preventDefault();
        const invoices = [];
        const invList = [];
        if (invoices.length > 0) {
            invoices.forEach(inv => {
                if (inv.invoiceNum && inv.total && inv.customerId) {
                    invList.push({
                        "invoiceNo": inv.invoiceNum,
                        "invoiceAmount": inv.total,
                        "customerId": inv.customerId
                    });
                }
            });
        }
        const ERPInfo = await this.api.getErpInfo(this.state.user.email);
        if (ERPInfo && ERPInfo.businessEntityID) {
            this.setState({ isLoading: true });
        }
        const { appState } = this.props;
        const response = await this.api.getInvoicesFromERP(this.state.user.email);
        if (response.message && response.message !== '') {
            alert(response.message);
        }
        else {
            await this.api.submitCustomersToLF(response.customers);
            await this.api.docSubmitInvoiceToLF(response.invoice);
            //  await this.api.submitInvoiceToLF(response.invoice);
            await this.api.updateERPLastSyncTime(this.state.user.email);
            const agingResponse = await this.api.getAgingReportFromERP(this.state.user.email);
            if (agingResponse.message && agingResponse.message !== '') {
                alert(response.message);
            }
            else {
                await this.api.submitAgingReportToLF(agingResponse);
            }
            if (invList.length > 0) {
                const closedInv = await this.api.getClosedInvoicesFromERP(this.state.user.email, {
                    "invoices": invList
                });

                if (closedInv && closedInv.invoices.length > 0) {
                    await this.api.submitClosedInvoiceToLF(closedInv);
                }
            }
        }
        this.setState({ isLoading: false });

    };

    importFromQuickBooks = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const invoices = await this.api.getinvoicefromQB(this.state.user.email);
        await this.api.docSubmitInvoiceToLF(invoices);
        this.setState({ isLoading: false });
    };

    importFromD365BusinessCentral = async (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const invoices = await this.api.getinvoicefromD365BC(this.state.user.email);
        await this.api.docSubmitInvoiceToLF(invoices);
        this.setState({ isLoading: false });
    };
    handleChangeField(fieldName, value) {
        this.state[fieldName] = value;
        // this.setState({ toDate });
    }


    isEnabledImport = () => {
        const { data } = this.state;
        if (!(data && data[0] && data[0].Terms >= 1 && data[0].Terms <= 365)) {
            return true;
        }
        else if (this.state.data.length == 1) {
            
            return  !this.state.isValidInvoiceNo;
        }
        else {
            if (this.state.selection == 0) {
                return true
            }
            else {
                return false;
            }
        }
    }

    handleDataChange = (updatedData) => {
        this.setState({ data: updatedData });
    };

    handleModalClose = () => {
        this.props.onClose();
    }

    handleInvoiceLoaded = (documentData) => {
        this.setState({
          data: documentData.data,
          invoiceFile: documentData.invoiceFile,
        });
      };

    handleLoadingError = (errorData) => {
        console.log(errorData)
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    render() {
        const { history } = this.props;
        const { toggleSelection, toggleAll, isSelected, logSelection } = this;
        const { data, selectAll, lastSyncOn, erpMessage, isAccountingSystemConnected } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox"
        };
        const columns = [
            {
                Header: "Invoice Number",
                accessor: "Invoice#"
            },
            {
                Header: "Invoice Date",
                accessor: "createdAt"
            },
            {
                Header: "Payment Terms",
                accessor: "Terms"
            },
            {
                Header: "Customer Name",
                accessor: "buyerName"
            },
            {
                Header: "Invoice Amount",
                accessor: "total",
                Cell: props => <div className='text-right'>{dollarFormatter.format(props.value)}</div>
            },
            {
                Header: "Customer Email",
                accessor: "email"
            }
        ];
        const { invoiceConfig, poConfig, addDocConfig } = this.state;
        return (
            <>
                <FullSpinner
                    isSpinning={this.state.isErpLastSync}
                />
                <FullSpinner
                    isSpinning={this.state.isPdfParsing}
                />
                <FullSpinner
                    isSpinning={this.state.isLoading}
                    confirmationLabel={this.props.t("transactions_page.import_invoice_content.invoice_uploaded")}
                    callback={e => this.props.onClose()}
                />
                <FullSpinner
                    isSpinning={this.state.isCreatingInvoice}
                    confirmationLabel={this.state.createInvoiceLabel}
                    callback={e => this.props.onClose()}
                />
                {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                    onClose={() => {
                        this.setState({ isFileViewerModal: false, fileViewerContent: null });
                    }}
                    data={this.state.fileViewerContent}
                    isFinancialDocument={true}
                ></FileViewerModal>}
                <CommonPopUpModal
                    onClose={this.handleModalClose}
                    onSubmit={this.uploadInvoices}
                    title={this.props.t("transactions_page.import_invoice_content.upload_invoice")}
                    primaryBtn={this.props.t("transactions_page.import_invoice_content.upload_invoice")}
                    isSubmitBtn={this.isEnabledImport()}
                    size="medium"
                    promptOnClose={this.state.data ? true : false}
                    children={
                        <div>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {this.state.user.accountingInformation != "other" && isAccountingSystemConnected && (
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            id="home-tab"
                                            data-toggle="tab"
                                            href="#erpimport"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >

                                            {this.state.user.accountingInformation == "quickbooks" ?
                                                this.props.t("invoice_from_qbo") :
                                                this.state.user.accountingInformation == "D365BC" ?  this.props.t("invoice_from_d365bc") :
                                                this.props.t("invoice_from_d365")}
                                        </a>
                                    </li>
                                )}
                                {/* <li className="nav-item">
                                    <a
                                        className={(this.state.user.accountingInformation == "other" || !isAccountingSystemConnected) ?
                                            "active nav-link" : "nav-link"}
                                        id="profile-tab"
                                        data-toggle="tab"
                                        href="#fileUpload"
                                        role="tab"
                                        aria-controls="password"
                                        aria-selected="false"
                                    >
                                        {this.props.t("transactions_page.import_invoice_content.navigation_tab_tiles.invoice_upload")}
                                    </a>
                                </li> */}
                                {/* <li className="nav-item ml-auto" >
                                    <button
                                        className="btn btn-primary"
                                        style={{ width: 150 }}
                                        onClick={() => {
                                            this.uploadInvoices();
                                        }}
                                        disabled={this.isEnabledImport()}
                                    >Import Invoice
                                    </button>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                {this.state.user.accountingInformation != "other" && isAccountingSystemConnected && (
                                    <div
                                        className="tab-pane show active"
                                        id="erpimport"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        {this.state.user.accountingInformation == "quickbooks" && (
                                            <React.Fragment>
                                                <div className="form-group mt-3">
                                                    <button className="btn btn-primary" type="submit" onClick={e => this.importFromQuickBooks(e)}>
                                                        <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
                                                        {this.props.t("transactions_page.import_invoice_content.title")}
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {this.state.user.accountingInformation == "D365BC" && (
                                            <React.Fragment>
                                                <div className="form-group mt-3">
                                                    <button className="btn btn-primary" type="submit" onClick={e => this.importFromD365BusinessCentral(e)}>
                                                        <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
                                                        {this.props.t("transactions_page.import_invoice_content.title")}
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {(this.state.user.accountingInformation == "erpinfo" || !this.state.user.accountingInformation) && (
                                            <div className="col-sm-4 mt-4">
                                                <div className="form-group hide">
                                                    <label htmlFor="fromDate">From Date</label>
                                                    <MaskedInput
                                                        name="fromDate"
                                                        onChange={e => this.handleChangeField("fromDate", e.target.value)}
                                                        mask={[
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            "-",
                                                            /\d/,
                                                            /\d/,
                                                            "-",
                                                            /\d/,
                                                            /\d/
                                                        ]}
                                                        className="form-control"
                                                        placeholder="YYYY-MM-DD"
                                                    />
                                                </div>
                                                <div className="form-group mt-3 hide">
                                                    <label htmlFor="toDate">To Date</label>
                                                    <MaskedInput
                                                        name="toDate"
                                                        onChange={e => this.handleChangeField("toDate", e.target.value)}
                                                        mask={[
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            "-",
                                                            /\d/,
                                                            /\d/,
                                                            "-",
                                                            /\d/,
                                                            /\d/
                                                        ]}
                                                        className="form-control"
                                                        placeholder="YYYY-MM-DD"
                                                    />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <button className="btn btn-primary" type="submit" onClick={e => this.importFromERP(e)}>
                                                        <FontAwesomeIcon className="mr-2" icon={faCloudUploadAlt} />
                                                        {this.props.t("transactions_page.import_invoice_content.title")}
                                                    </button>
                                                </div>
                                                {lastSyncOn && (
                                                    <div className="small">
                                                        <strong>{this.props.t("last_sync_as_on")}</strong> {lastSyncOn}
                                                    </div>
                                                )}
                                                {erpMessage && (
                                                    <div className="small">
                                                        <span className="formErrors">
                                                            {this.props.t("erp_info_not_available")}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div
                                    className={(this.state.user.accountingInformation == "other" || !isAccountingSystemConnected) ?
                                        "tab-pane show active" : "tab-pane"}
                                    id="fileUpload"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                >
                                    <>
                                        <div className="row justify-content-center">
                                            <div className='col-md'>
                                                <FileUploader
                                                    onFileExtracted={this.handleInvoiceLoaded}
                                                    onLoadingError={this.handleLoadingError}
                                                    onView={this.handleView}
                                                    convertToJson="invoice"
                                                    fileToExtract={this.state.invoiceFile}
                                                    supportedExt={invoiceConfig.supportedExt}
                                                    //errorMsgType={this.props.t("file_upload_msg.only_pdf_file_msg")}
                                                    placeholder={this.props.t("file_upload_msg.invoice_placeholder")}
                                                    isdisabled={false}
                                                    isMultiple={false}
                                                    isExtraction={true}
                                                />
                                            </div>
                                        </div>
                                        {this.state.data && (<>
                                            {this.state.data.length > 1 ? (<>
                                                <div className="row react-table-style mt-2 mb-0">
                                                    <CheckboxTable
                                                        ref={r => (this.checkboxTable = r)}
                                                        data={data}
                                                        columns={columns}
                                                        defaultPageSize={5}
                                                        className="col-12 -striped -highlight"
                                                        {...checkboxProps}
                                                    />
                                                </div>
                                            </>) : (
                                                <InvoiceDetails
                                                    data={data}
                                                    columns={columns}
                                                    checkboxProps={checkboxProps}
                                                    isValidInvoiceNo={this.state.isValidInvoiceNo}
                                                    isReadOnly={this.props.isReadOnly}
                                                    onDataChange={this.handleDataChange}
                                                    isEnabledImport={this.isEnabledImport}
                                                    t={this.props.t}
                                                />
                                            )}
                                        </>
                                        )}
                                    </>
                                </div>
                            </div>
                        </div>
                    }>
                </CommonPopUpModal>
            </>
        )
    }
}
