import React from 'react';
import {
  withRouter,
  Route,
} from 'react-router-dom';

import AppProvider from "../../../contexts/AppState/AppStateProvider";
import AppState from "../../../contexts/AppState/AppState";

import Login from './Login';
import Register from './Register';
import Forgot from './Forgot';
import QBResponse from './QBResponse';
import D365BCResponse from './D365BCResponse';

const Auth = () => (

        <React.Fragment>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={() => <Register />} />
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/qb-signin-response" component={QBResponse} />
          <Route exact path="/d365bc-signin-response" component={D365BCResponse} />
        </React.Fragment>
);

export default withRouter(Auth);
