import React, { Component } from 'react';

import List from '../../common/List/List';
import MarketplaceInvoiceListItem from '../../common/List/ListType/MarketplaceInvoiceListItem';
import MarketplaceClosedInvoiceListItem from '../../common/List/ListType/MarketplaceClosedInvoiceListItem';
import User from '../../../services/User';
import API from '../../../services/API';
import DataGrid from '../../common/DataGrid/DataGrid';
import DataGridFilter from '../../common/DataGrid/DataGridFilter';
import AppProvider from '../../../contexts/AppState/AppStateProvider';
import AppState from '../../../contexts/AppState/AppState';
import {dollarFormatter,dateFormatter,localDateFormat,addDaysInSufixForPaymentTerm} from '../../../services/Utilities';
import moment from 'moment';
import FullSpinner from '../../common/Spinner/FullSpinner';
import { withTranslation } from 'react-i18next';


class Marketplace extends Component {
  api = new API();
  user=new User();
  _columnsSpecification = [
    {
      key: "Key",
      name: this.props.t("grid_view_header_labels.fr_id"),
      contentProvider: (datum_) => {
        try {
          return <div style={{ color: "#5F9FBC" }}>{datum_.fundingRequestId}</div>;
        }
        catch (ex) { }
        return "";
      }
    },
    {
      key: "Key",
      name: this.props.t("grid_view_header_labels.funding_type"),
      contentProvider: (datum_) => {
        try {
          return <div>{datum_.fundingType}</div>;
        }
        catch(ex){}
        return "";
      }
    },
    {
        key: "amount",
        name: this.props.t("grid_view_header_labels.collateral_amount"),
        textAlignment: "right",
        contentProvider: (datum_) => {
          try {
            return <div style={{ color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.amount)}</div>
          }
          catch(ex){}
          return "";
        }
    },
    {
      key: "industry",
      name: this.props.t("grid_view_header_labels.requester_industry"),
      contentProvider: (datum_) => {
        try {
            return <div>{datum_.industry}</div>;
        }
        catch(ex){}
        return "";
      }
    },
    {
        key: "documentDate",
        name: this.props.t("grid_view_header_labels.funding_transactions"),
        contentProvider: (datum_) => {
          try {
            const customersDocs=datum_.invoices.reduce((cus,docs)=>{
              let customerName=docs.buyerId && docs.buyerId.length>0?docs.buyerId: docs.tradingPartnerId;
              if(!cus[customerName]){
                  cus[customerName]=[];
              }
              cus[customerName].push(docs);
              return cus;
          },{});
          return <div>
          {(datum_.fundingType == "Invoice" || datum_.fundingType=="SupplyChain") && (
            <React.Fragment>
              {customersDocs && Object.keys(customersDocs).length > 0 && (
                <React.Fragment>
                  {Object.keys(customersDocs).map((key, i) => (
                    <React.Fragment>
                      <strong>{this.props.t("trading_partner")} {" "}{i + 1}'s {this.props.t("user_labels.revenue")} :</strong>{" "} <strong><span style={{color: "#255F79", fontWeight: 600 }}>{customersDocs[key][0].tradingPartnerRevenue && customersDocs[key][0].tradingPartnerRevenue=="Not Available"?this.props.t("not_available"): dollarFormatter.format(customersDocs[key][0].tradingPartnerRevenue.replaceAll(',', ''))}</span></strong>
                      <br />
                      <div className="pt-1">
                        {customersDocs[key].map((invoice, n) => (
                          <div className="pl-5">
                            {invoice.paymentTerm ? <>
                              <strong>{datum_.fundingType == "Invoice" ? this.props.t("fundingType.invoice") : this.props.t("fundingType.supply_chian_label")}{" "}{n + 1}:</strong><span>{" "} ({addDaysInSufixForPaymentTerm(invoice.netStr)})</span> <strong> {this.props.t("invoice_labels.due_date")}:</strong> <span className="mr-2">{" "}{localDateFormat(invoice.paymentTerm)}</span>
                            </> : <>
                              <span> <strong> {this.props.t("invoice_labels.payment_term")}:</strong> ({addDaysInSufixForPaymentTerm(invoice.netStr)})</span>
                            </>}
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  )
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {datum_.fundingType == "PO" && (
            <React.Fragment>
              {datum_.pos && datum_.pos.length > 0 && (
                <React.Fragment>
                  {datum_.pos.map((key, i) => (
                    <React.Fragment>
                        <strong> {this.props.t("fundingType.purchase_order")} {" "}{i + 1}'s  {this.props.t("common_names.date")}:</strong> <span className="mr-2">{" "}{localDateFormat(datum_.pos[0].poDate)}</span>
                      <br />
                    </React.Fragment>
                  )
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {datum_.fundingType =="ABL" && (
            <>{this.props.t("fundingType.purchase_order")}</>
          )}
        </div>;
        }
        catch(ex){}
        return "";
      }
    },

    {
        key: "requestDate",
        name: this.props.t("grid_view_header_labels.requested_date"),
        contentProvider: (datum_) => {
          try {
            return <div> {localDateFormat(datum_.fundReqCreatedAt)} </div>;
          }
          catch(ex){}
          return "";
        }
    }];

  constructor(props) {
    super(props);
    this.state = {
      availableInvoices: [],
      inProgressInvoices: [],
      closedInvoices: [],
      availableDataLabel:this.props.t("please_wait_patience_msg"),
      inProgressDataLabel:this.props.t("please_wait_patience_msg"),
      closedDataLabel:this.props.t("please_wait_patience_msg")
    };
    this.getAvailableInvoices = this.getAvailableInvoices.bind(this);
    this.getInProgressInvoices = this.getInProgressInvoices.bind(this);
    this.getClosedInvoices = this.getClosedInvoices.bind(this);
  }

  componentDidMount() {
    document.title = `Marketplace - LedgerFunding ${this.user.currentUserRole()}`;
    this.getAvailableInvoices();
    this.getInProgressInvoices();
    this.getClosedInvoices();
  }

  transformData (data_) {
    return data_.map(d_ => {
      if (d_.fundingType == "Invoice") {
        if (d_.invoices && d_.invoices.length > 0) {
          d_.documentDate = localDateFormat(d_.invoices[0].paymentTerm);
        }
      }
      else if (d_.fundingType == "PO" && d_.pos.length>0) {
        d_.documentDate = localDateFormat(d_.pos[0].poDate);
      }

      d_.amount = parseFloat(d_.amount);
      d_.industry = d_.requesterIndustryStr?d_.requesterIndustryStr:d_.supplierIndustryStr;
      d_.requestDate = moment(d_.fundReqCreatedAt);

      return d_;
    });
  }

  async getAvailableInvoices() {
   // this.setState({isLoading : true})

    let openInvoicesResponse = await this.api.getAvailableMarketplaceData('available');
    if(openInvoicesResponse.data.length==0){
      this.setState({ availableInvoices: this.transformData(openInvoicesResponse.data),availableDataLabel:this.props.t("marketplace_page.no_record_for_available") });
    }
    else{
      openInvoicesResponse.data.sort((a,b)=>{
        return new Date(b.fundReqCreatedAt).getTime() - new Date(a.fundReqCreatedAt).getTime();
      })
      this.setState({ availableInvoices: this.transformData(openInvoicesResponse.data) });
    }
   // this.setState({isLoading : false})
  }

  async getInProgressInvoices() {
   // this.setState({isLoading : true})
    let inProgressResponse = await this.api.getAvailableMarketplaceData('inprogress');
    if(inProgressResponse.data.length==0){
      this.setState({ inProgressInvoices: this.transformData(inProgressResponse.data),inProgressDataLabel:this.props.t("marketplace_page.no_record_for_in_progress") });
    }
    else{
      inProgressResponse.data.sort((a,b)=>{
        return new Date(b.fundReqCreatedAt).getTime() - new Date(a.fundReqCreatedAt).getTime();
      })
      this.setState({ inProgressInvoices: this.transformData(inProgressResponse.data) });
    }
   // this.setState({isLoading: false})
  }

  async getClosedInvoices() {
   // this.setState({isLoading: true})
    let closedInvoiceResponse = await this.api.getAvailableMarketplaceData('closed');
    if(closedInvoiceResponse.data.length==0){
      this.setState({ closedInvoices: this.transformData(closedInvoiceResponse.data),closedDataLabel:this.props.t("marketplace_page.no_record_for_closed") });
    }
    else{
      closedInvoiceResponse.data.sort((a,b)=>{
        return new Date(b.fundReqCreatedAt).getTime() - new Date(a.fundReqCreatedAt).getTime();
      });
      this.setState({ closedInvoices: this.transformData(closedInvoiceResponse.data) });
    }
   // this.setState({isLoading: false})
  }

  render() {
    const { availableInvoices, inProgressInvoices, closedInvoices ,isLoading} = this.state;
    return (
      <AppProvider>
        <AppState.Consumer>
          {appState => (
            <React.Fragment>
              <FullSpinner isSpinning = {isLoading}/>
              <div className="header-titlebar">
                <h3>{this.props.t("page_titles.marketplace")} </h3>
                <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="nav-available-tab" data-toggle="tab" href="#nav-available" role="tab" aria-controls="nav-available" aria-selected="true">
                      {this.props.t("marketplace_page.available")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="nav-in-progress-tab" data-toggle="tab" href="#nav-in-progress" role="tab" aria-controls="nav-in-progress">
                      {this.props.t("marketplace_page.in_progress")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="nav-closed-tab" data-toggle="tab" href="#nav-closed" role="tab" aria-controls="nav-closed">
                      {this.props.t("marketplace_page.closed")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="content-container marketplace-list">
                <div className="tab-content" id="nav-tabInvoices">
                  <div className="tab-pane show active" id="nav-available" role="tabpanel" aria-labelledby="nav-invoices-tab">
                    <DataGrid
                      title="Open Marketplace"
                      data={availableInvoices}
                      columns={this._columnsSpecification}
                      isNoPagination={true}
                      isRowsNonSelectable={true}
                      isNoMoreLink={true}
                      isGridSearch={false}
                      emptydataLabel={this.state.availableDataLabel}
                    />
                  </div>
                  <div className="tab-pane" id="nav-in-progress" role="tabpanel" aria-labelledby="nav-in-progress-tab">
                    <DataGrid
                      title="In progress Marketplace"
                      data={inProgressInvoices}
                      columns={this._columnsSpecification}
                      isNoPagination={true}
                      isRowsNonSelectable={true}
                      isNoMoreLink={true}
                      isGridSearch={false}
                      emptydataLabel={this.state.inProgressDataLabel}
                    />
                  </div>
                  <div className="tab-pane" id="nav-closed" role="tabpanel" aria-labelledby="nav-closed-tab">
                    <DataGrid
                      title="Closed Marketplace"
                      data={closedInvoices}
                      columns={this._columnsSpecification}
                      isNoPagination={true}
                      isRowsNonSelectable={true}
                      isNoMoreLink={true}
                      isGridSearch={false}
                      emptydataLabel={this.state.closedDataLabel}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </AppState.Consumer>
      </AppProvider>
    );
  }
}

export default withTranslation() (Marketplace);
