import React, { Component } from "react"; 
import Popup from '../../../common/Auth/Popup';
import Dropzone, { useDropzone } from "react-dropzone";
import { API_ENDPOINT_SUPPLIER } from "../../../../services/API";
import User from "../../../../services/User";
import CommonConfirmationModal from "../../../common/Modals/CommonConfirmationModal";
import EnumTaxCreditStatus from "./EnumTaxCreditStatus";
import { dollarFormatter } from "../../../../services/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import MaskedInput from "react-text-mask";
import API from "../../../../services/API";
import FullSpinner from "../../../common/Spinner/FullSpinner";

export class TaxCreditModal extends React.Component {
    user = new User();
    api=new API();
    taxYears = [
        {
            value: "2017",
            text: "2017"
        },
        {
            value: "2018",
            text: "2018"
        },
        {
            value: "2019",
            text: "2019"
        },
        {
            value: "2020",
            text: "2020"
        },
        {
            value: "2021",
            text: "2021"
        },
    ];

    constructor(props_) {
        super(props_);

        console.log(props_.data);

        this.state = {
            taxId: props_.data ? props_.data.tcf.taxid : "",
            selectedYear: props_.data ? props_.data.tcf.taxyear : "",
            taxAmount: props_.data ? props_.data.total : "",
            financialDocuments: props_.data ? props_.data.tcf.findoc : [],
            taxDocuments: props_.data ? props_.data.documentFiles : [],
            isTaxId:false,
            isLoading:false
        }
        this.getProfile = this.getProfile.bind(this);
    }
    componentWillMount() {
        var currentYear = new Date().getFullYear();
        this.setState({
            taxYear: currentYear - 1
        });
        this.getProfile();
    }

    async getProfile() {
        const profileResponse = await this.api.getProfile();
        this.setState({
            taxId: profileResponse.user.taxId?profileResponse.user.taxId:""
        })
    }

    render() {
        return (<Popup className={"tcf-popup"}
            content={<>
                <div className="popup">
                    <FullSpinner
                        isSpinning={this.state.isLoading}
                        confirmationLabel="Updated Tax ID"
                    />
                    {
                        (this.state.validationError && this.state.validationError.length) &&
                        <CommonConfirmationModal
                            title="Invalid Inputs"
                            onClose={() => { this.setState({ validationError: null }) }}
                        >
                            <ul>
                                {this.state.validationError.map(t => <li>{t}</li>)}
                            </ul>
                        </CommonConfirmationModal>
                    }
                    {this.state.isTaxId && <CommonConfirmationModal
                        title="Add Tax ID"
                        submitText="Submit"
                        onSubmit={() => {
                            this.setState({
                                isLoading: true,
                            });
                            this.api.updateTaxId(this.state.taxId)
                                .then(r => {
                                    this.setState({
                                        isTaxId: false,
                                        isLoading:false
                                    });
                                });
                        }}
                        onClose={() => { this.setState({ isTaxId: false}) }}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="taxid">TIN</label>
                                    {
                                        this.props.isReadOnly ?
                                            <div className="form-value">{this.state.taxId}</div>
                                            : <MaskedInput
                                                name="taxid"
                                                placeholder="00-0000000"
                                                onChange={(ev_) => {
                                                    this.setState({
                                                        taxId: ev_.target.value
                                                    })
                                                }}
                                                mask={[/[0-9]/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                className="form-control"
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    </CommonConfirmationModal>}
                    <div>
                        <label className="popup-close-icon" onClick={e => this.props.onClose()}></label>
                        <h2>Tax Credit Pre-Funding</h2>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-6">
                            <div className="form-group">
                                <label for="taxid">TIN</label>
                                {
                                    this.props.isReadOnly ?
                                        <div className="form-value">{this.state.taxId}</div>
                                        : <MaskedInput
                                            name="taxid"
                                            placeholder="00-0000000"
                                            onChange={(ev_) => {
                                                this.setState({
                                                    taxId: ev_.target.value
                                                })
                                            }}
                                            mask={[/[0-9]/, /\d/,"-",/\d/, /\d/, /\d/,/\d/,/\d/,/\d/,/\d/]}
                                            className="form-control"
                                        />
                                }
                            </div>
                        </div> */}
                        {
                            this.props.isReadOnly &&
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label for="taxid">Tax Credit to be Pre-Funded</label>
                                    <div className="form-value">{
                                        !this.state.taxAmount
                                        ? "Yet to be filled"
                                        : dollarFormatter.format(this.state.taxAmount)
                                    }</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-md-6 dropbox-container">
                            <label>Financial Statements</label>
                            <Dropzone
                                disabled={this.props.isReadOnly}
                                onDrop={doc =>
                                    this.onDropFinancialDoc(
                                        "Financial_Documents",
                                        doc
                                    )
                                }
                            >
                                {this.state.financialDocuments.length
                                    ? <div>
                                        {
                                            this.getDropBoxContent(this.state.financialDocuments,"Financial_Documents")
                                        }
                                    </div>
                                    : (this.props.isReadOnly ?
                                        <div>No documents were uploaded</div>
                                        : <div>Drop the last 3 years and most recent financial statements to upload</div>)
                                }
                            </Dropzone>

                        </div>
                        <div className="col-md-6 dropbox-container">
                            <label>Tax Documents</label>
                            <Dropzone
                                disabled={this.props.isReadOnly}
                                onDrop={doc =>
                                    this.onDropFinancialDoc(
                                        "Tax_Document",
                                        doc
                                    )
                                }
                            >
                                {this.state.taxDocuments.length
                                    ? <div>
                                        {
                                            this.getDropBoxContent(this.state.taxDocuments,"Tax_Document")
                                        }
                                    </div>
                                    : (this.props.isReadOnly ?
                                        <div>No documents were uploaded</div>
                                        : <div>Drop the last 3 years of tax documents to upload</div>)
                                }
                            </Dropzone>
                        </div>
                    </div>
                    {
                        !this.props.isReadOnly && (
                            <div className="row">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <button
                                        className="btn btn-primary btn-block mt-2"
                                        type="submit"
                                        disabled={( !this.state.financialDocuments.length || !this.state.taxDocuments.length)}
                                        onClick={() => {
                                            let taxId = this.state.taxId.replace(/[^0-9.]/g, "");
                                            let errorMessage = [];

                                            if (this.state.financialDocuments.length == 0) {
                                                errorMessage.push("Upload at least 1 year of financial statements");
                                            }
                                            if (this.state.taxDocuments.length == 0) {
                                                errorMessage.push("Upload at least 1 year of tax documents");
                                            }

                                            if (errorMessage.length > 0) {
                                                this.setState({
                                                    validationError: errorMessage
                                                });
                                            }
                                            else if(!taxId || taxId.length!=9){
                                                this.setState({isTaxId:true});
                                            }
                                            else {
                                                let formData = new FormData();

                                                formData.append("taxYear", this.state.taxYear);
                                                formData.append("taxId", this.state.taxId);

                                                for (let file of this.state.financialDocuments) {
                                                    formData.append("FIN_DOC", file, file.name);
                                                }
                                                for (let file of this.state.taxDocuments) {
                                                    formData.append("TAX_DOC", file, file.name);
                                                }

                                                this.props.onSubmit(formData);
                                            }
                                        }}
                                    >Submit</button>
                                </div>
                            </div>)
                    }
                </div>
            </>}
            handleClose={e => this.props.onClose()}
        />);
    }

    getDropBoxContent(files,type) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                return <div key={d.filename}><a href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${d._id}`} target="_blank" download={d.filename}>{d.filename}</a></div>
            });
        }
        else {
            return files.map(d => {
                return <React.Fragment>
                    <div key={d.name}>{d.name}
                        <span style={{ float: "right", marginRight: "25px" }}
                        onClick={(e)=>{
                            e.stopPropagation();
                            switch (type) {
                                case "Financial_Documents":
                                   let financialdocs_= this.state.financialDocuments.filter((doc)=>{
                                        return doc.id!=d.id
                                    });
                                    this.setState({financialDocuments:financialdocs_})
                                    break;
                                case "Tax_Document":
                                    let taxdocs_= this.state.taxDocuments.filter((doc)=>{
                                        return doc.id!=d.id
                                    });
                                    this.setState({taxDocuments:taxdocs_})
                                    break;
                            }
                        }}>
                            <FontAwesomeIcon  icon={faTrashAlt}></FontAwesomeIcon>
                        </span></div>
                    </React.Fragment>
            });
        }
    }

    onDropFinancialDoc(type, documents) {
        let errorMessage = [];
        let existingDocuments = [];
        documents.forEach(element => {
            element.id=Math.random().toString(36).slice(2);
        });
        if (this.state.financialDocuments.length > 0 || this.state.taxDocuments.length > 0) {
            for (let existingDoc_ of (type == "Financial_Documents" ? this.state.financialDocuments : this.state.taxDocuments)) {
                for (let document_ of documents) {
                    if (document_.name == existingDoc_.name) {
                        errorMessage.push("The following document is already imported");
                        this.setState({
                            validationError: errorMessage
                        });
                        return;
                    }
                }
            }
        }
        switch (type) {
            case "Financial_Documents":
                existingDocuments = this.state.financialDocuments;
                existingDocuments = existingDocuments.concat(documents);
                this.setState({ financialDocuments: existingDocuments });
                break;
            case "Tax_Document":
                existingDocuments = this.state.taxDocuments;
                existingDocuments = existingDocuments.concat(documents);
                this.setState({ taxDocuments: existingDocuments });
                break;
        }
    }
}