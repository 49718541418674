import { config } from '@fortawesome/fontawesome-svg-core';
import { pdfjs } from 'react-pdf';
import { validateEmail, validatePhone ,netTermsMapper} from './Utilities.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function PdfPaser(config_,PDF_URL, callback) {
  let invoiceDic = {};
  let pfdDataConfig=config_;
  let PDFDocumentInstance = null;
  let pageWidth;
  let pageHeight;

  function loadPDFDocument() {
    pdfjs.getDocument(PDF_URL)
      .promise
      .then(function (pdfDocumentInstance) {
        // Use the PDFDocumentInstance To extract the text later
        PDFDocumentInstance = pdfDocumentInstance;
        readPdf();
      }
        , function (reason) {
          // PDF loading error
          console.error(reason);
        }
      );
  }

  function readPdf() {
    let textChunks = [];
    let paragraphs = [];

    let getParagraph = (textChunk_) => {
      for (let para of paragraphs) {
        if (para.chunks.indexOf(textChunk_) != -1) {
          return para;
        }
      }
  
      return new PdfTextParagraph(textChunk_);
    }

    let loadPage = (pageNo, complete_) => {
      if (pageNo > PDFDocumentInstance.numPages) {
        complete_();
        return;
      }
      PDFDocumentInstance
        .getPage(pageNo)
        .then(page_ => {
          pageWidth = page_._pageInfo.view[2];
          pageHeight = page_._pageInfo.view[3];

          page_.getTextContent({disableCombineTextItems: true})
            .then(textChunks_ => {
              for (let textChunk of textChunks_.items) {
                textChunk.transform[5] = (pageHeight * pageNo) - textChunk.transform[5];
              }
              textChunks_.items = sortItems(textChunks_.items);
              let lastChunk = null;
              for (let textChunk of textChunks_.items) {
                if (lastChunk == null || // First chunk
                  !(Math.abs(lastChunk.transform[5] - textChunk.transform[5]) < textChunk.height * 0.1 && // Check if in same line
                    Math.abs(textChunk.transform[4] - (lastChunk.transform[4] + lastChunk.width)) < textChunk.height * 0.25) // Check if same word continues
                ) {
                  textChunks.push(textChunk);
                  lastChunk = textChunk;
                }
                else {
                  lastChunk.str += textChunk.str;
                  lastChunk.width += ((lastChunk.transform[4] + lastChunk.width) - textChunk.transform[4]) + textChunk.width;
                }
              }

              for (let textChunk of textChunks) {
                textChunk.str = textChunk.str.trim();
              }
              // Group chunks by possible paragraphs
              let chunkIndex = 0;

              for (let textChunk of textChunks) {
                let textChunkBottom = textChunk.transform[5] + textChunk.height;
                let textChunkYLimit = textChunkBottom + textChunk.height;

                for (let c = chunkIndex + 1; c < textChunks.length; ++c) {
                  let possibleNextChunk = textChunks[c];

                  if (possibleNextChunk.transform[5] >= textChunkYLimit) break;

                  if (possibleNextChunk.transform[5] > textChunkBottom) {
                    let paragraph = getParagraph(textChunk);
                    if (paragraph.fits(possibleNextChunk) && !paragraph.isExisting) {
                      paragraph.isExisting = true;
                      paragraphs.push(paragraph);
                    }
                  }
                }

                ++chunkIndex;
              }

              loadPage(++pageNo, complete_);
            });
        })
        .catch(ex_ => console.error(ex_));
    }

    loadPage(1, () => {
      var clusters = groupAsClusters(textChunks);
      for (let configName in pfdDataConfig) {
        const configList = pfdDataConfig[configName];

        for (let config of configList) {

          switch (config.type) {
            case "simple":
              invoiceDic[configName] = getSingleData(config, textChunks).text;
              break;
            case "total":
              var total = getSingleData(config, textChunks).text.replace(/[^0-9.-]*/g, "");
              //invoiceDic[configName] = parseFloat(total).toFixed(3);
              let CN = configName
              for (let label of config.source.labels) {
                if (label == "Sales tax") {
                  CN = "Sales tax"
                }
              }
              invoiceDic[CN] = total;
              break;
            case "customerdetail":
              var custDetails = getMultiData(config, textChunks, clusters).text;
              if (custDetails.length >= 4) {
                invoiceDic["city"] = "";
                invoiceDic["state"] = "";
                invoiceDic["zip"] = "";
                invoiceDic["address1"] = "";
                // var cityStateZip =[];
                // let cityStateZipBase = [];

                // cityStateZipBase = custDetails[3].split(',')
                // debugger
                // for(var csz = 0; csz < cityStateZipBase.length; csz ++){
                //   if(csz == 0){
                //     cityStateZip.push(cityStateZipBase[csz]);
                //   }else if (csz == 1){
                //     var stateZip = cityStateZipBase[csz].split(' ');
                //     for(var sz = 0; sz < stateZip.length; sz ++){
                //       if(stateZip[sz]){
                //         cityStateZip.push(stateZip[sz])
                //       }
                //     }
                //   }
                // }
                //console.log("customer address split",cityStateZipBase.length == 2 ? custDetails[3].split(' ') : ['', '', '']);

              }
              //var cityStateZip = custDetails.length >= 4 ? custDetails[3].split(' ') : ['', '', ''];
              var address = "";
              for (var cd = 0; cd < custDetails.length; cd++) {
                if (validateEmail(custDetails[cd])) {
                  invoiceDic["email"] = custDetails[cd];
                }
                if (validatePhone(custDetails[cd].split(/\s/).join('')) || validatePhone(custDetails[cd].split(/\s/).join('').replace("Phone:", "")
                )) {
                  invoiceDic["phone"] = custDetails[cd].split(/\s/).join('').replace("Phone:", "");
                }

                extractAddressComponents(invoiceDic, custDetails[cd]);
              }

              if (custDetails.length >= 2) {

                address = custDetails[1] + custDetails[2];

              }

              // For CSV based implentation
              invoiceDic["buyerName"] = custDetails.length >= 1 ? custDetails[0] : "";
              invoiceDic["address"] = address ? address : "";
              var customerDetailsDic = {};
              customerDetailsDic["name"] = custDetails.length > 0 ? custDetails[0] : "";
              customerDetailsDic["address1"] = invoiceDic && invoiceDic.address1 ? invoiceDic.address1 : custDetails[1] ? custDetails[1] : "";
              customerDetailsDic["postalCode"] = invoiceDic.zip ? invoiceDic["zip"] : "";
              customerDetailsDic["region"] = invoiceDic.state ? invoiceDic["state"] : "";
              customerDetailsDic["country"] = "US";
              customerDetailsDic["phone"] = invoiceDic.phone ? invoiceDic["phone"] : "";
              customerDetailsDic["city"] = invoiceDic.city ? invoiceDic["city"] : "";
              customerDetailsDic["email"] = invoiceDic.email ? invoiceDic["email"] : "";
              if (customerDetailsDic.name.length > 0) {
                invoiceDic[configName] = customerDetailsDic;
                break;
              }
              break;

            case "supplierdetail":
              var supplierDetails = getMultiData(config, textChunks, clusters).text;
              for (var cd = 0; cd < supplierDetails.length; cd++) {
                if (validateEmail(supplierDetails[cd])) {
                  invoiceDic["supplierEmail"] = supplierDetails[cd];
                  break;
                }
              }
              let supplierObject = {
                "name": supplierDetails[0],
                "Address": supplierDetails.length >= 1 ? supplierDetails[1] : "No Data",
                "country": "US"
              };
              invoiceDic[configName] = supplierObject;

              let supplierAddress1 = "";
              for (let sa = 1; sa < supplierDetails.length; ++sa) {
                let addressLine = supplierDetails[sa];
                if (!extractAddressComponents(supplierObject, addressLine)) {
                  supplierAddress1 = (supplierAddress1 + " " + addressLine).trim();
                  supplierObject["address1"] = supplierAddress1;
                }
                else {
                  break;
                }
              }
              
              break;
            case "items":
              invoiceDic[configName] = getTableData(config, textChunks, clusters);
              break;
            case "custom":
              invoiceDic[configName] = config.source.function(clusters);
          }
          if (invoiceDic[configName]) {
            break;
          }
        }
      }
      // for (config.source.labels) {
      //   if (label == text) {
      //     let source = {}
      //     source["label"] = label;
      //     source["flag"] = true
      //     return source;
      //   }
      // }
      for (let key in invoiceDic) {
        if (key === "Sales tax") {
          invoiceDic["tax"] = parseFloat((invoiceDic["Sales tax"] / invoiceDic["subTotal"]) * 100).toFixed(3);
        }
        if (key == "Terms") {
          var termsNum = invoiceDic["Terms"];
          if(termsNum=="FOB" || termsNum=="" || invoiceDic["Terms"].indexOf("documents")>-1){
            invoiceDic["Terms"] = "International";
          }
          else{
            invoiceDic["Terms"] = "Net " + termsNum.replace(/[^0-9]/g, '');
            invoiceDic["Terms"] = netTermsMapper(invoiceDic["Terms"]);
          }
        }
      }

      callback([invoiceDic]);
    });
  }

  function extractAddressComponents(object_, addressString_) {
    var addressComponents = addressString_.trim().match(/^([a-z\s-.]+),\s*([a-z]+)\s*([0-9]+)/i);
    if (addressComponents && addressComponents.length) {
      object_["city"] = addressComponents[1] ? addressComponents[1] : "";
      object_["state"] = addressComponents[2] ? addressComponents[2] : "";
      object_["zip"] = addressComponents[3] ? addressComponents[3] : "";
      return true;
    }
    var addressComponents = addressString_.trim().match(/([\d\w\s-.]+,)\s*([a-z]+)\s*([0-9]+)/i);
    if (addressComponents && addressComponents.length) {
      object_["address1"] = addressComponents[1] ? addressComponents[1] : "";
      object_["state"] = addressComponents[2] ? addressComponents[2] : "";
      object_["zip"] = addressComponents[3] ? addressComponents[3] : "";
      return true;
    }

    return false;
  }

  // Line by line sorting (vertical)
  function sortItemsByLine(lines) {
    var getValue = (line_) => {
      if (line_[0] && line_[0].transform) {
        return line_[0].transform[5];
      }
      
      return line_.getY();
    }

    return lines.sort((a, b) => {
      if (getValue(a) < getValue(b)) {
        return -1;
      }
      else if (getValue(a) > getValue(b)) {
        return 1;
      }
      return 0;
    })
  }

  // Word by word sorting (horizontal)
  function sortItemsInLine(items) {
    var getValue = (item_) => {
      if (item_.transform) {
        return item_.transform[4];
      }
      
      return item_.getX();
    }

    return items.sort((a, b) => {
      if (getValue(a) < getValue(b)) {
        return -1;
      }
      else if (getValue(a) > getValue(b)) {
        return 1;
      }
      return 0;
    })
  }

  // Word by word sorting (horizontal)
  function sortItems(items) {
    return items.sort((a, b) => {
      if (a.transform[5] < b.transform[5]) {
        return -1;
      }
      else if (a.transform[5] > b.transform[5]) {
        return 1;
      }
      else {
        if (a.transform[4] < b.transform[4]) {
          return -1;
        }
        else if (a.transform[4] > b.transform[4]) {
          return 1;
        }
        return 0;
      }
    })
  }

  function isQueryMatching(config, text) {
    if (config.source.labels) {
      let index = 0;
      for (let label of config.source.labels) {
        if (label == text) {
          let source = {};
          source["label"] = label;
          source["flag"] = true;
          source["index"] = index;
          return source;
        }
        index++;
      }
    }

    return false;
  }

  function getSingleData(config, textChunks) {
    let threshold = config.threshold;
    let queryItem = null;
    let label = null;
    let side = config.side;
    let patterns = config && config.patterns ? config.patterns : [];

    let matchConfigFromIndex = (startIndex_) => {
      let keyIndex_;
      let isTryDifferentKey = false;

      for (keyIndex_ = startIndex_; keyIndex_ < textChunks.length; keyIndex_++) {
        let queryMatchResponse = isQueryMatching(config, textChunks[keyIndex_].str);
        if (queryMatchResponse && queryMatchResponse.flag) {
          queryItem = textChunks[keyIndex_];
          label = queryMatchResponse.label
          break;
        }
      }

      if (queryItem == null) {
        return { text: "" };
      }

      let queryYPos = queryItem.transform[5];
      let matchingTexts = [];

      let checkAndPushMatchedText = (textItem) => {
        if (patterns.length > 0) {
          let anyPatternMatches = false;

          patterns.forEach(element => {
            if (element.test(textItem.str)) {
              matchingTexts.push(textItem);
              anyPatternMatches = true;
            }
          });

          if (!anyPatternMatches && config.breakOnPatternFailure) {
            isTryDifferentKey = true;
            return false;
          }
        }
        else {
          matchingTexts.push(textItem);
        }

        return true;
      }

      for (let i = 0; i < textChunks.length; i++) {
        let textItem = textChunks[i];
        if (textItem == queryItem) {
          continue;
        }
        if (side == "right") {
          if (Math.abs(textItem.transform[5] - queryYPos) <= 5) {

            let xOffset = textItem.transform[4] - (queryItem.transform[4] + queryItem.width);
            if (xOffset < threshold && xOffset >= 0) {
              if (!checkAndPushMatchedText(textItem)) break;
            }
          }
        }
        else if (side == "left") {
          if (Math.abs(textItem.transform[5] - queryYPos) <= 5) {
            let xOffset = textItem.transform[4] - (queryItem.transform[4] + queryItem.width);

            if (xOffset < threshold) {
              if (!checkAndPushMatchedText(textItem)) break;
            }
          }
        }
        else if (side == "down") {
          let yThreshold = textItem.transform[5] - (queryYPos - queryItem.height);
          let minX = textItem.transform[4];
          let maxX = textItem.transform[4] + textItem.width;
          let queryMinX = queryItem.transform[4];
          let queryMaxX = queryItem.transform[4] + queryItem.width;
          if (yThreshold < threshold && yThreshold >= 0) {
            if ((minX >= queryMinX && minX <= queryMaxX) ||
              (maxX >= queryMinX && maxX <= queryMaxX) ||
              (minX <= queryMinX && maxX >= queryMaxX) ||
              (minX >= queryMinX && maxX <= queryMaxX)) {
              if (!checkAndPushMatchedText(textItem)) break;
            }
          }
        }
        else if (side == "gropbottom") {
          let yThreshold = textItem.transform[5] - (queryYPos + queryItem.height);
          let minX = textItem.transform[4];
          let maxX = textItem.transform[4] + textItem.width;
          let queryMinX = queryItem.transform[4];
          let queryMaxX = queryItem.transform[4] + queryItem.width;
          if (yThreshold < threshold && yThreshold >= 0) {
            if ((minX >= queryMinX && minX <= queryMaxX) ||
              (maxX >= queryMinX && maxX <= queryMaxX) ||
              (minX <= queryMinX && maxX >= queryMaxX) ||
              (minX >= queryMinX && maxX <= queryMaxX)) {
              if (!checkAndPushMatchedText(textItem)) break;
            }
          }
        }
      }

      if (isTryDifferentKey && keyIndex_ < textChunks.length - 1 && matchingTexts.length == 0) {
        return matchConfigFromIndex(keyIndex_ + 1);
      }

      matchingTexts = sortItemsInLine(matchingTexts);
      const _matchingTexts=matchingTexts.map(t => t.str).join("");

      return {
        text: config.mapper?config.mapper(_matchingTexts):_matchingTexts,
        queryItem: queryItem,
        items: matchingTexts
      };
    }

    return matchConfigFromIndex(0);
  }

  function getMultiData(config, textChunks, textClusters) {
    let threshold = config.threshold;
    let lines = [];
    let queryItem = null;

    if (config.source.labels) {
      let lineDistanceThreshold = 15; //// TODO: MAKE CONFIG
      let singleLineResponse = getSingleData(config, textChunks);

      if (!singleLineResponse.items || !singleLineResponse.items.length) {
        return singleLineResponse;
      }

      lines = [singleLineResponse.items];
      queryItem = singleLineResponse.queryItem;

      let firstLineItem = singleLineResponse.items[0];
      let firstLineItem_last = singleLineResponse.items[singleLineResponse.items.length - 1];
      let firstLineItemMinX = firstLineItem.transform[4];
      let firstLineItemMaxX = firstLineItem_last.transform[4] + firstLineItem_last.width + threshold;
      let lineItemY = firstLineItem.transform[5];

      while (true) {
        let line = [];

        for (let i = 0; i < textChunks.length; i++) {
          let textItem = textChunks[i];
          let textItemX = textItem.transform[4];
          let textItemY = textItem.transform[5];
          let yOffset = textItemY - lineItemY;

          if (yOffset < lineDistanceThreshold && yOffset > 0) {
            if (textItemX >= firstLineItemMinX && textItemX <= firstLineItemMaxX) {
              line.push(textItem);
            }
          }
        }

        if (line.length > 0) {
          line = sortItemsInLine(line);
          lines.push(line);
          lineItemY = line[0].transform[5];
        }
        else {
          break;
        }
      }
    }
    else if (config.source.type == "cluster") {
      let matchingIndex = -1;
      let foundCluster = false;

      for (let cluster of textClusters) {
        for (let pattern of config.source.patterns) {
          if (cluster.getText().match(pattern)) {
            if (++matchingIndex == config.source.instance) {
              foundCluster = true;
              lines = cluster.getChunkLines();

              break;
            }
          }
        }

        if (foundCluster) break;
      }

      if (!foundCluster) {
        return { text: "" };
      }
    }
    let _mappedLines=lines.map(l => l.map(t => t.str).join(""));
    return {
      text:config.mapper? config.mapper(_mappedLines):_mappedLines,
      queryItem: queryItem,
      items: lines
    };
  }
  function getTableData(config, textChunks, clusters) {
    let headerYPos = 0;
    let headerYPositions = [];
    let textItem = null;
    let columnConfig = null;
    let lastColumnConfig = null;
    let data = [];
    let columns = [];

    for (let i = 0; i < textChunks.length; i++) {
      textItem = textChunks[i];
      for (let columnName in config.columns) {
        columnConfig = config.columns[columnName];
        let queryMatchResponse = isQueryMatching(columnConfig, textItem.str);

        // Direct text didn't match, let's try cluster
        if (!queryMatchResponse && textItem.cluster) {
          queryMatchResponse = isQueryMatching(columnConfig, textItem.cluster.getText(true));
          queryMatchResponse.clusterMatch = true;
        }

        if (queryMatchResponse && queryMatchResponse.flag) {
          if (!columnConfig.headers) {
            columnConfig.headers = [];
          }
          columnConfig.headers.push(textItem);
          columnConfig.headerIndex = queryMatchResponse.index;
          columnConfig.clusterMatch = queryMatchResponse.clusterMatch;
          headerYPositions.push(Math.round(textItem.transform[5]));
        }
      }
    }

    // get count by header y position
    let yPosCount = {};
    let maxYPosValue = 0;
    let maxYPosCount = 0;
    for (let yPos of headerYPositions) {
      if (!yPosCount[yPos]) {
        yPosCount[yPos] = 1;
      }
      else {
        yPosCount[yPos]++;
      }

      if (yPosCount[yPos] > maxYPosCount) {
        maxYPosCount = yPosCount[yPos];
        maxYPosValue = yPos;
      }
    }
    headerYPos = maxYPosValue + 2;
    // headerYPos = Math.max(...headerYPositions);

    // filter the headers
    for (let columnName in config.columns) {
      columnConfig = config.columns[columnName];
      columnConfig.name = columnName;
      if (columnConfig.headers && columnConfig.headers.length) {
        for (let h = columnConfig.headers.length - 1; h >= 0; --h) {
          if (Math.abs(columnConfig.headers[h].transform[5] - headerYPos) > columnConfig.headers[h].height) {
            columnConfig.headers.splice(h, 1);
          }
        }
        columns.push(columnConfig);
      }
      else if (columnConfig.isRequired) {
        return []; // A required column is missing, so don't proceed.
      }
      else {
        delete config.columns[columnName];
      }
    }

    columns = columns.sort((a, b) => {
      if (a.headers[0].transform[4] < b.headers[0].transform[4]) {
        return -1;
      }
      else {
        return 1;
      }
    });

    for (let c = 0; c < columns.length - 1; ++c) {
      columns[c].nextColumn = columns[c + 1];
    }

    // TODO: Need to handle - If multiple text items with headers names are found
    let lines = [];

    for (let t = 0; t < clusters.length; t++) {
      let cluster = clusters[t];
      let neededLine = null;

      // possible candidates
      if (cluster.getY() > headerYPos) {
        for (let l = 0; l < lines.length; l++) {
          let line = lines[l];

          if (cluster.isHorizontalEngulf(line)) {
            neededLine = line;
            break;
          }
        }

        if (!neededLine) {
          neededLine = new PdfTextClusterSequence();
          lines.push(neededLine);
        }

        neededLine.addCluster(cluster);
      }
    }

    lines = sortItemsByLine(lines);

    for (let l1 = 0; l1 < lines.length; l1++) {
      let line1 = sortItemsInLine(lines[l1].getClusters());
      let datum = {};
      let isBreakProcess = false;
      let isDatumValid = true;

      for (let columnName in config.columns) {
        columnConfig = config.columns[columnName];

        if (columnConfig.headers && columnConfig.headers.length) {
          let columnHeaderItem = columnConfig.headers[0];
          let minX = columnConfig.headers[0].transform[4];
          let maxX = columnConfig.headers[columnConfig.headers.length - 1].transform[4] + columnConfig.headers[columnConfig.headers.length - 1].width;

          columnConfig.minX = minX;
          columnConfig.maxX = maxX;
        }
      }

      for (let wordItem of line1) {
        for (let columnName in config.columns) {
          columnConfig = config.columns[columnName];

          if (columnConfig.headers && columnConfig.headers.length) {
            let minX = wordItem.getX();
            let maxX = wordItem.getMaxX();
            if ((minX >= columnConfig.minX && minX <= columnConfig.maxX) ||
              (maxX >= columnConfig.minX && maxX <= columnConfig.maxX) ||
              (minX <= columnConfig.minX && maxX >= columnConfig.maxX) ||
              (minX >= columnConfig.minX && maxX <= columnConfig.maxX)) {

              let existingText = datum[columnName] ? (datum[columnName] + " ") : "";
              datum[columnName] = (existingText + wordItem.getText(true).trim()).trim();
            }
          }
        }
      }

      for (let columnName in config.columns) {
        columnConfig = config.columns[columnName];
        if (columnConfig.isRequired && !datum[columnName]) {
          isDatumValid = false;
          break;
        }

        if (columnConfig.removePattern) {
          datum[columnName] = datum[columnName].replace(columnConfig.removePattern, "");
        }

        if (columnConfig.isNumberOnly) {
          if ((/[A-z]/i).test(datum[columnName])) {
            isBreakProcess = true;
            isDatumValid = false;
          }

          if (isNaN(parseFloat(datum[columnName]))) {
            isDatumValid = false;
          }
        }

        if (columnConfig.mapper) {
          datum[columnName] = columnConfig.mapper(datum[columnName]);
        }
      }
      if (isDatumValid) {
        data.push(datum);
      }
      if (isBreakProcess) {
        break;
      }
    }

    return data;
  }

  function groupAsClusters(textChunks) {
    let sortedTextChunks = sortItems(textChunks);
    let clusters = [];

    for (let rawTextChunk of sortedTextChunks) {
      let isAdded = false;
      let textChunk = new PdfTextChunk(rawTextChunk);

      for (let c = clusters.length - 1; c >= 0; --c) {
        let cluster = clusters[c];
        if (cluster.fits(textChunk)) {
          cluster.addChunk(textChunk);
          isAdded = true;
          break;
        }
      }

      if (!isAdded) {
        let newCluster = new PdfTextCluster();
        newCluster.addChunk(textChunk);
        clusters.push(newCluster);
      }
    }

    return clusters;
  }

  loadPDFDocument();
};

class PdfTextCluster {
  _lines = [];
  _chunks = [];
  _rawChunks = [];
  _x = Number.MAX_VALUE;
  _y = Number.MAX_VALUE;
  _maxX = 0;
  _maxY = 0;
  _fontSize = 0;

  getX() {
    return this._x;
  }

  getY() {
    return this._y;
  }

  getMaxX() {
    return this._maxX;
  }

  getMaxY() {
    return this._maxY;
  }

  getWidth() {
    let width = this._maxX - this._x;
    if (width < 0) {
      width = 0;
    }
    return width;
  }

  getHeight() {
    let height = this._maxY - this._y;
    if (height < 0) {
      height = 0;
    }
    return height;
  }

  fits(chunk_) {
    if (this._chunks.length > 0) {
      let lastChunk = this._chunks[this._chunks.length - 1];

      // Seems to be on the same line
      if (Math.abs(lastChunk.getY() - chunk_.getY()) <= chunk_.getHeight() * 0.5) {
        let xGap = chunk_.getX() - lastChunk.getMaxX();

        // reasonable gap between words?
        if (xGap >= -5 && xGap <= chunk_.getHeight() * 2) {
          return true;
        }
      }

      // Check if this would fit as next line?
      else {
        if (
          Math.abs(chunk_.getY() - this._maxY) <= chunk_.getHeight() &&
          this._x - 5 <= chunk_.getX() &&
          this._maxX >= chunk_.getX()
        ) {
          return true;
        }
      }
    }

    return false;
  }

  addChunk(chunk_) {
    this._x = Math.min(this._x, chunk_.getX());
    this._y = Math.min(this._y, chunk_.getY());
    this._maxX = Math.max(this._maxX, chunk_.getMaxX());
    this._maxY = Math.max(this._maxY, chunk_.getMaxY());

    if (this._chunks.length == 0) {
      this._fontSize = chunk_.getHeight();
    }

    chunk_.cluster = this;
    this._chunks.push(chunk_);
    this._rawChunks.push(chunk_.get());

    for (let line of this._lines) {
      if (line.fits(chunk_)) {
        line.addChunk(chunk_);
        return;
      }
    }

    let line = new PdfTextLine();
    line.addChunk(chunk_);
    this._lines.push(line);
  }

  getText(noBreaks_) {
    return this._lines.map(l => l.getText().trim()).join(noBreaks_ ? " " : "\n");
  }

  getChunkLines() {
    return this._lines.map(l => l._rawChunks);
  }

  /** object_ can be chunk, line, cluster or cluster sequence */
  isHorizontalEngulf(object_) {
    const factor = 0.1;
    return (this.getY() - object_.getY() >= -(this._fontSize * factor)
      && this.getMaxY() - object_.getMaxY() <= this._fontSize * factor) ||
      (this.getY() - object_.getY() <= -(this._fontSize * factor)
      && this.getMaxY() - object_.getMaxY() >= this._fontSize * factor);
  }

  // isVerticalEngluf(x, maxX) {
  //   const factor = 0.25;
  //   return (Math.abs(this.getX() - x) >= this._fontSize * factor
  //     && Math.abs(this.getMaxX() - maxX) <= this._fontSize * factor) ||
  //     (Math.abs(this.getX() - x) <= this._fontSize * factor
  //     && Math.abs(this.getMaxX() - maxX) >= this._fontSize * factor);
  // }
}

class PdfTextLine {
  _chunks = [];
  _rawChunks = [];
  _yPos = 0;

  fits(chunk_) {
    return Math.abs(this._yPos - chunk_.getY()) <= chunk_.getHeight() * 0.5;
  }

  addChunk(chunk_) {
    if (this._chunks.length == 0) {
      this._yPos = chunk_.getY();
    }

    chunk_.line = this;
    this._chunks.push(chunk_);
    this._rawChunks.push(chunk_.get());
  }

  getText() {
    return this._chunks.map(c => c.getText()).join("");
  }
}

class PdfTextChunk {
  _rawChunk;

  constructor(rawChunk_) {
    this._rawChunk = rawChunk_;
  }

  get() {
    return this._rawChunk;
  }

  getX() {
    return this._rawChunk.transform[4];
  }

  getY() {
    return this._rawChunk.transform[5];
  }

  getMaxX() {
    return this._rawChunk.transform[4] + this._rawChunk.width;
  }

  getMaxY() {
    return this._rawChunk.transform[5] + this._rawChunk.height;
  }

  getWidth() {
    return this._rawChunk.width;
  }

  getHeight() {
    return this._rawChunk.height;
  }

  getText() {
    return this._rawChunk.str;
  }
}

class PdfTextClusterSequence {
  _x = Number.MAX_VALUE;
  _y = Number.MAX_VALUE;
  _maxX = 0;
  _maxY = 0;
  _clusters = [];

  addCluster(cluster_) {
    this._x = Math.min(this._x, cluster_.getX());
    this._y = Math.min(this._y, cluster_.getY());
    this._maxX = Math.max(this._maxX, cluster_.getMaxX());
    this._maxY = Math.max(this._maxY, cluster_.getMaxY());

    this._clusters.push(cluster_);
  }

  getClusters() {
    return this._clusters;
  }

  getX() {
    return this._x;
  }

  getY() {
    return this._y;
  }

  getMaxX() {
    return this._maxX;
  }

  getMaxY() {
    return this._maxY;
  }
}

class PdfTextParagraph {
  _rootChunk = null;
  chunks = [];
  _alignment = -2;

  constructor(rootChunk_) {
    this._rootChunk = rootChunk_;
    this._addChunk(rootChunk_);
  }

  getText() {
    let text = "";
    for (let chunk of this.chunks) {
      text += chunk.str.trim() + " ";
    }
    return text.trim();
  }

  fits(chunk_) {
    if (this._alignment == -2) {
      if (this._tryCenterAlignment(chunk_)) {
        this._alignment = 0;
      }
      else if (this._tryLeftAlignment(chunk_)) {
        this._alignment = -1;
      }
      else if (this._tryRightAlignment(chunk_)) {
        this._alignment = 1;
      }
      else {
        return false;
      }

      this._addChunk(chunk_);
      return true;
    }
    else {
      switch(this._alignment) {
        case -1:
          if (this._tryLeftAlignment(chunk_)) this._addChunk(chunk_);
          break;
        case 0:
          if (this._tryCenterAlignment(chunk_)) this._addChunk(chunk_);
          break;
        case 1:
          if (this._tryRightAlignment(chunk_)) this._addChunk(chunk_);
          break;
      }
    }
  }

  _addChunk(chunk_) {
    chunk_.para = this;
    this.chunks.push(chunk_);
  }

  _tryLeftAlignment(chunk_) {
    return Math.abs(this._rootChunk.transform[4] - chunk_.transform[4]) < this._rootChunk.height * 0.25;
  }

  _tryRightAlignment(chunk_) {
    return Math.abs((this._rootChunk.transform[4] + this._rootChunk.width) - (chunk_.transform[4] + chunk_.width)) < this._rootChunk.height * 0.25;
  }

  _tryCenterAlignment(chunk_) {
    return Math.abs((this._rootChunk.transform[4] + this._rootChunk.width / 2) - (chunk_.transform[4] + chunk_.width / 2)) < this._rootChunk.height * 0.25;
  }
}