import React, { Component } from 'react';
import './Spinner.css';
import { withTranslation } from 'react-i18next';

class FullSpinner extends Component {
  activeSpinnerCount = 0;
  constructor(props) {
    super(props);
    this.state = {
      isShown: props.isSpinning,
      spinnerCount: 0,
      isShowingConfirmation: false,
    };
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.isSpinning) {
        this.setState({ isShown: true });
      }
      if (prevProps.isSpinning === true && this.props.isSpinning === false) {
        if (this.props.confirmationLabel) {
          this.setState({ isShowingConfirmation: true });
          this.hide();
        }
        else {
          this.hide();
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.isGlobalSpinner) {
      SpinnerService.subscribe((flag_) => {
        if (flag_) {
          this.activeSpinnerCount = 1;
        }
        else {
          this.activeSpinnerCount = 0;
        }
        this.setState({
          spinnerCount: Math.max(0, this.activeSpinnerCount)
        });
      });
    }
  }

  hide() {
    const { callback } = this.props;
    this.setState({ isShown: false });
    setTimeout(() => {
      this.setState({
        isShowingConfirmation: false,
      });
      if (callback) {
        callback();
      }
    }, 3000);
  }

  render() {
    const { isShown, isShowingConfirmation, spinnerCount } = this.state;
    const { confirmationLabel, message } = this.props;
    return (
      <React.Fragment>
        {(isShown || spinnerCount != 0) && <Spinner className={this.props.className} message={message} t={this.props.t}/>}
        {isShowingConfirmation && <Checkmark confirmationLabel={confirmationLabel} />}
      </React.Fragment>
    );
  }
}

const Spinner = ({ className, message,t }) => {

  return (
    <React.Fragment>
      <div className={`spinner-overlay ${className}`} />
      <div className="spinner-wrapper d-flex flex-column justify-content-center align-items-center">
        <div className="spinner-content">
          <div className="sk-folding-cube">
            <div className="sk-cube1 sk-cube" />
            <div className="sk-cube2 sk-cube" />
            <div className="sk-cube4 sk-cube" />
            <div className="sk-cube3 sk-cube" />
          </div>
          <p className="spinner-message">{message || t("please_wait")}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

const Checkmark = ({ confirmationLabel }) => (
  <React.Fragment>
    <div className="spinner-overlay" />
    <div className="spinner-wrapper d-flex flex-column justify-content-center align-items-center">
      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
      <h4>{confirmationLabel}</h4>
    </div>
  </React.Fragment>
);

export class SpinnerService {
  static _callbacks = [];
  static subscribe(callback_) {
    SpinnerService._callbacks.push(callback_);
  }

  static emit(flag_) {
    for (let callback of SpinnerService._callbacks) {
      callback(flag_);
    }
  }
}


export default withTranslation() (FullSpinner);
