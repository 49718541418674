import React, { Component } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import FullSpinner from "../Spinner/FullSpinner";
import { STATES } from "../../../configs/States";
import Button from "../Buttons/Button";
import CommonConfirmationModal, { AddModal } from "../../common/Modals/CommonConfirmationModal";
import AppState from "../../../contexts/AppState/AppState";
import { ShortText, localDateFormat, countryCallingCode,countryList,passwordRegexString } from '../../../services/Utilities';
import API from "../../../services/API";
import { UUID, allCountryDetails,isUSCountryUser } from "../../../services/Utilities";
import COMPANY_TYPES from "../../../configs/Company";
import INDUSTRIES from "../../../configs/Industries";
import USER from "../../../services/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faExclamationTriangle,
  faFileUpload
} from "@fortawesome/free-solid-svg-icons";
import Load from "../../../assets/images/load-small.gif";
import Chart from "react-apexcharts";
import { withRouter } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FinancialStatementsModal from "../../common/Modals/FinancialStatementsModal";
import CustomerAgingReportModal from "../Modals/CustomerAgingReportModal";
import EventService from "../../../services/EventService";
import SupplierBankStatementModal from "../Modals/SupplierBankStatementModal";
import PayableAgingReportModal from "../Modals/PayableAgingReportModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Badge from '@mui/material/Badge';
import FinancialAdditonalDocuments from "../Modals/FinancialAdditonalDocuments";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FileViewerModal from "../FileViewerModal/FileViewerModal";
import QuestionnaireModal from "../Modals/QuestionnaireModal";
import { compareRole, enumRole } from "../../../configs/GlobalConfig";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
const { getCode, getName } = require('country-list');
import { getAgingReportDownload } from "../../../services/reportUtils";

import { supported_languages } from "../../../services/languages";
import { changeLanguage } from "../../../services/localizationService";
import { convertUTF8String, getFileName } from "../../../services/ConversionService.js";
import { isAdminForManagedUser } from "../../../services/userImpersonationService";
import FileMetaDataPopover from "../PopOver/FileMetaDataPopover.js";
const numberMask = createNumberMask({
  prefix: "$",
  suffix: "",
  allowDecimal: true
});
const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});
var AgingReportPostdata = {};
class Profile extends Component {
  api = new API();
  user = new USER();
  _timer;
  _jobUUID;
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      invoices: [],
      KPIInfo: {
        chartData: null,
        reportAsOn: null,
        avgCollectionPeriod: null,
        paymentTerm: "NET 30",
        errlbl: ""
      },
      erpUserInfo: {
        tenantId: "",
        clientId: "",
        secret: "",
        userName: "",
        erppassword: "",
        hostName: "",
        businessEntityID: "",
        isValid: false
      },
      isERPInfoAvailable: false,
      isLoading: false,
      isImporting: false,
      isSavedERP: false,
      isSavedAdvInfo: false,
      isUploadingDocument: false,
      isUpdatingPassword: false,
      password: "",
      newPassword: "",
      isFile: false,
      flagBalSta: false,
      flagIncSta: false,
      flagCasSta: false,
      pulleddate: "",
      currentPathlocation: "",
      previousPathLocation: "",
      currentTab: "",
      isSaveProfileInformation: true,
      isSaveFinInformation: true,
      financeDocs: {
        balanceSheet: {
          fileName: "",
          downloadUrl: ""
        },
        incomeStmt: {
          fileName: "",
          downloadUrl: ""
        },
        cashflow: {
          fileName: "",
          downloadUrl: ""
        },
        agingfile: {
          fileName: "",
          downloadUrl: ""
        },
        payableAgingFile: {
          fileName: "",
          downloadUrl: ""
        }
      },
      balanceStatement: {
        balanceStmt_1: {
          fileName: "",
          downloadUrl: ""
        },
        balanceStmt_2: {
          fileName: "",
          downloadUrl: ""
        },
        balanceStmt_3: {
          fileName: "",
          downloadUrl: ""
        }
      },
      isShowERPPassword: false,
      isShowOldPassword: false,
      isShowNewPassword: false,
      isFinancialHistoryModal: false,
      isAgingReportModal: false,
      isQBURLTextBox: false,
      qbURL: "",
      isUpdateQBURL: false,
      isQBAccessToken: false,
      isD365BCAccessToken: false,
      profilespinnerMsg: "",
      qbInfo: {},
      isBankStatementModal: false,
      receivablesAging: {},
      payablesAging: {},
      bankTransactionHistory: {},
      isPayableAgingReportModal: false,
      isQuickBookBtnEnabled: false,
      isSaveERPBtnEnabled: false,
      allBankStatements: [],
      iswebsiteUrlValid: true,
      istenantIDValid: true,
      isclientIDValid: true,
      isHostname: true,
      isbusinessEntityID: true,
      isSecretValue: true,
      isTaxIdValid: true,
      businessCentralUserInfo: {
        clientId: "",
        clientsecret: "",
        tenantdomain: "",
        accesstoken: "",
        redirecturi: "",
        company: "",
        environment: "",
        accountaccountreceivablesname: [],
        accountaccountpayablesname: [],
        accountinventoryname: []
      },
      chartOfAccounts: [],
      isConnectD365BCFields: false,
      isD365BCAdditionalfields: false,
      isUpdatedAccountingInformation: false,
      isBusinessValid: true,
      isBusinessAddressValid: true,
      isBusinessCityValid: true,
      isAdditonalDocumentsModal: false,
      allAdditionalDocuments: [],
      selectedCountryCode: "",
      isFileViewerModal: false,
      fileViewerContent: null,
      questionnaire: {
        estimatedFinancingVolume: "",
        funds: "",
        frequency: "",
        otherfrequency: "",
        invoiceAndPaymentProcess: "",
        isExistingLoan: "",
        existingDetails: {
          nameOfCommercialFinacne: "",
          loanAmount: "",
          maturity: ""
        },
        isBankruptcy: "",
        bankruptcyDetails: {

        }
      },
      isQuestionnaireModal: false
    };
    this.saveProfile = this.saveProfile.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getV2Profile = this.getV2Profile.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleDollarChangeField = this.handleDollarChangeField.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleERPInfoChangeField = this.handleERPInfoChangeField.bind(this);
    this.saveAndUpdapteERPInfo = this.saveAndUpdapteERPInfo.bind(this);
    this.uploadFinancialHistory = this.uploadFinancialHistory.bind(this);
    this.uploadAgingReport = this.uploadAgingReport.bind(this);
    this.checkIfFinancialDocExists = this.checkIfFinancialDocExists.bind(this);
    this.getSupplierDocs = this.getSupplierDocs.bind(this);
    this.getBalanceStatement = this.getBalanceStatement.bind(this);
    this.getIncomeStatement = this.getIncomeStatement.bind(this);
    this.getCashFlowStatement = this.getCashFlowStatement.bind(this);
    this.getQuickBooksURL = this.getQuickBooksURL.bind(this);
    this.validateField = this.validateField.bind(this);
  }
  async getBalanceStatement(fileName, fileURL) {
    fetch(fileURL, {
      "method": "GET",
      "mode": "cors",
      "credentials": "include"
    })
      .then(r => r.blob())
      .then(b => {
        let file = new File([b], fileName);
        console.log("balance test", file);
        this.setState({ fileToUpload: file });
      });
  }
  async getIncomeStatement(fileName, fileURL) {
    fetch(fileURL, {
      "method": "GET",
      "mode": "cors",
      "credentials": "include"
    })
      .then(r => r.blob())
      .then(b => {
        let file = new File([b], fileName);
        console.log("balance test", file);
        this.setState({ fileToUpload1: file });
      });
  }
  async getCashFlowStatement(fileName, fileURL) {
    fetch(fileURL, {
      "method": "GET",
      "mode": "cors",
      "credentials": "include"
    })
      .then(r => r.blob())
      .then(b => {
        let file = new File([b], fileName);
        console.log("balance test", file);
        this.setState({ fileToUpload2: file });
      });
  }



  async getSupplierDocs() {
    const res = await this.api.getSupplierDocs();
    if (res.items && res.items.length > 0) {
      const { financeDocs, balanceStatement } = this.state;
      let items = res.items.filter(
        x => x.financialDocumentType === "BALANCE_SHEET"
      );
      if (items.length > 0) {
        financeDocs.balanceSheet.fileName = items[0].filename;
        financeDocs.balanceSheet.downloadUrl = items[0].downloadUrl;
        financeDocs.balanceSheet.uploadDate = items[0].createdDate;
        financeDocs.balanceSheet.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.balanceSheet.userDetails = items[0].userDetails;
        // this.setState({ fileToUpload: financeDocs.balanceSheet });
        if (items[0].source !== "QBO") {
          this.getBalanceStatement(items[0].filename, items[0].downloadUrl);
        }
      }
      items = res.items.filter(
        x => x.financialDocumentType === "INCOME_STATEMENT"
      );
      if (items.length > 0) {
        financeDocs.incomeStmt.fileName = items[0].filename;
        financeDocs.incomeStmt.downloadUrl = items[0].downloadUrl;
        financeDocs.incomeStmt.uploadDate = items[0].createdDate;
        financeDocs.incomeStmt.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.incomeStmt.userDetails = items[0].userDetails;
        if (items[0].source !== "QBO") {
          this.getIncomeStatement(items[0].filename, items[0].downloadUrl);
        }
      }
      items = res.items.filter(x => x.financialDocumentType === "CASH_FLOW");
      if (items.length > 0) {
        financeDocs.cashflow.fileName = items[0].filename;
        financeDocs.cashflow.downloadUrl = items[0].downloadUrl;
        financeDocs.cashflow.uploadDate = items[0].createdDate;
        financeDocs.cashflow.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.cashflow.userDetails = items[0].userDetails;
        if (items[0].source !== "QBO") {
          this.getCashFlowStatement(items[0].filename, items[0].downloadUrl);
        }

      }
      items = res.items.filter(x => x.financialDocumentType === "AGING_REPORT");
      if (items.length > 0) {
        financeDocs.agingfile.fileName = items[0].filename;
        financeDocs.agingfile.downloadUrl = items[0].downloadUrl;
        financeDocs.agingfile.uploadDate = items[0].createdDate;
        financeDocs.agingfile.pulledDate = items[0].pulledDate;
        financeDocs.agingfile.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.agingfile.userDetails = items[0].userDetails;
      }
      items = res.items.filter(x => x.financialDocumentType === "PAYABLE_AGING_REPORT");
      if (items.length > 0) {
        financeDocs.payableAgingFile.fileName = items[0].filename;
        financeDocs.payableAgingFile.downloadUrl = items[0].downloadUrl;
        financeDocs.payableAgingFile.uploadDate = items[0].createdDate;
        financeDocs.payableAgingFile.pulledDate = items[0].pulledDate;
        financeDocs.payableAgingFile.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        financeDocs.payableAgingFile.userDetails = items[0].userDetails;
      }
      this.setState({ financeDocs });
      items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "bankstatement");
      this.setState({ allBankStatements: items });
      items = res.items.filter(x => x.financialDocumentType === "BANK_STATEMENT_1");
      if (items.length > 0) {
        balanceStatement.balanceStmt_1.fileName = items[0].filename;
        balanceStatement.balanceStmt_1.downloadUrl = items[0].downloadUrl;
        balanceStatement.balanceStmt_1.uploadDate = items[0].createdDate;
        balanceStatement.balanceStmt_1.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        balanceStatement.balanceStmt_1.userDetails = items[0].userDetails;
      }
      items = res.items.filter(x => x.financialDocumentType === "BANK_STATEMENT_2");
      if (items.length > 0) {
        balanceStatement.balanceStmt_2.fileName = items[0].filename;
        balanceStatement.balanceStmt_2.downloadUrl = items[0].downloadUrl;
        balanceStatement.balanceStmt_2.uploadDate = items[0].createdDate;
        balanceStatement.balanceStmt_2.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        balanceStatement.balanceStmt_2.userDetails = items[0].userDetails;
      }
      items = res.items.filter(x => x.financialDocumentType === "BANK_STATEMENT_3");
      if (items.length > 0) {
        balanceStatement.balanceStmt_3.fileName = items[0].filename;
        balanceStatement.balanceStmt_3.downloadUrl = items[0].downloadUrl;
        balanceStatement.balanceStmt_3.uploadDate = items[0].createdDate;
        balanceStatement.balanceStmt_3.isUploadedByRepresentative = items[0].isUploadedByRepresentative;
        balanceStatement.balanceStmt_3.userDetails = items[0].userDetails;
      }
      this.setState({ balanceStatement: balanceStatement });
      items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
      this.setState({ allAdditionalDocuments: items });
    }
  }

  componentDidMount() {
    if (this.props.match.params.tab) {
      this.setState({ currentTab: this.props.match.params.tab });
    }
    else {
      this.setState({ currentTab: "businessInformation" });
    }
    document.title = `Profile - LedgerFunding ${this.user.currentUserRole()}`;
    const { appState, location } = this.props;
    const currentPathLocation = location.pathname.replace(/\//g, "");
    const previousPathLocation = location.state ? location.state.prevPath : "";
    this.setState({ currentPathlocation: currentPathLocation, previousPathLocation: previousPathLocation });
    this.getProfile();
    //TO-DO: Check supplier docs method instead calling this method
    this.checkIfDocExists();
    if (this.props.appState.state.currentUserRole != "funder") {
      this.getSupplierDocs();
    }
  }

  getBuyerCount(invoices) {
    let buyersList = [];
    if (invoices) {
      invoices.forEach(inv => {
        if (buyersList.indexOf(inv.buyer.name) === -1) {
          buyersList.push(inv.buyer.name);
        }
      });
    }
    return buyersList.length;
  }

  async checkIfDocExists() {
    try {
      const isDocResponse = await this.checkIfFinancialDocExists();
      this.setState({ isFile: isDocResponse.fileExists });
    } catch (error) {
      this.setState({ isFile: false });
    }
  }

  async checkIfFinancialDocExists() {
    if (this.props.appState.state.currentUserRole === "supplier" || this.props.appState.state.currentUserRole === "buyer") {
      //return await this.api.checkIfDocumentationExists();
      // TO Do: Need to call V2 API
      return await this.api.checkV2IfDocumentationExists();
    } else {
      return await this.api.checkIfDocumentationExists();
    }
  }


  async getERPInfo(email) {
    const { erpUserInfo } = this.state;
    try {
      const ERPInfo = await this.api.getErpInfo(email);
      if (ERPInfo) {
        erpUserInfo.businessEntityID = ERPInfo.businessEntityID;
        erpUserInfo.tenantId = ERPInfo.tenantId;
        erpUserInfo.clientId = ERPInfo.clientId;
        erpUserInfo.secret = ERPInfo.secret;
        erpUserInfo.userName = ERPInfo.userName;
        erpUserInfo.erppassword = ERPInfo.password;
        erpUserInfo.hostName = ERPInfo.hostName;
        this.setState({ erpUserInfo });
        this.setState({ isERPInfoAvailable: true });
      } else {
        this.setState({ isERPInfoAvailable: false });
      }
    } catch (error) {
      this.setState({ erpUserInfo });
    }
  }

  handleChangeField(event) {
    event.preventDefault();
    const { user } = this.state;
    const name = event.target.name;
    const value = event.target.value;
    user[name] = value;
    this.setState({ user }, () => {
      this.validateField(name, value);
    });
  }

  questionnarieHandleChange = (event) => {
    const { questionnaire } = this.state;
    const name = event.target.name;
    const value = event.target.value;
    questionnaire[name] = value;
    this.setState({ questionnaire: questionnaire });
  }

  validateField(fieldName, value) {

    let websiteUrlValid = this.state.iswebsiteUrlValid;
    let tenantIDValid = this.state.istenantIDValid;
    let businessNameValid = this.state.isBusinessValid;
    let businessAddressValid = this.state.isBusinessAddressValid;
    let businessCityValid = this.state.isBusinessCityValid;
    let country = this.state.user.country;

    switch (fieldName) {

      case "name":
        var res = value.trim().match("^(?![0-9]+$)[A-Za-z0-9? ,_'-]+$");
        businessNameValid = res !== null;
        let businessName = businessNameValid ? true : false;
        this.setState(
          {
            isBusinessValid: businessName,
            isSaveProfileInformation: businessName ? false : true
          }
        );

        break;

      case "address":
        var res = value.trim().match("^(?![0-9]+$)[A-Za-z0-9? ,_-]+$");
        businessAddressValid = res !== null;
        let businessAddress = businessAddressValid ? true : false;
        this.setState(
          {
            isBusinessAddressValid: businessAddress,
            isSaveProfileInformation: businessAddress ? false : true
          }
        );

        break;
      case "city":
        var res = value.trim().match("^(?![0-9]+$)[A-Za-z0-9? ,_-]+$");
        businessCityValid = res !== null;
        let businessCity = businessCityValid ? true : false;
        this.setState(
          {
            isBusinessCityValid: businessCity,
            isSaveProfileInformation: businessCity ? false : true
          }
        );

        break;

      // case "websiteUrl":
      //   var res = value.match(/^(https?:\/\/)?([a-z0-9\-]+\.)+[a-z]{2,}(\/.*)?$/i);
      //   websiteUrlValid = res !== null;
      //   let strwebsiteUrl = websiteUrlValid ? true : false;
      //   this.setState(
      //     {
      //       iswebsiteUrlValid: strwebsiteUrl,
      //       isSaveProfileInformation: strwebsiteUrl ? false : true
      //     }
      //   );

      //   break;

      case "taxId":
        var res = value;
        let _strTaxID = false;
        if (country == "United States" || country == "United States of America") {
          if (value.length > 0) {
            value = res.replace(/[^0-9.]/g, "");
          }
          _strTaxID = (res !== null && value.length == 9) ? true : false;
        }
        else {
          if (value.length > 0) {
            _strTaxID = (res !== null && value.length >= 5) ? true : false;
          }
        }

        this.setState(
          {
            isTaxIdValid: _strTaxID,
            isSaveFinInformation: _strTaxID ? false : true
          }
        );

        break;

      default:
        break;
    }

  }


  onDrop(documents) {
    this.setState({ isUploadingDocument: true });
    this.setState(
      {
        fileToUpload: documents[0]
      },
      () => this.uploadDocument()
    );
  }


  // Gets currency code
  getCurrencyCode(columns, data) {
    let invObj = {};
    for (let i in columns) {
      switch (columns[i]) {
        case "Currency Code":
          invObj["Currency Code"] = data[i];
          break;

      }
    }
    return invObj["Currency Code"];
  }


  async getProfile() {
    const profileResponse = await this.getV2Profile();
    let selectedCountryCode_ = "";
    if (profileResponse && profileResponse.user) {
      if(!profileResponse.user.language){
        profileResponse.user.language="en";
        changeLanguage("en");
      }
      else{
        changeLanguage(profileResponse.user.language);
      }
      let allCountryDetails_ = allCountryDetails();
      if (allCountryDetails_.length > 0) {
        allCountryDetails_.forEach(country_ => {
          if (profileResponse.user.country.length == 2) {
            selectedCountryCode_ = profileResponse.user.country;
          }
          else if (country_.countryName == profileResponse.user.country) {
            selectedCountryCode_ = country_.countryShortCode;
          }
        })
      }
    }
    //let filteredPhoneFormat = countryCallingCode.filter((e) => e.code.toLocaleLowerCase() == selectedCountryCode_.toLocaleLowerCase());
    //profileResponse.user.phone=profileResponse.user.phone.slice(filteredPhoneFormat[0].dial_code.length);
    if (profileResponse.user.invoiceAgingReport && profileResponse.user.invoiceAgingReport.invoices && profileResponse.user.invoiceAgingReport.invoices.length > 0) {
      this.setState({ user: profileResponse.user, receivablesAging: profileResponse.user.invoiceAgingReport, selectedCountryCode: selectedCountryCode_.toLocaleLowerCase() });
    } else {
      this.setState({ user: profileResponse.user, selectedCountryCode: selectedCountryCode_.toLocaleLowerCase() });
    }
    if (profileResponse.user.payableAgingReport && profileResponse.user.payableAgingReport.Payables && profileResponse.user.payableAgingReport.Payables.length > 0) {
      this.setState({ payablesAging: profileResponse.user.payableAgingReport });
    }
    if (profileResponse.user.accountingInformation == "quickbooks") {
      if (profileResponse.user.payableAgingReport && profileResponse.user.payableAgingReport.Payables && profileResponse.user.payableAgingReport.Payables.length > 0) {
        this.setState({ payablesAging: profileResponse.user.payableAgingReport });
      }
      await this.getQBInfo(profileResponse.user.email);
      const res = await this.api.getsupbanktransactionhistory(profileResponse.user._id);
      this.setState({ bankTransactionHistory: res, isQuickBookBtnEnabled: true });
    }
    else if (profileResponse.user.accountingInformation == "D365BC") {
      this.setState({ isUpdatedAccountingInformation: true });
      if (profileResponse.user.payableAgingReport && profileResponse.user.payableAgingReport.Payables && profileResponse.user.payableAgingReport.Payables.length > 0) {
        this.setState({ payablesAging: profileResponse.user.payableAgingReport });
      }
      await this.getD365BusinessCentralDetails();
    }
    else if (profileResponse.user.accountingInformation == "erpinfo") {
      this.getERPInfo(profileResponse.user.email);
    }
  }
  getQBInfo = async (email_) => {
    const qbInfo = await this.api.gettokenfromQB(email_);
    this.setState({ qbInfo: qbInfo });
  }

  async getD365BusinessCentralDetails() {
    let chartOfAccounts_ = [];
    let isConnectD365BCFields_ = false;
    const businessCentralInfo_ = await this.api.getD365BCDetails(this.state.user.email);
    this.setState({ businessCentralUserInfo: businessCentralInfo_ });
    if (businessCentralInfo_.accesstoken) {
      isConnectD365BCFields_ = true;
      this.setState({ isConnectD365BCFields: isConnectD365BCFields_ });
      chartOfAccounts_ = await this.api.Getd365bcchartofaccounts(this.state.user.email);
    }
    chartOfAccounts_ = chartOfAccounts_.map((acc, i) => ({ value: i, text: acc.display }))
      .sort((a, b) => a.text > b.text ? 1 : -1);
    this.setState({ chartOfAccounts: chartOfAccounts_, isConnectD365BCFields: isConnectD365BCFields_ });
  }

  async getV2Profile() {
    if (this.props.appState.state.currentUserRole != "funder") {
      return await this.api.getV2Profile();
    } else {
      return await this.api.getProfile();
    }
  }

  async uploadFinancialHistory() {
    const { fileToUpload, fileToUpload1, fileToUpload2 } = this.state;
    this.setState({ isUploadingDocument: true });
    const documentData = new FormData();
    documentData.append("BALANCE_SHEET", fileToUpload);
    documentData.append("INCOME_STATEMENT", fileToUpload1);
    documentData.append("CASH_FLOW", fileToUpload2);
    await this.api.financialDocHistoryUpload(documentData);
    this.setState(
      {
        fileToUpload: null,
        fileToUpload1: null,
        fileToUpload2: null,
        fileToUpload3: null,
        flagBalSta: false,
        flagCasSta: false,
        flagIncSta: false,
        isUploadingDocument: false
      },
      () => {
        this.checkIfDocExists();
        this.getSupplierDocs();
      }
    );
  }
  async uploadAgingReport() {
    const { fileToUpload3 } = this.state;
    this.setState({ isUploadingDocument: true });
    const documentData = new FormData();
    documentData.append("AGING_REPORT", fileToUpload3);
    await this.api.financialAgingReportUpload(documentData);
    await this.api.submitAgingReportToLF(AgingReportPostdata);
    this.setState(
      {
        isUploadingDocument: false
      },
      () => {
        this.checkIfDocExists();
        this.getSupplierDocs();
      }
    );
  }


  async uploadDocument() {
    const { fileToUpload } = this.state;
    const documentData = new FormData();
    documentData.append("documents", fileToUpload);
    await this.api.uploadDocument(documentData);
    this.setState(
      {
        isUploadingDocument: false
      },
      () => this.checkIfDocExists()
    );
  }

  async saveProfile(profileSpinnerLabel_) {
    const {
      user: {
        name,
        address,
        city,
        state,
        country,
        zip,
        phone,
        year,
        taxId,
        entity,
        industry,
        balance,
        revenue,
        accountingInformation,
        websiteUrl,
        userName,
        title,
        language

      }
    } = this.state;
    const userToSave = {
      name,
      address,
      city,
      state,
      zip,
      country,
      phone,
      year,
      entity,
      industry,
      taxId,
      balance,
      revenue,
      accountingInformation,
      websiteUrl,
      userName,
      title,
      language
    };
    this.setState({ isLoading: true, profilespinnerMsg: profileSpinnerLabel_ });
    if (this.props.appState.state.currentUserRole === "supplier") {
      const v2Profile = {
        name: userToSave.name,
        address: {
          address1: userToSave.address,
          address2: "",
          city: userToSave.city,
          state: userToSave.state,
          country: userToSave.country,
          zip: userToSave.zip
        },
        phone: userToSave.phone,
        incorporationYear: userToSave.year,
        entity: userToSave.entity,
        industry: userToSave.industry,
        accountingInformation: userToSave.accountingInformation,
        websiteUrl: websiteUrl,
        userName: userToSave.userName,
        title: userToSave.title,
        language:userToSave.language
      };
      await this.api.updateV2Profile(v2Profile);
    } else {
      await this.api.updateProfile(userToSave);
    }
    //Update business name state properties globally
    let newUserValue;
    try {
      let currentUservalue = localStorage.getItem("LEDGER_FUNDING_CURRENT_USER");
      newUserValue = JSON.parse(currentUservalue) || {};
    } catch (error) {
      console.error("Failed to parse current user value from localStorage:", error);
      newUserValue = {};
    }
    newUserValue.name = name;
    localStorage.setItem("LEDGER_FUNDING_CURRENT_USER", JSON.stringify(newUserValue));
    this.props.appState.setState("currentUser", newUserValue);
    //........
    this.setState({ isLoading: false });
    if (this.state.user.accountingInformation == "quickbooks") {
      this.setState({ isQuickBookBtnEnabled: true, isSaveProfileInformation: true, isSaveERPBtnEnabled: false });
      await this.getQBInfo(this.state.user.email);
    }
    else if (this.state.user.accountingInformation == "erpinfo") {
      this.setState({ isQuickBookBtnEnabled: false, isSaveProfileInformation: true });
    }
    else if (this.state.user.accountingInformation == "D365BC") {
      this.setState({ isUpdatedAccountingInformation: true, isSaveProfileInformation: true });
    }
    else {
      this.setState({ isSaveProfileInformation: true });
    }
  }

  async changePassword(e) {
    e.preventDefault();
    const { password, newPassword } = this.state;
    if (
      !newPassword.match(passwordRegexString)
    ) {
      let _errMsg="";
      if(newPassword.length>=8){
        if (!newPassword.match(/^(?=.*?[aA-zZ])/)) {
          _errMsg =this.props.t("password_criterias.alphanumeric_validation");
        }
        else if (!newPassword.match(/^(?=.*?[0-9])/)) {
          _errMsg = this.props.t("password_criterias.numeric_validation");
        }
      }
      else{
        _errMsg = this.props.t("password_criterias.alphanumeric_validation");
      }
      this.setState({errorMsg:_errMsg});
      return false;
    } else {
      this.setState({ errorMsg: "" });
    }

    const passwordToUpdate = {
      password,
      newPassword,
      confirmPassword: newPassword
    };
    if (this.props.appState.state.currentUserRole === "supplier") {
      let pwdChangeResp = await this.api.v2ChangePassword(passwordToUpdate);
      console.log(pwdChangeResp);
      if (pwdChangeResp.message && pwdChangeResp.message !== "") {
          switch(pwdChangeResp.message){
            case "Invalid old password":
                pwdChangeResp.message=this.props.t("invalid_old_password");
            case "New password cannot be same as old password":
               pwdChangeResp.message=this.props.t("password_compare_error");
            default:
              pwdChangeResp.message=pwdChangeResp.message;
          }
        AddModal(this.props.t("invalid_action_title"), pwdChangeResp.message, null, false);
      }
      else {
        this.setState({ isUpdatingPassword: true });
        setTimeout(() => {
          window.location.href = `/${this.user.currentUserRole()}`;
          this.user.logOut();
        }, 2000);
      }
    } else {
      let pwdChangeResp = await this.api.changePassword(passwordToUpdate);
      console.log(pwdChangeResp);
      if (pwdChangeResp.message && pwdChangeResp.message !== "") {
        switch(pwdChangeResp.message){
          case "Invalid old password":
              pwdChangeResp.message=this.props.t("invalid_old_password");
          case "New password cannot be same as old password":
             pwdChangeResp.message=this.props.t("password_compare_error");
          default:
            pwdChangeResp.message=pwdChangeResp.message;
        }
        AddModal(this.props.t("invalid_action_title"), pwdChangeResp.message, null, false);
      }
      else {
        this.setState({ isUpdatingPassword: true });
        setTimeout(() => {
          window.location.href = `/${this.user.currentUserRole()}`;
          this.user.logOut();
        }, 2000);
      }
    }

    this.setState({ isUpdatingPassword: false });
  }
  //here saving Erp Info
  saveErpInfo = async e => {
    e.preventDefault();
    this.setState({ isSavedERP: true });
    const responseText = await this.saveAndUpdapteERPInfo();
  };
  async notify(message) {
    toast.info(message);
  }

  //here saving erp info
  saveAndUpdapteERPInfo = async () => {
    const { erpUserInfo, isERPInfoAvailable } = this.state;
    erpUserInfo.password = erpUserInfo.erppassword;
    erpUserInfo.hostName = erpUserInfo.hostName.replace(/^https?:\/\//, '').replace(/\/+$/, '');
    erpUserInfo.isValid = (this.state.pulleddate == "") ? false : true;
    //delete erpUserInfo.erppassword;
    const { appState } = this.props;
    ///once get from Text inputs calling
    const erpValue = await this.api.saveErpInfo(
      appState.state.currentUser.email,
      erpUserInfo
    );
    if (erpValue.message && erpValue.message == "Enter Valid ERP Info") {
      this.setState({ isSavedERP: false, erpMessage: this.props.t("profile_page.accounting_system_content.enter_valid_erp_msg") });
      AddModal(this.props.t("profile_page.accounting_system_content.invalid_erp_title"), this.props.t("profile_page.accounting_system_content.valid_erp_msg"), null, false);
    }
    else {
      // this.api.runErpBackgroundJobs();
      clearInterval(this._timer);
      this._jobUUID = UUID();
      this.api.runErpBackgroundJobs(this._jobUUID);
      localStorage.setItem("BackGround_Job_ID", this._jobUUID);
      localStorage.setItem("UI_JOB", true);
    }
    this.setState({ isSavedERP: false, isSaveERPBtnEnabled: false });
  };

  saveAdvancedInfo = async e => {
    e.preventDefault();
    const {
      user: {
        name,
        address,
        city,
        state,
        country,
        zip,
        phone,
        year,
        taxId,
        entity,
        industry,
        balance,
        revenue,
        accountingInformation,
        websiteUrl,
        userName,
        title,
        language

      }
    } = this.state;
    this.setState({ isSavedAdvInfo: true });
    if (this.props.appState.state.currentUserRole === "supplier") {
      let advInfo = {
        supplierIdInErp: this.state.user.name,
        taxId: this.state.user.taxId,
        annualRevenue: this.state.user && this.state.user.revenue ? parseFloat((this.state.user.revenue).toFixed(2)) : 0,
        averageBankBalance: this.state.user.balance
      };
      const responseText = await this.api.saveAdvancedInfo(advInfo);
      this.setState({ isSavedAdvInfo: false });
    }
    else if (this.props.appState.state.currentUserRole == "buyer") {
      const userToSave = {
        name,
        address,
        city,
        state,
        zip,
        country,
        phone,
        year,
        entity,
        industry,
        taxId,
        balance,
        revenue,
        accountingInformation,
        websiteUrl,
        userName,
        title,
        language
      };
      await this.api.updateProfile(userToSave);
      this.setState({ isSavedAdvInfo: false });
    }
  };

  saveAdditionalInfo = async e => { };

  downloadFile() {
    window.location.assign(`${this.api.currentUserEndpoint()} / file / `);
  }

  handleChangeField(event) {
    event.preventDefault();
    const { user } = this.state;
    const name = event.target.name;
    const value = event.target.value;
    user[name] = value;
    this.setState({ user }, () => {
      this.validateField(name, value);
    });
    this.setState({ isSaveProfileInformation: false });
  }

  handlePhoneChangeField = (val, data, event, formattedValue) => {
    const { user } = this.state;
    user["phone"] = formattedValue;
    let _phoneValue = user.phone ? user.phone : "";
    let filteredPhoneFormat = countryCallingCode.filter((e) => e.code.toLocaleLowerCase() == data.countryCode);
    if (filteredPhoneFormat && filteredPhoneFormat.length > 0) {
      _phoneValue = _phoneValue.replace(filteredPhoneFormat[0].dial_code, "");
      if (_phoneValue.length > 0) {
        this.setState({ user, isSaveProfileInformation: false });
      }
      else {
        this.setState({ user, isSaveProfileInformation: true });
      }
    }
  };

  handleCountryChange(val) {
    const { user } = this.state;
    user["country"] = val;
    user["phone"] = "";
    user["city"]="";
    let selectedCountryCode_ = "";
    let allCountryDetails_ = allCountryDetails();
    if (allCountryDetails_.length > 0) {
      allCountryDetails_.forEach(country_ => {
        if (val.length == 2) {
          selectedCountryCode_ = val;
        }
        else if (country_.countryName == val) {
          selectedCountryCode_ = country_.countryShortCode;
        }
      })
    }
    this.setState({
      user,
      selectedCountryCode: selectedCountryCode_.toLocaleLowerCase()
    })
  }

  handleStateChange(val) {
    const { user } = this.state;
    user["state"] = val;
    user["zip"] ="";
    this.setState({ user });
    this.setState({ isSaveProfileInformation: false });
  }

  handleDollarChangeField(fieldName, amount) {
    const { user } = this.state;
    user[fieldName] = amount;
    this.setState({ user });
    this.setState({ isSaveProfileInformation: false });
    if (fieldName == "revenue") {
      if (this.state.isTaxIdValid) {
        this.setState({ isSaveFinInformation: false });
      }
    }
  }

  handleERPInfoChangeField(fieldName, value) {
    const { erpUserInfo } = this.state;
    let [strtenantId, strclientId, strhostName, strbusinessEntity, strSecretValue] = [true, true, true, true, true];
    erpUserInfo[fieldName] = value;
    switch (fieldName) {
      case "tenantId":
        var res = value.match("^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$");
        strtenantId = (res !== null && value.length == 36) ? true : false;
        this.setState({ istenantIDValid: strtenantId });
        break;

      case "clientId":
        var res = value.match("^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$");
        strclientId = (res !== null && value.length == 36) ? true : false;
        this.setState({ isclientIDValid: strclientId });
        break;

      case "hostName":
        var res = value.match("[a-z0-9]+[\\.]{1}[a-z0-9]+[\\.]{1}[a-z0-9]+[\\.]{1}[a-z0-9]+");
        strhostName = (res !== null && value.length != 0) ? true : false;
        this.setState({ isHostname: strhostName });
        break;

      case "businessEntityID":
        var res = value;
        strbusinessEntity = (res !== null && value.length != 0) ? true : false;
        this.setState({ isbusinessEntityID: strbusinessEntity });
        break;

      case "secret":
        var res = value;
        strSecretValue = (res !== null && value.length != 0) ? true : false;
        this.setState({ isSecretValue: strSecretValue });
        break;

      default:
        break;
    }
    if (erpUserInfo.tenantId && erpUserInfo.businessEntityID && erpUserInfo.clientId && erpUserInfo.secret && erpUserInfo.hostName) {
      if (strtenantId && strclientId && strhostName && strbusinessEntity && strSecretValue) {
        this.setState({ erpUserInfo, isSaveERPBtnEnabled: true });
      }
      else {
        this.setState({ erpUserInfo, isSaveERPBtnEnabled: false });
      }
    }
  }

  handled365bcInfoChangeField = (fieldName, value) => {
    const { businessCentralUserInfo } = this.state;
    businessCentralUserInfo[fieldName] = value;
    const bC_ = businessCentralUserInfo;
    if (bC_.clientId && bC_.clientsecret && bC_.tenantdomain && bC_.company && bC_.environment) {
      this.setState({ businessCentralUserInfo: businessCentralUserInfo, isConnectD365BCFields: true });
    }
    this.setState({ businessCentralUserInfo });
  };

  async getQuickBooksURL() {
    const { user: { email, _id } } = this.state;
    let qburl;
    let redirecturl_ = "";
    qburl = await this.api.GetQBloginurl();
    var left = (window.innerWidth - 800) / 2;
    var top = (window.innerHeight - 800) / 2;
    let qbWindow = window.open(qburl, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=" + top + ",left=" + left + ",width=800,height=800");
    window.tempWindow = qbWindow;
    //Uncomment to connect qbo
    // this.setState({isQBURLTextBox:true});

    this.setState({ isQBAccessToken: true });

    EventService.subscribe("message", (payload_) => {
      if (payload_) {
        this.api.getQBAccessToken(payload_, this.state.user.email).then(() => {
          qbWindow.close();
          this.setState(
            { isQBAccessToken: false },
            () => {
              this.getQBInfo(email);
              clearInterval(this._timer);
              this._jobUUID = UUID();
              this.api.runErpBackgroundJobs(this._jobUUID);
              localStorage.setItem("BackGround_Job_ID", this._jobUUID);
              localStorage.setItem("UI_JOB", true);
            });
        });
      }
    });
  }

  async saveBusinessCentralInfo() {
    let { user: { email, _id }, businessCentralUserInfo, isUpdatedAccountingInformation } = this.state;
    if (!isUpdatedAccountingInformation) {
      return AddModal(this.props.t("profile_page.accounting_system_content.update_accounting_system_button_label"), <div>{this.props.t("profile_page.accounting_system_content.d365bc.upadate_msg")}</div>, null, false);
    }
    let qburl;
    businessCentralUserInfo["EmailID"] = email;
    businessCentralUserInfo.redirecturi = `${process.env.REACT_APP_UI_URL}/d365bc-signin-response`;
    // businessCentralUserInfo.redirecturi=`http://localhost:3000/d365bc-signin-response`;
    const savebusinessCentralInfo_ = await this.api.saveBusinessCentralInfo(businessCentralUserInfo);
    if (savebusinessCentralInfo_ == "success") {
      qburl = await this.api.getD365BCUrl(email);
      var left = (window.innerWidth - 800) / 2;
      var top = (window.innerHeight - 800) / 2;
      let qbWindow = window.open(qburl, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=" + top + ",left=" + left + ",width=800,height=800");
      window.tempWindow = qbWindow;
      // this.setState({ isD365BCAccessToken: true });
      EventService.subscribe("message", (payload_) => {
        if (payload_) {
          this.api.getBusinessCentralAccessToken(payload_, this.state.user.email).then((d) => {
            qbWindow.close();
            //this.getD365BusinessCentralDetails();

            this.getD365BusinessCentralDetails().then((d) => {
              // this.setState({ isD365BCAccessToken: false });
              let { businessCentralUserInfo } = this.state;
              if (businessCentralUserInfo.accountaccountpayablesname.length == 0 &&
                businessCentralUserInfo.accountaccountreceivablesname.length == 0 &&
                businessCentralUserInfo.accountinventoryname.length == 0) {
                AddModal(this.props.t("profile_page.accounting_system_content.d365bc.additional_config_title"), this.props.t("profile_page.accounting_system_content.d365bc.additional_config_msg"), null, false);
              }
              else {
                if (businessCentralUserInfo.accountaccountpayablesname.length > 0 &&
                  businessCentralUserInfo.accountaccountreceivablesname.length > 0 &&
                  businessCentralUserInfo.accountinventoryname.length > 0) {
                  this.setState({ isD365BCAccessToken: false, isD365BCAdditionalfields: false });
                  clearInterval(this._timer);
                  this._jobUUID = UUID();
                  this.api.runErpBackgroundJobs(this._jobUUID);
                  localStorage.setItem("BackGround_Job_ID", this._jobUUID);
                  localStorage.setItem("UI_JOB", true);
                }

              }
            });
          });
        }
      });
    }
  }

  async saveD365BCOtherDetails() {
    let { user: { email, _id }, businessCentralUserInfo } = this.state;
    this.setState({ isD365BCAccessToken: true });
    const accountaccountpayablesname_ = businessCentralUserInfo.accountaccountpayablesname.map(a => a.text);
    const accountaccountreceivablesname_ = businessCentralUserInfo.accountaccountreceivablesname.map(a => a.text);
    const accountinventoryname_ = businessCentralUserInfo.accountinventoryname.map(a => a.text);
    businessCentralUserInfo.accountaccountpayablesname = accountaccountpayablesname_[0] && accountaccountpayablesname_.length > 0 ? accountaccountpayablesname_ : businessCentralUserInfo.accountaccountpayablesname;
    businessCentralUserInfo.accountaccountreceivablesname = accountaccountreceivablesname_[0] && accountaccountreceivablesname_.length > 0 ? accountaccountreceivablesname_ : businessCentralUserInfo.accountaccountreceivablesname;
    businessCentralUserInfo.accountinventoryname = accountinventoryname_[0] && accountinventoryname_.length > 0 ? accountinventoryname_ : businessCentralUserInfo.accountinventoryname;
    const savebusinessCentralInfo_ = await this.api.saveBusinessCentralInfo(businessCentralUserInfo);
    if (savebusinessCentralInfo_ == "success") {
      this.setState({ isD365BCAccessToken: false, isD365BCAdditionalfields: false });
      clearInterval(this._timer);
      this._jobUUID = UUID();
      this.api.runErpBackgroundJobs(this._jobUUID);
      localStorage.setItem("BackGround_Job_ID", this._jobUUID);
      localStorage.setItem("UI_JOB", true);
    } else {
      this.setState({ isD365BCAccessToken: false, isD365BCAdditionalfields: false });
    }
  }

  getTransactionHistoryDownload = (e) => {
    e.preventDefault();
    const { bankTransactionHistory: { supplierBankTransactionHistory } } = this.state;
    let csvContent = "data:text/csv;charset=utf-8,";
    const table = {
      indent: 1,
      rows: []
    };
    const readbankTransactionValue = (rowData, indentation_, isGroup) => {
      for (let rowType of Object.keys(rowData)) {
        let row_;
        if (isGroup) {
          row_ = {
            indent: indentation_ + 1,
            columns: [],
          };
          table.indent = Math.max(table.indent, indentation_ + 1);
        }
        else {
          row_ = {
            indent: indentation_,
            columns: [],
          };
        }
        //table.rows.push(row_);
        row_.columns.push('"' + rowType + '"');
        table.rows.push(row_);
        if (rowData[rowType]) {
          if (typeof rowData[rowType] === 'object' && rowData[rowType] !== null) {
            Object.keys(rowData[rowType]).forEach((childRowType, i) => {
              if (rowData[rowType][childRowType].TransactionType) {
                let ele = rowData[rowType][childRowType];
                let row_ = {
                  columns: [],
                };
                //table.rows.push(row_);
                row_.columns.push(ele.TransactionType);
                row_.columns.push(ele.Date);
                row_.columns.push(ele.Description);
                row_.columns.push(ele.Amount);
                row_.columns.push(ele.Balance);
                table.rows.push(row_);
              }
              else {
                let newIndentation = indentation_;
                if (rowData[rowType][childRowType].length > 0 && Array.isArray(rowData[rowType][childRowType])) {
                  row_ = {
                    columns: [],
                    indent: indentation_ + 1,
                  };
                  table.indent = Math.max(table.indent, indentation_ + 1);
                  row_.columns.push('"' + childRowType + '"');
                  table.rows.push(row_);
                  rowData[rowType][childRowType].forEach(ele => {
                    let row_ = {
                      columns: [],
                    };
                    //table.rows.push(row_);
                    row_.columns.push(ele.TransactionType);
                    row_.columns.push(ele.Date);
                    row_.columns.push(ele.Description);
                    row_.columns.push(ele.Amount);
                    row_.columns.push(ele.Balance);
                    table.rows.push(row_);
                  });
                }
                else if (typeof rowData[rowType][childRowType] === 'object' && rowData[rowType][childRowType] !== null) {
                  ++newIndentation;
                  let row_ = {
                    indent: newIndentation,
                    columns: ['"' + childRowType + '"'],
                  };
                  table.rows.push(row_);

                  table.indent = Math.max(table.indent, newIndentation);
                  readbankTransactionValue(rowData[rowType][childRowType], newIndentation, true);
                }
              }
            });
          }
        }
      }
    };

    const readableJsonToCSV = (table_) => {
      let lastRow = null;
      console.log(table_);
      for (let row of table_.rows) {
        if (row.padding && !lastRow.padding) {
          csvContent += "\r\n";
        }

        for (let c = -table_.indent; c < row.columns.length; ++c) {
          let col;
          if (row.indent) {
            col = row.columns[c + (table_.indent - row.indent + 1)];
          } else {
            col = row.columns[c];
          }

          if (col) {
            csvContent += col;
          }

          if (c !== row.columns.length - 1) {
            csvContent += ",";
          }
        }

        if (row.padding) {
          csvContent += "\r\n";
        }

        csvContent += "\r\n";

        lastRow = row;
      }

      return csvContent;
    };
    readbankTransactionValue(supplierBankTransactionHistory, 1, false);
    if (table.rows.length > 0) {
      let headerRows_ = {
        columns: ["TransactionType", "Date", "Description", "Amount", "Balance"]
      };
      table.rows.unshift(headerRows_);
      readableJsonToCSV(table);
      console.log(csvContent);
      var encodedUri = encodeURI(csvContent);
      let _fileContent = {
        encodedUri: encodedUri,
        isCSVString: true,
        csvString: csvContent.replace("data:text/csv;charset=utf-8,", ""),
        csvData: table.rows,
        filename: "BankTransactionHistory.csv",
        isBankTransactionHistory: true
      }
      this.setState({
        isFinancialDocument: false,
        fileViewerContent: _fileContent,
        isFileViewerModal: true
      })
      // var link = document.createElement("a");
      // link.setAttribute("href", encodedUri);
      // link.setAttribute("download", `BankTransactionHistory.csv`);
      // document.body.appendChild(link); // Required for FF
      // link.click();
      // document.body.removeChild(link);
    }
  };

  autoCompleteDefaultValue = (defValue_) => {
    const dValue_ = [];
    if (this.state.chartOfAccounts.length > 0 && this.state.businessCentralUserInfo[defValue_].length > 0) {
      this.state.chartOfAccounts.forEach(chart_ => {
        this.state.businessCentralUserInfo[defValue_].forEach(val_ => {
          if (val_ == chart_.text) {
            dValue_.push(chart_);
          }
        });
      });

    }
    return dValue_;
  };

  getAggingReportExtractHandler = async (e, type) => {
    e.preventDefault();
    const { receivablesAging, payablesAging } = this.state;
    const fileContent = await getAgingReportDownload(receivablesAging, payablesAging, type);
    this.setState({
      isFinancialDocument: false,
      fileViewerContent: fileContent,
      isFileViewerModal: true
    });
  };

  render() {
    const {
      buyers,
      user: {
        name,
        address,
        phone,
        city,
        state,
        zip,
        year,
        entity,
        industry,
        taxId,
        revenue,
        balance,
        websiteUrl,
        accountingInformation,
        title,
        country,
        language
      },
      isLoading,
      isImporting,
      isSavedERP,
      isSavedAdvInfo,
      isUploadingDocument,
      isUpdatingPassword,
      password,
      newPassword,
      fileToUpload,
      fileToUpload1,
      fileToUpload2,
      fileToUpload3,
      financeDocs,
      KPIInfo,
      currentPathlocation,
      previousPathLocation,
      isFinancialHistoryModal,
      balanceStatement,
      isQuestionnaireModal
    } = this.state;
    const {
      businessEntityID,
      tenantId,
      clientId,
      secret,
      userName,
      erppassword,
      hostName,
    } = this.state.erpUserInfo;
    const { isFunder } = this.props;
    const {t}=this.props;

    const fileNameLength = 30;

    const getDisplayFileName = (fileName) => {
      const convertedFileName = getFileName(fileName);
      return convertedFileName.length > fileNameLength ? `${convertedFileName.slice(0, fileNameLength)}...` : convertedFileName;
    };

    const displayFileNameBalanceSheet = getDisplayFileName(financeDocs?.balanceSheet?.fileName);
    const displayFileNameIncomeStmt = getDisplayFileName(financeDocs?.incomeStmt?.fileName);
    const displayFileNameCashflow = getDisplayFileName(financeDocs?.cashflow?.fileName);

    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>
            <FullSpinner
              isSpinning={this.state.isUpdateQBURL}
            />
            <FullSpinner
              isSpinning={this.state.isQBAccessToken}
            />
            <FullSpinner
              isSpinning={this.state.isD365BCAccessToken}
            />
            <FullSpinner
              isSpinning={isUploadingDocument}
              confirmationLabel={t("document_upload_msg")}
            />
            <FullSpinner
              isSpinning={isLoading}
              confirmationLabel={this.state.profilespinnerMsg}
            />
            <FullSpinner
              isSpinning={isSavedERP}
              confirmationLabel={this.state.erpMessage ? "" : t("profile_page.accounting_system_content.erp_information_updated_msg")}
            />
            <FullSpinner
              isSpinning={isSavedAdvInfo}
              confirmationLabel={t("profile_page.accounting_system_content.financial_information_updated_msg")}
            />
            <FullSpinner
              isSpinning={isImporting}
              confirmationLabel={t("profile_page.accounting_system_content.imported_report_msg")}
            />
            <FullSpinner
              isSpinning={isUpdatingPassword}
              confirmationLabel={t("password_changed_msg")}
            />
            <ToastContainer />
            {(this.state.isFinancialHistoryModal ||
              this.state.isAgingReportModal ||
              this.state.isBankStatementModal ||
              this.state.isPayableAgingReportModal ||
              this.state.isAdditonalDocumentsModal ||
              this.state.isFileViewerModal ||
              this.state.isQuestionnaireModal) && (
                <div className="terms-checkbox-container">
                  {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                    onClose={() => {
                      this.setState({ isFileViewerModal: false, fileViewerContent: null });
                    }}
                    data={this.state.fileViewerContent}
                    isFinancialDocument={true}
                  ></FileViewerModal>}
                  {this.state.isQuestionnaireModal && <QuestionnaireModal
                    questions={this.state.user.questionnaires && this.state.user.questionnaires.length > 0 ? this.state.user.questionnaires : []}
                    isCreate={this.state.user.questionnaires && this.state.user.questionnaires.length > 0 ? false : true}
                    isDisableFields={false}
                    isShowUploadButton={true}
                    onClose={() => {
                      this.setState({ isQuestionnaireModal: false });
                      this.getProfile();
                    }}
                  >
                  </QuestionnaireModal>}
                  {this.state.isFinancialHistoryModal && <FinancialStatementsModal
                    onClose={() => {
                      this.setState({ isFinancialHistoryModal: false },
                        () => {
                          this.checkIfDocExists();
                          this.getSupplierDocs();
                        });

                    }}
                    data={financeDocs}
                  />}
                  {this.state.isAgingReportModal && <CustomerAgingReportModal
                    onClose={() => {
                      this.setState({ isAgingReportModal: false },
                        () => {
                          this.checkIfDocExists();
                          this.getSupplierDocs();
                          this.getProfile();
                        });

                    }}
                    receivablesAging={this.state.receivablesAging}
                    data={financeDocs}
                  />}
                  {this.state.isBankStatementModal && <SupplierBankStatementModal
                    onClose={() => {
                      this.setState({ isBankStatementModal: false },
                        () => {
                          this.getSupplierDocs();
                        });
                    }}
                    data={this.state.allBankStatements}
                  />}
                  {this.state.isAdditonalDocumentsModal &&
                    <FinancialAdditonalDocuments
                      onClose={() => {
                        this.setState({ isAgingReportModal: false },
                          () => {
                            this.checkIfDocExists();
                            this.getSupplierDocs();
                          });

                      }}
                      data={financeDocs}
                    />}
                  {this.state.isBankStatementModal && <SupplierBankStatementModal
                    onClose={() => {
                      this.setState({ isBankStatementModal: false },
                        () => {
                          this.getSupplierDocs();
                        });
                    }}
                    data={this.state.allBankStatements}
                  />}
                  {this.state.isAdditonalDocumentsModal &&
                    <FinancialAdditonalDocuments
                      onClose={() => {
                        this.setState({ isAdditonalDocumentsModal: false },
                          () => {
                            this.getSupplierDocs();
                          });
                      }}
                      data={this.state.allAdditionalDocuments}
                    />}
                  {this.state.isPayableAgingReportModal && <PayableAgingReportModal
                    onClose={() => {
                      this.setState({ isPayableAgingReportModal: false },
                        () => {
                          this.getSupplierDocs();
                          this.getProfile();
                        });

                    }}
                    payablesAging={this.state.payablesAging}
                    data={financeDocs}
                  />}
                </div>
              )}
            <React.Fragment>
              <div className="header-titlebar">
                <h3>{t("page_titles.profile")}</h3>
                <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className={"nav-link" + (this.state.currentTab == "businessInformation" ? " active" : "")}
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {t("profile_page.navigation_tab_tiles.business_information")}
                    </a>
                  </li>
                  {this.props.appState.state.currentUserRole != "funder" && (
                    <React.Fragment>
                      <li className="nav-item">
                        <a
                          className={"nav-link" + (this.state.currentTab == "erpInformation" ? " active" : "")}
                          id="erpinfo-tab"
                          data-toggle="tab"
                          href="#erpinfo"
                          role="tab"
                          aria-controls="erpinfo"
                          aria-selected="false"
                        >
                          {t("profile_page.navigation_tab_tiles.accounting_system")}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={"nav-link" + (this.state.currentTab == "financialInformation" ? " active" : "")}
                          id="advancedInfo-tab"
                          data-toggle="tab"
                          href="#advancedInfo"
                          role="tab"
                          aria-controls="advancedInfo"
                          aria-selected="false"
                        >
                          {t("profile_page.navigation_tab_tiles.financial_information")}
                        </a>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </div>
              <div className="content-container" id="profileContainer">
                <div className="row">
                  <div className="col">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className={"tab-pane show" + (this.state.currentTab == "businessInformation" ? " active" : "")}
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="row">
                          <div className="col">
                            <h4 className="mb-3">{t("profile_page.navigation_tab_tiles.business_information")}</h4>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 col-md-6 col-lg-4 mb-2">
                            <form>
                              <div className="form-group">
                                <label className="form-label-style" htmlFor="name">{t("user_labels.full_name")}</label>
                                <input
                                  type="text"
                                  value={this.state.user.userName}
                                  className="form-control"
                                  name="userName"
                                  onChange={this.handleChangeField}
                                />
                              </div>

                              <div className="form-group">
                                <label className="form-label-style" htmlFor="name">{t("user_labels.business_name")}</label>
                                <input
                                  type="text"
                                  value={name}
                                  className="form-control"
                                  name="name"
                                  onChange={this.handleChangeField}
                                />
                                {!this.state.isBusinessValid && (
                                  <div className="formErrors">
                                    {t("user_labels.invalid_business_name")}
                                  </div>
                                )}
                              </div>
                              <div className="form-group mt-3">
                                <label className="form-label-style" htmlFor="address">{t("user_labels.business_Address")}</label>
                                <input
                                  type="text"
                                  value={address}
                                  className="form-control"
                                  name="address"
                                  onChange={this.handleChangeField}
                                />
                                {!this.state.isBusinessAddressValid && (
                                  <div className="formErrors">
                                    {t("user_labels.invalid_business_address")}
                                  </div>
                                )}
                              </div>
                              <div className="form-group mt-3">
                                <label className="form-label-style" htmlFor="address">{t("user_labels.country")}</label>
                                <CountryDropdown
                                  value={country}
                                  classes="form-control mt-2 mb-"
                                  onChange={(val) => this.handleCountryChange(val)}
                                  defaultOptionLabel={t("please_select_country_msg")}
                                  whitelist={countryList}
                                />
                              </div>
                              <div className="form-group mt-3">
                                <label className="form-label-style" htmlFor="city">{t("user_labels.city")}</label>
                                <input
                                  type="text"
                                  value={city}
                                  className="form-control"
                                  name="city"
                                  onChange={this.handleChangeField}
                                />
                                {!this.state.isBusinessCityValid && (
                                  <div className="formErrors">
                                    {t("user_labels.invalid_city")}
                                  </div>
                                )}
                              </div>
                              <div className="form-row">
                                <div className="form-group col-7">
                                  <label className="form-label-style" htmlFor="state">{t("user_labels.state")}</label>
                                  <RegionDropdown
                                    country={country}
                                    value={state}
                                    classes="form-control mt-2 mb-4"
                                    defaultOptionLabel={t("user_labels.state")}
                                    blankOptionLabel={t("user_labels.state")}
                                    onChange={(val) => this.handleStateChange(val)}
                                    blacklist={{
                                      US: ["Micronesia", "Guam", "Marshall Islands", "Northern Mariana Islands", "Palau", "Puerto Rico"
                                        , "American Samoa", "District of Columbia", "Virgin Islands", "Armed Forces Americas", "Armed Forces Europe, Canada, Africa and Middle East", "Armed Forces Pacific"]
                                    }} />
                                  {/* <select
                                    id="state"
                                    name="state"
                                    value={state}
                                    onChange={this.handleChangeField}
                                    className="form-control"
                                  >
                                    {statelist && statelist.length > 0 && (
                                      statelist.map(state => (
                                        <option key={UUID()} value={state.region}>
                                          {state.name}
                                        </option>
                                      ))
                                    )}
                                    {!statelist && (
                                      STATES.map(state => (
                                        <option key={UUID()} value={state.region}>
                                          {state.name}
                                        </option>
                                      ))
                                    )}
                                  </select> */}
                                </div>
                                <div className="form-group col ">
                                  <label className="form-label-style" htmlFor="zip">{t("user_labels.zip_code")}</label>
                                  {/* <MaskedInput
                                    value={zip}
                                    name="zip"
                                    id="zip"
                                    onChange={this.handleChangeField}
                                    mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                  /> */}
                                  <input
                                    type="text"
                                    value={zip}
                                    className="form-control mt-2 mb-4"
                                    name="zip"
                                    id="zip"
                                    placeholder={t("user_labels.zip_code")}
                                    maxLength={10}
                                    onChange={this.handleChangeField}
                                  />
                                </div>
                              </div>

                            </form>
                          </div>
                          <div className="col-12 col-md-12 col-lg-6 mb-2">
                            <form>
                              <div className="form-row">
                                <div className="form-group col-6">
                                  <label className="form-label-style" htmlFor="name">{t("user_labels.job_title")}</label>
                                  <input
                                    type="text"
                                    value={title}
                                    className="form-control"
                                    name="title"
                                    onChange={this.handleChangeField}
                                  />
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-4">
                                  <label className="form-label-style" htmlFor="year">{t("user_labels.date_of_incorporation")}</label>
                                  <MaskedInput
                                    value={year}
                                    name="year"
                                    id="year"
                                    onChange={this.handleChangeField}
                                    mask={[/[1-9]/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group col-6">
                                  <label className="form-label-style" htmlFor="companyType">{t("user_labels.company_type")}</label>
                                  <select
                                    className="form-control"
                                    value={entity}
                                    id="entity"
                                    name="entity"
                                    onChange={this.handleChangeField}
                                  >
                                    {COMPANY_TYPES.map((type, i) => (
                                      <option key={UUID()} value={i + 1}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="form-row">
                                {appState.state.currentUserRole !== "funder" && (
                                  <div className="form-group col-6">
                                    <label className="form-label-style" htmlFor="industry">{t("user_labels.industry")}</label>
                                    <select
                                      className="form-control"
                                      value={industry}
                                      id="industry"
                                      name="industry"
                                      onChange={this.handleChangeField}
                                    >
                                      {INDUSTRIES.map((industryType, i) => (
                                        <option key={UUID()} value={i + 1}>
                                          {industryType}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </div>
                              <div className="form-row">
                                <div className="form-group col-6">
                                  {this.state.selectedCountryCode.length > 0 && (
                                    <>
                                      <label className="form-label-style" htmlFor="phone">{t("user_labels.phone_number")}</label>
                                      <PhoneInput
                                        country={this.state.selectedCountryCode}
                                        value={phone}
                                        countryCodeEditable={false}
                                        disableCountryGuess={true}
                                        onChange={this.handlePhoneChangeField}
                                        disableDropdown={true}
                                        onlyCountries={[this.state.selectedCountryCode]}
                                        key={this.state.selectedCountryCode}
                                        className="mt-2"
                                      />
                                    </>
                                  )}

                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col-6">
                                  <label id="profile-website-url" className="form-label-style" htmlFor="phone">{t("user_labels.website_address")}</label>
                                  <input
                                    type="url"
                                    value={websiteUrl}
                                    name="websiteUrl"
                                    id="websiteUrl"
                                    onChange={this.handleChangeField}
                                    className="form-control"
                                  />
                                  {websiteUrl && websiteUrl.length > 0 && !this.state.iswebsiteUrlValid && (
                                    <div className="formErrors">
                                      {t("user_labels.invalid_website_address")}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-6">
                                  <label id="profile-website-url" className="form-label-style" htmlFor="phone">{t("user_labels.preferred_language")}</label>
                                  <select
                                    className="form-control"
                                    value={language}
                                    id="language"
                                    name="language"
                                    onChange={(e)=>{
                                      const { user } = this.state;
                                      const name = e.target.name;
                                      const value = e.target.value;
                                      user[name] = value;
                                      this.setState({ user,isSaveProfileInformation:false },()=>{
                                        changeLanguage(value);
                                        window.location.reload();
                                      });
                                    }}
                                  >
                                    {supported_languages.map((lang_, i) => (
                                      <option key={lang_.code} value={lang_.code}>
                                        {lang_.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="form-group mt-3 hide">
                                <label className="form-label-style"  htmlFor="tax">{t("user_labels.tax_id")}</label>
                                <MaskedInput
                                  value={taxId}
                                  name="taxId"
                                  id="taxId"
                                  onChange={this.handleChangeField}
                                  mask={[
                                    /[1-9]/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                  className="form-control"
                                  placeholder={t("user_labels.placeholder_tax_id")}
                                />
                              </div>

                            </form>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={this.state.isSaveProfileInformation}
                              onClick={(e) => { this.saveProfile(t("profile_page.profile_updated")); }}
                            >
                              {t("button_names.save_business_information")}
                            </button>
                            {/* <Button
                              defaultLabel="Save Business Information"
                              clickedLabel="Confirm Save"
                              actionClickHandler={this.saveProfile}
                              buttonStyle="btn"
                              buttonColor="primary"
                            /> */}
                          </div>
                        </div>
                        {isAdminForManagedUser() && (
                          <>
                            <form onSubmit={e => this.changePassword(e)}>
                              <div className="row mt-2">
                                <div className="col">
                                  <h4 className="mb-3">{t("button_names.update_password")}</h4>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-12 col-md-12 col-lg-4 mb-2">
                                  <div className="form-group mb-0">
                                    <label className="form-label-style" htmlFor="name">{t("signup_labels.old_password")}</label>

                                    <div className="input-group mb-4">
                                    <input
                                      style={{paddingRight:"45px"}}
                                      type={this.state.isShowOldPassword ? "text" : "password"}
                                      value={password}
                                      className="form-control"
                                      name="password"
                                      onChange={e =>
                                        this.setState({ password: e.target.value })
                                      }
                                      required
                                      autoComplete="new-password"
                                    />
                                    <div className="input-group-append" onClick={() => {
                                      this.setState({ isShowOldPassword: !this.state.isShowOldPassword });
                                    }}>
                                      <span className="input-group-text eye-icon">
                                        {this.state.isShowOldPassword ? <VisibilityOff style={{ color: "black" }} /> : <Visibility style={{ color: "black" }} />}
                                      </span>
                                    </div>
                                    </div>

                                  </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-4 mb-2">
                                  <div className="form-group mb-0">
                                    <label className="form-label-style" htmlFor="name">{t("signup_labels.new_password")}</label>
                                    <div className="input-group mb-4">
                                    <input
                                      style={{paddingRight:"45px"}}
                                      type={this.state.isShowNewPassword ? "text" : "password"}
                                      value={newPassword}
                                      className="form-control"
                                      name="newPassword"
                                      onChange={e =>
                                        this.setState({ newPassword: e.target.value })
                                      }
                                      title={t("password_criterias.alphanumeric_validation")}
                                      required
                                      autoComplete="new-password"
                                    />
                                    <div className="input-group-append" onClick={() => {
                                      this.setState({ isShowNewPassword: !this.state.isShowNewPassword });
                                    }}>
                                      <span className="input-group-text eye-icon">
                                        {this.state.isShowNewPassword ? <VisibilityOff style={{ color: "black" }} /> : <Visibility style={{ color: "black" }} />}
                                      </span>
                                    </div>
                                    </div>

                                    {this.state.errorMsg && (
                                      <div className="formErrors">
                                        {this.state.errorMsg}
                                      </div>
                                    )}
                                  </div>

                                </div>
                              </div>
                              <div className="row mt-2 mb-2">
                                <div className="col">
                                  <p style={{ fontFamily: "Roboto", fontSize: 16, color: "#575757" }}>
                                    {t("password_criterias.alphanumeric_validation")}
                                  </p>
                                </div>
                              </div>
                              <div className="row mt-2 mb-2">
                                <div className="col">
                                  <button style={{ width: 154 }} className="btn btn-primary" type="submit">
                                    {t("button_names.update_password")}
                                  </button>
                                </div>
                              </div>
                            </form>
                          </>
                        )}

                        {appState.state.currentUserRole === "supplier" && (
                          <React.Fragment>
                            <div className="hide">
                              <div className="row">
                                <div className="col">
                                  <h4 className="mb-3">{t("profile_page.financial_document_history_title")}</h4>
                                  <hr />
                                </div>
                              </div>
                              <div className="row mb-5">
                                <div className="col-3">
                                  <Dropzone onDrop={this.onDrop}>
                                    {t("file_upload_msg.drop_a_file")}
                                  </Dropzone>
                                </div>
                                {this.state.isFile && (
                                  <div className="col-3">
                                    <button
                                      style={{ width: 154 }}
                                      type="button"
                                      onClick={() => this.downloadFile()}
                                      className="btn btn-outline-secondary btn-sm"
                                    >
                                      {t("button_names.download_financial_history")}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>

                      {appState.state.currentUserRole != "funder" && (
                        <>
                          <div
                            className={"tab-pane" + (this.state.currentTab == "erpInformation" ? " active" : "")}
                            id="erpinfo"
                            role="tabpanel"
                            aria-labelledby="erpinfo-tab"
                          >
                            <div className="row">
                              <div className="col-4">
                                <div className="row">
                                  <div className="col">
                                    <h4 className="mb-3">{t("profile_page.accounting_system_content.title")}</h4>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-12 form-group">
                                    <label className="form-label-style" htmlFor="accountingInformation">{t("profile_page.accounting_system_content.select_accounting_system_label")}</label>
                                    <select
                                      className="form-control"
                                      value={accountingInformation ? accountingInformation : ""}
                                      id="accountingInformation"
                                      name="accountingInformation"
                                      onChange={this.handleChangeField}
                                    >
                                      <option value="">{t("profile_page.accounting_system_content.please_select_your_system_sync")}</option>
                                      <option value="D365BC">{t("profile_page.accounting_system_content.d365_bc_label")}</option>
                                      <option value="erpinfo">{t("profile_page.accounting_system_content.d365_finance_label")}</option>
                                      <option value="quickbooks">{t("profile_page.accounting_system_content.quickbooks_label")}</option>
                                      <option value="other">{t("profile_page.accounting_system_content.other_label")}</option>
                                    </select>
                                  </div>
                                  <div className="col">
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                      onClick={(e) => { this.saveProfile(t("profile_page.accounting_system_content.accounting_system_updated_msg")); }}
                                      disabled={this.state.isSaveProfileInformation}
                                    >
                                      {t("profile_page.accounting_system_content.update_accounting_system_button_label")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-8">
                                {(accountingInformation == "quickbooks") && (
                                  <React.Fragment>
                                    <div className="row mb-5">
                                      <div className="col">
                                        <h4 className="">{t("profile_page.accounting_system_content.quickbooks.title")}</h4>
                                      </div>
                                    </div>
                                    <div className="row mb-1 mt-1">
                                      <div className="col">
                                        <button
                                          className="btn btn-primary"
                                          type="submit"
                                          onClick={this.getQuickBooksURL}
                                          disabled={!this.state.isQuickBookBtnEnabled}
                                        >
                                          {
                                            (this.state.qbInfo && this.state.qbInfo.EmailID) ?
                                              t("profile_page.accounting_system_content.quickbooks.reconnect_button_label")
                                              : t("profile_page.accounting_system_content.quickbooks.connect_button_label")
                                          }
                                        </button>
                                      </div>
                                    </div>
                                    {(this.state.qbInfo && this.state.qbInfo.EmailID) && (
                                      <div className="small">
                                        { t("profile_page.accounting_system_content.quickbooks.connected_msg")}<strong> {this.state.qbInfo.EmailID}</strong>
                                      </div>
                                    )}
                                    {this.state.isQBURLTextBox && (
                                      <div className="row">
                                        <div className="col-6 form-group mt-3">
                                          <label className="form-label-style" htmlFor="url">{ t("profile_page.accounting_system_content.quickbooks.quicbook_url_label")}</label>
                                          <input
                                            type="text"
                                            value={this.state.qbURL}
                                            className="form-control"
                                            name="url"
                                            onChange={async (e) => {
                                              this.setState({ qbURL: e.target.value });
                                              const { user: { email, _id } } = this.state;
                                              this.api.getQBAccessToken(e.target.value, this.state.user.email).then(() => {
                                                //qbWindow.close();
                                                this.setState(
                                                  { isQBAccessToken: false },
                                                  () => {
                                                    this.getQBInfo(email);
                                                    clearInterval(this._timer);
                                                    this._jobUUID = UUID();
                                                    this.api.runErpBackgroundJobs(this._jobUUID);
                                                    localStorage.setItem("BackGround_Job_ID", this._jobUUID);
                                                    localStorage.setItem("UI_JOB", true);
                                                  });
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                )}
                                {(accountingInformation == "erpinfo") && (
                                  <form>
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="mb-3">{t("profile_page.accounting_system_content.d365_finance.title")}</h4>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-12 col-md-12 col-lg-6 mb-4">
                                        <div className="form-group">
                                          <label className="form-label-style" htmlFor="erpBusinessEntityID">
                                          {t("profile_page.accounting_system_content.d365_finance.business_entity_label")}
                                          </label>
                                          <input
                                            type="text"
                                            name="erpBusinessEntityID"
                                            className="form-control"
                                            value={businessEntityID}
                                            placeholder={t("profile_page.accounting_system_content.d365_finance.business_entity_placeholder")}
                                            onChange={e =>
                                              this.handleERPInfoChangeField(
                                                "businessEntityID",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                            title={t("profile_page.accounting_system_content.d365_finance.business_entity_title")}
                                          />
                                          {!this.state.isbusinessEntityID && (
                                            <div className="formErrors">
                                             {t("profile_page.accounting_system_content.d365_finance.invalid_business_entity")}
                                            </div>
                                          )}
                                        </div>
                                        <div className="form-group">
                                          <label className="form-label-style" htmlFor="erpTanentID">{t("profile_page.accounting_system_content.d365_finance.tenant_id_label")}</label>
                                          <input
                                            type="text"
                                            name="erpTanentID"
                                            className="form-control"
                                            value={tenantId}
                                            placeholder={t("profile_page.accounting_system_content.d365_finance.tenant_id_label")}
                                            onChange={e =>
                                              this.handleERPInfoChangeField(
                                                "tenantId",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                            title={t("profile_page.accounting_system_content.d365_finance.tenant_id_title")}
                                          />
                                          {!this.state.istenantIDValid && (
                                            <div className="formErrors">
                                              {t("profile_page.accounting_system_content.d365_finance.invalid_tenant_id")}
                                            </div>
                                          )}
                                        </div>
                                        <div className="form-group mt-3">
                                          <label className="form-label-style" htmlFor="name">{t("profile_page.accounting_system_content.d365_finance.client_id_label")}</label>
                                          <input
                                            type="text"
                                            name="erpClientID"
                                            value={clientId}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365_finance.client_id_placeholder")}
                                            onChange={e =>
                                              this.handleERPInfoChangeField(
                                                "clientId",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                            title={t("profile_page.accounting_system_content.d365_finance.client_id_title")}
                                          />
                                          {!this.state.isclientIDValid && (
                                            <div className="formErrors">
                                              {t("profile_page.accounting_system_content.d365_finance.invalid_client_id")}
                                            </div>
                                          )}
                                        </div>
                                        {/* <div className="form-group mt-3">
                                    <label className="form-label-style" htmlFor="erpSecretID">Secret Value</label>
                                    <input
                                      type="text"
                                      name="erpSecretID"
                                      value={secret}
                                      className="form-control"
                                      placeholder="Secret Value"
                                      onChange={e =>
                                        this.handleERPInfoChangeField(
                                          "secret",
                                          e.target.value
                                        )
                                      }
                                      autoComplete={"off"}
                                    />
                                  </div> */}
                                      </div>
                                      <div className="col-12 col-md-12 col-lg-6 mb-4">
                                        {/* <div className="form-group">
                                    <label className="form-label-style" htmlFor="erpUserName">User Name</label>
                                    <input
                                      type="text"
                                      name="erpUserName"
                                      value={userName}
                                      className="form-control"
                                      placeholder="User Name"
                                      onChange={e =>
                                        this.handleERPInfoChangeField(
                                          "userName",
                                          e.target.value
                                        )
                                      }
                                      autoComplete={"off"}
                                    />
                                  </div> */}
                                        {/* <div className="form-group mt-3">
                                    <label className="form-label-style" htmlFor="erpPassword">Password</label>
                                    <div className="input-group">
                                      <input
                                        type={this.state.isShowERPPassword?"text":"password"}
                                        name="erpPassword"
                                        className="form-control"
                                        value={erppassword}
                                        onChange={e =>
                                          this.handleERPInfoChangeField(
                                            "erppassword",
                                            e.target.value
                                          )
                                        }
                                        autoComplete={"off"}
                                      />
                                      <div className="input-group-append" onClick={()=>{
                                        this.setState({isShowERPPassword:!this.state.isShowERPPassword})
                                      }}>
                                        <span className="input-group-text eye-icon-profile" style={{marginLeft:-40+ " !important",padding:"0px !important"}}>
                                          {this.state.isShowERPPassword ? <VisibilityOff style={{color:"black"}}/> : <Visibility style={{color:"black"}}/>}
                                        </span>
                                      </div>
                                    </div>
                                  </div> */}
                                        <div className="form-group">
                                          <label className="form-label-style" htmlFor="erpSecretID">{t("profile_page.accounting_system_content.d365_finance.secret_value_label")}</label>
                                          <input
                                            type="text"
                                            name="erpSecretID"
                                            value={secret}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365_finance.secret_value_placeholder")}
                                            onChange={e =>
                                              this.handleERPInfoChangeField(
                                                "secret",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                            title={t("profile_page.accounting_system_content.d365_finance.secret_value_title")}
                                          />
                                          {!this.state.isSecretValue && (
                                            <div className="formErrors">
                                              {t("profile_page.accounting_system_content.d365_finance.invalid_secret_value")}
                                            </div>
                                          )}
                                        </div>
                                        <div className="form-group ">
                                          <label className="form-label-style" htmlFor="erpUserName">{t("profile_page.accounting_system_content.d365_finance.host_name_label")}</label>
                                          <input
                                            type="text"
                                            name="erpUserName"
                                            value={hostName}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365_finance.host_name_placeholder")}
                                            onChange={e =>
                                              this.handleERPInfoChangeField(
                                                "hostName",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                            title={t("profile_page.accounting_system_content.d365_finance.host_name_title")}

                                          />
                                          {!this.state.isHostname && (
                                            <div className="formErrors">
                                              {t("profile_page.accounting_system_content.d365_finance.invalid_host_name")}
                                            </div>
                                          )}

                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <button
                                          className="btn btn-primary"
                                          type="submit"
                                          disabled={(!this.state.isSaveERPBtnEnabled)}
                                          onClick={e => this.saveErpInfo(e)}
                                        >
                                          <FontAwesomeIcon
                                            className="mr-2"
                                          />
                                          {t("profile_page.accounting_system_content.d365_finance.save_erp_btn_name")}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                )}
                                {(accountingInformation == "D365BC") && (
                                  <form>
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="mb-3"><span style={{ marginBottom: 2, marginRight: 10 }}><Badge badgeContent={1} color="primary"></Badge> </span> {t("profile_page.accounting_system_content.d365bc.title")}</h4>
                                      </div>
                                    </div>
                                    <div className="row mt-2">
                                      <div className="col-12 col-md-12 col-lg-6 mb-4">
                                        <div className="form-group">
                                          <label className="form-label-style" htmlFor="name">{t("profile_page.accounting_system_content.d365bc.client_id_label")}</label>
                                          <input
                                            type="text"
                                            name="erpClientID"
                                            value={this.state.businessCentralUserInfo.clientId}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365bc.client_id_placeholder")}
                                            onChange={e =>
                                              this.handled365bcInfoChangeField(
                                                "clientId",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label className="form-label-style" htmlFor="erpSecretID">{t("profile_page.accounting_system_content.d365bc.client_secret_label")}</label>
                                          <input
                                            type="text"
                                            name="erpSecretID"
                                            value={this.state.businessCentralUserInfo.clientsecret}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365bc.client_secret_placeholder")}
                                            onChange={e =>
                                              this.handled365bcInfoChangeField(
                                                "clientsecret",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                          />
                                        </div>
                                        <div className="form-group ">
                                          <label className="form-label-style" htmlFor="erpUserName">{t("profile_page.accounting_system_content.d365bc.tenant_domain_label")}</label>
                                          <input
                                            type="text"
                                            name="erpUserName"
                                            value={this.state.businessCentralUserInfo.tenantdomain}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365bc.tenant_domain_placeholder")}
                                            onChange={e =>
                                              this.handled365bcInfoChangeField(
                                                "tenantdomain",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                          />
                                        </div>

                                      </div>
                                      <div className="col-12 col-md-12 col-lg-6 mb-4">
                                        <div className="form-group ">
                                          <label className="form-label-style" htmlFor="erpUserName">{t("profile_page.accounting_system_content.d365bc.company_label")}</label>
                                          <input
                                            type="text"
                                            name="erpUserName"
                                            value={this.state.businessCentralUserInfo.company}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365bc.company_placeholder")}
                                            onChange={e =>
                                              this.handled365bcInfoChangeField(
                                                "company",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                          />
                                        </div>
                                        <div className="form-group ">
                                          <label className="form-label-style" htmlFor="erpUserName">{t("profile_page.accounting_system_content.d365bc.environment_label")}</label>
                                          <input
                                            type="text"
                                            name="erpUserName"
                                            value={this.state.businessCentralUserInfo.environment}
                                            className="form-control"
                                            placeholder={t("profile_page.accounting_system_content.d365bc.environment_placeholder")}
                                            onChange={e =>
                                              this.handled365bcInfoChangeField(
                                                "environment",
                                                e.target.value
                                              )
                                            }
                                            autoComplete={"off"}
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <button
                                          className="btn btn-primary"
                                          type="button"
                                          disabled={!this.state.isConnectD365BCFields}
                                          onClick={e => this.saveBusinessCentralInfo(e)}
                                        >
                                          {this.state.businessCentralUserInfo && this.state.businessCentralUserInfo.accesstoken ?
                                            t("profile_page.accounting_system_content.d365bc.reconnect_button_label") : t("profile_page.accounting_system_content.d365bc.connect_button_label")}
                                        </button>
                                      </div>
                                    </div>
                                    {this.state.businessCentralUserInfo && this.state.businessCentralUserInfo.accesstoken && (
                                      <>
                                        {(this.state.chartOfAccounts && this.state.chartOfAccounts.length > 0)
                                          ? (<React.Fragment>
                                            <div className="row mt-4">
                                              <div className="col">
                                                <h4 className="mb-3"><span style={{ marginBottom: 2, marginRight: 10 }}><Badge badgeContent={2} color="primary"></Badge> </span>{t("profile_page.accounting_system_content.d365bc.spinner_msg")}</h4>
                                              </div>
                                            </div>
                                            <div className="row mt-2">
                                              <div className="col-12 col-md-12 col-lg-6 mb-4">
                                                <div className="form-group ">
                                                  <label className="form-label-style" htmlFor="erpUserName">{t("profile_page.accounting_system_content.d365bc.ar_label")}</label>
                                                  <Autocomplete
                                                    multiple
                                                    id="tags-standard"
                                                    size="small"
                                                    options={this.state.chartOfAccounts}
                                                    getOptionLabel={(option) => option.text}
                                                    defaultValue={this.autoCompleteDefaultValue("accountaccountreceivablesname")}
                                                    onChange={(event, newValue) => {
                                                      let isAdditionalBCFields_ = false;
                                                      let businessCentralUserInfo_ = this.state.businessCentralUserInfo;
                                                      businessCentralUserInfo_.accountaccountreceivablesname = newValue;
                                                      if (newValue.length > 0 && businessCentralUserInfo_.accountinventoryname.length > 0 && businessCentralUserInfo_.accountaccountpayablesname.length > 0) {
                                                        isAdditionalBCFields_ = true;
                                                      }
                                                      this.setState({ businessCentralUserInfo: businessCentralUserInfo_, isD365BCAdditionalfields: isAdditionalBCFields_ });
                                                      // this.setState({accountaccountreceivablesname:newValue});
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder={t("profile_page.accounting_system_content.d365bc.ar_placeholder")}
                                                      />
                                                    )}
                                                  />
                                                </div>
                                                <div className="form-group ">
                                                  <label className="form-label-style" htmlFor="erpUserName">{t("profile_page.accounting_system_content.d365bc.ap_label")}</label>
                                                  <Autocomplete
                                                    multiple
                                                    id="tags-standard"
                                                    size="small"
                                                    options={this.state.chartOfAccounts}
                                                    getOptionLabel={(option) => option.text}
                                                    defaultValue={this.autoCompleteDefaultValue("accountaccountpayablesname")}
                                                    onChange={(event, newValue) => {
                                                      let isAdditionalBCFields_ = false;
                                                      let businessCentralUserInfo_ = this.state.businessCentralUserInfo;
                                                      businessCentralUserInfo_.accountaccountpayablesname = newValue;
                                                      if (newValue.length > 0 && businessCentralUserInfo_.accountinventoryname.length > 0 && businessCentralUserInfo_.accountaccountreceivablesname.length > 0) {
                                                        isAdditionalBCFields_ = true;
                                                      }
                                                      this.setState({ businessCentralUserInfo: businessCentralUserInfo_, isD365BCAdditionalfields: isAdditionalBCFields_ });
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder={t("profile_page.accounting_system_content.d365bc.ap_placeholder")}
                                                      />
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12 col-md-12 col-lg-6 mb-4">
                                                <div className="form-group ">
                                                  <label className="form-label-style" htmlFor="erpUserName">{t("profile_page.accounting_system_content.d365bc.inventory_account_label")}</label>
                                                  <Autocomplete
                                                    multiple
                                                    id="tags-standard"
                                                    size="small"
                                                    options={this.state.chartOfAccounts}
                                                    getOptionLabel={(option) => option.text}
                                                    defaultValue={this.autoCompleteDefaultValue("accountinventoryname")}
                                                    onChange={(event, newValue) => {
                                                      //let value = newValue ? [newValue] : [];
                                                      let isAdditionalBCFields_ = false;
                                                      let businessCentralUserInfo_ = this.state.businessCentralUserInfo;
                                                      businessCentralUserInfo_.accountinventoryname = newValue;
                                                      if (newValue.length > 0 && businessCentralUserInfo_.accountaccountpayablesname.length > 0 && businessCentralUserInfo_.accountaccountreceivablesname.length > 0) {
                                                        isAdditionalBCFields_ = true;
                                                      }
                                                      this.setState({ businessCentralUserInfo: businessCentralUserInfo_, isD365BCAdditionalfields: isAdditionalBCFields_ });
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        placeholder={t("profile_page.accounting_system_content.d365bc.inventory_account_placeholder")}
                                                      />
                                                    )}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-lg-6">
                                                <button
                                                  className="btn btn-primary"
                                                  type="button"
                                                  disabled={!this.state.isD365BCAdditionalfields}
                                                  onClick={e => this.saveD365BCOtherDetails(e)}
                                                >
                                                  {t("profile_page.accounting_system_content.d365bc.save_d365bc_button")}
                                                </button>
                                              </div>
                                            </div>
                                          </React.Fragment>) :
                                          (<>
                                            <div>
                                              <img
                                                className="logo"
                                                src={Load}
                                                alt={t("profile_page.accounting_system_content.d365bc.spinner_msg")}
                                              />
                                              <br />
                                              <span className="small font-italic">
                                              {t("profile_page.accounting_system_content.d365bc.spinner_msg")}
                                              </span>
                                            </div>
                                          </>)}
                                      </>
                                    )}
                                    <div className="alert alert-warning mt-4" role="alert">
                                      <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
                                      <span className="mr-1">
                                      {t("profile_page.accounting_system_content.d365bc.d365bc_please_configure_first_part")} <b>"{`${process.env.REACT_APP_UI_URL}/d365bc-signin-response`}"</b> {t("profile_page.accounting_system_content.d365bc.d365bc_please_configure_first_part")}
                                      </span>
                                    </div>
                                  </form>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={"tab-pane" + (this.state.currentTab == "financialInformation" ? " active" : "")}
                            id="advancedInfo"
                            role="tabpanel"
                            aria-labelledby="advancedInfo-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-md-12 col-lg-6">
                                <h4>{t("profile_page.financial_information_content.balance_sheet_title")}</h4>
                                {financeDocs && financeDocs.balanceSheet.fileName !== "" ?
                                  <React.Fragment>
                                    <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                      <div className="col-6">{t("common_names.document_label")}</div>
                                      <div className="col-3">{t("common_names.upload_date")}</div>
                                    </div>
                                    <div className="row pt-3 pb-4">
                                      <div className="col-6 d-flex">
                                        <a className="anchorstyle mr-2"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              isFileViewerModal: true,
                                              fileViewerContent: financeDocs.balanceSheet
                                            })
                                          }}
                                          href={
                                            ""
                                          }
                                          target="_blank"
                                        >
                                          {displayFileNameBalanceSheet}
                                        </a><FileMetaDataPopover data={financeDocs.balanceSheet}/></div>
                                      <div className="col-3 normal-text-color">{localDateFormat(new Date(financeDocs.balanceSheet.uploadDate))}</div>
                                    </div>

                                  </React.Fragment>
                                  :
                                  <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("accounting_system_connection_msg")}</p>
                                }

                                <h4>{t("profile_page.financial_information_content.income_statement_title")}</h4>
                                {financeDocs && financeDocs.incomeStmt.fileName !== "" ?
                                  <React.Fragment>
                                    <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                      <div className="col-6">{t("common_names.document_label")}</div>
                                      <div className="col-3">{t("common_names.upload_date")}</div>
                                    </div>
                                    <div className="row pt-3 pb-4">
                                      <div className="col-6 d-flex">
                                        <a className="anchorstyle mr-2" onClick={(e) => {
                                          e.preventDefault();
                                          this.setState({
                                            isFileViewerModal: true,
                                            fileViewerContent: financeDocs.incomeStmt
                                          })
                                        }}
                                          href={
                                            ""
                                          }

                                        >
                                          {displayFileNameIncomeStmt}
                                        </a><FileMetaDataPopover data={financeDocs.incomeStmt}/> </div>
                                      <div className="col-3 normal-text-color">{localDateFormat(new Date(financeDocs.incomeStmt.uploadDate))}</div>
                                    </div>

                                  </React.Fragment>
                                  :
                                  <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("accounting_system_connection_msg")}</p>
                                }
                                <h4>{t("profile_page.financial_information_content.cash_flow_statement_title")}</h4>
                                {financeDocs && financeDocs.cashflow.fileName !== "" ?
                                  <React.Fragment>
                                    <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                      <div className="col-6">{t("common_names.document_label")}</div>
                                      <div className="col-3">{t("common_names.upload_date")}</div>
                                    </div>
                                    <div className="row pt-3 pb-4">
                                      <div className="col-6 d-flex">
                                        <a className="anchorstyle mr-2"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              isFileViewerModal: true,
                                              fileViewerContent: financeDocs.cashflow
                                            })
                                          }}
                                          href={
                                            ""
                                          }
                                          target="_blank"
                                        >
                                          {displayFileNameCashflow}
                                        </a><FileMetaDataPopover data={financeDocs.cashflow}/></div>
                                      <div className="col-3 normal-text-color">{localDateFormat(new Date(financeDocs.cashflow.uploadDate))}</div>
                                    </div>

                                  </React.Fragment>
                                  :
                                  <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("accounting_system_connection_msg")}</p>
                                }

                                <button className="btn btn-primary mt-3" type="submit"
                                  style={{ width: 134 }}
                                  onClick={() => {
                                    this.setState({ isFinancialHistoryModal: true });
                                  }}>
                                  <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faFileUpload}
                                  />
                                  {t("button_names.upload_edit")}
                                </button>
                                <h4 className="mt-5">{t("profile_page.financial_information_content.account_receivable_title")}</h4>
                                {(financeDocs && financeDocs.agingfile.fileName !== "" || (this.state.receivablesAging && this.state.receivablesAging.invoices)) ?
                                  <React.Fragment>
                                    <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                      <div className="col-6">{t("common_names.document_label")}</div>
                                      <div className="col-3">{t("common_names.report_label")}</div>
                                      <div className="col-3">{t("common_names.upload_date")}</div>
                                    </div>
                                    <div className="row pt-3 pb-4">
                                      {(this.state.receivablesAging && this.state.receivablesAging.invoices) ? (<React.Fragment>
                                        <div className="col-6">
                                          <a
                                            download
                                            className="anchorstyle"
                                            // onClick={(e) => this.getAggingReportDownload(e, "receivable")}
                                            onClick={(e) => this.getAggingReportExtractHandler(e, "receivable")}
                                          >{t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                        </div>
                                        <div className="col-3 normal-text-color">{this.state.receivablesAging.pulledDate ?localDateFormat( new Date(this.state.receivablesAging.pulledDate)) : this.props.t("no_report_date")}</div>
                                        <div className="col-3 normal-text-color">{this.state.receivablesAging.uploadedDate ?localDateFormat(  new Date(this.state.receivablesAging.uploadedDate)) : this.props.t("no_uploaded_date")}</div>
                                      </React.Fragment>) :
                                        (<React.Fragment>
                                          <div className="col-6 d-flex">
                                            <a className="anchorstyle mr-2"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  isFileViewerModal: true,
                                                  fileViewerContent: financeDocs.agingfile
                                                })
                                              }}
                                              href={
                                                ""
                                              }
                                              target="_blank"
                                            >
                                              {t("profile_page.financial_information_content.receivable_aging_label")}
                                            </a><FileMetaDataPopover data={financeDocs.agingfile}/></div>
                                          <div className="col-3 normal-text-color">{financeDocs.agingfile.pulledDate ? localDateFormat(new Date(financeDocs.agingfile.pulledDate)) : this.props.t("no_report_date")}</div>
                                          <div className="col-3 normal-text-color">{financeDocs.agingfile.uploadDate ? localDateFormat(new Date(financeDocs.agingfile.uploadDate)) : this.props.t("no_uploaded_date")}</div>
                                        </React.Fragment>)}
                                    </div>

                                  </React.Fragment>
                                  :
                                  <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("accounting_system_connection_msg")}</p>
                                }
                                <button
                                  className="btn btn-primary mt-3"
                                  onClick={() => this.setState({ isAgingReportModal: true })}
                                >
                                  <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faFileUpload}
                                  />
                                  {t("profile_page.financial_information_content.upload_receivable_aging_button")}
                                </button>
                                <h4 className="mt-5">{t("profile_page.financial_information_content.account_payable_title")}</h4>
                                {(financeDocs && financeDocs.payableAgingFile.fileName !== "" || (this.state.payablesAging && this.state.payablesAging.Payables)) ?
                                  <React.Fragment>
                                    <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                      <div className="col-6">{t("common_names.document_label")}</div>
                                      <div className="col-3">{t("common_names.report_label")}</div>
                                      <div className="col-3">{t("common_names.upload_date")}</div>
                                    </div>
                                    <div className="row pt-3 pb-4">
                                      {(this.state.payablesAging && this.state.payablesAging.Payables) ?
                                        <>
                                          <div className="col-6 d-flex">
                                            <a
                                              download
                                              className="anchorstyle mr-2"
                                              //onClick={(e) => this.getAggingReportDownload(e, "payables")}
                                              onClick={(e) => this.getAggingReportExtractHandler(e, "payables")}
                                            >{t("profile_page.financial_information_content.payable_aging_label")}</a>
                                          </div>
                                          <div className="col-3 normal-text-color">{this.state.payablesAging.pulledDate ? localDateFormat(new Date(this.state.payablesAging.pulledDate)) : this.props.t("no_report_date")}</div>
                                          <div className="col-3 normal-text-color">{this.state.payablesAging.uploadedDate ? localDateFormat(new Date(this.state.payablesAging.uploadedDate)) : this.props.t("no_uploaded_date")}</div>
                                        </> : <>
                                          <div className="col-6">
                                            <a className="anchorstyle"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  isFileViewerModal: true,
                                                  fileViewerContent: financeDocs.payableAgingFile
                                                })
                                              }}
                                              href={
                                                ""
                                              }
                                              target="_blank"
                                            >
                                              {t("profile_page.financial_information_content.payable_aging_label")}
                                            </a><FileMetaDataPopover data={financeDocs.payableAgingFile}/></div>
                                          <div className="col-3 normal-text-color">{financeDocs.payableAgingFile.pulledDate ? localDateFormat(new Date(financeDocs.payableAgingFile.pulledDate)) : this.props.t("no_report_date")}</div>
                                          <div className="col-3 normal-text-color">{financeDocs.payableAgingFile.uploadDate ? localDateFormat(new Date(financeDocs.payableAgingFile.uploadDate)) : this.props.t("no_uploaded_date")}</div>
                                        </>}
                                    </div>

                                  </React.Fragment>
                                  :
                                  <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("accounting_system_connection_msg")}</p>
                                }
                                <button
                                  className="btn btn-primary mt-3"

                                  onClick={() => this.setState({ isPayableAgingReportModal: true })}
                                >
                                  <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faFileUpload}
                                  />
                                  {t("profile_page.financial_information_content.upload_payable_aging_button")}
                                </button>
                                <h4 className="mt-5">{t("profile_page.financial_information_content.bank_statement_title")}</h4>
                                {this.state.allBankStatements && this.state.allBankStatements.length > 0 || (this.state.bankTransactionHistory && this.state.bankTransactionHistory.supplierBankTransactionHistory) ?
                                  <React.Fragment>
                                    <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                      <div className="col-6">{t("common_names.document_label")}</div>
                                      <div className="col-3">{t("common_names.upload_date")}</div>
                                    </div>
                                    {(this.state.bankTransactionHistory && this.state.bankTransactionHistory.supplierBankTransactionHistory) &&
                                      (<React.Fragment>
                                        <div className="row pt-3">
                                          <div className="col-6">
                                            <a
                                              download
                                              className="anchorstyle"
                                              onClick={(e) => this.getTransactionHistoryDownload(e)}
                                            >
                                              {t("profile_page.financial_information_content.upload_bank_statement_button")}
                                            </a></div>
                                          <div className="col-3 normal-text-color">{localDateFormat(new Date(this.state.bankTransactionHistory.retrievalTimeStamp))}</div>
                                        </div>
                                      </React.Fragment>)}
                                    {(<React.Fragment>
                                      {this.state.allBankStatements && this.state.allBankStatements.length > 0 && this.state.allBankStatements.map(doc => (
                                        <React.Fragment>
                                          <div className="row pt-3">
                                            <div className="col-6 d-flex">
                                              <a className="anchorstyle mr-2"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    isFileViewerModal: true,
                                                    fileViewerContent: doc
                                                  })
                                                }}
                                                href=""
                                                target="_blank"
                                              >
                                                {doc.filename}
                                              </a><FileMetaDataPopover data={doc}/></div>
                                            <div className="col-3 normal-text-color">{localDateFormat(new Date(doc.createdDate))}</div>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </React.Fragment>)}

                                  </React.Fragment>
                                  :
                                  <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("accounting_system_connection_msg")}</p>
                                }
                                <button
                                  className="btn btn-primary mt-3 mb-4"

                                  onClick={() => this.setState({ isBankStatementModal: true })}
                                >
                                  <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faFileUpload}
                                  />
                                  {t("profile_page.financial_information_content.upload_bank_statement_button")}
                                </button>
                              </div>
                              <div className="flex-column col-12 col-md-12 col-lg-6">
                                <div className="col-12 col-md-12 col-lg-9 mb-4">
                                  <h4 className="mb-3">{t("profile_page.financial_information_content.financial_information_title")}</h4>
                                  <form
                                    onSubmit={e => {
                                      this.saveAdvancedInfo(e);
                                    }}
                                  >

                                    <label className={isUSCountryUser(country)?"form-label-style":"label-style"} htmlFor="tax">{t("profile_page.financial_information_content.tax_id_label")}</label>
                                    {country && (country == "United States" || country == "United States of America") ? (<>
                                      <MaskedInput
                                        className="form-control mb-4"
                                        name="taxId"
                                        id="taxId"
                                        value={taxId}
                                        onChange={this.handleChangeField}
                                        mask={[
                                          /[1-9]/,
                                          /\d/,
                                          "-",
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/,
                                          /\d/
                                        ]}
                                        placeholder={t("common_placeholder")}
                                      />
                                    </>) : (<>
                                      <input
                                        type="text"
                                        className="form-control mb-4"
                                        name="taxId"
                                        id="taxId"
                                        value={taxId ? taxId.replace(/[^0-9aA-zZ.]/g, "") : taxId}
                                        onChange={this.handleChangeField}
                                        placeholder={t("common_placeholder")}
                                        maxLength={15}
                                      />
                                    </>)}
                                    {!this.state.isTaxIdValid && (
                                      <div className="formErrors">
                                        {t("profile_page.financial_information_content.invalid_tax_id")}
                                      </div>
                                    )}
                                    <label className="form-label-style" htmlFor="tax">{t("profile_page.financial_information_content.annual_sales_label")}</label>
                                    <MaskedInput
                                      name="revenue"
                                      id="revenue"
                                      value={revenue}
                                      onChange={e =>
                                        this.handleDollarChangeField(
                                          "revenue",
                                          Number(
                                            e.target.value.replace(/[^0-9.-]+/g, "")
                                          )
                                        )
                                      }
                                      mask={numberMask}
                                      className="form-control mb-4"
                                      placeholder={t("profile_page.financial_information_content.annual_sales_placeholder")}
                                    />
                                    {/* <label className="form-label-style" htmlFor="tax">Average Bank Balance</label>
                                  <MaskedInput
                                    name="balance"
                                    id="balance"
                                    value={balance}
                                    onChange={e =>
                                      this.handleDollarChangeField(
                                        "balance",
                                        Number(
                                          e.target.value.replace(/[^0-9.-]+/g, "")
                                        )
                                      )
                                    }
                                    mask={numberMask}
                                    className="form-control mb-4"
                                    placeholder={`${appState.state.currentUserRole === "funder"
                                      ? "Investment Capital"
                                      : "Average Bank Balance"
                                      }`}
                                  /> */}
                                    <button className="btn btn-primary" type="submit" disabled={this.state.isSaveFinInformation}>
                                      <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave}
                                      />
                                      {t("profile_page.financial_information_content.save_finacial_button")}
                                    </button>
                                  </form>
                                </div>
                                {/* {this.props.appState.state.currentUserRole != "funder" && (
                                  <div className="col-12 col-md-12 col-lg-9 mb-4">
                                    <h4 className="mb-3">Questionnaire<span style={{ color: "red" }}> *</span></h4>
                                    <button className="btn btn-primary mt-2" type="submit" onClick={(e) => {
                                      this.setState({ isQuestionnaireModal: true });
                                    }}>
                                      <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave}
                                      />
                                      Add/Edit Questionnaire
                                    </button>

                                  </div>
                                )} */}
                                {/* {(!this.state.isFile && this.props.appState.state.currentUserRole == "supplier") && <DocumentationAlert />} */}

                                <div className="col-12 col-md-12 mb-4">
                                  <h4 className="mt-5">{t("profile_page.financial_information_content.additional_document_title")}</h4>
                                  {(this.state.allAdditionalDocuments && this.state.allAdditionalDocuments.length > 0) ?
                                    <React.Fragment>
                                      <div className="row pt-2" style={{ color: "#255F79", fontWeight: 600, fontSize: 14 }}>
                                        <div className="col-6">{t("common_names.document_label")}</div>
                                        <div className="col-3">{t("common_names.upload_date")}</div>
                                      </div>
                                      {this.state.allAdditionalDocuments && this.state.allAdditionalDocuments.length > 0 && this.state.allAdditionalDocuments.map(doc => (
                                        <React.Fragment>
                                          <div className="row pt-3">
                                            <div className="col-6 d-flex">
                                              <a className="anchorstyle mr-2"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.setState({
                                                    isFileViewerModal: true,
                                                    fileViewerContent: doc
                                                  })
                                                }}
                                                href={""}
                                                target="_blank"
                                              >
                                                {doc.filename}
                                              </a><FileMetaDataPopover data={doc}/></div>
                                            <div className="col-3 normal-text-color">{localDateFormat(new Date(doc.createdDate))}</div>
                                          </div>
                                        </React.Fragment>
                                      ))}

                                    </React.Fragment>
                                    :
                                    <p style={{ fontFamily: "Roboto", fontSize: 18, color: "#575757" }}>{t("documents_not_found_msg")}</p>
                                  }
                                  <button
                                    className="btn btn-primary mt-3"
                                    onClick={() => this.setState({ isAdditonalDocumentsModal: true })}
                                  >
                                    <FontAwesomeIcon
                                      className="mr-2"
                                      icon={faFileUpload}
                                    />
                                    {t("profile_page.financial_information_content.upload_additonal_button")}
                                  </button>
                                </div>
                                {compareRole(this.props.appState.state.currentUserRole, enumRole.Buyer) && (
                                  <div className="col-12 col-md-12 col-lg-9">
                                    <h4 className="mb-3">{t("profile_page.financial_information_content.credit_limit_assessment")}</h4>
                                    <a
                                      className="btn btn-primary mt-2"
                                      href="https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=5487dd66-a613-42cd-bf4d-fa1030baf0ae&activateonly=1"
                                      target="_blank">
                                      <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave}
                                      />
                                      {t("profile_page.financial_information_content.link_to_issuer_msg")}
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

const DocumentationAlert = () => (
  <div className="alert alert-warning" role="alert">
    <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
    <span className="mr-1">
      {this.props.t("profile_page.financial_information_content.all_fields_validation")}
    </span>
  </div>
);

export default compose(withRouter, withTranslation()) (Profile);