import React, { Component } from 'react'
import Popup from '../common/Auth/Popup';
import { API_ENDPOINT_SUPPLIER } from '../../services/API';
import User from '../../services/User';
import { dollarFormatter, getIndustryWithId, getEntity,dateFormatter,ShortText,localDateFormat  } from '../../services/Utilities';
import API from '../../services/API';
import FullSpinner from '../common/Spinner/FullSpinner';


export default class ViewFunderDetails extends Component {
    user = new User();
    viewData = {};
    api = new API();
    middeskResponse = {};

    constructor(props_) {
        super(props_);
        this.state = {
            agingReport:false,
            isUpdatedMiddesk:false,
            latestMiddeskResponse:{},
            signingContractValue:null,
            supplierFinancialAddDocs: [],
        }
        this.viewData = props_.data;
        this.middeskResponse = this.parseMidDeskResponse(props_.data.midDesk);
        this.getLatestMiddeskResponse=this.getLatestMiddeskResponse.bind(this);
    }

    componentDidMount() {
        this.getLatestMiddeskResponse();
    }
    //..changed...///
    async getLatestMiddeskResponse() {
        //this.setState({isUpdatedMiddesk:true});
        const midddesk =await this.api.getLatestMiddeskResponse(this.props.data.midDesk.id);
        this.setState({ latestMiddeskResponse:midddesk,})
    }

    getBadges(scope_) {
        return scope_.tags.map(t =>
            <span className={"verfication-badge badge " + (scope_.verified ? "badge-success" : "badge-danger")}>{t}</span>
        );
    }

    parseMidDeskResponse(obj) {
        let verificationSummary = {
            name: {
                verified: false,
                tags: []
            },
            address: {
                verified: false,
                tags: []
            },
            tin: {
                verified: false,
                tags: []
            },
            phone: {
                verified: false,
                tags: []
            },
            extra: [
                {
                    "type":"People",
                    details:[]
                },
                {
                    "type": "Website Address",
                    details: []
                },
                {
                    "type": "Profile",
                    details: []
                },
                {
                    "type": "Watch List",
                    details: []
                },
                {
                    "type": "Other Verfications",
                    details: []
                }
            ]
        };
        if(obj.website && obj.website.business_id){
            let websiteDetails={
                url:obj.website.url,
                status:obj.website.status,
                title:obj.website.title,
                description:obj.website.description,
                domain:obj.website.domain,
               // domainCreatedDate:obj.website.domain && obj.website.domain.creation_date,
               // domainExpirationDate:obj.website.domain && obj.website.domain.expiration_date,
                businessNameMatch:obj.website.business_name_match
            }
            verificationSummary.extra[1].details.push(websiteDetails);
        }
        for (let reviewTask of obj.review.tasks) {
            switch (reviewTask.key) {
                case "name":
                    verificationSummary.name.verified = reviewTask.status == "success";
                    verificationSummary.name.tags.push(reviewTask.sub_label);
                    break;
                case "address_verification":
                    verificationSummary.address.verified = reviewTask.status == "success";
                    verificationSummary.address.tags.push(reviewTask.sub_label);
                    break;
                case "address_property_type":
                    verificationSummary.address.tags.push(reviewTask.sub_label);
                    break;
                case "tin":
                    verificationSummary.tin.verified = reviewTask.status == "success";
                    verificationSummary.tin.tags.push(reviewTask.sub_label);
                    break;
                case "phone":
                        verificationSummary.phone.verified = reviewTask.status == "success";
                        verificationSummary.phone.tags.push(reviewTask.sub_label);
                default:
                    verificationSummary.extra[4].details.push({
                        "label": reviewTask.label,
                        "message": reviewTask.message
                    });
            }
        };

        for (let watchlist of obj.watchlist.lists) {
            verificationSummary.extra[3].details.push({
                "label": watchlist.agency,
                "message": (watchlist.results && watchlist.results.length) ? watchlist.results.join("\n") : "No entries found"
            });
        }

        for (let registration of obj.registrations) {
            let details = [];

            details.push({
                "label": "Status",
                "message": registration.status
            });
            details.push({
                "label": "Jurisdiction",
                "message": registration.jurisdiction
            });
            details.push({
                "label": "Entity Type",
                "message": registration.entity_type
            });
            details.push({
                "label": "Registration Date",
                "message": dateFormatter.format(new Date(registration.registration_date))
            });
            details.push({
                "label": "Registered State",
                "message": registration.state
            });
            details.push({
                "label": "Registration Officers",
                "message": registration.officers.length>0?registration.officers:"No Officers found",
            });
            details.push({
                "label": "Registration Addresses",
                "message": registration.addresses,
            });

            verificationSummary.extra.splice(0,0,{
                "type": `Registration Details of "${registration.name}"`,
                details: details
            });
        }
        return verificationSummary;
    }

    getDropBoxProfileContent(files, overrideFileName,isFileName_) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                let fileUrl = d.downloadUrl ? (d.downloadUrl.replace("http//", "http://").replace("https//", "https://")
                    .replace("http://http", "http").replace("https://http", "http")) : "";

                if (this.user.currentUserRole() == "funder") {
                    return <div className="col-md-6" key={d.filename}> <span className="form-value"><a className="anchorstyle" href={fileUrl} target="_blank">{isFileName_?d.filename:d.financialDocumentType}</a></span></div>
                } else {
                    return <div key={d.filename}><a href={fileUrl} target="_blank" download={d.filename}>{d.filename}</a></div>
                }

            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    getDropBoxContent(files, type, overrideFileName) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                return <div key={d.filename}><a className="anchorstyle" href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${d._id}`} target="_blank" download={d.filename}>{overrideFileName ? overrideFileName : d.filename}</a></div>
            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>
            });
        }
    }

    

    

    render() {
        const {
            supplier,
            midDesk,
            fundingDocuments,
            fundingType,
            status,
            contracts,
            additionalInfo
        } = this.props.data;
        const {  latestMiddeskResponse, isUpdatedMiddesk,
            isPayableDetailsAgingReport,
            isRecievablesDetailsAgingReport } = this.state;
        return (<Popup className="big"
            content={<>
                <div className="popup">
                    <FullSpinner
                        isSpinning={isUpdatedMiddesk}
                    />
                    <FullSpinner
                        isSpinning={isUpdatedMiddesk? false : this.state.agingReport}
                        confirmationLabel="Aging Report Downloaded "
                    />
                    <div>
                        <label className="popup-close-icon" onClick={e => this.props.onClose()}></label>
                        <h1>
                            {midDesk.tin ? midDesk.tin.name : midDesk.name}
                            {this.getBadges(this.middeskResponse.name)}
                        </h1>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-section">
                                    <label className="form-label">
                                    Tax ID {this.getBadges(this.middeskResponse.tin)}
                                    </label>
                                    <span className="form-value">
                                        {midDesk.tin ? midDesk.tin.tin : ""}
                                    </span>
                                </div>
                                <div className="form-section">
                                    <label className="form-label">
                                        Business Documents
                                    </label>
                                    <div className="row">
                                        {/* <div className="col-md-6">
                                            <span className="form-value">
                                                <a className="anchorstyle" href={`${API_ENDPOINT_SUPPLIER}/funding-request/middesk-document/${midDesk.id}`} target="_blank">Business PDF</a>
                                            </span>
                                        </div> */}
                                        <div className="col-md-6">
                                            <span className="form-value">
                                                <label className="form-label">Documents</label>
                                            </span>
                                            {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.documents && latestMiddeskResponse.documents.length > 0 ?
                                                <React.Fragment>
                                                    {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.documents &&
                                                    latestMiddeskResponse.documents.map(item => (
                                                        <span className="form-value">
                                                            <a className="anchorstyle" key={item.document_type} href={item.download_url} target="_blank">{ShortText(item.document_type ? item.document_type : item.filename)}</a>
                                                        </span>
                                                    ))}
                                                </React.Fragment>
                                                : <span className="form-value">
                                                    <div>No Documents</div>
                                                </span>
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <span className="form-value">
                                                <label className="form-label">Liens</label>
                                            </span>
                                            {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.liens && latestMiddeskResponse.liens.length > 0 ?
                                                <React.Fragment>
                                                    {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.liens &&
                                                    latestMiddeskResponse.liens.map(item => {
                                                        return (
                                                            <React.Fragment>
                                                                {item.documents && item.documents.map(doc => (
                                                                    <span className="form-value">
                                                                        <a className="anchorstyle" key={doc.document_type} href={doc.download_url} target="_blank">{ShortText(doc.document_type ? doc.document_type : doc.filename)}</a>
                                                                    </span>
                                                                ))}
                                                            </React.Fragment>
                                                        )
                                                    }
                                                    )}
                                                </React.Fragment>
                                                : <span className="form-value">
                                                    <div>No Liens</div>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form-section">
                                    <label className="form-label">
                                        Address {this.getBadges(this.middeskResponse.address)}
                                    </label>
                                    <span className="form-value">
                                        {this.props.data.address} <br />
                                        {this.props.data.city} <br />
                                        {this.props.data.state} <br />
                                        {this.props.data.zip} <br />
                                        {this.props.data.country?this.props.data.country:""}
                                    </span>
                                </div>
                                <div className="form-section">
                                    <label className="form-label">
                                        Phone Number{this.getBadges(this.middeskResponse.phone)}
                                    </label>
                                    {midDesk.phone_numbers.length > 0 ?
                                        (<>
                                            {midDesk.phone_numbers.map((pnos) => (
                                                <span className="form-value">{pnos.phone_number}</span>
                                            ))}
                                        </>)
                                        :
                                        (<React.Fragment>
                                            <span className="form-value">Phone numbers not found</span>
                                        </React.Fragment>)}
                                </div>
                                <div className="form-section">
                                    <label className="form-label">
                                        Funder Details
                                    </label>
                                    <span className="form-value">
                                        Full Name: {this.props.data.userName?this.props.data.userName:""}
                                        <br/>
                                        Job Title: {this.props.data.title?this.props.data.title:""}
                                        <br/>
                                        P: {this.props.data.phone}
                                        <br />
                                        Founded: {this.props.data.year}
                                        <br />
                                        {getEntity(this.props.data.entity)}
                                        <br />
                                    </span>
                                </div>
                                {
                                    this.middeskResponse.extra.map(e =>
                                        <div className="form-section">
                                            <label className="form-label">{e.type}</label>
                                            <div>
                                                {(e.type !== "Website Address" ||e.type!=="People"  ||e.type!=="Profile") && (<React.Fragment>
                                                    {e.details.map(d =>
                                                        <div className="form-value">
                                                            {(d.label == "Registration Officers" && d.message != "No Officers found") ?
                                                                <React.Fragment>
                                                                    <label className="form-label">Registration Officers:</label>
                                                                    <div className="row pl-4">
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">Officers Name</label>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">Officers Roles</label>
                                                                        </div>
                                                                        {d.message.map(officer =>
                                                                            <React.Fragment>
                                                                                <div className="col-md-6">
                                                                                    {officer.name ? officer.name : ""}
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    {officer.roles && officer.roles.map(role =>
                                                                                        <React.Fragment>
                                                                                            {role}<br />
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </React.Fragment> :
                                                                <React.Fragment>
                                                                    <b>{d.label}</b>: {d.message}
                                                                </React.Fragment>}
                                                        </div>
                                                    )}
                                                </React.Fragment>)}
                                                {e.type == "Website Address" &&
                                                    (<React.Fragment>
                                                        {e.details.length > 0 ?
                                                            <React.Fragment>
                                                                {e.details.map(d =>
                                                                    <React.Fragment>
                                                                        {(d.status && d.domain) ?
                                                                            <React.Fragment>
                                                                                <div className="form-value">
                                                                                    <b>Url</b>: {d.url ? d.url : ""}
                                                                                </div>
                                                                                <div className="form-value">
                                                                                    <b>Title</b>: {d.title ? d.title : ""}
                                                                                </div>
                                                                                <div className="form-value">
                                                                                    <b>Website Status</b>: {d.status ? d.status : ""}
                                                                                </div>
                                                                                <div className="form-value">
                                                                                    <b>Domain</b>: {d.domain && d.domain.domain ? d.domain.domain : ""}
                                                                                </div>
                                                                                <div className="form-value">
                                                                                    <b>Domain Creation Date</b>:  {d.domain && d.domain.creation_date ? localDateFormat(d.domain.creation_date) : ""}
                                                                                </div>
                                                                                <div className="form-value">
                                                                                    <b>Domain Expiration Date</b>:  {d.domain && d.domain.expiration_date ? localDateFormat(d.domain.expiration_date) : ""}
                                                                                </div>
                                                                                {d.domain && d.domain.registrar && (
                                                                                    <React.Fragment>
                                                                                        <div className="form-value">
                                                                                            <b>Registrar Organization</b>:  {d.domain.registrar && d.domain.registrar.organization ? d.domain.registrar.organization : ""}
                                                                                        </div>
                                                                                        <div className="form-value">
                                                                                            <b>Registrar Name</b>:  {d.domain.registrar && d.domain.registrar.name ? d.domain.registrar.name : ""}
                                                                                        </div>
                                                                                        <div className="form-value">
                                                                                            <b>Registrar Url</b>:  {d.domain.registrar && d.domain.registrar.url ? d.domain.registrar.url : ""}
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </React.Fragment> :
                                                                            <React.Fragment>
                                                                                <b>{d.label}</b>: {d.message}
                                                                            </React.Fragment>}
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment> : (
                                                                <React.Fragment><span className="form-value">Website details not found</span></React.Fragment>
                                                            )}
                                                </React.Fragment>)}
                                                {e.type == "People" && (
                                                    <React.Fragment>
                                                        {midDesk.people && midDesk.people.length > 0 ?
                                                            (<>
                                                                {midDesk.people.map((peoplelist) => (
                                                                    <span className="form-value">
                                                                        <b>{peoplelist.name}</b>
                                                                        <p>
                                                                            Role : {(peoplelist.titles.length > 0 ? peoplelist.titles[0].title : "Not found")}<br />
                                                                            Status : {(peoplelist.sources.length > 0) ? peoplelist.sources[0].metadata.status : "Not found"}<br />
                                                                            State : {(peoplelist.sources.length > 0) ? peoplelist.sources[0].metadata.state : "Not found"}<br />
                                                                            Jurisdiction : {(peoplelist.sources.length > 0) ? peoplelist.sources[0].metadata.jurisdiction : "Not found"}<br />
                                                                        </p>
                                                                    </span>
                                                                ))}
                                                            </>)
                                                            : (
                                                                <React.Fragment><span className="form-value">People details not found</span></React.Fragment>
                                                            )}
                                                    </React.Fragment>
                                                )}
                                                {e.type == "Profile" && (
                                                    <React.Fragment>

                                                        {midDesk.profiles && midDesk.profiles.length > 0 ?
                                                            (<>
                                                                {midDesk.profiles.map((profile) => (
                                                                    <span className="form-value">
                                                                        <p>
                                                                            Type : {profile.type}<br />
                                                                            url : {profile.url}<br />
                                                                        </p>
                                                                    </span>
                                                                ))}
                                                            </>)
                                                            : (
                                                                <React.Fragment><span className="form-value">Profile details not found</span></React.Fragment>
                                                            )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    )
                                }
                               
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            handleClose={e => this.props.onClose()}
        />);
    }
}

