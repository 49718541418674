// Author, Rajeh Rajendran
import React from 'react';
import PropTypes from 'prop-types';
import { RegionDropdown } from 'react-country-region-selector';

const RegionSelect = ({ label, name, country, value, onChange, disabled, required, defaultLabel, errorMsg}) => {
    return (
        <div>
            <label htmlFor={name} className={`form-label-style ${required ? 'required' : ''}`}>{label}</label>
            <RegionDropdown
                country={country}
                value={value}
                onChange={(val) => onChange(name, val)}
                classes="form-control"
                defaultOptionLabel={defaultLabel? defaultLabel : "Select State / Province"}
                disabled={disabled}
            />
            {required && !value && <div className="custom-form-error">{errorMsg? errorMsg : "Please select State / Province"}</div>}
        </div>
    );
}

RegionSelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

RegionSelect.defaultProps = {
    disabled: false,
    required: false
};

export default RegionSelect;
