import React, { Component } from 'react'
import User from '../../../services/User';
import API from '../../../services/API';
import FinancialDocuments from './FinancialDocuments';
import { enumRole, transactionType ,compareTransactionType} from "../../../configs/GlobalConfig";
import ViewInvoiceModal from "../../supplier/Documents/Invoices/ViewInvoiceModal";
import UploadSupplyChainModal from "../../supplier/Documents/SupplyChain/UploadSupplyChainModal";
import { POModal } from '../../supplier/Documents/Po/POModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';

export default class FinancialDocumentsModal extends Component {
    user = new User();
    api = new API();

    constructor(props_) {
        super(props_);
        this.state = {
            invoiceList: [],
            purchaseOrderList: [],
            supplyChainList: [],
            isViewPopupOpen:false,
            selectedDocument:null,
            isViewPurchaseOrder:false,
            isViewSupplyChainFinancing:false
        };
    }

    componentDidMount() {
        if (this.props && this.props.data) {
            this.getAllDocuments();
        }
    }

    getAllDocuments = async () => {
        const { data } = this.props;
        const _documents = await this.api.getallTypeOfDocuments(data._id, data.role);
        const _invoicesList = _documents.filter(d => compareTransactionType(d.documentType.toLowerCase() , transactionType.Invoice));
        const _purchaseOrderList = _documents.filter(d => compareTransactionType(d.documentType.toLowerCase() , transactionType.PurchaseOrder));
        const _supplyChainList = _documents.filter(d => compareTransactionType(d.documentType.toLowerCase() , transactionType.SupplyChain));
        this.setState({ 
            invoiceList: _invoicesList,
            purchaseOrderList: _purchaseOrderList,
            supplyChainList: _supplyChainList
        })
    }

    onTransactionClick=(e,doc_,type_)=>{
        e.preventDefault();
        switch(type_){
            case transactionType.Invoice:
                this.setState({
                    isViewPopupOpen:true,
                    selectedDocument:doc_
                })
                break;
            case transactionType.PurchaseOrder:
                this.setState({
                    isViewPurchaseOrder:true,
                    selectedDocument:doc_
                })
                break;
            case transactionType.SupplyChain:
                this.setState({
                    isViewSupplyChainFinancing:true,
                    selectedDocument:doc_
                })
                break;
        }

    }


    render() {
        const {
            isViewPopupOpen,
            isViewPurchaseOrder,
            isViewSupplyChainFinancing,
            selectedDocument
        }=this.state;
        return (<>
            {isViewPopupOpen && selectedDocument && <ViewInvoiceModal
                isReadOnly={false}
                data={selectedDocument}
                onClose={() => {
                    this.setState({ isViewPopupOpen: false, selectedDocument: null });
                }}
            />}
            {isViewPurchaseOrder && selectedDocument && <POModal
                isReadOnly={true}
                onClose={() => {  this.setState({ isViewPurchaseOrder: false, selectedDocument: null }); }}
                data={selectedDocument}
            />}
            {(isViewSupplyChainFinancing) && <UploadSupplyChainModal
                isReadOnly={isViewSupplyChainFinancing}
                onClose={() => {
                    this.setState({ isViewSupplyChainFinancing: false, selectedDocument: null });
                }}
                data={selectedDocument}
            />}
            <CommonPopUpModal
                title={this.props.data && this.props.data.name ? this.props.data.name : ""}
                size='medium'
                onClose={e => this.props.onClose()}
                promptOnClose={false}
            >
                <div >
                    {this.props.data && this.props.data.role && (this.props.data.role.toLowerCase() == enumRole.Supplier) && (
                        <>
                            <TransactionDocuments
                                transactionName_={"Invoice Documents"}
                                transactionList_={this.state.invoiceList}
                                emptyTransactionMsg_={"No invoice documents uploaded"}
                                onTransactionClick={this.onTransactionClick}
                                transactionType_={transactionType.Invoice}
                            />
                            <TransactionDocuments
                                transactionName_={"Purchase Order Documents"}
                                transactionList_={this.state.purchaseOrderList}
                                emptyTransactionMsg_={"No purchase order documents uploaded"}
                                onTransactionClick={this.onTransactionClick}
                                transactionType_={transactionType.PurchaseOrder}
                            />
                        </>
                    )}
                    {this.props.data && this.props.data.role && (this.props.data.role.toLowerCase() == enumRole.Buyer) && (
                        <>
                            <TransactionDocuments
                                transactionName_={"Supply Chain Documents"}
                                transactionList_={this.state.supplyChainList}
                                emptyTransactionMsg_={"No supply chain finance documents uploaded"}
                                onTransactionClick={this.onTransactionClick}
                                transactionType_={transactionType.SupplyChain}
                            />
                        </>
                    )}
                </div>
            </CommonPopUpModal>
        </>
        )
    }
}

const TransactionDocuments = ({transactionName_,transactionList_,emptyTransactionMsg_,onTransactionClick,transactionType_}) => {
    return (
        <div className='row py-2'>
            <div className="col-lg-12">
                <label className="view-details-header pb-2">{transactionName_}</label>
            </div>
            {transactionList_.length > 0 ? (<>
                {
                    transactionList_.map(d_ =>
                        <div className='col-md-3'><a className="anchorstyle" href='' onClick={(e)=>{
                            onTransactionClick(e, d_,transactionType_);
                        }}>{d_.documentId}</a></div>
                    )
                }
            </>) : (
                <div className='pl-3'>{emptyTransactionMsg_}</div>
            )}
        </div>
    );
}




