import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "./Login.css";

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Stepper from "react-stepper-horizontal";
import Spinner from "../Spinner/Spinner";
import Logo from '../../../assets/icons/newlogo.png';
import { STATES } from "../../../configs/States";
import COMPANY_TYPES from "../../../configs/Company";
import INDUSTRIES from "../../../configs/Industries";
import { UUID, validateEmail,allCountryDetails ,countryCallingCode,countryList,passwordRegexString, validatePassword } from "../../../services/Utilities";
import { AddModal } from "../Modals/CommonConfirmationModal";
import AppState from "../../../contexts/AppState/AppState";
import User from "../../../services/User";
import API from "../../../services/API";
import { FormErrors, renderPasswordValidation } from "./FormErrors";
import AllPagesPDFViewer from "../../../services/Pdf.allPages";
import Popup from './Popup';
import PDF from '../../../assets/doc/lf.pdf';
import SupplierPDF from '../../../assets/doc/LedgerFunding_Early_Adopter_Agreement_Supplier.pdf';
import FunderPDF from "../../../assets/doc/LedgerFunding_Early_Adopter_Agreement_Funder.pdf";
import BuyerPDF from "../../../assets/doc/LedgerFunding_Early_Adopter_Agreement_Buyer.pdf";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudDownloadAlt
} from "@fortawesome/free-solid-svg-icons";
import CommonTermsOfUserPDF from "../../../assets/doc/LedgerFunding_Terms_of_Use.pdf";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { GoogleReCaptchaProvider,GoogleReCaptcha,} from 'react-google-recaptcha-v3';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
const { getCode, getName } = require('country-list');
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose'
import i18next from "i18next";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { supported_languages } from '../../../services/languages';
import { changeLanguage } from '../../../services/localizationService';
import TranslateIcon from '@mui/icons-material/Translate';

const captchaRef = React.createRef(null);

const hCaptchaRef = React.createRef();

var countNum = 0;

const numberMask = createNumberMask({
  prefix: "$",
  suffix: ""
});

class Register extends Component {
  user = new User();
  api = new API();

  constructor(props) {
    super(props);
    this.state = {
      isEarlyprog: true,
      isPopupOpen: false,
      pdfSetPageNum: null,
      pageName: 1,
      user: {
        email: "",
        userName:"",
        password: "",
        confirmPassword: "",
        name: "",
        title:"",
        address: "",
        city: "",
        country:"",
        state: "",
        zip: "",
        phone: "",
        year: "",
        entity: 0,
        industry: 0,
        taxId: "DUMMYTAXID", // To Do : Remove when tax id validation from API
        revenue: "",
        balance: 1,
        accountingInformation:"erpinfo",
        websiteUrl:""
      },
      v2User: {
        email: "",
        userName:"",
        password: "",
        confirmPassword: "",
        name: "",
        title:"",
        address: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          country: "",
          zip: ""
        },
        phone: "",
        incorporationYear: "",
        entity: "0",
        industry: "0"
      },
      isLoading: false,
      isPullingDataFromInvoice: false,
      step: 0,
      formErrors: { email: "", password: "", confirmPassword: "" },
      emailValid: false,
      passwordValid: false,
      passwordValidation: {
        lengthValid: false,
        capitalValid: false,
        numberValid: false,
        noSpaces: false
      },
      confirmPasswordValid: false,
      formValid: false,
      isShowConfirmPassword:false,
      isShowPassword:false,
      websiteUrlValid:false,
      os: "",
      selectedCountry:"",
      selectedState:"",
      recaptchaToken:"",
      isValidPhoneNumberError:true,
      selectedCountryCode:"",
      buyerAddressFromInvite: {
        address: "",
        city: "",
        country:"",
        state: "",
        zip: "",
        phone: "",
      },
      isDataFromBuyerInvite:false,
      buyerDetails:{},
      isAllowedCountryForCaptcha:true
    };
    this.toggleChange = this.toggleChange.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleDollarChangeField = this.handleDollarChangeField.bind(this);
    this.checkIfSignUpButtonIsEnabled = this.checkIfSignUpButtonIsEnabled.bind(this);
    this.checkIfNextButtonIsEnabled = this.checkIfNextButtonIsEnabled.bind(this);
    this.signUp = this.signUp.bind(this);
    this.v2SignUp = this.v2SignUp.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.handleShowPassword=this.handleShowPassword.bind(this);
    this.handleShowConfirmPassword=this.handleShowConfirmPassword.bind(this);
  }

  componentDidMount() {
    this.checkAllowedCountriesForCaptacha();
    if (this.user.getBuyerToken() !== '') {
      this.initCustomerDetails();
    }
    if(this.user.getSupplierToken()!==''){
      this.initSupplierDetails();
    }
    this.setState({
      os: this.getOS()
    })
  }

  checkAllowedCountriesForCaptacha = async () => {
    let _res = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    _res = await _res.text();
    const _ipLocation = _res.match(/loc\=([A-Z.]*)/)[1];
    if (process.env.REACT_APP_NOT_ALLOWED_COUNTRIES.split(",").indexOf(_ipLocation)!=-1) {
      this.setState({isAllowedCountryForCaptcha : false});
    }
  }
  
  getOS() {
    const platform = window.navigator.platform
    return platform;
  }
  handleChangeField(event) {
    event.preventDefault();
    const { user } = this.state;
    const name = event.target.name;
    const value = event.target.value;
    user[name] = value;
    this.setState({ user }, () => {
      this.validateField(name, value);
    });
  }

  handleShowPassword(){
    this.setState({isShowPassword:!this.state.isShowPassword});
  }

  handleShowConfirmPassword(){
    this.setState({isShowConfirmPassword:!this.state.isShowConfirmPassword});
  }

  handleRecaptchaToken(token_){
    // if(token_){
    //   this.setState({recaptchaToken:token_});
    // }
  }

  initCustomerDetails = async () => {
    //AK: Need to fix later : call one time instead of calling multiple times
    countNum++;
    console.log(countNum);
    if (countNum >2) {
      console.log("Calling Token api condition:", countNum);
      const buyerDetails = await this.api.getBuyersDetailsByToken(this.user.getBuyerToken())
      if (buyerDetails && !buyerDetails.message) {
        this.setState({ buyerDetails: buyerDetails });
      } else {
        buyerDetails = this.state.buyerDetails;
      }
      if (buyerDetails) {
        const {
          user,
          buyerAddressFromInvite
        } = this.state;
        let selectedCountryCode_ = "";
        user.email = buyerDetails.buyerEmail;
        user.supplierID = buyerDetails.supplierId ? buyerDetails.supplierId : "";
        user.invoiceId = buyerDetails.invoiceId ? buyerDetails.invoiceId : "";
        user.name = buyerDetails.fromInvoice.name || "";
        user.address = $.trim(`${buyerDetails.fromInvoice.address.streetAddress1} ${buyerDetails.fromInvoice.address.streetAddress2}`) || "";
        user.city = buyerDetails.fromInvoice.address.city || "";
        user.state = buyerDetails.fromInvoice.address.region || "";
        user.phone = buyerDetails.fromInvoice.address.phone ? buyerDetails.fromInvoice.address.phone : "";
        user.zip = buyerDetails.fromInvoice.address.postalCode ? buyerDetails.fromInvoice.address.postalCode : "";
        if (buyerDetails.fromInvoice.address) {
          let countryValue_ = buyerDetails.fromInvoice.address.country ? buyerDetails.fromInvoice.address.country :
            buyerDetails.fromInvoice.address.countryCode ? buyerDetails.fromInvoice.address.countryCode : "";
          if (countryValue_.length == 2) {
            selectedCountryCode_ = countryValue_;

            allCountryDetails().forEach(country_ => {
              if (country_.countryShortCode == countryValue_) {
                user.country = country_.countryName;
              }
            })
          }
          if (countryValue_.length > 2) {
            let allCountryDetails_ = allCountryDetails();
            if (allCountryDetails_.length > 0) {
              allCountryDetails_.forEach(country_ => {
                if (country_.countryName.toLocaleLowerCase() == countryValue_.toLocaleLowerCase()) {
                  selectedCountryCode_ = country_.countryShortCode;
                  countryValue_=country_.countryName;
                }
              })
            }
            user.country = countryValue_;
          }
        }
        //user.country=buyerDetails.fromInvoice.address.country?getName(buyerDetails.fromInvoice.address.country):
        //buyerDetails.fromInvoice.address.countryCode?getName(buyerDetails.fromInvoice.address.countryCode):"";
        // user.country=user.country=="United States of America"?"United States":user.country;
        if (user.country == "United States" && user.state.length == 2) {
          const filteredState_ = STATES.filter(s_ => s_.region == user.state);
          if (filteredState_.length > 0) {
            user.state = filteredState_[0].name;
          }
        }
        if (user.country.length == 0 && user.state.length > 0) {
          let allCountryDetails_ = allCountryDetails();
          let stateValue_ = "";
          let countryValue_ = "";
          let countryCodeValue_ = "";
          allCountryDetails_.forEach(country_ => {
            country_.stateList.forEach(state_ => {
              if (user.state.length == 2) {
                if (user.state == state_.stateCode) {
                  stateValue_ = state_.stateName;
                  countryValue_ = country_.countryName;
                  countryCodeValue_ = country_.countryShortCode;
                }
              }
              else {
                if (user.state == state_.stateName) {
                  stateValue_ = state_.stateName;
                  countryValue_ = country_.countryName;
                  countryCodeValue_ = country_.countryShortCode;
                }
              }
            })
          })
          if (stateValue_.length > 0) {
            user.state = stateValue_;
          }
          if (countryValue_.length > 0) {
            user.country = countryValue_;
          }
          if (countryCodeValue_.length > 0) {
            selectedCountryCode_ = countryCodeValue_.toLocaleLowerCase();
          }
        }
        buyerAddressFromInvite.address = user.address;
        buyerAddressFromInvite.city = user.city;
        buyerAddressFromInvite.state = user.state;
        buyerAddressFromInvite.zip = user.zip;
        let selecteCountry_ = user.country;
        let selectedState_ = user.state;
        if (selectedCountryCode_.length == 0) {
          selectedCountryCode_ = buyerDetails.fromInvoice.address.country ? buyerDetails.fromInvoice.address.country.toLocaleLowerCase() :
            buyerDetails.fromInvoice.address.countryCode ? buyerDetails.fromInvoice.address.countryCode.toLocaleLowerCase() : "";
        }
        let callingCode_ = countryCallingCode.filter(phoneFormat_ => {
          if (phoneFormat_.code == selectedCountryCode_.toLocaleUpperCase()) {
            return true;
          }
        })
        if (callingCode_.length > 0) {
          if (selectedCountryCode_.toLocaleUpperCase() == "GB") {
            if ((user.phone).charAt(0) == 0) {
              user.phone = callingCode_[0].dial_code + (user.phone).substring(1)
            }
            else {
              user.phone = callingCode_[0].dial_code + user.phone;
            }
          }
          else {
            user.phone = (user.phone && user.phone.length > 0) ? (callingCode_[0].dial_code + user.phone) : "";
          }
        }
        buyerAddressFromInvite.phone = user.phone;
        this.validateField("email", user.email);
        this.setState({
          user,
          selectedCountry: selecteCountry_,
          selectedCountryCode: (selectedCountryCode_ && selectedCountryCode_.length > 0) ? selectedCountryCode_.toLocaleLowerCase() : "",
          selectedState: selectedState_,
          isDataFromBuyerInvite: true,
          buyerAddressFromInvite
        });
      }

    }
  }

  initSupplierDetails=async()=>{
   // this.setState({ isLoading: true });
    countNum++;
    if(countNum >2){
      const supplierDetails=await this.api.getSuppliersDetailsByToken(this.user.getSupplierToken());
      if(supplierDetails){
        let {user,buyerAddressFromInvite}=this.state;
        let selectedCountryCode_="";
        user.email = supplierDetails.supplierEmail;
        user.buyerID = supplierDetails.buyerId;
        user.name = supplierDetails.fromInvoice.name ||"";
        user.address = $.trim(`${supplierDetails.fromInvoice.address.streetAddress1} ${supplierDetails.fromInvoice.address.streetAddress2}`)||"";
        user.city = supplierDetails.fromInvoice.address.city ||"";
        user.state = supplierDetails.fromInvoice.address.region ||"";
        user.phone = supplierDetails.fromInvoice.address.phone ? supplierDetails.fromInvoice.address.phone : "";
        user.zip=supplierDetails.fromInvoice.address.postalCode?supplierDetails.fromInvoice.address.postalCode:"";
        if(supplierDetails.fromInvoice.address){
          let countryValue_=supplierDetails.fromInvoice.address.country?supplierDetails.fromInvoice.address.country:
          supplierDetails.fromInvoice.address.countryCode?supplierDetails.fromInvoice.address.countryCode:"";
          if(countryValue_.length ==2){
            selectedCountryCode_=countryValue_;

            allCountryDetails().forEach(country_=>{
              if(country_.countryShortCode==countryValue_){
                user.country=country_.countryName;
              }
            })
          }
          if (countryValue_.length > 2) {
            let allCountryDetails_ = allCountryDetails();
            if (allCountryDetails_.length > 0) {
              allCountryDetails_.forEach(country_ => {
                if (country_.countryName == countryValue_) {
                  selectedCountryCode_ = country_.countryShortCode;
                }
              })
            }
            user.country=countryValue_;
          }
        }
        if(user.country=="United States" && user.state.length==2){
          const filteredState_=STATES.filter(s_=>s_.region==user.state);
          if(filteredState_.length>0){
            user.state=filteredState_[0].name;
          }
        }
        if(user.country.length==0 && user.state.length>0){
          let allCountryDetails_=allCountryDetails();
          let stateValue_="";
          let countryValue_="";
          let countryCodeValue_="";
          allCountryDetails_.forEach(country_=>{
            country_.stateList.forEach(state_=>{
              if(user.state.length==2 ){
                  if(user.state==state_.stateCode){
                    stateValue_=state_.stateName;
                    countryValue_=country_.countryName;
                    countryCodeValue_=country_.countryShortCode;
                  }
              }
              else{
                if(user.state==state_.stateName){
                  stateValue_=state_.stateName;
                  countryValue_=country_.countryName;
                  countryCodeValue_=country_.countryShortCode;
                }
              }
            })
          })
          if(stateValue_.length>0){
            user.state=stateValue_;
          }
          if(countryValue_.length>0){
            user.country=countryValue_;
          }
          if(countryCodeValue_.length>0){
            selectedCountryCode_=countryCodeValue_.toLocaleLowerCase();
          }
        }
        buyerAddressFromInvite.address=user.address;
        buyerAddressFromInvite.city=user.city;
        buyerAddressFromInvite.state=user.state;
        buyerAddressFromInvite.zip=user.zip;
        let selecteCountry_=user.country;
        let selectedState_=user.state;
        if(selectedCountryCode_.length==0){
          selectedCountryCode_=supplierDetails.fromInvoice.address.country?supplierDetails.fromInvoice.address.country.toLocaleLowerCase():
          supplierDetails.fromInvoice.address.countryCode?supplierDetails.fromInvoice.address.countryCode.toLocaleLowerCase():"";
        }
        let callingCode_=countryCallingCode.filter(phoneFormat_=>{
          if(phoneFormat_.code==selectedCountryCode_.toLocaleUpperCase()){
            return true;
          }
        })
        if(callingCode_.length>0){
          if(selectedCountryCode_.toLocaleUpperCase()=="GB"){
            if((user.phone).charAt(0)==0){
              user.phone=callingCode_[0].dial_code + (user.phone).substring(1)
            }
            else{
              user.phone=callingCode_[0].dial_code + user.phone;
            }
          }
          else{
            user.phone=(user.phone && user.phone.length>0)?(callingCode_[0].dial_code + user.phone):"";
          }
        }
        buyerAddressFromInvite.phone=user.phone;
        this.validateField("email", user.email);
        this.setState({
          user,
          selectedCountry: selecteCountry_,
          selectedCountryCode:(selectedCountryCode_&& selectedCountryCode_.length>0)? selectedCountryCode_.toLocaleLowerCase():"",
          selectedState: selectedState_,
          isDataFromBuyerInvite: true,
          buyerAddressFromInvite
        });
      }

    }
    //this.setState({ isLoading: false });

  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;
    let websiteUrlValid=this.state.websiteUrlValid
    let _errorMsg="";
    let validationState = this.state.passwordValidation;
    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? "" : this.props.t("validation_msg.email_address_is_invalid");
        break;
      case "password":
        validationState = validatePassword(value)
        passwordValid = Object.values(validationState).every(Boolean)
        fieldValidationErrors.password = !passwordValid;
      
        if(this.state.user && this.state.user.confirmPassword && this.state.user.confirmPassword.length>0){
          confirmPasswordValid = this.state.user.confirmPassword === this.state.user.password;
          fieldValidationErrors.confirmPassword = confirmPasswordValid
            ? ""
            : this.props.t("password_criterias.password_not_match");
        }
        break;
      case "confirmPassword":
        confirmPasswordValid = value === this.state.user.password;
        fieldValidationErrors.confirmPassword = confirmPasswordValid
          ? ""
          : this.props.t("password_criterias.password_not_match");
        if(this.state.user && this.state.user.password && this.state.user.password.length>0){
          fieldValidationErrors.password = !passwordValid;
        }
        break;
      // case "websiteUrl":
      //   var res = value.match(/^(https?:\/\/)?([a-z0-9\-]+\.)+[a-z]{2,}(\/.*)?$/i);
      //   websiteUrlValid=res !== null
      //   fieldValidationErrors.websiteUrl = websiteUrlValid ? "" : "Website address is invalid.";
      //   break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        passwordValid: passwordValid ? true : false,
        passwordValidation: validationState,
        confirmPasswordValid: confirmPasswordValid,
        websiteUrlValid:websiteUrlValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.passwordValid &&
        this.state.confirmPasswordValid
    });
  }

  handleDollarChangeField(fieldName, amount) {
    const { user } = this.state;
    user[fieldName] = amount;
    this.setState({ user });
  }
  onDocumentLoadSuccess({ numPages }) {
    this.setNumPages(numPages);
  }
  toggleChange() {
    this.setState({
      isEarlyprog: !this.state.isEarlyprog,
    });
  }
  togglePopup() {
    this.setState({
      isPopupOpen: !this.state.isPopupOpen,
    })
  }

  //...not changed the method...//
  async signUp() {
     this.setState({ isLoading: true });
    const { user, websiteUrlValid } = this.state;
    let _selectedLanguage = localStorage.getItem("i18nextLng");
    _selectedLanguage = _selectedLanguage == "en-US" ? "en" : _selectedLanguage;
    user.language = _selectedLanguage;
    // if (user.websiteUrl && user.websiteUrl.length>0 && !websiteUrlValid) {
    //   let fieldValidationErrors = this.state.formErrors;
    //   fieldValidationErrors.websiteUrl = "Please enter valid website address.";
    //   this.setState({ isLoading: false, formErrors: fieldValidationErrors });
    //   return;
    // }
    if (this.user.currentUserRole() === "funder") {
      delete user.accountingInformation;
      delete user.industry;
      delete user.revenue;
      delete user.balance;
    }
    if (this.user.currentUserRole() == "buyer") {
      delete user.accountingInformation;
      delete user.balance;
    }
    let signUpResponse;
    try {
      signUpResponse = await this.api.signUp(user);
    } catch (error) {
      console.log('error message from API', error);
    }
    this.setState({ isLoading: false });
    if (this.user.currentUserRole() === "funder") {
      if (signUpResponse.isExistingUser) {
        await AddModal(this.props.t("message_label"), this.props.t("already_having_account_msg"), null, false);
        window.location.href = '/login';
      }
      else {
        //await this.api.sendApprovalEmail(signUpResponse.id);
        this.setState({ isLoading: false });
        AddModal( this.props.t("message_label"), this.props.t("user_created_success_msg") , null, false)
        .then(r => {
          return window.location.href = '/login';
        })
        .catch(() => {
            return window.location.href = '/login';
        });
      }
    }
    if (this.user.currentUserRole() == "buyer") {
      if(this.state.user.supplierID){
        this.api.logInWithEmailAndPassword({
          email: user.email,
          password: user.password
        }).then(res => {
          //this.setState({ isLoading: false });
          if (res.verified) {
            localStorage.setItem('LEDGER_FUNDING_CURRENT_USER', JSON.stringify(res));
            if (this.user.currentUserRole() === "supplier") {
              this.user.setCurrentUserType("InvoiceFinancing")
              window.location.href = '/dashboard';
            }
            else {
              this.user.setCurrentUserType("InvoiceFinancing")
              window.location.href = '/document';
            }
  
          }
        });
      }
      else{
        if (signUpResponse.isExistingUser) {
          await AddModal(this.props.t("message_label"),this.props.t("already_having_account_msg"), null, false);
          window.location.href = '/login';
        }
        else {
          //await this.api.sendApprovalEmail(signUpResponse.id);
           //this.setState({ isLoading: false });
          AddModal(this.props.t("message_label"),this.props.t("user_created_success_msg"), null, false)
          .then(r => {
            return window.location.href = '/login';
          })
          .catch(() => {
              return window.location.href = '/login';
          });
        }
      }
    }
  }

  //..Add Json in Api calls..//
  async v2SignUp() {
    this.setState({ isLoading: true });
    const { v2User, user, websiteUrlValid } = this.state;
    v2User.email = user.email;
    v2User.password = user.password;
    v2User.confirmPassword = user.confirmPassword;
    v2User.name = user.name;
    v2User.userName = user.userName;
    v2User.title = user.title;
    v2User.address.address1 = user.address;
    v2User.address.city = user.city;
    v2User.address.state = user.state;
    v2User.address.country = user.country;
    v2User.address.zip = user.zip;
    v2User.phone = user.phone;
    v2User.incorporationYear = user.year;
    v2User.entity = user.entity;
    v2User.industry = user.industry;
    v2User.accountingInformation = user.accountingInformation;
    v2User.websiteUrl = user.websiteUrl;
    v2User.buyerID = user.buyerID;
    let _selectedLanguage = localStorage.getItem("i18nextLng");
    _selectedLanguage = _selectedLanguage == "en-US" ? "en" : _selectedLanguage;
    v2User.language = _selectedLanguage;
    const signUpResponse = await this.api.v2SignUp(v2User);
    if (signUpResponse.isExistingUser) {
      await AddModal(this.props.t("message_label"), this.props.t("already_having_account_msg"), null, false);
      window.location.href = '/login';
    }
    else if (this.state.user.buyerID) {
      this.api.logInWithEmailAndPassword({
        email: user.email,
        password: user.password
      }).then(res => {
        if (res.verified) {
          localStorage.setItem('LEDGER_FUNDING_CURRENT_USER', JSON.stringify(res));
          if (this.user.currentUserRole() === "supplier") {
            this.user.setCurrentUserType("InvoiceFinancing")
            //this.setState({ isLoading: false });
            window.location.href = '/dashboard';
          }
          else {
            // this.setState({ isLoading: false });
            window.location.href = '/dashboard';
          }

        }
      });
    }
    else {
      // await this.api.sendApprovalEmail(signUpResponse.id);

      // this.setState({ isLoading: false });
      AddModal(this.props.t("message_label"), this.props.t("user_created_success_msg"), null, false)
      .then(r => {
        return window.location.href = '/login';
      })
      .catch(() => {
          return window.location.href = '/login';
      });
    }

  }

  async checkBuyer(email) {
    //this.setState({ isLoading: true });
    try {
      const emailCheckRes = await this.api.checkBuyer(email);
     // this.setState({ isLoading: false });
      if (emailCheckRes && emailCheckRes.json && emailCheckRes.json.message) {
        return null;
      } else {
        const { user } = this.state;
        this.setState(
          {
            isPullingDataFromInvoice: true,
            user: {
              ...this.state.user,
              name: emailCheckRes.name,
              phone: emailCheckRes.phone,
              city: emailCheckRes.city,
              state: emailCheckRes.state,
              address: emailCheckRes.address,
              zip: emailCheckRes.zip
            }
          },
          () => {
          }
        );
      }
    } catch (err) {
      alert(err); // TypeError: failed to fetch
     // this.setState({ isLoading: false });
    }
  }

  checkIfSignUpButtonIsEnabled() {
    const { appState } = this.props;
    const {
      user: {
        industry,
        year,
        entity,
        taxId,
        revenue,
        balance,
        websiteUrl
      },
      step,
      isEarlyprog,
      websiteUrlValid
    } = this.state;
    const isStepThreeEnabled =
      parseInt(year, 10) > 1900 &&
      parseInt(year, 10) < 2099 &&
      entity > 0 &&
      industry > 0 ;
    const isStepThreeEnabledAsFunder = parseInt(year, 10) > 1900 && parseInt(year, 10) < 2099 && entity > 0 && taxId.length > 0 && balance > 0;
    const isStepThreeEnabledAsBuyer = parseInt(year, 10) > 1900 && entity > 0 && taxId.length > 0 && revenue > 0;
    if (this.user.currentUserRole() === 'supplier') {
      return !isStepThreeEnabled;
    } else if (this.user.currentUserRole() === 'buyer') {
      return !isStepThreeEnabledAsBuyer;
    } else {
      return !isStepThreeEnabledAsFunder;
    }
  }
  //Make Next to Enable or Disable
  checkIfNextButtonIsEnabled() {
    const { appState } = this.props;
    const {
      isEarlyprog,
    } = this.state;
    const isStepThreeEnabled = isEarlyprog == false;
    if (this.user.currentUserRole() === 'supplier' || this.user.currentUserRole() === 'funder' || this.user.currentUserRole() === 'buyer') {
      return !isStepThreeEnabled;
    } else {
      return false;
    }
  }

  handleCountryChange=(val)=>{
    const { user,buyerAddressFromInvite,isDataFromBuyerInvite,selectedState } = this.state;
    let selectedState_="";
    let selectedCountryCode_="";
    user["country"] = val;
    user["city"] = "";
    user["state"] = "";
    user["zip"] = "";
    user["phone"] = "";
    let allCountryDetails_= allCountryDetails();
    if (allCountryDetails_.length > 0) {
      allCountryDetails_.forEach(country_ => {
        if(val.length==2){
          selectedCountryCode_=val;
        }
        else if (country_.countryName == val) {
          selectedCountryCode_=country_.countryShortCode;
        }
      })
    }
    if(isDataFromBuyerInvite){
      user.phone=buyerAddressFromInvite.phone;
      user.state=buyerAddressFromInvite.state;
      if(val && selectedState.length==2){
       if(allCountryDetails_.length>0){
        allCountryDetails_.forEach(country_=>{
          if(country_.countryName==val){
            country_.stateList.forEach(state_=>{
              if(selectedState==state_.stateCode){
                selectedState_=state_.stateName;
              }
            })
          }
        })
       }
      }
    }
    this.setState({
      selectedCountry:val,
      selectedCountryCode:selectedCountryCode_.toLocaleLowerCase(),
      user,
      selectedState:selectedState_.length>0?selectedState_:selectedState
    });
  }

  handleStateChange=(val)=>{
    const {user} =this.state;
    user["state"]=val;
    this.setState({selectedState:val,user});
  }

  handlePhoneChangeField=(val, data, event, formattedValue)=>{
    const {user} =this.state;
    user["phone"]=formattedValue;
    this.setState({user});
  }

    //Log Hcaptcha expire
    onExpireHcaptcha = () => {
      console.log("hCaptcha Token Expired");
    };
   
    //Log Hcaptcha error
    onErrorHcaptcha = (err) => {
      console.log(`hCaptcha Error: ${err}`);
    };
  
    //Set Htcaptcha verification token
    handleOnVerifyHcaptcha=(token,key)=>{
     this.setState({recaptchaToken:token});
    }

  static renderLoadingView() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row d-flex justify-content-center align-items-center loading-wrapper">
              <Spinner t={i18next.t}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  renderLoginView() {
    const { history } = this.props;
    const {
      user: {
        email,
        userName,
        password,
        confirmPassword,
        name,
        address,
        city,
        state,
        zip,
        phone,
        title,
        year,
        entity,
        industry,
        taxId,
        revenue,
        balance
      },
      step,
      isPullingDataFromInvoice,
      selectedCountry,
      selectedState,
      passwordValidation,
      formErrors
    } = this.state;

    const isStepOneEnabled =
      validateEmail(email) &&
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password === confirmPassword;
    const isStepTwoEnabled =
      name.length > 0 &&
      address.length > 0 &&
      selectedCountry.length> 0 &&
      city.length > 0 &&
      state.length > 0 &&
      zip.length > 0 &&
      phone.length > 8 &&
      title.length>0 &&
      userName.length>0;
      const { anchorEl } = this.state;
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;
    return (
      <AppState.Consumer>
        {appState => (
          <div className="container-fluid login-wrapper">
             <img className="signup-logo" src={Logo} alt="LedgerFunding logo" />
            <div className="row">
              <div className="signup-col-width">
                <div className="row d-flex flex-column justify-content-center align-items-center h-100">
                  <div className="signup-form-width">
                    {/* <div className="login-form shadow-lg card"> */}
                    <div className="text-center mt-5">
                      <h3 className="title-form-page">{this.props.t("signup_up_page.title")}</h3>
                    </div>
                    <div className="num-step-container pt-2">
                      <div className={ "num-step-item"}>
                        <div className="num-step-item-title">01</div>
                        <div className="num-step-item-subtitle">{this.props.t("signup_up_page.steps_name.account")}</div>
                      </div>
                      <div className={ step>=1?"num-step-item":"num-step-item link-text-color"}>
                        <div className="num-step-item-title">02</div>
                        <div className="num-step-item-subtitle">{this.props.t("signup_up_page.steps_name.profile")}</div>
                      </div>
                      <div className={ step>=2?"num-step-item":"num-step-item link-text-color"}>
                        <div className="num-step-item-title">03</div>
                        <div className="num-step-item-subtitle">{this.props.t("signup_up_page.steps_name.business")}</div>
                      </div>
                    </div>
                    {/* <FormErrors formErrors={this.state.formErrors} /> */}
                    <form
                      id="accountForm"
                      className="text-center p-4 signup-form"
                      onSubmit={e => e.preventDefault()}
                      autoComplete="off"
                    >
                      <AccountForm
                        passwordValidation={passwordValidation}
                        formErrors={formErrors}
                        handleChange={this.handleChangeField}
                        toggleChange={this.toggleChange}
                        togglePopup={this.togglePopup}
                        onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                        isShowConfirmPassword={this.state.isShowConfirmPassword}
                        isShowPassword={this.state.isShowPassword}
                        handleShowConfirmPassword={this.handleShowConfirmPassword}
                        handleRecaptchaToken={this.handleRecaptchaToken}
                        handleShowPassword={this.handleShowPassword}
                        email={email}
                        isHidden={step !== 0}
                        handleCountryChange={this.handleCountryChange}
                        handleStateChange={this.handleCountryChange}
                        handlePhoneChangeField={this.handlePhoneChangeField}
                        isAllowedCountryForCaptcha={this.state.isAllowedCountryForCaptcha}
                        handleOnVerifyHcaptcha={this.handleOnVerifyHcaptcha}
                        onErrorHcaptcha={this.onErrorHcaptcha}
                        onExpireHcaptcha={this.onExpireHcaptcha}
                        t={this.props.t}
                        {...this.state}
                      />

                      {step !== 1 ? (
                        <span />
                      ) : (
                          <div className={step !== 1}>

                            <small className="form-text whiteText text-left">
                              {this.props.t("user_labels.full_name")}
                            </small>
                            <input
                              type="text"
                              id="userName"
                              className="form-control mt-2 mb-4"
                              name="userName"
                              value={userName}
                              onChange={this.handleChangeField}
                              placeholder={this.props.t("user_labels.full_name")}
                              required
                            />
                            <small className="form-text whiteText text-left">
                              {this.props.t("user_labels.job_title")}
                            </small>
                            <input
                              type="text"
                              id="title"
                              className="form-control mt-2 mb-4"
                              name="title"
                              value={this.state.user.title}
                              onChange={this.handleChangeField}
                              placeholder={this.props.t("user_labels.job_title")}
                              required
                            />
                            <small className="form-text whiteText text-left">
                            {this.props.t("user_labels.business_name")}
                            </small>
                            <input
                              type="text"
                              id="name"
                              className="form-control mt-2 mb-4"
                              name="name"
                              value={this.state.user.name}
                              onChange={this.handleChangeField}
                              placeholder={this.props.t("user_labels.business_name")}
                              required
                            />

                            <small className="form-text whiteText text-left">
                             {this.props.t("user_labels.business_Address")}
                            </small>
                            <input
                              type="text"
                              id="address"
                              className="form-control mt-2 mb-4"
                              onChange={this.handleChangeField}
                              name="address"
                              value={this.state.user.address}
                              placeholder={this.props.t("user_labels.business_Address")}
                              required
                            />
                            <small className="form-text whiteText text-left">
                            {this.props.t("user_labels.country")}
                            </small>
                            {(selectedCountry.length > 0  || appState.state.currentUserRole != "buyer" || !isPullingDataFromInvoice)?
                              (<>
                                <CountryDropdown
                                  value={selectedCountry}
                                  classes="form-control mt-2 mb-4"
                                  onChange={(val) => this.handleCountryChange(val)}
                                  defaultOptionLabel={this.props.t("please_select_country_msg")}
                                  whitelist={countryList}
                                />
                              </>) :
                              (<>
                                <input
                                  type="text"
                                  id="country"
                                  className="form-control mt-2 mb-4"
                                  name="country"
                                  value={this.state.user.country}
                                  onChange={this.handleChangeField}
                                  placeholder={this.props.t("please_enter_country")}
                                  required
                                />
                              </>)}


                            <small className="form-text whiteText text-left">
                            {this.props.t("user_labels.city")}
                            </small>
                            <input
                              type="text"
                              id="city"
                              className="form-control mt-2 mb-4"
                              name="city"
                              value={this.state.user.city}
                              onChange={this.handleChangeField}
                              placeholder={this.props.t("user_labels.city")}
                              required
                            />
                            <div className="form-row">
                              <div className="form-group mb-0 col-md-6">
                                <small className="form-text whiteText text-left">
                                {this.props.t("user_labels.state")}
                                </small>
                                {!isPullingDataFromInvoice && (
                                  <>
                                   {(selectedCountry.length > 0 || !isPullingDataFromInvoice) ? (<>
                                    <RegionDropdown
                                        country={selectedCountry}
                                        value={selectedState}
                                        classes="form-control mt-2 mb-4"
                                        defaultOptionLabel={this.props.t("user_labels.state")}
                                        blankOptionLabel={this.props.t("user_labels.state")}
                                        onChange={(val) => this.handleStateChange(val)}
                                        blacklist={{
                                          US: ["Micronesia", "Guam", "Marshall Islands", "Northern Mariana Islands", "Palau", "Puerto Rico"
                                            , "American Samoa", "District of Columbia", "Virgin Islands", "Armed Forces Americas", "Armed Forces Europe, Canada, Africa and Middle East", "Armed Forces Pacific"]
                                        }} required/>
                                        
                                   </>):(<>
                                        <input
                                          type="text"
                                          id="state"
                                          className="form-control mt-2 mb-4"
                                          name="state"
                                          value={this.state.user.state}
                                          onChange={this.handleChangeField}
                                          placeholder="Please enter State / Province"
                                          required
                                        />
                                   </>)}
  
                                   
                                  </>
                                )}
                                {isPullingDataFromInvoice && (

                                  <div>
                                    <small className="form-text whiteText text-left">
                                    {this.props.t("user_labels.zip_code")}
                                    </small>
                                    <input
                                      type="state"
                                      className="form-control"
                                      name="state"
                                      value={this.state.user.state}
                                      onChange={this.handleChangeField}
                                      placeholder={this.props.t("user_labels.zip_code")}
                                      required
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="form-group mb-0 col-md-6">
                                <small className="form-text whiteText text-left">
                                {this.props.t("user_labels.zip_code")}
                                </small>
                                <input
                                  type="text"
                                  name="zip"
                                  id="zip"
                                  className="form-control mt-2 mb-4"
                                  value={this.state.user.zip}
                                  onChange={this.handleChangeField}
                                  placeholder={this.props.t("user_labels.zip_code")}
                                  required
                                  maxLength={10}
                                />
                                {/* <MaskedInput
                                  name="zip"
                                  id="zip"
                                  value={this.state.user.zip}
                                  guide={false}
                                  onChange={this.handleChangeField}
                                  mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
                                  pattern="^\d{5}(?:[-\s]\d{4})?$"
                                  title="Enter a valid US zipcode"
                                  className="form-control mt-2 mb-4"
                                  placeholder="Zip code"
                                  required
                                /> */}
                              </div>

                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-12">
                                <small className="form-text whiteText text-left">
                                {this.props.t("user_labels.phone_number")}
                                </small>
                                {/* <MaskedInput
                                  name="phone"
                                  id="phone"
                                  value={this.state.user.phone}
                                  onChange={this.handleChangeField}
                                  mask={[
                                    "(",
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    ")",
                                    " ",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/
                                  ]}
                                  className="form-control mt-2 mb-4"
                                  placeholder="Phone Number"
                                  // pattern="^[2-9]\d{2}-\d{3}-\d{4}$"
                                  pattern="^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                                  title="Enter a valid US telephone number"
                                  required
                                /> */}
                                {(this.state.selectedCountryCode.length> 0 || appState.state.currentUserRole != "buyer")  ? (<>
                                  <PhoneInput
                                    country={this.state.selectedCountryCode}
                                    value={this.state.user.phone}
                                    countryCodeEditable={false}
                                    onChange={this.handlePhoneChangeField}
                                    disableDropdown={true}
                                    disableCountryGuess={true}
                                    onlyCountries={[this.state.selectedCountryCode]}
                                    key={this.state.selectedCountryCode}
                                    required
                                  />
                                </>) : (
                                  <>
                                    <input
                                      type="number"
                                      name="phone"
                                      id="phone"
                                      className="form-control mt-2 mb-4"
                                      value={this.state.user.phone}
                                      onChange={this.handleChangeField}
                                      placeholder={this.props.t("user_labels.phone_number")}
                                      required
                                    />
                                  </>
                                )}

                              </div>
                            </div>
                          </div>
                      )}

                      <BusinessForm
                        handleChange={this.handleChangeField}
                        handleDollarChangeField={this.handleDollarChangeField}
                        isHidden={step !== 2}
                        {...this.state}
                        t={this.props.t}
                      />
                      <div className="d-flex">
                        {/* {step == 0 &&(
                          
                        )} */}
                        {step > 0 && (

                            <button
                              className="btn btn-primary btn-lg btn-block mt-3 sign-up-btn"
                              type="submit"
                              onClick={() =>
                                this.setState({ step: step - 1 })
                              }
                            >
                              {this.props.t("button_names.back")}
                            </button>
                        )}
                        {step === 0 && (

                          <button
                            type="button"
                            className="btn white-text-border mt-3 sign-up-btn"
                            onClick={() => history.push("/login")}
                          >
                            {this.props.t("button_names.back_to_login")}
                          </button>
                        )}

                          {step < 2 ? ( 
                            <button
                              id="nextButton"
                              className="btn btn-primary btn-lg btn-block mt-3 ml-2 sign-up-btn"
                              type="submit"
                              disabled={ step === 1 && this.state.formValid ? !isStepTwoEnabled : this.checkIfNextButtonIsEnabled()}
                              onClick={async () => {
                                const {user}=this.state;
                                if (step === 0 && this.state.formValid) {
                                  let isExisitngEmailId=false;
                                  isExisitngEmailId=await this.api.checkExistingUser(user.email);
                                  if(isExisitngEmailId){
                                    await AddModal(this.props.t("message_label"), this.props.t("already_having_account_msg"), null, false);
                                    window.location.href = '/login';
                                  }
                                 // if(this.state.recaptchaToken.length>0){
                                    this.setState({ step: step + 1 });
                                 // }
                                }
                                if (step === 1 && this.state.formValid && isStepTwoEnabled) {
                                  this.setState({ step: step + 1 });
                                }
                                if (
                                  appState.state.currentUserRole === "buyer" && step === 0
                                ) {
                                  this.checkBuyer(this.state.user.email);
                                }
                              }}
                            >
                              {this.props.t("button_names.next")}
                            </button>
                            
                          ) : (
                            <button
                              className="btn btn-primary btn-lg btn-block mt-3 ml-2 sign-up-btn"
                              type="submit"
                              onClick={(this.user.currentUserRole() === "supplier") ? this.v2SignUp : this.signUp}
                              disabled={this.checkIfSignUpButtonIsEnabled()}
                            >
                              {this.props.t("button_names.sign_up")}
                            </button>
                          )}
                      </div>
                      
                    </form>
                  </div>
                </div>
                <button aria-describedby={id} onClick={this.handlePopoverOpen} type="button" class="btn mt-2 translate_icon" style={{backgroundColor:"white"}}>
                    <TranslateIcon  fontSize={"large"} style={{color:'#255F79'}} />
                  </button>
                 
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableRestoreFocus
                  >
                    {supported_languages.map((lang_, i) => (

                      <Typography key={lang_.code} sx={{ p: 1 }} style={{ cursor: "pointer" }} onClick={(e) => {
                        changeLanguage(lang_.code);
                        this.setState({ anchorEl: null });
                      }}>{lang_.name}</Typography>
                    ))}
                  </Popover>
              </div>
              {/* <div className="col-md-6 col-sm-12 cover-image login-image d-flex justify-content-center align-items-center" /> */}
            </div>
          </div>
        )}
      </AppState.Consumer>
    );
  }

  render() {
    const { isLoading } = this.state;
    return isLoading ? Register.renderLoadingView() : this.renderLoginView();
  }
}

export default compose(withRouter,withTranslation())(Register);

const AccountForm = ({ formErrors, passwordValidation, handleChange, email, isHidden,
  appState, toggleChange, togglePopup, isPopupOpen,isShowConfirmPassword,isShowPassword,handleShowConfirmPassword,handleShowPassword,os
  ,handleRecaptchaToken,isAllowedCountryForCaptcha,onErrorHcaptcha,onExpireHcaptcha,handleOnVerifyHcaptcha,t}) => (
  <AppState.Consumer>
    {appState => (
      <div className={`${isHidden && "d-none"}`}>
        <small className="form-text whiteText text-left">
          {t("signup_labels.email")}
        </small>
        <input
          type="email"
          id="email"
          className="form-control mb-3"
          name="email"
          value={email}
          autoComplete="off"
          onChange={handleChange}
          placeholder={t("signup_labels.email_address")}
          required
        />
        {formErrors.email && (
          <div className="alert alert-light mt-2 p-2" role="alert">
            {formErrors.email}
          </div>
        )}
        <small className="form-text whiteText text-left">
        {t("signup_labels.password")}
        </small>
        <div className="input-group mb-3">
        <input
          style={{paddingRight:"45px"}}
          type={isShowPassword ? "text" : "password"}
          id="password"
          className="form-control"
          name="password"
          onChange={handleChange}
          placeholder={t("signup_labels.password")}
            //pattern="^(?=.*?[aA-zZ])(?=.*?[0-9])(?=.{8,})"
            //title={t("password_criteria_msg")}
          required
          autoComplete="new-password"
        />
        <div className="input-group-append">
          <span className="input-group-text eye-icon" onClick={handleShowPassword}>
            {isShowPassword ? <VisibilityOff /> : <Visibility />}
          </span>
        </div>
        </div>
        {formErrors.password && renderPasswordValidation(passwordValidation, t)}
        <small className="form-text whiteText text-left">
        {t("signup_labels.confirm_password")}
        </small>
        <div className="input-group mb-3">
        <input
          style={{paddingRight:"45px"}}
          type={isShowConfirmPassword ? "text" : "password"}
          id="confirmPassword"
          className="form-control"
          name="confirmPassword"
          onChange={handleChange}
          placeholder={t("signup_labels.confirm_password")}
            //pattern="^(?=.*?[aA-zZ])(?=.*?[0-9])(?=.{8,})"
          title={t("password_criteria_msg")}
          required
          autoComplete="new-password"
        />
        <div className="input-group-append" onClick={handleShowConfirmPassword}
        >
          <span className="input-group-text eye-icon">
            {isShowConfirmPassword ? <VisibilityOff /> : <Visibility />}
          </span>
        </div>
        </div>
        {formErrors.confirmPassword && (
          <div className="alert alert-light mt-2 p-2" role="alert">
            {formErrors.confirmPassword}
          </div>
        )}
        <small className="form-text whiteText text-left" style={{fontWeight:400,textTransform:"none"}}>
          {t("password_criteria_msg")}.
        </small>
        {(appState.state.currentUserRole == "supplier" ||  appState.state.currentUserRole == "buyer"|| appState.state.currentUserRole == "funder") && (
          <div className="terms-checkbox-container mb-2">
            <small className="form-text whiteText text-left" style={{fontWeight:400,textTransform:"none"}}>
              <label><input type="checkbox" defaultChecked={false} onChange={e => toggleChange()} />
                &nbsp; {t("i_agree_to")} </label>
                <label><a className="checkbox-termsColorChange" style={{textDecoration:"none",cursor:"pointer"}} href="https://www.ledgerfunding.com/terms-of-use/" target="_blank" >&nbsp;{t("term_of_use")}</a></label>
                <label>&nbsp;{t("and")} </label>
                <label><a className="checkbox-termsColorChange" style={{textDecoration:"none",cursor:"pointer"}} href="https://www.ledgerfunding.com/privacy-policy/" target="_blank">&nbsp;{t("privacy_policy")}</a></label>
              {/* <label className="checkbox-termsColorChange" onClick={e => togglePopup()} >&nbsp;Terms of Use</label> */}
              {/* <label>&nbsp;of Early Access Agreement</label> */}
            </small>

            {isPopupOpen && <Popup
              content={<>
                <div>
                  <div>
                    <label className="popup-close-icon" onClick={e => togglePopup()}></label>
                  </div>
                  <div>
                    
                    {(os == "iPad" || os == "iPhone") ? (
                      <div>
                        <a href={CommonTermsOfUserPDF}>
                          <span className="popup-download-icon"><FontAwesomeIcon icon={faCloudDownloadAlt} /></span>
                        </a>
                        <div style={{ height: '100%', width: '100%' }} className="popup-content">
                          <AllPagesPDFViewer pdf={CommonTermsOfUserPDF} />
                        </div>
                      </div>

                    ) : (<iframe
                      src={CommonTermsOfUserPDF}
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                      className="popup-content"
                    ></iframe>)}
                  </div>
                  {/* {(appState.state.currentUserRole == "funder") && (
                    <div>
                    {(os == "iPad" || os == "iPhone") ? (
                      <div style={{ height: '100%', width: '100%' }} className="popup-content">
                        <AllPagesPDFViewer pdf={FunderPDF} />
                      </div>
                    ) : (<iframe
                      src={FunderPDF}
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                      className="popup-content"
                    ></iframe>)}
                  </div>
                  )}
                  {(appState.state.currentUserRole == "supplier") && (
                   <div>
                   {(os == "iPad" || os == "iPhone") ? (
                     <div>
                       <a href={SupplierPDF}>
                         <span className="popup-download-icon"><FontAwesomeIcon icon={faCloudDownloadAlt} /></span>
                       </a>
                       <div style={{ height: '100%', width: '100%' }} className="popup-content">
                         <AllPagesPDFViewer pdf={SupplierPDF} />
                       </div>
                     </div>
                   ) : (<iframe
                     src={SupplierPDF}
                     frameBorder="0"
                     scrolling="auto"
                     height="100%"
                     width="100%"
                     className="popup-content"
                   ></iframe>)}
                 </div>
                  )}
                  {(appState.state.currentUserRole == "buyer") && (
                    <div>
                    {(os == "iPad" || os == "iPhone") ? (
                      <div style={{ height: '100%', width: '100%' }} className="popup-content">
                        <AllPagesPDFViewer pdf={BuyerPDF} />
                      </div>
                    ) : (<iframe
                      src={BuyerPDF}
                      frameBorder="0"
                      scrolling="auto"
                      height="100%"
                      width="100%"
                      className="popup-content"
                    ></iframe>)}
                  </div>
                  )} */}
                </div>
              </>}
              handleClose={e => togglePopup()}
            />}
          </div>

        )}
        {isAllowedCountryForCaptcha ? (
          <>
            <GoogleReCaptchaProvider
              reCaptchaKey="6Lf1vkMdAAAAAIh2hUwKgFOCLv23ShnmClsFea-j"
            >
              <GoogleReCaptcha onVerify={handleRecaptchaToken} />
            </GoogleReCaptchaProvider>
          </>
        ) : (
          <>
            <HCaptcha
              id="hcaptcha-size"
              sitekey="4579b879-7dd5-4168-9134-431374078c8d"
              ref={hCaptchaRef}
              onError={onErrorHcaptcha}
              onExpire={onExpireHcaptcha}
              onVerify={handleOnVerifyHcaptcha}
            />
          </>
        )}

      </div>
    )}
  </AppState.Consumer>
);



const BusinessForm = ({
  handleChange,
  handleDollarChangeField,
  isHidden,
  toggleChange,
  togglePopup,
  isEarlyprog,
  isPopupOpen,
  user: { entity, industry,accountingInformation,websiteUrl,taxId },
  t

}) => (
  <AppState.Consumer>
    {appState => (
      <div className={`${isHidden && "d-none"}`}>
          <small className="form-text whiteText text-left">
          {t("user_labels.year")}?
                              </small>
        <MaskedInput
          name="year"
          id="year"
          onChange={handleChange}
          mask={[/[1-9]/, /\d/, /\d/, /\d/]}
          className="form-control mt-2 mb-4"
          placeholder={t("user_labels.year")}
          pattern="^(19|20)\d{2}$"
          title={t("enter_date_between_range")}
        />
        <div className="form-row">
          <div className="form-group col-md-12">
            <small className="form-text whiteText text-left">
            {t("user_labels.website_address") }
            </small>
            <input
              type="url"
              id="name"
              className="form-control"
              name="websiteUrl"
              value={websiteUrl}
              onChange={handleChange}
              placeholder={t("user_labels.website_address") }
              />
          </div>
        </div>
        {/* {(appState.state.currentUserRole == "funder") && (
          <div className="form-row">
            <div className="form-group col-md-12">
              <small className="form-text whiteText text-left">
                Tax-ID
              </small>
              <MaskedInput
                value={taxId}
                name="taxId"
                id="taxId"
                onChange={handleChange}
                mask={[
                  /[1-9]/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/
                ]}
                className="form-control"
                placeholder="Business Tax ID"
              />
            </div>
          </div>
        )} */}
        <div className="form-row">
          <div className="form-group col-md-12">
          <small className="form-text whiteText text-left">
          {t("user_labels.entity")}
                              </small>
            <select
              className="form-control"
              value={entity}
              id="entity"
              name="entity"
              onChange={handleChange}
            >
               <option value="0" selected>{t("please_select")}</option>
              {COMPANY_TYPES.map((type, i) => (
                <option key={UUID()} value={i + 1}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        {appState.state.currentUserRole !== "funder" && (
          <div className="form-row my-3">
            <div className="form-group col-md-12">
            <small className="form-text whiteText text-left">
            {t("user_labels.industry")}
                              </small>
              <select
                className="form-control"
                value={industry}
                id="industry"
                name="industry"
                onChange={handleChange}
              >
                 <option value="0" selected>{t("please_select") }</option>
                {INDUSTRIES.map((industryType, i) => (
                  <option key={UUID()} value={i + 1}>
                    {industryType}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div className="form-row">
          {appState.state.currentUserRole == "buyer" && (
            <div className="form-group col-md-6">
              <small className="form-text whiteText text-left">
              {t("user_labels.revenue")}
                              </small>
              <MaskedInput
                name="revenue"
                id="revenue"
                onChange={e =>
                  handleDollarChangeField(
                    "revenue",
                    Number(e.target.value.replace(/[^0-9.-]+/g, ""))
                  )
                }
                mask={numberMask}
                className="form-control mb-4"
                placeholder={t("annual_revenue")}
              />
            </div>
          )}
          {/* {appState.state.currentUserRole == "funder" && (
            <div className="form-group col-md-6">
              <small className="form-text whiteText text-left">
              Investment Capital
                              </small>
              <MaskedInput
                name="balance"
                id="balance"
                onChange={e =>
                  handleDollarChangeField(
                    "balance",
                    Number(e.target.value.replace(/[^0-9.-]+/g, ""))
                  )
                }
                mask={numberMask}
                className="form-control mb-4"
                placeholder="Investment Capital"
              />
            </div>
          )} */}
        </div>
      </div>
    )}
  </AppState.Consumer>
);