import React, { Component } from 'react';
import FullSpinner from '../Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import Popup from '../Auth/Popup';
import Dropzone from 'react-dropzone';
import { ShortText } from '../../../services/Utilities';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import FileUploader from '../FileUploader/FileUploader';
import FileViewerModal from '../FileViewerModal/FileViewerModal';
import { withTranslation } from 'react-i18next';

var AgingReportPostdata = {};
class FinancialAdditionalDocuments extends Component {
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isBalanceStatement: false,
            isShortName: false,
            balanceStatement: [],
            deletedBalanceStatement: [],
            newBankStatement: [],
            isUpdateBankStatement: false,
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false
        };
    }
    componentDidMount() {
        if (this.props.data && this.props.data.length > 0) {
            this.setState({ balanceStatement: this.props.data });
        }
    }

    async uploadFinancialHistory() {
        const { balanceStatement, deletedBalanceStatement, newBankStatement } = this.state;
        this.setState({ isLoading: true });
        const documentData = new FormData();
        if (balanceStatement.length == 1) {
            documentData.append("ADDITIONAL_DOCUMENT_1", balanceStatement[0]);
        }
        else {
            balanceStatement.forEach((stmt, i) => {
                documentData.append("ADDITIONAL_DOCUMENT_" + (i + 1), stmt);
            });
        }
        if (deletedBalanceStatement.length > 0 || (newBankStatement.length > 0 && this.props.data.length > 0)) {
            for (let bsmt_ of deletedBalanceStatement) {
                documentData.append('deleteAdditonalDocs[]', JSON.stringify(bsmt_));
            }
            await this.api.updateFinancialAdditionalDocumentsUpload(documentData);
        } else {
            await this.api.financialAdditionalDocumentsUpload(documentData);
        }
        // this.props.onClose();
        this.setState({ isLoading: false });
    }

    onDropBankStatement = (type, documents) => {
        this.setState({
            balanceStatement: documents,
            isBalanceStatement: true,
            isShortName: true,
            newBankStatement: documents
        });
    }; 

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    handleDelete = (filteredDocs, selectedDoc) => {
        selectedDoc.version && selectedDoc.downloadUrl ?
            this.setState(prevState => ({
                balanceStatement: filteredDocs,
                deletedBalanceStatement: [...prevState.deletedBalanceStatement, selectedDoc],
                isBalanceStatement: true,
            })) :

            this.setState(prevState => ({
                balanceStatement: filteredDocs,
                newBankStatement: filteredDocs,
            }));
    };

    render() {
        const {
            isLoading,
            isBalanceStatement,
            balanceStatement,
        } = this.state;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("additional_document_uploaded_msg")} callback={e => this.props.onClose()} />
                        {this.state.isFileViewerModal && this.state.fileViewerContent &&
                            <FileViewerModal
                                onClose={() => {
                                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                                }}
                                data={this.state.fileViewerContent}
                                isFinancialDocument={this.state.isFinancialDocument}
                            ></FileViewerModal>
                        }
                        <CommonPopUpModal
                            size='small-medium'
                            title={this.props.t("upload_financial_additional_documents")}
                            onClose={e => this.props.onClose()}
                            primaryBtn={this.props.t("button_names.finish")}
                            isSubmitBtn={!(isBalanceStatement)}
                            onSubmit={() => this.uploadFinancialHistory()}
                            promptOnClose={!(!(isBalanceStatement))}
                        >
                            <div className="d-flex flex-column justify-content-center">
                                <FileUploader
                                    documents={this.state.balanceStatement.length ? this.state.balanceStatement : []}
                                    onDocUpload={doc => this.onDropBankStatement("Bank_Statements", doc)} on
                                    onDelete={this.handleDelete}
                                    onView={this.handleView}
                                    namelength={50}
                                    supportedExt={['.csv', '.pdf', '.xlsx', '.xls', '.doc', '.docx', '.ppt', '.pptx']}
                                    placeholder={this.props.t("file_upload_msg.common_upload_msg")}
                                    isdisabled={false}
                                    isMultiple={true}
                                    isExtraction={false}
                                    supportNotes={true}
                                    customHeight={"35vh"}
                                />
                            </div>
                        </CommonPopUpModal>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        );
    }
}

export default withTranslation() (FinancialAdditionalDocuments);