//Author Rajesh Rajendran
const fileUploadConfig = {
    placeholders: {
        invoice: "Drag & drop or click to upload an invoice file",
        po: "Drag & drop or click to upload a purchase order file",
        additionalDocx: "Drag & drop or click to upload additional documents",
        financialDocs: "Drag & drop or click to upload financial documents"
    },
    supportedFileTypes: {
        invoice: {
            extensions: ['.pdf'],
            message: 'Please upload only PDF files for invoices.'
        },
        po: {
            extensions: ['.pdf'],
            message: 'Please upload only PDF files for purchase orders.'
        },
        additionalDocx: {
            extensions: ['.csv', '.pdf', '.xlsx', '.xls', '.doc', '.docx', '.ppt', '.pptx'],
            message: 'Please provide the supported file formats.'
        }
    },
    duplicateFileMessage: "Document(s) already uploaded"
};

export default fileUploadConfig;
