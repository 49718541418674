//Author Rajesh Rajendran
import React from 'react';
import PropTypes from 'prop-types';
import { localDateFormat, numberMask } from '../../../services/Utilities';
import TextInput from '../../CustomInputs/TextInput';
import DateInput from '../../CustomInputs/DateInput';
import MaskedTextInput from '../../CustomInputs/MaskedTextInput';
import PaymentTermsInput from '../../../services/InputTerms';
import { Grid } from '@mui/material';

const InvoiceDetails = ({ data,
    isValidInvoiceNo,
    isReadOnly,
    onDataChange,
    isEnabledImport,t
    }) => {


    const handleInputChange = (name, value) => {
        const updatedData = [...data];
        const customerDetails = updatedData[0].customerDetails || {};

        if (name === "email") {
            customerDetails[name] = value;
        } else {
            updatedData[0][name] = value;
            if (name === "buyerName") {
                customerDetails["name"] = value;
            }
        }

        updatedData[0].customerDetails = customerDetails;
        onDataChange(updatedData);
    };

    const handleDateChange = (name, date) => {
        if (data.length > 0) {
            const newData = [...data];
            newData[0][name] = date ? localDateFormat(date) : null;
            onDataChange(newData);
        }
    };

    const handleTermsChange = (inputValue) => {
        const updatedData = [...data];
        updatedData[0].Terms = inputValue;

        onDataChange(updatedData);
        isEnabledImport();
    };

    return (
        <> 
           <div className="custom-form-container">
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextInput
                        label={t("invoice_labels.invoice_number")}
                        name="Invoice#"
                        value={data[0]?.["Invoice#"] || ""}
                        onChange={handleInputChange}
                        required={!isValidInvoiceNo}
                        disabled={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DateInput
                        label={t("invoice_labels.invoice_date")}
                        name="createdAt"
                        format="MM/dd/y"
                        value={data[0]?.createdAt ? new Date(data[0].createdAt) : ""}
                        onChange={(date) => handleDateChange('createdAt', date)}
                        disabled={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <PaymentTermsInput
                        data={data}
                        isReadOnly={isReadOnly}
                        value={data[0]?.Terms || ""}
                        onDataChange={handleTermsChange}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput
                        label={t("customer_labels.customer_name")}
                        name="buyerName"
                        value={data[0]?.["buyerName"] || ""}
                        onChange={handleInputChange}
                        disabled={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <MaskedTextInput
                        label={t("invoice_labels.invoice_amount")}
                        name="total"
                        value={data[0]?.total || ""}
                        mask={numberMask}
                        placeholder="$ ..."
                        onChange={handleInputChange}
                        disabled={isReadOnly}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput
                        label={t("customer_labels.customer_email")}
                        name="email"
                        value={data[0]?.customerDetails?.email || ""}
                        onChange={handleInputChange}
                        disabled={isReadOnly}
                    />
                </Grid>
            </Grid>
        </div> 
        </>
    );
};

InvoiceDetails.propTypes = {
    data: PropTypes.array.isRequired,
    isValidInvoiceNo: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    onDataChange: PropTypes.func.isRequired,
    isEnabledImport: PropTypes.func.isRequired
};

export default InvoiceDetails;