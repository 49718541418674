import React, { Component } from 'react'

import AppState from '../../../../contexts/AppState/AppState';
import AppProvider from '../../../../contexts/AppState/AppStateProvider';
import API from '../../../../services/API';
import { POModal } from "./POModal";
import { POUploadModal } from "./POUploadModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faExclamationTriangle, faSortUp, faSortDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import User from '../../../../services/User';
import FullSpinner from '../../../common/Spinner/FullSpinner';
import { getAllFinancialDocIsPresent } from "../../../../services/Utilities";
import DataGrid from '../../../common/DataGrid/DataGrid';
import DataGridFilter from '../../../common/DataGrid/DataGridFilter';
import { dollarFormatter,dateFormatter } from '../../../../services/Utilities';
import CommonCreateFundingRequestModal from '../../../common/Modals/CommonCreateFundingRequestModal';
import { NavLink} from "react-router-dom";
import BuyerPOUploadModal from "./BuyerPOUploadModal";
import SubmitPOModal from './SubmitPOModal';
import NotificationService from '../../../../services/NotificationService';
import { transactionType } from '../../../../configs/GlobalConfig';
import { withTranslation } from 'react-i18next';
import { generateSteps, calculateStatusCounts, StatusStepper, calculateStatusCountsByText } from '../../../../services/StatusStepperService';

class PoList extends Component {
    api = new API();
    user = new User();

    _columnsSpecification = [
        {
            key: "customerName",
            name: this.props.t("customer_labels.customer_name")
        },
        {
            key: "documentId",
            name: this.props.t("grid_view_header_labels.po_number"),
            eventType: "view"
        },
        {
            key: "total",
            name: this.props.t("grid_view_header_labels.po_amount"),
            textAlignment:"right",
            contentProvider: (datum_) => {
                return <div style={{color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>
            }
        },
        {
            key: "documentDate",
            name: this.props.t("grid_view_header_labels.po_date"),
            contentProvider: (datum_) => {
                return dateFormatter.format(new Date(datum_.documentDate));
            }
        },
        {
            key: "status",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div className="awaiting-text-color" style={{fontWeight:"600"}}>{this.props.t("status_label.submitted")}</div>;
                    case 99:
                        return <div className="progress-text-color">{this.props.t("status_label.in_funding")}</div>;
                    case 100:
                        return <div className="archived-text-color">{this.props.t("status_label.archived")}</div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
        {
            key: "action",
            name: this.props.t("grid_view_header_labels.type"),
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>{this.props.t("status_label.open")}</div>;
                    case 99:
                    case 100:
                        return <div><NavLink className="anchorstyle" style={{ color: "#5F9FBC"}} to="/fundingrequest">{datum_.fundingReqId}</NavLink></div>;
                    default:
                        return "Unknown";
                }
            },
            eventType: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return "open";
                    case 99:
                    case 100:
                        return "";
                    default:
                        return "";
                }
            }
        }
    ];

    _buyerColumnsSpecification = [
        {
            key: "purchaseOrder",
            name: this.props.t("grid_view_header_labels.supplier_name"),
            contentProvider: (datum_) => {
                if(datum_.purchaseOrder && datum_.purchaseOrder.supplierName){
                    return datum_.purchaseOrder.supplierName;
                }
                else{
                    return "";
                }
            }
        },
        {
            key: "documentId",
            name: this.props.t("grid_view_header_labels.po_number"),
            eventType: "view"
        },
        {
            key: "total",
            name: this.props.t("grid_view_header_labels.po_amount"),
            textAlignment:"right",
            contentProvider: (datum_) => {
                return <div style={{color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>
            }
        },
        {
            key: "documentDate",
            name: this.props.t("grid_view_header_labels.po_date"),
            contentProvider: (datum_) => {
                return dateFormatter.format(new Date(datum_.documentDate));
            }
        },
        {
            key: "status",
            name: this.props.t("grid_view_header_labels.status"),
            contentProvider: (datum_) => {
                if(datum_.userId){
                    switch (datum_.status) {
                        case 0:
                            return <div className="awaiting-text-color" style={{ fontWeight: "600" }}>{this.props.t("status_label.submitted")}</div>;
                        case 99:
                            return <div className="progress-text-color">{this.props.t("status_label.in_funding")}</div>;
                        case 100:
                            return <div className="archived-text-color">{this.props.t("status_label.archived")}</div>;
                        default:
                            return datum_.statusText;
                    }
                }
                else{

                    return <div className="awaiting-text-color" style={{fontWeight:"600"}}>{this.props.t("status_label.awaiting_submission")}</div>;
                }
            }
        },
        {
            key: "action",
            name: this.props.t("grid_view_header_labels.type"),
            contentProvider: (datum_) => {
                if(datum_.userId){
                    switch (datum_.status) {
                        case 99:
                        case 101:
                            return <div style={{ color: "#5F9FBC" }}>{datum_.fundingReqId}</div>;
                        case 100:
                            return <div style={{ color: "#930000", fontWeight: "600" }}>{this.props.t("status_label.archived")}</div>;
                        default:
                            return <div style={{ color: "#999999", fontWeight: "600" }}>{this.props.t("status_label.open")}</div>;
                    }
                }
                else {
                    return <div><button className="submit-invoice-button"
                    >{this.props.t("transactions_page.po_content.submit_po")}</button></div>;
                }

            },
            eventType: (datum_) => {
                if(datum_.userId){
                    return "open";
                }
                else{
                    return "Submit PO";
                }
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isUploadPopupOpen: props.isUpload,
            isSubmitPO: false,
            isLoading: false,
            missingFinDoc: "",
            funders: [],
            taxId: null,
            isAllCheckboxEnabled:false,
            allPurchaseOrder:[],
            isViewPopupOpen:false,
            isCFRModalOpen:false,
            selectedItems:[],
            customers: [],
            filtersApplied: {},
            purchaseOrder:[],
            isResetFilterEnabled:false,
            isPoSubmittedForSupplier:false,
            suppliers:[],
            isViewBuyerPO :false,
            isDeletePO: false,
            steps: "",
            activeStep: '',
            statusCounts: [],
        };
        this.getProfile = this.getProfile.bind(this);
    }
    componentDidMount() {
        this.loadPOs();
    }

    componentWillMount() {
        this.getProfile();
    }

    componentDidUpdate(prevProps_, prevState) {
        if (prevProps_.isUpload !== this.props.isUpload) {
            this.setState({ isUploadPopupOpen: this.props.isUpload });
        }
        if (prevProps_.isCreateFundingRequest != this.props.isCreateFundingRequest) {
            if(this.props.isCreateFundingRequest){
                const {selectedItems}=this.state;
                const _creditLimitValidationDetails = selectedItems.map(doc_ => doc_.tradingPartnerId)
                this.validateExistingContract(_creditLimitValidationDetails);
                //this.loadABL();
            }else{
                this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
            }
        }
    }

    validateExistingContract=async(_tpList)=>{
        const _createFundingRequestDetails=await this.api.checkCreateFundingRequest(_tpList,transactionType.PurchaseOrder);
        if(_createFundingRequestDetails && _createFundingRequestDetails.isCreateFundingRequest){
            this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest,isContractPresentInSystem: _createFundingRequestDetails && _createFundingRequestDetails.isContractPresentInSystem});
        }
        else{
            AddModal(this.props.t("invalid_funding_request"),
                <div className='d-flex flex-column'>
                    <div>
                    {this.props.t("invalid_funding_request_validation_msg")}<br />
                    </div>
                    {_createFundingRequestDetails && _createFundingRequestDetails.withContractTradingPartners && _createFundingRequestDetails.withContractTradingPartners.length > 0 && (
                        <div>
                            <div><b>{this.props.t("following_customer_msg")}:</b></div>
                            <div>
                                <ul style={{listStyleType:"none"}}>
                                    {_createFundingRequestDetails.withContractTradingPartners.map(tr_=>{
                                        return <li>{tr_.name}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                    {_createFundingRequestDetails && _createFundingRequestDetails.withoutContractTradingPartners && _createFundingRequestDetails.withoutContractTradingPartners.length > 0 && (
                        <div>
                            <div><b>{this.props.t("following_customer_without_contract_msg")}:</b></div>
                            <div>
                                <ul style={{listStyleType:"none"}}>
                                    {_createFundingRequestDetails.withoutContractTradingPartners.map(tr_=>{
                                        return <li>{tr_.name}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
                , null, false)
            .then((_isSubmitted)=>{
                this.closeCreateFundingRequestModal();
            })
            .catch(()=>{
              this.closeCreateFundingRequestModal();
            })
        }
    }

    closeCreateFundingRequestModal=()=>{
        this.setState({ isCFRModalOpen: false, selectedItems: null });
        this.props.createFundingRequest();
        this.props.hasCreateFundingRequest(0);
    }

    async loadABL() {
        const ablResponse= await this.api.docGetABL();
        if(ablResponse && ablResponse.length>0){
            if(ablResponse[0].status==99){
                this.props.createFundingRequest();
                this.props.hasCreateFundingRequest(0);
                this.setState({ selectedItems:null },()=>{
                    AddModal('Incomplete Asset-Based Lending Contract',  <div>
                    There is already <b>Asset-Based lending</b> in progress, complete the process and then initiate new transactions</div> , null, false);
                });
            }
            else{
                this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
            }
        }else{
            this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
        }
    }



    async getProfile() {
      //this.setState({isLoading: true})
        const profileResponse = await this.api.getProfile();
        this.setState({
            taxId: profileResponse.user.taxId
        })
      //this.setState({isLoading: false})
    }

    async loadPOs() {
        const poResponse= await this.api.docGetPO();
        if(this.user.currentUserRole()=="supplier"){
            this.setPurchaseOrder(poResponse);
        }
        else{
            this.setBuyerPurchaseOrder(poResponse);
        }
        // Calculate the counts for each status up to 'statusUpto'
        const statusUpto = 100; //Define the maximum status value to be calculated
        const counts = this.user.currentUserRole() == "buyer" ?
            calculateStatusCountsByText(poResponse) : calculateStatusCounts(poResponse, statusUpto);
            this.setState({ statusCounts: counts })
    }

    async setPurchaseOrder(poResponse) {
        let isAllCheckbox = true;
        let buyers = [];
        const purchaseOrder = poResponse
            .map(inv => {
                let isExistCustomer = buyers.filter(b => b == inv.buyerName);
                if (isExistCustomer.length == 0) {
                    buyers.push(inv.buyerName);
                }
                let isRowSelect = true;
                if (inv.status == 0) {
                    isRowSelect = false;
                    isAllCheckbox = false;
                }
                inv.isRowSelect = isRowSelect;
                if (inv.buyerName) {
                        inv.customerName = inv.buyerName;
                }
                if(inv.status < 99 && inv.purchaseOrder && (!inv.purchaseOrder.source || (inv.purchaseOrder.source && inv.purchaseOrder.source == "file"))){
                    inv.isNoMoreLink=true;
                }
                else{
                    inv.isNoMoreLink=false;
                }

                switch (inv.status) {
                    case 0:
                        inv.statusText = this.props.t("status_label.submitted");
                        break;
                    case 99:
                        inv.statusText = this.props.t("status_label.in_funding");
                        break;
                    case 100:
                        inv.statusText = this.props.t("status_label.archived");
                        break;
                    default:
                        inv.statusText = this.props.t("status_label.unknown");
                }

                return inv;
            });
            buyers=buyers.sort();
        this.setState({ allPurchaseOrder: purchaseOrder, isAllCheckboxEnabled: isAllCheckbox,customers: buyers}, () => {
            this._resetFilter();
            this.onFilterChange();
        });
    }

    async setBuyerPurchaseOrder(poResponse) {
        let isAllCheckbox = true;
        let suppliers_ = [];
        const purchaseOrder = poResponse
            .map(inv => {
                let isExistCustomer_=[];
                if(inv.purchaseOrder && inv.purchaseOrder.supplierName){
                    isExistCustomer_ = suppliers_.filter(b => b == inv.purchaseOrder.supplierName);
                    if (isExistCustomer_.length == 0) {
                        suppliers_.push(inv.purchaseOrder.supplierName);
                        inv.supplierName = inv.purchaseOrder.supplierName;
                    }
                    else{
                        inv.supplierName = isExistCustomer_[0];
                    }
                }
                if(inv.status < 99 && inv.purchaseOrder && (!inv.purchaseOrder.source || (inv.purchaseOrder.source && inv.purchaseOrder.source == "file"))){
                    inv.isNoMoreLink=true;
                }
                else{
                    inv.isNoMoreLink=false;
                }

                let isRowSelect = true;
                if (inv.status == 0) {
                    isRowSelect = false;
                    isAllCheckbox = false;
                }
                inv.isRowSelect = isRowSelect;
                if(inv.userId){
                    switch (inv.status) {
                        case 0:
                            inv.statusText = this.props.t("status_label.submitted");
                            break;
                        case 99:
                            inv.statusText = this.props.t("status_label.in_funding");
                            break;
                        case 100:
                            inv.statusText = this.props.t("status_label.archived");
                            break;
                        default:
                            inv.statusText = this.props.t("status_label.unknown");
                    }
                }
                else{
                    inv.statusText=this.props.t("status_label.awaiting_submission");
                }

                return inv;
            });
            suppliers_=suppliers_.sort();
        this.setState({ allPurchaseOrder: purchaseOrder, isAllCheckboxEnabled: isAllCheckbox,suppliers: suppliers_}, () => {
            this._resetFilter();
            this.onFilterChange();
        });
    }

    onFilterChange(status) {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        if (status === undefined && this.state.filtersApplied.status?.length) {
            status = this.state.filtersApplied.status[0].value;
        }

        let filteredInvoices = this.state.allPurchaseOrder.filter(invoice_ => {
            if (!this.state.filtersApplied.archived) {
                if (invoice_.status == 100) {
                    return false;
                }
            }
            if(this.user.currentUserRole()=="supplier"){
                if (this.state.filtersApplied.customers && this.state.filtersApplied.customers.length) {
                    if (invoice_.customerName != this.state.filtersApplied.customers[0].text) {
                        return false;
                    }
                }
            }
            else{
                if (this.state.filtersApplied.suppliers && this.state.filtersApplied.suppliers.length) {
                    if (invoice_.supplierName != this.state.filtersApplied.suppliers[0].text) {
                        return false;
                    }
                }
            }

            if(this.user.currentUserRole()=="supplier"){
                if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                    if (invoice_.status != this.state.filtersApplied.status[0].value) {
                        return false;
                    }
                }
            }
            else{
                if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                    if (invoice_.statusText!= this.state.filtersApplied.status[0].text) {
                        return false;
                    }
                }
            }

            if (this.state.filtersApplied.invoiceDate &&
                this.state.filtersApplied.invoiceDate.length &&
                this.state.filtersApplied.invoiceDate[0].startDate) {
                let startDate = this.state.filtersApplied.invoiceDate[0].startDate;
                let endDate = this.state.filtersApplied.invoiceDate[0].endDate;
                endDate.setHours(23);
                endDate.setMinutes(59);
                let date = new Date(invoice_.documentDate);
                if (date < startDate || date > endDate) {
                    return false;
                }
            }

            if (filteredText) {
                if (this.user.currentUserRole() == "supplier") {
                    if (!(invoice_.customerName.toLowerCase().indexOf(filteredText) >= 0 ||
                        invoice_.documentId.toLowerCase().indexOf(filteredText) >= 0 ||
                        invoice_.statusText.toLowerCase().indexOf(filteredText) >= 0)) {
                        return false;
                    }
                }
                else {
                    if (!(invoice_.supplierName.toLowerCase().indexOf(filteredText) >= 0 ||
                        invoice_.documentId.toLowerCase().indexOf(filteredText) >= 0 ||
                        invoice_.statusText.toLowerCase().indexOf(filteredText) >= 0)) {
                        return false;
                    }
                }

            }

            return true;
        });

        this.setState({
            purchaseOrder: filteredInvoices
        },() => {this._hasResetFilterEnabled();
            this.openNotificationActionModal();

        });
    }

    openNotificationActionModal=()=>{
        const _notificationIds=NotificationService._id;
        if(_notificationIds && _notificationIds.length==1){
            if(this.state.purchaseOrder && this.state.purchaseOrder.length>0){
                const _notificationFR=this.state.purchaseOrder.filter(f_=>f_.documentId==_notificationIds[0]);
                NotificationService.emit(_notificationIds[0]);
                if (_notificationFR && _notificationFR.length > 0) {
                    const selectedTCF = _notificationFR[0];
                    this.setState({
                        isViewPopupOpen: true,
                        selectedPurchaseOrder: selectedTCF,
                    });
                }
            }
        }
    }
    _onSelectionChange(items) {
        this.setState({ selectedItems: items });
        this.props.hasCreateFundingRequest(items.length);
    }

    _resetFilter = () => {
        this.state.filtersApplied.textFilter = "";
        this.state.filtersApplied.archived = false;
        this.state.filtersApplied.customers = [];
        this.state.filtersApplied.suppliers = [];
        this.state.filtersApplied.status = [];
        this.state.filtersApplied.invoiceDate = [{
            startDate: null,
            endDate: null,
            key: "selection"
        }];
        this.setState({
            filtersApplied: this.state.filtersApplied,
            activeStep:""
        }, () => this.onFilterChange());
    }

    _hasResetFilterEnabled=()=>{
        if(this.state.filtersApplied.textFilter!="" || this.state.filtersApplied.archived ||this.state.filtersApplied.customers.length>0  || this.state.filtersApplied.suppliers.length>0
        || this.state.filtersApplied.status.length>0 || (this.state.filtersApplied.invoiceDate[0].startDate && this.state.filtersApplied.invoiceDate[0].endDate)){
            this.setState({isResetFilterEnabled:true});
        }
        else{
            this.setState({isResetFilterEnabled:false});
        }
    }


    handleStepClick = (step, status) => {
        this.setState(prevState => {
            const isSameStep = prevState.activeStep === step;
            return {
                activeStep: isSameStep ? "" : step,
                filtersApplied: isSameStep
                    ? {
                        ...prevState.filtersApplied,
                        textFilter: "",
                        archived: false,
                        customers: [],
                        status: []
                    }
                    : {
                        ...prevState.filtersApplied,
                        status: [{ value: status, text: status === 0 ? "Submitted" 
                            : status === 1 ? "Awaiting Submission" 
                            : status === 99 ? "In Funding" 
                            : status === 100 ? "Archived" 
                            : "Unknown Status" }],
                        archived: status==3 || status==100? true : false,
                    }
            };
        }, () => {
            this.onFilterChange(status);
        });
    };

    generateStepData = () => {
        const { statusCounts } = this.state;

        const isBuyer = this.user.currentUserRole() == "buyer";
        const stepTitles = isBuyer ?
            [
                this.props.t("stepper_labels.sup_po.awaiting_submission"),
                this.props.t("stepper_labels.sup_po.submitted"),
                this.props.t("stepper_labels.buy_po.in_funding"),
                this.props.t("stepper_labels.buy_po.archived"),
            ] :
            [
                this.props.t("stepper_labels.buy_po.submitted_po"),
                this.props.t("stepper_labels.buy_po.in_funding"),
                this.props.t("stepper_labels.buy_po.archived"),
            ];
        // Status codes to include in the steps
        const statusToInclude = isBuyer ? [1, 0, 99, 100] : [0, 99, 100];
        const statuRequiredAction = isBuyer ? [1] : [0]; // Status to show Action Icon
        return generateSteps(statusCounts, stepTitles, statusToInclude, statuRequiredAction);
    }

    render() {
        const {
            isUploadPopupOpen,
            isViewPopupOpen,
            purchaseOrder,
            selectedItems,
            isSubmitPO,
            isLoading,
            isCFRModalOpen,
            customers,
            isPoSubmittedForSupplier,
            isViewBuyerPO,
            isDeletePO,
            activeStep
        } = this.state;

        const steps = this.generateStepData();
        return (
            <AppProvider>
                <AppState.Consumer>
                    {appState => (
                        <React.Fragment>
                            <FullSpinner
                                isSpinning={isLoading}
                                //confirmationLabel="Supplier Created Funding Request to External Funders"
                                callback={appState.state.loadPOs}
                            />
                            <FullSpinner
                                isSpinning={isLoading? false : isSubmitPO}
                                confirmationLabel={this.props.t("transactions_page.po_content.purchase_order_uploaded")}
                                callback={appState.state.loadPOs}
                            />
                            {(isUploadPopupOpen || isViewPopupOpen || isCFRModalOpen ||isDeletePO) && (this.user.currentUserRole()=="supplier") && (
                                <div className="terms-checkbox-container">
                                    {isUploadPopupOpen && <POUploadModal
                                        isReadOnly={false}
                                        onClose={() => {
                                            this.setState({ isUploadPopupOpen: false });
                                            this.props.upload();
                                            this._resetFilter();
                                            this.setState({selectedItems:[]});
                                            this.props.hasCreateFundingRequest(0);
                                        }}
                                        poList={this.state.allPurchaseOrder}
                                        onSubmit={(formData) => {
                                            this.setState({ isSubmitPO: true });
                                            this.api.docSubmitPO(formData).then(r => {
                                                if (r.error && r.message == "ALREADY_SUBMITTED") {
                                                    this.alreadySubmittedModal(formData.get("purchaseorder"));
                                                    this.setState({
                                                        isUploadPopupOpen: false,
                                                        isSubmitPO: false,
                                                        selectedItems:[]
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        isUploadPopupOpen: false,
                                                        isSubmitPO: false,
                                                        selectedItems:[]
                                                    }, () => {
                                                        this.loadPOs();
                                                    });
                                                }
                                                this.props.upload();
                                                this.props.hasCreateFundingRequest(0);
                                            });
                                        }}
                                        t={this.props.t}
                                    />}
                                    {this.state.isViewPopupOpen && this.state.selectedPurchaseOrder && <POModal
                                        isReadOnly={true}
                                        t={this.props.t}
                                        onClose={() => { this.setState({ isViewPopupOpen: false, selectedPurchaseOrder: null }) }}
                                        data={this.state.selectedPurchaseOrder}
                                    />}
                                    {isCFRModalOpen && selectedItems && <CommonCreateFundingRequestModal
                                        data={selectedItems}
                                        isGetExistingContractList={this.state.isContractPresentInSystem}
                                        onClose={() => {
                                            this.loadPOs();
                                            this.setState({ isCFRModalOpen: false,selectedItems:null });
                                            this.props.createFundingRequest();
                                            this.props.hasCreateFundingRequest(0);
                                        }}
                                        t={this.props.t}
                                    />}
                                    {
                                        this.state.isDeletePO && (
                                            <CommonConfirmationModal
                                                title={this.props.t("transactions_page.po_content.delete_purchase_order") +": #" + this.state.selectedPurchaseOrder.documentId}
                                                submitText={this.props.t("button_names.delete")}
                                                onClose={() => {
                                                    this.loadPOs();
                                                    this.setState({ isDeletePO: false, selectedPurchaseOrder: null,selectedItems:[] });
                                                    this.props.hasCreateFundingRequest(0);
                                                }}
                                                onSubmit={async () => {
                                                   // this.setState({isLoading:true});
                                                    await this.api.deleteDocument(this.state.selectedPurchaseOrder);
                                                    const deletedPO_=this.state.selectedPurchaseOrder;
                                                    // let selectedItems_=selectedItems;
                                                    // let filteredSelectedItems_=[];
                                                    // for(let items_ of selectedItems_){
                                                    //     console.log(items_);
                                                    //     if(items_._id!=deletedPO_._id){
                                                    //         filteredSelectedItems_.push(items_);
                                                    //     }
                                                    // }
                                                    this.setState({selectedItems:[]});
                                                    this.props.hasCreateFundingRequest(0);
                                                }}
                                            >
                                                {this.props.t("transactions_page.po_content.delete_po_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }
                                </div>
                            )}
                            {this.user.currentUserRole() == "buyer" && (isDeletePO || isUploadPopupOpen || isPoSubmittedForSupplier || isViewBuyerPO) && (<>
                                <div className="terms-checkbox-container">
                                    {(isUploadPopupOpen || isViewBuyerPO) && <BuyerPOUploadModal
                                    t={this.props.t}
                                        isReadOnly={isViewBuyerPO}
                                        onClose={() => {
                                            if(isViewBuyerPO){
                                                this.setState({ isUploadPopupOpen: false,isViewBuyerPO:false,selectedPurchaseOrder:null });
                                            }
                                            else{
                                                this.setState({ isUploadPopupOpen: false,isViewBuyerPO:false });
                                                this.props.upload();
                                            }
                                            
  
                                        }}
                                        data={this.state.selectedPurchaseOrder}
                                        onSubmit={(formData) => {
                                            this.setState({ isSubmitPO: true });
                                            this.api.docSubmitPO(formData).then(r => {
                                                if (r.error && r.message == "ALREADY_SUBMITTED") {
                                                    this.alreadySubmittedModal(formData.get("purchaseorder"));
                                                    this.setState({
                                                        isUploadPopupOpen: false,
                                                        isSubmitPO: false
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        isUploadPopupOpen: false,
                                                        isSubmitPO: false
                                                    }, () => {
                                                        this.loadPOs();
                                                    });
                                                }
                                                this.props.upload();
                                            });
                                        }}
                                    />}
                                    {isPoSubmittedForSupplier && <SubmitPOModal
                                        data={this.state.selectedPurchaseOrder}
                                        t={this.props.t}
                                        onClose={() => {
                                            this.loadPOs();
                                            this.setState({ isPoSubmittedForSupplier: false, selectedPurchaseOrder: null })
                                        }}
                                    />}
                                     {
                                        this.state.isDeletePO && (
                                            <CommonConfirmationModal
                                                title={this.props.t("transactions_page.po_content.delete_purchase_order")+": #" + this.state.selectedPurchaseOrder.documentId}
                                                submitText={this.props.t("button_names.delete")}
                                                onClose={() => {
                                                    this.loadPOs();
                                                    this.setState({ isDeletePO: false, selectedPurchaseOrder: null });
                                                }}
                                                onSubmit={async () => {
                                                   // this.setState({isLoading:true});
                                                    await this.api.deleteDocument(this.state.selectedPurchaseOrder);
                                                    this.setState({selectedItems:[]});
                                                    this.props.hasCreateFundingRequest(0);
                                                }}
                                            >
                                                {this.props.t("transactions_page.po_content.delete_po_msg")}
                                            </CommonConfirmationModal>
                                        )
                                    }

                                </div>
                            </>)}
                            <StatusStepper
                                activeStep={activeStep}
                                steps={steps}
                                handleStepClick={this.handleStepClick}
                            />
                            {this.user.currentUserRole() == "supplier" ? (
                                <>
                                    <DataGrid
                                        title="Purchase Order"
                                        emptydataLabel={this.props.t("transactions_page.po_content.no_record")}
                                        data={purchaseOrder}
                                        columns={this._columnsSpecification}
                                        isNoPagination={true}
                                        onSelectionChange={(items) => this._onSelectionChange(items)}
                                        isRowsNonSelectable={false}
                                        isGridSearch={true}
                                        isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                                        width="95"
                                        selectedItems={selectedItems}
                                        isNoMoreLink={false}
                                        appliedFilter={this.state.filtersApplied}
                                        resetFilters={() => this._resetFilter()}
                                        searchText={(text_) => {
                                            this.state.filtersApplied.textFilter = text_;
                                            this.setState({
                                                filtersApplied: this.state.filtersApplied
                                            }, () => this.onFilterChange());
                                        }}
                                        handleTableCellClick={(dataRow, type, index) => {
                                            if (type == "view" || type == "open") {
                                                this.setState({
                                                    isViewPopupOpen: true,
                                                    selectedPurchaseOrder: dataRow,
                                                });
                                            }
                                        }}
                                        popoverItems={ [
                                            { key: "deletePO", label: this.props.t("grid_view_action_labels.delete_po") }
                                        ]}
                                        onPopoverClick={(key_, row_) => {
                                            if (key_ == "deletePO") {
                                                this.setState({
                                                    isDeletePO: true,
                                                    selectedPurchaseOrder: row_,
                                                })
                                            }
                                        }}
                                        filterComponent={
                                            <React.Fragment>
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.customer")}
                                                    type="options"
                                                    options={
                                                        customers.map(cus => {
                                                            return ({ value: cus, text: cus })
                                                        })
                                                    }
                                                    value={this.state.filtersApplied.customers}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.customers = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                                <DataGridFilter
                                                    label={this.props.t("grid_view_filter_names.date_range")}
                                                    type="date_range"
                                                    value={this.state.filtersApplied.invoiceDate}
                                                    change={(filter_) => {
                                                        this.state.filtersApplied.invoiceDate = filter_;
                                                        this.setState({
                                                            filtersApplied: this.state.filtersApplied
                                                        }, () => this.onFilterChange());
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                        isResetFilterEnabled={this.state.isResetFilterEnabled}
                                    />
                                </>
                            ) : (
                                    <>
                                        <DataGrid
                                            title="Purchase Order(s)"
                                            emptydataLabel={this.props.t("transactions_page.po_content.no_record")}
                                            data={purchaseOrder}
                                            columns={this._buyerColumnsSpecification}
                                            isNoPagination={true}
                                            onSelectionChange={(items) => this._onSelectionChange(items)}
                                            isRowsNonSelectable={true}
                                            isGridSearch={true}
                                            isAllCheckboxEnabled={false}
                                            width="95"
                                            selectedItems={[]}
                                            isNoMoreLink={false}
                                            appliedFilter={this.state.filtersApplied}
                                            resetFilters={() => this._resetFilter()}
                                            searchText={(text_) => {
                                                this.state.filtersApplied.textFilter = text_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => this.onFilterChange());
                                            }}
                                            handleTableCellClick={(dataRow, type, index) => {
                                                if (type == "view" || type == "open") {
                                                    this.setState({
                                                        isViewBuyerPO: true,
                                                        selectedPurchaseOrder: dataRow,
                                                    });
                                                }
                                                else if(type=="Submit PO"){
                                                    this.setState({
                                                        isPoSubmittedForSupplier:true,
                                                        selectedPurchaseOrder:dataRow
                                                    })
                                                }
                                            }}
                                            popoverItems={ [
                                                { key: "deletePO", label: this.props.t("grid_view_action_labels.delete_po") }
                                            ]}
                                            onPopoverClick={(key_, row_) => {
                                                if (key_ == "deletePO") {
                                                    this.setState({
                                                        isDeletePO: true,
                                                        selectedPurchaseOrder: row_,
                                                    })
                                                }
                                            }}
                                            filterComponent={
                                                <React.Fragment>
                                                    <DataGridFilter
                                                        label={this.props.t("grid_view_filter_names.supplier")}
                                                        type="options"
                                                        options={
                                                            this.state.suppliers.map(cus => {
                                                                return ({ value: cus, text: cus })
                                                            })
                                                        }
                                                        value={this.state.filtersApplied.customers}
                                                        change={(filter_) => {
                                                            this.state.filtersApplied.suppliers = filter_;
                                                            this.setState({
                                                                filtersApplied: this.state.filtersApplied
                                                            }, () => this.onFilterChange());
                                                        }}
                                                    />
                                                    <DataGridFilter
                                                        label={this.props.t("grid_view_filter_names.date_range")}
                                                        type="date_range"
                                                        value={this.state.filtersApplied.invoiceDate}
                                                        change={(filter_) => {
                                                            this.state.filtersApplied.invoiceDate = filter_;
                                                            this.setState({
                                                                filtersApplied: this.state.filtersApplied
                                                            }, () => this.onFilterChange());
                                                        }}
                                                    />
                                                </React.Fragment>
                                            }
                                            isResetFilterEnabled={this.state.isResetFilterEnabled}
                                        />
                                    </>
                            )}
                            
                        </React.Fragment>
                    )}
                </AppState.Consumer>
            </AppProvider>
        )
    }
}

export default withTranslation() (PoList);