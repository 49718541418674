import React, { Component } from 'react';
import FullSpinner from '../Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import Popup from '../Auth/Popup';
import Dropzone from 'react-dropzone';
import { ShortText,localDateFormat} from '../../../services/Utilities';
import {parse} from "../../../../node_modules/csv/dist/esm/index.js";
import { AddModal } from './CommonConfirmationModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal.js';
import FileUploader from '../FileUploader/FileUploader.js';
import FileViewerModal from '../FileViewerModal/FileViewerModal.js';
import { getAgingReportDownload } from '../../../services/reportUtils.js';
import { withTranslation } from 'react-i18next';

var AgingReportPostdata = {};
 class PayableAgingReportModal extends Component {
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            financeDocs: this.props.data ? this.props.data : {
                balanceSheet: {
                    fileName: "",
                    downloadUrl: ""
                },
                incomeStmt: {
                    fileName: "",
                    downloadUrl: ""
                },
                cashflow: {
                    fileName: "",
                    downloadUrl: ""
                },
                agingfile: {
                    fileName: "",
                    downloadUrl: ""
                },
                payableAgingFile: {
                    fileName: "",
                    downloadUrl: ""
                }
            },
            flagBalSta: false,
            flagIncSta: false,
            flagCasSta: false,
            isLoading: false,
            isFileViewerModal: false,
            fileViewerContent: null,
            isFinancialDocument: false
        };
        this.onDropFinancialDoc = this.onDropFinancialDoc.bind(this);
    }

    onDropFinancialDoc(type, documents) {
        this.setState({ fileToUpload3: documents[0] });
        this.handleInvoiceLoad(documents);
    }

    async uploadAgingReport() {
        const { fileToUpload3 } = this.state;
        this.setState({ isLoading: true });
        const documentData = new FormData();
        documentData.append("PAYABLE_AGING_REPORT", fileToUpload3);
        await this.api.financialPayableAgingReportUpload(documentData);
        await this.api.submitPayableAgingReportToLF(AgingReportPostdata);
        // this.props.onClose();
        this.setState({ isLoading: false });
    }

    // Here to parse the aging report
    getPulledDateNew(data) {
        var baseInfo = {};
        var PDJ = null;
        var PDI = null;
        for (let i = 0; data.length > i; i++) {
            var datalevel2 = data[i];
            for (let j = 0; datalevel2.length > j; j++) {
                var Findstr = datalevel2[j];
                if (Findstr.includes("Page")) {
                    PDJ = j;
                }
                if (j == PDJ && i == PDI + 1) {
                    baseInfo["PulledData"] = datalevel2[j];
                }
                if (Findstr.includes("Currency")) {
                    var str = datalevel2[j];
                    str = str.trim();

                    var splitArr = str.split(':');
                    for (var s = 0; s < splitArr.length; s++) {
                        if (s == 1) {
                            baseInfo["CurrencyCode"] = splitArr[s];
                        }
                    }
                }
            }
        }
        return baseInfo;
    }

    // Handles loading the CSV aging invoices list
    handleInvoiceLoad(data) {
        const reader = new FileReader();
        let invoiceData = [];
        let fileExtention = '';

        if (data.length > 0 && data[0].name && data[0].name.indexOf('.') > -1) {
            fileExtention = data[0].name.substring(data[0].name.lastIndexOf(".") + 1).toUpperCase();
        }

        if (fileExtention == 'CSV') {
            reader.onload = () => {
                parse(reader.result, (err, data) => {
                    invoiceData.push(data);
                    const invoiceArr = [];
                    var InvoiceArrLevel1 = [];
                    for (let i = 0; i < data.length; ++i) {
                        InvoiceArrLevel1[i] = data[i].slice(0);
                    }
                    var pulledDate;
                    var basicinfo = this.getPulledDateNew(data);
                    let splitArr = [];
                    if (basicinfo && basicinfo.PulledData) {
                        splitArr = basicinfo["PulledData"].split('\n');
                    }
                    if (splitArr.length > 1) {
                        for (var sp = 0; sp < splitArr.length; sp++) {
                            if (sp == 0) {
                                pulledDate = (splitArr[sp]);
                            }
                        }
                    }
                    var trimIndex = 0;
                    for (let _i = 0; _i < InvoiceArrLevel1.length; ++_i) {
                        for (let j = 0; j < InvoiceArrLevel1[_i].length; ++j) {
                            let value = InvoiceArrLevel1[_i][j].trim();
                            if (value.indexOf("Current") >= 0 ||
                                value.indexOf("30 days") >= 0 ||
                                value.indexOf("45 days") >= 0 ||
                                value.indexOf("60 days") >= 0 ||
                                value.indexOf("90 days") >= 0 ||
                                value.indexOf("120 days") >= 0 ||
                                value.indexOf("180 and over") >= 0) {
                                trimIndex = _i;
                                break;
                            }
                        }
                        if (trimIndex != 0) {
                            break;
                        }
                    }
                    InvoiceArrLevel1.splice(0, trimIndex);
                    var row0 = InvoiceArrLevel1[0];
                    var row2 = InvoiceArrLevel1[2];
                    if (row2[0] == "Account") {
                        for (let si = 0; si < row0.length; si++) {
                            if (row0[si]) {
                                row2[si] = row0[si];
                            }
                        }
                        InvoiceArrLevel1.splice(0, 2);
                    }
                    else {
                        row0[1] = "Name";
                    }
                    const columnNames = InvoiceArrLevel1.shift();
                    for (let p = 0; InvoiceArrLevel1.length > p; p++) {
                        let invObj = this.convertToJSONInvoiceNew(columnNames, InvoiceArrLevel1[p]);
                        if (invObj.VendorName) {
                            invoiceArr.push(invObj);
                        }
                    }
                    AgingReportPostdata["pulledDate"] = localDateFormat(pulledDate?pulledDate:new Date());
                    AgingReportPostdata["currencyCode"] = "USD";
                    AgingReportPostdata["invoices"] = invoiceArr;
                    this.CheckAgingDate();
                });
            };

            reader.readAsBinaryString(data[0]);
        } else {
            this.setState({ fileToUpload3: "" });
            AddModal(this.props.t("file_upload_msg.unsupported_format_title"), this.props.t("file_upload_msg.aging_report_Upload_file_error"), null, false);
            // alert("Unsupported file format. Please upload the aging report in CSV format.");
        }
    };

    async CheckAgingDate() {
        const documentationResponse = await this.api.getfullPayableAgingReport();
        let checkDate = true;
        var currencyAgingDate = new Date(AgingReportPostdata["pulledDate"]);
        let docfileExists = [];
        docfileExists = documentationResponse.fileExists;
        var checkArr = [];
        for (var df = 0; df < docfileExists.length; df++) {
            let dic = docfileExists[df];
            var SysAgingDate = new Date(dic.pulledDate);
            if (currencyAgingDate > SysAgingDate) {
                checkArr.push(true);
            } else {
                checkArr.push(false);
            }
        }
        for (var ck = 0; ck < checkArr.length; ck++) {
            if (checkArr[ck] == false) {
                checkDate = false;
            }
        }
        if (!checkDate) {
            AgingReportPostdata = {};
            this.setState({ fileToUpload3: "" });
            alert(this.props.t("aging_report_date_error_msg"));
        }
    }

    // Converts CSV invoice row to Json object
    convertToJSONInvoiceNew(columns, data) {
        let invObj = {};
        for (let i in columns) {
            switch (columns[i]) {
                case "Account":
                    invObj["VendorId"] = data[i];
                    break;
                case "Name":
                    invObj["VendorName"] = data[i];
                    break;
                case "Current":
                    invObj["currentDue"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case "30 days":
                case "0-30 days":
                case "1-30 days":
                case "1-30":
                case "1 - 30":
                case "30":
                    invObj["due30Days"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case "45 days":
                case "31-45 days":
                case "31-45":
                case "31 - 45":
                case "45":
                    invObj["due45Days"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case "60 days":
                case "31-60 days":
                case "46-60 days":
                case "31-60":
                case "46-60":
                case "46 - 60":
                case "31 - 60":
                case "60":
                    invObj["due60Days"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case "90 days":
                case "60-90 days":
                case "61-90 days":
                case "61-90":
                case "61 - 90":
                case "60-90":
                case "60 - 90":
                case "90":
                    invObj["due90Days"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case ">90":
                    invObj["due90pluseDays"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case "120 days":
                case "90-120 days":
                case "90-120":
                case "120":
                    invObj["due120Days"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case "180 days":
                case "120+ days":
                case "180":
                    invObj["due180Days"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
                case "180+ days":
                case "> 180":
                    invObj["due180pluseDays"] = parseFloat(data[i].replace(/,/g, ""));
                    break;
            }
        }

        if (isNaN(invObj["due30Days"])) {
            invObj["due30Days"] = 0;
        }
        if (isNaN(invObj["due45Days"])) {
            invObj["due45Days"] = 0;
        }
        if (isNaN(invObj["due60Days"])) {
            invObj["due60Days"] = 0;
        }
        if (isNaN(invObj["due90Days"])) {
            invObj["due90Days"] = 0;
        }
        if (isNaN(invObj["due120Days"])) {
            invObj["due120Days"] = 0;
        }
        if (isNaN(invObj["due180Days"])) {
            invObj["due180Days"] = 0;
        }
        if (isNaN(invObj["due180pluseDays"])) {
            invObj["due180pluseDays"] = 0;
        }
        if (isNaN(invObj["due90pluseDays"])) {
            invObj["due90pluseDays"] = 0;
        }
        return invObj;
    }

    getAggingReportExtractHandler = async (type) => {
        const { receivablesAging, payablesAging } = this.props;
        const fileContent = await getAgingReportDownload(receivablesAging, payablesAging, type);
        this.setState({
            isFinancialDocument: false,
            fileViewerContent: fileContent,
            isFileViewerModal: true
        });
    };

    // handleDeletePayableAgingFile = () => {
    //     this.setState({
    //         financeDocs: {
    //             payableAgingFile: {
    //                 fileName: "",
    //                 downloadUrl: ""
    //             }
    //         },
    //         fileToUpload3: ""
    //     });
    // }

    render() {
        const {
            financeDocs,
            fileToUpload3,
            isLoading
        } = this.state;

        const isSubmitButtonEnabled = !(fileToUpload3);
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("document_upload_msg")} callback={e => this.props.onClose()} />
                        {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                            onClose={() => {
                                this.setState({ isFileViewerModal: false, fileViewerContent: null });
                            }}
                            data={this.state.fileViewerContent}
                            isFinancialDocument={true}
                        ></FileViewerModal>}
                        <CommonPopUpModal
                            title={this.props.t("upload_payable_aging_report")}
                            size='small-medium'
                            onClose={e => this.props.onClose()}
                            primaryBtn={this.props.t("button_names.finish")}
                            isSubmitBtn={isSubmitButtonEnabled}
                            onSubmit={() => this.uploadAgingReport()}
                            promptOnClose={!isSubmitButtonEnabled}
                        >
                            {/* <legend className="form-label-style required">{this.props.t("profile_page.financial_information_content.payable_aging_label")}</legend> */}
                            <FileUploader
                                documents={ fileToUpload3 ?  { fileName: fileToUpload3.name, downloadUrl: URL.createObjectURL(fileToUpload3) } : financeDocs && financeDocs.payableAgingFile.fileName!== "" ? financeDocs.payableAgingFile:[]}
                                onDocUpload={doc => this.onDropFinancialDoc("Aging_Report", doc)}
                                onView={(e) => this.getAggingReportExtractHandler("payables")}
                                //onDelete={this.handleDeletePayableAgingFile}
                                namelength={100}
                                supportedExt={['.csv']}
                                placeholder={this.props.t("file_upload_msg.upload_file")}
                                isdisabled={false}
                                isMultiple={false}
                                isExtraction={false}
                                //isDeleteEnable={true}
                                supportNotes={true}
                                customHeight={'13vh'}
                            />
                        </CommonPopUpModal>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        );
    }
}

export default withTranslation() (PayableAgingReportModal);