import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import '../../common/List/List.css';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSortDown,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import {localDateFormat} from "../../../services/Utilities";
import { compareTransactionType, transactionType } from '../../../configs/GlobalConfig';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import FileMetaDataPopover from '../../common/PopOver/FileMetaDataPopover';


 class FundingContractListItem extends Component {

     api = new API();
     _isLive = false;

     constructor(props) {
         super(props);
         this.state = {
             selectedTCF: null,
         };
     }

     componentDidMount() {
         this._isLive = true;
     }

     componentWillUnmount() {
         this._isLive = false;
     }

     render() {
        const {
            index,
            fundingContractId,
            terms,
            fundingAggrement,
            customerList,
            status,
            requesterDetails,
            funder,
            contractDueDate,
            signedAggrements,
            contractType,
            isCreatedByRepresentive,
            createdUserDetails
        } = this.props;
         return (
             <AppState.Consumer>
                 {appState => (
                     <React.Fragment>
                         <div className="row">
                             <div className="col">
                                 <div className="bg-white-1 wmy-2">
                                     <div className="card-body">
                                         <div className="row d-flex align-self-center">
                                             {appState.state.currentUserRole == "funder" && (
                                                 <React.Fragment>
                                                     <div className="col-2 align-self-center text-muted cusName">
                                                         <label className="namelbl">
                                                             {isCreatedByRepresentive && createdUserDetails && createdUserDetails.adminName && (
                                                                 <div style={{ fontSize: 12 }}>
                                                                     <b> {createdUserDetails.adminName}</b>
                                                                     <br />
                                                                     on behalf of
                                                                     <br />
                                                                 </div>
                                                             )}
                                                            <b>{requesterDetails && requesterDetails.name ? requesterDetails.name : ""}</b>
                                                             <span className="ml-2 badge badge-primary">
                                                                 {requesterDetails.role == "Supplier" ? "Supplier" : "Buyer"}
                                                             </span>
                                                         </label>
                                                         <div>
                                                             <a href="" className="anchorstyle" onClick={(e) => {
                                                                 e.preventDefault();
                                                             }}>
                                                                 {fundingContractId}
                                                             </a>
                                                         </div>
                                                     </div>
                                                 </React.Fragment>
                                             )}
                                             {appState.state.currentUserRole == "supplier" && (
                                                 <React.Fragment>
                                                     <div className="col-2 align-self-center text-muted cusName">
                                                         <label className="namelbl">{funder.name}</label>
                                                         <div>
                                                             <a href="" className="anchorstyle" onClick={(e) => {
                                                                 e.preventDefault();
                                                             }}>
                                                                 {fundingContractId}
                                                             </a>
                                                         </div>
                                                     </div>
                                                 </React.Fragment>
                                             )}
                                             <div className="col-2 align-self-center text-center">
                                                {contractType =="ABL" && (
                                                     <>
                                                     
                                                     {this.props.t("funding_offer_details.abl_advance_rate")}:{" "}
                                                         <strong className="link-text-color">
                                                             {terms.advanceRateOnEligibleAR} %
                                                         </strong>
                                                         <br />
                                                         {this.props.t("funding_offer_details.abl_eligible_inventory")}:{" "}
                                                         <strong className="link-text-color">
                                                             {terms.advanceRateOnEligibleInventory} %
                                                         </strong>
                                                         <br />
                                                         {terms.lineOfCredit.length > 0 && (
                                                             <>
                                                                 {this.props.t("funding_offer_details.line_of_credit")}:{" "}
                                                                 <strong className="link-text-color">
                                                                     {terms.lineOfCredit}
                                                                 </strong>
                                                                 <br />
                                                             </>
                                                         )}
                                                         {terms.loan.length > 0 && (
                                                             <>
                                                                 {this.props.t("funding_offer_details.loan")}:{" "}
                                                                 <strong className="link-text-color">
                                                                     {terms.loan}
                                                                 </strong>
                                                                 <br />
                                                             </>
                                                         )}
                                                         {this.props.t("funding_offer_details.total_financing_fees")}:{" "}
                                                         <strong className="link-text-color">
                                                             {terms.totalFinancingFee}
                                                         </strong>
                                                     </>
                                                 )}
                                                 {(contractType !="ABL" && contractType !="SupplyChain") && (
                                                    <React.Fragment>
                                                             {this.props.t("funding_offer_details.advance_rate")}:{" "}
                                                             <strong>
                                                                 {terms.advancerate}
                                                             </strong>
                                                             <br />
                                                             {this.props.t("funding_offer_details.factoring_rate")}:{" "}
                                                             <strong>
                                                                 {terms.factoringrate}
                                                             </strong>
                                                             <br />
                                                             {this.props.t("funding_offer_details.repayment_days")}:{" "}
                                                             <strong>
                                                                 {terms.lengthoftimeforpayment=="15"?"International":terms.lengthoftimeforpayment}
                                                             </strong>
                                                         </React.Fragment>
                                                 )}
                                                 {contractType == "SupplyChain" && (
                                                     <>
                                                         {this.props.t("funding_offer_details.offer_details_title")}:{" "}
                                                         <strong>
                                                             {terms.offernote}
                                                         </strong>
                                                     </>
                                                 )}
                                             </div>
                                             <div className="col-2 align-self-center text-center">
                                                 {this.props.isDigitalSignature && this.props.isDigitalSignature == "true" ?
                                                     (<a className="link-text-color anchorstyle" onClick={(e)=>{
                                                        if (this.props.callback) {
                                                            this.props.callback("signingStatusModal", index);
                                                        }
                                                     }} style={{ fontWeight: 600, cursor: "pointer" }}
                                                     >{this.props.t("funding_offer_details.signing_status")}</a>) :
                                                     (<>
                                                         {
                                                             fundingAggrement && (<div className='d-flex'><a className="anchorstyle pr-2" href='' onClick={(e)=>{e.preventDefault()
                                                                if (this.props.callback) {
                                                                    this.props.callback("fundingAgreementModal", index);
                                                                }}}>{this.props.t("funding_offer_details.funding_agreement_label")}</a><FileMetaDataPopover data={fundingAggrement}/></div>)
                                                         }
                                                         
                                                         {
                                                             signedAggrements && (<div className='d-flex'><a className="anchorstyle pr-2" href='' onClick={(e)=>{e.preventDefault()
                                                             if (this.props.callback) {
                                                                this.props.callback("signedAgreementModal", index);
                                                            }}}>{this.props.t("funding_offer_details.signed_copy_label")}</a><FileMetaDataPopover data={signedAggrements}/></div>)
                                                         }
                                                     </>)}
                                             </div>
                                             <div className="col-2 align-self-center text-center">
                                                 {contractType != "ABL" && (
                                                     <>
                                                         {customerList && customerList.map(cus => {
                                                             return <React.Fragment><label className="namelbl">{cus && cus.name}</label><br /></React.Fragment>
                                                         })}
                                                     </>
                                                 )}
                                             </div>
                                             <div className="col-2 align-self-center text-center">
                                                 {(fundingAggrement && signedAggrements) ?
                                                     (<>
                                                         {status == 2 && (
                                                             <span className="badge badge-pill-1 badge-success p-2">
                                                                 {this.props.t("valid_till")}:{" "}{localDateFormat(contractDueDate)}
                                                             </span>
                                                         )}
                                                         {status == 3 && (
                                                             <span className="badge badge-pill-1 badge-danger p-2">
                                                                 {this.props.t("contract_terminated")}
                                                             </span>
                                                         )}
                                                     </>) : (<>
                                                         {status == 2 && !fundingAggrement && !signedAggrements && (
                                                             <span className="badge badge-pill-1 badge-warning p-2">
                                                                 {this.props.t("waiting_for_funder_to_upload")}
                                                             </span>
                                                         )}
                                                         {status == 2 && fundingAggrement && !signedAggrements && (
                                                             <span className="badge badge-pill-1 badge-warning p-2">
                                                                 {this.props.t("waiting_for_signed_agreement")}
                                                             </span>
                                                         )}
                                                        <span className="badge badge-pill-1 badge-warning p-2">
                                                             </span>
                                                     </>)}
                                             </div>
                                             <div className="col-2 d-flex justify-content-center align-self-center">
                                                <div className="btn-group actionBtn">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {this.props.t("grid_view_header_labels.actions")} <FontAwesomeIcon icon={faSortDown} />
                                                    </button>
                                                    <div className="dropdown-menu">
                                                         <button
                                                             className="dropdown-item"
                                                             type="button"
                                                             onClick={() => {
                                                                 if (this.props.callback) {
                                                                     this.props.callback("VIEW", index);
                                                                 }
                                                             }}
                                                         >{this.props.t("grid_view_action_labels.view_funding_contract")}
                                                         </button>
                                                         {status != 3 && compareTransactionType(contractType[0] ,transactionType.Invoice) && (process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED=="true") && (
                                                             <button
                                                                 className="dropdown-item"
                                                                 type="button"
                                                                 onClick={() => {
                                                                     if (this.props.callback) {
                                                                         this.props.callback("updateCreditLimit", index);
                                                                     }
                                                                 }}
                                                             >{this.props.t("grid_view_action_labels.update_buyer_credit_limit")}
                                                             </button>
                                                         )}
                                                         {status != 3  && fundingAggrement && signedAggrements && (
                                                             <button
                                                                 className="dropdown-item"
                                                                 type="button"
                                                                 onClick={() => {
                                                                     if (this.props.callback) {
                                                                         this.props.callback("Terminate", index);
                                                                     }
                                                                 }}
                                                             >{this.props.t("terminate_contract")}
                                                             </button>
                                                         )}
                                                        {/* {appState.state.currentUserRole === "funder" &&
                                                            (
                                                                <React.Fragment>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.callback) {
                                                                                this.props.callback("PAID", this.props);
                                                                            }
                                                                        }
                                                                        }
                                                                    >
                                                                        View Supplier Details
                                                                    </button>
                                                                </React.Fragment>
                                                            )} */}
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </React.Fragment>
                 )}
             </AppState.Consumer>
         )
     }
}

export default compose( withRouter,withTranslation())(FundingContractListItem);