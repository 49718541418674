import React, { Component } from 'react';
import Load from '../../../assets/images/load-small.gif';
import Badge from '@mui/material/Badge';
import User from '../../../services/User';
import { compareRole, enumRole } from '../../../configs/GlobalConfig';

export default class DashboardWidgetContainer extends Component {
    user=new User();
    constructor(props_) {
        super(props_);

        this.state = {
            isReady: props_.isReady === false ? false : true
        };
    }

    componentDidUpdate(previousProps_) {
        if (previousProps_.isReady != this.props.isReady) {
            this.setState({
                isReady: this.props.isReady
            });
        }
    }

    render() {
        return (
            <div className={((compareRole(this.user.currentUserRole(),enumRole.Supplier)|| compareRole(this.user.currentUserRole(),enumRole.Funder) )?"m-0 p-2 ":"m-0 ") + this.props.size}>
                <div className="card m-0 p-1 dashboard-card">
                    <div className="p-0">
                        <div className="p-3 d-inline-block dashboard-card-header">
                            {this.props.title}
                            {this.props.count ? (
                                <Badge style={{paddingLeft:15,paddingBottom:4}} badgeContent={this.props.count} color="primary">
                                </Badge>
                            ) : null}
                        </div>
                        {this.props.titleContent != undefined && this.props.titleContent}
                    </div>
                    <div className="card-body dashboard-card-body">
                        {
                            this.state.isReady ?
                                this.props.children
                                : <div className="loadProgress">
                                    <img className="logo" src={Load} alt="Refreshing Aging Information" />
                                    <br />
                                    <span className="small font-italic">Loading Aging Information</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}