
import React, { Component } from 'react';
import "../List/List.css";
import FullSpinner from '../Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import { AddModal } from "./CommonConfirmationModal";
import User from "../../../services/User";
import "./modal.scss";
import { dateFormatter ,localDateFormat,localTimeFormat} from "../../../services/Utilities";
import SendIcon from "@mui/icons-material/Send";
import FileAttachmentModal from './FileAttachmentModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import FileViewerModal from '../FileViewerModal/FileViewerModal';
import { enumUserStatus,compareRole, enumRole } from '../../../configs/GlobalConfig';
import { convertUTF8String } from '../../../services/ConversionService';
import { withTranslation } from 'react-i18next';

class MessageModal extends Component {
  user = new User();
  api = new API();
  _textarea;
  _timer;
  _messageListContainer;
  _allowedFileExtensions = [".pdf", ".jpg", ".jpeg", ".png", ".tif", ".tiff", ".gif", ".csv", ".xls", ".xlsx"];

  constructor(props) {
    super(props);
    this.state = {
      uploadFileBase64: null,
      isLoading: false,
      attachedFile: null,
      errMsg: '',
      selectedRecipient: null,
      isEmailAvailable: false,
      selectedFiles: [],
      recipients: [],
      message: [],
      sendMsg: "",
      isAttachMentDocumentsModal: false,
      isLoading: false,
      isNewMessage:false,
      isFinancialDocument:false,
      isFileViewerModal:false,
      fileViewerContent:null
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.getMessage = this.getMessage.bind(this);
    this.handleAttachmentMessage = this.handleAttachmentMessage.bind(this);
  }

  updateMessageList = (message_) => {
    let inserted = false;

    for (let messageIndex = this.state.message.length-1; messageIndex >= 0; --messageIndex) {
      let message = this.state.message[messageIndex];
      if (message._id === message_._id) {
        this.state.message.splice(messageIndex, 1, message_);
        inserted = true;
        break;
      }
    }

    if (!inserted) {
      this.state.message.push(message_);
    }
  }

  componentDidMount() {
    this.getRecipients();
    this._timer = setInterval(() => {
      this.getMessage();
    }, 30000);
    this.getMessage().then(() => {
      this.scrollToBottom();
    });
  }

  componentWillUnmount() {
    clearInterval(this._timer);
  }
  async getRecipients() {
    let onRecipientsListReady = (selectedMatchedRecipients_) => {
      if (selectedMatchedRecipients_.length) {
        let autoSelectRecipient = selectedMatchedRecipients_[0];

        if (this.props.recipientId) {
          let filteredRec = selectedMatchedRecipients_.filter(r => r._id == this.props.recipientId);
          if (filteredRec && filteredRec.length) {
            autoSelectRecipient = filteredRec[0];
          }
        }

        this.setState({
          recipients: selectedMatchedRecipients_,
          selectedRecipient: autoSelectRecipient
        }, () => {
          this.getMessage();
        });
      }
    };

    if (this.props.recipients) {
      let users = await this.getUsers(this.props.recipients);
      onRecipientsListReady(users);
    }
    else {
      this.getMatchedRecipients().then(onRecipientsListReady);
    }
  }
  async getUsers(recipients) {
    let selectedFunders = recipients;
    let users = [];

    for (let i = 0; i < selectedFunders.length; i++) {
      if (typeof selectedFunders[i] === 'string') {
        const user = this.api.getUserById({ "id": selectedFunders[i] });
        users.push(user);
      }
    }
    return await Promise.all((users.length > 0) ? users : recipients);
  }

  scrollToBottom = () => {
    this._messageListContainer.scrollIntoView({ behavior: 'smooth' })
  }

  async getMatchedRecipients() {
    const { item: { _id } } = this.props;
    if (this.props.userRole == "supplier") {
      let selectedFunderIds = this.props.item.selectedFunders;
      if (selectedFunderIds && selectedFunderIds.length) {
        const matchedFundersResponse = await this.api.getMatchedFunders(_id);
        return matchedFundersResponse.filter(f => selectedFunderIds.indexOf(f._id) >= 0);
      }
    }
    else if (this.props.userRole == "funder") {
      return [
        this.props.item.supplier
      ];
    }

    return [];
  }
  
  async getMessage() {
    if (this.state.selectedRecipient) {
      return new Promise(async (resolve_, reject_) => {
        let onGetAPIResponse = (res) => {
          if (res.message) {
            for (let message of res.message) {
              this.updateMessageList(message);
            }
          }
          this.setState({ message: this.state.message }, () => {
            this.scrollToBottom();
            this.setState({isNewMessage:false});
            resolve_();
          });
        }

        if (this.props.userRole == "supplier" || this.props.userRole =="buyer") {
          this.api.getMessage(this.props.messageType, this.props.item._id, this.state.selectedRecipient.email)
            .then(onGetAPIResponse)
            .catch(r=>{
              this.state({isNewMessage:false});
            });
        }
        else if (this.props.userRole == "funder") {
          this.api.getMessageFunder(this.props.messageType, this.props.item._id, this.state.selectedRecipient.email)
            .then(onGetAPIResponse)
            .catch(r=>{
              this.setState({isNewMessage:false});
            });
        }
      });
    }
  }

  async sendMessage() {
    if (!this.state.selectedRecipient) {
      AddModal(this.props.t("message_label"), this.props.t("select_receiptent_msg"), null, false);
      return;
    }

    let finalSend = async () => {
      this.setState({isNewMessage:true});
      const messageData = new FormData();
      const Message = {
        "MessageType": this.props.messageType,
        "ItemId": this.props.item._id,
        "Receiver": this.state.selectedRecipient.email,
        "Message": this.state.sendMsg,
        "FundingRequestId": this.props.item.fundingReqId,
        "Sendon":new Date()
      };

      let res;
      let fileIndex = 0;

      for (let x of this.state.selectedFiles) {
        messageData.append(`file${++fileIndex}`, x, x.name);
      }
      messageData.append("Message", JSON.stringify(Message));

      if (this.props.userRole == "supplier" || this.props.userRole == "buyer") {
        res = await this.api.storeMessage(messageData);
      }
      else if (this.props.userRole == "funder") {
        res = await this.api.storeMessageFunder(messageData);
      }

      if (res && res.message && res.message.length > 0) {
        this._textarea.value = "";

        this.setState({ sendMsg: "", selectedFiles: [] }, () => {
          this.updateMessageList(res.message[0]);
          
          this.setState({ message: this.state.message }, () => {
            this.scrollToBottom();
            this.setState({isNewMessage:false});
          });
        });
      }
      else{
        this.setState({isNewMessage:false});
      }
    }

    if ((this.state.selectedFiles && this.state.selectedFiles.length) || this.state.sendMsg.trim() !== "") {
      finalSend()
    }
    else {
      AddModal(this.props.t("message_label"), this.props.t("type_a_message"), null, false);
    }

    this.setState({ selectedFiles: [] });
  }

  //handle  for attachment link message....
  handleAttachmentMessage = (files) => {
    this.setState({ selectedFiles: files });
  }

  render() {
    const { item } = this.props;
    const { isLoading } = this.state;
    let lastDate = null;
    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>
            {item !== undefined && (
              <React.Fragment>
                <FullSpinner isSpinning={this.state.isNewMessage}/>
                <FullSpinner isSpinning={this.state.isNewMessage? false : isLoading} confirmationLabel={this.props.t("message_submitted")} />
                {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                  onClose={() => {
                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                  }}
                  data={this.state.fileViewerContent}
                  isFinancialDocument={this.state.isFinancialDocument}
                ></FileViewerModal>}
                <div className="modal fade show modal-large" id="MessageModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ "display": "block", padding:"none" }}>
                  <div className="modal-dialog message-view" role="document">
                    <div className="modal-content">
                      <div className="common-popup-header">
                        <div className="common-popup-title">{this.props.title}</div>
                        <button type="button" className="common-popup-close" data-dismiss="modal" aria-label="Close" onClick={e => this.props.onClose()}>
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        {
                          (this.props.userRole == "supplier" || this.props.userRole=="buyer") && (
                            <div className="recipient-container">
                              {this.state.recipients.map(option => {
                                return (
                                  <div
                                    value={option.email}
                                    className={this.state.selectedRecipient == option ? "selected" : ""}
                                    onClick={(ev_) => {
                                      this.setState({
                                        message: [],
                                        selectedRecipient: option
                                      }, () => {
                                        this.getMessage().then(() => {
                                          this.scrollToBottom();
                                        });
                                      });
                                    }}
                                  >
                                    {option.name}
                                  </div>
                                );
                              })}
                            </div>
                          )
                        }
                        <div className="message-right-section">
                          <div className="message-list-section">
                            <div className="recipient-title">{this.state.selectedRecipient != null ? this.state.selectedRecipient.name : ""}</div>
                            <div className="message-list">
                              <div className="message-listSuperView">
                                {this.state.message.map(item => {
                                  if (item !== undefined) {
                                    let isUserSender = appState.state.currentUser.email == item.Sender;
                                    let date = new Date(item.Sendon);
                                    let dateString = localDateFormat(date);
                                    let messageElement = [];

                                    if (dateString != lastDate) {
                                      messageElement.push(<div className="message-date-divider">{dateString}</div>);
                                    }

                                    lastDate = dateString;

                                    messageElement.push(<div className={"message " + (isUserSender ? "sent" : "received")}>
                                      <div className={"message-inner"}>
                                        <div>{item.Message}</div>
                                        {item.Files && (
                                          <div>
                                            {item.Files.map(data => {
                                              return <div>
                                                <a href='' onClick={(e)=>{
                                                  e.preventDefault();
                                                  this.setState({
                                                    isFileViewerModal:true,
                                                    fileViewerContent:data,
                                                    isFinancialDocument:false
                                                  })
                                                }} >{convertUTF8String(data.filename ? data.filename : data.fileName)}</a>
                                              </div>
                                            })}

                                          </div>
                                        )}
                                        <div className="sent-date-time">{localTimeFormat(date)}</div>
                                      </div>
                                    </div>);

                                    return messageElement;
                                  } else
                                    return null;
                                }
                                )
                                }
                                <div className="message-end" ref={e => this._messageListContainer = e}></div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer-inner">
                            <div className="input-container">
                              {(compareRole(this.props.userRole,enumRole.Funder) && item && item.requesterDetails && item.requesterDetails.status == 3) ?
                                <div>
                                  {`${this.props.t("word_the")} ${item.requesterDetails.name} ${this.props.t("message_modal_account_disabled_msg")} admin@ledgerfunding.com`}
                                </div>

                                : (<textarea
                                  className="textinput"
                                  rows="5"
                                  cols="10"
                                  onChange={e => this.setState({ sendMsg: e.target.value })}
                                  placeholder={this.props.t("type_your_msg")}
                                  required
                                  ref={elem_ => this._textarea = elem_}
                                ></textarea>)}

                              {this.state.isAttachMentDocumentsModal  && <FileAttachmentModal
                                onSubmitFiles={this.handleAttachmentMessage} 
                                fileExtensions={this._allowedFileExtensions}
                                data={this.state.selectedFiles?this.state.selectedFiles:[]}
                                fileExtensionAllowMsg={this.props.t("file_upload_msg.without_doc_file_note")}
                                onClose={() => {
                                  this.setState({ isAttachMentDocumentsModal: false });
                                }}

                              />}
                              <button
                                className="btn btn-primary mt-3"
                                disabled={(compareRole(this.props.userRole,enumRole.Funder)) && item.requesterDetails && item.requesterDetails.status==3}
                                onClick={() => this.setState({ isAttachMentDocumentsModal: true })}
                              >
                                <FontAwesomeIcon
                                  className="mr-2"
                                  icon={faPaperclip}
                                />
                                {this.props.t("button_names.upload_file_attachments")}
                              </button>

                              {this.state.selectedFiles.length > 0 && (
                                <span className={"round-badge"}>{this.state.selectedFiles.length > 0 ? this.state.selectedFiles.length : ""}</span>
                              )}
                            </div>
                            {(compareRole(this.props.userRole, enumRole.Supplier) || compareRole(this.props.userRole, enumRole.Buyer) || (item.requesterDetails && item.requesterDetails.status != 3)) && (
                              <div className="btn-send-container">
                                <div onClick={this.sendMessage}
                                >
                                  <SendIcon htmlColor={this.user.currentUserRole() == "supplier" ? "#255F79" : this.user.currentUserRole() == "buyer" ? "#0572B0" : "#5ea03b"}></SendIcon>
                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">

                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show"></div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default withTranslation() (MessageModal);