import React, { Component } from 'react'
import FullSpinner from '../../common/Spinner/FullSpinner';
import API, { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import { dollarFormatter } from '../../../services/Utilities';
import User from '../../../services/User';
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { transactionType,compareTransactionType } from '../../../configs/GlobalConfig';
import CommonConfirmationModal from '../../common/Modals/CommonConfirmationModal';

const numberMask = createNumberMask({
    prefix: "$",
    suffix: "",
    allowDecimal: true
});

export default class ApproveFundingRequestModal extends Component {
    api = new API();
    user = new User();

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            creditLimit:{},
            tradingPartnerList:[],
            isApproveBtnEnabled:false
        };
    }

    componentDidMount() {
        const {data}=this.props;
        const {creditLimit}=this.state;
        if(data && data.fundingType[0]=="Invoice" && (process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED=="true")){
            let _tradingPartnerDetailsList=[];
            if(data.fundingDocuments && data.fundingDocuments.length>0 && (data.contracts && data.contracts.length>0 && data.contracts[0].addedNewCustomers && data.contracts[0].addedNewCustomers.length>0)){
                data.fundingDocuments.forEach(doc_ => {
                    if(doc_.customerDetails){
                        let _filteredTradingPartnerList=_tradingPartnerDetailsList.filter(t_=>t_.name==doc_.customerDetails.name);
                        if(_tradingPartnerDetailsList.length==0 || (_filteredTradingPartnerList.length==0)){
                            _tradingPartnerDetailsList.push(doc_.customerDetails);
                            creditLimit[doc_.customerDetails._id]={};
                            creditLimit[doc_.customerDetails._id].creditLimitValue=0;
                            creditLimit[doc_.customerDetails._id].fundingAmountValue=doc_.total;
                            creditLimit[doc_.customerDetails._id].tradingPartnerName=doc_.customerDetails.name;
                            creditLimit[doc_.customerDetails._id].maxCreditLimitValue=doc_.total;
                        }
                        else if(_filteredTradingPartnerList.length>0){
                            creditLimit[doc_.customerDetails._id].fundingAmountValue=creditLimit[doc_.customerDetails._id].fundingAmountValue + doc_.total;
                            creditLimit[doc_.customerDetails._id].maxCreditLimitValue=creditLimit[doc_.customerDetails._id].maxCreditLimitValue + doc_.total;
                        }

                    }
                });
                this.setState({tradingPartnerList:_tradingPartnerDetailsList,creditLimit});
            }
        }
    }

    handleCreditLimit=(e,data_)=> {
        e.preventDefault();
        let {creditLimit}=this.state;
        if(!creditLimit[e.target.name]){
            creditLimit[e.target.name]={};
        }
        creditLimit[e.target.name].creditLimitValue=Number(e.target.value.replace(/[^0-9.-]+/g,""));
        creditLimit[e.target.name].tradingPartnerName=data_.name;
        let _isValidAllCreditLimit=false;
        Object.keys(creditLimit).forEach(cID_=>{
            if(creditLimit[cID_].creditLimitValue >0 && creditLimit[cID_].creditLimitValue < creditLimit[cID_].maxCreditLimitValue){
                _isValidAllCreditLimit=true;
            }
        })
        this.setState({creditLimit,isApproveBtnEnabled:_isValidAllCreditLimit});
    }

    validateCreditLimitValue=(tpId_)=>{
        const {creditLimit}=this.state;
        if(creditLimit[tpId_].creditLimitValue==0){
            return false;
        }
        else{
            return !(creditLimit[tpId_].creditLimitValue >creditLimit[tpId_].maxCreditLimitValue);
        }
    }

    render() {
        let { 
            creditLimitForTradingPartner,
            _id,
            fundingReqId,
            fundingType
        } = this.props.data;
        return (
            <>     
                <CommonConfirmationModal
                    title={`${this.props.t("approve_funding_request_title")}: #${fundingReqId ? fundingReqId : ""}`}
                    submitText={this.props.t("button_names.approve_request")}
                    onClose={(e) => this.props.onClose()}
                    isSubmitBtn={this.state.isApproveBtnEnabled}
                    onSubmit={async (e) => {
                        let _data = {
                            _id: _id
                        }
                        if (compareTransactionType(fundingType[0], transactionType.Invoice)) {
                            let _creditLimitValue = this.state.creditLimit;
                            Object.keys(_creditLimitValue).forEach(c_ => {
                                if (c_.existingCreditLimitValue) {
                                    delete c_.existingCreditLimitValue;
                                }
                            });
                            _data.creditLimit = _creditLimitValue;
                        }
                        await this.api.acceptFundingRequest(_data);
                        this.props.onClose();
                    }}
                >
                    <div className="flex-column align-items-center">
                        <div>
                        {this.props.t("approve_funding_request_msg")}
                        </div>
                        {(compareTransactionType(fundingType[0], transactionType.Invoice) && this.state.tradingPartnerList.length>0 && (process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED=="true")) && (
                            <>
                                <label className="form-label-style mt-3" htmlFor="creditLimit">{this.props.t("credit_limit_for_trading_partners")}</label>
                                {this.state.tradingPartnerList.map((t_, index) => {
                                    return (
                                        <div className="row mb-3 mx-auto mt-3" key={index}>
                                            <div className="col-md-5">{t_.name}</div>
                                            <div className="col-md-7">
                                                <div className='flex-column'>
                                                    <MaskedInput
                                                        name={t_._id}
                                                        onChange={e => this.handleCreditLimit(e, t_)}
                                                        mask={numberMask}
                                                        className="form-control"
                                                        placeholder="$ ..."
                                                    />
                                                </div>
                                                {this.validateCreditLimitValue(t_._id) && (
                                                    <div className="formErrors">
                                                        {this.props.t("credit_limit_exceed_msg")}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        )}
                    </div>
                </CommonConfirmationModal>                     
            </>
        )
    }
}