import { getEntity, getIndustryWithId, dollarFormatter } from "../../../services/Utilities";

const UserDetails = ({ userDetails }) => {
    return (
        <span >
            <address className="normal-text-color wordWrap">
                {userDetails.name && (
                    <strong>
                        {userDetails.name}
                        <span
                            className={(userDetails && userDetails.role == "Supplier") ? "ml-2 badge badge-primary" :
                                (userDetails && userDetails.role == "Buyer") ? "ml-2 badge badge-primary" : "ml-2 badge badge-success"}>
                            {userDetails && userDetails.role == "Supplier" ? "Supplier" : userDetails && userDetails.role == "Buyer" ? "Buyer" : "Funder"}
                        </span>
                    </strong>
                )}
                {userDetails.address && (
                    <><br />{userDetails.address}
                    </>
                )}
                {userDetails.city && (
                    <><br />{userDetails.city}

                    </>
                )}
                {userDetails.state && (
                    <><br />{userDetails.state}
                    </>
                )}
                {userDetails.zip && (
                    <><br />{userDetails.zip}
                    </>
                )}
                {userDetails.country && (
                    <><br />{userDetails.country}
                    </>
                )}
                {userDetails.phone && (
                    <><br /><div className="d-flex"><b>Ph:</b> <div style={{fontSize:13,marginTop:2}}>&nbsp; {userDetails.phone} </div></div>
                    </>
                )}
                {userDetails.year && (
                    <>
                        <br />
                        <strong>Founded:{" "}</strong> {userDetails.year}

                    </>
                )}
                {userDetails.entity && (
                    <>
                        <br />
                        <strong>Entity:{" "}</strong>{getEntity(userDetails.entity)}

                    </>
                )
                }
                {userDetails.industry && (

                    <><br />
                        <strong>Industry:{" "}</strong>{getIndustryWithId(userDetails.industry)}
                    </>
                )
                }
                <br />
                Revenue:{" "}
                <strong className="link-text-color"> {dollarFormatter.format(userDetails.revenue? Number(
                userDetails.revenue.toString().replace(/[^0-9.-]+/g, "")
            ):0)}</strong>
            </address>
        </span>
    )
}

export default UserDetails;

