import React, { Component } from "react";
import Chart from "react-apexcharts";
import API from "../../../services/API";
import FullSpinner from "../Spinner/FullSpinner";
import { dollarFormatter,shortDollarFormatter } from '../../../services/Utilities';

export default class CustomerAgingDetailWidget extends Component {
  api = new API();

  constructor(props_) {
    super(props_);

    this.state = {
      isLoading: true,
      agingSelectedIndex: this.props.agingSelectedIndex,
      agingSelectedDataIndex: this.props.agingSelectedDataIndex,
    };
  }

  componentDidMount() {
    if (this.props.agingSelectedIndex >= 0) {
      this.getSupplierDashboard();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.agingSelectedIndex >= 0 &&
      (prevProps.agingSelectedIndex != this.props.agingSelectedIndex ||
        prevProps.agingSelectedDataIndex != this.props.agingSelectedDataIndex)
    ) {
      this.setState({
        series: [
          {
            data: [],
          },
        ],
      });
      this.getSupplierDashboard();
    }
  }

  async getV2Profile() {
    return await this.api.getV2Profile();
  }

  async getSupplierDashboard() {
    const profileResponse = await this.getV2Profile();
   
    let chartDataFilter = [];
    let chartDataCategories = [];
    let chartDataSeries = [];

    if (this.props.agingSelectedDataIndex == 0) {
      profileResponse.user.invoiceAgingReport.invoices.filter((invoice) => {
        switch (this.props.agingSelectedIndex) {
          case 0:
            if (invoice.currentDue > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.currentDue);
            }
            break;
          case 1:
            if (invoice.due30Days > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.due30Days);
            }
            break;
          case 2:
            if (invoice.due45Days > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.due45Days);
            }
            break;
          case 3:
            if (invoice.due60Days > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.due60Days);
            }
            break;
          case 4:
            if (invoice.due90Days > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.due90Days);
            }
            break;
          case 5:
            if (invoice.due120Days > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.due120Days);
            }
            break;
          case 6:
            if (invoice.due180Days > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.due180Days);
            }
            break;
          case 7:
            if (invoice.due180pluseDays > 0) {
              chartDataCategories.push(invoice.customerName);
              chartDataSeries.push(invoice.due180pluseDays);
            }
            break;
        }
      });
    } else if (this.props.agingSelectedDataIndex == 1) {
      profileResponse.user.payableAgingReport.Payables.filter((invoice) => {
        switch (this.props.agingSelectedIndex) {
          case 0:
            if (invoice.currentDue > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.currentDue);
            }
            break;
          case 1:
            if (invoice.due30Days > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.due30Days);
            }
            break;
          case 2:
            if (invoice.due45Days > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.due45Days);
            }
            break;
          case 3:
            if (invoice.due60Days > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.due60Days);
            }
            break;
          case 4:
            if (invoice.due90Days > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.due90Days);
            }
            break;
          case 5:
            if (invoice.due120Days > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.due120Days);
            }
            break;
          case 6:
            if (invoice.due180Days > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.due180Days);
            }
            break;
          case 7:
            if (invoice.due180pluseDays > 0) {
              chartDataCategories.push(invoice.VendorName);
              chartDataSeries.push(invoice.due180pluseDays);
            }
            break;
        }
      });
    }

    // Chart Height
    const minVal = 15;
    const maxVal = 20;
    const extraVal = 70;
    const availableVal = 340;
    let ticksVal = chartDataCategories.length;
    let tVal = (availableVal - extraVal) / ticksVal;

    let barheight = Math.min(maxVal, Math.max(tVal, minVal));

    let chartHeight = barheight * ticksVal + extraVal;

    let chartOptions = {
      chart: {
        type: "bar",
        // height: chartHeight,
      },
      colors:
        this.props.agingSelectedDataIndex == 0 ? ["#FFB45B"] : ["#0096FF"],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          // barHeight: barheight,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: chartDataCategories,
        labels: {
          formatter: value => shortDollarFormatter.format(value)
        }
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return "$" + value;
          },
        },
      },
    };

    let chartSeries = [
      {
        data: chartDataSeries,
      },
    ];

    this.setState({
      isLoading: false,
      options: chartOptions,
      series: chartSeries,
      chartData: chartDataFilter,
      height: chartHeight,
    });
  }

  render() {
    if (this.state.isLoading) {
      return <FullSpinner isSpinning={true} />;
    }

    return (
      <div style={{ width: "600px" }}>
        {this.props.agingSelectedIndex >= 0 && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-offset-2 col-md-8">
                <div className="mixed-chart">
                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height={this.state.height}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        {this.props.agingSelectedIndex < 0 && (
          <div
            className="awaiting-text-color text-center"
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            No records found
          </div>
        )}
      </div>
    );
  }
}
