//Author, Rajesh Rajendran
import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ label, name, value, onChange, placeholder, maxLength, disabled, required }) => {
    return (
        <div>
            <label htmlFor={name} className={`form-label-style ${required ? 'required' : ''}`}>{label}</label>
            <input
                type="text"
                className="form-control"
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                maxLength={maxLength}
                disabled={disabled}
            />
            {required && !value && <div className="custom-form-error">{label} is required</div>}
        </div>
    );
}

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    required: PropTypes.bool
};

TextInput.defaultProps = {
    placeholder: '',
    maxLength: 50,
    disabled: false,
    required: false
};

export default TextInput;
