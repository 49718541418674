import React, { Component } from 'react'
import FullSpinner from '../../common/Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import Popup from '../../common/Auth/Popup';
import DataGrid from '../../common/DataGrid/DataGrid';
import ConfirmSelectedMatchFundersModal from '../../common/Modals/ConfirmSelectedMatchFundersModal';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';

export default class ViewMatchedFunderModal extends Component {
    api = new API();

    _columnsSpecification = [
        {
            key: "name",
            name: this.props.t("fundingrequests_page.match_funders.name"),
        },
        // {
        //     key: "address",
        //     name: "Address",
        // },
        // {
        //     key: "city",
        //     name: "City",
        // },
        // {
        //     key: "state",
        //     name: "State",
        // },
        {
            key: "description",
            name: this.props.t("fundingrequests_page.match_funders.description"),
            // contentProvider: (datum_) => {
            //     if(datum_.description){
            //         return datum_.description;
            //     }
            //     else{
            //         return "No Description"
            //     }
            // }
        },
        // {
        //     key: "zip",
        //     name: "Zip",
        // },
        {
            key:"offerStatus",
            name:this.props.t("fundingrequests_page.match_funders.offer_status"),
            contentProvider: (datum_) => {
                switch (datum_.offerStatus) {
                    case "Waiting":
                        return <div style={{ color: "#FFB45B" }}>{this.props.t("waiting")}</div>;
                    case "Declined":
                        return <div style={{ color: "#930000" }}>{this.props.t("declined")}</div>;
                    case "Offered":
                        return <div style={{ color: "#4F984F" }}>{this.props.t("offered")}</div>;
                    default:
                        return datum_.offerStatus;
                }
            }
        },
        // {
        //     key: "websiteUrl",
        //     name: "Web Site",
        //     contentProvider: (datum_) => {
        //         let url=datum_.websiteUrl||"";
        //         if(url.indexOf("http")==-1){
        //             url=`http://${datum_.websiteUrl}`;
        //         }
        //         return <a className="link-text-color anchorstyle website-link" style={{ fontWeight: 600, cursor: "pointer" }} href={url} target="_blank">{datum_.websiteUrl}</a>
                
        //     }
        // }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            funders: [],
            selectedFunders: [],
            matchedFunders: [],
            isAllCheckboxEnabled:false,
            selectedItems:[],
            isConfirmFunders:false,
            emptydataLabel:this.props.t("fundingrequests_page.match_funders.no_data")
        };
        this.getMatchedFunders = this.getMatchedFunders.bind(this);
        this.selectFunder = this.selectFunder.bind(this);
        this.submitSelectedFunders = this.submitSelectedFunders.bind(this);
    }

    componentDidMount(){
        this.getMatchedFunders();
        if(this.props.data.selectedFunders){
            this.setState({
                matchedFunders: [...this.props.data.selectedFunders],
                selectedFunders: [...this.props.data.selectedFunders],
              })
        }
    }

    async getMatchedFunders() {
        const {data:{fundingType}}=this.props;
        let matchedFundersResponse=[];
        this.setState({emptydataLabel:this.props.t("fundingrequests_page.match_funders.please_wait_msg")});
        if (process.env.REACT_APP_GET_LOCAL_MATCHFUNDER) {
            matchedFundersResponse = await this.api.getFunders();
        }
        else {
            if (fundingType[0].toLowerCase() != "tcf") {
                const funders = await this.api.getMatchedFundersData(this.props.data.fundingReqId, fundingType[0].toLowerCase(), this.props.data.requesterId);
                if (funders.status) {
                    if (funders.message == "No data") {
                        this.setState({ emptydataLabel: funders.message });
                    }
                    else {
                        matchedFundersResponse = funders.message;
                    }
                }
                else {
                    if (funders.message != "No data") {
                        this.setState({ emptydataLabel: funders.message });
                    }
                }
            }
            else {
                matchedFundersResponse = await this.api.getFunders();
            }
        }
        let isAllCheckbox=false;
        let fundersResponse=matchedFundersResponse;
        let selectedItems_=[];
        if (this.props.data.selectedFunders) {
            fundersResponse=fundersResponse.map(funder=>{
                let isRowSelect = false;
                let offerStatus="";
                let isExistCustomer=this.props.data.selectedFunders.filter(b=>b==funder._id);
                if(this.props.data.selectedFunders){
                    for(let f_ of this.props.data.selectedFunders){
                        if(f_==funder._id){
                         offerStatus="Waiting";
                         break;
                        }
                    }
                }
                if(this.props.data.declinedFunders){
                    for(let f_ of this.props.data.declinedFunders){
                        if(f_==funder._id){
                         offerStatus="Declined";
                         break;
                        }
                    }
                 }
                if(this.props.data.contracts){
                    for(let c_ of this.props.data.contracts){
                        if(c_.funder==funder._id){
                         offerStatus="Offered";
                         break;
                        }
                    }
                }

                if (isExistCustomer.length>0) {
                    selectedItems_.push(funder);
                    isRowSelect = true;
                    isAllCheckbox = true;
                }

                funder.isRowSelect = isRowSelect;
                funder.offerStatus=offerStatus;
                return funder;
            })
        }
        this.setState({ funders: fundersResponse,isAllCheckboxEnabled:isAllCheckbox,selectedItems:selectedItems_});
    }

    selectFunder(funderId, event) {
        const { target } = event;
        const isSelectingFunder = target.type === 'checkbox' ? target.checked : target.value;
        const { selectedFunders } = this.state;
        let newFunders = selectedFunders;
        if (isSelectingFunder) {
            newFunders.push(funderId);
        } else {
            newFunders = selectedFunders.filter(funder => funder !== funderId);
        }
        this.setState({ selectedFunders: newFunders });
    }

    async submitSelectedFunders() {
        this.setState({ isConfirmFunders: true });
        // const { data } = this.props;
        // const { selectedFunders } = this.state;
        // const request = {
        //     fundingReqId: data._id,
        //     selectedFunders,
        // };
        // await this.api.addSelectFunders(request);
        // this.setState({ isLoading: false, matchedFunders: [] });
    }

    _onSelectionChange(items){
        let newFunders = [...this.state.matchedFunders];
        for (let fund of items){
            let exisitingFunder=newFunders.filter(d=>d==fund._id).length==0
            if(exisitingFunder){
                newFunders.push(fund._id);
           }
        }
        this.setState({ selectedFunders: newFunders,selectedItems:items });
    }

    render() {
        const { isLoading, funders, selectedFunders, matchedFunders } = this.state;
        const {data}=this.props;
        return (
            <CommonPopUpModal
                size="large"
                title={`${this.props.t("fundingrequests_page.match_funders.select_match_funders")}: #${data ? data.fundingReqId : ""}`}
                primaryBtn={this.props.t("fundingrequests_page.match_funders.send_request_to_funders")}
                onSubmit={this.submitSelectedFunders}
                isSubmitBtn={selectedFunders.length === 0 || selectedFunders.length == matchedFunders.length}
                onClose={e => this.props.onClose()}
                promptOnClose={false}
                children={
                    <>
                        {/* <FullSpinner
                        isSpinning={isLoading} confirmationLabel="Request Sent to Funders"
                        callback={e => this.props.onClose()}
                        /> */}
                        {this.state.isConfirmFunders && (
                            <div className="terms-checkbox-container">
                                {this.state.isConfirmFunders && this.state.selectedItems && <ConfirmSelectedMatchFundersModal
                                    onClose={(isClose) => {
                                        this.setState({ isConfirmFunders: false });
                                        if (isClose) {
                                            this.state.matchedFunders = [];
                                            this.props.onClose();
                                        }
                                    }}
                                    data={this.props.data}
                                    funderIDs={this.state.selectedFunders}
                                    selectedItems={this.state.selectedItems}
                                    t={this.props.t}
                                />}</div>
                        )}
                        <div className="mar-top">
                            <DataGrid
                                title={this.props.t("fundingrequests_page.match_funders.select_match_funders")}
                                emptydataLabel={this.state.emptydataLabel}
                                data={funders}
                                columns={this._columnsSpecification}
                                isNoPagination={true}
                                isRowsNonSelectable={false}
                                isGridSearch={false}
                                isNoMoreLink={true}
                                height={"50vh"}
                                onSelectionChange={(items) => this._onSelectionChange(items)}
                                isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                                selectedItems={this.state.selectedItems}
                            />
                        </div>  
                    </>
                }
            >
            </CommonPopUpModal>
        )
    }
}