import React, { Component } from 'react';
import $ from 'jquery';
import FullSpinner from '../Spinner/FullSpinner';
import AppState from '../../../contexts/AppState/AppState';
import { withTranslation } from 'react-i18next';

/**
 * Props expected:
 * title
 * children > message
 * onClose
 * onSubmit?
 * 
 */
class CommonConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
    this.doSubmit = this.doSubmit.bind(this);
  }

  async doSubmit(e) {
     this.setState({ isLoading: true });
    e.preventDefault();
    this.setState({ isLoading: true });

    if (this.props.onSubmit) {
      await this.props.onSubmit();
    }

    $('#fundInvoiceModal').modal('hide');
    this.setState({
      isLoading: false,
      isRPAUploaded: false,
    });

    this.props.onClose();
  }

  render() {
    const {
      isLoading
    } = this.state;
    const {t}=this.props;
    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>
            <FullSpinner isSpinning={this.props.isLoading} callback={appState.state.getInvoices} />
            <form onSubmit={e => { e.preventDefault(); this.doSubmit(e) }}>
            <div className="modal-backdrop fade show"></div>
              <div className="alert-modal show alert-zindex" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: "block", zIndex: 99999 }}>
                <div className="alert-modal-dialog alert-modal-dialog-centered" role="document">
                  <div className="alert-modal-content">
                    <div className="alert-modal-header">
                      <h4 className="alert-modal-title" id="exampleModalLabel">{this.props.title}</h4>
                      <button type="button" className="alert-modal-close" aria-label="Close" onClick={this.props.onClose}>
                        &times;
                      </button>
                    </div>
                    <div className="alert-modal-body d-flex flex-column align-items-center text-center ">
                      <label>{this.props.children}</label>
                    </div>
                    <div className="alert-modal-footer justify-content-center">
                      {!this.props.noCancel && (
                        <button type="button" className="btn btn-outline-secondary alert-btn mr-2" onClick={this.props.onClose} >
                          {this.props.cancelText?this.props.cancelText:this.props.t("button_names.cancel")}
                        </button>
                      )}
                      <button type="submit" disabled={this.props.isSubmitBtn} className={`btn ${this.props.isPrimaryButtonDanger ? "btn-danger" : "btn-primary"} alert-btn title-case`} >
                        {this.props.submitText?this.props.submitText:this.props.t("button_names.ok")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div onClick={this.props.onClose}></div>
            </form>
            <div className="modal-backdrop fade show"></div>
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default withTranslation() (CommonConfirmationModal);

export var AddModal;

class ModalContainer extends React.Component {
  constructor(props_) {
    super(props_);
    this.state = {
      modal: null
    };

    AddModal = this._addModal;
  }

  _addModal = (title_, message_, okText_, cancel_) => {
    return new Promise((res_,rej_) => {
      let isSubmitted = false;
      let modal =
        <CommonConfirmationModal
          title={title_}
          submitText={okText_}
          cancelText={cancel_}
          noCancel={cancel_ == false}
          onSubmit={() => { isSubmitted = true; }}
          t={this.props.t}
          onClose={() => {
           // resolve({ submitted: isSubmitted });
            isSubmitted ? res_(isSubmitted) : rej_(isSubmitted);
            this.setState({
              modal: null
            });
          }}
        >
          {message_}
        </CommonConfirmationModal>

      this.setState({
        modal: modal
      });
    });
  }

  render() {
    if (this.state.modal) {
      return this.state.modal;
    }

    return null;
  }
}

let _modalContainer=withTranslation() (ModalContainer);
export{_modalContainer as ModalContainer}