import React, { Component } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faExclamationTriangle, faSortUp, faSortDown, faFilter } from "@fortawesome/free-solid-svg-icons";
import AppState from '../../../../contexts/AppState/AppState';
import AppProvider from '../../../../contexts/AppState/AppStateProvider';
import API from '../../../../services/API';
import { InvoiceModal } from "./InvoiceModal";
import User from "../../../../services/User";
import FullSpinner from "../../../common/Spinner/FullSpinner";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import BuyerSubmitInvoiceModal from './BuyerSubmitInvoiceModal';
import { getAllFinancialDocIsPresent } from "../../../../services/Utilities";
import DataGrid from '../../../common/DataGrid/DataGrid';
import DataGridFilter from '../../../common/DataGrid/DataGridFilter';
import { dollarFormatter, dateFormatter,localDateFormat } from '../../../../services/Utilities';
import ViewInvoiceModal from "./ViewInvoiceModal";
import CommonCreateFundingRequestModal from "../../../common/Modals/CommonCreateFundingRequestModal";
import { NavLink} from "react-router-dom";

export default class InvoicesList extends Component {
    user = new User();
    api = new API();

    _columnsSpecification = [
        {
            key: "customerName",
            name: "Customer Name"
        },
        {
            key: "documentId",
            name: "Invoice #",
            eventType: "view"
        },
        {
            key: "total",
            name: "Invoice Amount",
            textAlignment:"right",
            contentProvider: (datum_) => {
                return <div style={{color: "#255F79", fontWeight: 600 }}>{dollarFormatter.format(datum_.total)}</div>
            }
        },
        {
            key: "documentDate",
            name: "Invoice Date",
            contentProvider: (datum_) => {
                return localDateFormat(datum_.documentDate);
            }
        },
        {
            key: "documentDueDate",
            name: "Due Date",
            contentProvider: (datum_) => {
                return `${localDateFormat(datum_.documentDueDate)} (${PaymentTerm(datum_.invoice.net)})`;
            }
        },
        {
            key: "status",
            name: "Status",
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        return <div className="awaiting-text-color" style={{fontWeight:"600"}}>Awaiting Submission</div>;
                    case 1:
                        return <div className="awaiting-text-color" style={{fontWeight:"600"}}>Awaiting Buyer Approval</div>;
                    case 2:
                        return <div className="progress-text-color">Buyer Approved</div>;
                    case 3:
                        return <div className="archived-text-color">Buyer Rejected</div>;
                    case 99:
                        return <div className="progress-text-color">In Funding</div>;
                    case 100:
                        return <div className="archived-text-color">Archived</div>;
                    default:
                        return datum_.statusText;
                }
            }
        },
        {
            key: "action",
            name: "Type/Request",
            contentProvider: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        // if (new Date(datum_.documentDueDate) < new Date()) {
                        //     return <div style={{ color: "#930000", fontWeight: "600" }}>Not Eligible</div>;
                        // }
                        // else {
                            return <div><button className="submit-invoice-button"
                            >Submit Invoice</button></div>;
                        //}
                        
                    case 1:
                        return "Awaiting Buyer Approval";
                    case 2:
                        return <div style={{ color: "#999999", fontWeight: "600" }}>Open</div>;
                    case 3:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>Archived</div>;
                    case 99:
                        return <div ><NavLink className="anchorstyle" style={{ color: "#5F9FBC"}} to="/fundingrequest">{datum_.fundingReqId}</NavLink></div>;
                    case 100:
                        return <div style={{ color: "#930000", fontWeight: "600" }}>Archived</div>;
                    default:
                        return "Unknown";
                }
            },
            eventType: (datum_) => {
                switch (datum_.status) {
                    case 0:
                        // if (new Date(datum_.documentDueDate) < new Date()) {
                        //     return "";
                        // }
                        // else {
                            return "SubmitInvoice";
                        //}
                    case 1:
                        return "";
                    case 2:
                        return "open";
                    case 3:
                    case 99:
                    case 100:
                        return "";
                    default:
                        return "";
                }
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            loadingText: "",
            isUploadPopupOpen: props.isUpload,
            allInvoices: [],
            invoices: [],
            activeTab: 'open',
            CFR: [],
            isSubmitInvoice: false,
            selectedInvoice: "",
            missingFinDoc: "",
            taxId: null,
            isAllCheckboxEnabled: true,
            customers: [],
            isViewPopupOpen: false,
            isCFRModalOpen: false,
            filtersApplied: {},
            isResetFilterEnabled:false,
            isDeleteInvoice:false
        };
        this.getInvoices = this.getInvoices.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }
    componentDidMount() {
        this.getInvoices();
        this.checkIfSupplierUploadedDocumentation();
    }

    componentWillMount() {
        this.getProfile();
    }

    async checkIfSupplierUploadedDocumentation() {
        const res = await getAllFinancialDocIsPresent(this.props.appState.state.currentUser.email)
        this.setState({
            missingFinDoc: res
        })
    }
    async getInvoices() {
        const invoicesResponse = await this.api.docGetInvoices();
        this.setInvoices(invoicesResponse.invoices);
    }
    async setInvoices(invoices) {
        let isAllCheckbox = true;
        let buyers = [];
        const invoice = invoices
            .map(inv => {
                if (inv.status != 100) {
                    let isRowSelect = true;
                    let isExistCustomer = buyers.filter(b => b == inv.invoice.buyerName);
                    if (isExistCustomer.length == 0) {
                        buyers.push(inv.invoice.buyerName);
                    }
                    if (inv.status == 2) {
                        isRowSelect = false;
                        isAllCheckbox = false;
                    }
                    inv.isRowSelect = isRowSelect;
                }

                if (inv.invoice) {
                    if (inv.invoice.customerDetails) {
                        inv.customerName = inv.invoice.customerDetails.name;
                    }
                    else if (inv.invoice.buyer) {
                        inv.customerName = inv.invoice.buyer;
                    }
                    else if (inv.invoice.buyerName) {
                        inv.customerName = inv.invoice.buyerName;
                    }
                }

                switch (inv.status) {
                    case 0:
                        inv.statusText = "Awaiting Submission";
                        break;
                    case 1:
                        inv.statusText = "Awaiting Buyer Approval";
                        break;
                    case 2:
                        inv.statusText = "Buyer Approved";
                        break;
                    case 3:
                        inv.statusText = "Buyer Rejected";
                        break;
                    case 99:
                        inv.statusText = "In Funding";
                        break;
                    case 100:
                        inv.statusText = "Archived";
                        break;
                    default:
                        inv.statusText = "Unknown";
                }

                return inv;
            });
            buyers=buyers.sort();
        this.setState({ allInvoices: invoice, isAllCheckboxEnabled: isAllCheckbox, customers: buyers }, () => {
            this._resetFilter();
            this.onFilterChange();
        });
    }
    getDocumentId(objectArray) {
        var keyArray = []
        for (var i = 0; i < objectArray.length; i++) {
            keyArray.push(objectArray[i].documentId);
        }
        return keyArray.toString();
    }

    async getProfile() {
        const profileResponse = await this.api.getProfile();
        this.setState({
            taxId: profileResponse.user.taxId
        })
    }

    componentDidUpdate(prevProps_, prevState) {
        if (prevProps_.isUpload !== this.props.isUpload) {
            this.setState({ isUploadPopupOpen: this.props.isUpload });
        }
        if (prevProps_.isCreateFundingRequest != this.props.isCreateFundingRequest) {
            if (this.props.isCreateFundingRequest) {
                this.loadABL();
            } else {
                this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
            }
        }
    }

    async loadABL() {
        const ablResponse= await this.api.docGetABL();
        if(ablResponse && ablResponse.length>0){
            if(ablResponse[0].status==99){
                this.props.createFundingRequest();
                this.props.hasCreateFundingRequest(0);
                this.setState({ selectedItems:null },()=>{
                    AddModal('Incomplete Asset-Based Lending Contract',  <div>
                    There is already <b>Asset-Based lending</b> in progress, complete the process and then initiate new transactions</div> , null, false);
                });
            }
            else{
                this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
            }
        }else{
            this.setState({ isCFRModalOpen: this.props.isCreateFundingRequest });
        }
    }

    archived(e) {
        const { filteredInvoices, invoices } = this.state;
        const invoiceDocs = filteredInvoices.length > 0 ? filteredInvoices : invoices;
        if (e.target.checked) {
            const archivedInvoices = invoiceDocs.filter(inv => inv.status == 100);
            this.setState({ filteredInvoices: archivedInvoices, isAllCheckboxEnabled: true, isArchived: true });
        }
        else {
            // const archivedInvoices=invoices.filter(inv=>inv.status!=100);
            this.getInvoices();
        }
    }

    onFilterChange() {
        let filteredText = this.state.filtersApplied.textFilter;

        if (filteredText) {
            filteredText = filteredText.toLowerCase();
        }

        let filteredInvoices = this.state.allInvoices.filter(invoice_ => {
            if (!this.state.filtersApplied.archived) {
                if (invoice_.status == 100) {
                    invoice_.isRowSelect = true;
                    return false;
                }
            }

            if (this.state.filtersApplied.customers && this.state.filtersApplied.customers.length) {
                if (invoice_.customerName != this.state.filtersApplied.customers[0].text) {
                    return false;
                }
            }

            if (this.state.filtersApplied.status && this.state.filtersApplied.status.length) {
                if (invoice_.status != this.state.filtersApplied.status[0].value) {
                    return false;
                }
            }

            if (this.state.filtersApplied.invoiceDate &&
                this.state.filtersApplied.invoiceDate.length &&
                this.state.filtersApplied.invoiceDate[0].startDate) {
                let startDate = this.state.filtersApplied.invoiceDate[0].startDate;
                let endDate = this.state.filtersApplied.invoiceDate[0].endDate;
                endDate.setHours(23);
                endDate.setMinutes(59);
                let date = new Date(invoice_.documentDate);
                if (date < startDate || date > endDate) {
                    return false;
                }
            }

            if (filteredText) {
                if (!(invoice_.customerName.toLowerCase().indexOf(filteredText) >= 0 ||
                    invoice_.documentId.toLowerCase().indexOf(filteredText) >= 0 ||
                    invoice_.statusText.toLowerCase().indexOf(filteredText) >= 0)) {
                    return false;
                }
            }

            return true;
        });

        this.setState({
            invoices: filteredInvoices
        },()=>{
            this._hasResetFilterEnabled();
        });

        // const { filteredInvoices, invoices } = this.state;
        // const invoiceDocs = invoices;
        // const _customers = customerName_.map(cus => cus.value);
        // let isAllCheckbox = true;
        // const filteredCustomers = invoiceDocs.filter(inv => {
        //     for (let cus of _customers) {
        //         if (cus == inv.invoice.buyerName) {
        //             return true;
        //         }
        //     }
        //     return false;
        // }).map(inv => {
        //     let isRowSelect = true;
        //     if (inv.status == 2) {
        //         isRowSelect = false;
        //         isAllCheckbox = false;
        //     }
        //     inv.isRowSelect = isRowSelect;
        //     return inv;
        // })
        // this.setState({ filteredInvoices: filteredCustomers, isAllCheckboxEnabled: isAllCheckbox });
    }

    _onSelectionChange(items) {
        this.setState({ CFR: items });
        this.props.hasCreateFundingRequest(items.length);
    }


    render() {
        const { isUploadPopupOpen, customers, isViewPopupOpen,
            selectedInvoice, isSubmitInvoice, isCFRModalOpen,isDeleteInvoice ,isLoading} = this.state;
        const invoices = this.state.invoices;
        return (
            <AppProvider>
                <AppState.Consumer>
                    {appState => (
                        <React.Fragment>
                            <FullSpinner
                                isSpinning={isLoading}
                            />
                            {(isUploadPopupOpen || isViewPopupOpen || isSubmitInvoice || isCFRModalOpen ||isDeleteInvoice) && (
                                <div className="terms-checkbox-container">
                                    {isUploadPopupOpen && <InvoiceModal
                                        isReadOnly={false}
                                        invoices={this.state.invoices}
                                        onClose={() => {
                                            this.getInvoices();
                                            this.setState({ isUploadPopupOpen: false},()=>{
                                               this._onSelectionChange([]);
                                            });
                                            this.props.upload();
                                        }}
                                    />}
                                    {isViewPopupOpen && selectedInvoice && <ViewInvoiceModal
                                        isReadOnly={false}
                                        data={selectedInvoice}
                                        onClose={() => {
                                            this.setState({ isViewPopupOpen: false, selectedInvoice: null });
                                        }}
                                    />}
                                    {isSubmitInvoice && selectedInvoice && <BuyerSubmitInvoiceModal
                                        isReadOnly={false}
                                        data={selectedInvoice}
                                        onClose={() => {
                                            this.getInvoices();
                                            this.setState({ isSubmitInvoice: false, selectedInvoice: null });
                                        }}
                                    />}
                                    {isCFRModalOpen && this.state.CFR && <CommonCreateFundingRequestModal
                                        isReadOnly={false}
                                        data={this.state.CFR}
                                        onClose={() => {
                                            this.getInvoices();
                                            this.setState({ isCFRModalOpen: false, CFR: null });
                                            this.props.createFundingRequest();
                                            this.props.hasCreateFundingRequest(0);
                                        }}
                                    />}
                                    {
                                        this.state.isDeleteInvoice && (
                                            <CommonConfirmationModal
                                                title={"Delete Invoice: #" + this.state.selectedInvoice.documentId}
                                                submitText="Delete"
                                                onClose={() => {
                                                    this.getInvoices();
                                                    this.setState({ isDeleteInvoice: false, selectedInvoice: null });
                                                }}
                                                onSubmit={async() => {
                                                 //  this.setState({isLoading:true});
                                                   await this.api.deleteDocument(this.state.selectedInvoice);
                                                   const deletedInvoice_=this.state.selectedInvoice;
                                                //    let selectedItems_=this.state.CFR;
                                                //    let filteredSelectedItems_=[];
                                                //    for(let items_ of selectedItems_){
                                                //        if(items_._id!=deletedInvoice_._id){
                                                //            filteredSelectedItems_.push(items_);
                                                //        }
                                                //    }
                                                   this.setState({CFR:[]});
                                                   this.props.hasCreateFundingRequest(0);
                                                   
                                                }}
                                            >
                                                Are you sure you want to delete this invoice?
                                            </CommonConfirmationModal>
                                        )
                                    }
                                </div>
                            )}
                            <DataGrid
                                title="Invoices"
                                emptydataLabel="No Invoice(s) Found"
                                data={invoices}
                                columns={this._columnsSpecification}
                                isNoPagination={true}
                                onSelectionChange={(items) => this._onSelectionChange(items)}
                                isRowsNonSelectable={false}
                                isGridSearch={true}
                                isNoMoreLink={false}
                                appliedFilter={this.state.filtersApplied}
                                resetFilters={() => this._resetFilter()}
                                searchText={(text_) => {
                                    this.state.filtersApplied.textFilter = text_;
                                    this.setState({
                                        filtersApplied: this.state.filtersApplied
                                    }, () => this.onFilterChange());
                                }}
                                popoverItems={ [
                                    { key: "deleteInvoice", label: "Delete Invoice" }
                                ]}
                                onPopoverClick={(key_, row_) => {
                                    if (key_ == "deleteInvoice") {
                                        this.setState({
                                            isDeleteInvoice: true,
                                            selectedInvoice: row_,
                                        })
                                    }
                                }}
                                filterComponent={
                                    <React.Fragment>
                                        <DataGridFilter
                                            label="Customer"
                                            type="options"
                                            options={
                                                customers.map(cus => {
                                                    return ({ value: cus, text: cus })
                                                })
                                            }
                                            value={this.state.filtersApplied.customers}
                                            change={(filter_) => {
                                                this.state.filtersApplied.customers = filter_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => this.onFilterChange());
                                            }}
                                        />
                                        <DataGridFilter
                                            label="Date Range"
                                            type="date_range"
                                            value={this.state.filtersApplied.invoiceDate}
                                            change={(filter_) => {
                                                this.state.filtersApplied.invoiceDate = filter_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => this.onFilterChange());
                                            }}
                                        />
                                        <DataGridFilter
                                            label="Show Archived"
                                            type="boolean"
                                            value={this.state.filtersApplied.archived}
                                            change={(filter_) => {
                                                this.state.filtersApplied.archived = filter_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => this.onFilterChange());
                                            }}
                                        />
                                        <DataGridFilter
                                            label="Status"
                                            type="options"
                                            options={
                                                [
                                                    {value:0,text:"Awaiting Submission"},
                                                    {value:1,text:"Awaiting Buyer Approval"},
                                                    {value:2,text:"Buyer Approved"},
                                                    {value:3,text:"Buyer Rejected"},
                                                    {value:99,text:"In Funding"},
                                                    {value:100,text:"Archived"}
                                                ]
                                            }
                                            value={this.state.filtersApplied.status}
                                            change={(filter_) => {
                                                this.state.filtersApplied.status = filter_;
                                                this.setState({
                                                    filtersApplied: this.state.filtersApplied
                                                }, () => this.onFilterChange());
                                            }}
                                        />
                                    </React.Fragment>
                                }
                                isAllCheckboxEnabled={this.state.isAllCheckboxEnabled}
                                handleTableCellClick={(dataRow, type, index) => {
                                    if (type == "view" || type == "open") {
                                        this.setState({
                                            isViewPopupOpen: true,
                                            selectedInvoice: dataRow,
                                        });
                                    }
                                    else if (type == "SubmitInvoice") {
                                        this.setState({
                                            isSubmitInvoice: true,
                                            selectedInvoice: dataRow,
                                        });
                                    }
                                }}
                                selectedItems={this.state.CFR}
                                isResetFilterEnabled={this.state.isResetFilterEnabled}
                            />
                            {/* <Modals invoices={this.state.activeInvoices} /> */}
                        </React.Fragment>
                    )}
                </AppState.Consumer>
            </AppProvider>
        )
    }

    _resetFilter = () => {
        this.state.filtersApplied.textFilter = "";
        this.state.filtersApplied.archived = false;
        this.state.filtersApplied.customers = [];
        this.state.filtersApplied.status = [];
        this.state.filtersApplied.invoiceDate = [{
            startDate: null,
            endDate: null,
            key: "selection"
        }];
        this.setState({
            filtersApplied: this.state.filtersApplied
        }, () => this.onFilterChange());
    }

    _hasResetFilterEnabled=()=>{
        if(this.state.filtersApplied.textFilter!="" || this.state.filtersApplied.archived ||this.state.filtersApplied.customers.length>0 
        || this.state.filtersApplied.status.length>0 || (this.state.filtersApplied.invoiceDate[0].startDate && this.state.filtersApplied.invoiceDate[0].endDate)){
            this.setState({isResetFilterEnabled:true});
        }
        else{
            this.setState({isResetFilterEnabled:false});
        }
    }
}


const PaymentTerm = (status) => {
    switch (status) {
        case 1:
            return "Net 30";
        case 2:
            return "Net 45";
        case 3:
            return "Net 60";
        case 4:
            return "Net 90";
        case 5:
            return "Net 120";
        case 6:
            return "Net 180";
        default:
            return "Net 30";
    }
};