import React, { Component } from 'react';
import moment from 'moment';
import { validateEmail } from '../../../../services/Utilities';
import FullSpinner from '../../../common/Spinner/FullSpinner';
import AppState from '../../../../contexts/AppState/AppState';
import CommonConfirmationModal, {AddModal} from '../../../common/Modals/CommonConfirmationModal';
import API from '../../../../services/API';
import Popup from '../../../common/Auth/Popup';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import VieweSupplierDetails from './viewSupplierDetails';
import CommonPopUpModal from '../../../CommonPopUpModal/CommonPopUpModal';

export default class SubmitPOModal extends Component {
    api = new API();

    constructor(props){
        super(props);
        this.state = {
            purchaseOrderDetails:this.props && this.props.data?this.props.data:"",
            isLoading: false,
            isSendingInvite: false,
            errMsg: '',
            isSupplierAvailable:false,
            isshowSupplierDetails:false,
            existingSupplier:[],
            allSuppliers:[],
            supplierEmail:this.props.data && this.props.data.purchaseOrder && this.props.data.purchaseOrder.supplierEmail?this.props.data.purchaseOrder.supplierEmail:"",
        };
    }

    componentDidMount() {
        this.checkSuppliers();
    }

    async checkSuppliers(){
        const {purchaseOrderDetails:{purchaseOrder}}=this.state;
        let supplierDetails=purchaseOrder;
        if(supplierDetails ){
            let supplier=await this.api.getSupplierByNameAndEmail(purchaseOrder.supplierName,purchaseOrder.supplierEmail);
            if(supplier.filteredSupplier && supplier.filteredSupplier.length>0){
                if(supplier.allSupplier && supplier.allSupplier.length>0){
                    this.setState({
                        isSupplierAvailable:true,
                        existingSupplier:supplier.filteredSupplier,
                        allSuppliers:supplier.allSupplier
                    })
                }
            }
        }
    }

    sendInvite=async(e)=> {
        e.preventDefault();
        const { invoice, buyerInInvoice, supplierEmail } = this.state;
        const customers = {
            "documentId": this.props.data._id,
            "supplierName": this.props.data.purchaseOrder.supplierName,
            "supplierEmail": supplierEmail,
        }
        let that = this;

        if (validateEmail(supplierEmail)) {
            this.setState({ isSendingInvite: true });
            this.api.sendInviteToSupplier(customers).then(res => {
                this.setState({ isSendingInvite: false });
                if (res.message === "Validation failed" && res.details.length > 0) {
                    that.setState({ errMsg: res.details[0].message });
                }
                else {
                    that.setState({ errMsg: '' });
                }
            });
        } else {
            AddModal(this.props.t("required_email_address"), this.props.t("kindly_enter_email_address"), null, false);
        }

    }

    submitPurchaseOrder=async()=> {
        this.setState({ isLoading: true });
        const { existingSupplier } = this.state;
        let submitPODetails={
            supplierId:existingSupplier[0]._id,
            _id:this.props.data._id
        }
        await this.api.SubmitPurchaseOrderToSupplier(submitPODetails);
       this.setState({ isLoading: false });
    }


    render() {
        const {data}=this.props;
        const { isLoading, isSendingInvite,existingSupplier,allSuppliers ,isSupplierAvailable,supplierEmail,errMsg ,isshowSupplierDetails} = this.state;
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <div className="popup">
                            {this.state.isshowSupplierDetails && (
                                <VieweSupplierDetails data={existingSupplier} t={this.props.t} onClose={(e)=>{this.setState({isshowSupplierDetails:false})}} />
                            )}
                        <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("transactions_page.po_content.purchase_order_submitted")} callback={e => this.props.onClose()} />
                        <FullSpinner isSpinning={isSendingInvite} confirmationLabel={this.props.t("transactions_page.submit_invoice.invite_sent")} />
                            <CommonPopUpModal
                                size='small-medium'
                                title={`${this.props.t("transactions_page.po_content.submit_po")}: #${this.props && this.props.data && this.props.data.documentId ? this.props.data.documentId : ""}`}
                                onClose={e => this.props.onClose()}
                                primaryBtn={existingSupplier.length > 0 ? this.props.t("button_names.submit") : false}
                                isSubmitBtn={existingSupplier.length == 0}
                                onSubmit={this.submitPurchaseOrder}
                                promptOnClose={false}
                            >
                            <form>
                                <div className="row">
                                    {isSupplierAvailable ? (
                                        <>
                                            <div className="col-12">
                                                <div className="form-group mt-2">
                                                    <label className="form-label-style">{this.props.t("transactions_page.po_content.select_supplier")}</label>
                                                    <select id="buyerSelect" className="form-control" onChange={(e)=>{
                                                        const selectedSupplier_=allSuppliers.filter(d_=>d_._id==e.target.value);
                                                        this.setState({existingSupplier:selectedSupplier_});
                                                    }}>
                                                        <option value="n/a">{this.props.t("transactions_page.submit_invoice.select_from_below")}</option>
                                                        {allSuppliers.map(supplier => <option selected={existingSupplier[0]._id === supplier._id} value={supplier._id}>{supplier.name}</option>)}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 text-right">
                                                <a className='anchorstyle' href='' onClick={(e)=>{
                                                    e.preventDefault();
                                                    this.setState({
                                                        isshowSupplierDetails:true
                                                    })
                                                }}>{this.props.t("transactions_page.submit_invoice.view_supplier_details")}</a>
                                            </div>

                                            
                                        </>
                                    ) : (<>
                                        <div className="col-12">
                                            <div className="submit-invoice-msg">
                                                <p>{data.purchaseOrder && data.purchaseOrder.supplierName ? data.purchaseOrder.supplierName : ""} {this.props.t("transactions_page.po_content.po_user_not_in_lf")}</p>
                                                <div className="form-group">
                                                    <label className="form-label-style">{this.props.t("grid_view_header_labels.supplier_email")}</label>
                                                    <div className="input-group mb-3">
                                                        <input type="email" value={supplierEmail}
                                                            onChange={e => this.setState({ supplierEmail: e.target.value })}
                                                            className="form-control" ></input>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-sm btn-info" onClick={this.sendInvite}>{this.props.t("button_names.invite")}</button>
                                                        </div>
                                                    </div>
                                                    {errMsg && errMsg !== '' && (
                                                        <div>
                                                            <span className="formErrors">
                                                                {errMsg}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>)}
                                </div>
                            </form>
                            </CommonPopUpModal>
                        </div>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}
