import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AppProvider from "../../contexts/AppState/AppStateProvider";
import AppState from "../../contexts/AppState/AppState";
import FullSpinner from "../../components/common/Spinner/FullSpinner";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import pic2 from '../../assets/images/Income-Factoring.png';

import Main from "../Main/Main";
import Auth from "../common/Auth/Auth";
import { AddModal, ModalContainer } from "../common/Modals/CommonConfirmationModal";

import User from "../../services/User";
import API, {
  API_ENDPOINT_SUPPLIER,
  API_ENDPOINT_BUYER,
  API_ENDPOINT_FUNDER,
  V2_API_ENDPOINT_SUPPLIER,
  V2_API_ENDPOINT_BUYER,
  V2_API_ENDPOINT_FUNDER,
  API_ENDPOINT_ADMIN
} from "../../services/API";
import "./app.css";
import "../../styles/layout.scss";
import { clearImpersonationData } from "../../services/userImpersonationService";

class App extends Component {
  user = new User();

  api = new API();

  constructor(props) {
    super(props);
    this.state = {
      currentUser: this.user.currentUser(),
      userName: null
    };
    this.detectAndSetUserRole = this.detectAndSetUserRole.bind(this);
  }

  componentDidMount() {
    const { currentUser } = this.state;
    const { history } = this.props;
    if (this.detectAndSetUserRole() && Object.getOwnPropertyNames(currentUser).length === 0) {
      history.push("/login");
    } else {
      // history.push('/dashboard');
    }

    this.getProfile();
  }
  async getProfile() {
    try {
      if (this.user.currentUserRole() != "admin") {
        const profileResponse = await this.api.getProfile();
        this.setState({ userName: profileResponse.user.name });
      }
      else {
        this.setState({ userName: "User" });
      }
    } catch (error) {
      console.error(error);
      this.setState({ userName: "User" });
    }
  }

  detectAndSetUserRole() {
    const {
      location: { pathname }
    } = this.props;

    // if (window.location.origin.toLowerCase() != process.env.REACT_APP_UI_URL.toLowerCase()) {
    //   window.location.href = process.env.REACT_APP_UI_URL + pathname;
    //   return false;
    // }
    //clearImpersonationData();
    if (
      pathname === "/buyer" || pathname.indexOf("/buyer") > -1 ||
      pathname === "/supplier" || pathname.indexOf("/supplier") > -1 ||
      pathname === "/funder" ||
      pathname === "/admin"
    ) {
      switch (pathname) {
        case "/supplier":
          this.user.setCurrentUserRole("supplier");
          this.api.setCurrentUserEndpoint(API_ENDPOINT_SUPPLIER);
          this.api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_SUPPLIER);

          require("../../supplier.scss");
          require("../../styles/supplier.theme.scss");
          document.title = "LedgerFunding Supplier";
          let token_ = pathname.replace('/supplier/', '');
          if (token_.length > 0 && pathname !== "/supplier") {
            this.user.setSupplierToken(token_);
            this.props.history.push("/register");
            return false;
          }
          break;
        case "/admin":
          localStorage.clear();
          this.user.setCurrentUserRole("admin");
          this.api.setCurrentUserEndpoint(API_ENDPOINT_ADMIN);
          require("../../admin.scss");
          require("../../styles/admin.theme.scss");
          document.title = "LedgerFunding Admin";
          break;
        case "/funder":
          this.user.setCurrentUserRole("funder");
          this.api.setCurrentUserEndpoint(API_ENDPOINT_FUNDER);
          this.api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_FUNDER);
          require("../../funder.scss");
          require("../../styles/funder.theme.scss");
          document.title = "LedgerFunding Funder";
          break;
        default:
          if(pathname.indexOf("/supplier") > -1){
            this.user.setCurrentUserRole("supplier");
            this.api.setCurrentUserEndpoint(API_ENDPOINT_SUPPLIER);
            this.api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_SUPPLIER);
  
            require("../../supplier.scss");
            require("../../styles/supplier.theme.scss");
            document.title = "LedgerFunding Supplier";
            let _supplierToken = pathname.replace("/supplier/","")
            if (_supplierToken.length > 0 && pathname !== "/supplier") {
              if(_supplierToken){
                this.user.setSupplierToken(_supplierToken);
              }
              else{
                this.user.setSupplierToken(pathname.replace("/supplier/",""))
              }

              this.props.history.push("/register");
              return false;
            }
            break;
          }
          else{
            this.user.setCurrentUserRole("buyer");
            this.api.setCurrentUserEndpoint(API_ENDPOINT_BUYER);
            this.api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_BUYER);
            require("../../buyer.scss");
            require("../../styles/buyer.theme.scss");
            document.title = "LedgerFunding Buyer";
            let token = pathname.replace('/buyer/', '');
            if (token.length > 0 && pathname !== "/buyer") {
              this.user.setBuyerToken(token);
              this.props.history.push("/register");
              return false;
            }
            break;
          }

      }
    } else if (this.user.currentUserRole()) {
      require(`../../${this.user.currentUserRole()}.scss`);
      require(`../../styles/${this.user.currentUserRole()}.theme.scss`);
    }
    return true;
  }

  render() {
    if (this.state.userName === null || this.state.isLoading ) {

      return <FullSpinner />;
    }
    
    return (
      <AppProvider>
        <AppState.Consumer>

          {({ state: { currentUser, currentUserRole } }) => (
            <div className={currentUserRole}>
              <React.Fragment>
                <PrivateRoute
                  path="/"
                  userName={this.state.userName}
                  currentUser={currentUser}
                />
              </React.Fragment>
              <ModalContainer />
              <FullSpinner isGlobalSpinner={true} />
            </div>
          )}
        </AppState.Consumer>
      </AppProvider>
    );
  }
}

export default withRouter(App);

const PrivateRoute = ({ userName, currentUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        (Object.keys(currentUser).length && props.location.pathname != "/qb-signin-response" && props.location.pathname != "/d365bc-signin-response") ? (
          <React.Fragment>
            <Main {...props} />
          </React.Fragment>
        ) : (
          <Auth {...props} />
        )
      }
    />
  );
}
