import React, { Component } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { API_ENDPOINT_SUPPLIER } from "../../../../services/API";
import User from "../../../../services/User";
import CommonConfirmationModal, { AddModal } from "../../../common/Modals/CommonConfirmationModal";
import API from "../../../../services/API";
import { ShortText, PaymentTerm, netTerms, localDateFormat, netTermsMapper, parseNetTermToDays } from '../../../../services/Utilities';
import FullSpinner from "../../../common/Spinner/FullSpinner";
import MaskedInput from "react-text-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import FileViewerModal from "../../../common/FileViewerModal/FileViewerModal";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import DatePicker from 'react-date-picker';
import moment from "moment";
import {compareRole,enumRole} from "../../../../configs/GlobalConfig";
import PaymentTermsInput from "../../../../services/InputTerms";
import { convertUTF8String } from "../../../../services/ConversionService";

import CommonPopUpModal from "../../../CommonPopUpModal/CommonPopUpModal";
import FileUploader from "../../../common/FileUploader/FileUploader";
import fileUploadConfig from "../../../../configs/FileUploader";


const { 
    supportedFileTypes, 
    placeholders,
    duplicateFileMessage
} = fileUploadConfig;

const getFileConfig = (type) => {
    const fileTypeConfig = supportedFileTypes[type] || {};
    return {
        supportedExt: fileTypeConfig.extensions || [],
        errorMsgType: fileTypeConfig.message || '',
        placeholder: placeholders[type] || '',
        errorMsgDuplicate: duplicateFileMessage || ''
    };
};

let scResponse = [];

const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
})

const numberMask = createNumberMask({
    prefix: "$",
    suffix: "",
    allowDecimal: true
});

export default class UploadSupplyChainModal extends React.Component {
    user = new User();
    api = new API();
    constructor(props_) {
        super(props_);
        this.state = {
            scFile: props_.data && props_.data.documentFiles && props_.data.documentFiles.length>0 ?props_.data.documentFiles: null,
            supportingSCDocs: props_.data && props_.data.supplyChain && props_.data.supplyChain.additionalDocs ? props_.data.supplyChain.additionalDocs : [],
            isFileViewerModal: false,
            isFinancialDocument: false,
            fileViewerContent: null,
            data: "",
            isValidEmail: true,
            isValidSupplierName: true,
            isSubmitEnabled: false,
            isValidInvoiceNumber: true,
            isValidInvoiceAmount: true,
            isValidInvoiceDate: true,
            isValidBuyerName:true,
            isValidTerms:true,
            isPdfParsing:false,
            allSupplierUsers:[]
        };
        this.state.invoiceConfig = getFileConfig('invoice');
        this.state.poConfig = getFileConfig('po');
        this.state.addDocConfig = getFileConfig('additionalDocx');
    }
        
    componentDidMount() {
        scResponse=this.props && this.props.supplyChainList?this.props.supplyChainList:[];
        if(!this.props.isReadOnly){
            this.getProfile();
            this.getAllSupplierUsers();
        }

    }

    getAllSupplierUsers=async()=>{
        let _allSuppliers=await this.api.getAllFunder({ "role": "Supplier" });
        _allSuppliers=_allSuppliers.map(s_=>{
            return {
                name:s_.name,
                email:s_.email
            }
        })
        this.setState({
            allSupplierUsers:_allSuppliers
        })
    }



    render() {
        const {data}=this.state;
        const { invoiceConfig, poConfig, addDocConfig  } = this.state;
        return (
            <>
                {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                    onClose={() => {
                        this.setState({ isFileViewerModal: false, fileViewerContent: null });
                    }}
                    data={this.state.fileViewerContent}
                    isFinancialDocument={false}
                ></FileViewerModal>}
                <FullSpinner
                    isSpinning={this.state.isErpLastSync}
                />
                <FullSpinner isSpinning={this.state.isPdfParsing} />   
                <CommonPopUpModal
                    onClose={this.handleModalClose}
                    title={this.props.t("transactions_page.supply_chain.upload_title")}
                    primaryBtn={this.props.t("button_names.upload_supply_chain")}
                    isSubmitBtn={!this.state.isSubmitEnabled}
                    promptOnClose={this.state.data ? true : false}
                    size="large"
                    style={{"zIndex":"99999"}}
                    onSubmit={() => {
                        (!this.props.isReadOnly)
                        {
                            let formData = new FormData();
                            const { allSupplierUsers } = this.state;
                            let _supplierCompanyName = this.state.data[0].SupplierCompanyName;
                            let _supplierEmail = this.state.data[0].supplierEmail;
                            let _isEmailAlreadyUsed = false;
                            allSupplierUsers.forEach(s_ => {
                                if (_supplierEmail.toLowerCase() == s_.email.toLowerCase()) {
                                    if (_supplierCompanyName.toLowerCase() != s_.name.toLowerCase()) {
                                        _isEmailAlreadyUsed = true;

                                    }
                                }
                            })
                            if (_isEmailAlreadyUsed) {
                                return AddModal(this.props.t("email_already_used"), this.props.t("transactions_page.supply_chain.invalid_email"), null, false);
                            }
                            for (let sc_ of this.state.data) {
                                formData.append("data[]", JSON.stringify(sc_));
                            }
                            for (let file of this.state.scFile) {
                                formData.append("supply_chain_doc", file, file.name);
                            }
                            for (let file of this.state.supportingSCDocs) {
                                formData.append("supporting_doc", file, file.name);
                            }
                            this.props.onSubmit(formData);
                        }
                    }
                    }
                    children={
                        <>
                            <div className="row justify-content-center">
                                <div className='col-md'>
                                    <FileUploader
                                        onFileExtracted={this.handleInvoiceLoaded}
                                        onLoadingError={this.handleLoadingError}
                                        onView={this.handleView}
                                        convertToJson="sc"
                                        fileToExtract={this.state.scFile}
                                        supportedExt={invoiceConfig.supportedExt}
                                        //errorMsgType={this.props.t("file_upload_msg.only_pdf_file_msg")}
                                        placeholder={this.props.t("file_upload_msg.invoice_placeholder")}
                                        isdisabled={this.props.isReadOnly}
                                        isMultiple={false}
                                        isExtraction={true}
                                    />
                                </div>
                                <div className='col-md'>
                                    <FileUploader
                                        documents={this.state.supportingSCDocs}
                                        onDocUpload={(newDocs) => this.handleAddDocUpload(newDocs, 'supportingSCDocs')}
                                        onDelete={(filteredDocs)=>this.setState({supportingSCDocs: filteredDocs})}
                                        onView={this.handleView}
                                        namelength={50}
                                        supportedExt={addDocConfig.supportedExt}
                                        //errorMsgType={addDocConfig.errorMsgType}
                                        errorMsgDuplicate={addDocConfig.errorMsgDuplicate}
                                        placeholder={this.props.t("file_upload_msg.additionalDocx_placeholder")}
                                        isdisabled={this.props.isReadOnly}
                                        isMultiple={true}
                                        isExtraction={false}
                                    />
                                </div>
                            </div>
                            {((this.state.data && this.state.data.length == 1) || (this.props.isReadOnly)) && (
                                <>
                                    <div className="d-flex mt-4">
                                        <div className="form-group col-3">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("invoice_labels.invoice_number")}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Invoice#"
                                                value={this.props.isReadOnly ? this.props.data.documentId : data[0]["Invoice#"] ? data[0]["Invoice#"] : ""}
                                                onChange={this.handleInputChange}
                                                disabled={this.props.isReadOnly}
                                            />
                                            {!this.state.isValidInvoiceNumber && (
                                                <>
                                                    <div className="formErrors">
                                                        {data[0]["Invoice#"] && data[0]["Invoice#"].length > 25 ? this.props.t("validation_msg.invoice_number_exceed") :this.props.t("validation_msg.invoice_number_required")}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="form-group col-3">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("invoice_labels.invoice_date")}</label>
                                            <DatePicker
                                                className={["form-control"]}
                                                format="MM/dd/y"
                                                value={this.props.isReadOnly ? new Date(this.props.data.documentDate) : data.length > 0 && data[0].createdAt ? new Date(data[0].createdAt) : ""}
                                                onChange={(e) => {
                                                    const { data } = this.state;
                                                    if (data.length > 0) {
                                                        if (e) {
                                                            data[0].createdAt = localDateFormat(e);
                                                        }
                                                        else {
                                                            data[0].createdAt = null;
                                                        }
                                                        this.setState({ data }, () => {
                                                            if (compareRole(this.user.currentUserRole(), enumRole.Supplier)) {
                                                                this.validateSupplierAllFields();
                                                            }
                                                            else {
                                                                this.validateAllFields();
                                                            }

                                                        });
                                                    }
                                                }}
                                                disabled={this.props.isReadOnly}
                                            />
                                            {!this.state.isValidInvoiceDate && (
                                                <div className="formErrors">
                                                    Invoice Date is invalid
                                                </div>
                                            )}
                                        </div>
                                        <PaymentTermsInput
                                            data={data}
                                            isReadOnly={this.props.isReadOnly}
                                            value={this.props.isReadOnly ? this.props.data.supplyChain.net : ""}
                                            onDataChange={this.handleDataChange}
                                        />
                                        {compareRole(this.user.currentUserRole(), enumRole.Supplier) ? (
                                            <>
                                                <div className="form-group col-3">
                                                    <label className="form-label-style" htmlFor="name">{this.props.t("grid_view_header_labels.buyer_name")}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="buyerName"
                                                        value={this.props.isReadOnly ? this.props.data.buyerName : data[0].buyerName ? data[0].buyerName : ""}
                                                        onChange={this.handleInputChange}
                                                        disabled={this.props.isReadOnly}
                                                    />
                                                    {!this.state.isValidBuyerName && (
                                                        <div className="formErrors">
                                                            {data[0].buyerName && data[0].buyerName.length > 50 ? this.props.t("validation_msg.buyer_name_exceed") :  this.props.t("validation_msg.buyer_name_required")}

                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="form-group col-3">
                                                    <label className="form-label-style" htmlFor="name">{this.props.t("grid_view_header_labels.supplier_name")}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="SupplierCompanyName"
                                                        value={this.props.isReadOnly ? this.props.data.supplierName : data[0].SupplierCompanyName ? data[0].SupplierCompanyName : ""}
                                                        onChange={this.handleInputChange}
                                                        disabled={this.props.isReadOnly}
                                                    />
                                                    {!this.state.isValidSupplierName && (
                                                        <div className="formErrors">
                                                            {data[0].SupplierCompanyName && data[0].SupplierCompanyName.length > 50 ? this.props.t("validation_msg.supplier_name_exceed") : this.props.t("validation_msg.supplier_name_required")}
                                                        </div>
                                                    )}
                                                    {this.props.isReadOnly && this.props.data.isInviteSupplier && (
                                                        <div className="formErrors">
                                                            Supplier is not present in LedgerFunding
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}

                                    </div>
                                    <div className="d-flex mt-2">
                                        <div className="form-group col-3">
                                            <label className="form-label-style" htmlFor="name">{this.props.t("invoice_labels.invoice_amount")}</label>
                                            <MaskedInput
                                                name="total"
                                                value={this.props.isReadOnly ? this.props.data.total : data[0].total ? data[0].total : ""}
                                                mask={numberMask}
                                                className="form-control"
                                                placeholder="$ ..."
                                                onChange={this.handleInputChange}
                                                disabled={this.props.isReadOnly}
                                            />
                                            {!this.state.isValidInvoiceAmount && (
                                                <div className="formErrors">
                                                    {data[0].total && data[0].total > 1000000000 ? this.props.t("validation_msg.invoice_amount_exceed") : this.props.t("validation_msg.invoice_amount_required")}
                                                </div>
                                            )}
                                        </div>
                                        {compareRole(this.user.currentUserRole(), enumRole.Supplier) ? (
                                            <>
                                                <div className="form-group col-3">
                                                    <label className="form-label-style" htmlFor="name">{this.props.t("grid_view_header_labels.buyer_email")}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        value={this.props.isReadOnly ? this.props.data.supplyChain.customerDetails.email : data[0].customerDetails && data[0].customerDetails.email ? data[0].customerDetails.email : ""}
                                                        disabled={this.props.isReadOnly}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {!this.state.isValidEmail && (
                                                        <div className="formErrors">
                                                            {data[0].customerDetails && data[0].customerDetails.email && data[0].customerDetails.email.length > 0 ? this.props.t("validation_msg.invalid_buyer_email") :this.props.t("validation_msg.required_buyer_email")}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="form-group col-3">
                                                    <label className="form-label-style" htmlFor="name">{this.props.t("grid_view_header_labels.supplier_email")}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="supplierEmail"
                                                        value={this.props.isReadOnly ? this.props.data.supplyChain.supperDetails.supplierEmail : data[0].supplierEmail ? data[0].supplierEmail : ""}
                                                        disabled={this.props.isReadOnly}
                                                        onChange={this.handleInputChange}
                                                    />
                                                    {!this.state.isValidEmail && (
                                                        <div className="formErrors">
                                                            {data[0].supplierEmail && data[0].supplierEmail.length > 0 ? this.props.t("validation_msg.invalid_supplier_email") : this.props.t("validation_msg.required_supplier_email")}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        {this.props.isReadOnly && compareRole(this.user.currentUserRole(), enumRole.Buyer) && this.props.data.isInviteSupplier && (
                                            <div className="d-flex flex-column justify-content-center">
                                                <button type="button" className="btn btn-primary btn-lg mr-3 mt-3"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        this.props.onSupplierInvite();
                                                    }}>{this.props.t("transactions_page.supply_chain.invite_supplier")}</button>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    }
                ></CommonPopUpModal>
            </>
        )
    }


    async getProfile() {
        const profileResponse = await this.api.getV2Profile();
        this.setState({ user: profileResponse.user });
    }

    handleInputChange = (e) => {
        e.preventDefault();
        const { data } = this.state;

        if (compareRole(this.user.currentUserRole(), enumRole.Supplier)) {
            if(e.target.name=="email"){
                data[0].customerDetails[e.target.name] = e.target.value;
            }
            else{
                data[0][e.target.name] = e.target.value;
                data[0].customerDetails["name"]=e.target.name=="buyerName"?e.target.value:data[0].customerDetails["name"];
            }
            this.setState({ data }, () => {
                this.validateSupplierAllFields();
            });
        }
        else{
            data[0][e.target.name] = (
                e.target.name == "Invoice#" ||
                e.target.name == "SupplierCompanyName") ? e.target.value : e.target.value;
            this.setState({ data }, () => {
                this.validateAllFields();
            });
        }
    }

    handleDataChange = (inputValue) => {
        const { data } = this.state;
        data[0].Terms = inputValue

        this.setState(({
            data: data,
        }), () => this.validateSupplierAllFields(), this.validateAllFields());
    }

    validateAllFields = () => {
        const { data } = this.state;
        if (data.length > 0) {
            let scDetails_ = data[0];
            scDetails_.total =scDetails_.total? Number(
                scDetails_.total.toString().replace(/[^0-9.-]+/g, "")
            ):0;
            let _invoiceNumber=scDetails_["Invoice#"] ? scDetails_["Invoice#"].toString().trim():"";
            let _supplierCompanyName=scDetails_.SupplierCompanyName?scDetails_.SupplierCompanyName:"";
            let isValidEmail_ = (scDetails_.supplierEmail ? scDetails_.supplierEmail : "").match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            const isValidInvoiceDate_ = moment(scDetails_.createdAt).isValid();
            const isValidInvoiceNumber = _invoiceNumber.length > 0 && _invoiceNumber.length < 25;
            const isValidSupplierName = _supplierCompanyName.length > 0 && _supplierCompanyName.length < 50;
            const isValidInvoiceAmount = scDetails_.total > 0 && scDetails_.total < 1000000000;
            const isValidNetTerms = data && data[0].Terms >= 1 && data[0].Terms <= 365;
            this.setState({
                isSubmitEnabled: isValidInvoiceNumber && isValidInvoiceAmount && isValidInvoiceDate_ && isValidSupplierName && isValidEmail_ && isValidNetTerms,
                isValidEmail: isValidEmail_ ? true : false,
                isValidSupplierName: isValidSupplierName,
                isValidInvoiceNumber: isValidInvoiceNumber,
                isValidInvoiceAmount: isValidInvoiceAmount,
                isValidInvoiceDate: isValidInvoiceDate_,
                isValidTerms: isValidNetTerms
            })
        }
    }

    validateSupplierAllFields = () => {
        const { data } = this.state;
        if (data.length > 0) {
            let scDetails_ = data[0];
            scDetails_.total =scDetails_.total? Number(
                scDetails_.total.toString().replace(/[^0-9.-]+/g, "")
            ):0;
            let _invoiceNumber=scDetails_["Invoice#"]?scDetails_["Invoice#"].toString().trim():"";
            let _buyerCompanyName=scDetails_.customerDetails && scDetails_.customerDetails.name? scDetails_.customerDetails.name.trim():"";
            let isValidEmail_ = (scDetails_.customerDetails.email ? scDetails_.customerDetails.email : "").match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            const isValidInvoiceDate_ = moment(scDetails_.createdAt).isValid();
            const isValidInvoiceNumber = _invoiceNumber.length > 0 && _invoiceNumber.length < 25;
            const isValidBuyerName = _buyerCompanyName.length > 0 && _buyerCompanyName.length < 50;
            const isValidInvoiceAmount = scDetails_.total > 0 && scDetails_.total < 1000000000;
            const isValidNetTerms = data && data[0].Terms >= 1 && data[0].Terms <= 365;
            this.setState({
                isSubmitEnabled: isValidInvoiceNumber && isValidInvoiceAmount && isValidInvoiceDate_ && isValidBuyerName && isValidEmail_ && isValidNetTerms,
                isValidEmail: isValidEmail_ ? true : false,
                isValidBuyerName: isValidBuyerName,
                isValidInvoiceNumber: isValidInvoiceNumber,
                isValidInvoiceAmount: isValidInvoiceAmount,
                isValidInvoiceDate: isValidInvoiceDate_,
                isValidTerms: isValidNetTerms
            })
        }
    }

    handleSupportingDocumentUpload = async(data) => {
        let _temporaryFiles=[];
        const {supportingSCDocs}= this.state;
        let existingDocuments = [];
        data.forEach(element => {
            element.id = Math.random().toString(36).slice(2);
        });
        for (let exisitingDoc_ of supportingSCDocs) {
            for (let file of data) {
                if (file.name == exisitingDoc_.name) {
                    AddModal(this.props.t("following_document_already_imported"));
                    return
                }
            }
        }
        try{
            const formData=new FormData();
            for (let file of data) {
                formData.append("TemporaryFiles", file, file.name);
            }

            _temporaryFiles= await this.api.saveTemporaryFileInServer(formData);
        }
        catch(ex){
            console.log(ex);
        }
        try{
            data.forEach((file_,i)=>{
                file_.preview=`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${_temporaryFiles[0]._id}`;
              })
        }
        catch(ex){
            console.log(ex);
        }
        existingDocuments = supportingSCDocs;
        existingDocuments = existingDocuments.concat(data);
        this.setState({ supportingSCDocs: existingDocuments })
    }

    getSupportingSCDocuments = (data) => {
        return data && data.map(d => {
            return <div key={this.props.isReadOnly?d.filename:d.name}>
                <a href="" onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.setState({
                        isFileViewerModal: true,
                        isFinancialDocument: false,
                        fileViewerContent: d
                    })
                }}>{this.props.isReadOnly?convertUTF8String(d.filename):d.name}</a>
                {!this.props.isReadOnly && (<>
                    <span style={{ float: "right", marginRight: "25px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            const filteredDocs_ = data.filter(doc => doc.id != d.id);
                            this.setState({ supportingSCDocs: filteredDocs_ });
                        }}>
                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </span>
                </>)}
            </div>
        })
    }

    handleInvoiceLoaded = (documentData) => {
        this.setState({
          data: documentData.data,
          scFile: documentData.scFile,
          uploadFileName:documentData.uploadFileName,
          fileToUpload:documentData.fileToUpload,
          isPdfParsing: documentData.isPdfParsing,
        }, () => {
            if (documentData.data !== "") {
              this.validateAllFields();
              this.validateSupplierAllFields();
            }
          });
      };

    handleLoadingError = (errorData) => {
        console.log(errorData)
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    handleModalClose = () => {
        this.props.onClose();
    }

    handleAddDocUpload = (newDocs, stateToUpdate) => {
        this.setState(prevState => ({
            [stateToUpdate]: newDocs,
        }));
    };

}