export default class EventService {
    static _subscribers = {};

    static emit = (event_, payload_) => {
        if (EventService._subscribers[event_]) {
            for (let handler of EventService._subscribers[event_]) {
                handler(payload_);
            }
        }
    }

    static subscribe = (event_, handler_) => {
        if (!EventService._subscribers[event_]) {
            EventService._subscribers[event_] = [];
        }

        EventService._subscribers[event_] = [handler_];
    }
}

window.addEventListener("message", (event) => {
    if (event.data.source == "qb-window") {
        EventService.emit("message", event.data.payload);
    }
    if(event.data.source=="business-central-window"){
        EventService.emit("message", event.data.payload);
    }
}, false);