import { useTranslation } from 'react-i18next';

const FundingOfferDetails = ({fundingType_, offerDetails }) => {
    const { t, i18n } = useTranslation();
    return (
        <>
            {fundingType_.indexOf("TCF") == 0 && (

                <div className="col-md-3">
                    <label>{t("funding_offer_details.factoring_rate")}</label>
                    <strong className="form-value link-text-color">
                        {offerDetails.factoringrate}%
                    </strong>
                </div>

            )}
            {fundingType_.indexOf("ABL") == 0 && (
                <>
                    <div className="col-md-4">
                        <label>{t("funding_offer_details.abl_advance_rate")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.advanceRateOnEligibleAR}%
                        </strong>
                    </div>
                    <div className="col-md-4">
                        <label>{t("funding_offer_details.abl_eligible_inventory")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.advanceRateOnEligibleInventory}%
                        </strong>
                    </div>
                    {offerDetails.lineOfCredit && offerDetails.lineOfCredit.length > 0 && (
                        <div className="col-md-4">
                            <label>{t("funding_offer_details.line_of_credit")}</label>
                            <strong className="form-value link-text-color">
                                {offerDetails.lineOfCredit}
                            </strong>
                        </div>
                    )}
                    {offerDetails.loan && offerDetails.loan.length > 0 && (
                        <div className="col-md-4">
                            <label>{t("funding_offer_details.loan")}</label>
                            <strong className="form-value link-text-color">
                                {offerDetails.loan}
                            </strong>
                        </div>
                    )}

                    <div className="col-md-12">
                        <label>{t("funding_offer_details.total_financing_fees")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.totalFinancingFee}
                        </strong>
                    </div>
                </>
            )}
            {fundingType_.indexOf("SupplyChain") == 0 && (
                <>
                    <div className="col-md-12">
                        <label>{t("funding_offer_details.other_note")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.offernote}
                        </strong>
                    </div>
                </>
            )}
            {fundingType_.indexOf("TCF") != 0 && fundingType_.indexOf("ABL") != 0 && fundingType_.indexOf("SupplyChain") != 0 && (
                <>
                    <div className="col-md-3">
                        <label>{t("funding_offer_details.advance_rate")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.advancerate}%
                        </strong>
                    </div>
                    <div className="col-md-3">
                        <label>{t("funding_offer_details.factoring_rate")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.factoringrate}%
                        </strong>
                    </div>
                    <div className="col-md-3">
                        <label>{t("funding_offer_details.repayment_days")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.lengthoftimeforpayment + " days"}
                        </strong>
                    </div>
                    <div className="col-md-3">
                        <label>{t("funding_offer_details.recourse")}</label>
                        <strong className="form-value link-text-color">
                            {((offerDetails.isRecourse).toString() == "false") ? "No" : "Yes"}
                        </strong>
                    </div>
                    <div className="col-md-12">
                        <label>{t("funding_offer_details.other_fees")}</label>
                        <strong className="form-value link-text-color">
                            {offerDetails.offernote}
                        </strong>
                    </div>
                </>
            )}
        </>
    )
}

export default FundingOfferDetails;

