import React, { Component } from 'react';

import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import FullSpinner from '../../common/Spinner/FullSpinner';
import Button from '../../common/Buttons/Button';

import API from '../../../services/API';
import { getIndustry, removeDuplicateObjects, getNewIndustryList } from '../../../services/Utilities';
import User from '../../../services/User';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Prompt
} from "react-router-dom";
import 'react-checkbox-tree-enhanced/src/scss/react-checkbox-tree.scss';
import CheckboxTree from 'react-checkbox-tree-enhanced';
import { CONTINENTWITHCOUNTRY } from '../../../configs/ContinentWithCountries';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withTranslation } from 'react-i18next';

const numberMask = createNumberMask({
  prefix: '$',
  suffix: '',
});

const numberPercentage = createNumberMask({
  prefix: '',
  suffix: '%',
});


class FundingSettings extends Component {
  user = new User();
  api = new API();
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      user: {},
      allIndustries: [],
      notSelectedIndustries: [],
      selectedIndustries: [],
      industrySelectedCount: 0,
      isSaveFundingCriteria: false,
      treeCheckedList: [],
      expanded: [],
      treeViewNodeList: []
    };
    this.updateFundingAmount = this.updateFundingAmount.bind(this);
    this.updateMinAnnualRevenue = this.updateMinAnnualRevenue.bind(this);
    this.updateMaxDebtorConcentration = this.updateMaxDebtorConcentration.bind(this);
    this.handlePaymentTermsChanged = this.handlePaymentTermsChanged.bind(this);
    this.generateIndustryList = this.generateIndustryList.bind(this);
  }

  componentDidMount() {
    document.title = `Funding Settings - LedgerFunding ${this.user.currentUserRole()}`;
    this.getProfile();
  }

  async getProfile() {
    const profileResponse = await this.api.getProfile();
    //this.generateIndustryList();
    //this.setState({ user: profileResponse.user }, () => this.getSelectedIndustries());
    this.setState({ user: profileResponse.user }, () => {
      this.generateIndustryList();
      this.generateContinentWithCountryList();
    });
  }

  generateContinentWithCountryList = () => {
    let { user: { filters } } = this.state;
    let checkedList_ = [];
    let treeViewNodeList_ = [];
    treeViewNodeList_ = CONTINENTWITHCOUNTRY.map(c_ => {
      let childrenList = c_.countries.map(cou_ => {
        checkedList_.push(cou_.toLowerCase());
        return { value: cou_.toLowerCase(), label: cou_ };
      });
      return { value: c_.continentName.toLowerCase(), label: c_.continentName, children: childrenList };
    });
    if (filters.continentWithCountries) {
      checkedList_ = [];
      filters.continentWithCountries.forEach(c_ => {
        c_.countries.forEach(cou_ => {
          checkedList_.push(cou_.toLowerCase());
        });
      });
    }
    this.setState({ treeViewNodeList: treeViewNodeList_, treeCheckedList: checkedList_ });
  };

  async updateFundingAmount() {
    this.setState({ isLoading: true });
    const { user: { filters } } = this.state;
    await this.api.changeFundingAmounts({ amounts: [filters.amounts[0], filters.amounts[1]] });
    this.setState({ isLoading: false });
  }

  async updateMinAnnualRevenue() {
    this.setState({ isLoading: true });
    const { user: { filters } } = this.state;
    await this.api.updateMinimumAnnualRevenue({ minimumAnnualRevenue: filters.minimumAnnualRevenue });
    this.setState({ isLoading: false });
  }

  async updateMaxDebtorConcentration() {
    this.setState({ isLoading: true });
    const { user: { filters } } = this.state;
    await this.api.updateMaxDebtorConcentration({ maximumDebtorConcentration: filters.maximumDebtorConcentration });
    this.setState({ isLoading: false });
  }

  handleAmountChangeField(fieldName, amount) {
    const { user } = this.state;
    if (fieldName === 'min') {
      user.filters.amounts[0] = amount;
    } else {
      user.filters.amounts[1] = amount;
    }
    this.setState({ user: user, isSaveFundingCriteria: true });
  }

  handleRevenueChangeField(fieldName, amount) {
    const { user } = this.state;
    if (fieldName === 'min') {
      user.filters.minimumAnnualRevenue = amount;
    } else {
      user.filters.maximumDebtorConcentration = amount;
    }
    this.setState({ user: user, isSaveFundingCriteria: true });
  }

  async handlePaymentTermsChanged(event) {
    const { user } = this.state;
    user.filters.payTerms = event.target.value;
    this.setState({ user: user, isSaveFundingCriteria: true });
  }

  handleTransactionChange = async (event) => {
    const { target } = event;
    const value = target.checked;
    const { user } = this.state;
    if (user.filters && !user.filters.transactions) {
      user.filters["transactions"] = {
        invoice: true,
        po: true,
        abl: true,
        supplychian:true
      };
    }
    if (target.name == "invoice") {
      user.filters.transactions.invoice = value;
    }
    else if (target.name == "po") {
      user.filters.transactions.po = value;
    }
    else if (target.name == "supplychain") {
      user.filters.transactions.supplychain = value;
    }
    else {
      user.filters.transactions.abl = value;
    }
    this.setState({ user: user, isSaveFundingCriteria: true });
  };

  generateIndustryList() {
    const { user: { filters } } = this.state;
    let allIndustries = [];
    // // const selectedIndustries = [];
    let _industrySelectedCount = 0;
    allIndustries = getNewIndustryList;
    if (filters.industries && filters.industries.length != 147) {
      allIndustries.forEach((allIndus_, index) => {
        filters.industries.forEach((status_, i) => {
          if (index == i) {
            _industrySelectedCount = allIndus_.status == status_ ? _industrySelectedCount++ : _industrySelectedCount;
            allIndus_.status = status_;
          }
        });
      });
    } else {
      _industrySelectedCount = allIndustries.length;
    }
    this.setState({ allIndustries: allIndustries, industrySelectedCount: _industrySelectedCount });
  }

  industryChange = (e, i) => {
    let { user: { filters }, allIndustries } = this.state;
    let _industrySelectedCount = this.state.industrySelectedCount;
    allIndustries[i].status = e.target.checked;
    _industrySelectedCount = e.target.checked ? _industrySelectedCount++ : _industrySelectedCount--;
    this.setState({ allIndustries: allIndustries, industrySelectedCount: _industrySelectedCount, isSaveFundingCriteria: true });
  };

  selectAllIndustry = (e) => {
    let { user: { filters }, allIndustries } = this.state;
    let isSelectAll = e.target.checked;
    allIndustries.forEach((val) => {
      val.status = isSelectAll;
    });
    const _industrySelectedCount = isSelectAll ? allIndustries.length : 0;
    this.setState({
      allIndustries: allIndustries,
      industrySelectedCount: _industrySelectedCount,
      isSaveFundingCriteria: true
    });
  };

  updateFundingCriteria = async () => {
    // e.preventDefault();
    const { user: { filters }, allIndustries } = this.state;
    this.setState({ isLoading: true });
    filters.industries = allIndustries.map(val => val.status);
    let selectedContinent = [
      {
        "continentName": "Africa",
        "countries": []
      },
      {
        "continentName": "Asia Pacific",
        "countries": []
      },
      {
        "continentName": "Europe",
        "countries": []
      },
      {
        "continentName": "Middle East",
        "countries": []
      },
      {
        "continentName": "North America",
        "countries": []
      },
      {
        "continentName": "Latin America",
        "countries": []
      },
    ];
    CONTINENTWITHCOUNTRY.forEach(details_ => {
      this.state.treeCheckedList.forEach(tn_ => {
        details_.countries.forEach(element => {
          if (element.toLowerCase() == tn_) {
            selectedContinent.forEach(selectedContinent_ => {
              if (selectedContinent_.continentName == details_.continentName) {
                selectedContinent_.countries.push(element);
              }
            });
          }
        });
        // if(details_.countries.filter(co_=>co_.toLowerCase()==tn_).length>0){
        //   if(selectedContinent.filter(s_=>s_.continentName==details_.continentName).length>0){

        //   }
        //   else{
        //     let continentDetails_={};
        //     continentDetails_.continentName=details_.continentName;
        //     continentDetails_.countries=[];
        //     continentDetails_.countries.push()
        //   }
        // }
      });
    });
    filters.continentWithCountries = selectedContinent;
    await this.api.UpdateAllFundingCriteria(filters);
    this.setState(
      {
        isSaveFundingCriteria: false,
        isLoading: false
      }
    );
  };



  render() {
    const { isLoading, user, user: { filters }, allIndustries, notSelectedIndustries, selectedIndustries } = this.state;
    const paymentTerms = [
      { label: "Up to 30 days", value: 30 },
      { label: "Up to 60 days", value: 60 },
      { label: "Up to 90 days", value: 90 },
      { label: "Up to 120 days", value: 120 },
      { label: "Up to 180 days", value: 180 },
      { label: "Beyond 180 days", value: 999 },
    ];
    return (
      <React.Fragment>
        <FullSpinner isSpinning={isLoading} confirmationLabel={this.props.t("funder_settings.funding_criteria_updated")} />
        <Prompt
          when={this.state.isSaveFundingCriteria}
          message={this.props.t("funder_settings.funding_criteria_unsaved_msg")}
        />
        <div className="header-titlebar" style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>
          {this.props.t("page_titles.fundingCriteria")}
          </h3>
          <div className='text-right'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={!this.state.isSaveFundingCriteria}
              onClick={this.updateFundingCriteria}>
              {this.props.t("funder_settings.save_funding_criteria")}
            </button>
          </div>
        </div>
        <div className='content-container funder-settings-list'>
          <React.Fragment>
            {Object.getOwnPropertyNames(user).length !== 0 && (
              <React.Fragment>
                <div className='row mt-2'>
                  <div className="col-lg-4">
                    <div className='d-flex flex-column'>
                      <div className="bg-white card p-3">
                        <h5 className='mb-3'>
                        {this.props.t("page_titles.transactions")}
                        </h5>
                        <label>
                          <input
                            name={'invoice'}
                            type="checkbox"
                            checked={filters.transactions ? filters.transactions.invoice : false}
                            onChange={this.handleTransactionChange}
                          />
                          <span className="ml-2">{this.props.t("fundingType.invoice")}</span>
                        </label>
                        <label>
                          <input
                            name={'po'}
                            type="checkbox"
                            checked={filters.transactions ? filters.transactions.po : false}
                            onChange={this.handleTransactionChange}
                          />
                          <span className="ml-2">{this.props.t("fundingType.po")}</span>
                        </label>
                        <label className='pb-1'>
                          <input
                            name={'supplychain'}
                            type="checkbox"
                            checked={filters.transactions ? filters.transactions.supplychain : false}
                            onChange={this.handleTransactionChange}
                          />
                          <span className="ml-2">{this.props.t("fundingType.supplychain")}</span>
                        </label>
                        {/* <label>
                          <input
                            name={'abl'}
                            type="checkbox"
                            checked={filters.transactions ? filters.transactions.abl : false}
                            onChange={this.handleTransactionChange}
                          />
                          <span className="ml-2">Asset-Based Lending</span>
                        </label> */}
                      </div>
                      <div className="bg-white card p-3 mt-3">
                        <h5 className='mb-2'>
                          {this.props.t("funder_settings.minimum_annual_revenue")}
                        </h5>
                        <MaskedInput
                          value={filters.minimumAnnualRevenue}
                          name="min"
                          id="min"
                          onChange={e => this.handleRevenueChangeField('min', Number(e.target.value.replace(/[^0-9.-]+/g, '')))}
                          mask={numberMask}
                          className="form-control mb-2 mt-2"
                        />
                      </div>
                      <div className="bg-white card p-3 mt-3">
                        <h5 className='mb-2'>
                          {this.props.t("funder_settings.lending_ranges")}
                        </h5>
                        <div className="row">
                          <div className="col-6">
                            <MaskedInput
                              value={filters.amounts[0]}
                              name="min"
                              id="min"
                              onChange={e => this.handleAmountChangeField('min', Number(e.target.value.replace(/[^0-9.-]+/g, '')))}
                              mask={numberMask}
                              className="form-control mb-2"
                              placeholder="Minimum"
                            />

                          </div>
                          <div className="col-6">
                            <MaskedInput
                              value={filters.amounts[1]}
                              name="max"
                              id="max"
                              onChange={e => this.handleAmountChangeField('max', Number(e.target.value.replace(/[^0-9.-]+/g, '')))}
                              mask={numberMask}
                              className="form-control mb-3"
                              placeholder="Maximum"
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className='d-flex flex-column'>
                      <div className="bg-white card p-3">
                        <h5 className='mb-3'>
                        {this.props.t("funder_settings.invoice_terms")}
                        </h5>
                        <div className='d-flex'>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={user.filters.payTerms}
                            fullWidth
                            onChange={this.handlePaymentTermsChanged}
                          >
                            {paymentTerms.map(p_=>{
                              return <MenuItem value={p_.value}>{p_.label}</MenuItem>
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="bg-white card p-3 mt-3 mb-3 geography-card">
                        <h5 className='mb-2'>
                          {this.props.t("funder_settings.geographies_interests")}
                        </h5>
                        <CheckboxTree
                          nodes={this.state.treeViewNodeList}
                          checked={this.state.treeCheckedList}
                          expanded={this.state.expanded}
                          onCheck={(checked, tn) => {
                            this.setState({ treeCheckedList: checked, isSaveFundingCriteria: true });
                          }}
                          onExpand={expanded => this.setState({ expanded })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 fundingCriteria-industries">
                    {allIndustries.length > 0 && (
                      <div className="bg-white card p-3">
                        <h5>
                        {this.props.t("funder_settings.industry_interests")}
                        </h5>
                        <div className="row">
                          <div className="col-12 pl-4" >
                            {/* <FormControlLabel
                          label={<Typography className={"select-all-checkbox"}>Select all</Typography>}
                          control={
                            <Checkbox
                              checked={allIndustries.length==this.state.industrySelectedCount}
                              indeterminate={isIntermidiate_}
                              onChange={(e)=>this.selectAllIndustry(e)}
                              color="success"
                            />}
                        /> */}
                            <React.Fragment>
                              {allIndustries.map((value, i) => {
                                return <div>
                                  <FormControlLabel
                                    label={value.industryName}
                                    control={
                                      <input type='checkbox' className='mr-2'
                                        checked={value.status}
                                        onChange={e => this.industryChange(e, i)}
                                      />}
                                  />
                                </div>;
                              })
                              }
                            </React.Fragment>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation() (FundingSettings);