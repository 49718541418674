import React, { Component } from "react";

import List from "../List/List";
import NotificationListItem from "../List/ListType/NotificationListItem";
import AppState from "../../../contexts/AppState/AppState";
import API from "../../../services/API";
import User from "../../../services/User";
import { withTranslation } from "react-i18next";

class Notifications extends Component {
  user = new User();

  constructor(props) {
    
    super(props);
    this.state={
      currentUserRole:null,
      notifications:[]
  };
    this.setReadState = this.setReadState.bind(this);
    this.api = new API();
  }
  state = {
    currentUserRole: null
  };
  componentDidMount() {
    document.title = `Notifications - LedgerFunding ${this.user.currentUserRole()}`;
    this.getNotifications().then(d=>{
        this.api.putReadAllUnread();
    });
    this.setState({ currentUserRole: this.user.currentUserRole() });
  }

  setReadState(setState) {

    setState("isNotificationsNotRead", true);
  }

  getNotifications= async ()=> {
    if (this.user.currentUserRole() !== "") {
      try {
        const notificationsResponse = await this.api.getNotifications();
     
        this.setState({
          notifications: notificationsResponse.notifications || []
        });
      }
      catch(ex_){}
    } else {
      return [];
    }
  }

  render() {
    return (
      <AppState.Consumer>
        {appState => (
          <React.Fragment>
            <div className="header-titlebar">
              <h3>{this.props.t("page_titles.notifications")}</h3>
            </div>
            <div className="content-container">
              <div className="row">
                <div className="col">
                  <List
                    data={this.state.notifications}
                    isLoading={false}
                    emptyListLabel={this.props.t("notifications_page.empty_notifications_msg")}
                    itemType={NotificationListItem}
                    userType={this.state.currentUserRole}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </AppState.Consumer>
    );
  }
}

export default withTranslation() (Notifications);
