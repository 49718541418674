import React, { Component } from 'react';
import {
  withRouter,
} from 'react-router-dom';
import './Login.css';

import Stepper from 'react-stepper-horizontal';
import Spinner from '../Spinner/Spinner';
import API from "../../../services/API";
import FullSpinner from "../Spinner/FullSpinner";
import Logo from '../../../assets/icons/logo.png';
import { FormErrors, renderPasswordValidation } from "./FormErrors";
import AppState from '../../../contexts/AppState/AppState';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { passwordRegexString, validatePassword } from '../../../services/Utilities';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import i18next from 'i18next';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { supported_languages } from '../../../services/languages';
import { changeLanguage } from '../../../services/localizationService';
import TranslateIcon from '@mui/icons-material/Translate';

class Forgot extends Component {
  api = new API();
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      step: 0,
      email: '',
      verificationCode: '',
      isSentCode: false,
      formErrors: { password: "", confirmPassword: "" },
      passwordValid: false,
      confirmPasswordValid: false,
      isShowConfirmPassword:false,
      isShowPassword:false,
      passwordValidation: {
        lengthValid: false,
        capitalValid: false,
        numberValid: false,
        noSpaces: false
      },
    };
    this.handleChangeField = this.handleChangeField.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.handleShowPassword=this.handleShowPassword.bind(this);
    this.handleShowConfirmPassword=this.handleShowConfirmPassword.bind(this);
  }

  handleChangeField(event) {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name.toLowerCase().indexOf('password') > -1) {
      this.validateField(event.target.name, event.target.value);
    }
  }

  handleShowPassword(){
    this.setState({isShowPassword:!this.state.isShowPassword});
  }

  handleShowConfirmPassword(){
    this.setState({isShowConfirmPassword:!this.state.isShowConfirmPassword});
  }

  async forgotPassword(step) {
    const { email } = this.state;
    this.setState({ isSentCode: true });
    const response = await this.api.forgotPassword(email);
    this.setState({ step: step + 1 });
    this.setState({ token: response.token });
    this.setState({ isSentCode: false });
  }

  async resetPassword(step) {
    const { token, verificationCode, password } = this.state;
   // this.setState({ isLoading: true });
    this.api.resetPassword({
      "token": token,
      "code": verificationCode,
      "password": password,
      "confirmPassword": password
    }).then(res => {
      this.setState({ step: step + 1 });
     // this.setState({ isLoading: false });
      if (res.status === 200) {
        this.setState({ isDone: true });
      } else {
        this.setState({ isDone: false });
      }
    });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let passwordValid = this.state.passwordValid;
    let confirmPasswordValid = this.state.confirmPasswordValid;
    let _errorMsg="";
    let validationState = this.state.passwordValidation;
    switch (fieldName) {
      case "password":
        validationState = validatePassword(value)
        passwordValid = Object.values(validationState).every(Boolean)
        fieldValidationErrors.password = !passwordValid;

        if(this.state.confirmPassword && this.state.confirmPassword.length>0){
          confirmPasswordValid = this.state.confirmPassword === value;
          fieldValidationErrors.confirmPassword = confirmPasswordValid
            ? ""
            : "Your passwords do not match.";
        }
        break;
      case "confirmPassword":
        confirmPasswordValid = value === this.state.password;
        fieldValidationErrors.confirmPassword = confirmPasswordValid
          ? ""
          : "Your passwords do not match.";
          if(this.state.password && this.state.password.length>0){
            fieldValidationErrors.password = !passwordValid;
          }
        break;
    }
    this.setState(
      {
        passwordValid: passwordValid ? true : false,
        passwordValidation: validationState,
        confirmPasswordValid: confirmPasswordValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.passwordValid &&
        this.state.confirmPasswordValid
    });
  }

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  static renderLoadingView() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row d-flex justify-content-center align-items-center loading-wrapper">
              <Spinner t={i18next.t} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForgotPasswordView() {
    const { history } = this.props;
    const { email, step, isDone, isSentCode, formValid, passwordValidation, formErrors } = this.state;
    const isStepOneEnabled = (email.length > 0);
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <AppState.Consumer>
        {appState => (
          <div className="container-fluid login-wrapper">
            <FullSpinner
              isSpinning={isSentCode}
              confirmationLabel="Verification code sent to email id"
            />
            <div className="row">
              <div className="col-6">
                <div className="row d-flex flex-column justify-content-center align-items-center h-100">
                  <div style={{width:"57%"}}>
                      <div className="text-center mt-5">
                        <h3 className="whiteText" style={{fontSize:38,fontWeight:600}}>
                          {this.props.t("forgot_password_page.title")}
                        </h3>
                      </div>
                      {/* <FormErrors formErrors={this.state.formErrors} /> */}
                      <div className="num-step-container pt-2">
                      <div className={ "num-step-item"}>
                        <div className="num-step-item-title">01</div>
                        <div className="num-step-item-subtitle">{this.props.t("forgot_password_page.steps_name.email")}</div>
                      </div>
                      <div className={ step>=1?"num-step-item":"num-step-item link-text-color"}>
                        <div className="num-step-item-title">02</div>
                        <div className="num-step-item-subtitle">{this.props.t("forgot_password_page.steps_name.secret_code")}</div>
                      </div>
                    </div>
                      {/* <Stepper circleFontSize="6" completeColor="#002f53" activeColor="#002f53" defaultColor="#5d7890" steps={[{ title: 'Email' }, { title: 'Secret Code' }]} activeStep={step} /> */}
                      <form id="forgot-password" className="text-center p-4" onSubmit={e => e.preventDefault()}>
                        <EmailForm handleChange={this.handleChangeField} isHidden={step !== 0} t={this.props.t}/>
                        <SecretCodeForm
                          passwordValidation={passwordValidation}
                          formErrors={formErrors}
                          isShowConfirmPassword={this.state.isShowConfirmPassword}
                          isShowPassword={this.state.isShowPassword}
                          handleShowConfirmPassword={this.handleShowConfirmPassword}
                          handleShowPassword={this.handleShowPassword}
                          handleChange={this.handleChangeField} isHidden={step !== 1} {...this.state}
                          t={this.props.t} />
                        <div className="row">
                          <div className="col">
                            {step === 0 && (
                              <button className="btn btn-primary white-text-border btn-lg btn-block mt-2" type="submit" onClick={() => this.forgotPassword(step)} disabled={!isStepOneEnabled}>
                                {this.props.t("button_names.send_verification_code")}
                              </button>
                            )}
                            {step === 1 && (
                              <button className="btn btn-primary btn-lg btn-block mt-5" type="submit" onClick={() => this.resetPassword(step)} disabled={!(isStepOneEnabled && this.state.formValid)}>
                                {this.props.t("button_names.reset_password")}
                              </button>
                            )}
                          </div>
                        </div>
                        {isDone !== null && isDone === true && (<span className="text-success mt-2">{this.props.t("forgot_password_page.success_msg")}, <br /> {this.props.t("forgot_password_page.please_click")} <a href="#" onClick={() => history.push('/login')}>{this.props.t("forgot_password_page.here")}</a> {this.props.t("forgot_password_page.to_login")}</span>)}
                        {isDone != null && isDone === false && (<span className="text-danger mt-2">{this.props.t("forgot_password_page.password_not_updated")}, <br /> {this.props.t("forgot_password_page.please_try_again")}</span>)}
                        {step === 0 && (
                          <div className="row">
                            <div className="col">
                              <button type="button" className="btn white-text-border mt-4" 
                               onClick={() => history.push("/login")}
                              >
                                &larr; {this.props.t("button_names.back_to_login")}
                              </button>
                            </div>
                          </div>
                        )}
                      </form>
                  </div>
                </div>
              </div>
              <div className="col-6 cover-image login-image d-flex justify-content-center align-items-center" />
              <button aria-describedby={id} onClick={this.handlePopoverOpen} type="button" class="btn mt-2 translate_icon" style={{backgroundColor:"white"}}>
                    <TranslateIcon  fontSize={"large"} style={{color:'#255F79'}} />
                  </button>
                 
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableRestoreFocus
                  >
                    {supported_languages.map((lang_, i) => (

                      <Typography key={lang_.code} sx={{ p: 1 }} style={{ cursor: "pointer" }} onClick={(e) => {
                        changeLanguage(lang_.code);
                        this.setState({ anchorEl: null });
                      }}>{lang_.name}</Typography>
                    ))}
                  </Popover>
            </div>
          </div>
        )
        }
      </AppState.Consumer>
    );
  }

  render() {
    const { isLoading } = this.state;
    return isLoading ? Forgot.renderLoadingView() : this.renderForgotPasswordView();
  }
}

export default compose( withRouter,withTranslation())(Forgot);

const EmailForm = ({ handleChange, isHidden ,t}) => (
  <div className={`${isHidden && 'd-none'}`}>
    <small className="form-text whiteText text-left">
    {t("signup_labels.email_address")}
    </small>
    <input type="email" id="email" className="form-control mt-2 mb-4" name="email" onChange={handleChange} placeholder={t("signup_labels.email_address")} required />
  </div>
);

const SecretCodeForm = ({ formErrors, passwordValidation, handleChange, isHidden, handleShowPassword,
  handleShowConfirmPassword,isShowPassword,isShowConfirmPassword ,t}) => (
  <div className={`${isHidden && 'd-none'}`}>
    <small className="form-text whiteText text-left">
    {t("signup_labels.verification_code")}
    </small>
    <input type="text" id="code" className="form-control mt-2 mb-4" name="verificationCode" onChange={handleChange} placeholder={t("signup_labels.verification_code")} required />
    <small className="form-text whiteText text-left">
    {t("signup_labels.password")}
    </small>
    <div className="input-group mb-3">
      <input
        style={{paddingRight:"45px"}}
        type={isShowPassword?"text":"password"}
        id="password"
        className="form-control"
        name="password"
        onChange={handleChange}
        placeholder={t("signup_labels.password")}
        required
        autoComplete="new-password"
      />
      <div className="input-group-append" onClick={handleShowPassword}>
        <span className="input-group-text eye-icon">
          {isShowPassword ? <VisibilityOff /> : <Visibility />}
        </span>
      </div>
      </div>
      {formErrors.password && renderPasswordValidation(passwordValidation, t)}
    <small className="form-text whiteText text-left">
    {t("signup_labels.confirm_password")}
    </small>
    <div className="input-group mb-3">
      <input
        style={{paddingRight:"45px"}}
        type={isShowConfirmPassword?"text":"password"}
        id="confirmPassword"
        className="form-control"
        name="confirmPassword"
        onChange={handleChange}
        placeholder={t("signup_labels.confirm_password")}
        required
        autoComplete="new-password"
      />
      <div  className="input-group-append" onClick={handleShowConfirmPassword}
      >
        <span className="input-group-text eye-icon">
          {isShowConfirmPassword ? <VisibilityOff /> : <Visibility />}
        </span>
      </div>
      </div>
      {formErrors.confirmPassword && (
        <div className="alert alert-light mt-2 p-2" role="alert">
          {formErrors.confirmPassword}
        </div>
      )}
    <small className="form-text whiteText text-left" style={{ fontWeight: 400, textTransform: "none" }}>
      {t("password_criteria_msg")}
    </small>
  </div>
);
