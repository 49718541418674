import { v4 as uuidv4 } from 'uuid';
import API from './API';
import moment from "moment";
import { CountryRegionData } from 'react-country-region-selector';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
export const UUID = () => uuidv4();
import { trans } from './localizationService';

export const dollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

export const dateFormatter = new Intl.DateTimeFormat("en-US");

export const shortDollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 1,
  maximumSignificantDigits:3,
  notation:"compact"
});

export const  getAllFinancialDocIsPresent = async (email) => {
  const api = new API();
  const res = await api.getSupplierDocs();
  const profileResponse = await api.getV2Profile();
  let isAging=false,isBankStatement=false;
  if (res.items && res.items.length > 0) {
    let balanceSheet = res.items.filter(
      x => x.financialDocumentType === "BALANCE_SHEET"
    );
    let incomeStatement = res.items.filter(
      x => x.financialDocumentType === "INCOME_STATEMENT"
    );
    let cashflow = res.items.filter(
      x => x.financialDocumentType === "CASH_FLOW"
    );
    let aging = res.items.filter(
      x => x.financialDocumentType === "AGING_REPORT"
    );
    let bankStatement = res.items.filter
      (x => x.financialDocumentType == "BANK_STATEMENT_1"
      );
    if(aging.length==0){
      // if(profileResponse.user.accountingInformation=="quickbooks"){
      //   const qbInfo=await api.gettokenfromQB(email);
      //   if(qbInfo && !qbInfo.msg){
      //     isAging=true;
      //   }
      // }
      // else{
      //   const ERPInfo = await api.getErpInfo(email);
      //   if (ERPInfo.isValid) {
      //     isAging=true;
      //   }
      // }
      if(profileResponse.user.invoiceAgingReport &&
        profileResponse.user.invoiceAgingReport.invoices.length > 0){
          isAging=true;
        }
    }
    else{
      isAging=true;
    }
    if (bankStatement.length == 0) {
      if (profileResponse.user.accountingInformation == "quickbooks") {
        const bankTransaction = await api.getsupbanktransactionhistory(profileResponse.user._id);
        if (bankTransaction) {
          isBankStatement = true;
        }
      }
    }
    else {
      isBankStatement = true;
    }
    if(balanceSheet.length==0 && incomeStatement.length==0 && cashflow.length==0 && !isBankStatement && !isAging){
      return "No Financial Documents"
    }
    else if (balanceSheet.length == 0 && incomeStatement.length == 0 && cashflow.length == 0) {
      if(!isAging){
        return "Financial Documents and Aging Report"
      }
      else if(!isBankStatement){
        return "Financial Documents and Bank Statement"
      }
      else{
        return "Financial Documents"
      }
    }
    else if(!isAging || !isBankStatement){
      if( !isAging && !isBankStatement){
        return "Aging Report and Bank Statement";
      }
      else if(!isAging){
        return "Aging Report"
      }
      else if(!isBankStatement){
        return "Bank Statement"
      }
    }
    else {
      return "All document are present"
    }
    //const res = await this.api.getSupplierDocs();
  }
  else{
    return "No Financial Documents"
  }
};
  export const validateEmail = string => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(string).toLowerCase());
  };
  export const validatePhone = string => {
    const regex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    return regex.test(String(string));
  }

  export const passwordRegexString =/^(?=.*?[aA-zZ])(?=.*?[0-9])(?=.{8,})/;

  export const validatePassword = (password) => {
    return {
      lengthValid: password.length >= 8 && password.length <= 20,
      capitalValid: /[A-Z]/.test(password),
      numberValid: /[0-9]/.test(password),
      noSpaces: !/\s/.test(password)
    };
  };
  

  export const getStepForTimeline = code => {
    switch (code) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
      case 3:
        return 2;
      case 4:
        return 3;
      case 5:
        return 4;
      case 7:
        return 5;
      case 6:
        return 6;
      case 99:
        return 3;
      default:
        return 0;
    }
  };
  export const ShortText = code => {
    if (code.length > 28) {
      return code.substring(0, 28) + "...";
    } else {
      return code;
    }
  };
  export const getStepForFRTimeline = code => {
    switch (code) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      case 4:
      case 5:
        return 4;
      case 6:
      case 11:
      case 8:
        return 5;
      case 7:
        return 6;
      default:
        return 0;
    }
  }

  export const getStepForDocInvoiceTimeline = code => {
    switch (code) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
      case 3:
        return 2;
      case 99:
        return 3;
      case 100:
        return 4;
      default:
        return 0;
    }
  }

  export const getStepForTCFTimeline = code => {
    switch (code) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
      case 3:
        return 2;
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 99:
        return 3;
      case 100:
        return 4;
      default:
        return 0;
    }
  };

  export const getStepForPOTimeline = code => {
    switch (code) {
      case 1:
        return 0;
      case 99:
        return 1;
      case 100:
        return 2;
      default:
        return 0;
    }
  };
  export const getStepForBuyerTimeline = code => {
    switch (code) {
      case 1:
        return 0;
      case 2:
      case 3:
        return 1;
      case 99:
        return 2;
      case 100:
        return 4;
      case 101:
        return 3;
      default:
        return 0;
    }
  };

  export const getStepForFunderTimeline = code => {
    switch (code) {
      case 4:
        return 0;
      case 5:
        return 1;
      case 7:
        return 2;
      case 6:
        return 3;
      default:
        return 0;
    }
  };

  export const getIndustryWithId = code => {
    switch (Number.parseInt(code)) {
      case 1:
        return "Agriculture";
      case 2:
        return "Apparel";
      case 3:
        return "Computer Hardware";
      case 4:
        return "Computer Software";
      case 5:
        return "Construction";
      case 6:
        return "Consulting";
      case 7:
        return "Distribution";
      case 8:
        return "Energy-Related";
      case 9:
        return "Food";
      case 10:
        return "Import Export";
      case 11:
        return "Industrial Fabrication";
      case 12:
        return "Legal";
      case 13:
        return "Manufacturing";
      case 14:
        return "Medical";
      case 15:
        return "Retail";
      case 16:
        return "Service Industry";
      case 17:
        return "Telecommunication";
      case 18:
        return "Textile";
      case 19:
        return "Transportation";
      case 20:
        return "Other";
      default:
        return `Unknown code ${code}`;
    }
  };

  export const getIndustry = [
    { industryName: 'Accounting', status: true },
    { industryName: 'Airlines/Aviation', status: true },
    { industryName: 'Alternative Dispute Resolution', status: true },
    { industryName: 'Alternative Medicine', status: true },
    { industryName: 'Animation', status: true },
    { industryName: 'Apparel & Fashion', status: true },
    { industryName: 'Architecture & Planning', status: true },
    { industryName: 'Arts and Crafts', status: true },
    { industryName: 'Automotive', status: true },
    { industryName: 'Aviation & Aerospace', status: true },
    { industryName: 'Banking', status: true },
    { industryName: 'Biotechnology', status: true },
    { industryName: 'Broadcast Media', status: true },
    { industryName: 'Building Materials', status: true },
    { industryName: 'Business Supplies and Equipment', status: true },
    { industryName: 'Capital Markets', status: true },
    { industryName: 'Chemicals', status: true },
    { industryName: 'Civic & Social Organization', status: true },
    { industryName: 'Civil Engineering', status: true },
    { industryName: 'Commercial Real Estate', status: true },
    { industryName: 'Computer & Network Security', status: true },
    { industryName: 'Computer Games', status: true },
    { industryName: 'Computer Hardware', status: true },
    { industryName: 'Computer Networking', status: true },
    { industryName: 'Computer Software', status: true },
    { industryName: 'Construction', status: true },
    { industryName: 'Consumer Electronics', status: true },
    { industryName: 'Consumer Goods', status: true },
    { industryName: 'Consumer Services', status: true },
    { industryName: 'Cosmetics', status: true },
    { industryName: 'Dairy', status: true },
    { industryName: 'Defense & Space', status: true },
    { industryName: 'Design', status: true },
    { industryName: 'Education Management', status: true },
    { industryName: 'E-Learning', status: true },
    { industryName: 'Electrical/Electronic Manufacturing', status: true },
    { industryName: 'Entertainment', status: true },
    { industryName: 'Environmental Services', status: true },
    { industryName: 'Events Services', status: true },
    { industryName: 'Executive Office', status: true },
    { industryName: 'Facilities Services', status: true },
    { industryName: 'Farming', status: true },
    { industryName: 'Financial Services', status: true },
    { industryName: 'Fine Art', status: true },
    { industryName: 'Fishery', status: true },
    { industryName: 'Food & Beverages', status: true },
    { industryName: 'Food Production', status: true },
    { industryName: 'Fund-Raising', status: true },
    { industryName: 'Furniture', status: true },
    { industryName: 'Gambling & Casinos', status: true },
    { industryName: 'Glass, Ceramics & Concrete', status: true },
    { industryName: 'Government Administration', status: true },
    { industryName: 'Government Relations', status: true },
    { industryName: 'Graphic Design', status: true },
    { industryName: 'Health, Wellness and Fitness', status: true },
    { industryName: 'Higher Education', status: true },
    { industryName: 'Hospital & Health Care', status: true },
    { industryName: 'Hospitality', status: true },
    { industryName: 'Human Resources', status: true },
    { industryName: 'Import and Export', status: true },
    { industryName: 'Individual & Family Services', status: true },
    { industryName: 'Industrial Automation', status: true },
    { industryName: 'Information Services', status: true },
    { industryName: 'Information Technology and Services', status: true },
    { industryName: 'Insurance', status: true },
    { industryName: 'International Affairs', status: true },
    { industryName: 'International Trade and Development', status: true },
    { industryName: 'Internet', status: true },
    { industryName: 'Investment Banking', status: true },
    { industryName: 'Investment Management', status: true },
    { industryName: 'Judiciary', status: true },
    { industryName: 'Law Enforcement', status: true },
    { industryName: 'Law Practice', status: true },
    { industryName: 'Legal Services', status: true },
    { industryName: 'Legislative Office', status: true },
    { industryName: 'Leisure, Travel & Tourism', status: true },
    { industryName: 'Libraries', status: true },
    { industryName: 'Logistics and Supply Chain', status: true },
    { industryName: 'Luxury Goods & Jewelry', status: true },
    { industryName: 'Machinery', status: true },
    { industryName: 'Management Consulting', status: true },
    { industryName: 'Maritime', status: true },
    { industryName: 'Marketing and Advertising', status: true },
    { industryName: 'Market Research', status: true },
    { industryName: 'Mechanical or Industrial Engineering', status: true },
    { industryName: 'Media Production', status: true },
    { industryName: 'Medical Devices', status: true },
    { industryName: 'Medical Practice', status: true },
    { industryName: 'Mental Health Care', status: true },
    { industryName: 'Military', status: true },
    { industryName: 'Mining & Metals', status: true },
    { industryName: 'Motion Pictures and Film', status: true },
    { industryName: 'Museums and Institutions', status: true },
    { industryName: 'Music', status: true },
    { industryName: 'Nanotechnology', status: true },
    { industryName: 'Newspapers', status: true },
    { industryName: 'Nonprofit Organization Management', status: true },
    { industryName: 'Oil & Energy', status: true },
    { industryName: 'Online Media', status: true },
    { industryName: 'Outsourcing/Offshoring', status: true },
    { industryName: 'Package/Freight Delivery', status: true },
    { industryName: 'Packaging and Containers', status: true },
    { industryName: 'Paper & Forest Products', status: true },
    { industryName: 'Performing Arts', status: true },
    { industryName: 'Pharmaceuticals', status: true },
    { industryName: 'Philanthropy', status: true },
    { industryName: 'Photography', status: true },
    { industryName: 'Plastics', status: true },
    { industryName: 'Political Organization', status: true },
    { industryName: 'Primary/Secondary Education', status: true },
    { industryName: 'Printing', status: true },
    { industryName: 'Professional Training & Coaching', status: true },
    { industryName: 'Program Development', status: true },
    { industryName: 'Public Policy', status: true },
    { industryName: 'Public Relations and Communications', status: true },
    { industryName: 'Public Safety', status: true },
    { industryName: 'Publishing', status: true },
    { industryName: 'Railroad Manufacture', status: true },
    { industryName: 'Ranching', status: true },
    { industryName: 'Real Estate', status: true },
    { industryName: 'Recreational Facilities and Services', status: true },
    { industryName: 'Religious Institutions', status: true },
    { industryName: 'Renewables & Environment', status: true },
    { industryName: 'Research', status: true },
    { industryName: 'Restaurants', status: true },
    { industryName: 'Retail', status: true },
    { industryName: 'Security and Investigations', status: true },
    { industryName: 'Semiconductors', status: true },
    { industryName: 'Shipbuilding', status: true },
    { industryName: 'Sporting Goods', status: true },
    { industryName: 'Sports', status: true },
    { industryName: 'Staffing and Recruiting', status: true },
    { industryName: 'Supermarkets', status: true },
    { industryName: 'Telecommunications', status: true },
    { industryName: 'Textiles', status: true },
    { industryName: 'Think Tanks', status: true },
    { industryName: 'Tobacco', status: true },
    { industryName: 'Translation and Localization', status: true },
    { industryName: 'Transportation/Trucking/Railroad', status: true },
    { industryName: 'Utilities', status: true },
    { industryName: 'Venture Capital & Private Equity', status: true },
    { industryName: 'Veterinary', status: true },
    { industryName: 'Warehousing', status: true },
    { industryName: 'Wholesale', status: true },
    { industryName: 'Wine and Spirits', status: true },
    { industryName: 'Wireless', status: true },
    { industryName: 'Writing and Editing', status: true },
  ];


  export const getEntity = code => {
    switch (code) {
      case 1:
        return "Sole Proprietor";
      case 2:
        return "Limited Liability Company (LLC)";
      case 3:
        return "Corporation";
      case 4:
        return "General Partnership";
      case 5:
        return "Limited Partnership (LP)";
      case 6:
        return "Limited Liability Partnership (LLP)";
      case 7:
        return "Non-Profit";
      default:
        return `Unknown code ${code}`;
    }
  };

  export const getNewIndustryList = [
    { industryName: 'Agriculture', status: true },
    { industryName: 'Apparel', status: true },
    { industryName: 'Computer Hardware', status: true },
    { industryName: 'Computer Software', status: true },
    { industryName: 'Construction', status: true },
    { industryName: 'Consulting', status: true },
    { industryName: 'Distribution', status: true },
    { industryName: 'Energy-Related', status: true },
    { industryName: 'Food', status: true },
    { industryName: 'Import Export', status: true },
    { industryName: 'Industrial Fabrication', status: true },
    { industryName: 'Legal', status: true },
    { industryName: 'Manufacturing', status: true },
    { industryName: 'Medical', status: true },
    { industryName: 'Retail', status: true },
    { industryName: 'Service Industry', status: true },
    { industryName: 'Telecommunication', status: true },
    { industryName: 'Textile', status: true },
    { industryName: 'Transportation', status: true },
    { industryName: 'Other', status: true },
  ];

//TO-DO: Update this date format string to array or switch condition
const _dateFormatValue = "MM/DD/YYYY";
const _timeFormatValue = "h:mm:ss a";
const _dateAndTimeFormatValue = "MM/DD/YYYY, h:mm:ss a";

export const localDateFormat = (date_) => {
  return moment(date_).format(_dateFormatValue)
}

export const numberMask = createNumberMask({
  prefix: '$',
  suffix: '',
  allowDecimal: true
});


export const convertDocumentDate = (date_) => {
  return date_.toJSON().split("T")[0];
}

export const convertLocalDateFormat = (date_) => {
  let _newDate = new Date(date_);
  let _convertedLocalDate = new Date(_newDate.getTime() + _newDate.getTimezoneOffset() * 60000);
  return moment(_convertedLocalDate).format(_dateFormatValue);
}

export const localDateFormatWithTime = (date_) => {
  return `${moment(date_).format(_dateFormatValue)}(${moment(date_).format(_timeFormatValue)})`
}

export const localDateFormatWithoutBracketTime = (date_) => {
  return moment(date_).format(_dateAndTimeFormatValue)
}

export const localTimeFormat = (date_) => {
  return moment(date_).format(_timeFormatValue);
}

  export const countryList = [
    "AR", "AM", "AU", "AT", "AZ", "BY", "BE", "BA", "BW", "BR", "BG",
    "CA", "CL", "CN", "CO", "CR", "HR", "CY", "CZ", "DK", "DO", "EG",
    "EE", "FI", "FR", "GE", "DE", "GR", "GT", "HN", "HK", "HU", "IN",
    "ID", "IE", "IL", "IT", "JP", "KE", "XK", "LV", "LB", "LT", "LU",
    "MY", "MT", "MU", "MX", "MD", "MA", "MK", "NL", "NZ", "KP", "NO",
    "OM", "PE", "PL", "PT", "RO", "RU", "RS", "SG", "SK", "SL", "ZA",
    "KR", "ES", "LK", "SE", "CH", "TW", "TH", "TN", "TR", "UG", "UA",
    "GB", "AE", "US", "GM", "VN"
  ];

export const blacklist = {
  US: ["Micronesia", "Guam", "Marshall Islands", "Northern Mariana Islands", "Palau", "Puerto Rico",
    "American Samoa", "District of Columbia", "Virgin Islands", "Armed Forces Americas", "Armed Forces Europe, Canada, Africa and Middle East", "Armed Forces Pacific"]
};


export const allCountryDetails = () => {
  let showCountriesList_ = countryList;
  let countrywithStates = CountryRegionData;
  return countrywithStates = countrywithStates.filter(country_ => showCountriesList_.includes(country_[1])).map((c_) => {
    let details_ = {};
    details_.countryName = c_[0];
    details_.countryShortCode = c_[1];
    details_.stateList = [];
    details_.stateList = c_[2].split("|").map(s_ => {
      let stateDetails_ = s_.split("~");
      return {
        "stateName": stateDetails_[0],
        "stateCode": stateDetails_[1]
      }
    });
    return details_;
  });
}


export const countryCallingCode=[
  {
  "name": "Afghanistan",
  "dial_code": "+93",
  "code": "AF"
  },
  {
  "name": "Aland Islands",
  "dial_code": "+358",
  "code": "AX"
  },
  {
  "name": "Albania",
  "dial_code": "+355",
  "code": "AL"
  },
  {
  "name": "Algeria",
  "dial_code": "+213",
  "code": "DZ"
  },
  {
  "name": "AmericanSamoa",
  "dial_code": "+1684",
  "code": "AS"
  },
  {
  "name": "Andorra",
  "dial_code": "+376",
  "code": "AD"
  },
  {
  "name": "Angola",
  "dial_code": "+244",
  "code": "AO"
  },
  {
  "name": "Anguilla",
  "dial_code": "+1264",
  "code": "AI"
  },
  {
  "name": "Antarctica",
  "dial_code": "+672",
  "code": "AQ"
  },
  {
  "name": "Antigua and Barbuda",
  "dial_code": "+1268",
  "code": "AG"
  },
  {
  "name": "Argentina",
  "dial_code": "+54",
  "code": "AR"
  },
  {
  "name": "Armenia",
  "dial_code": "+374",
  "code": "AM"
  },
  {
  "name": "Aruba",
  "dial_code": "+297",
  "code": "AW"
  },
  {
  "name": "Australia",
  "dial_code": "+61",
  "code": "AU"
  },
  {
  "name": "Austria",
  "dial_code": "+43",
  "code": "AT"
  },
  {
  "name": "Azerbaijan",
  "dial_code": "+994",
  "code": "AZ"
  },
  {
  "name": "Bahamas",
  "dial_code": "+1242",
  "code": "BS"
  },
  {
  "name": "Bahrain",
  "dial_code": "+973",
  "code": "BH"
  },
  {
  "name": "Bangladesh",
  "dial_code": "+880",
  "code": "BD"
  },
  {
  "name": "Barbados",
  "dial_code": "+1246",
  "code": "BB"
  },
  {
  "name": "Belarus",
  "dial_code": "+375",
  "code": "BY"
  },
  {
  "name": "Belgium",
  "dial_code": "+32",
  "code": "BE"
  },
  {
  "name": "Belize",
  "dial_code": "+501",
  "code": "BZ"
  },
  {
  "name": "Benin",
  "dial_code": "+229",
  "code": "BJ"
  },
  {
  "name": "Bermuda",
  "dial_code": "+1441",
  "code": "BM"
  },
  {
  "name": "Bhutan",
  "dial_code": "+975",
  "code": "BT"
  },
  {
  "name": "Bolivia, Plurinational State of",
  "dial_code": "+591",
  "code": "BO"
  },
  {
  "name": "Bosnia and Herzegovina",
  "dial_code": "+387",
  "code": "BA"
  },
  {
  "name": "Botswana",
  "dial_code": "+267",
  "code": "BW"
  },
  {
  "name": "Brazil",
  "dial_code": "+55",
  "code": "BR"
  },
  {
  "name": "British Indian Ocean Territory",
  "dial_code": "+246",
  "code": "IO"
  },
  {
  "name": "Brunei Darussalam",
  "dial_code": "+673",
  "code": "BN"
  },
  {
  "name": "Bulgaria",
  "dial_code": "+359",
  "code": "BG"
  },
  {
  "name": "Burkina Faso",
  "dial_code": "+226",
  "code": "BF"
  },
  {
  "name": "Burundi",
  "dial_code": "+257",
  "code": "BI"
  },
  {
  "name": "Cambodia",
  "dial_code": "+855",
  "code": "KH"
  },
  {
  "name": "Cameroon",
  "dial_code": "+237",
  "code": "CM"
  },
  {
  "name": "Canada",
  "dial_code": "+1",
  "code": "CA"
  },
  {
  "name": "Cape Verde",
  "dial_code": "+238",
  "code": "CV"
  },
  {
  "name": "Cayman Islands",
  "dial_code": "+ 345",
  "code": "KY"
  },
  {
  "name": "Central African Republic",
  "dial_code": "+236",
  "code": "CF"
  },
  {
  "name": "Chad",
  "dial_code": "+235",
  "code": "TD"
  },
  {
  "name": "Chile",
  "dial_code": "+56",
  "code": "CL"
  },
  {
  "name": "China",
  "dial_code": "+86",
  "code": "CN"
  },
  {
  "name": "Christmas Island",
  "dial_code": "+61",
  "code": "CX"
  },
  {
  "name": "Cocos (Keeling) Islands",
  "dial_code": "+61",
  "code": "CC"
  },
  {
  "name": "Colombia",
  "dial_code": "+57",
  "code": "CO"
  },
  {
  "name": "Comoros",
  "dial_code": "+269",
  "code": "KM"
  },
  {
  "name": "Congo",
  "dial_code": "+242",
  "code": "CG"
  },
  {
  "name": "Congo, The Democratic Republic of the Congo",
  "dial_code": "+243",
  "code": "CD"
  },
  {
  "name": "Cook Islands",
  "dial_code": "+682",
  "code": "CK"
  },
  {
  "name": "Costa Rica",
  "dial_code": "+506",
  "code": "CR"
  },
  {
  "name": "Cote d'Ivoire",
  "dial_code": "+225",
  "code": "CI"
  },
  {
  "name": "Croatia",
  "dial_code": "+385",
  "code": "HR"
  },
  {
  "name": "Cuba",
  "dial_code": "+53",
  "code": "CU"
  },
  {
  "name": "Cyprus",
  "dial_code": "+357",
  "code": "CY"
  },
  {
  "name": "Czech Republic",
  "dial_code": "+420",
  "code": "CZ"
  },
  {
  "name": "Denmark",
  "dial_code": "+45",
  "code": "DK"
  },
  {
  "name": "Djibouti",
  "dial_code": "+253",
  "code": "DJ"
  },
  {
  "name": "Dominica",
  "dial_code": "+1767",
  "code": "DM"
  },
  {
  "name": "Dominican Republic",
  "dial_code": "+1849",
  "code": "DO"
  },
  {
  "name": "Ecuador",
  "dial_code": "+593",
  "code": "EC"
  },
  {
  "name": "Egypt",
  "dial_code": "+20",
  "code": "EG"
  },
  {
  "name": "El Salvador",
  "dial_code": "+503",
  "code": "SV"
  },
  {
  "name": "Equatorial Guinea",
  "dial_code": "+240",
  "code": "GQ"
  },
  {
  "name": "Eritrea",
  "dial_code": "+291",
  "code": "ER"
  },
  {
  "name": "Estonia",
  "dial_code": "+372",
  "code": "EE"
  },
  {
  "name": "Ethiopia",
  "dial_code": "+251",
  "code": "ET"
  },
  {
  "name": "Falkland Islands (Malvinas)",
  "dial_code": "+500",
  "code": "FK"
  },
  {
  "name": "Faroe Islands",
  "dial_code": "+298",
  "code": "FO"
  },
  {
  "name": "Fiji",
  "dial_code": "+679",
  "code": "FJ"
  },
  {
  "name": "Finland",
  "dial_code": "+358",
  "code": "FI"
  },
  {
  "name": "France",
  "dial_code": "+33",
  "code": "FR"
  },
  {
  "name": "French Guiana",
  "dial_code": "+594",
  "code": "GF"
  },
  {
  "name": "French Polynesia",
  "dial_code": "+689",
  "code": "PF"
  },
  {
  "name": "Gabon",
  "dial_code": "+241",
  "code": "GA"
  },
  {
  "name": "Gambia",
  "dial_code": "+220",
  "code": "GM"
  },
  {
  "name": "Georgia",
  "dial_code": "+995",
  "code": "GE"
  },
  {
  "name": "Germany",
  "dial_code": "+49",
  "code": "DE"
  },
  {
  "name": "Ghana",
  "dial_code": "+233",
  "code": "GH"
  },
  {
  "name": "Gibraltar",
  "dial_code": "+350",
  "code": "GI"
  },
  {
  "name": "Greece",
  "dial_code": "+30",
  "code": "GR"
  },
  {
  "name": "Greenland",
  "dial_code": "+299",
  "code": "GL"
  },
  {
  "name": "Grenada",
  "dial_code": "+1473",
  "code": "GD"
  },
  {
  "name": "Guadeloupe",
  "dial_code": "+590",
  "code": "GP"
  },
  {
  "name": "Guam",
  "dial_code": "+1671",
  "code": "GU"
  },
  {
  "name": "Guatemala",
  "dial_code": "+502",
  "code": "GT"
  },
  {
  "name": "Guernsey",
  "dial_code": "+44",
  "code": "GG"
  },
  {
  "name": "Guinea",
  "dial_code": "+224",
  "code": "GN"
  },
  {
  "name": "Guinea-Bissau",
  "dial_code": "+245",
  "code": "GW"
  },
  {
  "name": "Guyana",
  "dial_code": "+595",
  "code": "GY"
  },
  {
  "name": "Haiti",
  "dial_code": "+509",
  "code": "HT"
  },
  {
  "name": "Holy See (Vatican City State)",
  "dial_code": "+379",
  "code": "VA"
  },
  {
  "name": "Honduras",
  "dial_code": "+504",
  "code": "HN"
  },
  {
  "name": "Hong Kong",
  "dial_code": "+852",
  "code": "HK"
  },
  {
  "name": "Hungary",
  "dial_code": "+36",
  "code": "HU"
  },
  {
  "name": "Iceland",
  "dial_code": "+354",
  "code": "IS"
  },
  {
  "name": "India",
  "dial_code": "+91",
  "code": "IN"
  },
  {
  "name": "Indonesia",
  "dial_code": "+62",
  "code": "ID"
  },
  {
  "name": "Iran, Islamic Republic of Persian Gulf",
  "dial_code": "+98",
  "code": "IR"
  },
  {
  "name": "Iraq",
  "dial_code": "+964",
  "code": "IQ"
  },
  {
  "name": "Ireland",
  "dial_code": "+353",
  "code": "IE"
  },
  {
  "name": "Isle of Man",
  "dial_code": "+44",
  "code": "IM"
  },
  {
  "name": "Israel",
  "dial_code": "+972",
  "code": "IL"
  },
  {
  "name": "Italy",
  "dial_code": "+39",
  "code": "IT"
  },
  {
  "name": "Jamaica",
  "dial_code": "+1876",
  "code": "JM"
  },
  {
  "name": "Japan",
  "dial_code": "+81",
  "code": "JP"
  },
  {
  "name": "Jersey",
  "dial_code": "+44",
  "code": "JE"
  },
  {
  "name": "Jordan",
  "dial_code": "+962",
  "code": "JO"
  },
  {
  "name": "Kazakhstan",
  "dial_code": "+77",
  "code": "KZ"
  },
  {
  "name": "Kenya",
  "dial_code": "+254",
  "code": "KE"
  },
  {
  "name": "Kiribati",
  "dial_code": "+686",
  "code": "KI"
  },
  {
  "name": "Korea, Democratic People's Republic of Korea",
  "dial_code": "+850",
  "code": "KP"
  },
  {
  "name": "Korea, Republic of South Korea",
  "dial_code": "+82",
  "code": "KR"
  },
  {
  "name": "Kuwait",
  "dial_code": "+965",
  "code": "KW"
  },
  {
  "name": "Kyrgyzstan",
  "dial_code": "+996",
  "code": "KG"
  },
  {
  "name": "Laos",
  "dial_code": "+856",
  "code": "LA"
  },
  {
  "name": "Latvia",
  "dial_code": "+371",
  "code": "LV"
  },
  {
  "name": "Lebanon",
  "dial_code": "+961",
  "code": "LB"
  },
  {
  "name": "Lesotho",
  "dial_code": "+266",
  "code": "LS"
  },
  {
  "name": "Liberia",
  "dial_code": "+231",
  "code": "LR"
  },
  {
  "name": "Libyan Arab Jamahiriya",
  "dial_code": "+218",
  "code": "LY"
  },
  {
  "name": "Liechtenstein",
  "dial_code": "+423",
  "code": "LI"
  },
  {
  "name": "Lithuania",
  "dial_code": "+370",
  "code": "LT"
  },
  {
  "name": "Luxembourg",
  "dial_code": "+352",
  "code": "LU"
  },
  {
  "name": "Macao",
  "dial_code": "+853",
  "code": "MO"
  },
  {
  "name": "Macedonia",
  "dial_code": "+389",
  "code": "MK"
  },
  {
  "name": "Madagascar",
  "dial_code": "+261",
  "code": "MG"
  },
  {
  "name": "Malawi",
  "dial_code": "+265",
  "code": "MW"
  },
  {
  "name": "Malaysia",
  "dial_code": "+60",
  "code": "MY"
  },
  {
  "name": "Maldives",
  "dial_code": "+960",
  "code": "MV"
  },
  {
  "name": "Mali",
  "dial_code": "+223",
  "code": "ML"
  },
  {
  "name": "Malta",
  "dial_code": "+356",
  "code": "MT"
  },
  {
  "name": "Marshall Islands",
  "dial_code": "+692",
  "code": "MH"
  },
  {
  "name": "Martinique",
  "dial_code": "+596",
  "code": "MQ"
  },
  {
  "name": "Mauritania",
  "dial_code": "+222",
  "code": "MR"
  },
  {
  "name": "Mauritius",
  "dial_code": "+230",
  "code": "MU"
  },
  {
  "name": "Mayotte",
  "dial_code": "+262",
  "code": "YT"
  },
  {
  "name": "Mexico",
  "dial_code": "+52",
  "code": "MX"
  },
  {
  "name": "Micronesia, Federated States of Micronesia",
  "dial_code": "+691",
  "code": "FM"
  },
  {
  "name": "Moldova",
  "dial_code": "+373",
  "code": "MD"
  },
  {
  "name": "Monaco",
  "dial_code": "+377",
  "code": "MC"
  },
  {
  "name": "Mongolia",
  "dial_code": "+976",
  "code": "MN"
  },
  {
  "name": "Montenegro",
  "dial_code": "+382",
  "code": "ME"
  },
  {
  "name": "Montserrat",
  "dial_code": "+1664",
  "code": "MS"
  },
  {
  "name": "Morocco",
  "dial_code": "+212",
  "code": "MA"
  },
  {
  "name": "Mozambique",
  "dial_code": "+258",
  "code": "MZ"
  },
  {
  "name": "Myanmar",
  "dial_code": "+95",
  "code": "MM"
  },
  {
  "name": "Namibia",
  "dial_code": "+264",
  "code": "NA"
  },
  {
  "name": "Nauru",
  "dial_code": "+674",
  "code": "NR"
  },
  {
  "name": "Nepal",
  "dial_code": "+977",
  "code": "NP"
  },
  {
  "name": "Netherlands",
  "dial_code": "+31",
  "code": "NL"
  },
  {
  "name": "Netherlands Antilles",
  "dial_code": "+599",
  "code": "AN"
  },
  {
  "name": "New Caledonia",
  "dial_code": "+687",
  "code": "NC"
  },
  {
  "name": "New Zealand",
  "dial_code": "+64",
  "code": "NZ"
  },
  {
  "name": "Nicaragua",
  "dial_code": "+505",
  "code": "NI"
  },
  {
  "name": "Niger",
  "dial_code": "+227",
  "code": "NE"
  },
  {
  "name": "Nigeria",
  "dial_code": "+234",
  "code": "NG"
  },
  {
  "name": "Niue",
  "dial_code": "+683",
  "code": "NU"
  },
  {
  "name": "Norfolk Island",
  "dial_code": "+672",
  "code": "NF"
  },
  {
  "name": "Northern Mariana Islands",
  "dial_code": "+1670",
  "code": "MP"
  },
  {
  "name": "Norway",
  "dial_code": "+47",
  "code": "NO"
  },
  {
  "name": "Oman",
  "dial_code": "+968",
  "code": "OM"
  },
  {
  "name": "Pakistan",
  "dial_code": "+92",
  "code": "PK"
  },
  {
  "name": "Palau",
  "dial_code": "+680",
  "code": "PW"
  },
  {
  "name": "Palestinian Territory, Occupied",
  "dial_code": "+970",
  "code": "PS"
  },
  {
  "name": "Panama",
  "dial_code": "+507",
  "code": "PA"
  },
  {
  "name": "Papua New Guinea",
  "dial_code": "+675",
  "code": "PG"
  },
  {
  "name": "Paraguay",
  "dial_code": "+595",
  "code": "PY"
  },
  {
  "name": "Peru",
  "dial_code": "+51",
  "code": "PE"
  },
  {
  "name": "Philippines",
  "dial_code": "+63",
  "code": "PH"
  },
  {
  "name": "Pitcairn",
  "dial_code": "+872",
  "code": "PN"
  },
  {
  "name": "Poland",
  "dial_code": "+48",
  "code": "PL"
  },
  {
  "name": "Portugal",
  "dial_code": "+351",
  "code": "PT"
  },
  {
  "name": "Puerto Rico",
  "dial_code": "+1939",
  "code": "PR"
  },
  {
  "name": "Qatar",
  "dial_code": "+974",
  "code": "QA"
  },
  {
  "name": "Romania",
  "dial_code": "+40",
  "code": "RO"
  },
  {
  "name": "Russia",
  "dial_code": "+7",
  "code": "RU"
  },
  {
  "name": "Rwanda",
  "dial_code": "+250",
  "code": "RW"
  },
  {
  "name": "Reunion",
  "dial_code": "+262",
  "code": "RE"
  },
  {
  "name": "Saint Barthelemy",
  "dial_code": "+590",
  "code": "BL"
  },
  {
  "name": "Saint Helena, Ascension and Tristan Da Cunha",
  "dial_code": "+290",
  "code": "SH"
  },
  {
  "name": "Saint Kitts and Nevis",
  "dial_code": "+1869",
  "code": "KN"
  },
  {
  "name": "Saint Lucia",
  "dial_code": "+1758",
  "code": "LC"
  },
  {
  "name": "Saint Martin",
  "dial_code": "+590",
  "code": "MF"
  },
  {
  "name": "Saint Pierre and Miquelon",
  "dial_code": "+508",
  "code": "PM"
  },
  {
  "name": "Saint Vincent and the Grenadines",
  "dial_code": "+1784",
  "code": "VC"
  },
  {
  "name": "Samoa",
  "dial_code": "+685",
  "code": "WS"
  },
  {
  "name": "San Marino",
  "dial_code": "+378",
  "code": "SM"
  },
  {
  "name": "Sao Tome and Principe",
  "dial_code": "+239",
  "code": "ST"
  },
  {
  "name": "Saudi Arabia",
  "dial_code": "+966",
  "code": "SA"
  },
  {
  "name": "Senegal",
  "dial_code": "+221",
  "code": "SN"
  },
  {
  "name": "Serbia",
  "dial_code": "+381",
  "code": "RS"
  },
  {
  "name": "Seychelles",
  "dial_code": "+248",
  "code": "SC"
  },
  {
  "name": "Sierra Leone",
  "dial_code": "+232",
  "code": "SL"
  },
  {
  "name": "Singapore",
  "dial_code": "+65",
  "code": "SG"
  },
  {
  "name": "Slovakia",
  "dial_code": "+421",
  "code": "SK"
  },
  {
  "name": "Slovenia",
  "dial_code": "+386",
  "code": "SI"
  },
  {
  "name": "Solomon Islands",
  "dial_code": "+677",
  "code": "SB"
  },
  {
  "name": "Somalia",
  "dial_code": "+252",
  "code": "SO"
  },
  {
  "name": "South Africa",
  "dial_code": "+27",
  "code": "ZA"
  },
  {
  "name": "South Sudan",
  "dial_code": "+211",
  "code": "SS"
  },
  {
  "name": "South Georgia and the South Sandwich Islands",
  "dial_code": "+500",
  "code": "GS"
  },
  {
  "name": "Spain",
  "dial_code": "+34",
  "code": "ES"
  },
  {
  "name": "Sri Lanka",
  "dial_code": "+94",
  "code": "LK"
  },
  {
  "name": "Sudan",
  "dial_code": "+249",
  "code": "SD"
  },
  {
  "name": "Suriname",
  "dial_code": "+597",
  "code": "SR"
  },
  {
  "name": "Svalbard and Jan Mayen",
  "dial_code": "+47",
  "code": "SJ"
  },
  {
  "name": "Swaziland",
  "dial_code": "+268",
  "code": "SZ"
  },
  {
  "name": "Sweden",
  "dial_code": "+46",
  "code": "SE"
  },
  {
  "name": "Switzerland",
  "dial_code": "+41",
  "code": "CH"
  },
  {
  "name": "Syrian Arab Republic",
  "dial_code": "+963",
  "code": "SY"
  },
  {
  "name": "Taiwan",
  "dial_code": "+886",
  "code": "TW"
  },
  {
  "name": "Tajikistan",
  "dial_code": "+992",
  "code": "TJ"
  },
  {
  "name": "Tanzania, United Republic of Tanzania",
  "dial_code": "+255",
  "code": "TZ"
  },
  {
  "name": "Thailand",
  "dial_code": "+66",
  "code": "TH"
  },
  {
  "name": "Timor-Leste",
  "dial_code": "+670",
  "code": "TL"
  },
  {
  "name": "Togo",
  "dial_code": "+228",
  "code": "TG"
  },
  {
  "name": "Tokelau",
  "dial_code": "+690",
  "code": "TK"
  },
  {
  "name": "Tonga",
  "dial_code": "+676",
  "code": "TO"
  },
  {
  "name": "Trinidad and Tobago",
  "dial_code": "+1868",
  "code": "TT"
  },
  {
  "name": "Tunisia",
  "dial_code": "+216",
  "code": "TN"
  },
  {
  "name": "Turkey",
  "dial_code": "+90",
  "code": "TR"
  },
  {
  "name": "Turkmenistan",
  "dial_code": "+993",
  "code": "TM"
  },
  {
  "name": "Turks and Caicos Islands",
  "dial_code": "+1649",
  "code": "TC"
  },
  {
  "name": "Tuvalu",
  "dial_code": "+688",
  "code": "TV"
  },
  {
  "name": "Uganda",
  "dial_code": "+256",
  "code": "UG"
  },
  {
  "name": "Ukraine",
  "dial_code": "+380",
  "code": "UA"
  },
  {
  "name": "United Arab Emirates",
  "dial_code": "+971",
  "code": "AE"
  },
  {
  "name": "United Kingdom",
  "dial_code": "+44",
  "code": "GB"
  },
  {
  "name": "United States",
  "dial_code": "+1",
  "code": "US"
  },
  {
  "name": "Uruguay",
  "dial_code": "+598",
  "code": "UY"
  },
  {
  "name": "Uzbekistan",
  "dial_code": "+998",
  "code": "UZ"
  },
  {
  "name": "Vanuatu",
  "dial_code": "+678",
  "code": "VU"
  },
  {
  "name": "Venezuela, Bolivarian Republic of Venezuela",
  "dial_code": "+58",
  "code": "VE"
  },
  {
  "name": "Vietnam",
  "dial_code": "+84",
  "code": "VN"
  },
  {
  "name": "Virgin Islands, British",
  "dial_code": "+1284",
  "code": "VG"
  },
  {
  "name": "Virgin Islands, U.S.",
  "dial_code": "+1340",
  "code": "VI"
  },
  {
  "name": "Wallis and Futuna",
  "dial_code": "+681",
  "code": "WF"
  },
  {
  "name": "Yemen",
  "dial_code": "+967",
  "code": "YE"
  },
  {
  "name": "Zambia",
  "dial_code": "+260",
  "code": "ZM"
  },
  {
  "name": "Zimbabwe",
  "dial_code": "+263",
  "code": "ZW"
  }
  ];

export const cslDataPreprocessor = (cslData) => {
  try {
    let cslSources = [
      "Specially Designated Nationals (SDN) - Treasury Department",
      "Denied Persons List (DPL) - Bureau of Industry and Security",
      "ITAR Debarred (DTC) - State Department",
      "Unverified List (UVL) - Bureau of Industry and Security",
      "Nonproliferation Sanctions (ISN) - State Department",
      "Sectoral Sanctions Identifications List (SSI) - Treasury Department",
      "Correspondent Account or Payable-Through Account Sanctions (CAPTA)",
      "Non-SDN Chinese Military-Industrial Complex Companies List (CMIC) - Treasury Department",
      "Entity List (EL) - Bureau of Industry and Security",
      "Foreign Sanctions Evaders (FSE) - Treasury Department",
      "Military End-User (MEU) - Bureau of Industry and Security",
      "Non-SDN Menu-Based Sanctions List (MBS) - Treasury Department",
      "Palestinian Legislative Council List (PLC) - Treasury Department",
    ];
  
    if (cslData) {
      if (!cslData.sources) {
        cslData.sources = [];
      }
  
      for (let cslSource of cslSources) {
        if (cslData.sources.filter(c => c.value == cslSource).length == 0) {
          cslData.sources.push({
            count: 0,
            value: cslSource
          });
        }
      }
  
      cslData.sources.sort((a, b) => a.value > b.value ? 1 : -1);
    }
  }
  catch(ex) { }
}

export const netTerms= ["NET 15", "NET 30", "NET 45", "NET 60", "NET 90", "NET 120", "NET 180", "International"];

export const PaymentTerm = (status) => {
  switch (status) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return netTerms[status];
    case 11:
      return "International";
    default:
      return netTerms[0];
  }
};

export const netTermsMapper = (term) => {
  let termKeyMaker = (paymentterm) => {
    return paymentterm.toLowerCase().replace(/\s/g, "");
  }
  let inputTerm = termKeyMaker(term);
  let matchingTerm = netTerms.filter(t => termKeyMaker(t)==inputTerm)[0];
  return matchingTerm?matchingTerm: netTerms[7];
}

export const parseNetTermToDays = (term) => {
  if (term === "FOB") {
    return 30;
  }
  const numericPart = term.match(/\d+/);
  if (numericPart !== null) {
    const days = parseInt(numericPart[0]);
    if (days >= 1 && days <= 365) {
      return days;
    }
  }
  return 30;
};

export const addressPatterns = [
  /[a-z\s]+,\s+[a-z\s]+\s+[0-9]+/i,
  /[a-z\s]+,\s+[aA-zZ\s]+,\s+[aA-zZ0-9]+/i
]

export const dateMapper=(text_)=>{
  const _dotDatePattern=/(\d+)[.](\d+)[.](\d+)/;
  const _matchedValue=text_.match(_dotDatePattern);
  if(_matchedValue && _matchedValue.length){
    return `${_matchedValue[2]}/${_matchedValue[1]}/${_matchedValue[3]}`;
  }
  else{
    return text_;
  }
}

export const fileStatusBadgeText = (text_) => {
  const status_=text_?text_.toLowerCase():"";
  switch (status_) {
      case "quickbooks":
      case "qbo":
          return "QuickBooks Online";
      case "d365bc":
          return "Microsoft Dynamics 365 Business Central";
      case "erpinfo":
      case "erp":
          return "Microsoft Dynamics 365 Finance";
      case "approved":
          return "Approved by Buyer";
      default:
          return "Manual Upload";
  }
}

export const addDaysInSufixForPaymentTerm=(val_)=>{
  return  `${val_} ${parseInt(val_)==1?trans("common_names.day").toString().toLowerCase( ):trans("common_names.days").toString().toLowerCase()}`;
}


export const isUSCountryUser=(country_)=>{
  if(country_){
    return (country_=="United States" || country_ == "United States of America" || country_.toString().toLocaleLowerCase()=="usa");
  }
  else{
    return false;
  }
}

const countries = [
  {
    "country": "Afghanistan",
    "currency_code": "AFN"
  },
  {
    "country": "Albania",
    "currency_code": "ALL"
  },
  {
    "country": "Algeria",
    "currency_code": "DZD"
  },
  {
    "country": "American Samoa",
    "currency_code": "USD"
  },
  {
    "country": "Andorra",
    "currency_code": "EUR"
  },
  {
    "country": "Angola",
    "currency_code": "AOA"
  },
  {
    "country": "Anguilla",
    "currency_code": "XCD"
  },
  {
    "country": "Antarctica",
    "currency_code": "XCD"
  },
  {
    "country": "Antigua and Barbuda",
    "currency_code": "XCD"
  },
  {
    "country": "Argentina",
    "currency_code": "ARS"
  },
  {
    "country": "Armenia",
    "currency_code": "AMD"
  },
  {
    "country": "Aruba",
    "currency_code": "AWG"
  },
  {
    "country": "Australia",
    "currency_code": "AUD"
  },
  {
    "country": "Austria",
    "currency_code": "EUR"
  },
  {
    "country": "Azerbaijan",
    "currency_code": "AZN"
  },
  {
    "country": "Bahamas",
    "currency_code": "BSD"
  },
  {
    "country": "Bahrain",
    "currency_code": "BHD"
  },
  {
    "country": "Bangladesh",
    "currency_code": "BDT"
  },
  {
    "country": "Barbados",
    "currency_code": "BBD"
  },
  {
    "country": "Belarus",
    "currency_code": "BYR"
  },
  {
    "country": "Belgium",
    "currency_code": "EUR"
  },
  {
    "country": "Belize",
    "currency_code": "BZD"
  },
  {
    "country": "Benin",
    "currency_code": "XOF"
  },
  {
    "country": "Bermuda",
    "currency_code": "BMD"
  },
  {
    "country": "Bhutan",
    "currency_code": "BTN"
  },
  {
    "country": "Bolivia",
    "currency_code": "BOB"
  },
  {
    "country": "Bosnia and Herzegovina",
    "currency_code": "BAM"
  },
  {
    "country": "Botswana",
    "currency_code": "BWP"
  },
  {
    "country": "Bouvet Island",
    "currency_code": "NOK"
  },
  {
    "country": "Brazil",
    "currency_code": "BRL"
  },
  {
    "country": "British Indian Ocean Territory",
    "currency_code": "USD"
  },
  {
    "country": "Brunei",
    "currency_code": "BND"
  },
  {
    "country": "Bulgaria",
    "currency_code": "BGN"
  },
  {
    "country": "Burkina Faso",
    "currency_code": "XOF"
  },
  {
    "country": "Burundi",
    "currency_code": "BIF"
  },
  {
    "country": "Cambodia",
    "currency_code": "KHR"
  },
  {
    "country": "Cameroon",
    "currency_code": "XAF"
  },
  {
    "country": "Canada",
    "currency_code": "CAD"
  },
  {
    "country": "Cape Verde",
    "currency_code": "CVE"
  },
  {
    "country": "Cayman Islands",
    "currency_code": "KYD"
  },
  {
    "country": "Central African Republic",
    "currency_code": "XAF"
  },
  {
    "country": "Chad",
    "currency_code": "XAF"
  },
  {
    "country": "Chile",
    "currency_code": "CLP"
  },
  {
    "country": "China",
    "currency_code": "CNY"
  },
  {
    "country": "Christmas Island",
    "currency_code": "AUD"
  },
  {
    "country": "Cocos (Keeling) Islands",
    "currency_code": "AUD"
  },
  {
    "country": "Colombia",
    "currency_code": "COP"
  },
  {
    "country": "Comoros",
    "currency_code": "KMF"
  },
  {
    "country": "Congo",
    "currency_code": "XAF"
  },
  {
    "country": "Cook Islands",
    "currency_code": "NZD"
  },
  {
    "country": "Costa Rica",
    "currency_code": "CRC"
  },
  {
    "country": "Croatia",
    "currency_code": "EUR"
  },
  {
    "country": "Cuba",
    "currency_code": "CUP"
  },
  {
    "country": "Cyprus",
    "currency_code": "EUR"
  },
  {
    "country": "Czech Republic",
    "currency_code": "CZK"
  },
  {
    "country": "Denmark",
    "currency_code": "DKK"
  },
  {
    "country": "Djibouti",
    "currency_code": "DJF"
  },
  {
    "country": "Dominica",
    "currency_code": "XCD"
  },
  {
    "country": "Dominican Republic",
    "currency_code": "DOP"
  },
  {
    "country": "East Timor",
    "currency_code": "USD"
  },
  {
    "country": "Ecuador",
    "currency_code": "ECS"
  },
  {
    "country": "Egypt",
    "currency_code": "EGP"
  },
  {
    "country": "El Salvador",
    "currency_code": "SVC"
  },
  {
    "country": "England",
    "currency_code": "GBP"
  },
  {
    "country": "Equatorial Guinea",
    "currency_code": "XAF"
  },
  {
    "country": "Eritrea",
    "currency_code": "ERN"
  },
  {
    "country": "Estonia",
    "currency_code": "EUR"
  },
  {
    "country": "Ethiopia",
    "currency_code": "ETB"
  },
  {
    "country": "Falkland Islands",
    "currency_code": "FKP"
  },
  {
    "country": "Faroe Islands",
    "currency_code": "DKK"
  },
  {
    "country": "Fiji Islands",
    "currency_code": "FJD"
  },
  {
    "country": "Finland",
    "currency_code": "EUR"
  },
  {
    "country": "France",
    "currency_code": "EUR"
  },
  {
    "country": "French Guiana",
    "currency_code": "EUR"
  },
  {
    "country": "French Polynesia",
    "currency_code": "XPF"
  },
  {
    "country": "French Southern territories",
    "currency_code": "EUR"
  },
  {
    "country": "Gabon",
    "currency_code": "XAF"
  },
  {
    "country": "Gambia",
    "currency_code": "GMD"
  },
  {
    "country": "Georgia",
    "currency_code": "GEL"
  },
  {
    "country": "Germany",
    "currency_code": "EUR"
  },
  {
    "country": "Ghana",
    "currency_code": "GHS"
  },
  {
    "country": "Gibraltar",
    "currency_code": "GIP"
  },
  {
    "country": "Greece",
    "currency_code": "EUR"
  },
  {
    "country": "Greenland",
    "currency_code": "DKK"
  },
  {
    "country": "Grenada",
    "currency_code": "XCD"
  },
  {
    "country": "Guadeloupe",
    "currency_code": "EUR"
  },
  {
    "country": "Guam",
    "currency_code": "USD"
  },
  {
    "country": "Guatemala",
    "currency_code": "QTQ"
  },
  {
    "country": "Guinea",
    "currency_code": "GNF"
  },
  {
    "country": "Guinea-Bissau",
    "currency_code": "CFA"
  },
  {
    "country": "Guyana",
    "currency_code": "GYD"
  },
  {
    "country": "Haiti",
    "currency_code": "HTG"
  },
  {
    "country": "Heard Island and McDonald Islands",
    "currency_code": "AUD"
  },
  {
    "country": "Holy See (Vatican City State)",
    "currency_code": "EUR"
  },
  {
    "country": "Honduras",
    "currency_code": "HNL"
  },
  {
    "country": "Hong Kong",
    "currency_code": "HKD"
  },
  {
    "country": "Hungary",
    "currency_code": "HUF"
  },
  {
    "country": "Iceland",
    "currency_code": "ISK"
  },
  {
    "country": "India",
    "currency_code": "INR"
  },
  {
    "country": "Indonesia",
    "currency_code": "IDR"
  },
  {
    "country": "Iran",
    "currency_code": "IRR"
  },
  {
    "country": "Iraq",
    "currency_code": "IQD"
  },
  {
    "country": "Ireland",
    "currency_code": "EUR"
  },
  {
    "country": "Israel",
    "currency_code": "ILS"
  },
  {
    "country": "Italy",
    "currency_code": "EUR"
  },
  {
    "country": "Ivory Coast",
    "currency_code": "XOF"
  },
  {
    "country": "Jamaica",
    "currency_code": "JMD"
  },
  {
    "country": "Japan",
    "currency_code": "JPY"
  },
  {
    "country": "Jordan",
    "currency_code": "JOD"
  },
  {
    "country": "Kazakhstan",
    "currency_code": "KZT"
  },
  {
    "country": "Kenya",
    "currency_code": "KES"
  },
  {
    "country": "Kiribati",
    "currency_code": "AUD"
  },
  {
    "country": "Kuwait",
    "currency_code": "KWD"
  },
  {
    "country": "Kyrgyzstan",
    "currency_code": "KGS"
  },
  {
    "country": "Laos",
    "currency_code": "LAK"
  },
  {
    "country": "Latvia",
    "currency_code": "EUR"
  },
  {
    "country": "Lebanon",
    "currency_code": "LBP"
  },
  {
    "country": "Lesotho",
    "currency_code": "LSL"
  },
  {
    "country": "Liberia",
    "currency_code": "LRD"
  },
  {
    "country": "Libyan Arab Jamahiriya",
    "currency_code": "LYD"
  },
  {
    "country": "Liechtenstein",
    "currency_code": "CHF"
  },
  {
    "country": "Lithuania",
    "currency_code": "EUR"
  },
  {
    "country": "Luxembourg",
    "currency_code": "EUR"
  },
  {
    "country": "Macau",
    "currency_code": "MOP"
  },
  {
    "country": "North Macedonia",
    "currency_code": "MKD"
  },
  {
    "country": "Madagascar",
    "currency_code": "MGF"
  },
  {
    "country": "Malawi",
    "currency_code": "MWK"
  },
  {
    "country": "Malaysia",
    "currency_code": "MYR"
  },
  {
    "country": "Maldives",
    "currency_code": "MVR"
  },
  {
    "country": "Mali",
    "currency_code": "XOF"
  },
  {
    "country": "Malta",
    "currency_code": "EUR"
  },
  {
    "country": "Marshall Islands",
    "currency_code": "USD"
  },
  {
    "country": "Martinique",
    "currency_code": "EUR"
  },
  {
    "country": "Mauritania",
    "currency_code": "MRO"
  },
  {
    "country": "Mauritius",
    "currency_code": "MUR"
  },
  {
    "country": "Mayotte",
    "currency_code": "EUR"
  },
  {
    "country": "Mexico",
    "currency_code": "MXN"
  },
  {
    "country": "Micronesia",
    "currency_code": "USD"
  },
  {
    "country": "Moldova",
    "currency_code": "MDL"
  },
  {
    "country": "Monaco",
    "currency_code": "EUR"
  },
  {
    "country": "Mongolia",
    "currency_code": "MNT"
  },
  {
    "country": "Montserrat",
    "currency_code": "XCD"
  },
  {
    "country": "Morocco",
    "currency_code": "MAD"
  },
  {
    "country": "Mozambique",
    "currency_code": "MZN"
  },
  {
    "country": "Myanmar",
    "currency_code": "MMR"
  },
  {
    "country": "Namibia",
    "currency_code": "NAD"
  },
  {
    "country": "Nauru",
    "currency_code": "AUD"
  },
  {
    "country": "Nepal",
    "currency_code": "NPR"
  },
  {
    "country": "Netherlands",
    "currency_code": "EUR"
  },
  {
    "country": "Netherlands Antilles",
    "currency_code": "ANG"
  },
  {
    "country": "New Caledonia",
    "currency_code": "XPF"
  },
  {
    "country": "New Zealand",
    "currency_code": "NZD"
  },
  {
    "country": "Nicaragua",
    "currency_code": "NIO"
  },
  {
    "country": "Niger",
    "currency_code": "XOF"
  },
  {
    "country": "Nigeria",
    "currency_code": "NGN"
  },
  {
    "country": "Niue",
    "currency_code": "NZD"
  },
  {
    "country": "Norfolk Island",
    "currency_code": "AUD"
  },
  {
    "country": "North Korea",
    "currency_code": "KPW"
  },
  {
    "country": "Northern Ireland",
    "currency_code": "GBP"
  },
  {
    "country": "Northern Mariana Islands",
    "currency_code": "USD"
  },
  {
    "country": "Norway",
    "currency_code": "NOK"
  },
  {
    "country": "Oman",
    "currency_code": "OMR"
  },
  {
    "country": "Pakistan",
    "currency_code": "PKR"
  },
  {
    "country": "Palau",
    "currency_code": "USD"
  },
  {
    "country": "Palestine",
    "currency_code": null
  },
  {
    "country": "Panama",
    "currency_code": "PAB"
  },
  {
    "country": "Papua New Guinea",
    "currency_code": "PGK"
  },
  {
    "country": "Paraguay",
    "currency_code": "PYG"
  },
  {
    "country": "Peru",
    "currency_code": "PEN"
  },
  {
    "country": "Philippines",
    "currency_code": "PHP"
  },
  {
    "country": "Pitcairn Islands",
    "currency_code": "NZD"
  },
  {
    "country": "Poland",
    "currency_code": "PLN"
  },
  {
    "country": "Portugal",
    "currency_code": "EUR"
  },
  {
    "country": "Puerto Rico",
    "currency_code": "USD"
  },
  {
    "country": "Qatar",
    "currency_code": "QAR"
  },
  {
    "country": "Reunion",
    "currency_code": "EUR"
  },
  {
    "country": "Romania",
    "currency_code": "RON"
  },
  {
    "country": "Russia",
    "currency_code": "RUB"
  },
  {
    "country": "Rwanda",
    "currency_code": "RWF"
  },
  {
    "country": "Saint Helena",
    "currency_code": "SHP"
  },
  {
    "country": "Saint Kitts and Nevis",
    "currency_code": "XCD"
  },
  {
    "country": "Saint Lucia",
    "currency_code": "XCD"
  },
  {
    "country": "Saint Pierre and Miquelon",
    "currency_code": "EUR"
  },
  {
    "country": "Saint Vincent and the Grenadines",
    "currency_code": "XCD"
  },
  {
    "country": "Samoa",
    "currency_code": "WST"
  },
  {
    "country": "San Marino",
    "currency_code": "EUR"
  },
  {
    "country": "Sao Tome and Principe",
    "currency_code": "STD"
  },
  {
    "country": "Saudi Arabia",
    "currency_code": "SAR"
  },
  {
    "country": "Scotland",
    "currency_code": "GBP"
  },
  {
    "country": "Senegal",
    "currency_code": "XOF"
  },
  {
    "country": "Serbia",
    "currency_code": "RSD"
  },
  {
    "country": "Seychelles",
    "currency_code": "SCR"
  },
  {
    "country": "Sierra Leone",
    "currency_code": "SLL"
  },
  {
    "country": "Singapore",
    "currency_code": "SGD"
  },
  {
    "country": "Slovakia",
    "currency_code": "EUR"
  },
  {
    "country": "Slovenia",
    "currency_code": "EUR"
  },
  {
    "country": "Solomon Islands",
    "currency_code": "SBD"
  },
  {
    "country": "Somalia",
    "currency_code": "SOS"
  },
  {
    "country": "South Africa",
    "currency_code": "ZAR"
  },
  {
    "country": "South Georgia and the South Sandwich Islands",
    "currency_code": "GBP"
  },
  {
    "country": "South Korea",
    "currency_code": "KRW"
  },
  {
    "country": "South Sudan",
    "currency_code": "SSP"
  },
  {
    "country": "Spain",
    "currency_code": "EUR"
  },
  {
    "country": "Sri Lanka",
    "currency_code": "LKR"
  },
  {
    "country": "Sudan",
    "currency_code": "SDG"
  },
  {
    "country": "Suriname",
    "currency_code": "SRD"
  },
  {
    "country": "Svalbard and Jan Mayen",
    "currency_code": "NOK"
  },
  {
    "country": "Swaziland",
    "currency_code": "SZL"
  },
  {
    "country": "Sweden",
    "currency_code": "SEK"
  },
  {
    "country": "Switzerland",
    "currency_code": "CHF"
  },
  {
    "country": "Syria",
    "currency_code": "SYP"
  },
  {
    "country": "Tajikistan",
    "currency_code": "TJS"
  },
  {
    "country": "Tanzania",
    "currency_code": "TZS"
  },
  {
    "country": "Thailand",
    "currency_code": "THB"
  },
  {
    "country": "The Democratic Republic of Congo",
    "currency_code": "CDF"
  },
  {
    "country": "Togo",
    "currency_code": "XOF"
  },
  {
    "country": "Tokelau",
    "currency_code": "NZD"
  },
  {
    "country": "Tonga",
    "currency_code": "TOP"
  },
  {
    "country": "Trinidad and Tobago",
    "currency_code": "TTD"
  },
  {
    "country": "Tunisia",
    "currency_code": "TND"
  },
  {
    "country": "Turkey",
    "currency_code": "TRY"
  },
  {
    "country": "Turkmenistan",
    "currency_code": "TMT"
  },
  {
    "country": "Turks and Caicos Islands",
    "currency_code": "USD"
  },
  {
    "country": "Tuvalu",
    "currency_code": "AUD"
  },
  {
    "country": "Uganda",
    "currency_code": "UGX"
  },
  {
    "country": "Ukraine",
    "currency_code": "UAH"
  },
  {
    "country": "United Arab Emirates",
    "currency_code": "AED"
  },
  {
    "country": "United Kingdom",
    "currency_code": "GBP"
  },
  {
    "country": "United States",
    "currency_code": "USD"
  },
  {
    "country": "United States Minor Outlying Islands",
    "currency_code": "USD"
  },
  {
    "country": "Uruguay",
    "currency_code": "UYU"
  },
  {
    "country": "Uzbekistan",
    "currency_code": "UZS"
  },
  {
    "country": "Vanuatu",
    "currency_code": "VUV"
  },
  {
    "country": "Venezuela",
    "currency_code": "VEF"
  },
  {
    "country": "Vietnam",
    "currency_code": "VND"
  },
  {
    "country": "Virgin Islands, British",
    "currency_code": "USD"
  },
  {
    "country": "Virgin Islands, U.S.",
    "currency_code": "USD"
  },
  {
    "country": "Wales",
    "currency_code": "GBP"
  },
  {
    "country": "Wallis and Futuna",
    "currency_code": "XPF"
  },
  {
    "country": "Western Sahara",
    "currency_code": "MAD"
  },
  {
    "country": "Yemen",
    "currency_code": "YER"
  },
  {
    "country": "Zambia",
    "currency_code": "ZMW"
  },
  {
    "country": "Zimbabwe",
    "currency_code": "ZWD"
  }
];

export default countries;
